import { ACCESS_TOKEN } from '../utils/constants'
import { AxiosResponse } from 'axios'
import { MeasurementGroupResponse } from '../app/types/response'
import {
    PermeabilityRequest,
    PorosityRequest,
    SaturationRequest,
} from '../app/types/request'
import {instance} from "../app/api";

export const standardStudyGroupService = {
    getStandardGroup: async (projectId: number, coreId: number) => {
        return await instance
            .get<any, AxiosResponse<MeasurementGroupResponse>>(
                `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },

    createStandardGroup: async (
        projectId: number,
        coreId: number,
        data: { core: number },
    ) => {
        return await instance.post<
            { projectId: number; coreId: number; data: { core: number } },
            AxiosResponse<MeasurementGroupResponse>
        >(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    getPorosity: async (projectId: number, coreId: number, groupId: number) => {
        return await instance
            .get<any, AxiosResponse<PorosityRequest & { id?: number }>>(
                `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/porosity/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    createPorosity: async (
        projectId: number,
        coreId: number,
        groupId: number,
        data: PorosityRequest,
    ) => {
        return await instance
            .post<
                {
                    projectId: number
                    coreId: number
                    groupId: number
                    data: PorosityRequest
                },
                AxiosResponse<
                    PorosityRequest & { id?: number; value_normalized?: number }
                >
            >(
                `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/porosity/`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },

    updatePorosity: async (
        projectId: number,
        coreId: number,
        groupId: number,
        porosityId: number,
        data: PorosityRequest,
    ) => {
        return await instance
            .put<
                {
                    projectId: number
                    coreId: number
                    groupId: number
                    data: PorosityRequest
                },
                AxiosResponse<
                    PorosityRequest & { id?: number; value_normalized?: number }
                >
            >(
                `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/porosity/${porosityId}/`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    deletePorosity: async (
        projectId: number,
        coreId: number,
        groupId: number,
        porosityId: number,
    ) => {
        return await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/porosity/${porosityId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    getPermeability: async (
        projectId: number,
        coreId: number,
        groupId: number,
    ) => {
        return await instance
            .get(
                `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/permeability/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },

    createPermeability: async (
        projectId: number,
        coreId: number,
        groupId: number,
        data: PermeabilityRequest,
    ) => {
        const response = await instance.post<
            {
                projectId: number
                coreId: number
                groupId: number
                data: PorosityRequest
            },
            AxiosResponse<
                PermeabilityRequest & { id?: number; value_normalized?: number }
            >
        >(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/permeability/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return response.data
    },

    updatePermeability: async (
        projectId: number,
        coreId: number,
        groupId: number,
        permeabilityId: number,
        data: PermeabilityRequest,
    ) => {
        return await instance.put<
            {
                projectId: number
                coreId: number
                groupId: number
                data: PorosityRequest
            },
            AxiosResponse<
                PermeabilityRequest & { id?: number; value_normalized?: number }
            >
        >(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/permeability/${permeabilityId}/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    deletePermeability: async (
        projectId: number,
        coreId: number,
        groupId: number,
        permeabilityId: number,
    ) => {
        return await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/permeability/${permeabilityId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    getSaturation: async (
        projectId: number,
        coreId: number,
        groupId: number,
    ) => {
        return await instance
            .get<
                any,
                AxiosResponse<
                    SaturationRequest & {
                        id?: number
                        value_normalized?: string
                    }
                >
            >(
                `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/saturation/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },

    createSaturation: async (
        projectId: number,
        coreId: number,
        groupId: number,
        data: SaturationRequest,
    ) => {
        const res = await instance.post<
            any,
            AxiosResponse<
                SaturationRequest & { id?: number; value_normalized?: string }
            >
        >(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/saturation/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return res.data
    },

    updateSaturation: async (
        projectId: number,
        coreId: number,
        groupId: number,
        saturationId: number,
        data: SaturationRequest,
    ) => {
        return await instance.put<
            any,
            AxiosResponse<SaturationRequest & { id?: number }>
        >(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/saturation/${saturationId}/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    deleteSaturation: async (
        projectId: number,
        coreId: number,
        groupId: number,
        saturationId: number,
    ) => {
        return await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/saturation/${saturationId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    getOilDisplacement: async (
        projectId: number,
        coreId: number,
        groupId: number,
    ) => {
        return await instance
            .get(
                `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/oil-displacement/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },

    createOilDisplacement: async (
        projectId: number,
        coreId: number,
        groupId: number,
        data,
    ) => {
        const response = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/oil-displacement/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return response.data
    },

    updateOilDisplacement: async (
        projectId,
        coreId,
        groupId,
        oilDisplacementId,
        data,
    ) => {
        return await instance.put(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/oil-displacement/${oilDisplacementId}/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    deleteOilDisplacement: async (
        projectId,
        coreId,
        groupId,
        oilDisplacementId,
    ) => {
        return await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/oil-displacement/${oilDisplacementId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    getCarbonateContent: async (
        projectId: number,
        coreId: number,
        groupId: number,
    ) => {
        return await instance
            .get(
                `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/carbonate-content/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },

    createCarbonateContent: async (
        projectId: number,
        coreId: number,
        groupId: number,
        data: number,
    ) => {
        const response = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/carbonate-content/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return response.data
    },

    updateCarbonateContent: async (
        projectId,
        coreId,
        groupId,
        carbonateId,
        data,
    ) => {
        return await instance.put(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/carbonate-content/${carbonateId}/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    deleteCarbonateContent: async (
        projectId: number,
        coreId: number,
        groupId: number,
        carbonateId: number,
    ) => {
        return await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/carbonate-content/${carbonateId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    getDensity: async (projectId: number, coreId: number, groupId: number) => {
        return await instance
            .get(
                `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/density/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },

    createDensity: async (
        projectId: number,
        coreId: number,
        groupId: number,
        data: number,
    ) => {
        const response = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/density/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return response.data
    },

    updateDensity: async (
        projectId: number,
        coreId: number,
        groupId: number,
        densityId: number,
        data,
    ) => {
        return await instance.put(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/density/${densityId}/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    deleteDensity: async (
        projectId: number,
        coreId: number,
        groupId: number,
        densityId: number,
    ) => {
        return await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/density/${densityId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    getCapillaryCurve: async (projectId, coreId, groupId) => {
        return await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/capillary-curve/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    createCapillaryCurve: async (projectId, coreId, groupId, data) => {
        const response = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/capillary-curve/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return response.data
    },

    updateCapillaryCurve: async (projectId, coreId, groupId, data) => {
        return await instance.patch(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/capillary-curve/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    deleteCapillaryCurve: async (projectId, coreId, groupId, curveId) => {
        return await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/capillary-curve/${curveId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    getCapillaryCurvePoint: async (projectId, coreId, groupId) => {
        return await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/capillary-curve/capillary-curve-point/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    createCapillaryCurvePoint: async (projectId, coreId, groupId, data) => {
        const response = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/capillary-curve/capillary-curve-point/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return response.data
    },

    updateCapillaryCurvePoint: async (
        projectId,
        coreId,
        groupId,
        pointId,
        data,
    ) => {
        return await instance.put(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/capillary-curve/capillary-curve-point/${pointId}/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    deleteCapillaryCurvePoint: async (projectId, coreId, groupId, pointId) => {
        return await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/standard-group/${groupId}/capillary-curve/capillary-curve-point/${pointId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },
}
