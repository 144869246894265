import {AxiosResponse} from "axios";
import {ACCESS_TOKEN} from "../utils/constants";
import {IAddMnemonicFile,} from "../app/types/dto/add-mnemonic-file";
import {instance} from "../app/api";

export const mnemonicGroupsService = {
    getMnemonicGroups: async (projectId: number,params:any) => {
        return await instance
            .get<undefined, AxiosResponse<any>>(
                `projects/${projectId}/wells/mnemonic-group/`,
                {
                    headers: {
                        Authorization:`Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                    params
                },
            ).then((res) => res.data)
    },
    addMnemonicGroup: async (projectId: number,data:any) => {
        return await instance
            .post<undefined, AxiosResponse<IAddMnemonicFile>>(
                `projects/${projectId}/wells/mnemonic-group/`,data,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    updateMnemonicGroup: async (projectId: number,data:any,groupId:number) => {
        return await instance
            .patch<undefined, AxiosResponse<IAddMnemonicFile>>(
                `projects/${projectId}/wells/mnemonic-group/${groupId}/`,data,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    deleteMnemonicGroup: async (projectId: number,mnemonicId:number) => {
        return await instance
            .delete(
                `projects/${projectId}/wells/mnemonic-group/${mnemonicId}`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },

}