import React from 'react'

export const DoerIcon = () => {
    return (
        <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="29" height="29" rx="1" fill="white" />
            <path
                d="M5.5 8C5.5 7.17157 6.17157 6.5 7 6.5H22C22.8284 6.5 23.5 7.17157 23.5 8V23C23.5 23.8284 22.8284 24.5 22 24.5H7C6.17157 24.5 5.5 23.8284 5.5 23V8Z"
                fill="#B7B6FF"
                stroke="#B7B6FF"
            />
            <circle cx="12.5" cy="11.5" r="4" fill="white" stroke="white" />
            <path
                d="M14.5858 17.5H13H10.0582C9.41237 17.5 8.7831 17.7045 8.26061 18.0841L8.09228 18.2064C7.40606 18.705 7 19.5019 7 20.3501C7 21.8136 8.18638 23 9.64986 23H17.3871C18.4917 23 19.3871 22.1046 19.3871 21V20.8823C19.3871 19.6402 19.6375 18.4108 20.1234 17.2677L22.6119 11.4131C22.8391 10.8787 22.6671 10.2582 22.1973 9.9168C21.5506 9.44694 20.6351 9.69849 20.3192 10.4328L18.6567 14.2983C18.4568 14.7629 18.1819 15.1915 17.8428 15.5669L17 16.5C16.3597 17.1403 15.4913 17.5 14.5858 17.5Z"
                fill="white"
                stroke="white"
            />
        </svg>
    )
}
