import React from 'react'

export const UnAcceptIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="7" fill="white" stroke="#FF0000" strokeWidth="2"/>
            <path d="M4 7.93293L7.99986 7.93293L12.4999 7.93294" stroke="#FF0000" strokeWidth="2"/>
        </svg>



    )
}
