import { slice } from './tasks-slice'
import * as notificationSelectors from './selectors'
import * as actions from './actions'

const notificationActions = {
    ...slice.actions,
    ...actions,
}

const notificationReducer = slice.reducer

export { notificationReducer, notificationSelectors, notificationActions }
