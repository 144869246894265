import React, {FC, useEffect, useState} from 'react';
import {IAllNotificationsProps} from "./types";
import {useSelector} from "react-redux";
import Notification from "../../../../entities/notification/Notification";
import {INotification} from "../../../../app/types/models/i-notification";
import './allSettings.css';
import {errorsHandler} from "../../../../utils/helpers/errors/errors-hendler";
import {useTranslation} from "react-i18next";
import {notificationsService} from "../../../../services/notifications-service";
import {showNotice} from "../../../../utils/helpers/showNotice";
import {RequestAction} from "../../../../app/types/enums";
import {userService} from "../../../../services/user-service";
import SpinInLog from "../../../../shared/spin/spin";
import {useAppDispatch} from "../../../../hooks/redux-hooks";
import {deleteNotification, setNotifications, updateNotification} from "../../../../store/notifications/actions";
import {selectNotifications} from "../../../../store/notifications/selectors";

const AllNotifications: FC<IAllNotificationsProps> = ({isLoading}) => {
    const dispatch = useAppDispatch()
    const {t,i18n} = useTranslation()
    const notifications = useSelector(selectNotifications) || []

    const deleteNotificationHandler = async (id: number) => {
        try {
            await notificationsService.deleteNotification(id)
            dispatch(deleteNotification({id}))
            showNotice('notification-deleted-successfully', t)
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const sendNoticeResponse = async (item: INotification, action: RequestAction) => {
        try {
            const getPreparedData = (field: string) => (
                {
                    [field]: item?.data[field]?.id,
                    action: action
                }
            ) as any
            if (item?.type === 'role_request') {
                await userService.changeRoleAdminResponse(item?.data?.project?.id, getPreparedData(item?.type))
            }
            if (item?.type === 'project_user_invitation') {
                await notificationsService.invitationResponse(item?.data?.project?.id, getPreparedData(item?.type))
            }
            const response = await notificationsService.getNotifications()
            dispatch(setNotifications({notifications:response}))
        } catch (e) {
            console.log(e)
            errorsHandler(e, t)
        }
    }

    const readNotification = async (id: number) => {
        try {
            await notificationsService.updateNotification(id,{is_read:true})
            const targetNotification = notifications.find(el=>el.id===id)
            dispatch(updateNotification({id,notification:{...targetNotification, is_read: true}}))
        } catch (e) {
            console.log(e)
            errorsHandler(e, t)
        }
    }

    return (
        <div className='all-notifications mt-20'>
            <SpinInLog isLoading={isLoading}/>
            {notifications?.filter(el => !(el?.is_deleted) && !(el?.deleted))?.map((item: INotification, index: number) => (
                <Notification
                    key={index}
                    item={item}
                    deleteBlock={deleteNotificationHandler}
                    sendNoticeResponse={sendNoticeResponse}
                    readNotification={readNotification}
                />
            ))}
        </div>
    );
};

export default AllNotifications;