export const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'rgba(219, 218, 255, 0.5)' : 'transparent',
})

export const getItemStyle = (isDragging, draggableStyle) => {
    const validBackground = isDragging ? {
        background: 'rgba(219, 218, 255, 0.5)',
    } : {}
    return {
        userSelect: 'none',
        ...draggableStyle, 
        // width:isDragging ?  70 : '100%', minHeight:isDragging ? 40 : '100%', height:isDragging ? 40 : '100%',
        ...validBackground,
        // overflow:'hidden'
    }
}

