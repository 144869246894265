import { Button } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { membersSelectors } from '../../store/members'
import ShareProject from '../shareProject/ShareProject'
import { TeamItem } from './components/TeamItem/TeamItem'
import './team.css'

const Team = () => {
    const members = useSelector(membersSelectors.selectMembers)
    const { t } = useTranslation()
    const [openModal, setOpenModal] = useState(false)

    return (
        <div className="team">
            <div className="team__container">
                <div className="team-top">
                    <h1 className="team__title">
                        {`${t('team')}`}
                    </h1>
                    <Button onClick={() => setOpenModal(true)}>
                        {t('open-access').toString()}
                    </Button>
                </div>
                <div className="team__table-list">
                    <TeamItem isTopRow={true} />
                    {members &&
                        Array.isArray(members) &&
                        members.map((m) => {
                            return <TeamItem member={m} key={m?.id} />
                        })}
                </div>
            </div>
            <ShareProject
                isOpenModal={openModal}
                setIsOpenModal={setOpenModal}
            />
        </div>
    )
}

export { Team }
