import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { appReducer } from './app'
import { authReducer } from './auth'
import { userReducer } from './user'
import { projectsReducer } from './projects'
import thunkMiddleware from 'redux-thunk'
import { currentProjectReducer } from './currentProject'
import { membersReducer } from './members'
import { tasksReducer } from './tasks'
import { researchEntitiesReducer } from './researchEntities'
import { panelsReducer } from './panels'
import { notificationReducer } from './notifications'

export const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    user: userReducer,
    projects: projectsReducer,
    currentProject: currentProjectReducer,
    members: membersReducer,
    tasks: tasksReducer,
    researchEntities: researchEntitiesReducer,
    panels: panelsReducer,
    notifications: notificationReducer
})

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().prepend(thunkMiddleware),
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
