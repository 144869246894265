import React, {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {LoggingPanelItem} from './components/loggingPanelItem/LoggingPanelItem'
import {ILoggingPanelProps} from "./types";
import {LoggingPanelTypes} from "./components/loggingPanelItem/types";
import {IUploadFile} from "../../../../../../shared/uploadButton/types";
import {useSelector} from "react-redux";
import {currentProjectSelectors} from "../../../../../../store/currentProject";
import {createFromFileService} from "../../../../../../services/create-from-file-service";
import SpinInLog from "../../../../../../shared/spin/spin";
import {projectsService} from "../../../../../../services/projects-service.";
import {useAppDispatch} from "../../../../../../hooks/redux-hooks";
import {
    setCurrentCores,
    setCurrentPads,
    setCurrentWellBores,
    setCurrentWells
} from "../../../../../../store/currentProject/actions";
import {wellsService} from "../../../../../../services/wells-service";
import {wellBoreService} from "../../../../../../services/wellBore-service";
import { errorsHandler } from '../../../../../../utils/helpers/errors/errors-hendler';

const LoggingPanel: FC<ILoggingPanelProps> = ({
                                                  showPadsInput,
                                                  showWellsInput,
                                                  showCoresInput,
                                                  tabActiveKey,
                                                  setShowPadsInput,
                                                  setShowWellsInput,
                                                  setShowCoresInput,
                                                  setTabActiveKey,
                                              }) => {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const currentProject = useSelector(
        currentProjectSelectors.selectCurrentProject,
    )
    const currentWell = useSelector(currentProjectSelectors.selectCurrentWell)
    const currentPad = useSelector(currentProjectSelectors.selectCurrentPad)
    const currentWellBore = useSelector(currentProjectSelectors.selectCurrentWellBore)
    const dispatch = useAppDispatch()
    const uploadTableData = async (data: IUploadFile, type: LoggingPanelTypes) => {
        const formData = new FormData()
        if (data && data?.file) {
            formData.append('file', data?.file)
        }
        try {
            switch (type) {
                case LoggingPanelTypes.WELL: {
                    setLoading(true)
                    formData.append('well_pad', currentPad.id.toString())
                    await createFromFileService.createFromNamesFile(currentProject?.id, formData)
                    const wellsResponse = await wellsService.getWells(currentProject?.id)
                    const wellBoresResponse = await wellBoreService.getWellBores(currentProject?.id)
                    dispatch(setCurrentWells({wells: wellsResponse}))
                    dispatch(setCurrentWellBores({wellBores: wellBoresResponse}))
                    setLoading(false)
                    break;
                }
                case LoggingPanelTypes.PAD: {
                    setLoading(true)
                    await createFromFileService.createPadFile(currentProject?.id, formData)
                    const padsResponse = await projectsService.getPads(currentProject?.id)
                    dispatch(setCurrentPads({pads: padsResponse}))
                    setLoading(false)
                    break;
                }
                case LoggingPanelTypes.CORE: {
                    setLoading(true)
                    if (currentWell) {
                        formData.append('well', currentWell?.id?.toString())
                    }
                    if (currentWellBore) {
                        formData.append('wellbore', currentWellBore?.id?.toString())
                    }
                    await createFromFileService.createCoreFile(currentProject?.id, formData,'create-from-names-file')
                    const response = await projectsService.getCores(currentProject?.id)
                    dispatch(setCurrentCores({cores: response}))
                    setLoading(false)
                    break;
                }
                default: {
                    setLoading(false)
                    break
                }
            }
        } catch (e) {
            setLoading(false)
            errorsHandler(e,t)
        }
    }


    return (
        <>
            <SpinInLog isLoading={loading}/>

            <div className={'projectAccordion__topChildren'}>

                <LoggingPanelItem
                    plusIcon
                    title={t('pads')}
                    toOpenInput={setShowPadsInput}
                    openValue={showPadsInput}
                    type={LoggingPanelTypes.PAD}
                    onChange={uploadTableData}
                />
                <LoggingPanelItem
                    plusIcon
                    title={t('wells')}
                    toOpenInput={setShowWellsInput}
                    openValue={showWellsInput}
                    type={LoggingPanelTypes.WELL}
                    onChange={uploadTableData}
                />
                <LoggingPanelItem
                    plusIcon
                    title={t('core')}
                    toOpenInput={setShowCoresInput}
                    openValue={showCoresInput}
                    setTabActiveKey={setTabActiveKey}
                    tabActiveKey={tabActiveKey}
                    tabKey={'0'}
                    type={LoggingPanelTypes.CORE}
                    onChange={uploadTableData}
                />
                {
                    (currentWell || currentWellBore) &&
                    <LoggingPanelItem
                        title={t('well-data')}
                        setTabActiveKey={setTabActiveKey}
                        tabActiveKey={tabActiveKey}
                        tabKey={'1'}
                        type={LoggingPanelTypes.WELL_LOGGING}
                    />
                }
                <LoggingPanelItem
                    title={t('production')}
                    setTabActiveKey={setTabActiveKey}
                    tabActiveKey={tabActiveKey}
                    tabKey={'2'}
                    type={LoggingPanelTypes.PRODUCTION}
                />
            </div>
        </>
    )
}

export default LoggingPanel
