import * as authSelectors from './selectors'
import * as actions from './actions'
import { asyncActions, slice } from './auth-slice'

const authActions = {
    ...asyncActions,
    ...slice.actions,
    ...actions,
}
// Обьеденяем все actions в один объект

const authReducer = slice.reducer

export { authSelectors, authActions, authReducer }
