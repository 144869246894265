import {ICustomGroupParameter} from "../../../../../../../../../../../app/types/models/i-custom-group";

export const prepareParametersToRender = (items:ICustomGroupParameter[],isInitial?:boolean)=>{
    return items && Array.isArray(items) ? items?.map((el)=>{
        let data = JSON.parse(el.data)
        if(isInitial){
            data = {...data,value:'',isError:true}
        }

        const showIsErrorStatus = () =>{
            const targetValue = el?.data?.value
            if(targetValue){
                if(typeof targetValue ==='string'){
                    return targetValue !==''
                }else{
                    return false
                }
            }else return false
        }
    return {
        ...el,
        data,
        isError:showIsErrorStatus()
    }
    }) : []

    //...el,data: JSON.parse(el.data)
}