import {createSlice} from '@reduxjs/toolkit'
import {deleteNotification, setNotifications, updateNotification} from './actions'
import {INotification} from "../../app/types/models/i-notification";

export interface InitialState {
    notifications: INotification[]
}

const initialState: InitialState = {
    notifications: [],
}

export const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setNotifications, (state, action) => {
            state.notifications = action.payload.notifications
        })
        builder.addCase(deleteNotification, (state, action) => {
            state.notifications = state.notifications.filter(
                (f) => f.id !== action.payload.id,
            )
        })
        builder.addCase(updateNotification, (state, action) => {
            state.notifications = state.notifications.map((t) =>
                t.id === action.payload.id
                    ? action.payload.notification
                    : t,
            )
        })
    },
})
