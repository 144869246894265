import {IUser} from "../app/types/models/users";

export const getCorrectFirstLetter = (user:IUser)=>{
    let result = ''
    if(user){
        if(user?.first_name && user?.first_name?.length>0){
            result = user?.first_name[0]
        }else{
            result = user?.email[0]
        }
    }
    return result?.toUpperCase()
}