export const getTableTopData = (t:Function) =>([
    {
        label:t('well-data-logging-method')
    },
    {
        label:t('mnemonic-matching')
    },
    {
        label:t('unit-matching-convertation')
    }
])