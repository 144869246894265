import React, {FC, useEffect, useState} from 'react';
import {ILogsToInLogItemProps} from "./types";

const GisTableRow: FC<ILogsToInLogItemProps> = (
    {
        title,
        middleData,
        endData,
        className,
        showRadioButton = true,
        isCheckedRadioButton,
        id,
        disabled,
        onClick
    }
) => {
    const [isChecked, setIsChecked] = useState(isCheckedRadioButton)

    const onClickSetIsCheckedStatus = () => {
        setIsChecked(!isCheckedRadioButton)
        onClick && onClick(id, !isCheckedRadioButton)
    }

    useEffect(() => {
        setIsChecked(isCheckedRadioButton)
    }, [isCheckedRadioButton])

    return (
        <>
            <div className={className ? `font-14-normal gis-table-item ${className}` : 'font-14-normal gis-table-item'}>
                <div className='p-10 break-word'>{title}</div>
                <div className={`p-10  ${middleData?.length>1 ? 'gis-table-middle' : ''}`}>
                    {middleData.map((item,i) => <div key={i}>{item}</div>)}
                </div>
                <div className={`p-10 ${endData?.length>1 ? 'gis-table-end' : ''}`}>
                    {endData.map((item,i) => <div key={i}>{item}</div>)}
                </div>
                {
                    showRadioButton &&
                    <div className={'gis-table-radio-btn w-15 h-15'}>
                        <input type="radio"
                               checked={isChecked}
                               onClick={onClickSetIsCheckedStatus}
                               onChange={() => {
                               }}
                               disabled={disabled}
                               className='w-100p h-100p cursor-pointer'
                        />
                    </div>
                }
            </div>

        </>
    )
}
export default GisTableRow;