import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import SignHeader from "../../shared/signHeader/SignHeader"
import { authSelectors } from '../../store/auth'
import { routes } from '../../utils/constants'
import { getLocalizedText } from '../../utils/helpers/getLocalizedText'
import './recoveryMessage.css'

const RecoveryMessage = () => {
    const { email } = useSelector(authSelectors.selectPasswordResetData)
    const { t } = useTranslation()
    return (
        <div className="signBlock recoveryMessage">
            <div className="signBlock">
                <SignHeader
                    buttonRoute={{link: routes.LOGIN, name: t('sign-in')}}
                />
                <div className="signWrapper recoveryMessage__content">
                    <h2 className="signForm__header">
                        {getLocalizedText(
                            'reset-password',
                            t,
                        )}
                    </h2>
                    <div className="recoveryMessage__textBlock">
                        <p className="main_Recovery_text recover_changed_text">
                            <>
                                {t(
                                    'instruction-sent-to-address',{value:email}
                                )}
                                {t(
                                    'resend-email-if-didnt-get-mail',
                                )}
                            </>
                        </p>
                    </div>
                    <div className={'recoveryMessage__linkWrap'}>
                        <Link className="signForm__textLink" to={routes.LOGIN}>
                            {t(
                                'sign-in',
                            )}
                        </Link>
                        <Link
                            to={routes.PASSWORD_RECOVERY}
                            className="signForm__textLink"
                        >
                            {t(
                                'send-instruction-one-more-time',
                            )}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecoveryMessage
