import React from 'react'

const CoreStudyIcon = ({ className }) => {
    return (
        <svg
            className={className ? className : ''}
            width="45"
            height="45"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width="39"
                height="39"
                rx="4.5"
                fill="#FDFDFD"
            />
            <rect
                x="20.7891"
                y="6"
                width="18.765"
                height="19.501"
                rx="1"
                transform="rotate(45 20.7891 6)"
                fill="#FEC580"
                fillOpacity="0.6"
            />
            <rect
                x="20.7891"
                y="6.28284"
                width="18.365"
                height="19.101"
                rx="0.8"
                transform="rotate(45 20.7891 6.28284)"
                stroke="#2B506B"
                strokeOpacity="0.6"
                strokeWidth="0.4"
            />
            <path
                d="M13.1147 27C13.1147 26.6686 12.8461 26.4 12.5147 26.4L7.11469 26.4C6.78332 26.4 6.51469 26.6686 6.51469 27C6.51469 27.3313 6.78332 27.6 7.11469 27.6L11.9147 27.6L11.9147 32.4C11.9147 32.7313 12.1833 33 12.5147 33C12.8461 33 13.1147 32.7313 13.1147 32.4L13.1147 27ZM4.42426 35.9389L12.939 27.4242L12.0904 26.5757L3.57574 35.0904L4.42426 35.9389Z"
                fill="#364F6B"
                fillOpacity="0.6"
            />
            <path
                d="M36.6 4C36.6 3.66863 36.3314 3.4 36 3.4L30.6 3.4C30.2686 3.4 30 3.66863 30 4C30 4.33137 30.2686 4.6 30.6 4.6L35.4 4.6L35.4 9.4C35.4 9.73137 35.6686 10 36 10C36.3314 10 36.6 9.73137 36.6 9.4L36.6 4ZM28.4243 12.4243L36.4243 4.42426L35.5757 3.57574L27.5757 11.5757L28.4243 12.4243Z"
                fill="#364F6B"
                fillOpacity="0.6"
            />
        </svg>
    )
}

export default CoreStudyIcon
