import {ISettingRow} from "./types";

export const getDefaultRowsList = (t:Function):ISettingRow[]=>{
    return [
        {
            id:1,
            title:t('task-update-comments-added'),
            value:'comment',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:2,
            title:t('task-update-supervisor-changed'),
            value:'supervisor',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:3,
            title:t('task-update-task-name-changed'),
            value:'name',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:4,
            title:t('task-update-description-changed'),
            value:'description',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:5,
            title:t('task-update-priority-changed'),
            value:'priority',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:6,
            title:t('task-update-status-changed'),
            value:'status',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:7,
            title:t('task-update-start-date-changed'),
            value:'due_date_start',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:8,
            title:t('task-update-end-date-changed'),
            value:'due_date_end',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:9,
            title:t('task-update-doers-changed'),
            value:'doers',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:10,
            title:t('task-update-tags-changed'),
            value:'tags',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:11,
            title:t('inLog-news-and-updates'),
            value:'inlog_news',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:12,
            title:t('invitation-to-project'),
            value:'project_user_invitation',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:13,
            title:t('response-on-invitation-to-project'),
            value:'project_user_invitation_response',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:14,
            title:t('role-request'),
            value:'role_request',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:15,
            title:t('request-to-change-role'),
            value:'role_request_response',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:16,
            title:t('project-user-removal'),
            value:'project_user_removal',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:17,
            title:t('role-changing-by-admin'),
            value:'role_change',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:18,
            title:t('admin-rights-transfer'),
            value:'admin_rights_transfer',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:19,
            title:t('existing-the-project'),
            value:'project_leaving',
            email_checked:true,
            inLog_checked:true
        },
        {
            id:20,
            title:t('sound-on-all-notifications-types'),
            value:'sound_notification',
            email_checked:undefined,
            inLog_checked:true
        },
    ]
}