import React, {useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Form, Input} from 'antd'
import {useTranslation} from 'react-i18next'
import {getLocalizedText} from '../../utils/helpers/getLocalizedText'
import LetterIcon from '../../image_files/icons/LetterIcon'
import LockIcon from '../../image_files/icons/LockIcon'
import {ACCESS_TOKEN, REFRESH_TOKEN, routes} from '../../utils/constants'
import '../../styles/commonSign.css'
import {LoginRequest} from '../../app/types/request'
import {authService} from '../../services/auth-service'
import {useAppDispatch} from '../../hooks/redux-hooks'
import {authActions} from '../../store/auth'
import {appActions} from '../../store/app'
import {userActions} from '../../store/user'
import {LargeSpin} from "../../shared/LargeSpin";
import {errorsHandler} from "../../utils/helpers/errors/errors-hendler";
import SignHeader from "../../shared/signHeader/SignHeader";

function Login() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const signFormRef = useRef()
    const dispatch = useAppDispatch()
    const {setAppIsInitialized} = appActions
    const {setUser} = userActions
    const {setIsLoggedIn} = authActions
    const [loading, setLoading] = useState(false)

    const onFinish = async (values) => {
        const preparedData: LoginRequest = {
            email: values.email,
            password: values.password,
        }
        try {
            setLoading(true)
            const response = await authService.login(preparedData)
            setLoading(false)
            dispatch(setAppIsInitialized({isInitialized: true}))
            dispatch(setIsLoggedIn({isLoggedIn: true}))
            dispatch(setUser({user: response.user}))
            localStorage.setItem(
                ACCESS_TOKEN,
                JSON.stringify(response.access_token),
            )
            localStorage.setItem(
                REFRESH_TOKEN,
                JSON.stringify(response.refresh_token),
            )
            if (response) {
                navigate(routes.LOGGING)
            }
        } catch (e) {
            console.log(e)
            errorsHandler(e, t)
            setLoading(false)
        }
    }

    if (loading) {
        return <LargeSpin/>
    }

    return (
        <div className="signBlock">
            <SignHeader
                buttonRoute={{link:routes.REGISTRATION,name:t('registration')}}
            />
            <div className="signWrapper login__wrapper">
                <Form
                    className="signForm"
                    name="register"
                    scrollToFirstError
                    onFinish={onFinish}
                    ref={signFormRef}
                >
                    <h2 className="signForm__header">
                        {getLocalizedText('entrance', t)}

                    </h2>
                    <div className={'signForm__inputWrapper'}>
                        <Form.Item
                            name={'email'}
                            className={' signForm__inputItem'}
                            rules={[
                                {
                                    required: true,
                                    message: t('enter-email-address'),
                                },
                                {
                                    type: 'email',
                                    message: t('enter-real-email-and-password'),
                                },
                            ]}
                        >
                            <Input
                                type="email"
                                className="signForm__input"
                                prefix={<LetterIcon/>}
                                placeholder={t('email')}
                            />
                        </Form.Item>
                        <Form.Item
                            name={'password'}
                            rules={[
                                {
                                    required: true,
                                    message: t('password-must-has-more-than-value-symbols',{value:8}),
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password
                                className="signForm__input"
                                placeholder={t('password')}
                                prefix={<LockIcon/>}
                            />
                        </Form.Item>
                        <Link
                            to={routes.PASSWORD_RECOVERY}
                            className="signForm__textLink"
                        >
                            {getLocalizedText('forgot-password', t)}
                        </Link>
                        {/*<MediaAuth/>*/}
                        <div
                            className={'signForm__btn-wrapper'}
                            style={{textAlign: 'center'}}
                        >
                            <button
                                type={'submit'}
                                className={'signForm__submitBtn cursorPointer'}
                            >
                                {getLocalizedText('start', t)}
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default Login
