import React from 'react'
import { useState } from 'react'
import { getValidText } from '../../utils/helpers/getValidText'
import './Tabs.css'
import { Tabs } from 'antd'

const TabsInlog = ({
    items,
    className
}: {
    items: { label: string; key: string; children: React.ReactNode }[]
    className?: string
}) => {
    const [currentTab, setCurrentTab] = useState(0)


    return (
        <Tabs items={items} className={`${getValidText(className)}`}/>
    )
}
export default TabsInlog
