import {AxiosResponse} from 'axios'
import {UserResponse, UserSettingsDataResponse} from '../app/types/response'
import {SocialNames} from '../app/types/enums'
import {IUser, IUserSettingsData} from '../app/types/models/users'
import {ACCESS_TOKEN} from '../utils/constants'
import {instance} from "../app/api";
import {IChangeRoleRequest} from "../app/types/i-role-request";
import {IChangeRoleAdminResponse, IChangeRoleByUser} from "../app/types/i-user-invite-request";

export const userService = {
    fetchUser: async () => {
        return await instance
            .get<any, AxiosResponse<IUser>>('users/me/', {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
    updateUser:async(data:any)=>{
        return await instance
        .patch<IUser, AxiosResponse<IUser>>('users/me/',data, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        })
        .then((res) => res.data)
    },
    fetchUserSettingsData: async () => {
        return await instance
            .get<any, AxiosResponse<IUserSettingsData>>('users/me/settings/', {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
    updateUserSettingsData: async (data: FormData | {language:'ru' | 'en'} | object) => {
        return await instance
            .patch<FormData, AxiosResponse<UserSettingsDataResponse>>(
                'users/me/settings/',
                data,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    socialLogin: async (data: { code: string }, socialName: SocialNames) => {
        return await instance
            .patch<
                { data: { code: string }; socialName: SocialNames },
                AxiosResponse<{ user: IUser; access_token: string }>
            >(`users/social-auth/${socialName}/login/`, data)
            .then((res) => res.data)
    },
    fetchResentParticipants:async ()=>{
        return await instance
            .get<undefined,AxiosResponse<IUser[]>>(`users/recent-participants/`,{
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
    fetchInvitationList:async (projectId:number)=>{
        return await instance
            .get<undefined,AxiosResponse<IUser[]>>(`projects/${projectId}/user-invitation/`,{
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
    changeRole:async (projectId:number,memberId:number, data:IChangeRoleRequest)=>{
        return await instance
            .patch(`projects/${projectId}/members/${memberId}/`,data,{
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
    closeRoleAccess:async (projectId:number,memberId:number)=>{
        return await instance
            .delete(`projects/${projectId}/members/${memberId}/`,{
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
    changeRoleByUser:async (projectId:number, data:IChangeRoleByUser)=>{
        return await instance
            .post(`projects/${projectId}/role-request/`,data,{
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
    changeRoleAdminResponse:async (projectId:number, data:IChangeRoleAdminResponse)=>{
        return await instance
            .post(`projects/${projectId}/role-request/response/`,data,{
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
}
