import { Modal, Popover } from 'antd'
import React, { SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFile, ITask } from '../../../../app/types/models/tasks'
import { DeleteIcon } from '../../../../image_files/icons/DeleteIcon'
import FileIcon from '../../../../image_files/icons/FileIcon'
import { PreviewIcon } from '../../../../image_files/icons/PreviewIcon'
import { DownloadIcon } from '../../../../image_files/icons/downloadIcon'
import { tasksService } from '../../../../services/tasks-services'
import { FileAttach } from '../../../../shared/FileAttach/FileAttach'
import { CustomAccordion } from '../../../../shared/customAccordion/CustomAccordion'
import { downloadFile } from '../../../../utils/helpers/downloadFile'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { getLocalizedText } from '../../../../utils/helpers/getLocalizedText'
import { showNotify } from '../../../../utils/helpers/showNotice'
import { imageExtensions } from '../../../../utils/image-extensions'
import './TasksFiles.css'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { setTasksLoadingStatus } from '../../../../store/tasks/actions'

const TasksFiles = ({
    task,
    setTask,
}: {
    task: ITask
    setTask: (data: ITask) => void
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [previewOpen, setPreviewOpen] = useState({
        status: false,
        fileName: '',
        file: '',
    })

    const handleDeleteFile = async (id: number) => {
        try {
            dispatch(setTasksLoadingStatus(true))
            await tasksService.deleteTaskFile(task?.project, task?.slug, id)
            setTask({
                ...task,
                files: task.files?.filter((el) => el.id !== id),
            })
            showNotify(t('file-deleted-successfully'))
        } catch (e) {
            errorsHandler(e, t)
        } finally{
            dispatch(setTasksLoadingStatus(false))
        }
    }

    const customRequest = async (options) => {
        const fmData = new FormData()
        fmData.append('file', options.file)
        try {
            dispatch(setTasksLoadingStatus(true))
            const response = await tasksService.addTaskFile(
                task?.project,
                task?.slug,
                options.file,
            )
            setTask({
                ...task,
                files: [...task.files, response],
            })
            showNotify(t('file-added-successfully'))
        } catch (err) {
            errorsHandler(err, t)
        } finally{
            dispatch(setTasksLoadingStatus(false))
        }
    }

    const showFilesList = () => {
        if (task?.files && task.files?.length > 0) {
            return task.files.map((file) => {
                const { type, node } = showValidElement(file)
                return (
                    <Popover
                        placement="top"
                        content={
                            <div className="font-14-normal">
                                {file.filename.split('/').at(-1)}
                            </div>
                        }
                    >
                        <li>
                            {node}
                            <div className="tasks-files-item-bg" />
                            <div className="tasks-files-item__icon-wrapper">
                                {type === 'image' ? (
                                    <PreviewIcon
                                        fill={'#ffffff'}
                                        className={'tasks-files-item__icon '}
                                        onClick={() =>
                                            setPreviewOpen({
                                                status: true,
                                                file: file.file,
                                                fileName: file.filename
                                                    .split('/')
                                                    .at(-1),
                                            })
                                        }
                                    />
                                ) : (
                                    <DownloadIcon
                                        stroke="transparent"
                                        fill="transparent"
                                        className={
                                            'tasks-files-item__icon tasks-files-item__icon--download'
                                        }
                                        onClick={() =>
                                            downloadFile(
                                                file.file,
                                                file.filename,
                                            )
                                        }
                                    />
                                )}
                                <DeleteIcon
                                    stroke="#ffffff"
                                    className={'tasks-files-item__icon'}
                                    onClick={() => handleDeleteFile(file?.id)}
                                />
                            </div>
                        </li>
                    </Popover>
                )
            })
        }
        return <></>
    }

    const showValidElement = (elem: IFile) => {
        const file = elem.file
        const fileName = elem.filename
        if (imageExtensions.includes(fileName.split('.').at(-1))) {
            return {
                type: 'image',
                node: <img src={file} alt="task details image" />,
            }
        } else {
            return {
                type: 'file',
                node: (
                    <FileIcon
                        onClick={undefined}
                        className={'cursor-pointer'}
                    />
                ),
            }
        }
    }

    return (
        <CustomAccordion
            activeStatus={true}
            title={t('pin-files')}
            className="scheduler__tasksSettings-attach"
        >
            <div className="tasks-files">
                <div>
                    <FileAttach
                        className="tasks-files-attach-btn"
                        files={null}
                        multiple={true}
                        buttonTitle={getLocalizedText('upload', t)}
                        title={getLocalizedText('drag-files', t)}
                        customRequest={customRequest}
                        onChange={() => {}}
                        removeFile={handleDeleteFile}
                    />
                </div>
                <ul className="tasks-files-list">{showFilesList()}</ul>
                <Modal
                    open={previewOpen.status}
                    title={
                        <div className="tasks-files-preview-modal-title">
                            <span>{previewOpen.fileName}</span>
                        </div>
                    }
                    footer={null}
                    onCancel={() =>
                        setPreviewOpen((prev) => ({
                            status: false,
                            fileName: '',
                            file: '',
                        }))
                    }
                >
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewOpen.file}
                    />
                </Modal>
            </div>
        </CustomAccordion>
    )
}

export default TasksFiles
