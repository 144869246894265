import React from 'react'
import { Modal } from 'antd'
import './taskDeleteModal.css'
import { useTranslation } from 'react-i18next'
const TaskDeleteModal = ({
    openModal,
    showModal,
    hideModal,
    title,
    confirmText,
}) => {
    const {t} = useTranslation()
    return (
        <Modal
            title={title ? title : t('subtask-deleting')}
            className="subtask__modal"
            open={openModal}
            onOk={showModal}
            onCancel={hideModal}
            okText="Ок"
            cancelText={t('no')}
        >
            <p className="subtask__modal-content">
                {confirmText
                    ? confirmText
                    : t('you-realy-want-do-delete-this-task')}
            </p>
        </Modal>
    )
}

export { TaskDeleteModal }
