import { Tabs } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import 'simplebar-react/dist/simplebar.min.css'
import { getLocalizedText } from '../../../../../../utils/helpers/getLocalizedText'
import CoreForm from './components/coreForm/CoreForm'
import { Studies } from './components/coreStudies/Studies'
import './coreInfo.css'

const { TabPane } = Tabs

const CoreInfo = () => {
    const { t } = useTranslation()

    return (
        <div className={'core-info'}>
            <Tabs className={'coreInfo-tabs'} defaultActiveKey="0">
                <TabPane
                    key="0"
                    className={'coreInfo-tabs-pane'}
                    tab={
                        <div className={'core-info-tab-title'}>
                            {t('sample-info')}
                        </div>
                    }
                >
                    <CoreForm />
                </TabPane>
                <TabPane
                    key="1"
                    tab={
                        <div className={'core-info-tab-title'}>
                            {t('studies-on-core').toString()}
                        </div>
                    }
                >
                    <Studies />
                </TabPane>
            </Tabs>
        </div>
    )
}

export { CoreInfo }
