import { IUser } from "../../app/types/models/users"

export const getFirstMemberCharacter = (data:any) => {
    let res = ''
        if(data){
            if(data?.user?.full_name && data?.user?.full_name?.length>0 ){
                res = data?.user?.full_name[0]
            }else{
                console.log(data?.user,'--member?.user')

                if(data?.user?.email){
                    
                    res =data?.user?.email[0]
                }else {
                   res = '123'
                }
            }
        }
    return res?.toUpperCase()
}

export const getFirstUserCharacter = (user:IUser) => {
    let res = ''
        if(user){
            if(user?.first_name){
                res = user?.first_name[0]
            }else if(user?.middle_name){
                res = user?.middle_name[0] 
            }else if(user?.last_name){
                res = user?.last_name[0] 
            }else if(user?.email){
                res = user?.email[0]
            }else res = '123'
        }
    return res?.toUpperCase()
}