import React, { FC, useState } from 'react'
import './wellLogging.css'
import 'antd/dist/antd.css'
import WellInfo from './components/wellInfo/WellInfo'
import { useTranslation } from 'react-i18next'
import { getLocalizedText } from '../../utils/helpers/getLocalizedText'
import Logs from './components/logs/Logs'
import SimpleBar from 'simplebar-react'
import CoreBoxes from './components/coreBoxes/CoreBoxes'

interface IProps {
    currentWellId: number | null
    currentWellBoreId: number | null
}

const WellLogging: FC<IProps> = ({ currentWellId, currentWellBoreId }) => {
    const [currentTab, setCurrentTab] = useState('0')
    const { t } = useTranslation()

    const setCurrentTabStyle = (value: string) => {
        return {
            className:
                value === currentTab
                    ? ` wellLogging__label wellLogging__active`
                    : 'wellLogging__label',
        }
    }

    const showCurrentBlock = () => {
        switch (currentTab) {
            case '0':
                return (
                    <WellInfo
                        currentWellId={currentWellId}
                        currentWellBoreId={currentWellBoreId}
                    />
                )
            case '1':
                return <Logs />
            case '2':
                return <CoreBoxes />
            default:
                return ''
        }
    }
    return (
        <SimpleBar>
            <div className={'wellLogging'}>
                <div className={'wellLogging__tabs'}>
                    <div className={'wellLogging__tabPanel'}>
                        <div
                            {...setCurrentTabStyle('0')}
                            onClick={() => {
                                setCurrentTab('0')
                            }}
                        >
                            {getLocalizedText('well-info', t)}
                        </div>
                        <div
                            {...setCurrentTabStyle('1')}
                            onClick={() => {
                                setCurrentTab('1')
                            }}
                        >
                            {getLocalizedText('well-logging', t)}
                        </div>
                        <div
                            {...setCurrentTabStyle('2')}
                            onClick={() => {
                                setCurrentTab('2')
                            }}
                        >
                            {getLocalizedText('core-boxes', t)}
                        </div>
                    </div>
                </div>
                {currentWellId !== undefined && currentWellId !== null && (
                    <div className={'wellLogging__currentBlock'}>
                        {showCurrentBlock()}
                    </div>
                )}
            </div>
        </SimpleBar>
    )
}

export default WellLogging
