import React from 'react';

const DevIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="7" fill="white" stroke="#FF792D" strokeWidth="2"/>
            <path d="M8.63989 7.00004L7.24338 9.06531L5.67222 11.3889" stroke="#FF792D" strokeWidth="2"/>
            <path d="M11.5245 5.91777L10.1194 7.71056C9.78997 8.1309 9.18836 8.21819 8.75304 7.90881L7.84658 7.2646C7.39412 6.94303 7.29014 6.31449 7.61494 5.86435L8.93105 4.04034" stroke="#FF792D" strokeWidth="1.5"/>
        </svg>

    );
};

export default DevIcon;