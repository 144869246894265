import React, {FC} from 'react';
import {LangPanel} from "../langPanel/LangPanel";
import {UserMenuPanel} from "../../shared/userMenuPanel/UserMenuPanel";

interface IProps {
    currentTitle:string
}
const Header:FC<IProps> = ({currentTitle}) => {
    return (
        <div className="top_menu">
            <div className="header_user_box">
                <h2 className="header_user_box_head">{`${currentTitle ? currentTitle : ''}`}</h2>
            </div>
            <div className="top_menu-leftSide">
                <LangPanel />
                <UserMenuPanel />
            </div>
        </div>
    );
};

export default Header;