import {AxiosResponse} from "axios";
import {IWell, IWellInfo} from "../app/types/models/logging";
import {ACCESS_TOKEN} from "../utils/constants";
import {ResearchEntityRequest} from "../app/types/request";
import {IWellLogFile, UpdateWellLogFile} from "../app/types/models/well-log-file";
import {instance} from "../app/api";
import { IValidateStatementFileResponse } from "../app/types/dto/validate-file-dto";

export const wellsService ={
    getWells: async (projectId: number) => {
        return await instance
            .get<{ projectId: number }, AxiosResponse<IWell[]>>(
                `projects/${projectId}/wells/well/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    addWell: async (
        id: number,
        data: ResearchEntityRequest & { well_pad?: number },
    ) => {
        return await instance
            .post<{
                id: number
                data: ResearchEntityRequest & { well_pad?: number }
            },
                AxiosResponse<IWell>>(`projects/${id}/wells/well/`, data, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
    updateWell: async (projectId: number, wellId: number, data: IWellInfo,
    ) => {
        return await instance.patch<undefined, AxiosResponse<IWellInfo>
            >(`projects/${projectId}/wells/well/${wellId}/`, data, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        })
            .then((res) => res.data)
    },
    deleteWell: async (projectId: number, wellId: number) => {
        return await instance.delete(
            `projects/${projectId}/wells/well/${wellId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },
    getWellsInfo: async (projectId: number,wellId:number) => {
        return await instance.get<undefined, AxiosResponse<IWellInfo>>(
                `projects/${projectId}/wells/well/${wellId}/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    // getWellLogFiles:async (projectId: number,params:object)=>{
    //     return await instance.get<undefined, AxiosResponse<any>>(
    //         `projects/${projectId}/wells/well-log-file/`,
    //         {
    //             headers: {
    //                 Authorization: `Bearer ${JSON.parse(
    //                     localStorage.getItem(ACCESS_TOKEN),
    //                 )}`,
    //             },
    //             params
    //         },
    //     )
    //         .then((res) => {
    //             return res.data
    //         })
    // },
    getWellLogFileById:async (projectId: number,wellId:number)=>{
        return await instance.get<undefined, AxiosResponse<IWellLogFile>>(
            `projects/${projectId}/wells/well-log-file/${wellId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
            .then((res) => res.data)
    },
    addWellLogFile:async (projectId: number,data:FormData)=>{
        return await instance.post<undefined, AxiosResponse<IWellLogFile>>(
            `projects/${projectId}/wells/well-log-file/`,data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
            .then((res) => res.data)
    },
    updateWellLogFile:async (projectId: number,wellId:number,data:UpdateWellLogFile)=>{
        return await instance.patch<undefined, AxiosResponse<IWellLogFile>>(
            `projects/${projectId}/wells/well-log-file/${wellId}/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
            .then((res) => res.data)
    },
    validateWellLogFile:async (projectId:number,data:FormData)=>{
        return await instance.post<undefined,AxiosResponse<IValidateStatementFileResponse[]>>(
            `projects/${projectId}/wells/core/validate-statement-file/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
            .then((res) => res.data)
    },
    validateWellFile:async (projectId:number,data:FormData)=>{
        return await instance.post<undefined,AxiosResponse<IValidateStatementFileResponse[]>>(
            `projects/${projectId}/wells/well/validate-file/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
            .then((res) => res.data)
    },
    deleteWellLogFile:async (projectId: number,wellId:number)=>{
        return await instance.delete(
            `projects/${projectId}/wells/well-log-file/${wellId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
            .then((res) => res.data)
    },
}