import React, { FC, ReactElement } from 'react'
import './dotesMenuItem.css'

interface IProps {
    className?: string
    icon: ReactElement
    title: string

    handler?: () => void
}

export const DotesMenuItem: FC<IProps> = ({
    className,
    handler,
    icon,
    title,
}) => {
    return (
        <button
            className={
                className
                    ? `dotsMenu__list-btn ${className}`
                    : 'dotesMenu__list-btn'
            }
            onClick={handler}
        >
            {icon}
            <span>{title}</span>
        </button>
    )
}
