import React from 'react'

const LogoutIcon = () => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="28" height="28" fill="white" />
            <path
                d="M18 4.5L5.49998 4.50001C4.9477 4.50001 4.49999 4.94772 4.49999 5.50001L4.49999 22.5C4.49999 23.0522 4.9477 23.5 5.49999 23.5L18 23.5"
                stroke="black"
                strokeOpacity="0.62"
            />
            <path
                d="M23.8536 14.3536C24.0488 14.1583 24.0488 13.8417 23.8536 13.6464L20.6716 10.4645C20.4763 10.2692 20.1597 10.2692 19.9645 10.4645C19.7692 10.6597 19.7692 10.9763 19.9645 11.1716L22.7929 14L19.9645 16.8284C19.7692 17.0237 19.7692 17.3403 19.9645 17.5355C20.1597 17.7308 20.4763 17.7308 20.6716 17.5355L23.8536 14.3536ZM10.5 14.5H23.5V13.5H10.5V14.5Z"
                fill="black"
                fillOpacity="0.62"
            />
        </svg>
    )
}

export { LogoutIcon }
