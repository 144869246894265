import React, {FC} from 'react';
import {LangPanel} from "../../entities/langPanel/LangPanel";
import {SignButton} from "../signButton/SignButton";
import {useTranslation} from "react-i18next";
import './signHeader.css';
import {ISignHeaderProps} from "./types";

const SignHeader:FC<ISignHeaderProps> = ({buttonRoute}) => {
    const {t} = useTranslation()

    return (
        <div className="signBlock__top">
            <div className={'signBlock__top-col'}></div>
            <div className={'signBlock__top-col'}></div>
            <div className="signBlock__top-col signBlock__rightBox">
                <LangPanel noPhone={true} signMode/>
                <SignButton {...buttonRoute}/>
            </div>
            <div className={'signBlock__top--name'}>
                {t('laboratory-information-system').toString()}
            </div>
        </div>
    );
};

export default SignHeader;