import * as actions from './actions'
import * as researchEntitiesSelectors from './selectors'
import { slice } from './researchEntities-slice'

const researchEntitiesReducer = slice.reducer

const researchEntitiesActions = {
    ...slice.actions,
    ...actions,
}

export {
    researchEntitiesReducer,
    researchEntitiesActions,
    researchEntitiesSelectors,
}
