import { ICalendarGroup, ICalendarItem } from '../../../../../app/types/i-roadmap-calendar'
import { getDaysInMonth } from '../../../../../shared/calendarDetalizationRow/services'
import { generateQuarterInterval } from './get-quarter-percentage'

const quarters = [
    {
        quarter: 0,
        monthes: [0, 1, 2],
    },
    {
        quarter: 1,
        monthes: [3, 4, 5],
    },
    {
        quarter: 2,
        monthes: [6, 7, 8],
    },
    {
        quarter: 3,
        monthes: [9, 10, 11],
    },
]

const firstMonthInquarterIndexes = [0, 3, 6, 9]
const secondMonthInquarterIndexes = [1, 4, 7, 10]
const thirdMonthInquarterIndexes = [2, 5, 8, 11]

export function getDaysInQuarter(year, quarter) {
    // Определяем начальный месяц квартала (месяцы в JS начинаются с 0)
    const startMonth = (quarter - 1) * 3

    let daysInQuarter = 0

    // Проходим по каждому месяцу квартала и суммируем дни
    for (let i = 0; i < 3; i++) {
        const daysInMonth = new Date(year, startMonth + i + 1, 0).getDate()
        daysInQuarter += daysInMonth
    }

    return new Array(daysInQuarter).fill('').map((d, i) => i + 1)
}

const getQuartersList = (year: number) => {
    return quarters.map((quarter) => {
        return {
            orderIndex: quarter.quarter,
            monthes: quarter.monthes.map((m) => ({
                orderIndex: m,
                days: getDaysInMonth(year, m),
            })),
            days: getDaysInQuarter(year, quarter.quarter),
        }
    })
}

const getMonthPercentages = (data: {
    startDay: number
    endDay: number
    monthIndex: number
    daysCount: number
}) => {
    const { startDay, endDay, monthIndex, daysCount } = data
    let firstPercent = 0,
        endPercent = 0

    if (firstMonthInquarterIndexes.includes(monthIndex)) {
        firstPercent = ((startDay / daysCount) * 100) / 3
        endPercent = 0 ? 100 : ((endDay / daysCount) * 100) / 3
    } else if (secondMonthInquarterIndexes.includes(monthIndex)) {
        firstPercent = (((startDay / daysCount) * 100) / 3) * 2
        endPercent = 0 ? 100 : (((endDay / daysCount) * 100) / 3) * 2
    } else {
        firstPercent = (startDay / daysCount) * 100
        endPercent = 0 ? 100 : (endDay / daysCount) * 100
    }

    return {
        firstPercent,
        endPercent,
    }
}

export const getGroupsByYearDetalization = (
    calendarGroups: ICalendarGroup[],
    data: {
        priorityColor: string
        startDate: string | Date
        endDate: string | Date
    },
) => {

    let { startDate, endDate, priorityColor } = data

    startDate = new Date(startDate)
    endDate = new Date(endDate)

    const startYear = startDate.getFullYear()
    const startMonth = startDate.getMonth()
    const startDay = startDate.getDate()

    const endYear = endDate.getFullYear()
    const endMonth = endDate.getMonth()
    const endDay = endDate.getDate()

    const getQuarter = (quarter:ICalendarItem,interval:[number,number])=>{
        return {
            ...quarter,value: {
                ...quarter.value,
                interval: interval,priorityColor,
            },
        }
    }

    return calendarGroups.map((year) => {
        const currentYear = year?.year

        if (currentYear > startYear && currentYear < endYear) {
            return {
                ...year,
                list: year.list.map((quarter) => {
                    return getQuarter(quarter,[0,100])
                }),
            }
        } else if (currentYear === startYear && currentYear < endYear) {
            return {
                ...year,
                list: year.list.map((quarter) => {
                    const quarterIndex = quarter.value.orderIndex
                    const { startPercent, endPercent } = generateQuarterInterval({
                        startDay,
                        endDay: 31,
                        startMonth,
                        endMonth: 11,
                        quarter: quarterIndex,
                        year: year?.year,
                    })
                    return getQuarter(quarter,[startPercent, endPercent])
                }),
            }
        } else if (currentYear > startYear && currentYear === endYear) {
            return {
                ...year,
                list: year.list.map((quarter) => {
                    const quarterIndex = quarter.value.orderIndex
                    const { startPercent, endPercent } = generateQuarterInterval({
                        startDay: 1,
                        endDay,
                        startMonth: 0,
                        endMonth,
                        quarter: quarterIndex,
                        year: year?.year,
                    })
                    return getQuarter(quarter,[startPercent, endPercent])
                }),
            }
        } else if (currentYear > endYear || currentYear < startYear) {
            return year
        } else {
            return {
                ...year,
                list: year.list.map((quarter) => {
                    const quarterIndex = quarter.value.orderIndex
                    const { startPercent, endPercent } = generateQuarterInterval({
                        startDay,
                        endDay,
                        startMonth,
                        endMonth,
                        quarter: quarterIndex,
                        year: year?.year,
                    })
                    return getQuarter(quarter,[startPercent, endPercent])
                }),
            }
        }
    }) as ICalendarGroup[]
}
