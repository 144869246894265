import React, { useEffect, useState } from 'react'
import './userMenuPanel.css'
import { UserAvatar } from '../userAvatar/UserAvatar'
import ProfileMenu from '../profileMenu/ProfileMenu'

//todo - переделать, сделать логику без списков

const exceptionList = [
    'userMenuPanel',
    'user_name_box_letter',
    'userAvatar',
    'userAvatar__txt',
    'userAvatar__img',
]

const UserMenuPanel = () => {
    const [collapsed, setCollapsed] = useState(false)

    const clickHandler = (e) => {
        if (exceptionList.indexOf(e.target.className) === -1) {
            setCollapsed(false)
        }
    }
    useEffect(() => {
        addEventListener('click', clickHandler, false)
        return () => {
            removeEventListener('click', clickHandler, false)
        }
    }, [])
    return (
        <>
            <UserAvatar setCollapsed={setCollapsed} />
            {collapsed ? (
                <ProfileMenu />
            ) : null}
        </>
    )
}

export { UserMenuPanel }
