import { Tag } from "antd"
import { SystemTagItem } from "../../TagsList/types"
import React from "react"

export const renderSelectedTags =(tag:SystemTagItem, i:number,deleteFn:(ids:number[])=>void)=> {
    const tagElem = (
        <Tag
            style={{
                margin: '2px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
            }}
            closable
            onClose={(e) => {
                e.preventDefault()
                deleteFn([tag?.id])
            }}
        >
            <span style={{ lineHeight: '20px' }}>{tag?.name}</span>
        </Tag>
    )
    return (
        <span
            key={tag.name + i}
            style={{
                display: 'inline-block',
                margin: '2px 0',
            }}
        >
            {tagElem}
        </span>
    )
}