import React from 'react';
import './loggingProduction.css';

const LoggingProduction = () => {
    return (
        <div className='core-photo'>
            LoggingProduction
        </div>
    );
};

export default LoggingProduction;