import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { authService } from '../../services/auth-service'
import { routes } from '../../utils/constants'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { getQueryVariables } from '../../utils/helpers/getQueryVariable'
import { getValidText } from '../../utils/helpers/getValidText'

const ChangeEmail = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const urlParams = getQueryVariables()

    const confirmEmailChangeAsync = useCallback(async () => {
        try {
            const targetToken = urlParams?.find(
                (item) => item?.name === 'token',
            )?.value
            const targetEmail = urlParams?.find(
                (item) => item?.name === 'email',
            )?.value
            const targetUuid = urlParams?.find(
                (item) => item?.name === 'uid',
            )?.value
            const data = await authService.confirmEmailChange({
                email: getValidText(targetEmail),
                uid: getValidText(targetUuid),
                token: getValidText(targetToken),
            })
            if (data?.detail) {
                toast.success(t('email-changed-successfully').toString(), {
                    position: 'top-center',
                })
                navigate(routes.HOME)
            } else {
                navigate(routes.LOGIN)
            }
        } catch (e) {
            navigate(routes.LOGIN)
            errorsHandler(e, t)
        }
    }, [])

    useEffect(() => {
        confirmEmailChangeAsync()
    }, [window?.location?.href])

    return <div className="ChangeEmail"></div>
}

export default ChangeEmail
