import {IProject} from "../../../../../../app/types/models/project";

export enum SelectInputTypes{
    input='input',
    unit='unit',
    tags='tags'
}

export interface ISelectFieldData{
    en:any,
    ru:any
}




export type FormRequestType = {
    along_well_depth: string
    bottom: string
    comment: string
    formation_id: string
    geology: string
    lithology_description: string
    recovery: string
    sampling_point: string
    status: string
    storage_address: string
    top: string
    true_vertical_depth: string
    well: string
    wl_matching: boolean
}

export interface ILoggingInfoProps {
    currentProject: IProject
    currentWellId: null | number
    currentCoreId: null | number
    currentWellBoreId: null | number
}

