import React, { FC } from 'react'
import { getValidText } from '../../utils/helpers/getValidText';

interface IProps {
    className?: string
}

export const SearchIcon: FC<IProps> = ({ className }) => {
    return (
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={getValidText(className)}
        >
            <circle cx="5.5" cy="5.5" r="5" stroke="#364F6B" />
            <line
                x1="9.35355"
                y1="8.64645"
                x2="14.3536"
                y2="13.6464"
                stroke="#364F6B"
            />
        </svg>
    )
}