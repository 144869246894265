import React from 'react'

export const AgreeAcceptIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="7" fill="white" stroke="#3EC23B" strokeWidth="2"/>
            <path d="M3.99997 7.00005L8 10.0001L12.4997 6.00005" stroke="#3EC23B" strokeWidth="2"/>
        </svg>


    )
}
