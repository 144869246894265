import React, {FC, useEffect, useState} from 'react';
import ReactFlagsSelect from "react-flags-select";
import {useTranslation} from "react-i18next";
import {countriesEn, countriesRu} from "../../utils/countries";
import {ICountrySelectProps} from "./types";
import './countrySelect.css';

const CountrySelect:FC<ICountrySelectProps> = ({onChange,defaultValue,setSelectedData,selectedData}) => {
    const {t,i18n} = useTranslation()
    const [labels,setLabels] = useState({})
    const [selectData, setSelectData] = useState(defaultValue ? defaultValue : 'RU')

    const onSelect = (code) => {
        setSelectData(code)
        onChange(code)

    }

    useEffect(()=>{
        const keys = new Set(Object.keys(countriesEn))
        const list:any = {}
        keys.forEach((item:any)=>{
            list[item] = i18n.language === 'en' ? countriesEn[item] : countriesRu[item]
        })
        setLabels(list)
    },[i18n.language])

    return (
        <div className={'country-select'}>
            <ReactFlagsSelect
                selected={selectedData ? selectedData : selectData as string}
                className={'flagsSelect'}
                //@ts-ignore
                customLabels={labels}
                //@ts-ignore
                defaultCountry="RU"
                searchPlaceholder={t('search')}
                onSelect={onSelect}
                searchable
                fullWidth
                showOptionLabel
            />
        </div>
    );
};

export default CountrySelect;