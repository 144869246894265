import React from 'react'
import {
    ACOUSTIC_VELOCITY,
    BIO,
    BULK_MODULUS,
    CAPACITY,
    COMPRESSIBILITY,
    COMPRESSIVE_STRENGTH,
    CONDUCTIVITY,
    DIFFUSIVITY,
    ELASTIC_LIMIT,
    POISSON_RATIO, PYROLYSIS,
    SHEAR_MODULUS,
    ULTIMATE_COMPRESSIVE_STRENGTH,
    YIELD_STRENGTH,
    YOUNG_MODULE,
} from '../../../../../../../utils/constants'

export const changeSettingsListByParams = (
    activeList,
    toggleItemHandler,
    setActiveGroupItem,
    t,
) => {
    switch (activeList) {
        case 1: {
            return (
                <ul className="coreStudies__menuItems">
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler('porosity')}
                        onClick={() => setActiveGroupItem('porosity')}
                    >
                        {t('porosity')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler('permeability')}
                        onClick={() => setActiveGroupItem('permeability')}
                    >
                        {t('permeability')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler('saturation')}
                        onClick={() => setActiveGroupItem('saturation')}
                    >
                        {t('saturation')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler('oilDisplasment')}
                        onClick={() => setActiveGroupItem('oilDisplasment')}
                    >
                        {t('oil-displasment')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler('carbonateness')}
                        onClick={() => setActiveGroupItem('carbonateness')}
                    >
                        {t('carbonateness')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler('density')}
                        onClick={() => setActiveGroupItem('density')}
                    >
                        {t('density')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler('capillaryCurve')}
                        onClick={() => setActiveGroupItem('capillaryCurve')}
                    >
                        {t('capillary-curve')}
                    </li>
                </ul>
            )
        }
        case 2: {
            return (
                <ul className="coreStudies__menuItems">
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler(YOUNG_MODULE)}
                        onClick={() => setActiveGroupItem(YOUNG_MODULE)}
                    >
                        {t('young-modulus')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler(POISSON_RATIO)}
                        onClick={() => setActiveGroupItem(POISSON_RATIO)}
                    >
                        {t('poisson-ratio')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler(ACOUSTIC_VELOCITY)}
                        onClick={() => setActiveGroupItem(ACOUSTIC_VELOCITY)}
                    >
                        {t('acoustic-velocity')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler(COMPRESSIBILITY)}
                        onClick={() => setActiveGroupItem(COMPRESSIBILITY)}
                    >
                        {t('compressibility')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler(SHEAR_MODULUS)}
                        onClick={() => setActiveGroupItem(SHEAR_MODULUS)}
                    >
                        {t('shear-modulus')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler(YIELD_STRENGTH)}
                        onClick={() => setActiveGroupItem(YIELD_STRENGTH)}
                    >
                        {t('yield-strength')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler(BIO)}
                        onClick={() => setActiveGroupItem(BIO)}
                    >
                        {t('bio')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler(BULK_MODULUS)}
                        onClick={() => setActiveGroupItem(BULK_MODULUS)}
                    >
                        {t('bulk-modulus')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler(COMPRESSIVE_STRENGTH)}
                        onClick={() => setActiveGroupItem(COMPRESSIVE_STRENGTH)}
                    >
                        {t('compressive-strength')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler(ULTIMATE_COMPRESSIVE_STRENGTH)}
                        onClick={() =>
                            setActiveGroupItem(ULTIMATE_COMPRESSIVE_STRENGTH)
                        }
                    >
                        {t('ultimate-compressive-strength')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler(ELASTIC_LIMIT)}
                        onClick={() => setActiveGroupItem(ELASTIC_LIMIT)}
                    >
                        {t('elastic-limit')}
                    </li>
                </ul>
            )
        }
        case 3: {
            return (
                <ul className="coreStudies__menuItems">
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler(CONDUCTIVITY)}
                        onClick={() => setActiveGroupItem(CONDUCTIVITY)}
                    >
                        {t('conductivity')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler(DIFFUSIVITY)}
                        onClick={() => setActiveGroupItem(DIFFUSIVITY)}
                    >
                        {t('diffusivity')}
                    </li>
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler(CAPACITY)}
                        onClick={() => setActiveGroupItem(CAPACITY)}
                    >
                        {t('capacity')}
                    </li>
                </ul>
            )
        }
        case 4: {
            return (
                <ul className="coreStudies__menuItems">
                    <li
                        className="coreStudies__menuText"
                        style={toggleItemHandler(PYROLYSIS)}
                        onClick={() => setActiveGroupItem(PYROLYSIS)}
                    >
                        {t(`${PYROLYSIS}`)}
                    </li>
                </ul>
            )
        }
        default: {
            return (
                <ul className="coreStudies__menuItems">
                    <li>default value</li>
                </ul>
            )
        }
    }
}
