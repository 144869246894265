import React from 'react'

export const StatusIcon = () => {
    return (
        <svg
            style={{ transform: 'translateY(1px)' }}
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="29" height="29" rx="1" fill="white" />
            <path
                d="M7 6.5H22C22.8284 6.5 23.5 7.17157 23.5 8V23C23.5 23.8284 22.8284 24.5 22 24.5H7C6.17157 24.5 5.5 23.8284 5.5 23V8C5.5 7.17157 6.17157 6.5 7 6.5Z"
                fill="#B7B6FF"
                stroke="#B2AFAF"
            />
            <path
                d="M11 16L13.6947 18.6947C14.1215 19.1215 14.8264 19.0757 15.1944 18.5973L17 16.25L19.5 13"
                stroke="white"
                strokeWidth="2"
            />
            <rect x="8" y="4" width="13" height="6" rx="1" fill="#B2AFAF" />
        </svg>
    )
}
