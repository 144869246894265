import { Button, Form } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { XIcon } from '../../../../image_files/icons/XIcon'
import { projectsService } from '../../../../services/projects-service.'
import { wellsService } from '../../../../services/wells-service'
import CountrySelect from '../../../../shared/countrySelect/CountrySelect'
import InputInLog from '../../../../shared/input/Input'
import {
    selectCurrentPad,
    selectCurrentWell,
    setCurrentPads,
    setCurrentWells,
    setProject,
} from '../../../../store/currentProject/actions'
import { projectsActions, projectsSelectors } from '../../../../store/projects'
import { setPads, setWells } from '../../../../store/projects/actions'
import { showNotice } from '../../../../utils/helpers/showNotice'
import './newProject.css'

const NewProject = () => {
    const { t, i18n } = useTranslation()
    const [isChangedField, setIsChangedField] = useState(false)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const projects = useSelector(projectsSelectors.selectProjects)
    const navigate = useNavigate()
    const { addProject } = projectsActions
    const [selectData, setSelectData] = useState('RU')

    const cancelHandler = (e) => {
        e.preventDefault()
        navigate(-1)
    }
    const onSelect = (value: string) => setSelectData(value)

    //Form
    const onFormFinish = useCallback(
        async (values) => {
            try {
                const projectRes = await projectsService.addNewProject({
                    ...values,
                    name:values?.name.trim(),
                    country: selectData,
                })

                dispatch(addProject({ project: projectRes }))
                dispatch(setProject({project:projectRes}))

                const padRes = await projectsService.addPad(projectRes?.id, {
                    name: 'pad 1',
                })

                dispatch(
                    setPads({
                        id: projectRes?.id,
                        pads: [padRes],
                    }),
                )

                dispatch(
                    setCurrentPads({
                        pads: [padRes],
                    }),
                )

                dispatch(selectCurrentPad(padRes))

                const wellRes = await wellsService.addWell(projectRes?.id, {
                    name: 'well 1',
                    well_pad: padRes?.id,
                })

                dispatch(
                    setWells({
                        id: projectRes?.id,
                        padId: padRes?.id,
                        wells: [wellRes],
                    }),
                )
                dispatch(setCurrentWells({wells:[wellRes]}))
                dispatch(selectCurrentWell(wellRes))

                form.resetFields()
                navigate(`/logging/${projectRes?.id}`)
                showNotice('new-project-added-successfully', t)

            } catch (error) {
                console.log(error)
                toast.error(error?.message, { position: 'top-center' })
            }
        },
        [form, selectData, i18n],
    )

    useEffect(() => {
        if (
            form.getFieldsError(['name', 'reservoir']) &&
            form
                .getFieldsError(['name', 'reservoir'])
                .filter((item) => item.errors.length > 0).length > 0
        ) {
            form.submit()
        }
    }, [i18n.language])

    return (
        <div className="new-project">
            <div className="new-project-container br-5">
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    className={'new-project__form'}
                    layout="vertical"
                >
                    <Form.Item
                        name="name"
                        label={`${t('project-name')}`}
                        rules={[
                            {
                                required: true,
                                message: t('project-name-required'),
                            },
                        ]}
                        className={'new-project__formItem'}
                    >
                        <InputInLog
                            className={'new-project__formInput'}
                            onChange={() => setIsChangedField(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="reservoir"
                        label={`${t('resorvior')}`}
                        rules={[
                            {
                                required: true,
                                message: t('resorvoir-required'),
                            },
                        ]}
                        className={'new-project__formItem'}
                    >
                        <InputInLog
                            className={'new-project__formInput'}
                            onChange={() => setIsChangedField(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="company_customer"
                        label={`${t(
                            'company-customer',
                        )}`}
                        className={'new-project__formItem'}
                    >
                        <InputInLog
                            className={'new-project__formInput'}
                            onChange={() => setIsChangedField(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="contractor"
                        label={`${t('assignee')}`}
                        className={'new-project__formItem'}
                    >
                        <InputInLog
                            className={'new-project__formInput'}
                            onChange={() => setIsChangedField(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="#"
                        label={`${t('object-country')}`}
                        className={'new-project__formItem'}
                    >
                        <CountrySelect
                            onChange={onSelect}
                            selectedData={selectData}
                            setSelectedData={setSelectData}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            className={'new-project__formBtn'}
                            disabled={!isChangedField}
                            htmlType="submit"
                        >
                            {`${t('continue')}`}
                        </Button>
                    </Form.Item>
                </Form>
                {projects.length > 0 && (
                    <button
                        className={'new-project__cancelBtn'}
                        onClick={cancelHandler}
                    >
                        <XIcon />
                    </button>
                )}
            </div>
        </div>
    )
}

export { NewProject }

