import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { membersService } from '../services/memembers-service'
import { selectCurrentProject } from '../store/currentProject/selectors'
import { setMembers, setStorageMembers } from '../store/members/actions'
import { errorsHandler } from '../utils/helpers/errors/errors-hendler'
import { useAppDispatch } from './redux-hooks'

export const useGetMembers = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const fetchMembers = async (id: number) => {
        try {
            const data = await membersService.getMembers(id)
            const storageMembers = JSON.parse(sessionStorage.getItem('members'))
            if (!storageMembers || storageMembers.length === 0) {
                dispatch(setMembers({ members: data }))
            }
            if (
                storageMembers.length === data.length &&
                data.filter((member, index) => {
                    return (
                        member.user.id === storageMembers[index].user.id &&
                        member.user.full_name ===
                            storageMembers[index].user.full_name &&
                        member.user.email === storageMembers[index].user.email
                    )
                }).length === storageMembers.length
            ) {
                dispatch(setStorageMembers({ members: storageMembers }))
            } else {
                dispatch(setMembers({ members: data }))
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const refetch = (id: number) => {
        fetchMembers(id)
    }

    return {
        refetch,
    }
}
