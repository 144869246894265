import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import {
    IExtendedWell,
    IWell,
} from '../../../../../../app/types/models/logging'
import { AddElementIcon } from '../../../../../../image_files/icons/AddElementIcon'
import { projectsService } from '../../../../../../services/projects-service.'
import { wellBoreService } from '../../../../../../services/wellBore-service'
import { wellsService } from '../../../../../../services/wells-service'
import { EditableSpan } from '../../../../../../shared/EditableSpan/EditableSpan'
import {
    currentProjectActions,
    currentProjectSelectors,
} from '../../../../../../store/currentProject'
import {
    selectCurrentWell,
    selectCurrentWellBore,
    setCurrentCore,
    setCurrentCores,
    setCurrentWellBores,
    setCurrentWells,
    setLoadingCurrentItemStatus,
} from '../../../../../../store/currentProject/actions'
import { selectCurrentProject } from '../../../../../../store/currentProject/selectors'
import { projectsActions } from '../../../../../../store/projects'
import { errorsHandler } from '../../../../../../utils/helpers/errors/errors-hendler'
import { showNotify } from '../../../../../../utils/helpers/showNotice'
import LoggingProductSceleton from '../logging-product-sceleton/LoggingProductSceleton'
import { IWellsProps } from './types'
import './wells.css'

const Wells: FC<IWellsProps> = ({
    openValue,

    toOpenInput,
}) => {
    const [showWellBoreInput, setShowWellBoreInput] = useState(false)
    const [loading, setLoading] = useState(false)
    const [wellValue, setWellValue] = useState('')
    const [wellBoreValue, setWellBoreValue] = useState('')
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { updateWell, updateWellBore } = projectsActions
    const {
        updateCurrentWell,
        updateCurrentWellBore,
    } = currentProjectActions
    const wells = useSelector(currentProjectSelectors.selectCurrentWells)
    const wellBores = useSelector(
        currentProjectSelectors.selectCurrentWellBores,
    )
    const currentPad = useSelector(currentProjectSelectors.selectCurrentPad)
    const currentProject = useSelector(selectCurrentProject)
    const wellsLoadingStatus = useSelector(currentProjectSelectors.selectLoadingCurrentWellsStatus)
    const currentWell = useSelector(currentProjectSelectors.selectCurrentWell)
    const currentWellBore = useSelector(currentProjectSelectors.selectCurrentWellBore)

    //асинхронные обработчики
    const handleAddWell = async (e) => {
        try {
            if (e.keyCode === 13) {
                const data = await wellsService.addWell(currentProject?.id, {
                    name: wellValue,
                    well_pad: currentPad?.id,
                })
                dispatch(setCurrentWells({wells:[data,...wells]}))
                dispatch(selectCurrentWell(data))
                setWellValue('')
                toOpenInput(false)
                showNotify(t('data-saved-successfully'))
            }
        } catch (e) {
            toOpenInput(false)
            setWellValue('')
            errorsHandler(e, t)
        }
    }

    const handleAddWellBore = async (e) => {
        try {
            if (e.keyCode === 13) {
                const data = await wellBoreService.addWellBore(
                    currentProject?.id,
                    {
                        name: wellBoreValue,
                        well: currentWell?.id,
                    },
                )
                dispatch(setCurrentWellBores({wellBores:[data,...wellBores]}))
                setShowWellBoreInput(false)
                setWellBoreValue('')
                showNotify(t('data-saved-successfully'))
            }
        } catch (e) {
            setWellBoreValue('')
            errorsHandler(e, t)
            setShowWellBoreInput(false)
        }
    }

    const handleUpdateWell = async (value) => {
        try {
            dispatch(selectCurrentWellBore(null))
            const data = await wellsService.updateWell(
                currentProject?.id,
                currentWell?.id,
                { name: value },
            )
            dispatch(
                updateCurrentWell({ id: currentProject?.id, data:{...currentWell,name: data.name} }),
            )
            dispatch(
                updateWell({
                    id: currentProject?.id,
                    wellId: currentWell?.id,
                    padId: currentPad?.id,
                    name: data.name,
                }),
            )
        } catch (e) {
            console.log(e)
            errorsHandler(e, t)
        }
    }

    const handleUpdateWellBore = async (value, wellId) => {
        try {
            const data = await wellBoreService.updateWellBore(
                currentProject?.id,
                currentWellBore?.id,
                {
                    name: value,
                    well: wellId,
                },
            )
            dispatch(
                updateCurrentWellBore({
                    id: currentWellBore?.id,
                    data:{
                        ...currentWellBore,
                        name: data.name,
                    }
                }),
            )
            dispatch(
                updateWellBore({
                    id: currentProject?.id,
                    wellId: wellId,
                    wellBoreId: currentWellBore?.id,
                    padId: currentPad?.id,
                    name: data.name,
                }),
            )
        } catch (e) {
            console.log(e)
            errorsHandler(e, t)
        }
    }

    const onBlurWellsHandler = () => {
        toOpenInput(false)
    }
    const onBlurWellBoresHandler = () => {
        setShowWellBoreInput(false)
    }

    const wellBoreHandler = (wellId) => {
        const targetWell = wells?.find((item) => item.id === wellId)
        targetWell && dispatch(selectCurrentWell(targetWell))
        dispatch(selectCurrentWellBore(null))
        setShowWellBoreInput(!showWellBoreInput)
    }

    const onChangeWellHandler = (e) => {
        setWellValue(e.target.value)
    }

    const onChangeWellBoreHandler = (e) => {
        setWellBoreValue(e.currentTarget.value)
    }

    const iconHandler = (itemWell: IExtendedWell, e: any) => {
        e.stopPropagation()
        e.preventDefault()
        wellBoreHandler(itemWell?.id)
    }

    const handleClickSetCurrentWellBore = async (e, itemWellBore) => {
        e.stopPropagation()
        const targetWellBore = wellBores?.find(
            (item) => item?.id === itemWellBore.id,
        )
        targetWellBore && dispatch(selectCurrentWellBore(targetWellBore))
        dispatch(setCurrentCore(null))
        dispatch(selectCurrentWell(null))
        await fetchCores()
    }

    const filteredWells = useMemo(() => {
        return wells.filter((item) => item?.well_pad === currentPad?.id)
    }, [currentPad?.id])

    const fetchCores = async () => {
        try {
            setLoading(true)
            dispatch(
                setLoadingCurrentItemStatus({
                    status: true,
                    type: 'currentCores',
                }),
            )
            const data = await projectsService.getCores(
                Number(currentProject?.id),
            )
            dispatch(setCurrentCores({ cores: data }))
            setLoading(false)
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentCores',
                }),
            )
        } catch (e) {
            setLoading(false)
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentCores',
                }),
            )
            errorsHandler(e, t)
        }
    }

    const handleClickOnWell = async (data: IWell) => {
        dispatch(selectCurrentWell(data))
        dispatch(selectCurrentWellBore(null))
        dispatch(setCurrentCore(null))
        await fetchCores()
    }

    const getListClass = () =>{
        if(wellsLoadingStatus){
            return 'projects__elements-wellList projects__elements-wellList-loading'
        }
        return 'projects__elements-wellList'
    }

    useEffect(() => {
        if (wells && wells.length > 0) {
            dispatch(selectCurrentWell(filteredWells[0]))
            dispatch(selectCurrentWellBore(null))
        }
    }, [currentPad?.id])

    const showItems = () => {
        if (wellsLoadingStatus) {
            return <LoggingProductSceleton />
        } else {
            return (
                <>
                    {wells &&
                        wells
                            .filter((item) => item?.well_pad === currentPad?.id)
                            .map((itemWell) => (
                                <li
                                    className={'projects__elements-well'}
                                    key={itemWell?.id}
                                    onClick={() => handleClickOnWell(itemWell)}
                                >
                                    <div
                                        className={
                                            currentWell?.id === itemWell?.id
                                                ? 'projects__elements-wellContainer activeElement'
                                                : 'projects__elements-wellContainer'
                                        }
                                    >
                                        <AddElementIcon
                                            title={t(
                                                'add-wellbore',
                                            )}
                                            onClick={(e) =>
                                                iconHandler(itemWell, e)
                                            }
                                            className={
                                                'projects__elements-wells-plusIcon cursorPointer'
                                            }
                                        />
                                        <EditableSpan
                                            title={
                                                itemWell?.name === 'well 1'
                                                    ? t(
                                                          'well-1',
                                                      )
                                                    : itemWell?.name
                                            }
                                            inputClassName={
                                                'projects__elements-editInput projects__elements-wells-editableInput'
                                            }
                                            spanClassName={
                                                'projects__elements-editSpan projects__elements-wells-editableSpan'
                                            }
                                            handler={handleUpdateWell}
                                        />
                                    </div>
                                    {showWellBoreInput &&
                                        currentWell?.id === itemWell?.id && (
                                            <div
                                                className={
                                                    'projects__elements__inputWrap'
                                                }
                                            >
                                                <input
                                                    type="text"
                                                    className="projects__elements-addInput wellBoreInput"
                                                    autoFocus
                                                    onChange={
                                                        onChangeWellBoreHandler
                                                    }
                                                    onBlur={
                                                        onBlurWellBoresHandler
                                                    }
                                                    onKeyDown={
                                                        handleAddWellBore
                                                    }
                                                />
                                            </div>
                                        )}
                                    <ul className="projects__elements-wellBoresList">
                                        {wellBores &&
                                            wellBores.map((itemWellBore) =>
                                                itemWellBore?.well ===
                                                itemWell?.id ? (
                                                    <li
                                                        key={itemWellBore.id}
                                                        className={
                                                            currentWellBore?.id ===
                                                            itemWellBore?.id
                                                                ? 'projects__elements-wellBoreItem activeElement'
                                                                : 'projects__elements-wellBoreItem'
                                                        }
                                                        onClick={(e) =>
                                                            handleClickSetCurrentWellBore(
                                                                e,
                                                                itemWellBore,
                                                            )
                                                        }
                                                    >
                                                        <EditableSpan
                                                            inputClassName={
                                                                'projects__elements-editInput'
                                                            }
                                                            spanClassName={
                                                                'projects__elements-wellBore-editSpan'
                                                            }
                                                            title={
                                                                itemWellBore?.name
                                                            }
                                                            handler={(value) =>
                                                                handleUpdateWellBore(
                                                                    value,
                                                                    itemWell.id,
                                                                )
                                                            }
                                                        />
                                                    </li>
                                                ) : null,
                                            )}
                                    </ul>
                                </li>
                            ))}
                </>
            )
        }
    }

    return (
        <div className="projects__elements-wells">
            <ul
                className={getListClass()}
            >
                <SimpleBar className={'projects__elements-wells-sb'}>
                    <div>
                        {openValue && (
                            <div className={'projects__elements__inputWrap'}>
                                <input
                                    type="text"
                                    className="projects__elements-addInput "
                                    autoFocus
                                    onChange={onChangeWellHandler}
                                    onKeyDown={handleAddWell}
                                    onBlur={onBlurWellsHandler}
                                />
                            </div>
                        )}
                        {showItems()}
                    </div>
                </SimpleBar>
            </ul>
        </div>
    )
}

export { Wells }

