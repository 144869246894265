import { createSlice } from '@reduxjs/toolkit'
import { IStatus, ITask } from '../../app/types/models/tasks'
import {
    addPanel,
    addTasksToPanel,
    deletePanel,
    deletePanelTask,
    editPanelName,
    moveAllTasks,
    moveTasksBetweenPanels,
    setPanels,
    setPanelsWithoutStatus,
    updatePanelsOrder,
    updateTasksOrder,
} from './actions'

export interface InitialState {
    panels: any
    panelsOrder: null | any[]
    withoutStatusPanel: (IStatus & { tasks?: ITask[] }) | null
}

const initialState: InitialState = {
    panels: [],
    panelsOrder: [],
    withoutStatusPanel: null,
}

export const slice = createSlice({
    name: 'panels',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setPanels, (state, action) => {
            state.panels = action.payload.panels
            state.panelsOrder = action.payload.panelsOrder
        })
        builder.addCase(setPanelsWithoutStatus, (state, action) => {
            state.withoutStatusPanel = action.payload.panel
        })
        builder.addCase(updatePanelsOrder, (state, action) => {
            state.panelsOrder = action.payload.panelsOrder
        })
        builder.addCase(addPanel, (state, action) => {
            state.panels = {
                ...state.panels,
                [action.payload.position]: action.payload.panel,
            }
            state.panelsOrder = action.payload.panelsOrder
        })
        builder.addCase(deletePanel, (state, action) => {
            delete state.panels[action.payload.position]
            state.panelsOrder = action.payload.panelsOrder
        })
        builder.addCase(addTasksToPanel, (state, action) => {
            if (action.payload.position === 'withoutStatus') {
                state.withoutStatusPanel = {
                    ...state.withoutStatusPanel,
                    tasks: action.payload.tasks,
                }
            } else {
                state.panels = {
                    ...state.panels,
                    [action.payload.position]: {
                        ...state.panels[action.payload.position],
                        tasks: action.payload.tasks,
                        tasksOrder: action.payload.tasksOrder,
                    },
                }
            }
        })
        builder.addCase(updateTasksOrder, (state, action) => {
            state.panels = {
                ...state.panels,
                [action.payload.position]: {
                    ...state.panels[action.payload.position],
                    tasks: action.payload.tasks,
                    tasksOrder: action.payload.tasksOrder,
                },
            }
        })
        builder.addCase(editPanelName, (state, action) => {
            state.panels = {
                ...state.panels,
                [action.payload.position]: {
                    ...state.panels[action.payload.position],
                    name: action.payload.name,
                },
            }
        })
        builder.addCase(moveAllTasks, (state, action) => {
            state.panels = {
                ...state.panels,
                [action.payload.currentPosition]: {
                    ...state.panels[action.payload.currentPosition],
                    tasksOrder: [],
                    tasks: [],
                },
                [action.payload.targetPosition]: {
                    ...state.panels[action.payload.targetPosition],
                    tasksOrder: [
                        ...state.panels[action.payload.targetPosition]
                            .tasksOrder,
                        ...action.payload.tasksOrder,
                    ],
                    tasks: [
                        ...state.panels[action.payload.targetPosition].tasks,
                        ...action.payload.tasks,
                    ],
                },
            }
        })
        builder.addCase(deletePanelTask, (state, action) => {
            if (action.payload.position === 'withoutStatus') {
                state.withoutStatusPanel = {
                    ...state.withoutStatusPanel,
                    tasks: state?.withoutStatusPanel?.tasks?.filter(
                        (task) => task.id !== action.payload.taskId,
                    ),
                }
            } else {
                const filteredTasks = state.panels[
                    action.payload.position
                ].tasks.filter((task) => task.id !== action.payload.taskId)
                const filteredTasksOrder = state.panels[
                    action.payload.position
                ].tasksOrder.filter(
                    (order) => order !== action.payload.status_position,
                )
                state.panels = {
                    ...state.panels,
                    [action.payload.position]: {
                        ...state.panels[action.payload.position],
                        tasks: filteredTasks,
                        tasksOrder: filteredTasksOrder,
                    },
                }
            }
        })
        builder.addCase(moveTasksBetweenPanels, (state, action) => {
            state.panels = {
                ...state.panels,
                [action.payload.startPosition]: action.payload.startPanel,
                [action.payload.finishPosition]: action.payload.finishPanel,
            }
        })
    },
})
