import React from 'react'

export const TagsIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_19_281)">
                <path
                    d="M14.087 23.0171L9.92108 18.9548L18.8679 9.81853L23.1811 14.0458L14.087 23.0171Z"
                    fill="#8AB4F8"
                />
                <path
                    d="M14.1047 5.05668L9.954 0.818527L0.8775 9.80896C-0.2925 10.979 -0.2925 12.8758 0.8775 14.0471L9.8175 23.0458L14.0447 19.0912L7.23337 11.9281L14.1047 5.05668Z"
                    fill="#4285F4"
                />
                <path
                    d="M23.1211 9.87844L14.1211 0.878439C12.9498 -0.292874 11.0502 -0.292874 9.87892 0.878439C8.70751 2.04984 8.70751 3.94941 9.87892 5.12081L18.8789 14.1208C20.0502 15.2921 21.9498 15.2921 23.1211 14.1208C24.2925 12.9494 24.2925 11.0498 23.1211 9.87844Z"
                    fill="#8AB4F8"
                />
                <path
                    d="M11.9311 24.0004C13.5503 24.0004 14.863 22.6877 14.863 21.0685C14.863 19.4493 13.5503 18.1367 11.9311 18.1367C10.3119 18.1367 8.99927 19.4493 8.99927 21.0685C8.99927 22.6877 10.3119 24.0004 11.9311 24.0004Z"
                    fill="#246FDB"
                />
            </g>
            <defs>
                <clipPath id="clip0_19_281">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
