import React, {FC} from 'react'
import './userAvatar.css'
import {useSelector} from 'react-redux'
import {userSelectors} from '../../store/user'
import {getCorrectFirstLetter} from "../../services/get-correct-first-letter";

interface IProps {
    setCollapsed?: (value: boolean) => void
}

export const UserAvatar: FC<IProps> = ({ setCollapsed }) => {
    const storageMembers = JSON.parse(sessionStorage.getItem('members'))
    const user = useSelector(userSelectors.selectUser)

    const targetMember =
        storageMembers &&
        storageMembers.find((member) => {
            if (
                member?.user?.email === user?.email ||
                member?.user?.full_name === user?.first_name
            ) {
                return member
            }
        })
    const currentColor =
        storageMembers && storageMembers.length > 0 && targetMember
            ? targetMember.color
            : '#3FC1C9'

    return (
        <div
            className="userAvatar"
            onClick={setCollapsed ? () => setCollapsed(true) : null}
        >
            {user && user?.avatar !== null ? (
                <img
                    src={typeof user?.avatar ==='string' ? user?.avatar : user?.avatar?.small}
                    className={'userAvatar__img'}
                    alt={'user image'}
                />
            ) : (
                <p
                    className={'userAvatar__txt'}
                    style={{ backgroundColor: currentColor }}
                >
                    {getCorrectFirstLetter(user)}
                </p>
            )}
        </div>
    )
}
