import React, { SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ISelectOption } from '../../../../app/types/common'
import { RoadMapDetalizationTypes } from '../../../../app/types/enums'
import { ITask, ITasksFilterParams } from '../../../../app/types/models/tasks'
import { CalendarDetalizationRow } from '../../../../shared/calendarDetalizationRow/CalendarDetalizationRow'
import { Pagination } from '../../../../shared/Pagination/Pagination'
import RoadMapCalendarItem from '../roadMapCalendarItem/RoadMapCalendarItem'
import RoadMapCalendarTop from '../roadMapCalendarTop/RoadMapCalendarTop'
import RoadMapItems from '../roadMapItems/RoadMapItems'

const RoadMapTasks = ({
    tasks,
    count,
    isLoading,
    tagsOptions,
    filterParams,
    detalizationData,
    setFilterParams,
    setAddTaskModalData,
}: {
    tasks: ITask[]
    count: number
    tagsOptions: ISelectOption[]
    filterParams: ITasksFilterParams
    detalizationData: {
        detalization: RoadMapDetalizationTypes
        startPeriod: string
        endPeriod: string
    }
    isLoading: boolean
    setFilterParams: (data: SetStateAction<ITasksFilterParams>) => void,
    setAddTaskModalData:(data:SetStateAction<{isOpen?:boolean,task?:ITask}>)=>void
}) => {
    const { t } = useTranslation()
    const block1Ref = useRef(null)
    const block2Ref = useRef(null)
    const [isSyncing, setIsSyncing] = useState(false)

    const [paginationTrigger, setPaginationTrigger] = useState(0)
    const [tasksHeightList, setTasksHeightList] = useState<
        { id: number; height: number }[]
    >([])

    const handleChangePagination = async (params: {
        limit: number | string
        offset: number
    }) => {
        setFilterParams((prev) => ({ ...prev, ...params }))
    }

    const handleScroll1 = () => {
        if (!isSyncing) {
            setIsSyncing(true)
            block2Ref.current.scrollLeft = block1Ref.current.scrollLeft
            setIsSyncing(false)
        }
    }

    const handleScroll2 = () => {
        if (!isSyncing) {
            setIsSyncing(true)
            block1Ref.current.scrollLeft = block2Ref.current.scrollLeft
            setIsSyncing(false)
        }
    }

    const showCalendarItems = () => {
        return tasks?.map((el, i) => (
            <RoadMapCalendarItem
                item={el}
                blockHeight={
                    tasksHeightList.find((val) => val?.id === el?.id)?.height
                }
                detalizationData={detalizationData}
            />
        ))
    }

    useEffect(() => {
        if (tasks) {
            setTasksHeightList(
                tasks.map((el) => ({ id: el?.id, height: undefined })),
            )
        }
    }, [])

    return (
        <>
            {tasks.length > 0 ? (
                <div className="roadMap-workSpace">
                    <div className="roadMap-workSpace-top">
                        <div className="roadMap-workSpace-column">
                            <RoadMapCalendarTop setAddTaskModalData={setAddTaskModalData}/>
                        </div>
                        <div
                            className="roadMap-workSpace-column hide-scrollbar"
                            ref={block1Ref}
                            onScroll={handleScroll1}
                        >
                            <div className="roadMap-workSpace-column-top">
                                <CalendarDetalizationRow
                                    type="header"
                                    detalizationData={detalizationData}
                                    className="roadMap-calendar-groups"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="roadMap-workSpace-bottom">
                        <div className="roadMap-workSpace-column">
                            <>
                                <div className="roadMap-tasks">
                                    <RoadMapItems
                                        items={tasks}
                                        detalizationData={detalizationData}
                                        tagsOptions={tagsOptions}
                                        saveHeightList={setTasksHeightList}
                                    />
                                    <div className="roadMap-pagination">
                                        {count >= 20 && (
                                            <Pagination
                                                goToFirstPageTrigger={
                                                    paginationTrigger
                                                }
                                                onChange={
                                                    handleChangePagination
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </>
                        </div>
                        <div
                            className="roadMap-workSpace-column"
                            ref={block2Ref}
                            onScroll={handleScroll2}
                        >
                            <ul className="calendar-items">
                                {showCalendarItems()}
                            </ul>
                        </div>
                    </div>
                </div>
            ) : (
                !isLoading && (
                    <div className="font-14-normal pt-10">
                        {Object.keys(filterParams).length === 1
                            ? t('tasks-absent-message')
                            : t('filtered-tasks-absent-message')}
                    </div>
                )
            )}
        </>
    )
}

export default RoadMapTasks
