import TextArea from 'antd/lib/input/TextArea'
import React, { FC, useState } from 'react'
import InputInLog from '../../../../../../shared/input/Input'
import './addCoreBoxPhotoItem.css'
import { IAddCoreBoxPhotoItemProps } from './types'

const AddCoreBoxPhotoItem: FC<IAddCoreBoxPhotoItemProps> = (props) => {
    const { title, type, name, value,rules, radioItems = [],isShowError,setFieldError, onChange } = props

    const onClickHandler = (index: number) => {
        let targetValue = radioItems.find((el,i)=>i===index).value
        onChange(name,targetValue)
    }

    const onChangeHandler = (val: string) => {
            onChange && onChange(name, val)
    }

    const showContent = () => {
        switch (type) {
            case 'input': {
                return (
                    <div className="add-core-box-photo-core-length-inputField">
                        <InputInLog
                            max={5000}
                            value={value}
                            rules={rules}
                            isShowError={isShowError}
                            type="float-numeric"
                            className={Number(value) && Number(value)>0 && Number(value)<=5000 ? 'input-success' : 'input-error'}

                            onChange={(val) => {
                                onChangeHandler(val)
                            }}
                            setFieldError={setFieldError}
                        />
                    </div>
                )
            }
            case 'radio': {
                return (
                    <div className="add-core-box-photo-core-length-radioList">
                        {radioItems?.map((item, index) => (
                            <div
                                key={index}
                                className="add-core-box-photo-core-length-radioItem"
                            >
                                <input
                                    type="radio"
                                    value={item.value}
                                    checked={item.value===value}
                                    onClick={() => onClickHandler(index)}
                                    onChange={()=>undefined}
                                />
                                {item.content}
                            </div>
                        ))}
                    </div>
                )
            }
            case 'textarea': {
                return (
                    <TextArea
                        className='add-core-box-photo-core-length-textarea'
                        value={value}
                        onChange={({ target }) => {
                            onChangeHandler(target.value)
                        }}
                    />
                )
            }
            default:
                return <></>
        }
    }
    return (
        <div className="add-core-box-photo-item">
            <div>{title}</div>
            {showContent()}
        </div>
    )
}

export default AddCoreBoxPhotoItem
