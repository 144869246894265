import { slice } from './currentProject-slice'
import * as currentProjectSelectors from './selectors'
import * as actions from './actions'
const currentProjectReducer = slice.reducer

const currentProjectActions = {
    ...slice.actions,
    ...actions,
}

export { currentProjectReducer, currentProjectActions, currentProjectSelectors }
