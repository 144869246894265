export const getRoleOptions = (t:Function) =>{
    return [
        {
            label: t('member').toString(),
            value: 'member'
        },
        {
            label: t('editor').toString(),
            value: 'editor'
        },
        {
            label: t('creator').toString(),
            value: 'admin'
        },
    ]
}

export const getAdminRoleOptions = (t:Function) =>{
    return [
        {
            label: t('member').toString(),
            value: 'member'
        },
        {
            label: t('editor').toString(),
            value: 'editor'
        },

    ]
}