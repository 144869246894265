import React, {FC, useEffect, useState} from 'react'

interface IProps {
    className?: string
    style?: object
    items?: any

    onClick?: () => void
    open?: () => void
    close?: () => void
}

const SortIcon: FC<IProps> = ({className, open, close,onClick}) => {
    return (
        <svg
            className={className ? className : ''}
            onClick={onClick}
            // style={
            //     openMenu
            //         ? {transform: 'rotate(90deg)'}
            //         : {transform: 'rotate(0deg)'}
            // }
            width="15"
            height="30"
            viewBox="0 0 15 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <rect width="15" height="30" fill="url(#pattern0)"/>
            <defs>
                <pattern
                    id="pattern0"
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                >
                    <use
                        xlinkHref="#image0_741_1373"
                        transform="translate(0 0.25) scale(0.0111111 0.00555556)"
                    />
                </pattern>
                <image
                    id="image0_741_1373"
                    width="90"
                    height="90"
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAABmJLR0QA/wD/AP+gvaeTAAACIklEQVR4nO3cv6oTQRiG8WdEMpZB8ZoklX8u4ECqdEHstNJOQRByA0HsUhy1Cl6ShWybXTiMxZ5KTDk73/fN+5TbvMOPKXcXlFJKKaWUUkoppeKUWg2fTqfVMAyfgBvgLqX07XK5vNvv92OrM9XsQavhYRg+Aq+Bx8DTUsqbnPP3w+GQW52pZs2gmW/yv22iYreEfnLl+Wa1Wv08Ho+PFj1N5VpCXy2l9Gwcxx+RsE1CQzxss9AQC9s0NMTBNg8NMbBdQIN/bDfQ4BvbFTT4xXYHDT6xXUKDP2y30OAL2zU0+MF2Dw0+sENAg33sMNBgGzsUNNjFDgcNNrFDQoM97LDQYAs7NDTYwQ4PDTawu4CG9tjdQENb7K6goR12d9DQBrtLaFgeu1tomLGnabpd4l2/rqHv2+Sc39ceEfTctvaAoOfuag8IGiilfK29IWg4T9P0ofbIw9oDliul/Mo5v9rtdtW/m+n2Rt8jv9hut5cl9rqEXhoZOoRugQydQbdCho6gWyJDJ9CtkaEDaAvIEBzaCjIEhraEDEGhrSFDQGiLyBAM2ioyBIK2jAxBoK0jQwBoD8jgHNoLMjiG9oQMTqG9IYNDaI/I4AzaKzI4gvaMDE6gvSODA+gIyGAcOgoyGIaOhAxGoaMhQ1voP1een6dpeh4JGRpCp5T+9wbneRzHlxH/Id0Mer1evwW+MN/s36WUz1GRlVJKKaWUUkoppaL1FyEnhXfP3iC5AAAAAElFTkSuQmCC"
                />
            </defs>
        </svg>
    )
}
export {SortIcon}
