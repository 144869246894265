export const getCorrectDateFormat = (data: {
    date: string | Date
    isShowTime?: boolean | Intl.DateTimeFormatOptions
    dateParamsSequence?: string[]
    separator?: string
}) => {
    const separator = data?.separator ? data?.separator : '-'
    const timeOptions: Intl.DateTimeFormatOptions =
        data?.isShowTime && typeof data?.isShowTime === 'object'
            ? data?.isShowTime
            : {
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
              }

    const time = new Date(data?.date)
        .toLocaleString('ru', timeOptions)
        .split('.')
        .join(separator)
    if (data?.date) {
        if (data?.dateParamsSequence && data?.dateParamsSequence.length > 0) {
            const arr = data?.dateParamsSequence.map((el) => [el, 'numeric'])

            const localParams = Object.fromEntries(arr)
            data.date = new Date(data?.date)
                .toLocaleString('ru', localParams)
                .split('.')
                .join(separator)
        } else {
            data.date = new Date(data?.date)
                .toLocaleString('ru', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                })
                .split('.')
                .join(separator)
        }
        if (data?.isShowTime) {
            return `${data?.date}, ${time}`
        } else {
            return data?.date
        }
    } else {
        return ''
    }
}
