import React, {useEffect, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {Form, Input, Spin} from 'antd'
import {authService} from '../../services/auth-service'
import {updateFieldError} from '../../utils/updateFieldError'
import {routes} from '../../utils/constants'
import LetterIcon from '../../image_files/icons/LetterIcon'
import {showNotice} from '../../utils/helpers/showNotice'
import {errorsHandler} from '../../utils/helpers/errors/errors-hendler'
import {userSelectors} from '../../store/user'
import {authActions} from '../../store/auth'
import SignHeader from "../../shared/signHeader/SignHeader";

function PasswordReset() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const resetFormRef = useRef()
    const currentLanguage = useSelector(userSelectors.selectLanguage)
    const {setPasswordResetData} = authActions

    const onFinish = async (values) => {
        try {
            setLoading(true)
            const response = await authService.passwordReset({
                email: values.email,
            })
            dispatch(
                setPasswordResetData({
                    passwordResetData: {
                        response,
                        email: values.email,
                    },
                }),
            )
            if (response.detail) {
                navigate(routes.RECOVERY_MESSAGE)
                showNotice(response.detail, t)
            }
            setLoading(false)
        } catch (e) {
            errorsHandler(e, t)
            setLoading(false)
        }
    }

    useEffect(() => {
        updateFieldError(resetFormRef.current, t)
    }, [currentLanguage])

    if (loading) {
        return (
            <div className="spinContainer">
                <Spin size={'large'}/>
            </div>
        )
    }
    return (
        <div className="signBlock">
            <SignHeader
                buttonRoute={{link: routes.REGISTRATION, name: t('registration')}}
            />
            <div className="signWrapper recovery__wrapper">
                <Form
                    className="signForm"
                    name="register"
                    scrollToFirstError
                    onFinish={onFinish}
                    ref={resetFormRef}
                >
                    <h2 className="signForm__header">
                        {`${t('password-recovery')}`}
                    </h2>
                    <p className="signForm__topContent">
                        {`${t('enter-current-email')}`}
                    </p>
                    <div className={'signForm__inputWrapper'}>
                        <Form.Item
                            name={'email'}
                            className={' signForm__inputItem'}
                            rules={[
                                {
                                    required: true,
                                    message: t('enter-email-address'),
                                },
                                {
                                    type: 'email',
                                    message: t('enter-real-email-and-password'),
                                },
                            ]}
                        >
                            <Input
                                type="email"
                                className="signForm__input"
                                prefix={<LetterIcon/>}
                                placeholder={t('mail')}
                            />
                        </Form.Item>
                        <div
                            className={'signForm__btn-wrapper'}
                            style={{textAlign: 'center'}}
                        >
                            <button
                                type={'submit'}
                                className={'signForm__submitBtn cursorPointer'}
                            >
                                {`${t('continue')}`}
                            </button>
                        </div>
                        <Link to={routes.LOGIN} className="signForm__textLink ">
                            {`${t('sign-in')}`}
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default PasswordReset
