import React, {FC} from 'react';
import {ITermsAndPolicyProps} from "./types";
import {useTranslation} from "react-i18next";
import './termsAndPolicy.css';

export const TermsAndPolicy: FC<ITermsAndPolicyProps> = ({type}) => {
    const {i18n} = useTranslation()
    const termsEn = (
        <>
            <div className={'terms-policy-paragraph-title'}>Terms of Use</div>
            <div>This User Agreement (hereinafter referred to as the Agreement) governs the relationship between Data
                Factory LLC (hereinafter referred to as inLog or Administration) on the one hand and the user of the
                site on the other. The inLog website is not a mass media outlet. By using the site, you agree to the
                terms of this agreement.
            </div>
            <div className={'terms-policy-paragraph font-bold'}>Subject of the agreement</div>
            <div>The administration grants the user the right to post the following information on the website:</div>
            <div>- Text information</div>
            <div>- Photo materials</div>
            <div>- Links to materials posted on other sites</div>
            <div></div>
            <div className={'terms-policy-paragraph font-bold'}>Rights and obligations of the parties</div>
            <div></div>
            <div>The user has the right:</div>
            <div>- search for information on the site</div>
            <div>- receive information on the site</div>
            <div> - create information for the site</div>
            <div>- comment on content posted on the site</div>
            <div>- copy information to other sites with the permission of the site Administration</div>
            <div>- copy information to other sites with the permission of the copyright holder</div>
            <div>- require the administration to hide any information about the user</div>
            <div>- require the administration to hide any information transmitted by the user to the site</div>
            <div> - use site information for commercial purposes with the permission of the Administration</div>
            <div>- use the site information for commercial purposes with the permission of the copyright holders</div>
            <div></div>
            <div>The administration has the right:</div>
            <div>- at your own discretion and need to create, change, cancel rules</div>
            <div>- restrict access to any information on the site</div>
            <div></div>
            <div>The user undertakes:</div>
            <div> - ensure the reliability of the information provided</div>
            <div>- ensure the safety of personal data from access by third parties</div>
            <div>- update the Personal data provided during registration if they change</div>
            <div>- do not disrupt the functionality of the site</div>
            <div> - do not create multiple accounts on the Site if they actually belong to the same person - do not take
                actions aimed at misleading other Users
            </div>
            <div>- do not transfer your account and/or login and password to third parties for use</div>
            <div> - do not use scripts (programs) for automated collection of information and/or interaction with the
                Site and its Services
            </div>
            <div></div>
            <div>The administration undertakes:</div>
            <div> - maintain the functionality of the site except in cases where this is impossible for reasons beyond
                the control of the Administration.
            </div>
            <div>- carry out comprehensive protection of the User account</div>
            <div> - protect information the distribution of which is limited or prohibited by law by issuing a warning
                or deleting the account of a user who has violated the rules
            </div>
            <div></div>
            <div className={'terms-policy-paragraph font-bold'}>Responsibility of the parties</div>
            <div></div>
            <div>- the user personally bears full responsibility for the information he disseminates</div>
            <div>- the administration does not bear any responsibility for the accuracy of information copied from other
                sources
            </div>
            <div>- the administration does not bear any responsibility for services provided by third parties</div>
            <div>- in the event of a force majeure situation (combat operations, state of emergency, natural disaster,
                etc.), the Administration does not guarantee the safety of information posted by the User, as well as
                the uninterrupted operation of the information resource
            </div>
            <div></div>
            <div className={'terms-policy-paragraph font-bold'}>Terms of the Agreement</div>
            <div></div>
            <div>This Agreement comes into force upon registration on the site. The agreement ceases to be valid when a
                new version appears. The administration reserves the right to unilaterally change this agreement at its
                discretion. If there is any change to this agreement, the administration will notify users in a way
                convenient for it.
            </div>
            <div></div>
        </>
    )
    const termsRu = (
        <>
            <div className={'terms-policy-paragraph-title'}>Пользовательское Соглашение</div>
            <div>Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между ООО Дата Фактори
                (далее inLog или Администрация) с одной стороны и пользователем сайта с другой.
            </div>
            <div>Сайт inLog не является средством массовой информации.</div>
            <div></div>
            <div>Используя сайт, Вы соглашаетесь с условиями данного соглашения.</div>
            <div className={'terms-policy-paragraph'}>Предмет соглашения</div>
            <div className={'font-bold'}>Администрация предоставляет пользователю право на размещение на сайте следующей
                информации:
            </div>
            <div>- Текстовой информации</div>
            <div>- Фотоматериалов</div>
            <div>- Ссылок на материалы, размещенные на других сайтах</div>
            <div></div>
            <div></div>
            <div className={'terms-policy-paragraph'}>Права и обязанности сторон</div>
            <div className={'font-bold'}>Пользователь имеет право:</div>
            <div>- осуществлять поиск информации на сайте</div>
            <div>- получать информацию на сайте</div>
            <div>- создавать информацию для сайта</div>
            <div>- комментировать контент, выложенный на сайте</div>
            <div>- копировать информацию на другие сайты с разрешения Администрации сайта</div>
            <div>- копировать информацию на другие сайты с разрешения правообладателя</div>
            <div>- требовать от администрации скрытия любой информации о пользователе</div>
            <div>- требовать от администрации скрытия любой информации переданной пользователем сайту</div>
            <div>- использовать информацию сайта в коммерческих целях с разрешения Администрации</div>
            <div>- использовать информацию сайта в коммерческих целях с разрешения правообладателей</div>
            <div></div>
            <div className={'font-bold'}>Администрация имеет право:</div>
            <div>- по своему усмотрению и необходимости создавать, изменять, отменять правила</div>
            <div>- ограничивать доступ к любой информации на сайте</div>
            <div></div>
            <div className={'font-bold'}>Пользователь обязуется:</div>
            <div>- обеспечить достоверность предоставляемой информации</div>
            <div>- обеспечивать сохранность личных данных от доступа третьих лиц</div>
            <div>- обновлять Персональные данные, предоставленные при регистрации, в случае их изменения</div>
            <div>- не нарушать работоспособность сайта</div>
            <div>- не создавать несколько учётных записей на Сайте, если фактически они принадлежат одному и тому же
                лицу
            </div>
            <div>- не совершать действия, направленные на введение других Пользователей в заблуждение</div>
            <div>- не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим
                лицам
            </div>
            <div>- не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с
                Сайтом и его Сервисами
            </div>
            <div></div>
            <div className={'font-bold'}>Администрация обязуется:</div>
            <div>- поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от
                Администрации причинам.
            </div>
            <div>- осуществлять разностороннюю защиту учетной записи Пользователя</div>
            <div>- защищать информацию, распространение которой ограничено или запрещено законами путем вынесения
                предупреждения либо удалением учетной записи пользователя, нарушившего правила
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div className={'terms-policy-paragraph'}>Ответственность сторон</div>
            <div>- пользователь лично несет полную ответственность за распространяемую им информацию</div>
            <div>- администрация не несет никакой ответственности за достоверность информации, скопированной из других
                источников
            </div>
            <div>- администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами</div>
            <div>- в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное
                бедствие и т. д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а
                также бесперебойную работу информационного ресурса
            </div>
            <div></div>
            <div></div>
            <div className={'terms-policy-paragraph'}>Условия действия Соглашения</div>
            <div>Данное Соглашение вступает в силу при регистрации на сайте.</div>
            <div>Соглашение перестает действовать при появлении его новой версии.</div>
            <div>Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему
                усмотрению.
            </div>
            <div>При любом изменении данного соглашения, администрация будет оповещать пользователей удобным для нее
                способом.
            </div>
            <div></div>
        </>
    )

    const policy_en = (
        <>
            <div className={'terms-policy-paragraph-title'}>Personal Data Processing Policy</div>
            <div className={'terms-policy-paragraph'}>1. General Provisions</div>
            <div>This personal data processing policy has been drawn up in accordance with the requirements of the
                Federal Law of July 27, 2006. No. 152-FZ “On Personal Data” (hereinafter referred to as the Law on
                Personal Data) and determines the procedure for processing personal data and measures to ensure the
                security of personal data taken by Data Factory LLC (hereinafter referred to as the Operator).
            </div>
            <div></div>
            <div>1.1. The operator sets as its most important goal and condition for carrying out its activities the
                observance of the rights and freedoms of man and citizen when processing his personal data, including
                the protection of the rights to privacy, personal and family secrets.
            </div>
            <div>1.2. This Operator’s policy regarding the processing of personal data (hereinafter referred to as the
                Policy) applies to all information that the Operator can obtain about visitors to the website
                <a href={'https://inlog.data-factory.ru'}> https://inlog.data-factory.ru.</a>
            </div>
            <div className={'terms-policy-paragraph'}>2. Basic Terms used in the Policy</div>
            <div>2.1. Automated processing of personal data - processing of personal data using computer technology.
            </div>
            <div>2.2. Blocking of personal data is a temporary cessation of processing of personal data (except for
                cases where processing is necessary to clarify personal data).
            </div>
            <div>2.3. Website is a collection of graphic and information materials, as well as computer programs and
                databases that ensure their availability on the Internet at the network address
                <a href={'https://inlog.data-factory.ru'}> https://inlog.data-factory.ru.</a>

            </div>
            <div>2.4. Personal data information system is a set of personal data contained in databases and information
                technologies and technical means that ensure their processing.
            </div>
            <div>2.5. Depersonalization of personal data - actions as a result of which it is impossible to determine
                without the use of additional information the ownership of personal data to a specific User or other
                subject of personal data.
            </div>
            <div>2.6. Processing of personal data - any action (operation) or set of actions (operations) performed
                using automation tools or without the use of such tools with personal data, including collection,
                recording, systematization, accumulation, storage, clarification (updating, changing), extraction, use,
                transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction of
                personal data.
            </div>
            <div>2.7. Operator - a state body, municipal body, legal or natural person, independently or jointly with
                other persons organizing and/or carrying out the processing of personal data, as well as determining the
                purposes of processing personal data, the composition of personal data to be processed, actions
                (operations) performed with personal data.
            </div>
            <div>
                2.8. Personal data - any information relating directly or
                indirectly to a specific or identified User of the website <a href={'https://inlog.data-factory.ru'}> https://inlog.data-factory.ru.</a>
            </div>
            <div>2.9. Personal data authorized by the subject of personal data for distribution - personal data, access
                to an unlimited number of persons to which is provided by the subject of personal data by giving consent
                to the processing of personal data authorized by the subject of personal data for distribution in the
                manner prescribed by the Law on Personal Data (hereinafter referred to as personal data). data
                authorized for distribution).
            </div>
            <div>2.10. User - any visitor to the website <a href={'https://inlog.data-factory.ru'}> https://inlog.data-factory.ru.</a></div>
            <div>2.11. Providing personal data - actions aimed at disclosing personal data to a certain person or a
                certain circle of persons.
            </div>
            <div>2.12. Distribution of personal data - any actions aimed at disclosing personal data to an indefinite
                number of persons (transfer of personal data) or to familiarize with personal data to an unlimited
                number of persons, including the publication of personal data in the media, posting in information and
                telecommunication networks or providing access to personal data in any other way.
            </div>
            <div>2.13. Cross-border transfer of personal data is the transfer of personal data to the territory of a
                foreign state to an authority of a foreign state, a foreign individual or a foreign legal entity.
            </div>
            <div>2.14. Destruction of personal data - any actions as a result of which personal data is irretrievably
                destroyed with the impossibility of further restoration of the content of personal data in the personal
                data information system and/or material media of personal data are destroyed.
            </div>
            <div></div>
            <div className={'terms-policy-paragraph'}>3. Basic rights and obligations of the Operator</div>
            <div>3.1. The operator has the right:</div>
            <div>— receive from the subject of personal data reliable information and/or documents containing personal
                data;
            </div>
            <div>— in the event that the subject of personal data withdraws consent to the processing of personal data,
                as well as sends a request to stop processing personal data, the Operator has the right to continue
                processing personal data without the consent of the subject of personal data if there are grounds
                specified in the Law on Personal Data;
            </div>
            <div> — independently determine the composition and list of measures necessary and sufficient to ensure the
                fulfillment of the obligations provided for by the Law on Personal Data and regulations adopted in
                accordance with it, unless otherwise provided by the Law on Personal Data or other federal laws.
            </div>
            <div></div>
            <div>3.2. The operator is obliged:</div>
            <div>— provide the subject of personal data, at his request, with information regarding the processing of
                his personal data;
            </div>
            <div>— organize the processing of personal data in the manner established by the current legislation of the
                Russian Federation;
            </div>
            <div>— respond to requests and inquiries from personal data subjects and their legal representatives in
                accordance with the requirements of the Personal Data Law;
            </div>
            <div>— report to the authorized body for the protection of the rights of personal data subjects, at the
                request of this body, the necessary information within 10 days from the date of receipt of such a
                request;
            </div>
            <div>— publish or otherwise provide unrestricted access to this Policy regarding the processing of personal
                data;
            </div>
            <div>— take legal, organizational and technical measures to protect personal data from unauthorized or
                accidental access, destruction, modification, blocking, copying, provision, distribution of personal
                data, as well as from other unlawful actions in relation to personal data;
            </div>
            <div>— stop the transfer (distribution, provision, access) of personal data, stop processing and destroy
                personal data in the manner and cases provided for by the Law on Personal Data;
            </div>
            <div>— fulfill other duties provided for by the Personal Data Law.</div>
            <div></div>
            <div className={'terms-policy-paragraph'}>4. Basic rights and obligations of personal data subjects</div>
            <div>4.1. Subjects of personal data have the right:</div>
            <div>— receive information regarding the processing of his personal data, except for cases provided for by
                federal laws. The information is provided to the subject of personal data by the Operator in an
                accessible form, and it should not contain personal data relating to other subjects of personal data,
                except in cases where there are legal grounds for the disclosure of such personal data. The list of
                information and the procedure for obtaining it is established by the Law on Personal Data;
            </div>
            <div>— require the operator to clarify his personal data, block it or destroy it if the personal data is
                incomplete, outdated, inaccurate, illegally obtained or is not necessary for the stated purpose of
                processing, as well as take measures provided by law to protect their rights;
            </div>
            <div>— put forward the condition of prior consent when processing personal data in order to promote goods,
                works and services on the market;
            </div>
            <div>— to withdraw consent to the processing of personal data, as well as to send a request to stop
                processing personal data;
            </div>
            <div>— appeal to the authorized body for the protection of the rights of personal data subjects or in court
                the unlawful actions or inaction of the Operator when processing his personal data;
            </div>
            <div>— to exercise other rights provided for by the legislation of the Russian Federation.</div>
            <div></div>
            <div>4.2. Subjects of personal data are obliged to:</div>
            <div>— provide the Operator with reliable information about yourself;</div>
            <div>— inform the Operator about clarification (updating, changing) of your personal data.</div>
            <div></div>
            <div>4.3. Persons who provided the Operator with false information about themselves or information about
                another subject of personal data without the latter’s consent are liable in accordance with the
                legislation of the Russian Federation.
            </div>
            <div className={'terms-policy-paragraph'}>5. Principles for processing personal data</div>
            <div>5.1. The processing of personal data is carried out on a legal and fair basis.</div>
            <div>5.2. The processing of personal data is limited to the achievement of specific, pre-defined and
                legitimate purposes. Processing of personal data that is incompatible with the purposes of collecting
                personal data is not permitted.
            </div>
            <div>5.3. It is not allowed to combine databases containing personal data, the processing of which is
                carried out for purposes that are incompatible with each other.
            </div>
            <div>5.4. Only personal data that meets the purposes of their processing are subject to processing.</div>
            <div>5.5. The content and volume of personal data processed correspond to the stated purposes of processing.
                Redundancy of the processed personal data in relation to the stated purposes of their processing is not
                allowed.
            </div>
            <div>5.6. When processing personal data, the accuracy of personal data, their sufficiency, and, where
                necessary, relevance in relation to the purposes of processing personal data are ensured. The operator
                takes the necessary measures and/or ensures that they are taken to delete or clarify incomplete or
                inaccurate data.
            </div>
            <div>5.7. The storage of personal data is carried out in a form that makes it possible to identify the
                subject of personal data, no longer than required by the purposes of processing personal data, unless
                the period for storing personal data is established by federal law, an agreement to which the subject of
                personal data is a party, beneficiary or guarantor. The processed personal data is destroyed or
                anonymized upon achievement of the processing goals or in the event of the loss of the need to achieve
                these goals, unless otherwise provided by federal law.
            </div>
            <div className={'terms-policy-paragraph'}>6. Purposes of processing personal data</div>
            <div className={'terms-policy-table'}>
                <div className={'terms-policy-table-row'}>
                    <div>The purpose of processing</div>
                    <div className={'terms-policy-paragraph--highliter'}>
                        to provide the User with access to services, information and/or materials contained on the website
                    </div>
                </div>
                <div className={'terms-policy-table-row'}>
                    <div>Personal data</div>
                    <div className={'terms-policy-paragraph--highliter'}>
                        <div className={'terms-policy-paragraph--highliter'}>last name, first name, patronymic </div>
                        <div className={'terms-policy-paragraph--highliter'}>email address</div>
                        <div className={'terms-policy-paragraph--highliter'}>phone numbers </div>
                        <div className={'terms-policy-paragraph--highliter'}>photos</div>
                    </div>
                </div>
                <div className={'terms-policy-table-row'}>
                    <div>Legal grounds</div>
                    <div className={'terms-policy-paragraph--highliter'}>
                        contracts concluded between the operator and the subject of personal data
                    </div>
                </div>
                <div className={'terms-policy-table-row'}>
                    <div>Types of processing of personal data</div>
                    <div>
                        <div className={'terms-policy-paragraph--highliter'}>
                            collection, recording, systematization, accumulation, storage, destruction and depersonalization of personal data
                        </div>
                        <div className={'terms-policy-paragraph--highliter'}>Sending information letters to an email address</div>
                    </div>
                </div>
            </div>


            <div className={'terms-policy-paragraph'}>7. Conditions for processing personal data</div>
            <div>7.1. The processing of personal data is carried out with the consent of the subject of personal data to
                the processing of his personal data.
            </div>
            <div>7.2. The processing of personal data is necessary to achieve the goals provided for by an international
                treaty of the Russian Federation or law, to implement the functions, powers and responsibilities
                assigned by the legislation of the Russian Federation to the operator.
            </div>
            <div>7.3. The processing of personal data is necessary for the administration of justice, the execution of a
                judicial act, an act of another body or official, subject to execution in accordance with the
                legislation of the Russian Federation on enforcement proceedings. 7.4. The processing of personal data
                is necessary for the execution of an agreement to which the subject of personal data is a party or
                beneficiary or guarantor, as well as for concluding an agreement on the initiative of the subject of
                personal data or an agreement under which the subject of personal data will be a beneficiary or
                guarantor.
            </div>
            <div>7.5. The processing of personal data is necessary to exercise the rights and legitimate interests of
                the operator or third parties or to achieve socially significant goals, provided that the rights and
                freedoms of the subject of personal data are not violated.
            </div>
            <div>7.6. The processing of personal data is carried out, access to an unlimited number of persons is
                provided by the subject of personal data or at his request (hereinafter referred to as publicly
                available personal data).
            </div>
            <div>7.7. We process personal data that is subject to publication or mandatory disclosure in accordance with
                federal law.
            </div>
            <div></div>
            <div className={'terms-policy-paragraph'}>8. The procedure for collecting, storing, transferring and other
                types of processing of personal data
            </div>
            <div>The security of personal data processed by the Operator is ensured by implementing legal,
                organizational and technical measures necessary to fully comply with the requirements of current
                legislation in the field of personal data protection.
            </div>
            <div></div>
            <div>8.1. The operator ensures the safety of personal data and takes all possible measures to prevent access
                to personal data by unauthorized persons.
            </div>
            <div>8.2. The User’s personal data will never, under any circumstances, be transferred to third parties,
                except in cases related to the implementation of current legislation or in the event that the subject of
                personal data gives consent to the Operator to transfer data to a third party to fulfill obligations
                under a civil law contract.
            </div>
            <div>8.3. If inaccuracies in personal data are identified, the User can update them independently by sending
                a notification to the Operator to the Operator's email address <a
                    href={'yury.meshalkin@data-factory.ru'}> yury.meshalkin@data-factory.ru</a> with the
                note “Updating personal data.”
            </div>
            <div>8.4. The period for processing personal data is determined by the achievement of the purposes for which
                the personal data were collected, unless a different period is provided for by the contract or current
                legislation. The User may at any time withdraw his consent to the processing of personal data by sending
                a notification to the Operator via email to the Operator's email address <a
                    href={'yury.meshalkin@data-factory.ru'}> yury.meshalkin@data-factory.ru </a>
                with the note “Withdrawal of consent to the processing of personal data.”
            </div>
            <div>8.5. All information that is collected by third-party services, including payment systems,
                communications and other service providers, is stored and processed by these persons (Operators) in
                accordance with their User Agreement and Privacy Policy. Subject of personal data and/or with specified
                documents. The operator is not responsible for the actions of third parties, including the service
                providers specified in this paragraph.
            </div>
            <div>8.6. Prohibitions established by the subject of personal data on the transfer (except for providing
                access), as well as on processing or conditions for processing (except for gaining access) of personal
                data permitted for distribution, do not apply in cases of processing personal data in state, public and
                other public interests determined by law RF.
            </div>
            <div>8.7. When processing personal data, the operator ensures the confidentiality of personal data.</div>
            <div>8.8. The operator stores personal data in a form that makes it possible to identify the subject of
                personal data for no longer than required by the purposes of processing personal data, unless the period
                for storing personal data is established by federal law, an agreement to which the subject of personal
                data is a party, beneficiary or guarantor. 8.9. The condition for terminating the processing of personal
                data may be the achievement of the purposes of processing personal data, the expiration of the consent
                of the subject of personal data, the withdrawal of consent by the subject of personal data or a
                requirement to cease the processing of personal data, as well as the identification of unlawful
                processing of personal data.
            </div>
            <div></div>
            <div className={'terms-policy-paragraph'}>9. List of actions performed by the Operator with received
                personal data
            </div>
            <div></div>
            <div>9.1. The operator collects, records, systematizes, accumulates, stores, refines (updates, changes),
                extracts, uses, transfers (distribute, provide, access), depersonalizes, blocks, deletes and destroys
                personal data.
            </div>
            <div>9.2. The operator carries out automated processing of personal data with or without receiving and/or
                transmitting the received information via information and telecommunication networks.
            </div>
            <div></div>
            <div className={'terms-policy-paragraph'}>10. Cross-border transfer of personal data</div>
            <div>10.1. Before starting activities for the cross-border transfer of personal data, the operator is
                obliged to notify the authorized body for the protection of the rights of personal data subjects of its
                intention to carry out cross-border transfer of personal data (such notification is sent separately from
                the notification of the intention to process personal data).
            </div>
            <div>10.2. Before submitting the above notification, the operator is obliged to obtain relevant information
                from the authorities of a foreign state, foreign individuals, foreign legal entities to whom the
                cross-border transfer of personal data is planned.
            </div>
            <div></div>
            <div className={'terms-policy-paragraph'}>11. Confidentiality of personal data</div>
            <div>The operator and other persons who have access to personal data are obliged not to disclose to third
                parties or distribute personal data without the consent of the subject of personal data, unless
                otherwise provided by federal law.
            </div>
            <div className={'terms-policy-paragraph'}>12. Final provisions</div>
            <div>12.1. The User can receive any clarifications on issues of interest concerning the processing of his
                personal data by contacting the Operator via e-mail <a
                    href={"yury.meshalkin@data-factory.ru"}>yury.meshalkin@data-factory.ru.</a>
            </div>
            <div>12.2. This document will reflect any changes to the personal data processing policy by the Operator.
                The policy is valid indefinitely until it is replaced by a new version.
            </div>
            <div>12.3. The current version of the Policy is freely available on the Internet at
                <a href={'https://inlog.data-factory.ru/register'}> https://inlog.data-factory.ru/register.</a>
            </div>
            <div></div>

        </>
    )

    const policy_ru = (
        <>
            <div className={'terms-policy-paragraph-title'}>Политика в Отношении Обработки Персональных Данных</div>
            <div className={'terms-policy-paragraph'}>1. Общие положения</div>
            <div>Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального
                закона от 27.07.2006. № 152-ФЗ «О персональных данных» (далее — Закон о персональных данных) и
                определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных,
                предпринимаемые <div className={'terms-policy-paragraph--highliter'}>ООО Дата Фактори</div> (далее —
                Оператор).
            </div>
            <div>1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав
                и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на
                неприкосновенность частной жизни, личную и семейную тайну.
            </div>
            <div>1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее — Политика)
                применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта
                <a href={'yury.meshalkin@data-factory.ru'}> https://inlog.data-factory.ru.</a>
            </div>
            <div className={'terms-policy-paragraph'}>2. Основные понятия, используемые в Политике</div>
            <div>2.1. Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств
                вычислительной техники.
            </div>
            <div>2.2. Блокирование персональных данных — временное прекращение обработки персональных данных (за
                исключением случаев, если обработка необходима для уточнения персональных данных).
            </div>
            <div>2.3. Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз
                данных, обеспечивающих их доступность в сети интернет по сетевому адресу <a
                    href={'yury.meshalkin@data-factory.ru'}> https://inlog.data-factory.ru.</a>
            </div>
            <div>2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных
                данных и обеспечивающих их обработку информационных технологий и технических средств.
            </div>
            <div>2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без
                использования дополнительной информации принадлежность персональных данных конкретному Пользователю или
                иному субъекту персональных данных.
            </div>
            <div>2.6. Обработка персональных данных — любое действие (операция) или совокупность действий (операций),
                совершаемых с использованием средств автоматизации или без использования таких средств с персональными
                данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
                извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
                блокирование, удаление, уничтожение персональных данных.
            </div>
            <div>2.7. Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо,
                самостоятельно или совместно с другими лицами организующие и/или осуществляющие обработку персональных
                данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих
                обработке, действия (операции), совершаемые с персональными данными.
            </div>
            <div>2.8. Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или
                определяемому Пользователю веб-сайта <a
                    href={'yury.meshalkin@data-factory.ru'}> https://inlog.data-factory.ru.</a>
            </div>
            <div>2.9. Персональные данные, разрешенные субъектом персональных данных для распространения, — персональные
                данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи
                согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения
                в порядке, предусмотренном Законом о персональных данных (далее — персональные данные, разрешенные для
                распространения).
            </div>
            <div>2.10. Пользователь — любой посетитель веб-сайта <a
                href={'https://inlog.data-factory.ru'}>https://inlog.data-factory.ru.</a></div>
            <div>2.11. Предоставление персональных данных — действия, направленные на раскрытие персональных данных
                определенному лицу или определенному кругу лиц.
            </div>
            <div>2.12. Распространение персональных данных — любые действия, направленные на раскрытие персональных
                данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными
                данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой
                информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к
                персональным данным каким-либо иным способом.
            </div>
            <div>2.13. Трансграничная передача персональных данных — передача персональных данных на территорию
                иностранного государства органу власти иностранного государства, иностранному физическому или
                иностранному юридическому лицу.
            </div>
            <div>2.14. Уничтожение персональных данных — любые действия, в результате которых персональные данные
                уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в
                информационной системе персональных данных и/или уничтожаются материальные носители персональных данных.
            </div>
            <div className={'terms-policy-paragraph'}>3. Основные права и обязанности Оператора</div>
            <div>3.1. Оператор имеет право:</div>
            <div>— получать от субъекта персональных данных достоверные информацию и/или документы, содержащие
                персональные данные;
            </div>
            <div>— в случае отзыва субъектом персональных данных согласия на обработку персональных данных, а также,
                направления обращения с требованием о прекращении обработки персональных данных, Оператор вправе
                продолжить обработку персональных данных без согласия субъекта персональных данных при наличии
                оснований, указанных в Законе о персональных данных;
            </div>
            <div>— самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения
                обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним
                нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими
                федеральными законами.
            </div>
            <div>3.2. Оператор обязан:</div>
            <div>— предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки его
                персональных данных;
            </div>
            <div>— организовывать обработку персональных данных в порядке, установленном действующим законодательством
                РФ;
            </div>
            <div>— отвечать на обращения и запросы субъектов персональных данных и их законных представителей в
                соответствии с требованиями Закона о персональных данных;
            </div>
            <div>— сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа
                необходимую информацию в течение 10 дней с даты получения такого запроса;
            </div>
            <div>— публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике в отношении
                обработки персональных данных;
            </div>
            <div>— принимать правовые, организационные и технические меры для защиты персональных данных от
                неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования,
                предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении
                персональных данных;
            </div>
            <div>— прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить
                обработку и уничтожить персональные данные в порядке и случаях, предусмотренных Законом о персональных
                данных;
            </div>
            <div>— исполнять иные обязанности, предусмотренные Законом о персональных данных.</div>
            <div className={'terms-policy-paragraph'}>4. Основные права и обязанности субъектов персональных данных
            </div>
            <div>4.1. Субъекты персональных данных имеют право:</div>
            <div>— получать информацию, касающуюся обработки его персональных данных, за исключением случаев,
                предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных Оператором
                в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам
                персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких
                персональных данных. Перечень информации и порядок ее получения установлен Законом о персональных
                данных;
            </div>
            <div>— требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае,
                если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не
                являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по
                защите своих прав;
            </div>
            <div>— выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на
                рынке товаров, работ и услуг;
            </div>
            <div>— на отзыв согласия на обработку персональных данных, а также, на направление требования о прекращении
                обработки персональных данных;
            </div>
            <div>— обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке
                неправомерные действия или бездействие Оператора при обработке его персональных данных;
            </div>
            <div>— на осуществление иных прав, предусмотренных законодательством РФ.</div>
            <div>4.2. Субъекты персональных данных обязаны:</div>
            <div>— предоставлять Оператору достоверные данные о себе;</div>
            <div>— сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных.</div>
            <div>4.3. Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте
                персональных данных без согласия последнего, несут ответственность в соответствии с законодательством
                РФ.
            </div>
            <div className={'terms-policy-paragraph'}>5. Принципы обработки персональных данных</div>
            <div>5.1. Обработка персональных данных осуществляется на законной и справедливой основе.</div>
            <div>5.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и
                законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных
                данных.
            </div>
            <div>5.3. Не допускается объединение баз данных, содержащих персональные данные, обработка которых
                осуществляется в целях, несовместимых между собой.
            </div>
            <div>5.4. Обработке подлежат только персональные данные, которые отвечают целям их обработки.</div>
            <div>5.5. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Не
                допускается избыточность обрабатываемых персональных данных по отношению к заявленным целям их
                обработки.
            </div>
            <div>5.6. При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а
                в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Оператор
                принимает необходимые меры и/или обеспечивает их принятие по удалению или уточнению неполных или
                неточных данных.
            </div>
            <div>5.7. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных
                данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных
                данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или
                поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные
                уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в
                достижении этих целей, если иное не предусмотрено федеральным законом.
            </div>
            <div className={'terms-policy-paragraph'}>6. Цели обработки персональных данных</div>
            <div className={'terms-policy-table'}>
                <div className={'terms-policy-table-row'}>
                    <div>Цель обработки</div>
                    <div className={'terms-policy-paragraph--highliter'}>предоставление доступа Пользователю к сервисам,
                        информации и/или материалам,
                        содержащимся на веб-сайте
                    </div>
                </div>
                <div className={'terms-policy-table-row'}>
                    <div>Персональные данные</div>
                    <div className={'terms-policy-paragraph--highliter'}>
                        <div className={'terms-policy-paragraph--highliter'}>фамилия, имя, отчество</div>
                        <div className={'terms-policy-paragraph--highliter'}>электронный адрес</div>
                        <div className={'terms-policy-paragraph--highliter'}>номера телефонов</div>
                        <div className={'terms-policy-paragraph--highliter'}>фотографии</div>
                    </div>
                </div>
                <div className={'terms-policy-table-row'}>
                    <div>Правовые основания</div>
                    <div className={'terms-policy-paragraph--highliter'}>договоры, заключаемые между оператором и
                        субъектом персональных данных
                    </div>
                </div>
                <div className={'terms-policy-table-row'}>
                    <div>Виды обработки</div>
                    <div>
                        <div className={'terms-policy-paragraph--highliter'}>
                            персональных данных Сбор, запись, систематизация, накопление, хранение, уничтожение и
                            обезличивание персональных данных
                        </div>
                        <div className={'terms-policy-paragraph--highliter'}>Отправка информационных писем на адрес электронной почты</div>
                    </div>
                </div>
            </div>
            <div className={'terms-policy-paragraph'}>7. Условия обработки персональных данных</div>
            <div>7.1. Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку
                его персональных данных.
            </div>
            <div>7.2. Обработка персональных данных необходима для достижения целей, предусмотренных международным
                договором Российской Федерации или законом, для осуществления возложенных законодательством Российской
                Федерации на оператора функций, полномочий и обязанностей.
            </div>
            <div>7.3. Обработка персональных данных необходима для осуществления правосудия, исполнения судебного акта,
                акта другого органа или должностного лица, подлежащих исполнению в соответствии с законодательством
                Российской Федерации об исполнительном производстве.
            </div>
            <div>7.4. Обработка персональных данных необходима для исполнения договора, стороной которого либо
                выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для
                заключения договора по инициативе субъекта персональных данных или договора, по которому субъект
                персональных данных будет являться выгодоприобретателем или поручителем.
            </div>
            <div>7.5. Обработка персональных данных необходима для осуществления прав и законных интересов оператора или
                третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права
                и свободы субъекта персональных данных.
            </div>
            <div>7.6. Осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым
                предоставлен субъектом персональных данных либо по его просьбе (далее — общедоступные персональные
                данные).
            </div>
            <div>7.7. Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию
                в соответствии с федеральным законом.
            </div>
            <div className={'terms-policy-paragraph'}>8. Порядок сбора, хранения, передачи и других видов обработки
                персональных данных
            </div>
            <div>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации
                правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований
                действующего законодательства в области защиты персональных данных.
            </div>
            <div>8.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие
                доступ к персональным данным неуполномоченных лиц.
            </div>
            <div>8.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам,
                за исключением случаев, связанных с исполнением действующего законодательства либо в случае, если
                субъектом персональных данных дано согласие Оператору на передачу данных третьему лицу для исполнения
                обязательств по гражданско-правовому договору.
            </div>
            <div>8.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их
                самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора
                <a href={'yury.meshalkin@data-factory.ru'}> https://inlog.data-factory.ru </a>
                yury.meshalkin@data-factory.ru с пометкой «Актуализация персональных данных».
            </div>
            <div>8.4. Срок обработки персональных данных определяется достижением целей, для которых были собраны
                персональные данные, если иной срок не предусмотрен договором или действующим законодательством.
            </div>
            <div>Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив
                Оператору уведомление посредством электронной почты на электронный адрес Оператора
                <a href={'yury.meshalkin@data-factory.ru'}> yury.meshalkin@data-factory.ru </a> с пометкой «Отзыв согласия на обработку персональных данных».
            </div>
            <div>8.5. Вся информация, которая собирается сторонними сервисами, в том числе платежными системами,
                средствами связи и другими поставщиками услуг, хранится и обрабатывается указанными лицами (Операторами)
                в соответствии с их Пользовательским соглашением и Политикой конфиденциальности. Субъект персональных
                данных и/или с указанными документами. Оператор не несет ответственность за действия третьих лиц, в том
                числе указанных в настоящем пункте поставщиков услуг.
            </div>
            <div>8.6. Установленные субъектом персональных данных запреты на передачу (кроме предоставления доступа), а
                также на обработку или условия обработки (кроме получения доступа) персональных данных, разрешенных для
                распространения, не действуют в случаях обработки персональных данных в государственных, общественных и
                иных публичных интересах, определенных законодательством РФ.
            </div>
            <div>8.7. Оператор при обработке персональных данных обеспечивает конфиденциальность персональных данных.
            </div>
            <div>8.8. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта
                персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения
                персональных данных не установлен федеральным законом, договором, стороной которого,
                выгодоприобретателем или поручителем по которому является субъект персональных данных.
            </div>
            <div>8.9. Условием прекращения обработки персональных данных может являться достижение целей обработки
                персональных данных, истечение срока действия согласия субъекта персональных данных, отзыв согласия
                субъектом персональных данных или требование о прекращении обработки персональных данных, а также
                выявление неправомерной обработки персональных данных.
            </div>
            <div className={'terms-policy-paragraph'}>9. Перечень действий, производимых Оператором с полученными
                персональными данными
            </div>
            <div>9.1. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
                изменение), извлечение, использование, передачу (распространение, предоставление, доступ),
                обезличивание, блокирование, удаление и уничтожение персональных данных.
            </div>
            <div>9.2. Оператор осуществляет автоматизированную обработку персональных данных с получением и/или
                передачей полученной информации по информационно-телекоммуникационным сетям или без таковой.
            </div>
            <div className={'terms-policy-paragraph'}>10. Трансграничная передача персональных данных</div>
            <div>10.1. Оператор до начала осуществления деятельности по трансграничной передаче персональных данных
                обязан уведомить уполномоченный орган по защите прав субъектов персональных данных о своем намерении
                осуществлять трансграничную передачу персональных данных (такое уведомление направляется отдельно от
                уведомления о намерении осуществлять обработку персональных данных).
            </div>
            <div>10.2. Оператор до подачи вышеуказанного уведомления, обязан
                получить от органов власти иностранного
                государства, иностранных физических лиц, иностранных юридических лиц, которым планируется трансграничная
                передача персональных данных, соответствующие сведения.
            </div>
            <div className={'terms-policy-paragraph'}>11. Конфиденциальность персональных данных</div>
            <div>Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не
                распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено
                федеральным законом.
            </div>
            <div className={'terms-policy-paragraph'}>12. Заключительные положения</div>
            <div>12.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его
                персональных данных, обратившись к Оператору с помощью электронной почты <a href={'yury.meshalkin@data-factory.ru'}> yury.meshalkin@data-factory.ru.</a>
            </div>
            <div>12.2. В данном документе будут отражены любые изменения политики обработки персональных данных
                Оператором. Политика действует бессрочно до замены ее новой версией.
            </div>
            <div>12.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу
                <a href={'https://inlog.data-factory.ru/register'}> https://inlog.data-factory.ru/register.</a>
            </div>
            <div></div>
        </>
    )

    switch (type) {
        case 'terms':
            return i18n.language === 'ru'
                ? termsRu : termsEn
        case 'policy':
            return i18n.language === 'ru'
                ? policy_ru : policy_en
        default:
            return <></>
    }
}