import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import en_EN from 'antd/es/date-picker/locale/en_US'
import ru_RU from 'antd/es/date-picker/locale/ru_RU'
import SelectInLog from '../../../../shared/select/Select'
import { RoadMapDetalizationTypes } from '../../../../app/types/enums'
import { DefaultOptionType } from 'antd/lib/select'
import { DatePicker } from 'antd'
import { ITask } from '../../../../app/types/models/tasks'
import { getMinPeriodFromTasksList } from '../../services'
import moment from 'moment'

interface IFieldsData {
    detalization: RoadMapDetalizationTypes
    startPeriod: Date | string
    endPeriod: Date | string
}

const RoadmapDetalizationFilter = ({
    data,
    tasks,
    onChange,
}: {
    data: {
        detalization: RoadMapDetalizationTypes
        startPeriod: Date | string
        endPeriod: Date | string
    }
    tasks: ITask[]
    onChange?: (data: {
        detalization: RoadMapDetalizationTypes
        startPeriod: Date | string
        endPeriod: Date | string
    }) => void
}) => {
    const { t, i18n } = useTranslation()
    const [fieldsData, setFieldsData] = useState<IFieldsData>({
        detalization: RoadMapDetalizationTypes.MONTH,
        startPeriod: new Date() as string | Date,
        endPeriod: new Date() as string | Date,
    })

    const detailsSelectOptions: DefaultOptionType[] = [
        {
            label: t('week'),
            value: RoadMapDetalizationTypes.MONTH,
            title: '',
        },
        {
            label: t('month'),
            value: RoadMapDetalizationTypes.QUARTER,
            title: '',
        },
        {
            label: t('year'),
            value: RoadMapDetalizationTypes.YEAR,
            title: '',
        },
    ]

    const handleChangePeriod = (value: any[]) => {
        if (Array.isArray(value)) {
            setFieldsData((prev) => ({
                ...prev,
                startPeriod: moment(value[0]).format('YYYY-MM-DD'),
                endPeriod: moment(value[1]).format('YYYY-MM-DD'),
            }))

            onChange &&
                onChange({
                    ...fieldsData,
                    startPeriod: moment(value[0]).format('YYYY-MM-DD'),
                    endPeriod: moment(value[1]).format('YYYY-MM-DD'),
                })
        }
    }

    useEffect(() => {
        if (data.detalization) {
            setFieldsData((prev) => ({
                ...prev,
                detalization: data.detalization,
            }))
        }
    }, [data])

    useEffect(() => {
        const periodData = getMinPeriodFromTasksList(tasks)
        setFieldsData((prev) => ({
            ...prev,
            startPeriod: periodData.min,
            endPeriod: periodData.max,
        }))
        onChange &&
            onChange({
                detalization: fieldsData.detalization,
                startPeriod: periodData.min,
                endPeriod: periodData.max,
            })
    }, [tasks])

    return (
        <div className="roadMap-top-item roadMap-top-item-detalization">
            <div className="roadmap-filter-field-wrap">
                <div className="roadmap-filter-field-label">
                    {t('detalization')}
                </div>
                <SelectInLog
                    options={detailsSelectOptions}
                    value={fieldsData.detalization}
                    className="roadmap-filter-field"
                    onChange={(e) => {
                        setFieldsData((prev) => ({ ...prev, detalization: e }))
                        onChange && onChange({ ...fieldsData, detalization: e })
                    }}
                />
            </div>
            <div className="roadmap-filter-field-wrap">
                <div className="roadmap-filter-field-label">{t('period')}</div>
                <DatePicker.RangePicker
                    allowClear
                    format={'YYYY-MM-DD'}
                    value={[
                        moment(fieldsData.startPeriod),
                        moment(fieldsData.endPeriod),
                    ]}
                    className="datePicker-item"
                    locale={i18n.language === 'ru' ? ru_RU : en_EN}
                    onChange={handleChangePeriod}
                />
            </div>
        </div>
    )
}

export default RoadmapDetalizationFilter
