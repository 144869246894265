import { Skeleton } from 'antd'
import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import { tasksSelectors } from '../../store/tasks'
import { Task } from '../Task/Task'

const TasksList = ({ isLoading }: { isLoading: boolean }) => {
    const navigate = useNavigate()
    const tasksList = useSelector(tasksSelectors.selectTasks)

    const handleGetTask = (slug: string) => {
        navigate(slug)
    }

    const taksItems = useMemo(
        () =>
            tasksList.map((task) => {
                return (
                    <NavLink to={`${task?.slug}`} key={task?.id}>
                        <Task task={task} onClick={handleGetTask} />
                    </NavLink>
                )
            }),
        [tasksList],
    )

    return (
        <div className="scheduler__tasks-items">
            <SimpleBar
                autoHide={false}
                className={'scheduler__tasks-itemsList'}
            >
                {isLoading ? (
                    <div className="scheduler__tasks-skeleton-wrapper">
                        <TaskSkeletonItem />
                        <TaskSkeletonItem />
                        <TaskSkeletonItem />
                        <TaskSkeletonItem />
                    </div>
                ) : (
                    taksItems
                )}
            </SimpleBar>
        </div>
    )
}

const TaskSkeletonItem = () => {
    return (
        <Skeleton.Input
            active={true}
            className="scheduler__tasks-skeleton-item"
        />
    )
}

export default memo(TasksList)
