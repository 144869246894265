export const findColorByParam = (param) => {
    let color = ''
    switch (param) {
        case 'low':
            color = '#DEFF5B'
            break
        case 'medium':
            color = '#FFEC43'
            break
        case 'important':
            color = '#FF9255'
            break
        case 'critical':
            color = '#FF4545'
            break
        default:
            color = '#FFEC43'
    }
    return { backgroundColor: color }
}
