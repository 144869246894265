export const weekDaysMoc = {
  en: [
      {
          name:'Mon',
          id:7,
      },
      {
          name:'Tues',
          id:1,
      },
      {
          name:'Wed',
          id:2,
      },
      {
          name:'Thurs',
          id:3,
      },
      {
          name:'Fri',
          id:4,
      },
      {
          name:'Sat',
          id:5,
      },
      {
          name:'Sun',
          id:6,
      }
  ],
  ru: [
      {
          name:'Пн',
          id:7,
      },
      {
          name:'Вт',
          id:1,
      },
      {
          name:'Ср',
          id:2,
      },
      {
          name:'Чт',
          id:3,
      },
      {
          name:'Пт',
          id:4,
      },
      {
          name:'Сб',
          id:5,
      },
      {
          name:'Вс',
          id:6,
      }
  ]
}