import React, {FC, ReactElement} from 'react';
//@ts-ignore
import s from './mnemTopItem.module.scss'

interface ITopItem {
    icon: ReactElement
    topText: string
    bottomText?: string
}

export const MnemTopItem: FC<ITopItem> = ({icon, topText, bottomText}) => {
    return (
        <div className={s.topItem}>
            <div className={s.topItem__top}>
                <span>{topText}</span>
                {icon}
            </div>
            {
                bottomText &&
                <div className={s.topItem__bottom}>
                    {bottomText}
                </div>
            }

        </div>
    )
}