import React from 'react'

export const PriorityIcon = () => {
    return (
        <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="29" height="29" rx="1" fill="white" />
            <path d="M6 6H15H24.5" stroke="#FF4545" strokeWidth="2" />
            <path d="M6 12H12.3243H19" stroke="#FF9255" strokeWidth="2" />
            <path d="M6 18H10.3784H15" stroke="#FFEC43" strokeWidth="2" />
            <path d="M6 24H8.91892H12" stroke="#DEFF5B" strokeWidth="2" />
        </svg>
    )
}
