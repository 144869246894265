import { createSlice } from '@reduxjs/toolkit'
import { RequestStatusType } from '../../app/types/request'
import { setAppError, setAppIsInitialized, setAppStatus } from './actions'

const initialState: InitialStateType = {
    status: 'idle',
    error: null,
    isInitialized: false,
}

export const slice = createSlice({
    name: 'app',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setAppError, (state, action) => {
            state.error = action.payload.error
        })
        builder.addCase(setAppStatus, (state, action) => {
            state.status = action.payload.status
        })
        builder.addCase(setAppIsInitialized, (state, action) => {
            state.isInitialized = action.payload.isInitialized
        })
    },
})

export type InitialStateType = {
    // происходит ли сейчас взаимодействие с сервером
    status: RequestStatusType
    // если ошибка какая-то глобальная произойдёт - мы запишем текст ошибки сюда
    error: string | null
    isInitialized: boolean
}
