import { slice } from './projects-slice'
import * as projectsSelectors from './selectors'
import * as actions from './actions'

const projectsReducer = slice.reducer

const projectsActions = {
    ...slice.actions,
    ...actions,
}

export { projectsReducer, projectsSelectors, projectsActions }
