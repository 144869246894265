import React, {FC, useEffect, useState} from 'react';
import InputInLog from "../../../../../shared/input/Input";
import {useTranslation} from "react-i18next";
import './FieldsInterval.scss';
import {IFieldsIntervalProps} from "./types";
import {ValueTypes} from "../valueRangeBlock/types";

const FieldsInterval:FC<IFieldsIntervalProps> = (props) => {
    const {onChange,defaultData,activeRange,setIsErrorIntervalFields} = props
    const {t} = useTranslation()
    const [fromValue,setFromValue]=useState('')
    const [toValue,setToValue]=useState('')
    const [isErrorFromField,setIsErrorFromField] = useState(false)
    const [isErrorToField,setIsErrorToField] = useState(false)
    const [intervalError,setIntervalError] = useState('')

    const onChangeHandler = (e:string,type:'from' | 'to')=>{
        setIntervalError('')
        setIsErrorIntervalFields(false)
        let value = e.replace(/[^-\d]/g,'')
        if(type==='from'){
            if(toValue===''){
                setFromValue(value)
            }else{
                if(Number(toValue)>Number(value)){
                    setFromValue(value)
                }else if(value===''){
                    setFromValue(value)
                }else{
                    setIntervalError(`the minimum value must be less ${toValue}`)
                    setIsErrorIntervalFields(true)
                }
            }
            setFromValue(value)
        }else if(type==='to'){
            // setToValue(value)
            if(fromValue===''){
                setToValue(value)
            }else{
                if(Number(fromValue)<Number(value)){
                    setToValue(value)
                }else if(value===''){
                    setToValue(value)
                }else if(value==='-'){
                    setToValue(value)
                    setIntervalError(`the maximum value must be greater ${fromValue}`)
                    setIsErrorIntervalFields(true)
                }else{
                    setToValue(value)
                    setIntervalError(`the maximum value must be greater ${fromValue}`)
                    setIsErrorIntervalFields(true)
                }
            }
        }
    }

    const clearFields = ()=>{
        setFromValue('')
        setToValue('')
    }

    useEffect(()=>{
        if(defaultData?.value && Array.isArray(defaultData?.value)){
            setFromValue(defaultData?.value[0])
            setToValue(defaultData?.value[1])
        }else{
            clearFields()
        }
    },[defaultData])

    useEffect(()=>{
        onChange &&  onChange([fromValue,toValue])
    },[fromValue,toValue])


    useEffect(()=>{
        if(activeRange?.type===ValueTypes.INTERVAL){
            if(isErrorFromField || isErrorToField){
                setIsErrorIntervalFields(true)

            }else{
                if(intervalError?.length===0){
                    setIsErrorIntervalFields(false)
                }else{
                    setIsErrorIntervalFields(true)
                }
            }
        }
    },[isErrorFromField,isErrorToField,activeRange])

    return (
        <div>


        <div className={`fields-interval ${activeRange?.type!==ValueTypes.INTERVAL ? 'fields-interval--disabled' : ''} `}>
            <InputInLog value={fromValue}
                        onChange={(e)=>onChangeHandler(e,'from')}
                        // setIsError={setIsErrorFromField}
                        disabled={activeRange?.type!==ValueTypes.INTERVAL}
                        rules={[{isRequired:activeRange?.type===ValueTypes.INTERVAL}]}
                        className={intervalError?.length>0 ? 'fields-interval--input-error' : ''}
            />
            <span className={'font-10-normal'}>{t('to').toString()}</span>
            <InputInLog value={toValue}

                        onChange={(e)=>onChangeHandler(e,'to')}
                        // setIsError={setIsErrorToField}
                        disabled={activeRange?.type!==ValueTypes.INTERVAL}
                        rules={[{isRequired:activeRange?.type===ValueTypes.INTERVAL}]}
                        className={intervalError?.length>0 ? 'fields-interval--input-error' : ''}
            />
        </div>
        </div>

    );
};

export default FieldsInterval;