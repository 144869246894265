import React from 'react';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useRef, useState } from 'react';
import { POPOVER_MARGIN } from '../../app/constants';
import InfoIcon20 from '../../image_files/icons/InfoIcon20';
import './PopoverMenu.css';

interface IPopoverList {
  isOpenDetailInfo: boolean;
  isShowTitleIcon?: boolean;
  title?: string | React.ReactNode;
  mouseCoordinates: { x: number; y: number };
  children?: React.ReactNode;
  handleClose?: (value: boolean) => void;
}

const PopoverList: FC<IPopoverList> = (props) => {
  const {
    isOpenDetailInfo,
    isShowTitleIcon,
    title,
    mouseCoordinates,
    children,
    handleClose
  } = props;

  const divWrapperRef = useRef<HTMLDivElement>(null);
  const divContainerRef = useRef<HTMLDivElement>(null);
  const [raiseHeight, setRaiseHeight] = useState<number>(0);

  useEffect(() => {
    const divContainer: HTMLDivElement | null = divContainerRef.current;
    const heightFooter =
      document.querySelector('.app-bottom')?.clientHeight;

    if (divContainer && heightFooter) {
      const { height } = divContainer.getBoundingClientRect();
      const heightView = window.innerHeight - heightFooter;
      if (mouseCoordinates.y + height > heightView) {
        setRaiseHeight(
          height - heightView + mouseCoordinates.y + POPOVER_MARGIN
        );
      }
    }

    return () => {
      setRaiseHeight(0);
    };
  }, [isOpenDetailInfo]);

  return (
    <>
      {isOpenDetailInfo && (
        <div
          ref={divWrapperRef}
          className="popover-web-info-list-wrapper"
          onClick={() => handleClose && handleClose(false)}
        >
          {mouseCoordinates &&
            mouseCoordinates.x !== null &&
            mouseCoordinates.y !== null && (
              <div
                ref={divContainerRef}
                className="popover-web-info-list-container black-white-bg"
                style={{
                  right: window.innerWidth - mouseCoordinates.x,
                  top: mouseCoordinates.y - raiseHeight,
                }}
              >
                {title && title.toString().length > 0 && (
                  <div className="popover-web-info-list-title font-18-semibold black-white-color margin-b12">
                    {isShowTitleIcon && (
                      <div className="margin-r8">
                        <InfoIcon20 />
                      </div>
                    )}
                    {title}
                  </div>
                )}
                <div className="popover-web-info-list-body">{children}</div>
              </div>
            )}
        </div>
      )}
    </>
  );
};

export default PopoverList;
