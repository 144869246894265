import {IUploadFile} from "../../../../../../../../shared/uploadButton/types";

export interface ILoggingPanelProps {
    title: string
    plusIcon?: boolean
    openValue?: boolean
    tabActiveKey?: string
    tabKey?: string
    type?:LoggingPanelTypes
    onChange?:(data:IUploadFile,type:LoggingPanelTypes)=>void
    toOpenInput?: (value: boolean) => void
    setTabActiveKey?: (value: string) => void

}

export enum LoggingPanelTypes {
    PAD,
    WELL,
    CORE,
    WELL_LOGGING,
    PRODUCTION
}

