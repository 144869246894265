import React, { FC, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PriorityTypes } from '../../../../app/types/enums'
import { ITask } from '../../../../app/types/models/tasks'
import { IMember } from '../../../../app/types/models/users'
import { ClockIcon } from '../../../../image_files/icons/ClockIcon'
import { DoerIcon } from '../../../../image_files/icons/DoerIcon'
import { LightIcon } from '../../../../image_files/icons/LightIcon'
import { PriorityIcon } from '../../../../image_files/icons/PriorityIcon'
import { StatusIcon } from '../../../../image_files/icons/StatusIcon'
import { SuperVisorIcon } from '../../../../image_files/icons/SuperVisorIcon'
import { TagsIcon } from '../../../../image_files/icons/TagsIcon'
import { tasksService } from '../../../../services/tasks-services'
import { CustomDatePicker } from '../../../../shared/CustomDatePicker/CustomDatePicker'
import { MembersSelect } from '../../../../shared/MembersrSelect/MembersSelect'
import { PrioritySelect } from '../../../../shared/PrioritySelect/PrioritySelect'
import { TagsDropdown } from '../../../../shared/TagsDropdown/TagsDropdown'
import { membersSelectors } from '../../../../store/members'
import { tasksSelectors } from '../../../../store/tasks'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { showNotify } from '../../../../utils/helpers/showNotice'
import { DetailsName } from '../DetailsName/DetailsName'
import { DetailsSelect } from '../DetailsSelect/DetailsSelect'
import './Details.css'

interface IProps {
    task: ITask
    projectId: number

    setTask: (data: SetStateAction<ITask>) => void
}

export const Details: FC<IProps> = ({ task, setTask, projectId }) => {
    const [currentPriority, setCurrentPriority] = useState(task?.priority)
    const [currentStatus, setCurrentStatus] = useState<number | undefined>()
    const { t } = useTranslation()

    //selectors
    const members = useSelector(membersSelectors.selectMembers)
    const statuses = useSelector(tasksSelectors.selectStatuses)

    //handlers
    const selectPriorityHandler = async (value: PriorityTypes) => {
        try {
            if (projectId) {
                setCurrentPriority(value)
                await tasksService.updateTask(
                    { priority: value },
                    projectId,
                    task?.slug,
                )
                setTask((prev) => ({ ...prev, priority: value }))
                showNotify(t('priority-changed-successfully'))
            }
        } catch (e) {
            errorsHandler(e, t)
        }
        setCurrentPriority(value)
    }

    const selectStatusHandler = async (value: number) => {
        try {
            setCurrentStatus(value)
            await tasksService.updateTask(
                { status: value },
                task?.project,
                task?.slug,
            )
            setTask((prev) => ({ ...prev, status: value }))
            showNotify(t('status-changed-successfully'))
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const startDateHandler = (startDate) => {
        tasksService
            .updateTask({ ...startDate }, projectId, task?.slug)
            .then(() => {
                setTask((prev) => ({ ...prev, due_date_start: startDate }))
                showNotify(t('initial-time-changed-successfully'))
            })
            .catch((e) => {
                errorsHandler(e, t)
            })
    }
    const endDateHandler = (endDate) => {
        tasksService
            .updateTask({ ...endDate }, projectId, task?.slug)
            .then(() => {
                setTask((prev) => ({ ...prev, due_date_end: endDate }))
                showNotify(t('end-time-changed-successfully'))
            })
            .catch((e) => {
                errorsHandler(e, t)
            })
    }

    const addSuperVisor = (value: IMember) => {
        tasksService
            .addTaskSuperVisor(task?.project, task?.slug, {
                user: value?.user?.id,
                project: value?.project,
            })
            .then((res) => {
                const targetMember = members?.find(
                    (m) => m?.user?.id === res?.user,
                )
                if (targetMember) {
                    setTask((prev) => ({
                        ...prev,
                        supervisor: { ...targetMember, id: res?.id },
                    }))
                    showNotify(t('supervisor-added-successfully'))
                }
            })
            .catch((err) => {
                errorsHandler(err, t)
            })
    }
    const deleteSuperVisor = async (superVisorId: number) => {
        try {
            await tasksService.deleteTaskSuperVisor(
                task?.project,
                task?.slug,
                superVisorId,
            )
            showNotify(t('supervisor-deleted-successfully'))
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const addAssignee = async (value: IMember) => {
        try {
            await tasksService.addTaskDoer(task?.project, task?.slug, {
                user: value?.user?.id,
                project: value?.project,
            })
            setTask((prev) => ({
                ...prev,
                doers:
                    prev?.doers && prev.doers?.length > 0
                        ? [
                              { user: value.user, id: value?.user?.id },
                              ...prev.doers,
                          ]
                        : [{ user: value.user, id: value?.user?.id }],
            }))
            showNotify(t('assignee-added-successfully'))
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const deleteAssignee = async (assigneeId: number) => {
        try {
            await tasksService.deleteTaskAssignee(
                task?.project,
                task?.slug,
                assigneeId,
            )
            setTask((prev) => ({
                ...prev,
                doers: prev?.doers?.filter((el) => el?.id !== assigneeId),
            }))
            showNotify(t('assignee-deleted-successfully'))
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    useEffect(() => {
        setCurrentPriority(task?.priority)

        if (typeof task?.status !== 'number' && task?.status?.id) {
            setCurrentStatus(task?.status?.id)
        } else {
            setCurrentStatus(undefined)
        }
    }, [task?.id, projectId])

    return (
        <div className="details">
            <div className="details__column-left">
                <div className="details__column-priority details__column-item">
                    <DetailsName name={t('priority')} icon={<PriorityIcon />} />
                    <PrioritySelect
                        currentPriority={currentPriority}
                        style={{ transform: 'translateY(8px)' }}
                        onChange={selectPriorityHandler}
                    />
                </div>
                <div className="details__column-status details__column-item">
                    <DetailsName name={t('status')} icon={<StatusIcon />} />
                    <DetailsSelect
                        initialStatus={currentStatus}
                        statusesList={statuses ? statuses : []}
                        selectStatus={selectStatusHandler}
                    />
                </div>
                <div className="details__column-date details__column-item">
                    <DetailsName name={t('deadlines')} icon={<ClockIcon />} />
                    <CustomDatePicker
                        initialDate={{
                            due_date_start: task?.due_date_start,
                            due_date_end: task?.due_date_end,
                        }}
                        startDateHandler={startDateHandler}
                        endDateHandler={endDateHandler}
                    />
                </div>
                <div className="details__column-tags details__column-item">
                    <DetailsName name={t('tags')} icon={<TagsIcon />} />
                    <TagsDropdown projectId={projectId} currentTask={task} />
                </div>
            </div>
            <div className="details__column-right">
                <div className="details__column-right-names">
                    <DetailsName name={t('creator')} icon={<LightIcon />} />
                    <span className="details__column-creatorName">
                        {task?.creator?.full_name}
                    </span>
                </div>
                <MembersSelect
                    title={t('supervisor')}
                    className={'details__column-item addMemberBlock'}
                    addMember={addSuperVisor}
                    deleteMember={deleteSuperVisor}
                    members={members || []}
                    initialMembers={task?.supervisor}
                    membersType={'supervisor'}
                    icon={<SuperVisorIcon />}
                />
                <MembersSelect
                    title={t('assignees')}
                    className={'details__column-item addMemberBlock'}
                    addMember={addAssignee}
                    deleteMember={deleteAssignee}
                    members={members || []}
                    initialMembers={task?.doers}
                    membersType={'assignees'}
                    icon={<DoerIcon />}
                />
            </div>
        </div>
    )
}
