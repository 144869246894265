import { FC, useEffect, useState } from 'react'
import { PlusIcon } from '../../image_files/icons/PlusIcon'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { createSetting } from '../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/helpers/createSetting'
import { DENSITY } from '../../utils/constants'
import { updateSetting } from '../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/helpers/updateSetting'
import { deleteSetting } from '../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/helpers/deleteSetting'
import { fetchGroupAsync } from '../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/helpers/fetchGroupAsync'
import { getEntity } from '../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/helpers/getEntity'
import { TargetParameters } from '../../app/types/enums'
import StudyInput from '../../shared/studyInput/StudyInput'
import { addSetting } from '../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/helpers/addSetting'
import { standardStudyGroupService } from '../../services/standard-study-group-service'
import { densityFluidTypes } from '../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/helpers/defaultCoresData'
import SettingCancelIcon from '../../image_files/icons/SettingCancelIcon'
import './density.css'
import React from 'react'
import { IDensity } from '../../app/types/models/logging'
import { showNotice } from '../../utils/helpers/showNotice'

interface IProps {
    projectId: number | null
    coreId: number | null
    groupId: number | null

    setGroup: (value: number | null) => void
}

const defaultSetting = {
    value: '',
    equipment: null,
    method: null,
}

const Density: FC<IProps> = ({
    projectId,
    coreId,
    groupId,

    setGroup,
}) => {
    const [settingsData, setSettingsData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [currentEquipmentOptions, setCurrentEquipmentOptions] = useState(null)
    const [currentMethodOptions, setCurrentMethodOptions] = useState(null)
    const { t } = useTranslation()

    const checkIsReadyTheFields = (
        groupId: number,
        targetSetting: IDensity,
    ) => {
        return (
            groupId &&
            targetSetting.value !== '' &&
            Number(targetSetting.value) > 0
        )
    }

    const changeSetting = async (
        value: string,
        name: string,
        id: number,
    ): Promise<void> => {
        const changedSettings = { ...settingsData, [name]: value }
        if (settingsData && !Array.isArray(settingsData)) {
            setSettingsData(changedSettings)
            if (!groupId) {
                standardStudyGroupService
                    .createStandardGroup(projectId, coreId, { core: coreId })
                    .then((res) => {
                        setGroup(res.data.id)
                        if (
                            checkIsReadyTheFields(res.data.id, changedSettings)
                        ) {
                            createSetting({
                                projectId,
                                coreId,
                                groupId: res.data.id,
                                data: res.data,
                                groupType: 'standard',
                                settingType: DENSITY,
                                setData: setSettingsData,
                                t,
                            })
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                        toast.error(`${t('server-error')}`, {
                            position: 'top-center',
                        })
                    })
            } else {
                if (checkIsReadyTheFields(groupId, changedSettings)) {
                    await createSetting({
                        projectId,
                        coreId,
                        groupId,
                        data: changedSettings,
                        groupType: 'standard',
                        settingType: DENSITY,
                        setData: setSettingsData,
                        t,
                    })
                }
            }
        }
        //если работаем с массивом настроек
        if (Array.isArray(settingsData)) {
            let targetSetting = settingsData.find((item) => item.id === id)
            const changedSettingsData = settingsData.map((item) =>
                item.id === id
                    ? {
                          ...targetSetting,
                          [name]: value,
                      }
                    : item,
            )
            setSettingsData(changedSettingsData)
            if (
                (typeof id === 'string' &&
                    String(id).split('/')[1] === 'default') ||
                String(id) === ''
            ) {
                if (
                    checkIsReadyTheFields(groupId, {
                        ...targetSetting,
                        [name]: value,
                    })
                ) {
                    standardStudyGroupService
                        .createDensity(projectId, coreId, groupId, {
                            ...targetSetting,
                            [name]: value,
                        })
                        .then((res) => {
                            const createdSetting = {
                                ...res,
                                value: res.value_normalized,
                                method: null,
                                equipment: null,
                            }
                            const changedSettings = settingsData.map((item) =>
                                item.id === id ? createdSetting : item,
                            )
                            setSettingsData(changedSettings)
                            showNotice('setting-created-successfully', t)
                        })
                        .catch((e) => {
                            console.log(e)
                        })
                }
            } else {
                await updateSetting({
                    settingType: DENSITY,
                    groupType: 'standard',
                    settingId: id,
                    data: {
                        ...targetSetting,
                        [name]: value,
                    },
                    coreId,
                    groupId,
                    projectId,
                    t,
                })
            }
        }
    }

    const deleteItemHandler = async (item: any) => {
        await deleteSetting({
            settingType: DENSITY,
            groupType: 'standard',
            settingId: item.id,
            setLoading,
            setData: setSettingsData,
            data: settingsData,
            coreId,
            groupId,
            isReadyToSend: checkIsReadyTheFields(groupId, item),
            projectId,
            t,
        })
    }

    useEffect(() => {
        fetchGroupAsync({
            settingType: DENSITY,
            groupType: 'standard',
            setLoading,
            setData: setSettingsData,
            coreId,
            projectId,
            setGroup,
        })
        getEntity({
            id: projectId,
            type: 'equipment',
            parameter: TargetParameters.density,
            setData: setCurrentEquipmentOptions,
        }).then()
        getEntity({
            id: projectId,
            type: 'method',
            parameter: TargetParameters.density,
            setData: setCurrentMethodOptions,
        }).then()
    }, [coreId])

    const renderCommonBlock = (id, type, value, method, equipment) => {
        return (
            <>
                <StudyInput
                    dataList={densityFluidTypes(t)}
                    label={t('density-type')}
                    initialValue={type}
                    id={id}
                    name={'type'}
                    onChange={changeSetting}
                />
                <StudyInput
                    label={t('density-value')}
                    type={'positiveNumber'}
                    initialValue={value}
                    id={id}
                    name={'value'}
                    onChange={changeSetting}
                />
                <StudyInput
                    dataList={currentMethodOptions}
                    type={'addValueSelect'}
                    projectId={projectId}
                    label={t('method')}
                    initialValue={method}
                    id={id}
                    name={'method'}
                    target={TargetParameters.density}
                    onChange={changeSetting}
                />
                <StudyInput
                    dataList={currentEquipmentOptions}
                    type={'addValueSelect'}
                    projectId={projectId}
                    label={t('equipment')}
                    initialValue={equipment}
                    id={id}
                    name={'equipment'}
                    target={TargetParameters.density}
                    onChange={changeSetting}
                />
            </>
        )
    }

    if (loading) {
        return <div>...loading</div>
    }

    return (
        <div className={'coreStudySetting'}>
            <div className="coreStudySetting__container">
                <button
                    className={'addSettingBtn'}
                    onClick={() =>
                        addSetting(
                            groupId,
                            settingsData,
                            setSettingsData,
                            defaultSetting,
                        )
                    }
                >
                    <PlusIcon />
                </button>
                {Array.isArray(settingsData) ? (
                    settingsData.map((item) => (
                        <div
                            className="coreStudySetting__item densitySetting__item"
                            key={item.id}
                        >
                            {renderCommonBlock(
                                item.id,
                                item.type,
                                item.value,
                                item.method,
                                item.equipment,
                            )}
                            {
                                <button
                                    className={'deleteSettingBtn'}
                                    onClick={() => deleteItemHandler(item)}
                                >
                                    <SettingCancelIcon />
                                </button>
                            }
                        </div>
                    ))
                ) : settingsData ? (
                    <div className="coreStudySetting__item densitySetting__item">
                        {renderCommonBlock(
                            settingsData.id,
                            settingsData.type,
                            settingsData.value,
                            settingsData.method,
                            settingsData.equipment,
                        )}
                        {
                            <button
                                className={'deleteSettingBtn'}
                                onClick={() => deleteItemHandler(settingsData)}
                            >
                                <SettingCancelIcon />
                            </button>
                        }
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

export { Density }
