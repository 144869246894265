import React from 'react'
import { FC } from 'react'
import {IIconProps} from "../../app/types/i-props";
import './icon-styles.scss';
import {getValidText} from "../../utils/helpers/getValidText";

export const XIcon: FC<IIconProps> = ({ className,onClick,width,height }) => {
    return (
        <svg
            onClick={onClick ? onClick : ()=>{}}
            className={`cancel-icon ${getValidText(className)}`}
            width={  width ? width :"10"}
            height={height ? height :"9"}
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line
                x1="0.332182"
                y1="0.626295"
                x2="9.33218"
                y2="8.6263"
            />
            <line
                x1="0.332182"
                y1="0.626295"
                x2="9.33218"
                y2="8.6263"
            />
            <line
                x1="0.646447"
                y1="8.64645"
                x2="8.64645"
                y2="0.646446"
            />
            <line
                x1="0.646447"
                y1="8.64645"
                x2="8.64645"
                y2="0.646446"
            />
        </svg>
    )
}
