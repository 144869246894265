import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import './AddParameterZone.scss';
import { IAddGroupZoneProps } from "./types";
import { CustomGroupParameters, ICustomGroupParameter } from "../../types";
import CustomDropdownBlock from "./CustomDropdownBlock";
import { AddElementIcon } from "../../../../image_files/icons/AddElementIcon";
import SettingCancelIcon from "../../../../image_files/icons/SettingCancelIcon";
import NumericValueBlock from "./NumericValueBlock";
import PairsOfPointBlock from "./PairsOfPointBlock";
import { DescriptionArrowIcon } from "../../../../image_files/icons/DescriptionArrowIcon";
import { v4 as uuidv4 } from 'uuid'
import { Draggable, Droppable } from "react-beautiful-dnd";
import { getItemStyle, getListStyle } from "./valueRangeBlock/data";

const AddParameterZone: FC<IAddGroupZoneProps> = (props) => {
    const {
        type,
        parameter,
        parameters = [],
        droppableId,
        activeParameter,
        isErrorActiveElement,

        setParameter,
        addParameter,
        setActiveParameter,
        setIsErrorActiveElement,
        changeParametersValidStatus
    } = props
    const { t } = useTranslation()
    const uid = uuidv4()
    const [isReadyToAdd, setIsReadyToAdd] = useState(true)
    const [isDragging, setIsDragging] = useState(false)

    const getBlockClass = useCallback(() => {
        if (activeParameter?.type === CustomGroupParameters.CUSTOM_DROPDOWN) {
            return 'add-parameter-zone-space__orange'
        } else if (activeParameter?.type === CustomGroupParameters.NUMERIC_VALUE) {
            return 'add-parameter-zone-space__gray'
        } else if (activeParameter?.type === CustomGroupParameters.SET_OF_PAIRS) {
            return 'add-parameter-zone-space__green'
        } else return ''
    }, [activeParameter])

    const addNewParameter = (data: ICustomGroupParameter) => {
        setParameter(data)
    }

    const showBlockByType = () => {
        switch (activeParameter?.type) {
            case CustomGroupParameters.CUSTOM_DROPDOWN:
                return <CustomDropdownBlock className={getBlockClass()}
                    activeParameter={activeParameter}
                    addParameter={addNewParameter}
                    setIsError={setIsErrorActiveElement}
                />
            case CustomGroupParameters.NUMERIC_VALUE:
                return <NumericValueBlock className={getBlockClass()}
                    activeParameter={activeParameter}
                    addParameter={addNewParameter}
                    setIsError={setIsErrorActiveElement}
                />
            case CustomGroupParameters.SET_OF_PAIRS:
                return <PairsOfPointBlock className={getBlockClass()}
                    activeParameter={activeParameter}
                    addParameter={addNewParameter}
                    setIsError={setIsErrorActiveElement}
                />
            default:
                return ''
        }
    }

    const clearGroupFields = useCallback(() => {
        const activeGroupType = activeParameter?.type
        const setIdByType = () => activeGroupType === CustomGroupParameters.CUSTOM_DROPDOWN ? '1' : activeGroupType === CustomGroupParameters.NUMERIC_VALUE ? '2' : '3'
        setActiveParameter({
            type: activeGroupType,
            id: setIdByType(),
            data: {},
            isValidField: !isErrorActiveElement
        })
    }, [activeParameter, isErrorActiveElement])

    const addParameterHandler = () => {
        addParameter({ ...parameter, id: uid, isValidField: !isErrorActiveElement })
        clearGroupFields()
        setIsReadyToAdd(false)
        setTimeout(() => {
            setIsReadyToAdd(true)
        }, 500)
    }

    const showValidIcon = useCallback(() => {
        return (
            <AddElementIcon
                className={!isReadyToAdd ? 'add-parameter-zone--add-icon--disabled' : ''}
                onClick={isReadyToAdd && !getDragDisableStatus() ? addParameterHandler : () => {
                }}
            />
        )

    }, [parameters, parameter])

    const addParameterCashed = useCallback(() => {
        if (parameters) {
            const targetParameter = parameters && parameters.find(item => {
                return item && item.id === parameter?.id
            })
            if (targetParameter) {
                addParameter({
                    ...parameter,
                    isValidField: !isErrorActiveElement
                })
            }
            return targetParameter
        } else return {}
    }, [parameters, parameter])

    const getDragDisableStatus = () => {
        if (parameters.length > 0) {
            if (parameters.length < 6) {
                if (parameters.length === 1 && parameters[0]?.type === CustomGroupParameters.SET_OF_PAIRS) {
                    changeParametersValidStatus(false)
                    return true
                } else {
                    if (parameters.length > 1 && activeParameter?.type === CustomGroupParameters.SET_OF_PAIRS) {
                        changeParametersValidStatus(false)
                        return true
                    } else {
                        changeParametersValidStatus(true)
                        return false
                    }
                }
            } else return true

        } else {
            changeParametersValidStatus(true)
            return false
        }
    }

    console.log(droppableId, '--dropabbleId')
    console.log(isDragging, '--isDragging')

    return (
        <div className={'add-parameter-zone'}>
            <div className={'add-parameter-zone-top font-14-normal'}>
                <div className={'row-flex-10 font-14-normal'}>
                    <span>{t('input-element')}</span>
                    {activeParameter.id.toString().length === 1 && !getDragDisableStatus() && showValidIcon()}
                </div>
                <SettingCancelIcon onClick={clearGroupFields} width={25} height={25} viewBox={'9 9 18 18'} />
            </div>
            <div className='add-parameter-zone-description'>
                <span className={'font-10-normal'}>{t('fill-form').toString()}</span>
            </div>
            <div>
                <Droppable
                    key={droppableId}
                    type={type}
                    droppableId={droppableId}
                    isDropDisabled={true}
                    direction={'horizontal'}
                    
                >
                    {
                        (provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(
                                    snapshot.isDraggingOver,
                                )}
                            >
                                <Draggable
                                    draggableId={parameter?.id ? parameter.id : uid}
                                    index={parameters?.length}
                                    key={parameters?.length}
                                    isDragDisabled={getDragDisableStatus()}
                                >
                                    {
                                        (provided, snapshot) => {
                                            setIsDragging(snapshot.isDragging)
                                            return (
                                                <div
                                                    {...provided.draggableProps}
                                                    ref={provided.innerRef}
                                                    key={parameters?.length + 2}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style,
                                                    )}
                                                    className={snapshot.isDragging ? 'add-parameter-zone-form-wrapper--isDragging' : 'add-parameter-zone-form-wrapper'}
                                                >
                                                    <div {...provided.dragHandleProps}
                                                        className={`${snapshot.isDragging ? 'add-parameter-zone-form-item--isDragging' : 'add-parameter-zone-form-item'} ${parameters?.length >= 6 ? 'add-parameter-zone-form-item--disabled' : ''} `}>
                                                        <div className='add-parameter-zone-draggable-parameter' 
                                                        >
                                                            <span>{t('drag-element')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                </Draggable>
                                {/* {provided.placeholder} */}
                            </div>

                        )

                    }

                </Droppable>
                <div className='add-parameter-zone-active-form'
                    style={{
                        // display: isDragging ? 'none' : 'block'
                        opacity: isDragging ? 0 : 1,
                        // transition:'0.2'
                    }}

                >


                    {showBlockByType() as any}
                </div>
            </div>
            <div className='row-flex-5 mt-10'>
                <span
                    className={'font-10-normal'}>{t('put-to-parameter-constructor').toString()}</span>
                <DescriptionArrowIcon />
            </div>
        </div>
    );
};

export default AddParameterZone;