import {useSelector} from 'react-redux'
import React, {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {authService} from '../../services/auth-service'
import {Form, Input} from 'antd'
import {useForm} from 'antd/es/form/Form'
import {getLocalizedText} from '../../utils/helpers/getLocalizedText'
import {routes} from '../../utils/constants'
import '../../styles/commonSign.css'
import {LargeSpin} from '../../shared/LargeSpin'
import {errorsHandler} from '../../utils/helpers/errors/errors-hendler'
import LockIcon from "../../image_files/icons/LockIcon";
import SignHeader from "../../shared/signHeader/SignHeader";

function PasswordResetConfirm() {
    const email = useSelector((state: any) => state.data)
    const navigate = useNavigate()
    const [url] = useState(window.location.href)
    const uid = url.slice(url.indexOf('=') + 1, url.indexOf('&'))
    const token = url.slice(url.lastIndexOf('=') + 1, url.length)
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [form] = useForm()


    const onFinish = async (values) => {
        const data = {
            new_password1: values.password1,
            new_password2: values.password2,
            uid,
            token,
        }
        try {
            setLoading(true)
            const response = await authService.passwordResetConfirm(data)
            if (response.detail) {
                setLoading(false)
                toast.success(response.detail, {
                    position: 'top-center',
                })
                navigate(routes.RECOVERY_CHANGE)
                form.resetFields()
            } else {
            }
        } catch (e) {
            setLoading(false)
            errorsHandler(e, t)
            console.log(e)
        }
    }

    if (loading) {
        return <LargeSpin/>
    } else
        return (
            <div className="signBlock">
                <SignHeader
                    buttonRoute={{link:routes.REGISTRATION,name:t('sign-in')}}
                />
                <div className="signWrapper ">
                    <Form
                        className="signForm"
                        onFinish={onFinish}
                        name="passwordRecovery"
                        scrollToFirstError
                    >
                        <h2 className="signForm__header">
                            {getLocalizedText('password-recovery', t)}
                        </h2>
                        <p className="signForm__topContent">{email}</p>
                        <div className={'signForm__inputWrapper'}>
                            <Form.Item
                                name={'password1'}
                                rules={[
                                    {
                                        required: true,
                                        message: t('password-must-has-more-than-value-symbols',{value:8}),
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password
                                    className="signForm__input"
                                    placeholder={t('password')}
                                    prefix={<LockIcon/>}
                                />
                            </Form.Item>
                            <Form.Item
                                name={'password2'}
                                rules={[
                                    {
                                        required: true,
                                        message: t('password-must-has-more-than-value-symbols',{value:8}),
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue('password1') ===
                                                value
                                            ) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    t(
                                                        'both-passwords-dont-match',
                                                    ),
                                                ),
                                            )
                                        },
                                    }),
                                ]}
                                hasFeedback
                            >
                                <Input.Password
                                    className="signForm__input"
                                    placeholder={t(
                                        'repeat-password',
                                    )}
                                    prefix={<LockIcon/>}
                                />
                            </Form.Item>

                            <div
                                className={'signForm__btn-wrapper'}
                                style={{textAlign: 'center'}}
                            >
                                <button
                                    type={'submit'}
                                    className={
                                        'signForm__submitBtn cursorPointer'
                                    }
                                >
                                    {getLocalizedText(
                                        'start',
                                        t,
                                    )}
                                </button>
                            </div>
                            <Link
                                to={routes.LOGIN}
                                className="signForm__textLink "
                            >
                                {getLocalizedText(
                                    'sign-in',
                                    t,
                                )}
                            </Link>
                        </div>
                    </Form>
                </div>
            </div>
        )
}

export default PasswordResetConfirm
