import React from 'react'
import { IDoer, IMember, ISupervisor, IUser } from '../../../../app/types/models/users'
import { MembersItem } from '../../../../shared/MembersItem/MembersItem'
import './CreatorWithAssignees.css'
interface IProps {
    supervisor: ISupervisor
    assignees: IDoer[]
}

const SupervisorWithAssignees = ({ supervisor, assignees }: IProps) => {
    const makeBlocksLeftShift = (i: number) => {
        if (i > 0) {
            return { transform: `translateX(-${3.5 * i}px)` }
        } else return undefined
    }

    const showAssignees = () => {
        if (assignees.length <= 3) {
            return assignees.map((el,i) => (
                <div key={el?.id} style={makeBlocksLeftShift(i+1)}>
                    <MembersItem
                        member={{ user: el?.user } as IMember}
                        isHideText={true}
                    />
                </div>
            ))
        } else {
            return assignees.slice(0, 2).map((el,i) => (
                <div key={el?.id} style={makeBlocksLeftShift(i+1)}>
                    <MembersItem
                        member={{ user: el?.user } as IMember}
                        isHideText={true}
                    />
                </div>
            ))
        }
    }

    const showRestAssigneesCount = () => {
        if (assignees?.length > 3) {
            return (
                <div
                    className="creator-with-assignees-rest-indicator"
                    style={makeBlocksLeftShift(3)}
                >
                    <span>{`+${assignees.length - 2}`}</span>
                </div>
            )
        } else return undefined
    }

    return (
        <div className="creator-with-assignees-wrapper">
            {supervisor && (
                <MembersItem
                    member={{ user: supervisor?.user } as IMember}
                    isHideText={true}
                />
            )}
            {assignees && assignees?.length > 0 && (
                <div className="creator-with-assignees-list">
                    {showAssignees()}
                    {showRestAssigneesCount()}
                </div>
            )}
        </div>
    )
}

export default SupervisorWithAssignees
