import { FC, useContext } from 'react';
import { IIconProps } from '../../app/types/i-props';
import React from 'react';

const ArrowShortLeft: FC<IIconProps> = ({
  fill,
  width,
  height,
  viewBox,
  onClick
}) => {
  return (
    <svg
      width={width ? width : 24}
      height={height ? height : 24}
      viewBox={viewBox ? viewBox : '0 0 24 24'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M14.5 7L10.2071 11.2929C9.81658 11.6834 9.81658 12.3166 10.2071 12.7071L14.5 17"
        stroke={fill ? fill : '#364f6b'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowShortLeft;