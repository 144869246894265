import React, { useCallback, useEffect, useState } from 'react'
import Clipboard from 'react-clipboard.js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CopyDataIcon } from '../../image_files/icons/CopyDataIcon'
import { tasksService } from '../../services/tasks-services'
import { EditableSpan } from '../../shared/EditableSpan/EditableSpan'
import { CustomAccordion } from '../../shared/customAccordion/CustomAccordion'
import PopoverInLog from '../../shared/popoverInlog/PopoverInLog'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { tasksActions, tasksSelectors } from '../../store/tasks'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { showNotice } from '../../utils/helpers/showNotice'
import { Comment } from './components/Comment/Comment'
import { Description } from './components/Description/Description'
import { Details } from './components/Details/Details'
import { SubTask } from './components/SubTask/SubTask'
import TasksFiles from './components/TaskFiles/TasksFiles'
import TasksBoard from './components/tasksBoard/TasksBoard'
import './taskEditSpace.css'
import { getTaskSlugFromUrl } from '../../services/get-task-slug-from-url'
import { IComment, ISubTask, ITask } from '../../app/types/models/tasks'
import { createPortal } from 'react-dom'
import SpinInLog from '../../shared/spin/spin'

export const TaskEditSpace = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const copyLink = window.location.href
    const [loading, setLoading] = useState(false)
    const [errorTitle, setErrorTitle] = useState('')
    const taskSlug = getTaskSlugFromUrl(location.pathname)
    const tasksList = useSelector(tasksSelectors.selectTasks)
    const [comments, setComments] = useState<IComment[]>([])
    const currentProject = useSelector(selectCurrentProject)
    const [currentTask, setCurrentTask] = useState<ITask>({} as ITask)

    const changeTaskTitleHandler = async (value: string) => {
        try {
            setLoading(true)
            await tasksService.updateTask(
                { name: value },
                currentProject?.id,
                taskSlug,
            )
            setCurrentTask((prev) => ({ ...prev, name: value }))
            showNotice('name-changed-successfully', t)
            setLoading(false)
        } catch (error) {
            setErrorTitle('error')
            setLoading(false)
            errorsHandler(error, t)
        }
    }

    const getTask = useCallback(
        async (projectId: number, taskSlug: string) => {
            try {
                setLoading(true)
                const res = await tasksService.getCurrentTask(
                    projectId,
                    decodeURIComponent(taskSlug),
                )
                setCurrentTask(res)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                errorsHandler(error, t)
            }
        },
        [currentProject?.id, location.pathname, location.pathname],
    )

    const fetchComments = async () => {
        try {
            if (currentProject?.id && currentTask?.slug) {
                setLoading(true)
                const res = await tasksService.getTaskComments(
                    currentProject?.id,
                    currentTask?.slug,
                )
                setComments(res || [])
                setLoading(false)
            }
        } catch (error) {
            errorsHandler(error, t)
            setLoading(false)
        }
    }

    const saveComment = async (value: string) => {
        try {
            setLoading(true)
            const res = await tasksService.addTaskComment(
                currentProject?.id,
                currentTask?.slug,
                { text: value },
            )
            setComments((prev) => [...prev, res])
            setLoading(false)
        } catch (error) {
            setLoading(false)
            errorsHandler(error, t)
        }
    }

    useEffect(() => {
        if (currentProject?.id) {
            getTask(currentProject?.id, getTaskSlugFromUrl(location.pathname))
        }
    }, [currentProject?.id, location.pathname])

    useEffect(() => {
        fetchComments()
    }, [currentProject?.id, currentTask])

    if (tasksList.length === 0) {
        return <div className="scheduler__clearSpace"></div>
    }

    return (
        <div className={'scheduler__tasksSettings'}>
            {createPortal(<SpinInLog isLoading={loading} />, document.body)}
            <div className="scheduler__tasksSettings-inner">
                <div className="scheduler__tasksSettings-top">
                    <PopoverInLog
                        content={
                            <div
                                className={
                                    'font-12-normal font-white scheduler__tasksSettings-popover'
                                }
                            >
                                {t('copy-link').toString()}
                            </div>
                        }
                    >
                        <Clipboard
                            data-clipboard-text={copyLink}
                            className="taskEditSpace__clipboard"
                            onSuccess={() => {
                                toast.success(`${t('copy-link')}`, {
                                    position: 'top-center',
                                })
                            }}
                        >
                            <CopyDataIcon />
                        </Clipboard>
                    </PopoverInLog>
                    <TasksBoard
                        task={currentTask}
                        projectId={currentProject?.id}
                    />
                </div>
                <EditableSpan
                    handler={changeTaskTitleHandler}
                    title={currentTask?.name}
                    error={errorTitle}
                    setError={setErrorTitle}
                    className={'scheduler__taskEditSpace-description'}
                    spanClassName={'scheduler__taskEditSpace-editSpan'}
                    inputClassName={'scheduler__taskEditSpace-editInput'}
                />
                <div className="scheduler__taskEditSpace-scrollbarInner">
                    <CustomAccordion
                        title={t('details')}
                        activeStatus
                        className="scheduler__tasksSettings-description"
                    >
                        <Details
                            task={currentTask}
                            setTask={setCurrentTask}
                            projectId={currentProject?.id}
                        />
                    </CustomAccordion>
                    <CustomAccordion title={t('description')} activeStatus>
                        <>
                            <Description
                                key={currentTask?.id}
                                initialText={currentTask?.description}
                                projectId={currentTask?.project}
                                taskSlug={currentTask?.slug}
                            />
                            <SubTask task={currentTask} />
                        </>
                    </CustomAccordion>
                    <TasksFiles task={currentTask} setTask={setCurrentTask} />
                    <CustomAccordion
                        activeStatus={true}
                        title={t('comments')}
                        className="scheduler__tasksSettings-comment"
                    >
                        <Comment onSave={saveComment} comments={comments} />
                    </CustomAccordion>
                </div>
            </div>
        </div>
    )
}
