import { ICoreBoxIntervalRequest, ICoreBoxIntervalResponse } from "../../../../../app/types/dto/core-box-data";
import { ISelectInterval } from "../types";

export const convertIntervalForRequest = (interavlArg: ISelectInterval): ICoreBoxIntervalRequest => {
  return {
    actual_interval_min: interavlArg.interval?.actual[0],
    actual_interval_max: interavlArg.interval?.actual[1],
    vertical_interval_min: interavlArg.interval?.vertical[0],
    vertical_interval_max: interavlArg.interval?.vertical[1],
  }
}

export const convertIntervalFromResponse = (intervalArg: ICoreBoxIntervalResponse): ISelectInterval => {
  return {
    id: intervalArg?.id,
    type: 'vertical',
    interval: {
      vertical: [intervalArg.vertical_interval_min, intervalArg.vertical_interval_max],
      actual: [intervalArg.actual_interval_min, intervalArg.actual_interval_max]
    },
    photoBoxes: intervalArg.photos.map(el=>{
      return {
        id:el?.id,
        photo:{
          medium:el?.photo,
          light:el?.light
        }
      }
    }),
    selected: false,
    isNew:false
  }
}