export interface IValueRangeProps{
    defaultData:IValueRangeItem
    setIsErrorIntervalFields:(status:boolean)=>void
    onChange:(value:IValueRangeItem)=>void
}

export interface IValueRangeItem {
    type:string
    value:any
}

export enum ValueTypes{
    ANY='any',
    POSITIVE='positive-number',
    INTERVAL='interval'
}