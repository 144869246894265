import React, {FC, memo, useEffect, useRef, useState} from 'react'
import {Divider, Input, Select} from 'antd'
import './studyInput.css'
import {SelectIcon} from '../../image_files/icons/SelectIcon'
import {NumericFormat} from 'react-number-format'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {PlusIcon} from '../../image_files/icons/PlusIcon'
import useDebounce from '../../hooks/useDebounce'
import {entitiesServices} from '../../services/entities-services'
import {errorsHandler} from '../../utils/helpers/errors/errors-hendler'
import {researchEntitiesActions} from '../../store/researchEntities'
import {getEntity} from "../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/helpers/getEntity";
import {IProps} from "./types";
import {namesList} from "./data";
import { createPortal } from 'react-dom'
import SpinInLog from '../spin/spin'

const StudyInput: FC<IProps> = ({
    id,
    name,
    label,
    target,
    dataList,
    projectId,
    initialValue,
    type = 'select',

    onChange,
}) => {
    const { t } = useTranslation()
    const [inputName, setInputName] = useState('')
    const inputRef = useRef(null)
    const [numericValue, setNumericValue] = useState(initialValue)
    const [isErrorNumericField, setIsErrorNumericField] = useState(false)
    const [positiveNumberValue, setPositiveNumberValue] = useState(initialValue)
    const [fetchedItems, setFetchedItems] = useState(dataList ? dataList : [])
    const [selectValue, setSelectValue] = useState(null)
    const dispatch = useDispatch()
    const selectRef = useRef(null)
    const addBtnRef = useRef(null)
    const debouncedPositiveValue = useDebounce(positiveNumberValue, 500)
    const { addEntity } = researchEntitiesActions
    const changeSelectHandler = (e) => {
        onChange(e, name, id)
    }

    const onKeyDownSendNumericValue = (e) => {
        if (e.keyCode === 13) {
            onChange(numericValue, name, id)
            setIsErrorNumericField(false)
        }
    }

    const onKeyDownSendPositiveValue = (e) => {
        if (e.keyCode === 13) {
            onChange(positiveNumberValue, name, id)
        }
    }

    //для name=value
    const changePositiveInputHandler = (e) => {
        setPositiveNumberValue(e.currentTarget.value)
    }

    const changeNumericInputHandler = (e) => {
        //numericValue && Number(numericValue) && numericValue.toString().length < 4 ||
        setNumericValue(e.currentTarget.value)
        if (e.currentTarget.value.length > 3) {
            onChange(e.currentTarget.value, name, id)
            setIsErrorNumericField(false)
        } else {
            setIsErrorNumericField(true)
        }
    }

    const addItem = (e) => {
        e.preventDefault()
        if (name === 'method') {
            entitiesServices
                .addMethod(projectId, {
                    name: inputName,
                    project: projectId,
                    target_parameter: target,
                })
                .then((res) => {
                    dispatch(
                        addEntity({ entity: res.data, type: 'methods' }),
                    )
                    setFetchedItems([...fetchedItems, res.data])
                    onChange(res.data.id, name, id)
                    setSelectValue(res.data.id)
                    selectRef.current.scrollTo(res.data.id)
                    setInputName('')
                    setTimeout(() => {
                        inputRef.current?.focus()
                    }, 0)
                })
                .catch((e) => {
                    errorsHandler(e,t)
                    console.log(e)
                })
        }
        if (name === 'equipment') {
            entitiesServices
                .addEquipment(projectId, {
                    name: inputName,
                    project: projectId,
                    target_parameter: target,
                })
                .then((res) => {
                    dispatch(
                        addEntity({ entity:res, type: 'equipments' }),
                    )
                    setFetchedItems([...fetchedItems, res])
                    onChange(res?.id, name, id)
                    setSelectValue(res?.id)
                    selectRef.current.scrollTo(res?.id)
                    setInputName('')
                    setTimeout(() => {
                        inputRef.current?.focus()
                    }, 0)
                })
                .catch((e) => {
                    errorsHandler(e, t)
                    console.log(e)
                })
        }

        setTimeout(() => {
            inputRef.current?.focus()
        }, 0)
    }

    //input внутри addValueSelect
    const onNameChangeHandler = (event) => {
        setInputName(event.currentTarget.value)
    }

    //по клику на опцию в addValueSelect происходит отправка запроса
    const addValueSelectHandler = (e) => {
        setSelectValue(e)
        onChange(e, name, id)
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            addBtnRef.current.click()
        }
    }

    useEffect(() => {
        setSelectValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        if (
            debouncedPositiveValue &&
            onChange &&
            name &&
            initialValue !== debouncedPositiveValue
        ) {
            onChange(debouncedPositiveValue, name, id)
        }
    }, [debouncedPositiveValue])

    const onDropdownVisibleGetEntities = async (value:boolean)=>{
        if(value){
            await getEntity({
                id:projectId,
                //@ts-ignore
                type:name,
                setData:setFetchedItems,
                parameter:target
            })
        }
    }

    if(name==='method'){
        console.log(fetchedItems,'--fetchedItems, method')
    }

    if(name==='equipment'){
        console.log(fetchedItems,'--fetchedItems, equipment')
    }

    useEffect(()=>{
        setFetchedItems(dataList)
    },[dataList])
    return (
        <div className={'coreStudyInput'}>
            {createPortal(<SpinInLog isLoading={false}/>,document.body)}
            {type === 'select' ? (
                <Select
                    options={dataList ? dataList : undefined}
                    className={
                        namesList.includes(name) && !initialValue
                            ? 'selectError'
                            : 'coreStudyInput__select'
                    }
                    dropdownStyle={{minWidth:'150px'}}
                    suffixIcon={<SelectIcon />}
                    defaultValue={initialValue}
                    onChange={changeSelectHandler}
                />
            ) : type === 'addValueSelect' ? (
                <Select
                    onChange={addValueSelectHandler}
                    defaultValue={initialValue}
                    suffixIcon={<SelectIcon />}
                    value={selectValue}
                    ref={selectRef}
                    onDropdownVisibleChange={onDropdownVisibleGetEntities}
                    dropdownRender={(menu) => {
                        return (
                            <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <div className={'addValueSelect__bottom'}>
                                    <Input
                                        className={'coreStudyInput__input'}
                                        style={{
                                            background: '#fff',
                                            height: 28,
                                        }}
                                        ref={inputRef}
                                        value={inputName}
                                        onKeyDown={onKeyDownHandler}
                                        onChange={onNameChangeHandler}
                                        placeholder={t(
                                            'enter-value',
                                        )}
                                    />
                                    <button
                                        ref={addBtnRef}
                                        className={'addValueSelect__btn'}
                                        onClick={addItem}
                                    >
                                        <PlusIcon />
                                    </button>
                                </div>
                            </>
                        )
                    }}
                    options={
                        fetchedItems &&
                        fetchedItems?.length > 0 &&
                        fetchedItems?.map((item) => ({
                            label: item.name.toLowerCase(),
                            value: item.id,
                        }))
                    }
                />
            ) : type === 'positiveNumber' ? (
                <NumericFormat
                    className={
                        Number(positiveNumberValue) < 0 ||
                        !positiveNumberValue ||
                        Number(positiveNumberValue) === 0
                            ? 'coreStudyInput__input coreStudyInput__numeric inputError'
                            : 'coreStudyInput__input coreStudyInput__numeric'
                    }
                    value={positiveNumberValue}
                    style={{ height: 28 }}
                    onChange={changePositiveInputHandler}
                    onKeyDown={onKeyDownSendPositiveValue}
                    decimalScale={2}
                    isAllowed={(values) => {
                        const { floatValue } = values
                        return floatValue >= 0 && floatValue <= 2147483647
                    }}
                />
            ) : (
                <NumericFormat
                    className={
                        isErrorNumericField || !numericValue
                            ? 'coreStudyInput__input coreStudyInput__numeric inputError'
                            : 'coreStudyInput__input coreStudyInput__numeric'
                    }
                    maxLength={11}
                    value={numericValue}
                    style={{ height: 28 }}
                    onKeyDown={onKeyDownSendNumericValue}
                    onChange={changeNumericInputHandler}
                    decimalScale={2}
                    isAllowed={(values) => {
                        const { floatValue } = values
                        return floatValue >= 0 && floatValue <= 1
                    }}
                />
            )}
            <span className={'coreStudyInput__label'}>{label}</span>
        </div>
    )
}

export default memo(StudyInput)
