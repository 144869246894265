import React, { FC, useEffect, useState } from 'react'
import SelectInputBlock from '../../../../../../shared/selectInputBlock/SelectInputBlock'
import { CompleteIcon } from '../../../../../../image_files/icons/CompleteIcon'
import SettingCancelIcon from '../../../../../../image_files/icons/SettingCancelIcon'
import { IMnemonic } from '../../../../../../app/types/models/IMnemonic'
import { SelectInputTypes } from '../../../../../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/types'
import { ITableItemProps, MnemColumnTypes } from './types'
import './mnemTable.css'
import InputInLog from '../../../../../../shared/input/Input'

export const MnemTableItem: FC<ITableItemProps> = (props) => {
    const {
        data,
        orderIndex,
        activeLang,
        mnemonics,
        
        onSave,
        onDelete,
        setMnemonicStatus,
    } = props
    const [rowData, setRowData] = useState<IMnemonic>(data)
    const [isErrorWellLog, setIsErrorWellLog] = useState(false)
    const [isErrorMnemonic, setIsErrorMnemonic] = useState(false)
    const [isErrorUnit, setIsErrorUnit] = useState(false)
    const [isErrorDescription, setIsErrorDescription] = useState(false)
    const [isErrorMnemonicsList, setIsErrorMnemonicsList] = useState(false)

    const errorStatusList = [
        isErrorWellLog,
        isErrorMnemonic,
        isErrorUnit,
        isErrorDescription,
        isErrorMnemonicsList,
    ]

    console.log('errorStatusList', errorStatusList)

    const changeSelectInputField = (data: any, fieldName: MnemColumnTypes) => {
        switch (fieldName) {
            case 'log':
                setRowData({
                    ...rowData,
                    log_en: data.en,
                    log_ru: data.ru,
                })
                break
            case 'inlog_mnemonic':
                setRowData({
                    ...rowData,
                    inlog_mnemonic: data,
                })
                break
            case 'inlog_unit_and_conversion':
                setRowData({
                    ...rowData,
                    inlog_unit_and_conversion_en: data.en,
                    inlog_unit_and_conversion_ru: data.ru,
                })
                break
            case 'inlog_description':
                setRowData({
                    ...rowData,
                    inlog_description_en: data.en,
                    inlog_description_ru: data.ru,
                })
                break
            case 'inlog_mnemonics':
                setRowData({
                    ...rowData,
                    possible_mnemonics: data,
                })
                break
            default:
                return
        }
    }
    const setMnemonicFieldStatus = () => {
        const changedData = {
            ...rowData,
            constant_mnemonic: !rowData.constant_mnemonic,
        }
        setRowData(changedData)
    }

    const handleDelete = () => {
        onDelete(data.id,!errorStatusList.includes(true))
    }

    useEffect(() => {
        data && setRowData(data)
    }, [])

    //save data if all fields in row is active
    useEffect(() => {
        if (JSON.stringify(data) === JSON.stringify(rowData)) {
            setMnemonicStatus(null)
        } else {
            if (errorStatusList.includes(true)) {
                setMnemonicStatus({ id: data?.id, status: 'not completed' })
            } else {
                setMnemonicStatus({ id: data?.id, status: 'ready' })
                onSave(data.id, rowData)
            }
        }
    }, [rowData])

    return (
        <div className={'mnem-table-item'}>
            {errorStatusList.includes(true) ? <div></div> : <CompleteIcon />}

            <SelectInputBlock
                type={SelectInputTypes.input}
                activeLang={activeLang}
                id={'wellLog' + data.id}
                disabled={orderIndex===0}
                
                onChange={(value) =>
                    changeSelectInputField(value, MnemColumnTypes.log)
                }
                defaultData={{
                    ru: rowData.log_ru,
                    en: rowData.log_en,
                }}
                setIsError={setIsErrorWellLog}
            />
            <InputInLog
                debouncedMode={true}
                rules={[{ isRequired: true }]}
                defaultValue={rowData?.inlog_mnemonic}
                disabled={orderIndex===0}
                className={'select-input-block--mnemonic'}
                onChange={(data) =>
                    changeSelectInputField(data, MnemColumnTypes.mnemonic)
                }
            />
            {/*unit*/}
            <SelectInputBlock
                type={SelectInputTypes.unit}
                activeLang={activeLang}
                onChange={(data) =>
                    changeSelectInputField(data, MnemColumnTypes.unit)
                }
                defaultData={{
                    en: rowData?.inlog_unit_and_conversion_en,
                    ru: rowData?.inlog_unit_and_conversion_ru,
                }}
                setIsError={setIsErrorUnit}
            />
            <SelectInputBlock
                type={SelectInputTypes.input}
                activeLang={activeLang}
                onChange={(data, _) =>
                    changeSelectInputField(data, MnemColumnTypes.description)
                }
                defaultData={{
                    en: rowData?.inlog_description_en,
                    ru: rowData?.inlog_description_ru,
                }}
                setIsError={setIsErrorDescription}
            />
            <InputInLog
                debouncedMode={true}
                defaultValue={rowData?.possible_mnemonics?.join(',')}
                className={'select-input-block--mnemonic'}
                rules={[{isRequired: true}]}
                onChange={(data) => {
                    if (data && data?.length > 0) {
                        const arrFromStr = data?.split(',').filter(el=>el!=='')
                        changeSelectInputField(
                            arrFromStr,
                            MnemColumnTypes.mnemonics,
                        )
                    }
                }}
            />
            {/*buttons*/}
            <div className={'mnem-table-buttons'}>
                <input
                    type="radio"
                    className={'mnem-table-radioBtn'}
                    checked={rowData.constant_mnemonic}
                    onClick={setMnemonicFieldStatus}
                    onChange={() => {}}
                    disabled={errorStatusList.includes(true)}
                />
                <button
                    className={'mnem-table-cancelBtn'}
                    onClick={handleDelete}
                >
                    {mnemonics[0]?.id !== rowData?.id && <SettingCancelIcon />}
                </button>
            </div>
        </div>
    )
}
