import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import SelectInLog from '../../../../../../shared/select/Select'
import { useTranslation } from 'react-i18next'
import { ISelectOption } from '../../../../../../app/types/common'
import InputInLog from '../../../../../../shared/input/Input'
import { TaskCancelIcon } from '../../../../../../image_files/icons/TaskCancelIcon'
import { ISelectionIntervalProps } from './types'
import { ISelectInterval } from '../../types'
import { initialCorePhotoInterval } from '../../CoreBoxes'
import useDebounce from '../../../../../../hooks/useDebounce'

const fieldPattern = /^\d{1,10}(?:\.\d{1,2})?$/
const DEFAULT_SELECT: ISelectInterval['type'] = 'vertical'

const SelectionInterval: FC<ISelectionIntervalProps> = (props) => {
    const { item, deleteInterval, onSelect, changeInterval } = props
    const { t } = useTranslation()
    const [mode, setMode] = useState<'error' | 'success'>('success')
    const [interval, setInterval] = useState<ISelectInterval>(
        initialCorePhotoInterval,
    )
    const debouncedInterval = useDebounce(interval.interval, 500)

    //*local services
    const compareIntervalsToValid = (intervalVal: string[]) => {
        const firstVal = Number(intervalVal[0])
        const secondVal = Number(intervalVal[1])
        if (!isNaN(firstVal) && !isNaN(secondVal)) {
            return secondVal > firstVal
        } else return false
    }

    const getValidIntervalModeStatus = (
        intervalArg: ISelectInterval['interval'],
    ) => {
        if (
            compareIntervalsToValid(intervalArg['vertical']) &&
            compareIntervalsToValid(intervalArg['actual'])
        ) {
            setMode('success')
            return true
        } else {
            setMode('error')
            return false
        }
    }

    const options: ISelectOption[] = [
        {
            label: t('vertical'),
            value: 'vertical',
        },
        {
            label: t('actual'),
            value: 'actual',
        },
    ]

    const onDelete = () => {
        deleteInterval(item.id)
    }

    const onChangeSelect = (type: ISelectInterval['type']) => {
        setInterval({ ...interval, type })
    }

    const onChangeTextField = useCallback(
        (data: { type: 'left' | 'right'; value: string }) => {
            const { type, value } = data
            if (fieldPattern.test(value)) {
                let changedInterval = {
                    ...interval.interval,
                    [interval?.type]: [
                        ...interval.interval[interval?.type],
                    ].map((el, i) => {
                        if (
                            (type === 'left' && i === 0) ||
                            (type === 'right' && i === 1)
                        ) {
                            return value
                        } else {
                            return el
                        }
                    }),
                }
                setInterval({
                    ...interval,
                    interval: changedInterval,
                })
            }
        },
        [interval, setInterval],
    )

    const showSelectionIntervalBlockClass = () =>{
        return `selection-interval-block selection-interval-block--${
            typeof interval?.id === 'number' && !interval?.isNew
                ? 'success'
                : 'error'
        }`
    }

    useEffect(() => {
        if (item) {
            setInterval(item)
        }
    }, [item])

    useEffect(() => {
        const initialInterval = {
            vertical: ['', ''],
            actual: ['', ''],
        }
        let intervalModeStatus = undefined
        if (JSON.stringify(interval.interval) !== JSON.stringify(initialInterval)) {
            intervalModeStatus = getValidIntervalModeStatus(debouncedInterval)
        }
        if (
            intervalModeStatus &&
            JSON.stringify(item) !== JSON.stringify(interval)
        ) {
            changeInterval({
                intervalDataArg: {
                    ...interval,
                },
                type: typeof interval.id === 'number' ? 'update' : 'add',
            })
        }
    }, [debouncedInterval])

    return (
        <div className="select-interval">
            {typeof item.id === 'number' ? (
                <div
                    className={`select-interval-checkbox ${
                        item.selected
                            ? 'select-interval-checkbox--selected'
                            : ''
                    } `}
                    onClick={onSelect}
                />
            ) : (
                <div className="select-interval-checkbox select-interval-checkbox--disabled"></div>
            )}
            <div
                className={showSelectionIntervalBlockClass()}
            >
                <SelectInLog
                    className="selection-intervals-select"
                    options={options}
                    defaultValue={DEFAULT_SELECT}
                    value={interval?.type ? interval?.type : DEFAULT_SELECT}
                    onChange={onChangeSelect}
                />
                <div className="selection-intervals-textFields">
                    <InputInLog
                        type={'float-numeric'}
                        value={
                            interval?.interval[interval?.type][0] !== undefined
                                ? interval?.interval[interval.type][0]
                                : ''
                        }
                        onChange={(value) =>
                            onChangeTextField({ type: 'left', value })
                        }
                    />
                    <span>-</span>
                    <InputInLog
                        type={'float-numeric'}
                        value={
                            interval?.interval[interval?.type][1] !== undefined
                                ? interval?.interval[interval.type][1]
                                : ''
                        }
                        onChange={(value) =>
                            onChangeTextField({ type: 'right', value })
                        }
                    />
                </div>
                <TaskCancelIcon
                    className={`selection-interval-cancel-icon cursor-pointer`}
                    onClick={onDelete}
                />
            </div>
        </div>
    )
}

export default memo(SelectionInterval)
