import React, { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ISelectOption } from '../../app/types/common'
import { RoadMapDetalizationTypes } from '../../app/types/enums'
import { ITask, ITasksFilterParams } from '../../app/types/models/tasks'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { tagsServices } from '../../services/tags-services'
import { tasksService } from '../../services/tasks-services'
import { CustomAccordion } from '../../shared/customAccordion/CustomAccordion'
import SpinInLog from '../../shared/spin/spin'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { tasksSelectors } from '../../store/tasks'
import {
    setTasks,
    setTasksLoadingStatus
} from '../../store/tasks/actions'
import { selectIsLoadingTasksStatus } from '../../store/tasks/selectors'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import RoadMapUpdateTaskBlock from './components/roadMapAddTaskBlock/RoadMapUpdateTaskBlock'
import RoadMapTasks from './components/roadMapTasks/RoadMapTasks'
import RoadMapTop from './components/roadMapTop/RoadMapTop'
import { initialFilterData } from './data'
import './roadMap.css'

const RoadMap = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const currentProject = useSelector(selectCurrentProject)
    const tasksData = useSelector(tasksSelectors.selectTaskData)
    const isLoadingTasks = useSelector(selectIsLoadingTasksStatus)
    const [tagsOptions, setTagsOptions] = useState<ISelectOption[]>([])
    const [filterParams, setFilterParams] =
        useState<ITasksFilterParams>(initialFilterData)
    const [detalizationData, setDetalizationData] = useState({
        detalization: RoadMapDetalizationTypes.MONTH,
        startPeriod: '',
        endPeriod: '',
    })

    const [addTaskModalData, setAddTaskModalData] = useState({
        isOpen: undefined as boolean | undefined,
        task: undefined as ITask | undefined,
    })

    const getTags = useCallback(async () => {
        try {
            const response = await tagsServices.getTags(currentProject?.id)
            if (
                response &&
                response?.results &&
                response?.results?.length > 0
            ) {
                setTagsOptions(
                    response.results.map((el) => ({
                        label: el?.name,
                        value: el?.id,
                    })),
                )
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }, [currentProject])

    useEffect(() => {
        currentProject?.id !== undefined && getTags()
    }, [currentProject])

    const getTasks = async () => {
        try {
            dispatch(setTasksLoadingStatus(true))
            const response = await tasksService.getTasks(currentProject?.id, {
                ...filterParams,
                tags:
                    filterParams?.tags && filterParams?.tags?.length > 0
                        ? filterParams?.tags.join(',')
                        : undefined,
                doers__user:
                    filterParams?.doers__user &&
                    filterParams?.doers__user?.length > 0
                        ? filterParams?.doers__user.join(',')
                        : undefined,
            })
            dispatch(setTasks(response))
            dispatch(setTasksLoadingStatus(false))
        } catch (error) {
            errorsHandler(error, t)
            dispatch(setTasksLoadingStatus(false))
        }
    }

    useEffect(() => {
        currentProject?.id !== undefined && getTasks()
    }, [filterParams, currentProject])

    return (
        <div className="roadMap">
            {createPortal(
                <SpinInLog isLoading={isLoadingTasks} />,
                document.body,
            )}
            <div className="roadMap-inner">
                <CustomAccordion
                    className="roadmap-accordion"
                    title={t('filters')}
                    activeStatus={true}
                >
                    <RoadMapTop
                        tagsOptions={tagsOptions}
                        filterParams={filterParams}

                        setFilterParams={setFilterParams}
                        detalizationData={detalizationData}
                        setDetalizationData={setDetalizationData}
                    />
                </CustomAccordion>
                <RoadMapTasks
                    count={tasksData.count}
                    tasks={tasksData.results}
                    isLoading={isLoadingTasks}
                    tagsOptions={tagsOptions}
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                    detalizationData={detalizationData}
                    setAddTaskModalData={setAddTaskModalData}
                />

                {addTaskModalData.isOpen && (
                    <RoadMapUpdateTaskBlock
                        task={addTaskModalData.task}
                        isOpen={addTaskModalData.isOpen}
                        tagsOptions={tagsOptions}
                        setIsOpen={(isOpen) =>
                            setAddTaskModalData((prev) => ({
                                ...prev,
                                isOpen,
                            }))
                        }
                        refetchTasks={getTasks}
                    />
                )}
            </div>
        </div>
    )
}

export { RoadMap }

