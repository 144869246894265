import React from 'react';

export const CompleteIcon = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30558 17.5 0.5 13.6944 0.5 9C0.5 4.30558 4.30558 0.5 9 0.5C13.6944 0.5 17.5 4.30558 17.5 9Z"
                fill="#FDFDFD" stroke="var(--green-success)"/>
            <path d="M4.50007 8.49995L9 12.5L13.5932 5.70933" stroke="var(--green-success)"/>
        </svg>

    );
};
