import './deleteModal.css'
import { Button, Modal, Spin } from 'antd'
import { FC } from 'react'
import { getLocalizedText } from '../../utils/helpers/getLocalizedText'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getValidText } from '../../utils/helpers/getValidText'
import { IDeleteModalProps } from './types'
import TrashIcon from '../../image_files/icons/TrashIcon'
import SpinInLog from '../spin/spin'
import { createPortal } from 'react-dom'

const DeleteModal: FC<IDeleteModalProps> = ({
    open,
    title,
    className,
    onDelete,
    onCancel,
    isLoading,
}) => {
    const { t } = useTranslation()
    return (
        <>
            {createPortal(<SpinInLog isLoading={isLoading} />, document.body)}
            <Modal
                className={`delete-modal ${getValidText(className)}`}
                title={
                    <div className="delete-modal-title">
                        <TrashIcon />
                        <span>{t('deleting')}</span>
                    </div>
                }
                open={open}
                footer={
                    <div>
                        <Button type="default" onClick={onCancel} className='delete-modal-cancel-btn'>
                            {getLocalizedText('no', t)}
                        </Button>
                        <Button type="primary" onClick={onDelete} className='delete-modal-delete-btn'>
                            {getLocalizedText('delete', t)}
                        </Button>
                    </div>
                }
                onCancel={onCancel}
            >
                <div className="delete-modal-wrapper">
                    <p className='font-16-normal'>{title}</p>
                </div>
            </Modal>
        </>
    )
}

export { DeleteModal }
