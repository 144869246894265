import { toast } from 'react-toastify'

import {
    ACOUSTIC_VELOCITY,
    BIO,
    BULK_MODULUS,
    CAPACITY,
    CARBONATES,
    COMPRESSIBILITY,
    COMPRESSIVE_STRENGTH,
    CONDUCTIVITY,
    DENSITY,
    DIFFUSIVITY,
    ELASTIC_LIMIT,
    OIL_DISPLACEMENT,
    PERMEABILITY,
    POISSON_RATIO,
    POROSITY,
    SATURATION,
    SHEAR_MODULUS,
    ULTIMATE_COMPRESSIVE_STRENGTH,
    YIELD_STRENGTH,
    YOUNG_MODULE,
} from '../../../../../../../utils/constants'
import { mechanicalService } from '../../../../../../../services/mechanical-service'
import { thermalService } from '../../../../../../../services/thermal-service'
import { showNotice } from '../../../../../../../utils/helpers/showNotice'
import { getLocalizedText } from '../../../../../../../utils/helpers/getLocalizedText'
import { standardStudyGroupService } from '../../../../../../../services/standard-study-group-service'

interface IParams {
    settingType: string
    groupType: 'standard' | 'mechanical' | 'thermal'
    projectId: number
    coreId: number
    settingId: number
    data: any
    groupId: number
    isReadyToSend: boolean

    setData: (value: any) => void
    setLoading: (value: boolean) => void
    t: Function
}

export const deleteSetting = async (values: IParams) => {
    const {
        settingType,
        groupType,
        settingId,
        setLoading,
        setData,
        data,
        coreId,
        groupId,
        isReadyToSend,
        projectId,
        t,
    } = values
    try {
        if (isReadyToSend) {
            setLoading(true)
            if (groupType === 'standard') {
                if (settingType === POROSITY) {
                    await standardStudyGroupService.deletePorosity(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === PERMEABILITY) {
                    await standardStudyGroupService.deletePermeability(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === SATURATION) {
                    await standardStudyGroupService.deleteSaturation(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === OIL_DISPLACEMENT) {
                    await standardStudyGroupService.deleteOilDisplacement(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === CARBONATES) {
                    await standardStudyGroupService.deleteCarbonateContent(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === DENSITY) {
                    await standardStudyGroupService.deleteDensity(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                }
            } else if (groupType === 'mechanical') {
                if (settingType === YOUNG_MODULE) {
                    await mechanicalService.deleteYoungModule(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === POISSON_RATIO) {
                    await mechanicalService.deletePoissonRatio(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === ACOUSTIC_VELOCITY) {
                    await mechanicalService.deleteAcousticVelocity(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === COMPRESSIBILITY) {
                    await mechanicalService.deleteCompressibility(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === SHEAR_MODULUS) {
                    await mechanicalService.deleteShearModulus(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === YIELD_STRENGTH) {
                    await mechanicalService.deleteYieldStrength(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === BIO) {
                    await mechanicalService.deleteBio(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === BULK_MODULUS) {
                    await mechanicalService.deleteBulkModulus(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === COMPRESSIVE_STRENGTH) {
                    await mechanicalService.deleteUniaxialCompressiveStrength(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === ULTIMATE_COMPRESSIVE_STRENGTH) {
                    await mechanicalService.deleteUltimateCompressiveStrength(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === ELASTIC_LIMIT) {
                    await mechanicalService.deleteElasticLimit(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                }
            } else {
                if (settingType === CONDUCTIVITY) {
                    await thermalService.deleteConductivity(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === DIFFUSIVITY) {
                    await thermalService.deleteDiffusivity(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                } else if (settingType === CAPACITY) {
                    await thermalService.deleteCapacity(
                        projectId,
                        coreId,
                        groupId,
                        settingId,
                    )
                }
            }

            const changedItems = data.filter((el) => el.id !== settingId)
            setData(changedItems)
            setLoading(false)
            showNotice('setting-deleted-successfully', t)
        } else {
            if (data && Array.isArray(data)) {
                setData(data.filter((el) => el.id !== settingId))
                setLoading(false)
            } else {
                setData(null)
                setLoading(false)
            }
        }
    } catch (e) {
        console.log(e)
        //todo поменять на errorsHandler
        toast.error(`${getLocalizedText('server-error', t)}`, {
            position: 'top-center',
        })
        setLoading(false)
    }
}
