import { ICalendarGroup } from '../../app/types/i-roadmap-calendar'
import { ITasksFilterParams } from '../../app/types/models/tasks'

export const initialFilterData: ITasksFilterParams = {
    ordering: 'created_at',
    doers__user: undefined,
    name__icontains: '',
    priority: undefined,
    status: '',
    slug__icontains: '',
    tags: undefined,
    supervisor__user: undefined,
}

