import React,{ FC } from "react"
import { ICoreBoxRadioContent } from "../addCoreBoxPhotoModal/types"
import { UpIcon } from "../../../../../../image_files/icons/UpIcon"
import './coreBoxRadioContent.css';

const CoreBoxRadioContent: FC<ICoreBoxRadioContent> = ({ text,arrow='' }) => {

    const showArrow = () => {
        switch(arrow){
            case 'right':
                return <UpIcon className="add-core-box-photo-right-arrow" />
            case 'left':
                return <UpIcon className="add-core-box-photo-left-arrow" />
            default:
                return ''
        }
    }

    return (
        <div className="add-core-box-photo-radio-content">
            {showArrow()}
            <span>{text}</span>
        </div>
    )
}
export default CoreBoxRadioContent;