import React, { FC, ReactNode, useState } from 'react'
import { findColorByParam } from '../../utils/findColorByParam'
import { getCorrectDateFormat } from '../../utils/getCorrectDateFormat'
import { useLocation } from 'react-router-dom'
import './task.css'
import { ITask } from '../../app/types/models/tasks'
import react from 'react'
import PopoverMenu from '../../shared/popoverMenu/PopoverMenu'
import { IPopoverMenuItem } from '../../app/types/common'
import { DeleteIcon } from '../../image_files/icons/DeleteIcon'
import { useTranslation } from 'react-i18next'
import { tasksService } from '../../services/tasks-services'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { deleteTask } from '../../store/tasks/actions'
import SpinInLog from '../../shared/spin/spin'
import { createPortal } from 'react-dom'
import ModalInlog from '../../shared/modalInlog/ModalInlog'
import { ButtonItem } from '../../app/types/i-button'
import { stopEvent } from '../../shared/stopEvent'
import { showNotify } from '../../utils/helpers/showNotice'
import { getTaskSlugFromUrl } from '../../services/get-task-slug-from-url'

interface IProps {
    task: ITask
    onClick: (value:string) => void
}

const initialModalData: IModalData = {
    title: '',
    isOpen: false,
    text: '',
}

interface IModalData {
    title: string
    titleIcon?: ReactNode
    isOpen: boolean
    text: string
    saveBtnTitle?: string
    onSave?: () => void
}

export const Task: FC<IProps> = react.memo(({ task, onClick }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const currentProject = useSelector(selectCurrentProject)
    const [closeMenuTrigger, setCloseMenuTrigger] = useState(0)
    const [modalsData, setModalsData] = useState(initialModalData)
    const [isLoading, setIsLoading] = useState(false)

    const deleteITem = async () => {
        try {
            setIsLoading(true)
            await tasksService.deleteTask(currentProject?.id, task?.slug)
            dispatch(deleteTask({ slug: task?.slug }))
            setIsLoading(false)
            setModalsData(initialModalData)
            showNotify(t('task-deleted-successfully'))
        } catch (error) {
            setIsLoading(false)
            errorsHandler(error, t)
            setModalsData(initialModalData)
        }
    }

    const menuItems: IPopoverMenuItem[] = [
        {
            id: 1,
            title: t('delete-task'),
            icon: <DeleteIcon />,
            onClick: () => {
                setModalsData({
                    isOpen: true,
                    text: t('you-realy-want-do-delete-this-task'),
                    titleIcon: <DeleteIcon width={18} height={18} />,
                    title: t('task-deleting'),
                    onSave: deleteITem,
                })
                setCloseMenuTrigger((prev) => prev + 1)
            },
        },
    ]

    const modalBtnList: ButtonItem[] = [
        {
            id: 1,
            titleBtn: t('cancel'),
            className: 'font-14-normal-imp',
            handleBtn: () => setModalsData(initialModalData),
        },
        {
            id: 2,
            type: 'primary',
            titleBtn: modalsData?.saveBtnTitle || t('delete'),
            handleBtn: modalsData?.onSave,
        },
    ]

    const handleClick = (e) =>{
        stopEvent(e)
        if(!modalsData?.isOpen && !isLoading){
            onClick(task?.slug)
        }
    }

    return (
        <div
            className="scheduler-task"
            onClick={handleClick}
            style={
                getTaskSlugFromUrl(location.pathname) === task.slug
                    ? { backgroundColor: 'rgba(148, 215, 219, 0.3)' }
                    : {}
            }
        >
            {createPortal(<SpinInLog isLoading={isLoading} />, document.body)}
            <div
                className="scheduler-task__priority"
                style={findColorByParam(task?.priority)}
            ></div>
            <div className="scheduler-task__content">
                <div className="scheduler-task__content-top">
                    <div>{task?.slug}</div>
                    <div>
                        {getCorrectDateFormat({ date: task?.created_at })}
                    </div>
                </div>
                    <div className={'scheduler-task__text'}>{task?.name}</div>
                    <PopoverMenu
                        items={menuItems}
                        closeTrigger={closeMenuTrigger}
                        className='scheduler-task-menu'
                    />
                {createPortal(
                    <ModalInlog
                        width={400}
                        open={modalsData.isOpen}
                        title={modalsData.title}
                        iconTitle={modalsData?.titleIcon}
                        listButton={modalBtnList}
                        onCancel={() => setModalsData(initialModalData)}
                    >
                        <div className="font-14-normal roadmap-item-modal-inner">
                            {modalsData?.text}
                        </div>
                    </ModalInlog>,
                    document.body,
                )}
            </div>
        </div>
    )
})
