import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import {
    ICore,
    IExtendedWell,
    IPad,
    IWellBore,
} from '../../../../app/types/models/logging'
import { IExtendedProject } from '../../../../app/types/models/project'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { DotIcon } from '../../../../image_files/icons/DotIcon'
import { SortIcon } from '../../../../image_files/icons/SortIcon'
import { projectsService } from '../../../../services/projects-service.'
import { wellBoreService } from '../../../../services/wellBore-service'
import { wellsService } from '../../../../services/wells-service'
import { updateProject } from '../../../../store/projects/actions'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { getValidText } from '../../../../utils/helpers/getValidText'
import { showNotice } from '../../../../utils/helpers/showNotice'
import ProjectItemContent from '../projectItemContent/ProjectItemContent'
import './ProjectItem.css'

interface IProps {
    project: IExtendedProject
    pad?: IPad
    well?: IExtendedWell
    wellbore?: IWellBore
    type:
        | 'project'
        | 'pad'
        | 'well'
        | 'wellbore'
        | 'wellCore'
        | 'wellboreCore'
        | 'core'
    children?: ReactNode
}

const ProjectItem: FC<IProps> = (props) => {
    const { project, children, pad, well, wellbore, type } = props
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const params = useParams()

    const fetchPads = async (): Promise<void> => {
        try {
            const data = await projectsService.getPads(project?.id)
            dispatch(
                updateProject({ ...project, pads: data, isCollapsed: true }),
            )
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const fetchWells = async (): Promise<void> => {
        try {
            const wells = await wellsService.getWells(project?.id)
            if (wells?.length > 0) {
                const pads = project?.pads.map((p) =>
                    p?.id === pad?.id
                        ? {
                              ...pad,
                              isCollapsed: true,
                              wells: wells?.filter(
                                  (w) => w?.well_pad === pad?.id,
                              ),
                          }
                        : p,
                )
                dispatch(updateProject({ ...project, pads }))
            } else {
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const fetchWellCores = async (): Promise<void> => {
        try {
            const data = await projectsService.getCores(project?.id)

            const filteredCores = data.filter((core) => core.well === well?.id)
            fetchWellBores(filteredCores || [])
        } catch (e) {
            fetchWellBores([])
            errorsHandler(e, t)
        }
    }

    const fetchWellBores = async (cores: ICore[]) => {
        try {
            const data = await wellBoreService.getWellBores(project?.id)
            const filteredWellBores = data.filter(
                (wellBore) => wellBore.well === well?.id,
            )

            if (
                (filteredWellBores && filteredWellBores?.length > 0) ||
                cores?.length > 0
            ) {
                const pads = project?.pads.map((p) =>
                    p?.id === pad?.id
                        ? {
                              ...pad,
                              wells: pad?.wells?.map((w) =>
                                  w?.id === well?.id
                                      ? {
                                            ...w,
                                            isCollapsed: true,
                                            wellBores: filteredWellBores || [],
                                            cores: cores || [],
                                        }
                                      : w,
                              ),
                          }
                        : p,
                )
                dispatch(updateProject({ ...project, pads }))
            } else {
                showNotice(t('no-data'), t, {}, 'info')
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const fetchWellBoreCores = async (): Promise<void> => {
        try {
            const data = await projectsService.getCores(project?.id)
            const filteredCores =
                data.length > 0
                    ? data.filter((core) => core.wellbore === wellbore?.id)
                    : []

            if (filteredCores && filteredCores.length>0) {
                const pads = project?.pads.map((p) =>
                    p?.id === pad?.id
                        ? {
                              ...pad,
                              wells: pad?.wells?.map((w) =>
                                  w?.id === well?.id
                                      ? {
                                            ...w,
                                            wellBores:
                                                w?.wellBores &&
                                                w?.wellBores?.length > 0
                                                    ? w.wellBores?.map((wb) =>
                                                          wb?.id ===
                                                          wellbore?.id
                                                              ? {
                                                                    ...wb,
                                                                    isCollapsed:
                                                                        true,
                                                                    cores: filteredCores,
                                                                }
                                                              : wb,
                                                      )
                                                    : [],
                                        }
                                      : w,
                              ),
                          }
                        : p,
                )
                dispatch(updateProject({ ...project, pads }))
            } else {
                showNotice(t('no-data'), t, {}, 'info')
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const handleClickToggleIcon = () => {
        switch (type) {
            case 'project':
                if (!project?.isCollapsed) {
                    fetchPads()
                } else {
                    dispatch(updateProject({ ...project, isCollapsed: false }))
                }

                break
            case 'pad':
                if (!pad?.isCollapsed) {
                    fetchWells()
                } else {
                    const pads = project?.pads.map((p) =>
                        p?.id === pad?.id ? { ...pad, isCollapsed: false } : p,
                    )
                    dispatch(updateProject({ ...project, pads }))
                }

                break
            case 'well':
                if (!well?.isCollapsed) {
                    fetchWellCores()
                } else {
                    const pads = project?.pads.map((p) =>
                        p?.id === pad?.id
                            ? {
                                  ...pad,
                                  wells: pad?.wells?.map((w) =>
                                      w?.id === well?.id
                                          ? {
                                                ...w,
                                                isCollapsed: false,
                                            }
                                          : w,
                                  ),
                              }
                            : p,
                    )
                    dispatch(updateProject({ ...project, pads }))
                }
                break
            case 'wellbore':
                if (!wellbore?.isCollapsed) {
                    fetchWellBoreCores()
                } else {
                    const pads = project?.pads.map((p) =>
                        p?.id === pad?.id
                            ? {
                                  ...pad,
                                  wells: pad?.wells?.map((w) =>
                                      w?.id === well?.id
                                          ? {
                                                ...w,
                                                wellBores:
                                                    w?.wellBores &&
                                                    w?.wellBores?.length > 0
                                                        ? w.wellBores?.map(
                                                              (wb) =>
                                                                  wb?.id ===
                                                                  wellbore?.id
                                                                      ? {
                                                                            ...wb,
                                                                            isCollapsed:
                                                                                false,
                                                                        }
                                                                      : wb,
                                                          )
                                                        : [],
                                            }
                                          : w,
                                  ),
                              }
                            : p,
                    )
                    dispatch(updateProject({ ...project, pads }))
                }
                break
            default:
                break
        }
    }

    const getValidSortIconClass = () => {
        switch (type) {
            case 'project':
                return `cursorPointer ${
                    project?.isCollapsed
                        ? 'projectMenu-sortIcon-opened'
                        : 'projectMenu-sortIcon'
                }`
            case 'pad':
                return `cursorPointer ${
                    pad?.isCollapsed
                        ? 'projectMenu-sortIcon-opened'
                        : 'projectMenu-sortIcon'
                }`
            case 'well':
                return `cursorPointer ${
                    well?.isCollapsed
                        ? 'projectMenu-sortIcon-opened'
                        : 'projectMenu-sortIcon'
                }`
            case 'wellbore':
                return `cursorPointer ${
                    wellbore?.isCollapsed
                        ? 'projectMenu-sortIcon-opened'
                        : 'projectMenu-sortIcon'
                }`
            default:
                return ''
        }
    }

    const getValidName = (name?: string) => {
        switch (type) {
            case 'project':
                return project.name
            case 'pad': {
                return pad.name
            }
            case 'well': {
                return well.name
            }
            case 'wellbore': {
                return wellbore.name
            }
            case 'wellboreCore':
            case 'wellCore': {
                return getValidText(name)
            }
            default:
                return ''
        }
    }

    const getValidClassName = () => {
        switch (type) {
            case 'project': {
                const selectItemClass = `${
                    Object.values(params)[0] !== undefined &&
                    +Object.values(params)[0] === project?.id
                        ? 'projectsMenu__item-project projectsMenu__item-project-selected'
                        : 'projectsMenu__item-project'
                }`
                return `${selectItemClass} ${
                    project?.isCollapsed
                        ? 'projectsMenu__item projectsMenu__item-collapsed'
                        : 'projectsMenu__item'
                }`
            }

            case 'pad': {
                return ` ${
                    pad?.isCollapsed
                        ? 'projectsMenu__item projectsMenu__item-collapsed'
                        : 'projectsMenu__item'
                }`
            }
            case 'well': {
                return ` ${
                    well?.isCollapsed
                        ? 'projectsMenu__item projectsMenu__item-collapsed'
                        : 'projectsMenu__item'
                }`
            }
            case 'wellbore': {
                return ` ${
                    wellbore?.isCollapsed
                        ? 'projectsMenu__item projectsMenu__item-collapsed'
                        : 'projectsMenu__item'
                }`
            }
            case 'wellCore': {
                return `${
                    well?.cores && well?.cores?.length > 0
                        ? 'projectsMenu__item'
                        : 'projectsMenu__item projectsMenu__item-absent'
                } projectsMenu__item-wellCore`
            }

            case 'wellboreCore': {
                return `${
                    wellbore?.cores && wellbore?.cores?.length > 0
                        ? 'projectsMenu__item'
                        : 'projectsMenu__item projectsMenu__item-absent'
                } projectsMenu__item-wellboreCore`
            }
            default:
                return 'projectsMenu__item'
        }
    }

    const getValidChildren = () => {
        if (type === 'wellCore') {
            return well?.cores && well.cores?.length > 0 ? (
                well.cores.map((wellCore, i) => (
                    <ProjectItemContent
                        key={i}
                        pad={pad}
                        type={'core'}
                        core={wellCore}
                        project={project}
                        leftIcon={getValidLeftIcon()}
                        name={getValidName(wellCore?.name)}
                        className='projectsMenu__item-core'
                    />
                ))
            ) : (
                <></>
            )
        } else if (type === 'wellboreCore') {
            return wellbore?.cores && wellbore.cores?.length > 0 ? (
                wellbore.cores.map((wellboreCore, i) => (
                    <ProjectItemContent
                        key={i}
                        pad={pad}
                        type={'core'}
                        project={project}
                        core={wellboreCore}
                        leftIcon={getValidLeftIcon()}
                        name={getValidName(wellboreCore?.name)}
                    />
                ))
            ) : (
                <></>
            )
        } else return children || <></>
    }

    const getValidLeftIcon = () => {
        if (!['wellCore', 'wellboreCore'].includes(type)) {
            return (
                <SortIcon
                    className={getValidSortIconClass()}
                    onClick={() => handleClickToggleIcon()}
                />
            )
        } else {
            return <DotIcon />
        }
    }

    return (
        <div className={getValidClassName()}>
            {!['wellCore', 'wellboreCore'].includes(type) && (
                <ProjectItemContent
                    pad={pad}
                    type={type}
                    well={well}
                    project={project}
                    wellbore={wellbore}
                    leftIcon={getValidLeftIcon()}
                    name={getValidName()}
                />
            )}
            <div className="projectsMenu__item-nested">
                {getValidChildren()}
            </div>
        </div>
    )
}

export default ProjectItem
