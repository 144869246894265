import React, {FC} from 'react'
import {IIconProps} from "../../app/types/i-props";
import {getValidText} from "../../utils/helpers/getValidText";

export const UpIcon:FC<IIconProps> = ({className}) => {
    return (
        <svg className={getValidText(className)} width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="8" height="16" fill={'var(--grey-phone-color)'}/>
            <path d="M4 15L4 1" stroke="#364F6B" strokeLinecap="round"/>
            <path d="M4 1L0.5 4.5" stroke="#364F6B" strokeLinecap="round"/>
            <path d="M7.5 4.5L4 1" stroke="#364F6B" strokeLinecap="round"/>
        </svg>
    )
}
