import { ACCESS_TOKEN } from '../utils/constants'
import { AxiosResponse } from 'axios'
import { MeasurementGroupResponse } from '../app/types/response'
import {instance} from "../app/api";

export const mechanicalService = {
    getMechanicalGroup: async (projectId: number, coreId: number) => {
        const { data } = await instance.get<
            any,
            AxiosResponse<MeasurementGroupResponse>
        >(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createMechanicalGroup: async (
        projectId: number,
        coreId: number,
        bodyData: { core: number },
    ) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    getYoungModule: async (
        projectId: number,
        coreId: number,
        groupId: number,
    ) => {
        const { data } = await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/youngs-modulus-strength/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createYoungModule: async (projectId, coreId, groupId, bodyData) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/youngs-modulus-strength/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    updateYoungModule: async (
        projectId,
        coreId,
        groupId,
        moduleId,
        bodyData,
    ) => {
        const { data } = await instance.patch(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/youngs-modulus-strength/${moduleId}/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    deleteYoungModule: async (projectId, coreId, groupId, moduleId) => {
        const { data } = await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/youngs-modulus-strength/${moduleId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    getPoissonRatio: async (projectId, coreId, groupId) => {
        const { data } = await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/poissons-ratio/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createPoissonRatio: async (projectId, coreId, groupId, bodyData) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/poissons-ratio/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    updatePoissonRatio: async (
        projectId,
        coreId,
        groupId,
        moduleId,
        bodyData,
    ) => {
        const { data } = await instance.patch(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/poissons-ratio/${moduleId}/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    deletePoissonRatio: async (projectId, coreId, groupId, moduleId) => {
        const { data } = await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/poissons-ratio/${moduleId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    getAcousticVelocity: async (projectId, coreId, groupId) => {
        const { data } = await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/acoustic-velocity/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    deleteAcousticVelocity: async (projectId, coreId, groupId, moduleId) => {
        const { data } = await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/acoustic-velocity/${moduleId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createAcousticVelocity: async (projectId, coreId, groupId, bodyData) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/acoustic-velocity/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    updateAcousticVelocity: async (
        projectId,
        coreId,
        groupId,
        moduleId,
        bodyData,
    ) => {
        const { data } = await instance.patch(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/acoustic-velocity/${moduleId}/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    getCompressibility: async (projectId, coreId, groupId) => {
        const { data } = await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/compressibility/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    deleteCompressibility: async (projectId, coreId, groupId, moduleId) => {
        const { data } = await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/compressibility/${moduleId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createCompressibility: async (projectId, coreId, groupId, bodyData) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/compressibility/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    updateCompressibility: async (
        projectId,
        coreId,
        groupId,
        moduleId,
        bodyData,
    ) => {
        const { data } = await instance.patch(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/compressibility/${moduleId}/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    getShearModulus: async (projectId, coreId, groupId) => {
        const { data } = await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/shear-modulus/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    deleteShearModulus: async (projectId, coreId, groupId, moduleId) => {
        const { data } = await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/shear-modulus/${moduleId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createShearModulus: async (projectId, coreId, groupId, bodyData) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/shear-modulus/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    updateShearModulus: async (
        projectId,
        coreId,
        groupId,
        moduleId,
        bodyData,
    ) => {
        const { data } = await instance.patch(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/shear-modulus/${moduleId}/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    getYieldStrength: async (projectId, coreId, groupId) => {
        const { data } = await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/yield-strength/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    deleteYieldStrength: async (projectId, coreId, groupId, moduleId) => {
        const { data } = await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/yield-strength/${moduleId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createYieldStrength: async (projectId, coreId, groupId, bodyData) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/yield-strength/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    updateYieldStrength: async (
        projectId,
        coreId,
        groupId,
        moduleId,
        bodyData,
    ) => {
        const { data } = await instance.patch(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/yield-strength/${moduleId}/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    getBio: async (projectId, coreId, groupId) => {
        const { data } = await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/bio/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    deleteBio: async (projectId, coreId, groupId, moduleId) => {
        const { data } = await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/bio/${moduleId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createBio: async (projectId, coreId, groupId, bodyData) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/bio/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    updateBio: async (projectId, coreId, groupId, moduleId, bodyData) => {
        const { data } = await instance.patch(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/bio/${moduleId}/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    getBulkModulus: async (projectId, coreId, groupId) => {
        const { data } = await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/bulk-modulus/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    deleteBulkModulus: async (projectId, coreId, groupId, moduleId) => {
        const { data } = await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/bulk-modulus/${moduleId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createBulkModulus: async (projectId, coreId, groupId, bodyData) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/bulk-modulus/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    updateBulkModulus: async (
        projectId,
        coreId,
        groupId,
        moduleId,
        bodyData,
    ) => {
        const { data } = await instance.patch(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/bulk-modulus/${moduleId}/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    getUniaxialCompressiveStrength: async (projectId, coreId, groupId) => {
        const { data } = await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/uniaxial-compressive-strength/`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    deleteUniaxialCompressiveStrength: async (
        projectId,
        coreId,
        groupId,
        moduleId,
    ) => {
        const { data } = await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/uniaxial-compressive-strength/${moduleId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createUniaxialCompressiveStrength: async (
        projectId,
        coreId,
        groupId,
        bodyData,
    ) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/uniaxial-compressive-strength/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    updateUniaxialCompressiveStrength: async (
        projectId,
        coreId,
        groupId,
        moduleId,
        bodyData,
    ) => {
        const { data } = await instance.patch(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/uniaxial-compressive-strength/${moduleId}/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },
    getUltimateCompressiveStrength: async (projectId, coreId, groupId) => {
        const { data } = await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/ultimate-compressive-strength/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    deleteUltimateCompressiveStrength: async (
        projectId,
        coreId,
        groupId,
        moduleId,
    ) => {
        const { data } = await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/ultimate-compressive-strength/${moduleId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createUltimateCompressiveStrength: async (
        projectId,
        coreId,
        groupId,
        bodyData,
    ) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/ultimate-compressive-strength/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    updateUltimateCompressiveStrength: async (
        projectId,
        coreId,
        groupId,
        moduleId,
        bodyData,
    ) => {
        const { data } = await instance.patch(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/ultimate-compressive-strength/${moduleId}/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    getElasticLimit: async (projectId, coreId, groupId) => {
        const { data } = await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/elastic-limit/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    deleteElasticLimit: async (projectId, coreId, groupId, moduleId) => {
        const { data } = await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/elastic-limit/${moduleId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createElasticLimit: async (projectId, coreId, groupId, bodyData) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/elastic-limit/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    updateElasticLimit: async (
        projectId,
        coreId,
        groupId,
        moduleId,
        bodyData,
    ) => {
        const { data } = await instance.patch(
            `projects/${projectId}/wells/core/${coreId}/measurements/mechanical-group/${groupId}/elastic-limit/${moduleId}/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },
}
