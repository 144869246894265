import React, { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import LoggingProduction from '../../../../features/loggingProduction/LoggingProduction'
import WellLogging from '../../../../features/wellLogging/WellLogging'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { membersService } from '../../../../services/memembers-service'
import { projectsService } from '../../../../services/projects-service.'
import { wellBoreService } from '../../../../services/wellBore-service'
import { wellsService } from '../../../../services/wells-service'
import { LargeSpin } from '../../../../shared/LargeSpin'
import { ProjectAccordion } from '../../../../shared/projectAccodion/ProjectAccordion'
import {
    currentProjectActions,
    currentProjectSelectors,
} from '../../../../store/currentProject'
import {
    selectCurrentWell,
    setCurrentCore,
    setLoadingCurrentItemStatus,
    setWellLogMnemonics,
    setWellLogStrangeNans,
} from '../../../../store/currentProject/actions'
import { membersActions, membersSelectors } from '../../../../store/members'
import { projectsOpenMenuStatus, selectProjects } from '../../../../store/projects/selectors'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { CoreInfo } from './components/coreInfo/CoreInfo'
import { Cores } from './components/cores/Cores'
import LoggingBaseInfo from './components/loggingBaseInfo/LoggingBaseInfo'
import LoggingPanel from './components/loggingPanel/LoggingPanel'
import { Pads } from './components/pads/Pads'
import { Wells } from './components/wells/Wells'
import './projectsWorkSpace.css'
import { useGetMembers } from '../../../../hooks/use-get-members'

const LoggingEditSpace = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const currentProject = useSelector(
        currentProjectSelectors.selectCurrentProject,
    )
    const projects = useSelector(selectProjects)
    const navigate = useNavigate()
    const [showPadsInput, setShowPadsInput] = useState(false)
    const [showWellsInput, setShowWellsInput] = useState(false)
    const [showCoresInput, setShowCoresInput] = useState(false)
    const [tabActiveKey, setTabActiveKey] = useState('0')
    const [dataLoading, setDataLoading] = useState(true)
    const currentWell = useSelector(currentProjectSelectors.selectCurrentWell)
    const currentWellBore = useSelector(
        currentProjectSelectors.selectCurrentWellBore,
    )
    const currentCore = useSelector(currentProjectSelectors.selectCurrentCore)
    const [messageText, setMessageText] = useState('')
    const {
        setProject,
        setCurrentPads,
        setCurrentWells,
        setCurrentWellBores,
        setCurrentCores,
    } = currentProjectActions

    const { refetch:fetchMembers } =useGetMembers()

    const isOpenProjectMenu = useSelector(projectsOpenMenuStatus)

    const fetchProjects = async (): Promise<void> => {
        try {
            const projects = await projectsService.getProjects()
            if (projects && projects.length === 0) {
                setMessageText(
                    t('you-still-dont-selected-project')+'...'
                )
            }
        } catch (e) {
            console.log(e)
        }
    }

    const fetchProject = async (id: number) => {
        try {
            const data = await projectsService.getCurrentProject(id)
            dispatch(setProject({ project: data }))
            dispatch(setWellLogMnemonics(data.well_log_mnemonics))
            dispatch(setWellLogStrangeNans(data.well_log_strange_nans))
            dispatch(setCurrentCore(null))
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const fetchPads = useCallback(async () => {
        try {
            dispatch(
                setLoadingCurrentItemStatus({
                    status: true,
                    type: 'currentPads',
                }),
            )
            const data = await projectsService.getPads(currentProject?.id)
            dispatch(setCurrentPads({ pads: data }))
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentPads',
                }),
            )
        } catch (e) {
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentPads',
                }),
            )
            errorsHandler(e, t)
        }
    }, [currentProject,setCurrentPads])

    const fetchWells = useCallback(async () => {
        try {
            dispatch(
                setLoadingCurrentItemStatus({
                    status: true,
                    type: 'currentWells',
                }),
            )
            const data = await wellsService.getWells(currentProject?.id)
            dispatch(setCurrentWells({ wells: data }))
            dispatch(selectCurrentWell(data[0]))
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentWells',
                }),
            )
        } catch (e) {
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentWells',
                }),
            )
            console.log(e)
            errorsHandler(e, t)
        }
    }, [currentProject,setCurrentWells,setCurrentWellBores])

    const fetchWellBores = useCallback(async () => {
        try {
            dispatch(
                setLoadingCurrentItemStatus({
                    status: true,
                    type: 'currentWellbores',
                }),
            )
            const data = await wellBoreService.getWellBores(currentProject?.id)
            dispatch(setCurrentWellBores({ wellBores: data }))
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentWellbores',
                }),
            )
        } catch (e) {
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentWellbores',
                }),
            )
            errorsHandler(e, t)
        }
    }, [currentProject,setCurrentWellBores])

    const fetchCores = useCallback(async () => {
        try {
            dispatch(
                setLoadingCurrentItemStatus({
                    status: true,
                    type: 'currentCores',
                }),
            )
            const data = await projectsService.getCores(currentProject?.id)
            dispatch(setCurrentCores({ cores: data }))
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentCores',
                }),
            )
        } catch (e) {
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentCores',
                }),
            )
            console.log(e)
            errorsHandler(e, t)
        }
    }, [currentProject,setCurrentCores])

    const fetchEntities = useCallback(async () => {
        try {
            setDataLoading(true)
            await fetchProjects()
            await fetchProject(currentProject?.id)
            await fetchPads()
            await fetchWells()
            await fetchWellBores()
            setDataLoading(false)
            await fetchCores()
            fetchMembers(currentProject?.id)

            setDataLoading(false)
            if (projects?.length === 0) {
                navigate(`/logging/new`)
            }
        } catch (e) {
            console.log(e)
            setDataLoading(false)
        }
    }, [currentProject])

    useEffect(() => {
        setDataLoading(true)
        if(currentProject?.id){
            fetchEntities()
        }
    }, [currentProject?.id])

    const renderBlockById = () => {
        switch (tabActiveKey) {
            case '0':
                return (
                    <div className="projects__tabCore-inner">
                        <Cores
                            openValue={showCoresInput}
                            toOpenInput={setShowCoresInput}
                        />
                        {currentCore && (
                            <CoreInfo/>
                        )}
                    </div>
                )
            case '1':
                return (
                    <div className="data-logging-container">
                        <WellLogging
                            currentWellId={currentWell?.id}
                            currentWellBoreId={currentWellBore?.id}
                        />
                    </div>
                )
            case '2':
                return (
                    <div className="data-logging-container">
                        <LoggingProduction />
                    </div>
                )
            default:
                return ''
        }
    }
    if (dataLoading) {
        return <LargeSpin style={{ width: '75vw' }} />
    } else if (messageText && messageText?.length > 0) {
        return <div className={'no-project-block'}>{messageText}</div>
    } else
        return (
            <div
                className="projects__workSpace"
                style={{
                    width: isOpenProjectMenu
                        ? 'calc(100vw - 270px)'
                        : 'calc(100vw - 70px)',
                }}
            >
                <SimpleBar
                    className="projects__workSpace-simpleBar"
                    autoHide={false}
                >
                    <div className="projects__workSpace-inner">
                        <ProjectAccordion
                            title={t('info-about-project').toUpperCase()}
                        >
                            <LoggingBaseInfo />
                        </ProjectAccordion>
                        <ProjectAccordion
                            topChildren={
                                <LoggingPanel
                                    showPadsInput={showPadsInput}
                                    showWellsInput={showWellsInput}
                                    showCoresInput={showCoresInput}
                                    tabActiveKey={tabActiveKey}
                                    setShowPadsInput={setShowPadsInput}
                                    setShowWellsInput={setShowWellsInput}
                                    setShowCoresInput={setShowCoresInput}
                                    setTabActiveKey={setTabActiveKey}
                                />
                            }
                        >
                            <div className="projects__elements">
                                <div className="projects__elements-menu">
                                    <Pads
                                        openValue={showPadsInput}
                                        toOpenInput={setShowPadsInput}
                                        setOpenValue={setShowPadsInput}
                                    />
                                    <Wells
                                        openValue={showWellsInput}
                                        toOpenInput={setShowWellsInput}
                                    />
                                    {renderBlockById()}
                                </div>
                            </div>
                        </ProjectAccordion>
                    </div>
                </SimpleBar>
            </div>
        )
}

export default memo(LoggingEditSpace)
