import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ArrowLeftIcon } from '../../image_files/icons/ArrowLeftIcon'
import { RootState } from '../../store/store'
// @ts-ignore
import s from './pagination.module.css'

const defaultPaginationData = {
    page: 1,
    pageCount: 20,
    portionSize: 5,
}

interface IProps {
    goToFirstPageTrigger?:number

    onChange: (data: { limit: number; offset: number }) => void
}

export const Pagination: FC<IProps> = ({ goToFirstPageTrigger,onChange }) => {
    const { pageCount, page, portionSize } = defaultPaginationData

    const count = useSelector((state: RootState): number => state.tasks.count)
    const [currentPage, setCurrentPage] = useState(page)

    let pages = []
    let pagesCount = Math.ceil(count / pageCount)

    for (let i = 1; i <= pagesCount; i++) {
        pages.push(i)
    }

    let portionCount = Math.ceil(pagesCount / portionSize)
    let [portionNumber, setPortionNumber] = useState(1)
    let leftPortionPageNumber = (portionNumber - 1) * portionSize + 1
    let rightPortionPageNumber = portionNumber * portionSize

    const changePageHandler = (pageNumber: number) => {
        const params = {
            limit: 20,
            offset: 20 * (pageNumber - 1),
        }

        onChange(params)
        setCurrentPage(pageNumber)
    }

    const handleLeftArrow = () => {
        if (portionNumber > 1) {
            setPortionNumber(portionNumber - 1)
            changePageHandler(currentPage-1)
        }
        changePageHandler(currentPage-1)
    }

    const showPageNumbers = () => {
        return pages
            .filter(
                (p) =>
                    p >= leftPortionPageNumber && p <= rightPortionPageNumber,
            )
            .map((p, i) => {
                return (
                    <div
                        className={`${s.pageNumber} ${
                            currentPage === p ? s.selectedPage : ''
                        }`}
                        key={i}
                        onClick={() => {
                            changePageHandler(p)
                        }}
                    >
                        {' '}
                        {p}
                    </div>
                )
            })
    }

    const handleRightArrow = () => {
        if (portionNumber < portionCount) {
            setPortionNumber(portionNumber + 1)
            
        }
        changePageHandler(currentPage+1)
    }

    useEffect(()=>{
        if(goToFirstPageTrigger && goToFirstPageTrigger>0){
            setCurrentPage(1)
        }
    },[goToFirstPageTrigger])

    useEffect(()=>{
        setCurrentPage(1)
    },[count])

    useEffect(()=>{
        if(portionNumber>1){
            
        }
    },[portionNumber])

    if (count <= 0) {
        return <div></div>
    } else
        return (
            <div className={s.paginator}>
                <div className={s.paginatorContainer}>
                    <div className={s.pageContainer}>
                        <button
                            className={s.paginatorBtn}
                            disabled={currentPage === 1}
                            onClick={handleLeftArrow}
                        >
                            <ArrowLeftIcon />
                        </button>
                        <div className={s.pageNumbersBlock}>
                            {showPageNumbers()}
                        </div>
                        <button
                            className={s.paginatorBtn}
                            disabled={currentPage === pagesCount}
                            onClick={handleRightArrow}
                        >
                            <ArrowLeftIcon
                                style={{ transform: 'rotate(180deg' }}
                            />
                        </button>
                    </div>
                </div>
            </div>
        )
}
