import React from 'react'

export const AccordionArrow = ({ style, className }) => {
    return (
        <svg
            className={className ? className : ''}
            style={style ? style : {}}
            width="16"
            height="6"
            viewBox="0 0 16 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.0439 4.58838L8.5083 1.27793C8.22751 1.1357 7.89614 1.13398 7.61388 1.27327L1.04413 4.51552"
                stroke="#2B506B"
                stroke-linecap="round"
            />
        </svg>
    )
}
