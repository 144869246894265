import {
    ACOUSTIC_VELOCITY,
    BIO,
    BULK_MODULUS,
    CAPACITY,
    CAPILLARY_CURVE,
    CARBONATES,
    COMPRESSIBILITY,
    COMPRESSIVE_STRENGTH,
    CONDUCTIVITY,
    DENSITY,
    DIFFUSIVITY,
    ELASTIC_LIMIT,
    OIL_DISPLACEMENT,
    PERMEABILITY,
    POISSON_RATIO,
    POROSITY,
    PYROLYSIS,
    SATURATION,
    SHEAR_MODULUS,
    ULTIMATE_COMPRESSIVE_STRENGTH,
    YIELD_STRENGTH,
    YOUNG_MODULE,
} from '../../../../../../../utils/constants'
import { Porosity } from '../../../../../../../entities/porosity/Porosity'
import { Permeability } from '../../../../../../../entities/permeability/Permeability'
import { Saturation } from '../../../../../../../entities/saturation/Saturation'
import { Displacement } from '../../../../../../../entities/displacement/Displacement'
import { CarbonateContent } from '../../../../../../../entities/carbonateContent/CarbonateContent'
import { Density } from '../../../../../../../entities/density/Density'
import { CapillaryCurve } from '../../../../../../../entities/capillaryCurve/CapillaryCurve'
import { CommonSetting } from '../../../../../../../entities/commonSetting/CommonSetting'
import { TargetParameters } from '../../../../../../../app/types/enums'
import { getLocalizedText } from '../../../../../../../utils/helpers/getLocalizedText'
import { acousticWaveTypes } from './defaultCoresData'
import { useTranslation } from 'react-i18next'
import { Compressibility } from '../../../../../../../entities/compressibility/Compressibility'
import { Conductivity } from '../../../../../../../entities/conductivity/Conductivity'
import { Diffusivity } from '../../../../../../../entities/diffusivity/Diffusivity'
import { Capacity } from '../../../../../../../entities/capacity/Capacity'
import React from 'react'
import Pyrolysis from '../../../../../../../entities/pyrolysis/Pyrolysis'

export const RenderedGroupSettingByParam = (
    activeGroupItem,
    projectId,
    currentCoreId,
    standardGroupId,
    setActiveGroupItem,
    setStandardGroupId,
    mechanicalGroupId,
    setMechanicalGroupId,
    thermalGroupId,
    setThermalGroupId,
    chemicalGroupId,
    setChemicalGroupId,
) => {
    const { t } = useTranslation()
    switch (activeGroupItem) {
        case POROSITY:
            return (
                <Porosity
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={standardGroupId}
                    setGroup={setStandardGroupId}
                    setActiveItem={setActiveGroupItem}
                />
            )
        case PERMEABILITY:
            return (
                <Permeability
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={standardGroupId}
                    setGroup={setStandardGroupId}
                />
            )
        case SATURATION:
            return (
                <Saturation
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={standardGroupId}
                    setGroup={setStandardGroupId}
                />
            )
        case OIL_DISPLACEMENT:
            return (
                <Displacement
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={standardGroupId}
                    setGroup={setStandardGroupId}
                />
            )
        case CARBONATES:
            return (
                <CarbonateContent
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={standardGroupId}
                    setGroup={setStandardGroupId}
                />
            )
        case DENSITY:
            return (
                <Density
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={standardGroupId}
                    setGroup={setStandardGroupId}
                />
            )
        case CAPILLARY_CURVE:
            return (
                <CapillaryCurve
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={standardGroupId}
                    setGroup={setStandardGroupId}
                />
            )
        case YOUNG_MODULE:
            return (
                <CommonSetting
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={mechanicalGroupId}
                    settingType={YOUNG_MODULE}
                    groupType={'mechanical'}
                    valueType={'positiveNumber'}
                    parameter={TargetParameters.youngs_modulus}
                    setGroup={setMechanicalGroupId}
                    setActiveItem={setActiveGroupItem}
                />
            )
        case POISSON_RATIO:
            return (
                <CommonSetting
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={mechanicalGroupId}
                    settingType={POISSON_RATIO}
                    groupType={'mechanical'}
                    parameter={TargetParameters.poissons_ratio}
                    valueLabel={t('gpa')}
                    setGroup={setMechanicalGroupId}
                />
            )
        case ACOUSTIC_VELOCITY:
            return (
                <CommonSetting
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={mechanicalGroupId}
                    settingType={ACOUSTIC_VELOCITY}
                    groupType={'mechanical'}
                    parameter={TargetParameters.elastic_wave}
                    typeLabel={t('wave-type')}
                    valueLabel={t('value-km')}
                    valueType={'positiveNumber'}
                    typeList={acousticWaveTypes(t)}
                    setGroup={setMechanicalGroupId}
                />
            )
        case COMPRESSIBILITY:
            return (
                <Compressibility
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={mechanicalGroupId}
                    setGroup={setMechanicalGroupId}
                />
            )
        case SHEAR_MODULUS:
            return (
                <CommonSetting
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={mechanicalGroupId}
                    settingType={SHEAR_MODULUS}
                    groupType={'mechanical'}
                    parameter={TargetParameters.shear_modulus}
                    valueType={'positiveNumber'}
                    setGroup={setMechanicalGroupId}
                />
            )
        case YIELD_STRENGTH:
            return (
                <CommonSetting
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={mechanicalGroupId}
                    settingType={YIELD_STRENGTH}
                    groupType={'mechanical'}
                    parameter={TargetParameters.yield_strength}
                    valueType={'positiveNumber'}
                    setGroup={setMechanicalGroupId}
                />
            )
        case BIO:
            return (
                <CommonSetting
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={mechanicalGroupId}
                    settingType={BIO}
                    groupType={'mechanical'}
                    parameter={TargetParameters.bio}
                    valueType={'positiveNumber'}
                    valueLabel={t('value')}
                    setGroup={setMechanicalGroupId}
                />
            )
        case BULK_MODULUS:
            return (
                <CommonSetting
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={mechanicalGroupId}
                    settingType={BULK_MODULUS}
                    groupType={'mechanical'}
                    parameter={TargetParameters.bulk_modulus}
                    valueType={'positiveNumber'}
                    setGroup={setMechanicalGroupId}
                />
            )
        case COMPRESSIVE_STRENGTH:
            return (
                <CommonSetting
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={mechanicalGroupId}
                    settingType={COMPRESSIVE_STRENGTH}
                    groupType={'mechanical'}
                    parameter={TargetParameters.uniaxial_compressive_strength}
                    valueType={'positiveNumber'}
                    valueLabel={t('strength-value')}
                    setGroup={setMechanicalGroupId}
                />
            )
        case ULTIMATE_COMPRESSIVE_STRENGTH:
            return (
                <CommonSetting
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={mechanicalGroupId}
                    settingType={ULTIMATE_COMPRESSIVE_STRENGTH}
                    groupType={'mechanical'}
                    parameter={TargetParameters.ultimate_compressive_strength}
                    valueType={'positiveNumber'}
                    valueLabel={t('strength-value')}
                    setGroup={setMechanicalGroupId}
                />
            )
        case ELASTIC_LIMIT:
            return (
                <CommonSetting
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={mechanicalGroupId}
                    settingType={ELASTIC_LIMIT}
                    groupType={'mechanical'}
                    parameter={TargetParameters.elastic_limit}
                    valueType={'positiveNumber'}
                    valueLabel={t('strength-value')}
                    setGroup={setMechanicalGroupId}
                />
            )
        case CONDUCTIVITY:
            return (
                <Conductivity
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={thermalGroupId}
                    setGroup={setThermalGroupId}
                />
            )
        case DIFFUSIVITY:
            return (
                <Diffusivity
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={thermalGroupId}
                    setGroup={setThermalGroupId}
                />
            )
        case CAPACITY:
            return (
                <Capacity
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={thermalGroupId}
                    setGroup={setThermalGroupId}
                />
            )
        case PYROLYSIS:
            return (
                <Pyrolysis
                    projectId={projectId}
                    coreId={currentCoreId}
                    groupId={chemicalGroupId}
                    setActiveItem={setActiveGroupItem}
                    setGroup={setChemicalGroupId}
                />
            )
        default:
            return <></>
    }
}
