import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Input } from 'antd'
import { IInputProps } from './types'
import { useTranslation } from 'react-i18next'
import useDebounce from '../../hooks/useDebounce'
import { DEBOUNCE_DELAY } from '../../utils/constants'
import { formatResultSum } from '../../utils/helpers/formatResultSum'
import './input.scss'

const InputInLog: FC<IInputProps> = (props) => {
    const {
        max,
        type,
        rules,
        style = {},
        scale,
        value,
        prefix,
        disabled,
        className,
        autoFocus,
        isShowError,
        placeholder,
        defaultValue,
        debouncedMode,

        onBlur,
        onFocus,
        onChange,
        setFieldError,
        onKeyDownhandler,
    } = props
    const { t } = useTranslation()
    const [inputValue, setInputValue] = useState<string | undefined>(undefined)
    const debouncedValue = useDebounce(inputValue, DEBOUNCE_DELAY)
    const [isFocusedField, setIsFocusedField] = useState(false)
    const [error, setError] = useState<string>('')

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const localValue = e.target.value
        if (localValue.trim() !== '') {
            setError('')
        }
        value === undefined && setInputValue(localValue)
        if (!debouncedMode) {
            onChange && onChange(localValue)
        }
    }

    const onFocusHandler = () => {
        setIsFocusedField(true)
        onFocus && onFocus()
    }

    const validateByRules = () => {
        const isValidInputValue =
            inputValue !== undefined && inputValue.length > 0
        if (rules && rules.length > 0) {
            let errorValue = ''
            rules.forEach((rule) => {
                if (rule?.isRequired) {
                    if (!isValidInputValue) {
                        errorValue = t('required-field')
                    }
                }
                if (rule?.pattern) {
                    if (!isValidInputValue) {
                        errorValue = t('required-field')
                    } else {
                        if (!rule.pattern.test(inputValue)) {
                            errorValue = rule?.message
                                ? rule.message
                                : t('value-doesnt-correspond-requirements')
                        }
                    }
                }
            })
            if(errorValue.length>0){
                setError(errorValue)
            }else{
                setError('')
            }
        }
    }

    const onBlurHandler = () => {
        setInputValue(
            type === 'float-numeric'
                ? formatResultSum(inputValue, scale)
                : inputValue,
        )

        onBlur && onBlur()
        setIsFocusedField(false)
    }

    const onChangeSumHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setError('')
        let val = e.target.value
            .split('')
            .filter((el) => el !== ' ')
            .join('')

        const targetPattern = new RegExp(
            `^[0-9_ ]{1,11}/*(\\.[0-9]{0,${
                scale !== undefined ? scale : 2
            }})?$`,
        )

        if ((max !== undefined && Number(val) <= max) || max === undefined) {
            if (targetPattern.test(val)) {
                setInputValue(val)

                if (!debouncedMode) {
                    onChange && onChange(val)
                }
            } else {
                const prevVal = val
                    .split('')
                    .slice(0, val.length - 1)
                    .join('')

                setInputValue(prevVal)

                if (!debouncedMode) {
                    onChange && onChange(prevVal)
                }
            }
        }
    }

    useEffect(() => {
        defaultValue !== undefined &&
            defaultValue !== null &&
            setInputValue(defaultValue)
    }, [defaultValue])

    useEffect(() => {
        if (value !== undefined && value !== null) {
            setInputValue(value)
        }
    }, [value])

    useEffect(() => {
        if (debouncedMode && error.length === 0) {
            if (debouncedValue !== defaultValue) {
                onChange && onChange(debouncedValue)
            }
        }
        // onChange && onChange(debouncedValue)
    }, [debouncedValue])

    useEffect(() => {
        setFieldError && setFieldError(error)
    }, [error])

    useEffect(() => {
        validateByRules()
    }, [rules])

    const renderInputByType = () => {
        if (type === 'float-numeric') {
            return (
                <Input
                    type={'text'}
                    style={style}
                    prefix={prefix}
                    value={inputValue}
                    disabled={disabled}
                    placeholder={placeholder}
                    className={className ? `input ${className}` : 'input'}
                    onChange={onChangeSumHandler}
                    onFocus={onFocusHandler}
                    onBlur={onBlurHandler}
                />
            )
        } else
            return (
                <Input
                    className={className ? `input ${className}` : 'input'}
                    value={inputValue}
                    placeholder={placeholder}
                    disabled={disabled}
                    type={type ? type : 'text'}
                    style={style}
                    prefix={prefix}
                    autoFocus={autoFocus}
                    onChange={onChangeHandler}
                    onFocus={onFocusHandler}
                    onBlur={onBlurHandler}
                    onKeyDown={onKeyDownhandler}
                />
            )
    }

    return (
        <div
            className={`input-wrapper ${
                error.length > 0 ? 'input-wrapper-error' : ''
            }`}
            data-focused={isFocusedField ? 'focused' : 'unFocused'}
        >
            {renderInputByType()}
            {error.length > 0 && isShowError && (
                <div className="input-error-text">{error}</div>
            )}
        </div>
    )
}

export default InputInLog
