import { ITag } from '../../app/types/models/tasks'
import { TagsTypes } from '../TagsDropdown/types'

export interface SystemTagItem extends ITag {
    type?: 'folder' | 'service',
    systemType?:SystemTagsTypes
}

export interface SystemTagListItem {
    list: SystemTagItem[]
    name: string
}

export enum SystemTagsTypes {
    PAD = 'pad',
    WELL = 'well',
    WELLBORE = 'wellbore',
    CORE = 'core',
    METHOD = 'method',
    EQUIPMENT = 'equipment',
    STUDIES = 'studies',
    STANDARD_STUDIES='standard-studies',
    MECHANICAL_STUDIES='mechanical-studies',
    THERMAL_STUDIES='thermal-studies',
    CHEMICAL_STUDIES='chemical-studies',

}

export interface ITagsListProps {
    tagType?: string
    selectedTags: ITag[]
    currentTab: TagsTypes

    fetchedTags: ITag[]
    deleteTags?: (list: number[]) => void
    addTags?: (list: string[]) => void
}
