import React, {FC, useEffect, useState} from 'react';
import {PlusIcon} from "../../../../../../../../../../image_files/icons/PlusIcon";
import {useTranslation} from "react-i18next";
import './renderCustomGroupMeasurement.scss';
import SettingCancelIcon from "../../../../../../../../../../image_files/icons/SettingCancelIcon";
import {ICustomGroupMeasurement, ICustomGroupParameter} from "../../../../../../../../../../app/types/models/i-custom-group";
import {CustomGroupParameters} from "../../../../../../../../../../entities/customGroup/types";
import CustomGroupParameter from "../../../../../../../../../../shared/customGroupParameter/CustomGroupParameter";
import {IRenderCustomGroupMeasurementProps, IRenderGroupParameter, IRenderParameterGroup} from "./types";
import {customGroupService} from "../../../../../../../../../../services/custom-group-service";
import {useSelector} from "react-redux";
import {selectCurrentProject} from "../../../../../../../../../../store/currentProject/selectors";
import {errorsHandler} from "../../../../../../../../../../utils/helpers/errors/errors-hendler";
import {prepareParametersToRender} from "./services/prepareParametersToRender";
import {generateSelectOptions} from "./services/generateSelectOptions";
import {showNotice} from '../../../../../../../../../../utils/helpers/showNotice';
import {Skeleton} from "antd";

const RenderCustomGroupMeasurement: FC<IRenderCustomGroupMeasurementProps> = ({measurement, groupId, coreId}) => {
    const {i18n, t} = useTranslation()
    const [renderedParameterGroups, setRenderedParameterGroups] = useState<IRenderParameterGroup[]>([])
    const currentProject = useSelector(selectCurrentProject)
    const [currentMeasurement, setCurrentMeasurement] = useState<ICustomGroupMeasurement>({} as ICustomGroupMeasurement)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage,setErrorMessage] = useState('')
    const [numberOfPoints,setNumberOfPoints] = useState(null)
    useEffect(() => {
        fetchMeasurements()
    }, [measurement])

    const fetchMeasurements = async () => {
        try {
            setIsLoading(true)
            const response = await customGroupService.getCustomMeasurements(currentProject?.id, coreId, groupId)
            const targetMeasurement = response.find(el => el.id === measurement.id) as any
            if (targetMeasurement) {
                const parametersGroupResponse = await customGroupService.getParameterGroups(currentProject?.id, coreId, groupId, targetMeasurement?.id)
                targetMeasurement.groupsParameters = parametersGroupResponse
                if (Array.isArray(parametersGroupResponse) && parametersGroupResponse.length > 0) {
                    for (let k = 0; k < parametersGroupResponse.length; k++) {
                        const parameterGroupItem = parametersGroupResponse[k];
                        const parameterResponse = await customGroupService.getParameters(currentProject?.id, coreId, groupId, targetMeasurement?.id, parameterGroupItem?.id)
                        targetMeasurement.groupsParameters[k].parameters = parameterResponse.map((el) => {
                            return {
                                ...el,
                            }
                        })
                        if (parameterResponse && Array.isArray(parameterResponse)) {
                        }
                    }
                }
                targetMeasurement && setCurrentMeasurement(targetMeasurement)
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            errorsHandler(e, t)
        }
    }

    const showParameterByType = (item: ICustomGroupParameter, parameterGroup: IRenderParameterGroup) => {
        switch (item.type) {
            case CustomGroupParameters.CUSTOM_DROPDOWN:
                return (
                    <CustomGroupParameter type={item.type}
                                          label={item?.data?.title}
                                          onChange={(val) => {
                                              console.log('onChange render, CustomGroupParameters.CUSTOM_DROPDOWN')
                                              changeParameterHandler(val, item, parameterGroup)
                                          }}
                                          options={generateSelectOptions(item?.data?.tags, i18n.language)}
                                          value={item?.data?.value}
                    />
                )
            case CustomGroupParameters.NUMERIC_VALUE:
                return (
                    <CustomGroupParameter type={item.type}
                                          label={item?.data?.element_name}
                                          onChange={(val) => {
                                              console.log('onChange render, CustomGroupParameters.NUMERIC_VALUE')
                                              changeParameterHandler(val, item, parameterGroup)
                                          }}
                                          rules={item?.data?.range}
                                          value={item?.data?.value}

                    />
                )
            case CustomGroupParameters.SET_OF_PAIRS:
                return (
                    <CustomGroupParameter type={item.type}
                                          label={[item?.data?.x_title, item?.data?.y_title]}
                                          onChange={(val) => {
                                              changeParameterHandler(val, item, parameterGroup)
                                          }}
                                          rules={[item?.data?.x_range, item?.data?.y_range]}
                                          value={item?.data?.value}
                    />
                )
            default:
                return ''
        }
    }

    useEffect(() => {
        renderParameterGroups()
    }, [currentMeasurement, groupId])

    const renderParameterGroups = () => {
        if (currentMeasurement && Object.keys(currentMeasurement).length > 0) {
            const preparedParameterGroups = []
            currentMeasurement?.groupsParameters?.forEach((el) => {
                const preparedParameters = prepareParametersToRender(el.parameters)
                const isCompleted = preparedParameters?.filter(el => el && el?.data && el?.data?.value && el?.data?.value !== '')?.length === preparedParameters.length
                console.log(preparedParameters, '---preparedParameters')
                preparedParameterGroups.push({
                    id: el.id,
                    parameters: preparedParameters,
                    completed: isCompleted,
                    saved: true //saved on server
                })
            })
            setRenderedParameterGroups(preparedParameterGroups)
        }
    }

    const showParameterGroups = () => {
        return renderedParameterGroups.map((parameterGroup: IRenderParameterGroup) => {
            return (
                <div className={'mt-20 custom-group-parameters'} key={parameterGroup.id}>
                    <div className={'custom-group-parameters-wrapper'}>
                        {
                            parameterGroup?.parameters.map((item, index) => (
                                <div key={index}>{showParameterByType(item, parameterGroup)}</div>
                            ))
                        }
                    </div>
                    <button
                        className={'custom-group-parameters--deleteBtn'}
                        onClick={() => renderedParameterGroups?.length > 0 ? deleteParametersGroup(parameterGroup) : () => {
                        }}
                    >
                        <SettingCancelIcon/>
                    </button>
                </div>
            )
        })
    }

    const addParameterGroup = () => {
        const lastItem = renderedParameterGroups.at(-1)
        setRenderedParameterGroups(
            [...renderedParameterGroups,
                {
                    id: lastItem.id + 1,
                    parameters: prepareParametersToRender(currentMeasurement?.groupsParameters[0]?.parameters, true),
                    completed: false,
                    saved: false
                }
            ])
    }

    const deleteParametersGroup = async (parameterGroup: IRenderParameterGroup) => {
        try {
            if (parameterGroup?.saved && renderedParameterGroups.length > 1) {
                await customGroupService.deleteParameterGroup(currentProject?.id, coreId, groupId, +currentMeasurement?.id, parameterGroup?.id)
                showNotice('setting-deleted-successfully', t)
            }
            if (renderedParameterGroups.length > 1) {
                setRenderedParameterGroups(renderedParameterGroups.filter(item => item.id !== parameterGroup?.id))
            } else {
                setRenderedParameterGroups([
                    {
                        id: currentMeasurement.groupsParameters[0]?.id,
                        parameters: prepareParametersToRender(currentMeasurement?.groupsParameters[0]?.parameters),
                        completed: false,
                        saved: false
                    }])
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const changeParameterHandler = async (value: { value: any, isError: boolean }, parameter: IRenderGroupParameter | ICustomGroupParameter, parameterGroup: IRenderParameterGroup) => {
        const changedParameters = parameterGroup.parameters.map((param) => {
            if (param.id === parameter?.id) {
                return {
                    ...param,
                    data: {
                        ...param.data,
                        value: value.value,
                    },
                    isError: value.isError
                }
            } else return param
        }) as IRenderGroupParameter[]
        const readyValuesArr = changedParameters.map(item => {
            if (item.hasOwnProperty('isError')) {
                return item?.isError === undefined ? false : item?.isError === false
            } else return false
        })
        const readyStatus = readyValuesArr.filter(el => el === true).length === changedParameters.length
        let changedParameterGroup: IRenderParameterGroup = {
            ...parameterGroup,
            parameters: changedParameters,
            completed: readyStatus
        }
        let parameterGroupResponse;
        if (readyStatus) {
            if (changedParameterGroup?.saved) {
                saveParameters(changedParameterGroup, changedParameterGroup?.id, parameter, readyStatus).then((data: any) => {
                    changedParameterGroup = data
                })
                    .catch(() => {
                        changedParameterGroup = {
                            ...parameterGroup,
                            parameters: changedParameters.map(el => el.id === parameter.id
                                ? {
                                    ...parameter, data: {
                                        ...parameter.data,
                                        value: ''
                                    }
                                }
                                : el
                            ),
                            completed: readyStatus
                        }
                    })

            } else {
                try {
                    parameterGroupResponse = await customGroupService.addParameterGroup(currentProject?.id, coreId, groupId, +currentMeasurement?.id, {})
                    if (changedParameterGroup && changedParameterGroup?.parameters && changedParameterGroup?.parameters?.length > 0) {
                        saveParameters(changedParameterGroup, parameterGroupResponse?.id, parameter, readyStatus).then((data: any) => {
                            changedParameterGroup = data
                            setRenderedParameterGroups(renderedParameterGroups.map(el => {
                                return el.id === parameterGroup.id ? {...data} : el
                            }))
                        })
                    }

                } catch (e) {
                    errorsHandler(e, t)
                }
            }
        } else {
            setRenderedParameterGroups(renderedParameterGroups.map(el => {
                return el.id === parameterGroup.id ? {...changedParameterGroup} : el
            }))
        }
    }

    const saveParameters = async (
        parameterGroup: IRenderParameterGroup,
        fetchedParameterGroupId: number,
        parameter: IRenderGroupParameter | ICustomGroupParameter,
        isReadyGroup: boolean
    ) => {
        try {
            let changedParameters = [...parameterGroup?.parameters]
            let changedParametersGroup;
            if (parameterGroup?.saved) {
                //обновление параметров
                //1) обновить все параметры только при условии заполнении всей группы
                //2) точечно обновить каждый параметр
                for (let i = 0; i < parameterGroup?.parameters?.length; i++) {
                    let element = parameterGroup?.parameters[i]
                    await customGroupService.updateParameter(currentProject?.id, coreId, groupId, +currentMeasurement?.id, fetchedParameterGroupId, element?.id, {
                        type: element?.type,
                        data: JSON.stringify(element?.data),
                    })
                }
                changedParametersGroup = {
                    ...parameterGroup,
                    id: fetchedParameterGroupId,
                    parameters: changedParameters,
                    saved: true,
                    completed: true
                }
                showNotice('setting-updated-successfully', t)
            } else {
                //создание параметров
                for (let i = 0; i < parameterGroup?.parameters?.length; i++) {
                    let element = parameterGroup?.parameters[i]
                    let response = await customGroupService.addParameter(currentProject?.id, coreId, groupId, +currentMeasurement?.id, fetchedParameterGroupId, {
                        type: element?.type,
                        data: JSON.stringify(element?.data)
                    })
                    changedParameters = changedParameters.map((el) => el?.id === element.id ? {
                        ...el,
                        id: response.id,
                        isError: false,
                    } : {...el, isError: false})

                    changedParametersGroup = {
                        ...parameterGroup,
                        id: fetchedParameterGroupId,
                        parameters: changedParameters,
                        saved: true,
                        completed: true
                    }
                }
                showNotice('setting-created-successfully', t)
            }
            return Promise.resolve(changedParametersGroup)
        } catch (e) {
            return Promise.reject()
        }
    }

    useEffect(()=>{
        if(currentMeasurement && Object.keys(currentMeasurement).length>0){
            const targetGroupParameter = currentMeasurement?.groupsParameters?.find(el=>{
                const targetParameter = el.parameters?.find(val=>val?.type===CustomGroupParameters.SET_OF_PAIRS)
                if(targetParameter){
                    return el
                }else return undefined
            })
            if(targetGroupParameter){
                const parameterData = targetGroupParameter.parameters[0]?.data && typeof targetGroupParameter.parameters[0]?.data === 'string' ? JSON.parse(targetGroupParameter.parameters[0]?.data) : null
                if(parameterData){
                    const pointsNumber = parameterData?.points_number
                    if(!isNaN(Number(pointsNumber))){
                        setNumberOfPoints(pointsNumber)
                    }
                }
            }

        }

    },[currentMeasurement])

    useEffect(()=>{
        if(numberOfPoints && renderedParameterGroups.length>=numberOfPoints){
            setErrorMessage(t('max-pairs-of-points-value',{value:numberOfPoints}))
        }else{
            setErrorMessage('')
        }
    },[renderedParameterGroups,numberOfPoints])

    return (
        <div>
            <button
                className={'addSettingBtn'}
                onClick={addParameterGroup}
                disabled={errorMessage.length>0}
            >
                <PlusIcon/>
            </button>
            <div className={'mt-10 font-error'}>{errorMessage}</div>
            <div>
                {
                    isLoading ? <Skeleton.Input active={true}
                                                className={'custom-group-parameter--skeleton'}/> : showParameterGroups()
                }
            </div>
        </div>
    );
};

export default RenderCustomGroupMeasurement;