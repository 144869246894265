import React, { FC, useState } from "react";
import './coreBoxEditSpace.css'
import { ICoreBoxEditSpaceProps } from "./types";
import { useTranslation } from "react-i18next";
import CoreBoxPreparing from "../coreBoxPreparing/CoreBoxPreparing";

const CoreBoxEditSpace:FC<ICoreBoxEditSpaceProps> = (
    {
        currentBox,
        changeCurrentBox
    }) =>{
    const {t} = useTranslation()
    const [currentTab,setCurrentTab] = useState(1)

    const showActiveTabClass = (tab:number)=>{
        return tab===currentTab ? 'activeTab' : ''
    }

    const showActiveContent = () => {
        switch(currentTab){
            case 1:
                return <CoreBoxPreparing currentBox={currentBox} onSave={changeCurrentBox}/>
            default:
                return ''
        }
    }

    return (
        <div className="core-box-edit-space">
            <ul className="core-box-edit-space-top">
                <li className={showActiveTabClass(1)} onClick={()=>setCurrentTab(1)}>{t('preparing')}</li>
                <li className={showActiveTabClass(2)} onClick={()=>setCurrentTab(2)}>{t('core-column')}</li>
                <li className={showActiveTabClass(3)} onClick={()=>setCurrentTab(3)}>{t('laboratory-samples')}</li>
                <li className={showActiveTabClass(4)} onClick={()=>setCurrentTab(4)}>{t('add-profiles')}</li>
            </ul>
            <div className="core-box-edit-space-content">
                {showActiveContent()}
            </div>
        </div>
    )
}

export default CoreBoxEditSpace;