import React, {FC} from 'react'

interface IProps{
    className?:string
}
export const BallIcon:FC<IProps> = ({ className}) => {
    return (
        <svg
            id={'ballIcon'}
            className={className ? className : ''}
            width="23"
            height="30"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="16" height="15" rx="7.5" fill="white" />
            <rect
                x="0.2"
                y="0.2"
                width="15.6"
                height="14.6"
                rx="7.3"
                stroke="black"
                strokeOpacity="0.7"
                strokeWidth="0.4"
            />
            <ellipse cx="8" cy="7.5" rx="3" ry="7.5" fill="white" />
            <path
                d="M10.8 7.5C10.8 9.55214 10.4669 11.4008 9.93563 12.729C9.66976 13.3937 9.35818 13.9182 9.02242 14.2732C8.68707 14.6278 8.3415 14.8 8 14.8C7.6585 14.8 7.31293 14.6278 6.97758 14.2732C6.64182 13.9182 6.33024 13.3937 6.06438 12.729C5.53308 11.4008 5.2 9.55214 5.2 7.5C5.2 5.44786 5.53308 3.59921 6.06438 2.27098C6.33024 1.60631 6.64182 1.08184 6.97758 0.726809C7.31293 0.372202 7.6585 0.2 8 0.2C8.3415 0.2 8.68707 0.372202 9.02242 0.726809C9.35818 1.08184 9.66976 1.60631 9.93563 2.27098C10.4669 3.59921 10.8 5.44786 10.8 7.5Z"
                stroke="black"
                strokeOpacity="0.7"
                strokeWidth="0.4"
            />
            <path
                d="M0 7L16 7"
                stroke="black"
                strokeOpacity="0.7"
                strokeWidth="0.4"
            />
            <path
                d="M2 3L14 3"
                stroke="black"
                strokeOpacity="0.7"
                strokeWidth="0.4"
            />
            <line
                x1="2"
                y1="11.8"
                x2="14"
                y2="11.8"
                stroke="black"
                strokeOpacity="0.7"
                strokeWidth="0.4"
            />
        </svg>
    )
}
