import * as appSelectors from './selectors'
import * as actions from './actions'
import { slice } from './app-slice'

const appActions = {
    ...actions,
}

const appReducer = slice.reducer

export { appActions, appSelectors, appReducer }
