import React, {FC, useCallback, useEffect, useState} from 'react';
import './participant.scss';
import {IParticipantProps} from "./types";
import {getValidText} from "../../../../utils/helpers/getValidText";
import {getFirstMemberCharacter, getFirstUserCharacter} from "../../../../utils/helpers/getFirstMemberCharacter";

const Participant: FC<IParticipantProps> = ({item}) => {
    const storageMembers = JSON.parse(sessionStorage.getItem('members'))
    const [bgColor, setBgColor] = useState('')

    const findCurrentBgColor = useCallback(() => {
        const currentMember = storageMembers.filter((f) => {
            if (f?.user?.id === item?.id) {
                return f
            }
        })
        return `${currentMember[0]?.color}`
    }, [storageMembers])

    useEffect(() => {
        setBgColor(findCurrentBgColor())
    }, [storageMembers])
    return (
        <div className={'participant'}>
            {item && item?.avatar ? (
                <img
                    className={'memberItem__img'}
                    src={item?.avatar ? item?.avatar?.small : ''}
                    alt="user image"
                />
            ) : <div
                className={'membersSelect__bottom-phone'}
                style={{backgroundColor: 'var(--main-text-color)',color:'#fff'}}
            >
                <span>{getFirstUserCharacter(item)}</span>
            </div>}
            <div className={' font-14-normal participant-email'}>{getValidText(item?.email)}</div>
        </div>
    );
};

export default Participant;