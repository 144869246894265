import { Button } from "antd"
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Countries } from "../../../../../../app/types/countries-enum"
import ShareProject from "../../../../../../features/shareProject/ShareProject"
import { useAppDispatch } from '../../../../../../hooks/redux-hooks'
import { projectsService } from '../../../../../../services/projects-service.'
import { InfoItem } from '../../../../../../shared/infoItem/InfoItem'
import MembersBlock from '../../../../../../shared/membersBlock/MembersBlock'
import { currentProjectActions, currentProjectSelectors, } from '../../../../../../store/currentProject'
import { membersSelectors } from '../../../../../../store/members'
import { projectsActions } from '../../../../../../store/projects'
import { errorsHandler } from '../../../../../../utils/helpers/errors/errors-hendler'
import { showNotice } from "../../../../../../utils/helpers/showNotice"
import './loggingBaseInfo.scss'

const LoggingBaseInfo = () => {
    const { t } = useTranslation()
    const currentProject = useSelector(
        currentProjectSelectors.selectCurrentProject,
    )
    const members = useSelector(membersSelectors.selectMembers)
    const dispatch = useAppDispatch()
    const currentProjectPath = location.pathname.split('/').at(-1)
    const [country, setCountry] = useState<Countries>(currentProject.country)
    const { updateProject } = currentProjectActions
    const { updateProjectName } = projectsActions
    const [isOpenModal,setIsOpenModal] = useState(false)
    const [loading,setLoading] = useState<{[x:string]:boolean}>({} as {[x:string]:boolean})

    const upDateProject = useCallback(
        async (projectData) => {
            const field = Object.keys(projectData)[0] ? Object.keys(projectData)[0] : ''
            try {
                setLoading({
                    ...loading,
                    [field]:true
                })
                const response = await projectsService.updateProject(
                    Number(currentProjectPath),
                    { ...projectData },
                )
                dispatch(updateProject({ ...projectData }))
                setCountry(response.country)
                if (projectData.name) {
                    dispatch(
                        updateProjectName({
                            id: Number(currentProjectPath),
                            name: projectData.name,
                        }),
                    )
                }
                showNotice('data-udated-successfully',t)
                setLoading({
                    ...loading,
                    [field]:false
                })
            } catch (e) {
                setLoading({
                    ...loading,
                    [field]:false
                })
                errorsHandler(e, t )
                //todo - проверить целесообразность
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            }
        },
        [currentProjectPath],
    )

    const setProjectNameHandler = (value: string) =>
        upDateProject({ name: value })
    const setReservoirNameHandler = (value: string) =>
        upDateProject({ reservoir: value })
    const setCompanyCustomerHandler = (value: string) =>
        upDateProject({ company_customer: value })
    const setContractorHandler = (value: string) =>
        upDateProject({ contractor: value })

    const updateCountryHandler = async (value: string): Promise<void> => {
        await upDateProject({ country: value })
    }
    return (
        <div className="projects__info">
            <InfoItem
                name={t('project-name')}
                value={currentProject.name}
                onChange={setProjectNameHandler}
                isLoading={!!(loading['name'])}
            />
            <InfoItem
                name={t('resorvior')}
                value={currentProject.reservoir}
                onChange={setReservoirNameHandler}
                isLoading={!!(loading['reservoir'])}
            />
            <InfoItem
                name={t('company-customer')}
                value={currentProject.company_customer}
                onChange={setCompanyCustomerHandler}
                isLoading={!!(loading['company_customer'])}
            />
            <InfoItem
                name={t('assignee')}
                value={currentProject.contractor}
                onChange={setContractorHandler}
                isLoading={!!(loading['contractor'])}
            />
            <InfoItem
                country={country}
                name={t('object-country')}
                type={'country'}
                value={currentProject.country as string}
                onChange={updateCountryHandler}
                isLoading={!!(loading['country'])}
            />
            <InfoItem
                name={t('team')}
                onChange={updateCountryHandler}
                // isLoading={isLoading}
            >
                <div className={'projects__info-team-items'}>
                    <MembersBlock
                        members={members}
                        className={'projects__workSpace-members'}
                    />
                    <Button onClick={()=>setIsOpenModal(true)}>{t('open-access').toString()}</Button>
                </div>
            </InfoItem>
            <ShareProject isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal}/>
        </div>
    )
}

export default LoggingBaseInfo
