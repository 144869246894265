import React from 'react'

export const TasksIcon = ({ isCurrentPage }) => {
    return (
        <svg
            width="40"
            style={{ cursor: 'pointer' }}
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                style={{ fill: isCurrentPage ? '#E9EFF3' : '#364F6B' }}
                strokeWidth="2"
                d="M0 10C0 4.47715 4.47715 0 10 0H30C35.5228 0 40 4.47715 40 10V30C40 35.5228 35.5228 40 30 40H10C4.47715 40 0 35.5228 0 30V10Z"
            />
            <rect
                style={{ stroke: isCurrentPage ? '#364F6B' : '#94D7DB' }}
                x="6"
                y="6"
                width="29"
                height="6"
                rx="3"
                strokeWidth="2"
            />
            <rect
                style={{ stroke: isCurrentPage ? '#364F6B' : '#94D7DB' }}
                x="18"
                y="17"
                width="17"
                height="6"
                rx="3"
                strokeWidth="2"
            />
            <path
                style={{ stroke: isCurrentPage ? '#364F6B' : '#94D7DB' }}
                d="M9 31C9 29.3431 10.3431 28 12 28H23C24.6569 28 26 29.3431 26 31C26 32.6569 24.6569 34 23 34H12C10.3431 34 9 32.6569 9 31Z"
                strokeWidth="2"
            />
        </svg>
    )
}
