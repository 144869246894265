import React, { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ISelectOption } from '../../../../app/types/common'
import { ITask, ITasksFilterParams } from '../../../../app/types/models/tasks'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { ResetDataIcon } from '../../../../image_files/icons/ResetDataIcon'
import { selectCurrentProject } from '../../../../store/currentProject/selectors'
import { tasksSelectors } from '../../../../store/tasks'
import { initialFilterData } from '../../data'
import { IDetalizationData } from '../../types'
import RoadMapFilter from '../roadMapFilter/RoadMapFilter'
import RoadmapDetalizationFilter from '../roadmapDetalizationFilter/RoadmapDetalizationFIlter'

const RoadMapTop = ({
    tagsOptions,
    filterParams,
    setFilterParams,
    detalizationData,
    setDetalizationData,
}: {
    tagsOptions:ISelectOption[],
    filterParams: ITasksFilterParams
    detalizationData: IDetalizationData
    setFilterParams: (data: SetStateAction<ITasksFilterParams>) => void
    setDetalizationData: (data: SetStateAction<IDetalizationData>) => void
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const tasksData = useSelector(tasksSelectors.selectTaskData)
    const currentProject = useSelector(selectCurrentProject)

    const getIsDisabledFilterFieldsStatus = () => {
        if (
            Object.keys(filterParams).length === 1 &&
            filterParams.ordering !== undefined
        ) {
            return tasksData.results.length === 0
        } else {
            return false
        }
    }



    return (
        <div className="roadMap-top">
            <div className="roadMap-top-item">
                <div className="roadMap-top-icons">
                    <ResetDataIcon
                        className="cursor-pointer"
                        onClick={() => setFilterParams(initialFilterData)}
                    />
                </div>
                <RoadMapFilter
                    params={filterParams}
                    setParams={setFilterParams}
                    tagsOptions={tagsOptions}
                    isDisabled={getIsDisabledFilterFieldsStatus()}
                />
            </div>
            <RoadmapDetalizationFilter
                data={detalizationData}
                tasks={tasksData.results}
                onChange={(data: IDetalizationData) => {
                    setDetalizationData(data)
                }}
            />
        </div>
    )
}

export default RoadMapTop
