import { ACCESS_TOKEN } from '../utils/constants'
import { AxiosResponse } from 'axios'
import { MeasurementGroupResponse } from '../app/types/response'
import {instance} from "../app/api";

export const thermalService = {
    getThermalGroup: async (projectId: number, coreId: number) => {
        const { data } = await instance.get<
            any,
            AxiosResponse<MeasurementGroupResponse>
        >(
            `projects/${projectId}/wells/core/${coreId}/measurements/thermal-group/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createThermalGroup: async (projectId: number, coreId: number, bodyData) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/thermal-group/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    getConductivity: async (
        projectId: number,
        coreId: number,
        groupId: number,
    ) => {
        const { data } = await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/thermal-group/${groupId}/conductivity/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createConductivity: async (
        projectId: number,
        coreId: number,
        groupId: number,
        bodyData: { core: number },
    ) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/thermal-group/${groupId}/conductivity/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    updateConductivity: async (
        projectId,
        coreId,
        groupId,
        moduleId,
        bodyData,
    ) => {
        const { data } = await instance.patch(
            `projects/${projectId}/wells/core/${coreId}/measurements/thermal-group/${groupId}/conductivity/${moduleId}/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    deleteConductivity: async (projectId, coreId, groupId, moduleId) => {
        const { data } = await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/thermal-group/${groupId}/conductivity/${moduleId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    getDiffusivity: async (projectId, coreId, groupId) => {
        const { data } = await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/thermal-group/${groupId}/diffusivity/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createDiffusivity: async (projectId, coreId, groupId, bodyData) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/thermal-group/${groupId}/diffusivity/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    updateDiffusivity: async (
        projectId,
        coreId,
        groupId,
        moduleId,
        bodyData,
    ) => {
        const { data } = await instance.patch(
            `projects/${projectId}/wells/core/${coreId}/measurements/thermal-group/${groupId}/diffusivity/${moduleId}/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    deleteDiffusivity: async (projectId, coreId, groupId, moduleId) => {
        const { data } = await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/thermal-group/${groupId}/diffusivity/${moduleId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    getCapacity: async (projectId, coreId, groupId) => {
        const { data } = await instance.get(
            `projects/${projectId}/wells/core/${coreId}/measurements/thermal-group/${groupId}/volumetric-heat-capacity/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    createCapacity: async (projectId, coreId, groupId, bodyData) => {
        const { data } = await instance.post(
            `projects/${projectId}/wells/core/${coreId}/measurements/thermal-group/${groupId}/volumetric-heat-capacity/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    updateCapacity: async (projectId, coreId, groupId, moduleId, bodyData) => {
        const { data } = await instance.patch(
            `projects/${projectId}/wells/core/${coreId}/measurements/thermal-group/${groupId}/volumetric-heat-capacity/${moduleId}/`,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },

    deleteCapacity: async (projectId, coreId, groupId, moduleId) => {
        const { data } = await instance.delete(
            `projects/${projectId}/wells/core/${coreId}/measurements/thermal-group/${groupId}/volumetric-heat-capacity/${moduleId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return data
    },
}
