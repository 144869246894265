import React, {FC} from 'react';
import {Draggable, Droppable} from "react-beautiful-dnd";
import {getItemStyle, getListStyle} from "./add-parameter-zone/valueRangeBlock/data";
import {XIcon} from "../../../image_files/icons/XIcon";
import {ICustomGroupParameter} from "../types";
import {useTranslation} from "react-i18next";
import {IMadeParametersListProps} from "./add-parameter-zone/types";

const MadeParametersList: FC<IMadeParametersListProps> = (props) => {
    const {
        activeParameterGroup,
        parameters,
        activeParameter,
        setActiveParameter,
        deleteMadeParameter,
        droppableId,
        type
    } = props
    const {t} = useTranslation()

    const deleteMadeParameterHandler = (e, item) => {
        deleteMadeParameter(item.id)
    }

    //класс для элемента параметра
    const getValidClassForMadeParameterItem = (item: ICustomGroupParameter) => {
        let initialClass = 'custom-group-add-group-item' + ' ' + `${item?.isValidField ? '' : 'custom-group-add-group-item--error'}`;
        if (item?.id === activeParameter?.id) {
            return `${initialClass} custom-group-add-group-item--active`
        } else {
            return initialClass
        }
    }

    const parametersList = activeParameterGroup && Object.keys(activeParameterGroup).length < 0
    && activeParameterGroup.hasOwnProperty('parameters')
        ? activeParameterGroup.parameters : parameters

    const showMadeParametersList = () => {
        const nodeList = (
            parametersList
                .map((item, index) => (
                    <Draggable
                        draggableId={item?.id?.toString()}
                        index={index}
                        key={item?.id?.toString()}
                        isDragDisabled={true}
                    >
                        {(provided, snapshot) => {
                            return (
                                <div className={getValidClassForMadeParameterItem(item)}
                                     key={item?.id}
                                     id={item?.id}
                                     data-group={item?.type}
                                     ref={provided.innerRef}
                                     {...provided.draggableProps}
                                     {...provided.dragHandleProps}
                                     onClick={() => setActiveParameter(item)}
                                     style={getItemStyle(
                                         snapshot.isDragging,
                                         provided.draggableProps.style,
                                     )}
                                >
                                    {/*<div>{item?.id.toString().slice(0, 3)}</div>*/}
                                    <XIcon width={13} height={13}
                                           className={'custom-group-add-group-item-cancel-icon'}
                                           onClick={(e) => {
                                               e.stopPropagation()
                                               deleteMadeParameterHandler(e, item)
                                           }}/>
                                </div>
                            )
                        }
                        }

                    </Draggable>
                ))
        )
        return (
            <Droppable
                key={droppableId}
                type={type}
                droppableId={droppableId}
                direction={'horizontal'}
            >
                {
                    (provided, snapshot) => (
                        <div className={`${parametersList.length > 0
                            ? 'custom-group-add-group-input-field'
                            : 'custom-group-add-group-text-field'}`}
                             {...provided.droppableProps}
                             ref={provided.innerRef}
                             style={getListStyle(
                                 snapshot.isDraggingOver,
                             )}
                        >
                            {parametersList.length > 0
                                ? nodeList
                                : <div className={'font-14-normal'}>
                                    {t('prepare-input-element-bellow-and-drag-it-here').toString()}
                                </div>}
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        )
    }

    return (
        <>
            {showMadeParametersList()}
        </>
    );
};

export default MadeParametersList;