import React, {FC} from 'react';
//@ts-ignore
import s from './dataQualityTable.module.scss'
import {DownloadIcon} from "../../../../../../image_files/icons/downloadIcon";
import {AgreeAcceptIcon} from "../../../../../../image_files/icons/AgreeAcceptIcon";
import {ErrorIcon} from "../../../../../../image_files/icons/ErrorIcon";
import {UnAcceptIcon} from "../../../../../../image_files/icons/UnAcceptIcon";
import {IDataAssessmentDetail} from "../../../../../../app/types/models/well-log-file";
import DevIcon from "../../../../../../image_files/icons/DevIcon";
import {useTranslation} from "react-i18next";

enum AcceptanceTypes {
    accept = 'accept',
    reject = '"reject"',
    inDev = 'in_development',
    error = 'Error',
}

interface IDataQualityTable {
    data:IDataAssessmentDetail
}


const DataQualityTable:FC<IDataQualityTable> = ({data}) => {
    const {t,i18n} = useTranslation()

    const prepareDataToRender = ()=>{
        const criterionFields = Object.keys(data)
        const dataFields = Object.values(data)
        return [...Array(criterionFields.length)].map((_item,i)=>{
            console.log(dataFields[i][0])
            return {
                count:i+1,
                criterion:i18n.language==='en' ? dataFields[i][1] : dataFields[i][2],
                description:i18n.language==='en' ? dataFields[i][3] : dataFields[i][4],
                acceptance:dataFields[i][0]
            }
        })
    }


    return (
        <div className={s.block}>
            <div className={s.top}>
                <h3>{t('data-quality').toString()}</h3>
                <button>
                    <DownloadIcon/>
                </button>
            </div>
            <div className={s.table}>
                <TableRow count={'#'} criterion={t('criterions')} description={t('description')} acceptance={t('acceptance')}/>
                {prepareDataToRender().map((item,i)=>
                    <TableRow {...item} key={i}/>)}
            </div>
        </div>
    );
};

export default DataQualityTable;


interface ITableRowProps {
    count:number | string
    criterion:string
    description:string
    acceptance:AcceptanceTypes | string
}

const TableRow:FC<ITableRowProps> = (
    {
        count,
        criterion,
        description,
        acceptance
    }
)=>{
    const {t} = useTranslation()
    const showCorrectIcon = ()=>{
        switch (acceptance){
            case 'accept':
                return <AgreeAcceptIcon/>
            case 'reject':
                return <UnAcceptIcon/>
            case 'Error':
                return <ErrorIcon/>
            case 'in_development':
            return <DevIcon/>
            default:
                return t('acceptance').toString()
        }
    }

    return (
        <div className={s.row}>
            <div>{count}</div>
            <div>{criterion}</div>
            <div>{description}</div>
            <div>{showCorrectIcon()}</div>
        </div>
    )
}