import React, { ComponentProps, FC } from 'react'
import { IIconProps } from '../../app/types/i-props'


export const DownloadIcon:FC<IIconProps> = ({fill='#364F6B',stroke='#364F6B',className,onClick}) => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            fill='transparent'
            onClick={onClick}
        >
            <rect width="25" height="25" fill="#E9EFF3" />
            <rect width="25" height="25" fill="white" />
            <path
                d="M5.42857 15V15C5.19804 17.6895 7.31896 20 10.0183 20H15C17.7614 20 20 17.7614 20 15V15"
                stroke={stroke}
                strokeWidth="1.2"
                strokeLinecap="round"
            />
            <path
                d="M11.9 5.5C11.9 5.16863 12.1686 4.9 12.5 4.9C12.8314 4.9 13.1 5.16863 13.1 5.5H11.9ZM12.9243 16.9243C12.6899 17.1586 12.3101 17.1586 12.0757 16.9243L8.25736 13.1059C8.02304 12.8716 8.02304 12.4917 8.25736 12.2574C8.49167 12.023 8.87157 12.023 9.10589 12.2574L12.5 15.6515L15.8941 12.2574C16.1284 12.023 16.5083 12.023 16.7426 12.2574C16.977 12.4917 16.977 12.8716 16.7426 13.1059L12.9243 16.9243ZM13.1 5.5L13.1 16.5H11.9L11.9 5.5H13.1Z"
                fill={fill}
            />
        </svg>
    )
}
