import {AxiosResponse} from 'axios'
import {ICorePhotoResponse, ProjectResponse} from '../app/types/response'
import {IProject} from '../app/types/models/project'
import {ICore, IPad, IResearchEntity} from '../app/types/models/logging'
import {AddProjectRequest, ICorePhotoRequest, ResearchEntityRequest,} from '../app/types/request'
import {ACCESS_TOKEN} from '../utils/constants'
import {instance} from "../app/api";

export const projectsService = {
    getProjects: async () => {
        return await instance
            .get<any, AxiosResponse<IProject[]>>('projects/project/', {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
    addNewProject: async (data: AddProjectRequest) => {
        return await instance
            .post<{ data: AddProjectRequest }, AxiosResponse<IProject>>(
                'projects/project/',
                data,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    getCurrentProject: async (id: number) => {
        return await instance
            .get<{ id: number }, AxiosResponse<IProject>>(
                'projects/project/' + id + '/',
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    updateProject: async (id: number, data: IProject) => {
        return await instance
            .patch<
                { id: number; data: IProject },
                AxiosResponse<ProjectResponse>
            >(`projects/project/${id}/`, data, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
    removeProject: async (id: number) => {
        return await instance.delete(`projects/project/${id}/`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        })
    },
    getPads: async (projectId: number) => {
        return await instance
            .get<{ projectId: number }, AxiosResponse<IResearchEntity[]>>(
                `projects/${projectId}/wells/pad/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    getCores: async (projectId: number) => {
        return await instance
            .get<{ projectId: number }, AxiosResponse<ICore[]>>(
                `projects/${projectId}/wells/core/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    addPad: async (projectId: number, data: ResearchEntityRequest) => {
        return await instance
            .post<
                { projectId: number; data: ResearchEntityRequest },
                AxiosResponse<IPad>
            >(`projects/${projectId}/wells/pad/`, data, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
    updatePad: async (
        projectId: number,
        padId: number,
        data: ResearchEntityRequest,
    ) => {
        return await instance
            .put<
                {
                    projectId: number
                    padId: number
                    data: ResearchEntityRequest
                },
                AxiosResponse<IResearchEntity>
            >(`projects/${projectId}/wells/pad/${padId}/`, data, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
    deletePad: async (projectId: number, padId: number) => {
        return await instance.delete(
            `projects/${projectId}/wells/pad/${padId}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    updateCore: async (projectId: number, coreId: number, data: ICore) => {
        return await instance.put(
            `projects/${projectId}/wells/core/${coreId}/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },

    deleteCore: async (projectId: number, coreId: number) => {
        return await instance.delete(
            `projects/${projectId}/wells/core/${coreId}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },
    addCore: async (id: number, data: ICore) => {
        return await instance
            .post<{ id: number; data: ICore }, AxiosResponse<ICore>>(
                `projects/${id}/wells/core/`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    addCorePhoto: async (id: number, data: FormData) => {
        return await instance.post<
                { id: number; data: ICorePhotoRequest },
                AxiosResponse<ICorePhotoResponse>
            >(`projects/${id}/wells/core-photo/`, data)
            .then((res) => res.data)
    },
    deleteCorePhoto: async (id: number, coreId: number) => {
        return await instance.delete(
            `projects/${id}/wells/core-photo/${coreId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },
}
