import React, { FC } from 'react';
import './datePicker.css';
import { IDatePickerInlogProps } from './types';
import { ConfigProvider, DatePicker } from 'antd';
import ruRU from 'antd/es/locale/ru_RU'
import enEN from 'antd/es/locale/en_US'
import ru_RU from 'antd/es/date-picker/locale/ru_RU'
import en_EN from 'antd/es/date-picker/locale/en_US'
import { useTranslation } from 'react-i18next';

const DatePickerInlog: FC<IDatePickerInlogProps> = (props) => {
  const { i18n, t } = useTranslation()
  return (
    <ConfigProvider locale={i18n.language === 'ru' ? ruRU : enEN}>
      <div className='date-picker-wrapper'>
        <DatePicker {...props} locale={i18n.language === 'ru' ? ru_RU : en_EN}/>
      </div>
    </ConfigProvider>
  )
}

export default DatePickerInlog