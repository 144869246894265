export const getTypeData = (t:Function)=>{
    return [
        {
            name: t('vertical'),
            value: 'vertical',
        },
        {
            name: t('obliquely-directed'),
            value: 'obliquely_directed',
        },
        {
            name: t('horizontal'),
            value: 'horizontal',
        },
        {
            name: t('multilateral'),
            value: 'multilateral',
        },
    ]
}