import { FC, ReactElement, useState } from 'react'
import './projectAccordion.css'
import React from 'react'
import {AccordionArrow} from "../../image_files/icons/AccordionArrow";

interface IProps {
    title?: string
    children: ReactElement | ReactElement[]
    topChildren?: ReactElement | ReactElement[]
}

const ProjectAccordion: FC<IProps> = ({ title, topChildren, children }) => {
    const [collapsed, setCollapsed] = useState(true)

    return (
        <div className={'projectsAccordion'}>
            <div className="top">
                <div className={'top__content'}>
                    {title ? title : topChildren}
                </div>
                <div className={`top__arrow` } onClick={() => setCollapsed(!collapsed)}>
                    <AccordionArrow
                        style={collapsed ? { transform: 'rotate(180deg)' } : {}}
                        className={'scheduler-accordion__arrowIcon'}
                    />
                </div>
            </div>
            <div
                className={`bottom ${collapsed ? 'bottom-collapsed' : ''}`}
            >
                <div
                    className={`bottomContainer transition2 ${collapsed ? 'bottomContainer-collapsed' : ''}`}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export { ProjectAccordion }
