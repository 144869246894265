import React from 'react'


export const OpenIcon = () => {
    return (
        <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.49983 1.66609L6.86921 1.66609C4.10779 1.66609 1.86921 3.90467 1.86921 6.66609L1.86921 11.2407C1.86921 14.0021 4.10779 16.2407 6.86921 16.2407L10.9403 16.2407C13.7018 16.2407 15.9404 14.0021 15.9403 11.2406L15.9403 9.94986" stroke="black" strokeOpacity="0.62" strokeLinecap="round"/>
            <path d="M6.6462 11.0957C6.45094 11.2909 6.45094 11.6075 6.6462 11.8028C6.84146 11.998 7.15805 11.998 7.35331 11.8028L6.6462 11.0957ZM16.9998 1.94922C16.9998 1.67308 16.7759 1.44922 16.4998 1.44922L11.9998 1.44922C11.7236 1.44922 11.4998 1.67308 11.4998 1.94922C11.4998 2.22536 11.7236 2.44922 11.9998 2.44922L15.9998 2.44922L15.9998 6.44922C15.9998 6.72536 16.2236 6.94922 16.4998 6.94922C16.7759 6.94922 16.9998 6.72536 16.9998 6.44922L16.9998 1.94922ZM7.35331 11.8028L16.8533 2.30277L16.1462 1.59567L6.6462 11.0957L7.35331 11.8028Z" fill="black" fillOpacity="0.62"/>
        </svg>

    )
}
