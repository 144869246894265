import React from 'react';
import './progreeBlock.css';

interface IProps{
    value:string
    text:string
}

const ProgressBlock = ({value,text}:IProps) => {
    return (
        <div className={'progressBlock'}>
            <div className={'progressBlock__percent'} style={{width:`${value}%`}}></div>
            <span>{text}</span>
        </div>
    );
};

export default ProgressBlock;