import { Input, InputProps } from 'antd'
import React from 'react'
import { getValidText } from '../../../utils/helpers/getValidText';
import './UiInput.css'

type IProps = InputProps & {
    label?: string
}

const UiInput = (props: IProps) => {
    return (
        <div className="field-wrap">
            {props?.label && (
                <div className="font-14-normal field-wrap-label">{props?.label}</div>
            )}

            <Input
                {...props}
                className={`ui-input ${getValidText(props?.className)}`}
            />
        </div>
    )
}

export default UiInput;
