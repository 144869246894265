import { Button, Col, Form } from 'antd'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IValidateStatementFileResponse } from '../../../../app/types/dto/validate-file-dto'
import {
    IWell,
    IWellBoreInfo,
    IWellInfo,
} from '../../../../app/types/models/logging'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { createFromFileService } from '../../../../services/create-from-file-service'
import { projectsService } from '../../../../services/projects-service.'
import { wellBoreService } from '../../../../services/wellBore-service'
import { wellsService } from '../../../../services/wells-service'
import { FileAttach } from '../../../../shared/FileAttach/FileAttach'
import AddonsInput from '../../../../shared/addonsInput/AddonsInput'
import DownloadIconButton from '../../../../shared/downloadIconButton/DownloadIconButton'
import FormItem from '../../../../shared/formItem/FormItem'
import ModalInlog from '../../../../shared/modalInlog/ModalInlog'
import SpinInLog from '../../../../shared/spin/spin'
import { currentProjectSelectors } from '../../../../store/currentProject'
import {
    selectCurrentWell,
    setCurrentPads,
    setCurrentWells,
    setLoadingCurrentItemStatus,
} from '../../../../store/currentProject/actions'
import { selectCurrentPads } from '../../../../store/currentProject/selectors'
import { setPads, setWells } from '../../../../store/projects/actions'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { showNotice } from '../../../../utils/helpers/showNotice'
import { addonsSelectOptions } from '../../helpers/getAddonsSelectOptions'
import { getKindData } from '../../helpers/getKindData'
import { getTypeData } from '../../helpers/getTypeData'
import './wellInfo.scss'

interface IProps {
    currentWellId: number | null
    currentWellBoreId: number | null
}

const WellInfo: FC<IProps> = () => {
    const [form] = Form.useForm()
    const currentProject = useSelector(
        currentProjectSelectors.selectCurrentProject,
    )
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    const [isChangedField, setIsChangedField] = useState(false)
    const [isLoadingFile, setIsLoadingFile] = useState(false)
    const currentPad = useSelector(currentProjectSelectors.selectCurrentPad)
    const currentWell = useSelector(currentProjectSelectors.selectCurrentWell)
    const currentWellbore = useSelector(
        currentProjectSelectors.selectCurrentWellBore,
    )

    const currentPads = useSelector(selectCurrentPads)
    const [openModal, setOpenModal] = useState(false)
    const [isLoadingValidateFile, setIsLoadingValidateFile] = useState(false)
    const [modalData, setModalData] = useState<string[]>([])
    const [fileData, setFileData] = useState<any>(null)
    const [fetchedWells, setFetchedWells] = useState<IWell[]>([])

    const fetchEntityInfo = useCallback(async () => {
        try {
            setLoading(true)
            form.resetFields()
            let response
            if (currentWell?.id) {
                dispatch(
                    setLoadingCurrentItemStatus({
                        status: true,
                        type: 'currentWells',
                    }),
                )
                response = await wellsService.getWellsInfo(
                    currentProject?.id,
                    currentWell?.id,
                )
            } else if (currentWellbore?.id) {
                response = await wellBoreService.getWellBoreInfo(
                    currentProject?.id,
                    currentWellbore?.id,
                )
            }
            form.setFieldsValue(response)
            setLoading(false)
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentWells',
                }),
            )
        } catch (e) {
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentWells',
                }),
            )
            console.log(e)
            errorsHandler(e, t)
            setLoading(false)
        }
    }, [currentWell, currentWellbore])

    const onChangeInputValue = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        name?: string,
    ) => {
        if (name) {
            form.setFieldValue(name, `${e.target.value}`)
        }
        setIsChangedField(true)
    }

    const onChangeSelectValue = (e: string, name?: string) => {
        if (name) {
            form.setFieldValue(name, e)
            form.submit()
        }
        setIsChangedField(true)
    }

    const onChangeSetFieldValue = (
        data: { type: string; value: string },
        name: string,
    ) => {
        if (name === 'latitude') {
            form.setFieldValue(name, data.value)
            form.setFieldValue('latitude_direction', data.type)
        } else if (name === 'longitude') {
            form.setFieldValue(name, data.value)
            form.setFieldValue('longitude_direction', data.type)
        } else if (name === 'hole_depth') {
        }
        setIsChangedField(true)
        // form.submit()
    }

    const customRequest = async (options) => {
        const { onSuccess, onError, file } = options
        const fmData = new FormData()
        fmData.append('file', file)
        setFileData(file)
        try {
            setIsLoadingFile(true)
            const responseValidateData = await wellsService.validateWellFile(
                currentProject?.id,
                fmData,
            )
            checkCores(responseValidateData, file)
        } catch (err) {
            onError({ err })
            setIsLoadingFile(false)
            errorsHandler(err, t)
        }
    }

    const updateWellFromFile = async (file: File) => {
        try {
            const fmData = new FormData()
            fmData.append('file', file)

            await createFromFileService.createWellFile(
                currentProject?.id,
                fmData,
            )

            const padsRes = await projectsService.getPads(currentProject?.id)
            await fetchWells()
            dispatch(setPads({ id: currentProject?.id, pads: padsRes }))
            dispatch(
                setCurrentPads({
                    pads: padsRes,
                }),
            )

            setIsLoadingFile(false)
            setOpenModal(false)
        } catch (error) {
            errorsHandler(error, t)
            setIsLoadingValidateFile(false)
        }
    }

    const checkCores = (data: IValidateStatementFileResponse[], file: File) => {
        if (data && Array.isArray(data)) {
            const overlaps = []

            if (currentPads && currentPads.length > 0 && data?.length > 0) {
                let targetOverlapedWells = []
                currentPads.forEach((pad) => {
                    const targetPad = data.find(
                        (el) =>
                            el.well_pad !== null &&
                            Number(el?.well_pad?.id) === Number(pad?.id),
                    )

                    if (targetPad) {
                        if (fetchedWells.length > 0) {
                            const targetWell = fetchedWells?.find(
                                (val) => val?.name === targetPad?.name,
                            )
                            if (targetWell) {
                                const str = t('core-name-in-pad-name', {
                                    pad: pad?.name,
                                    well: targetWell?.name,
                                })
                                overlaps.push(str)
                                targetOverlapedWells.push(targetWell)
                            }
                        }
                    }
                })
                if (overlaps.length > 0) {
                    setModalData(overlaps)
                    setOpenModal(true)
                } else {
                    updateWellFromFile(file)
                }
            }
        }
    }

    const onFormFinish = useCallback(
        async (values) => {
            const preparedData: IWellBoreInfo | IWellInfo = {
                ...values,
                latitude: form.getFieldValue('latitude'),
                latitude_direction: form.getFieldValue('latitude_direction'),
                longitude: form.getFieldValue('longitude'),
                longitude_direction: form.getFieldValue('longitude_direction'),
            }
            try {
                if (currentWell?.id) {
                    await wellsService.updateWell(
                        currentProject?.id,
                        currentWell?.id,
                        {
                            ...preparedData,
                            name: currentWell?.name,
                        },
                    )
                    showNotice(t('data-saved-successfully'), t)
                } else if (currentWellbore?.id) {
                    await wellBoreService.updateWellBore(
                        currentProject?.id,
                        currentWellbore?.id,
                        {
                            ...preparedData,
                            name: currentWellbore?.name,
                            well: currentWellbore?.well,
                            wellbore: currentWellbore?.id,
                        },
                    )
                    showNotice(t('data-saved-successfully'), t)
                }
            } catch (e) {
                errorsHandler(e, t)
            }
        },
        [form, currentWell, currentWellbore],
    )

    useEffect(() => {
        fetchEntityInfo()
        form.resetFields()
        if (currentWell) {
            form.setFieldValue(
                'latitude_direction',
                currentWell?.latitude_direction,
            )
        }
        if (currentWellbore) {
            form.setFieldValue(
                'latitude_direction',
                currentWellbore?.latitude_direction,
            )
        }
    }, [currentWell, currentWellbore])

    const fetchWells = async () => {
        try {
            const wellsRes = await wellsService.getWells(currentProject?.id)
            dispatch(
                setWells({
                    id: currentProject?.id,
                    padId: currentPad?.id,
                    wells: wellsRes,
                }),
            )
            dispatch(setCurrentWells({ wells: wellsRes }))
            if (wellsRes.length > 0) {
                dispatch(selectCurrentWell(wellsRes[0]))
            } else {
                dispatch(selectCurrentWell(null))
            }

            setFetchedWells(wellsRes)
        } catch (error) {
            console.log(error)
            errorsHandler(error, t)
        }
    }

    useEffect(() => {
        fetchWells()
    }, [])

    return (
        <div className={'pos-relative'}>
            {createPortal(
                <SpinInLog isLoading={isLoadingValidateFile} />,
                document.body,
            )}
            <ModalInlog
                open={openModal}
                onCancel={() => setOpenModal(false)}
                footer={null}
            >
                <div className="core-form-question-modal-text">
                    <div>
                        {modalData?.map((el, i) => (
                            <div key={i}>{el}</div>
                        ))}
                    </div>
                    <div>{t('already-added-project-base')}</div>
                </div>
                <div className="core-form-question-modal-bottom">
                    <Button type="primary" onClick={() => setOpenModal(false)}>
                        {t('cancel-loading')}
                    </Button>
                    <Button
                        type="primary"
                        onClick={async () => {
                            await updateWellFromFile(fileData)
                        }}
                    >
                        {t('continue-loading-with-updating-info-about-wells')}
                    </Button>
                </div>
            </ModalInlog>
            <div className="well-info-upload-block">
                <FileAttach
                    files={null}
                    title={t('select-well-info-and-transfer-here')}
                    className={'logs__upload'}
                    accept={'.xlsx, .las'}
                    listType={'text'}
                    customRequest={customRequest}
                    onSelect={() => {}}
                />
                <DownloadIconButton
                    href={'/files/шаблон_инфо_скважины.xlsx'}
                    description={t(
                        'download-template',
                    ).toString()}
                />
            </div>

            <Form
                form={form}
                layout="horizontal"
                onFinish={onFormFinish}
                className={'coreInfo__form'}
            >
                <FormItem
                    label={t(
                        'longitude',
                    )}
                    formItemClassName={'wellInfo__formItem'}
                    type={'custom'}
                    colProps={{ className: 'wellInfo__inputWrap' }}
                    loading={loading}
                >
                    <AddonsInput
                        options={addonsSelectOptions(t).slice(2, 4)}
                        defaultOption={form.getFieldValue(
                            'longitude_direction',
                        )}
                        value={form.getFieldValue('longitude')}
                        onChange={(data) => {
                            onChangeSetFieldValue(data, 'longitude')
                        }}
                    />
                </FormItem>
                <FormItem
                    label={t('latitude', t)}
                    type={'custom'}
                    formItemClassName={'wellInfo__formItem'}
                    colProps={{ className: 'wellInfo__inputWrap' }}
                    loading={loading}
                >
                    <AddonsInput
                        options={addonsSelectOptions(t).slice(0, 2)}
                        defaultOption={form.getFieldValue('latitude_direction')}
                        value={form.getFieldValue('latitude')}
                        onChange={(data) =>
                            onChangeSetFieldValue(data, 'latitude')
                        }
                    />
                </FormItem>
                <FormItem
                    label={t('rocks-age-the-bottom')}
                    onChange={onChangeInputValue}
                    formItemClassName={'wellInfo__formItem'}
                    name={'bottom_rock_age'}
                    colProps={{ className: 'wellInfo__inputWrap' }}
                    loading={loading}
                />
                <FormItem
                    label={t(
                        'vertical-bottom-hole-depth',
                        
                    )}
                    onChange={onChangeInputValue}
                    name={'vertical_bottom_hole_depth'}
                    formItemClassName={'wellInfo__formItem'}
                    colProps={{ className: 'wellInfo__inputWrap' }}
                    loading={loading}
                />
                <FormItem
                    label={t('actual-bottom-hole-depth')}
                    onChange={onChangeInputValue}
                    formItemClassName={'wellInfo__formItem'}
                    name={'actual_bottom_hole_depth'}
                    colProps={{ className: 'wellInfo__inputWrap' }}
                    loading={loading}
                />
                <FormItem
                    label={t('square')}
                    onChange={onChangeInputValue}
                    name={'area'}
                    formItemClassName={'wellInfo__formItem'}
                    colProps={{ className: 'wellInfo__inputWrap' }}
                    loading={loading}
                />
                <FormItem
                    label={t('well-type')}
                    onSelectChange={onChangeSelectValue}
                    name={'type'}
                    type={'select'}
                    options={getTypeData(t)}
                    formItemClassName={'wellInfo__formItem'}
                    colProps={{ className: 'wellInfo__inputWrap' }}
                    loading={loading}
                />
                <FormItem
                    label={t('well-sort')}
                    onSelectChange={onChangeSelectValue}
                    name={'kind'}
                    type={'select'}
                    options={getKindData(t)}
                    formItemClassName={'wellInfo__formItem'}
                    colProps={{ className: 'wellInfo__inputWrap' }}
                    loading={loading}
                />
                <FormItem
                    label={t('admin-unit')}
                    onChange={onChangeInputValue}
                    name={'administrative_unit'}
                    formItemClassName={'wellInfo__formItem'}
                    colProps={{ className: 'wellInfo__inputWrap' }}
                    loading={loading}
                />
                <FormItem
                    label={t('oil-province')}
                    onChange={onChangeInputValue}
                    name={'oil_and_gas_province'}
                    formItemClassName={'wellInfo__formItem'}
                    colProps={{ className: 'wellInfo__inputWrap' }}
                    loading={loading}
                />
                <FormItem
                    label={t('tectonic-elem')}
                    onChange={onChangeInputValue}
                    name={'tectonic_element'}
                    formItemClassName={'wellInfo__formItem'}
                    colProps={{ className: 'wellInfo__inputWrap' }}
                    loading={loading}
                />
                <FormItem
                    label={t('poleontological-region')}
                    onChange={onChangeInputValue}
                    name={'paleontological_region'}
                    formItemClassName={'wellInfo__formItem'}
                    colProps={{ className: 'wellInfo__inputWrap' }}
                    loading={loading}
                />
                <Col span={24}>
                    <div></div>
                    <Button
                        type="primary"
                        size="large"
                        className={'sendBtn'}
                        disabled={!isChangedField}
                        onClick={() => form.submit()}
                    >
                        {t('save')}
                    </Button>
                </Col>
            </Form>
        </div>
    )
}

export default WellInfo
