import {INotification} from "../../app/types/models/i-notification";

export interface  INoticeBlockProps{
    wsNotifications:INotification[]
    refetchNotifications?:()=>Promise<void>
}

export enum NoticeTypes{
    ProjectUserInvitation="project_user_invitation",
    RoleRequest = "role_request",
    RoleRequestResponse = "role_request_response",
    ProjectUserInvitationResponse ="project_user_invitation_response",
    ProjectUserRemoval = "project_user_removal",
    RoleChangeByAdmin="role_change",
    GeneralTaskUpdate="general_task_update",
    AdminRightsTransfer="admin_rights_transfer",
    ProjectLeaving="project_leaving"
}