import React, { FC, useEffect, useState } from 'react'
import { PlusIcon } from '../../../../../../../../image_files/icons/PlusIcon'
import { QuestionIcon } from '../../../../../../../../image_files/icons/QuestionIcon'
import './mnemMatching.css'
import { MnemTableItem } from '../../../mnemTableItem/MnemTableItem'
import { MnemTopItem } from '../../../mnemTopItem/MnemTopItem'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    selectCurrentMnemonics,
    selectWellLogFiles,
} from '../../../../../../../../store/currentProject/selectors'
import { IMnemonic } from '../../../../../../../../app/types/models/IMnemonic'
import { mocMnemonic } from '../../../../../../../../utils/moc/mocMnemonic'
import { LanguageTypes } from '../../../../../../../../app/types/enums'
import PopoverInLog from '../../../../../../../../shared/popoverInlog/PopoverInLog'

interface IMnemMatchingProps {
    updateMnemonics: (value: IMnemonic[]) => void
}

export const MnemMatching: FC<IMnemMatchingProps> = ({ updateMnemonics }) => {
    const [fetchedMnemonics, setFetchedMnemonics] = useState<
        IMnemonic[] | null
    >(null)
    const mnemonics = useSelector(selectCurrentMnemonics)
    const [mnemonicRowStatusData, setMnemonicRowsStatusData] = useState<
        { id: number; status: 'not completed' | 'ready' }[] | null
    >(null)
    const [activeLang, setActiveLang] = useState<LanguageTypes>(
        LanguageTypes.ru,
    )
    const { t } = useTranslation()
    const wellLogFiles = useSelector(selectWellLogFiles)

    const changeRowData = (id: number, data: IMnemonic) => {
        const changedData = fetchedMnemonics.map((item) =>
            item.id === id ? data : item,
        )
        setFetchedMnemonics(changedData)
        updateMnemonics(changedData)
    }

    const setMnemonicsStatus = (
        data: { id: number; status: 'not completed' | 'ready' } | null,
    ) => {
        if (!mnemonicRowStatusData) {
            data && setMnemonicRowsStatusData([data])
        } else {
            if (data !== null) {
                if (
                    mnemonicRowStatusData &&
                    mnemonicRowStatusData?.find((item) => item?.id === data.id)
                ) {
                    const changedData = mnemonicRowStatusData.map((item) =>
                        item && item?.id === data?.id ? data : item,
                    )
                    setMnemonicRowsStatusData(changedData)
                } else {
                    setMnemonicRowsStatusData([...mnemonicRowStatusData, data])
                }
            } else {
                setMnemonicRowsStatusData(null)
            }
        }
    }

    const addTableItem = () => {
        if (fetchedMnemonics) {
            setFetchedMnemonics([
                ...fetchedMnemonics,
                { ...mocMnemonic, id: fetchedMnemonics.at(-1).id + 1 },
            ])
        } else {
            setFetchedMnemonics([mocMnemonic])
        }
    }

    const deleteTableItem = (id: number,isValid:boolean) => {
        const filteredData = fetchedMnemonics.filter((item) => item.id !== id)
        setFetchedMnemonics(filteredData)
        if(isValid){
            updateMnemonics(fetchedMnemonics.filter((item) => item.id !== id))
        }
    }

    const setTextProps = (order: number) => {
        const textList = [
            t('well-log'),
            t('mnemonic'),
            t('unit'),
            t('inlog-description'),
            t('possible-mnemonics'),
            t('constant-mnemonic'),
        ]
        if (order === 0) {
            return {
                topText: textList[order],
            } as { topText: string }
        } else {
            return {
                topText: textList[order].toString().split(' ')[0],
                bottomText: textList[order].toString().split(' ')[1],
            }
        }
    }
    const showPopover = (text: string) => {
        return (
            <PopoverInLog
                content={
                    <div
                        className={
                            'font-12-normal font-white mnemMatching-popover'
                        }
                    >
                        {text}
                    </div>
                }
            >
                <QuestionIcon />
            </PopoverInLog>
        )
    }
    useEffect(() => {
        if (mnemonics) {
            setFetchedMnemonics(mnemonics)
        }
    }, [mnemonics])

    return (
        <div>
            {wellLogFiles &&
            Array.isArray(wellLogFiles) &&
            wellLogFiles?.length > 0 ? (
                <>
                    <div className={'mnem-matching-top'}>
                        <div></div>
                        <MnemTopItem
                            icon={
                                <PlusIcon
                                    className={'cursor-pointer'}
                                    fill={'white'}
                                    onClick={addTableItem}
                                />
                            }
                            {...setTextProps(0)}
                        />
                        <MnemTopItem
                            icon={showPopover(
                                t('value-used-in-unified-db', {
                                    value: t('mnemonic').toLowerCase(),
                                }),
                            )}
                            {...setTextProps(1)}
                        />
                        <MnemTopItem
                            icon={showPopover(
                                t('value-used-in-unified-db', {
                                    value: t('unit').toLowerCase(),
                                }),
                            )}
                            {...setTextProps(2)}
                        />
                        <MnemTopItem
                            icon={showPopover(
                                t('value-used-in-unified-db', {
                                    value: t('description').toLowerCase(),
                                }),
                            )}
                            {...setTextProps(3)}
                        />
                        <MnemTopItem
                            icon={showPopover(
                                t('mnemonics-list-found-in-well-log-files'),
                            )}
                            {...setTextProps(4)}
                        />
                        <MnemTopItem
                            icon={showPopover(
                                t('value-can-be-constant-with-depth'),
                            )}
                            topText={'Constant'}
                            {...setTextProps(5)}
                        />
                    </div>
                    <div className={'additional-row'}>
                        <div className={'mt-10 row-flex-10 font-14-normal'}>
                            <div
                                onClick={() => setActiveLang(LanguageTypes.ru)}
                                className={
                                    activeLang === LanguageTypes.ru
                                        ? `current-lang currentLang_active cursor-pointer`
                                        : 'current-lang cursor-pointer'
                                }
                            >
                                Ру
                            </div>
                            <div
                                onClick={() => setActiveLang(LanguageTypes.en)}
                                className={
                                    activeLang === LanguageTypes.en
                                        ? `current-lang currentLang_active cursor-pointer`
                                        : 'current-lang cursor-pointer'
                                }
                            >
                                En
                            </div>
                        </div>
                        {mnemonicRowStatusData &&
                        mnemonicRowStatusData?.find(
                            (item) => item?.status === 'not completed',
                        ) ? (
                            <div className={'font-error'}>
                                {t('all-mnemonic-fields-must-be-filled')}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>

                    <div>
                        {fetchedMnemonics &&
                            fetchedMnemonics.map((item, i) => (
                                <MnemTableItem
                                    key={item.id}
                                    data={item}
                                    orderIndex={i}
                                    setMnemonicStatus={setMnemonicsStatus}
                                    mnemonics={fetchedMnemonics}
                                    onSave={changeRowData}
                                    onDelete={deleteTableItem}
                                    activeLang={
                                        activeLang === LanguageTypes.ru
                                            ? 'ru'
                                            : 'en'
                                    }
                                />
                            ))}
                    </div>
                </>
            ) : (
                <div className={'font-error'}>
                    {t('all-mnemonic-fields-must-be-filled').toString()}
                </div>
            )}
        </div>
    )
}
