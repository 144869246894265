import React, {FC, useState} from 'react';
import {OpenIcon} from "../../../../../../image_files/icons/OpenIcon";
import SettingCancelIcon from "../../../../../../image_files/icons/SettingCancelIcon";
import './BlockItem.css';
import ProgressBlock from "../progressBlock/ProgressBlock";
import {DownloadIcon} from "../../../../../../image_files/icons/downloadIcon";
import {Modal} from "antd";
import DataQualityTable from "../dataQualityTable/DataQualityTable";
import {formatBytes} from "../../../../../../utils/helpers/formatBytes";
import {IWellLogFile} from "../../../../../../app/types/models/well-log-file";
import {useTranslation} from "react-i18next";
import {getCorrectDateFormat} from "../../../../../../utils/getCorrectDateFormat";

interface ILogsItemProps {
    currentFileId: number | null
    item: IWellLogFile | null

    deleteWellLogFile: (id: number) => void
    setCurrentFile: (file: IWellLogFile) => void
}

const BlockItem: FC<ILogsItemProps> = (
    {
        item,
        currentFileId,

        deleteWellLogFile,
        setCurrentFile
    }
) => {
    const [openModal, setOpenModal] = useState(false)
    const {t} = useTranslation()
    const {assessment_detail_is_being_processed,id,
        data_quality,filename,size,extension,file,data_assessment_detail} = item

    const onClickDeleteFile = () => {
        deleteWellLogFile(id)
    }

    const onClickSetCurrentFile = () => {
        if (item) {
            setCurrentFile(item)
        }
    }
    const showItemQuality = ()=>{
        if(assessment_detail_is_being_processed){
            return t('processing')
        }else{
           return `${data_quality} %`
        }
    }
        return (
        <>
            <li className={'logsItem'} onClick={onClickSetCurrentFile}>
                <span></span>
                <span className={id===currentFileId ?  ' logsItem_span active break-word pr-5 max-w-200' : ' logsItem_span break-word pr-5 max-w-200'} onClick={onClickSetCurrentFile}>
                    {filename ? filename.split('/')?.at(-1) : ''}
                </span>
                <span className={'logsItem_span'}>{formatBytes(size)}</span>
                <span className={'logsItem_span'}>{getCorrectDateFormat({date:item?.created_at,isShowTime:true})}</span>
                <span className={'logsItem_span'}>{extension}</span>
                <div className={'logsItem__quality'}>
                    <ProgressBlock value={data_quality ? data_quality?.toString() : ''} text={showItemQuality().toString()}/>
                    <div className={'logsItem__buttons'}>
                        <button onClick={() => setOpenModal(true)}><OpenIcon/></button>
                        <button onClick={onClickDeleteFile}><SettingCancelIcon/></button>
                        <a href={file}><DownloadIcon/></a>
                    </div>
                </div>
            </li>
            <Modal open={openModal} onCancel={() => setOpenModal(false)} footer={null} className={'dataQualityModal'}
                   width={828}>
                {
                    data_assessment_detail ?
                        <DataQualityTable data={data_assessment_detail}/>
                        : <div>{t('no-data-available').toString()}</div>
                }
            </Modal>
        </>
    );
};

export default BlockItem;