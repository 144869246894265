import { Button, Checkbox, Form, Input, Modal } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import { RegistrationRequest } from '../../app/types/request'
import LetterIcon from '../../image_files/icons/LetterIcon'
import LockIcon from '../../image_files/icons/LockIcon'
import { authService } from '../../services/auth-service'
import { LargeSpin } from '../../shared/LargeSpin'
import SignHeader from '../../shared/signHeader/SignHeader'
import { setPasswordResetData } from '../../store/auth/actions'
import { userSelectors } from '../../store/user'
import '../../styles/commonSign.css'
import { routes } from '../../utils/constants'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { getQueryVariables } from '../../utils/helpers/getQueryVariable'
import { updateFieldError } from '../../utils/updateFieldError'
import { TermsAndPolicy } from './TermsAndPolicy'
import './registration.css'

function Registration() {
    const [receive_notifications, setCheckPolicy] = useState(false)
    const [checkUpdate, setCheckUpdate] = useState(false)
    const dispatch = useDispatch()
    const [openPreview, setOpenPreview] = useState(false)
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const [loading, setLoading] = useState(false)
    const currentLanguage = useSelector(userSelectors.selectLanguage)
    const formRef = useRef()
    const urlParams = getQueryVariables()
    const [form] = Form.useForm()
    const [modalType, setModalType] = useState<'terms' | 'policy'>('terms')

    const signUpAsync = useCallback(async (sendData: RegistrationRequest) => {
        try {
            setLoading(true)
            const response = await authService.registration(sendData)
            dispatch(
                setPasswordResetData({
                    ...response,
                    passwordResetData: { email: response.email },
                }),
            )
            setLoading(false)
            navigate(routes.CHECK_EMAIL)
        } catch (e) {
            setLoading(false)
            errorsHandler(e, t)
        } finally {
            setLoading(false)
        }
    }, [])

    const onFinish = async (values) => {
        const targetParam = urlParams?.find(
            (item) => item.name === 'invitation_token',
        )
        const data = {
            email: values.email,
            password1: values.password1,
            password2: values.password2,
            receive_advertisement: receive_notifications,
            invitation_token: targetParam ? targetParam?.value : '',
            belonging: 'common',
        }
        await signUpAsync(data)
    }

    useEffect(() => {
        updateFieldError(formRef.current, t)
    }, [currentLanguage])

    useEffect(() => {
        const emailParam = urlParams?.find((item) => item.name === 'email')
        if (emailParam) {
            form.setFieldValue('email', emailParam?.value)
        }
    }, [urlParams])

    if (loading) {
        return <LargeSpin />
    } else
        return (
            <>
                <div className="signBlock">
                    <SignHeader
                        buttonRoute={{
                            link: routes.LOGIN,
                            name: t('entrance'),
                        }}
                    />
                    <div className="signWrapper reqistration__wrapper ">
                        <Form
                            form={form}
                            className="signForm"
                            onFinish={onFinish}
                            name="register"
                            scrollToFirstError
                            ref={formRef}
                        >
                            <h2 className="signForm__header">
                                {t('registration')}
                            </h2>
                            <div className={'signForm__inputWrapper'}>
                                <Form.Item
                                    name={'email'}
                                    className={' signForm__inputItem'}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('email'),
                                        },
                                        {
                                            type: 'email',
                                            message: t(
                                                'enter-real-email-and-password',
                                            ),
                                        },
                                    ]}
                                >
                                    <Input
                                        type="email"
                                        className="signForm__input"
                                        prefix={<LetterIcon />}
                                        placeholder={t('email')}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={'password1'}
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'password-must-has-more-than-value-symbols',
                                                { value: 8 },
                                            ),
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password
                                        className="signForm__input signForm__password"
                                        placeholder={t('password')}
                                        prefix={<LockIcon />}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={'password2'}
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'password-must-has-more-than-value-symbols',
                                                { value: 8 },
                                            ),
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    getFieldValue(
                                                        'password1',
                                                    ) === value
                                                ) {
                                                    return Promise.resolve()
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        t(
                                                            'both-passwords-dont-match',
                                                        ),
                                                    ),
                                                )
                                            },
                                        }),
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password
                                        className="signForm__input"
                                        placeholder={t('repeat-password')}
                                        prefix={<LockIcon />}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={'agreePolicy'}
                                    valuePropName="checked"
                                    style={{ marginBottom: '0' }}
                                    rules={[
                                        ({}) => ({
                                            validator() {
                                                if (!receive_notifications) {
                                                    return Promise.reject(
                                                        new Error(
                                                            t(
                                                                'agree-with-policy',
                                                            ),
                                                        ),
                                                    )
                                                } else {
                                                    return Promise.resolve()
                                                }
                                            },
                                        }),
                                    ]}
                                >
                                    <>
                                        <Checkbox
                                            checked={receive_notifications}
                                            onClick={() =>
                                                setCheckPolicy(
                                                    !receive_notifications,
                                                )
                                            }
                                            className={'signForm__checkbox'}
                                        />
                                        <p className="signForm__agreementContent">
                                            <>
                                                <Trans
                                                    i18nKey="agree-with-rules-and-pocicy" // optional -> fallbacks to defaults if not provided
                                                    defaults="Согласен с <firstLink id='terms'>Правилами</firstLink> и <secondLink id='policy'>Политикой</secondLink>" // optional defaultValue
                                                    components={{
                                                        firstLink: (
                                                            <a
                                                                onClick={() => {
                                                                    setModalType(
                                                                        'terms',
                                                                    )
                                                                    setOpenPreview(
                                                                        true,
                                                                    )
                                                                }}
                                                            />
                                                        ),
                                                        secondLink: (
                                                            <a
                                                                onClick={() => {
                                                                    setModalType(
                                                                        'policy',
                                                                    )
                                                                    setOpenPreview(
                                                                        true,
                                                                    )
                                                                }}
                                                            />
                                                        ),
                                                    }}
                                                />
                                                <span>
                                                    {i18n.language === 'ru' &&
                                                        ' Inlog'}
                                                </span>
                                            </>
                                        </p>
                                    </>
                                </Form.Item>
                                <Form.Item
                                    name={'agreeUpdates'}
                                    valuePropName="checked"
                                >
                                    <Checkbox
                                        onClick={() =>
                                            setCheckUpdate(!checkUpdate)
                                        }
                                        checked={checkUpdate}
                                        className={'signForm__checkbox'}
                                    ></Checkbox>
                                    <p className="signForm__agreementContent">
                                        {t('agree-to-recieve-news-and-updates')}
                                    </p>
                                </Form.Item>
                            </div>
                            {/*<MediaAuth />*/}
                            <div
                                className={'signForm__btn-wrapper'}
                                style={{ textAlign: 'center' }}
                            >
                                <button
                                    type={'submit'}
                                    className={
                                        'signForm__submitBtn cursorPointer'
                                    }
                                >
                                    {t('start')}
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
                <Modal
                    width={700}
                    open={openPreview}
                    onCancel={() => setOpenPreview(false)}
                    footer={[
                        <Button
                            key="back"
                            onClick={() => setOpenPreview(false)}
                        >
                            {t('close')}
                        </Button>,
                    ]}
                >
                    <SimpleBar
                        className={'signForm__textPreview'}
                        autoHide={false}
                    >
                        <div className={'textPreview'}>
                            <TermsAndPolicy type={modalType} />
                        </div>
                    </SimpleBar>
                </Modal>
            </>
        )
}

export default Registration
