import React, { FC } from 'react'

interface IProps {
    className?: string
    style?: object
    handler?: () => void
}
export const TagsArrowIcon: FC<IProps> = ({ className, style, handler }) => {
    return (
        <svg
            onClick={handler}
            style={style ? style : {}}
            id={'tagsArrowIcon'}
            className={className ? className : 'tagsArrowIcon'}
            width="12"
            height="5"
            viewBox="0 0 12 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 1L5.4855 3.6913C5.80219 3.88131 6.19781 3.88131 6.5145 3.6913L11 1"
                stroke="#364F6B"
            />
        </svg>
    )
}

export default TagsArrowIcon
