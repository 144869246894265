import { useEffect, useState } from "react";
import React from "react";
import wellio from "wellio";
import wellioviz from "wellioviz";
import './WellMode.css';
import {curveBoxTemplateLocal, minimumDataIntoTemplateFuncLocal, multipleLogPlotLocal} from "./utils";
// @ts-ignore
import las4Exz from '../../widgets/scheduler/data/well_logging_example_4.las';
import { useTranslation } from "react-i18next";
export const uwi2 = "add"

const showValidDepthName = (data:any):string=>{
    const keys = data?.CURVES ? Object.keys(data?.CURVES) : []
    if(keys && keys?.length>0){
        const targetKey = keys?.find(el=>['MD','DEPT','DEPTH'].includes(el))
        if(targetKey){
            return targetKey
        }else return ''
    }
    return ''
}

export const WellMode = () => {
    const {t} = useTranslation();
    const [threeThings,setThreeThings] = useState(null)
    const [depthName,setDepthName] = useState('')

    const wellLogFetch = async () => {
        try {
            fetch(las4Exz)
                .then(row => {
                    return row.text()
                })
                .then(text => {
                    const convertedLas = wellio.las2json(text)
                    if(convertedLas){
                        const depthNameVal = showValidDepthName(convertedLas)
                        setDepthName(depthNameVal)
                        const three_things_2 = wellioviz.fromJSONofWEllGetThingsForPlotting(convertedLas, depthNameVal)['well_log_curves_reformatted_for_d3']
                        if(three_things_2){
                            setThreeThings(three_things_2)
                        }
                    }
                });

        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        wellLogFetch()
    }, [])

    const showGraphic = () => {
        if (threeThings && depthName?.length!==0 ) {
            const list = Object.keys(threeThings[0])?.filter(el => !['UWI',depthName].includes(el))
            const example_template = curveBoxTemplateLocal("example")
            if (list && list?.length > 0) {
                const templatesArr = list?.map(val => {
                    return minimumDataIntoTemplateFuncLocal(
                        example_template, threeThings,
                        [uwi2],
                        [val],
                        ["#364f6b"],
                        [""],
                        [{ "curve_name": val, "fill": "yes", "fill_direction": "right", "cutoffs": [0, 'ShaleSiltCutOff', 'SiltSandCutOff'], "fill_colors": ["#364f6b", "orange", "yellow"], "curve2": "" }],
                        "well_holder_1A",
                        110,
                        500,
                        depthName
                    )
                })
                multipleLogPlotLocal(`example-1`, templatesArr, true, true)
            }

        }
    }

    useEffect(() => {
        threeThings &&
        showGraphic()
    }, [threeThings])



    useEffect(()=>{
        const nodeList = document.getElementsByClassName('component_inner')
        if(nodeList && Array.isArray(Array.from(nodeList))){
            const list = Array.from(nodeList)
            for(let i=0;i<list.length;i++){
                const nodeItem = nodeList[i]
                const othersNodesList = list.filter((el,index)=>index!==i)
                nodeItem.addEventListener('scroll',()=>{
                    let scrollTop = nodeItem?.scrollTop
                    othersNodesList?.forEach(val=>{
                        val.scrollTop = scrollTop
                    })
                })
            }
        }
    },[document,threeThings])

    return (
        <div className="well-mode">
            <h1>{t('graphics-examples')}</h1>
            <div className="well-mode-content">
                <div id={`example-1`} className='well-mode-graphics'></div>
            </div>
        </div>
    )
}