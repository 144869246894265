import React, {useState} from 'react';
import './matchSettings.css';
import {MnemMatching} from "./components/mnemMatching/MnemMatching";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectCurrentProject} from "../../../../../../store/currentProject/selectors";
import {StrangeNanList} from "../strangeNansList/StrangeNanList";
import {projectsService} from "../../../../../../services/projects-service.";
import {showNotice} from "../../../../../../utils/helpers/showNotice";
import {toast} from "react-toastify";
import {IMnemonic} from "../../../../../../app/types/models/IMnemonic";
import {setWellLogMnemonics, setWellLogStrangeNans} from "../../../../../../store/currentProject/actions";
import {useAppDispatch} from "../../../../../../hooks/redux-hooks";
import {errorsHandler} from "../../../../../../utils/helpers/errors/errors-hendler";

export const MatchSettings = () => {
    const [activeTabId, setActiveTabId] = useState<1 | 2>(1)
    const {t} = useTranslation()
    const currentProject = useSelector(selectCurrentProject)
    const dispatch = useAppDispatch()

    const updateStrangeNans = async (value:string) => {
        const strangeNansArray = value.split(',').map(item=>item.trim())
        try {
            if (currentProject && currentProject?.id) {
                await projectsService.updateProject(currentProject?.id, {...currentProject, well_log_strange_nans:strangeNansArray})
                dispatch(setWellLogStrangeNans(strangeNansArray))
                showNotice('strange-nans-updated-successfully',t)
            }
        } catch (e) {
            Object.values(e.response?.data?.well_log_strange_nans).flat()?.forEach((item:string)=>{
                toast.error(item,{position:'top-center'})
            })
        }
    }

    const updateMnemonics = async (well_log_mnemonics:IMnemonic[])=>{
        try {
            if (currentProject && currentProject?.id) {
                await projectsService.updateProject(currentProject?.id, {...currentProject, well_log_mnemonics})
                dispatch(setWellLogMnemonics(well_log_mnemonics))
                showNotice('mnemonic-dictionary-updated-successfully',t)
            }
        }catch (e) {
            errorsHandler(e,t)
        }
    }

    const renderBlockById = () => {
        if (activeTabId === 1) {
            return <MnemMatching updateMnemonics={updateMnemonics}/>
        } else {
            return <StrangeNanList onChange={updateStrangeNans} />
        }
    }

    const setActiveClassName = (id:number)=> activeTabId === id
        ? {className:'matchSettings_tab font-14-normal cursor-pointer matchSettings_tab_active'} : {className:'matchSettings_tab font-14-normal cursor-pointer'}
    return (
        <div className={'mt-30'}>
            <div className={'row-flex-20'}>
                <div {...setActiveClassName(1)} onClick={() => setActiveTabId(1)}>{t('mnem-and-unit-matching').toString()}</div>
                <div {...setActiveClassName(2)} onClick={() => setActiveTabId(2)}>{t('strange-nan').toString()}</div>
            </div>
            <div className={'matchSettings_content mt-15'}>
                {renderBlockById()}
            </div>

        </div>
    );


};


