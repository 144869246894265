export const classesList = [
    'editableSpan-input status__item-editInput',
    'editableSpan',
    'editableSpan-input',
    'editableSpan-input projectsInfoItem-input',
    'editableSpan-input scheduler__taskEditSpace-editInput',
    'editableSpan-input projects__elements-editInput',
    'editableSpan-input projects__elements-editInput projects__elements-wells-editableInput',
    'logsItem__editSpan logsItem__editSpan_active editSpanContainer',
    'editableSpan-input logsItem__editInput'
]
