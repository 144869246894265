import { createSlice } from '@reduxjs/toolkit'
import {
    IComment,
    IStatus,
    ISubTask,
    ITag,
    ITask,
} from '../../app/types/models/tasks'
import {
    addTask,
    deleteTaskAssigneeMember,
    deleteTask,
    setCurrentTask,
    setTaskPriority,
    setTaskTitle,
    setTasks,
    updateTaskAssigneeList,
    updateTaskSupervisor,
    setSubTasks,
    addSubtask,
    deleteSubtask,
    setComments,
    addComment,
    updateTagsList,
    setStatuses,
    setTasksLoadingStatus,
    updateTask,
} from './actions'
import { LoadingStatusesType } from '../../app/types/common'

export interface InitialState {
    count: number
    next: null | number | string
    previous: null | number | string
    results: ITask[]
    currentTask: null | ITask
    subtasks: ISubTask[]
    comments: IComment[]
    statuses: IStatus[]
    isLoading: boolean
}

const initialState: InitialState = {
    count: 0,
    next: null,
    previous: null,
    results: [],
    currentTask: null,
    subtasks: [],
    comments: [],
    statuses: [],
    isLoading: false,
}

export const slice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setTasks, (state, action) => {
            state.count = action.payload.count
            state.next = action.payload.next
            state.previous = action.payload.previous
            state.results = action.payload.results
        })
        builder.addCase(setCurrentTask, (state, action) => {
            state.currentTask = action.payload.currentTask
        })
        builder.addCase(addTask, (state, action) => {
            state.results = [action.payload.task, ...state.results]
            state.count = state.count + 1
        })
        builder.addCase(deleteTask, (state, action) => {
            state.results = state.results.filter(
                (f) => f.slug !== action.payload.slug,
            )
        })
        builder.addCase(updateTask, (state, action) => {
            state.results = state.results.map(
                (task) => task.id === action.payload.id ? action.payload : task,
            )
        })
        builder.addCase(setTaskPriority, (state, action) => {
            state.results = state.results.map((t) =>
                t.id === action.payload.id
                    ? { ...t, priority: action.payload.priority }
                    : t,
            )
        })
        builder.addCase(setTaskTitle, (state, action) => {
            state.results = state.results.map((t) =>
                t.slug === action.payload.slug
                    ? { ...t, name: action.payload.title }
                    : t,
            )
        })
        builder.addCase(updateTaskAssigneeList, (state, action) => {
            const doer = {
                user: action.payload.user,
                id: action.payload.assigneeId,
            }
            state.currentTask = {
                ...state.currentTask,
                doers: [doer, ...state.currentTask.doers],
            }
        })
        builder.addCase(deleteTaskAssigneeMember, (state, action) => {
            const filteredMembers = state.currentTask.doers.filter(
                (f) => f.id !== action.payload.assigneeId,
            )
            state.currentTask = { ...state.currentTask, doers: filteredMembers }
        })
        builder.addCase(updateTaskSupervisor, (state, action) => {
            const supervisor = {
                user: action.payload.user,
                id: action.payload.supervisorId,
            }
            state.currentTask = { ...state.currentTask, supervisor }
        })
        builder.addCase(setSubTasks, (state, action) => {
            state.subtasks = action.payload.subTasks
        })
        builder.addCase(addSubtask, (state, action) => {
            state.subtasks = [action.payload.subtask, ...state.subtasks]
        })
        builder.addCase(deleteSubtask, (state, action) => {
            state.subtasks = state.subtasks.filter(
                (f) => f.slug !== action.payload.slug,
            )
        })
        builder.addCase(setComments, (state, action) => {
            state.comments = action.payload.comments
        })
        builder.addCase(addComment, (state, action) => {
            state.comments = [action.payload.comment, ...state.comments]
        })
        builder.addCase(updateTagsList, (state, action) => {
            const tags = state.currentTask?.tags
                ? [...state?.currentTask?.tags, action.payload.tags]
                : (action.payload.tags as ITag[])
            //@ts-ignore //todo - поправить
            state.currentTask = { ...state.currentTask, tags }
        })
        builder.addCase(setStatuses, (state, action) => {
            state.statuses = action.payload.statuses
        })
        builder.addCase(setTasksLoadingStatus, (state, action) => {
            state.isLoading = action.payload
        })
    },
})
