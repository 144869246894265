import React, {FC} from 'react';
import {Popover, PopoverProps} from "antd";
import './popoverInLog.scss';

interface IProps extends PopoverProps {}

const PopoverInLog:FC<IProps> = (props) => {
    const {content,placement='top',children,style,trigger,open} = props
    return (
        <Popover trigger={trigger}
                 color={'black'}
                 style={style}
                 content={content} placement={placement}
                 className={'custom-popover'}
                 open={open}
        >
            <div className={'cursor-pointer'}>
                {children}
            </div>
        </Popover>
    );
};

export default PopoverInLog;