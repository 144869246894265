import React, { FC, memo, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IPopoverMenuItem, ISelectOption } from '../../../../app/types/common'
import { LanguageTypes, RoadMapDetalizationTypes } from '../../../../app/types/enums'
import { ButtonItem } from '../../../../app/types/i-button'
import { IStatus, ITask } from '../../../../app/types/models/tasks'
import { ISupervisor } from '../../../../app/types/models/users'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { CopyDataIcon } from '../../../../image_files/icons/CopyDataIcon'
import { DeleteIcon } from '../../../../image_files/icons/DeleteIcon'
import DescriptionIcon from '../../../../image_files/icons/DescriptionIcon'
import { EditIcon } from '../../../../image_files/icons/EditIcon'
import MessageIcon from '../../../../image_files/icons/MessageIcon'
import { OpenInNewTabIcon } from '../../../../image_files/icons/OpenInNewTabIcon'
import PinIcon from '../../../../image_files/icons/PinIcon'
import RoadmapClockIcon from '../../../../image_files/icons/RoadmapClockIcon'
import { tasksService } from '../../../../services/tasks-services'
import ModalInlog from '../../../../shared/modalInlog/ModalInlog'
import PopoverInLog from '../../../../shared/popoverInlog/PopoverInLog'
import PopoverMenu from '../../../../shared/popoverMenu/PopoverMenu'
import SpinInLog from '../../../../shared/spin/spin'
import { selectCurrentProject } from '../../../../store/currentProject/selectors'
import { deleteTask } from '../../../../store/tasks/actions'
import { selectStatuses } from '../../../../store/tasks/selectors'
import { getCorrectDateFormat } from '../../../../utils/getCorrectDateFormat'
import { goToNewTab } from '../../../../utils/go-to-new-tab'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { getValidText } from '../../../../utils/helpers/getValidText'
import { showNotify } from '../../../../utils/helpers/showNotice'
import SupervisorWithAssignees from '../creatorWithAssginees/CreatorWithAssignees'
import RoadMapUpdateTaskBlock from '../roadMapAddTaskBlock/RoadMapUpdateTaskBlock'
import RoadmapItemPriority from '../roadmapPriority/RoadmapPriority'
import './RoadMapItem.css'

interface IProps {
    item: ITask
    tagsOptions: ISelectOption[]
    detalizationData: {
        detalization: RoadMapDetalizationTypes,
        startPeriod: Date | string
        endPeriod: Date | string
    },
    saveBlockHeight?:(data:number)=>void
}

interface IModalData {
    title: string
    titleIcon?: ReactNode
    isOpen: boolean
    text: string
    saveBtnTitle?: string
    onSave?: () => void
}

const initialModalData: IModalData = {
    title: '',
    isOpen: false,
    text: '',
}

const MAX_NAME_SYMBOLS_COUNT = 90

const RoadMapItem: FC<IProps> = ({ item,detalizationData,saveBlockHeight, tagsOptions }) => {
    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation()
    const statuses = useSelector(selectStatuses)
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [closeMenuTrigger, setCloseMenuTrigger] = useState(0)
    const [modalsData, setModalsData] = useState(initialModalData)
    const currentProject = useSelector(selectCurrentProject)

    const getValidStatusName = (status: IStatus | undefined) => {
        if (status !== undefined && status !== null) {
            if (i18n.language === LanguageTypes.ru) {
                return getValidText(status?.name_ru)
            } else {
                return getValidText(status?.name_en)
            }
        } else {
            return t('without-status')
        }
    }

    const getValidStatus = () => {
        if (typeof item.status === 'number') {
            const targetStatus = statuses.find((el) => el?.id === item?.status)
            return getValidStatusName(targetStatus)
        } else {
            return getValidStatusName(item.status)
        }
    }

    const getValidEndDate = () => {
        if (item?.due_date_end && item.due_date_end?.length > 0) {
            return getCorrectDateFormat({
                date: item?.due_date_end,
                separator: '.',
            })
        } else {
            return t('no-date-has-been-set')
        }
    }

    const menuItems: IPopoverMenuItem[] = [
        {
            id: 1,
            title: t('open-in-new-tab'),
            icon: <OpenInNewTabIcon />,
            onClick: () => {
                goToNewTab(`../${currentProject?.id}/tasks/${item?.slug}`)
            },
        },
        {
            id: 2,
            title: t('copy-link'),
            icon: <CopyDataIcon />,
            onClick: () => {
                const changedPath = window.location.href.replace(
                    'roadmap',
                    'tasks',
                )
                navigator.clipboard
                    .writeText(`${changedPath}/${item?.slug}`)
                    .then(() => {
                        showNotify(t('link-copied'))
                        setCloseMenuTrigger((prev) => prev + 1)
                    })
            },
        },
        {
            id: 3,
            title: t('edit-task'),
            icon: <EditIcon />,
            onClick: () => {
                setIsOpenModal(true)
                setCloseMenuTrigger((prev) => prev + 1)
            },
        },
        {
            id: 4,
            title: t('delete-task'),
            icon: <DeleteIcon />,
            onClick: () => {
                setModalsData({
                    isOpen: true,
                    text: t('you-realy-want-do-delete-this-task'),
                    titleIcon: <DeleteIcon width={18} height={18} />,
                    title: t('task-deleting'),
                    saveBtnTitle: t('delete'),
                    onSave: deleteITem,
                })
                setCloseMenuTrigger((prev) => prev + 1)
            },
        },
    ]

    const modalBtnList: ButtonItem[] = [
        {
            id: 1,
            titleBtn: t('cancel'),
            className: 'font-14-normal-imp',
            handleBtn: () => setModalsData(initialModalData),
        },
        {
            id: 2,
            type: 'primary',
            titleBtn: modalsData?.saveBtnTitle || t('save'),
            handleBtn: modalsData?.onSave,
        },
    ]

    const deleteITem = async () => {
        try {
            setIsLoading(true)
            setIsLoading(false)
            await tasksService.deleteTask(currentProject?.id, item?.slug)
            dispatch(deleteTask({ slug: item?.slug }))
            setModalsData(initialModalData)
            showNotify(t('task-deleted-successfully'))
        } catch (error) {
            setIsLoading(false)
            errorsHandler(error, t)
            setModalsData(initialModalData)
        }
    }

    const getClassByDeadline = () => {
        const now = new Date().getTime()
        const targetDate =
            new Date(getValidText(item?.due_date_end)).getTime() || 0
        if (targetDate >= now) {
            return 'roadmap-item-deadline'
        } else return 'roadmap-item-deadline roadmap-item-deadline-error'
    }

    const showValidTaskName = () => {
        if (item?.name.length > MAX_NAME_SYMBOLS_COUNT) {
            return (
                <PopoverInLog
                    content={
                        <div
                            className={
                                'font-12-normal font-white popover-content'
                            }
                        >
                            {item.name}
                        </div>
                    }
                >
                    <span>{getValidText(item.name.slice(0, MAX_NAME_SYMBOLS_COUNT) + '...')}</span>
                </PopoverInLog>
            )
        } else return <span>{getValidText(item?.name)}</span>
    }

    const id = `roadmap-item-${item.id}`

    useEffect(()=>{
        const myDiv = document.getElementById(id);
        saveBlockHeight && saveBlockHeight(myDiv.offsetHeight)
    },[item])

    return (
        <li className="cursor-pointer roadmap-item" id={id}>
            {createPortal(<SpinInLog isLoading={isLoading} />, document.body)}
            <div
                className="roadmap-item-task-data"
                onClick={() => setIsOpenModal(!isOpenModal)}
            >
                <RoadmapItemPriority priority={getValidText(item?.priority)} />
                <div className="roadmap-item-data ">
                    <div className={getClassByDeadline()}>
                        {/* <RoadmapClockIcon /> */}
                        <span>{getValidEndDate()}</span>
                    </div>
                </div>
                <div className="roadmap-item-slug">
                    <span>{getValidText(item?.slug)}</span>
                </div>
                <div className="roadmap-item-data-task-name">
                    {showValidTaskName()}
                </div>
                <SupervisorWithAssignees
                    supervisor={item?.supervisor as ISupervisor}
                    assignees={item?.doers}
                />
                <div className="roadmap-item-status">
                    <span>{getValidStatus()}</span>
                </div>
                <div className="roadmap-item-tools">
                    <DescriptionIcon />
                    <PinIcon />
                    <MessageIcon />
                    <PopoverMenu
                        items={menuItems}
                        closeTrigger={closeMenuTrigger}
                        className="roadmap-item-menu"
                    />
                </div>
                {isOpenModal && (
                    <RoadMapUpdateTaskBlock
                        task={item}
                        isOpen={isOpenModal}
                        tagsOptions={tagsOptions}
                        setIsOpen={setIsOpenModal}
                    />
                )}
            </div>
            {/* <div className="roadmap-item-calendar-data">
                <RoadMapCalendarItem item={item} detalizationData={detalizationData} />
            </div> */}

            {createPortal(
                <ModalInlog
                    width={400}
                    open={modalsData.isOpen}
                    title={modalsData.title}
                    iconTitle={modalsData?.titleIcon}
                    listButton={modalBtnList}
                    onCancel={() => setModalsData(initialModalData)}
                >
                    <div className="font-14-normal roadmap-item-modal-inner">
                        {modalsData?.text}
                    </div>
                </ModalInlog>,
                document.body,
            )}
        </li>
    )
}
export default memo(RoadMapItem)
