import React, {FC} from 'react';
import './table.css';
import {QuestionIcon} from "../../image_files/icons/QuestionIcon";
import {IFileBlockProps} from "./types";
import PopoverInLog from "../popoverInlog/PopoverInLog";

const TableInLog: FC<IFileBlockProps> = (
    {
        topDataList,
        hintData,
        rowFileList,
    }
) => {

    const showTopDataList = ()=>{
        return topDataList.map((item,index)=>(
            <span className={'break-word pr-5 font-14-normal'} key={index}>{item.label}</span>
        ))
    }
    return (
        <div className={'table'}>
            <div className={'table-top'}>
                {
                    hintData ?
                        <PopoverInLog content={<div className={'font-12-normal font-white table-popover'}>{hintData}</div>}>
                            <QuestionIcon width={21} height={21}/>
                        </PopoverInLog>
                        : <div></div>
                }
                {showTopDataList()}
            </div>
            <ul >
                {rowFileList}
            </ul>
        </div>
    );
};

export default TableInLog;