import React, { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import './CustomGroup.scss';
import { CustomGroupParameters, ICustomGroupParameter, ICustomGroupProps, ICustomParameterGroup } from "./types";
import SelectInputBlock from "../../shared/selectInputBlock/SelectInputBlock";
import { ISelectFieldData, SelectInputTypes } from "../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/types";
import { LanguageTypes } from "../../app/types/enums";
import SettingCancelIcon from "../../image_files/icons/SettingCancelIcon";
import { getInitialCustomGroupParameters } from "./get-initial-custom-group-parameters";
import AddParameterZone from "./components/add-parameter-zone/AddParameterZone";
import { showNotice } from "../../utils/helpers/showNotice";
import { AddGroupIcon } from "../../image_files/icons/AddGroupIcon";
import { AddElementIcon } from "../../image_files/icons/AddElementIcon";
import { errorsHandler } from "../../utils/helpers/errors/errors-hendler";
import { customGroupService } from "../../services/custom-group-service";
import { useSelector } from "react-redux";
import { selectCurrentProject } from "../../store/currentProject/selectors";
import { defaultElement, defaultNameData } from "./data";
import SpinInLog from "../../shared/spin/spin";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import MadeParametersList from "./components/MadeParametersList";


const CustomGroup: FC<ICustomGroupProps> = (props) => {
    const { coreId,refreshTrigger, closeModal, refechGroups } = props
    const { t, i18n } = useTranslation()
    const uid = uuidv4()
    const [customGroupName, setCustomGroupName] = useState<ISelectFieldData>(defaultNameData)
    const [parameterGroupName, setParameterGroupName] = useState<ISelectFieldData>(defaultNameData)
    const [parameter, setParameter] = useState<ICustomGroupParameter>({} as ICustomGroupParameter)
    const [isErrorActiveElement, setIsErrorActiveElement] = useState(false)
    const [isErrorCustomGroupName, setIsErrorCustomGroupName] = useState(false)
    const [isErrorParameterGroupName, setIsErrorParameterGroupName] = useState(false)
    const [parameters, setParameters] = useState<ICustomGroupParameter[]>([])
    const [activeParameter, setActiveParameter] = useState<ICustomGroupParameter>(defaultElement as ICustomGroupParameter)
    const currentProject = useSelector(selectCurrentProject)
    const [isLoading, setIsLoading] = useState(false)
    const [isValidParametersToAdd, setIsValidParametersToAdd] = useState(true)
    //test data

    //parameters
    const [parametersGroups, setParametersGroups] = useState<ICustomParameterGroup[]>([])
    const [activeParameterGroup, setActiveParameterGroup] = useState<ICustomParameterGroup>({} as ICustomParameterGroup)

    const changeCustomGroupNameHandler = (e: ISelectFieldData) => {
        setCustomGroupName(e)
    }

    const clearNameOfGroupField = () => {
        setCustomGroupName(defaultNameData)
    }

    const changeParameterGroupNameHandler = (e) => {
        setParameterGroupName(e)
    }

    const clearParameterGroupNameField = () => {
        setParameterGroupName(defaultNameData)
    }

    const getActiveParameterClass = (id: string) => {
        return id === activeParameter?.id ? 'custom-group-parameters-list-item__active' : ''
    }

    //Parameters
    const addParameter = (data: ICustomGroupParameter) => {
        let preparedParameters = [];
        if (parameters?.find(item => item.id === data.id)) {
            preparedParameters = parameters.map(item => item.id === data.id ? data : item)
            setParameters(preparedParameters)
        } else {
            preparedParameters = [...parameters, data]
            setParameters(preparedParameters)
        }
        if (parametersGroups.length > 0 && Object.keys(activeParameterGroup).length > 0) {
            const isHasErrorParameter = preparedParameters.some((value) => !value.isValidField)
            setParametersGroups(parametersGroups.map(item => item.id === activeParameterGroup.id ? {
                ...item,
                parameters: preparedParameters,
                isValidField: !isHasErrorParameter
            } : item))
            setActiveParameterGroup({
                ...activeParameterGroup,
                parameters: preparedParameters,
                isValidField: !isHasErrorParameter
            })
        }
    }

    const deleteMadeParameter = (id: string) => {
        if (activeParameterGroup && Object.keys(activeParameterGroup).length > 0) {
            let preparedParameters = parameters.filter(item => item?.id !== id)
            setActiveParameterGroup({ ...activeParameterGroup, parameters: preparedParameters })
            setParameters(preparedParameters)
            // setParametersOrder(preparedParameters?.map(item => item?.order))
            if (preparedParameters.length > 1) {
                setActiveParameter(preparedParameters.at(-1))
            } else if (preparedParameters.length === 1) {
                setActiveParameter(preparedParameters[0])
            } else {
                setActiveParameter(defaultElement)
            }
        } else {
            setParameters(parameters.filter(item => item.id !== id))
            //установка активного элемента
            if (parametersGroups.length > 1) {
                setActiveParameter(parameters.at(-1))
            } else if (parametersGroups.length === 1) {
                setActiveParameter(parameters[0])
            } else {
                setActiveParameter(defaultElement)
            }
        }
    }

    const setNewActiveParameterHandler = (param: CustomGroupParameters, i) => {
        setActiveParameter({
            type: param,
            id: (i + 1).toString(),
            data: {},
            isValidField: false
        })
    }

    const showInitialParametersList = () => {
        return getInitialCustomGroupParameters(t).map((item, i) => (
            <div key={item.id}
                className={`custom-group-parameters-list-item font-14-normal ${getActiveParameterClass(item.id)}`}
                onClick={() => setNewActiveParameterHandler(item.parameter, i)}>
                <div className={item.className} />
                <span>{item.title}</span>
            </div>
        ))
    }

    //класс для группы параметров
    const getValidClassForParameterGroupTitle = (item: ICustomParameterGroup) => {
        let initialClass = 'custom-group-groups-parameters-item';
        if (Object.keys(activeParameterGroup).length > 0 && item?.id === activeParameterGroup?.id) {
            return `${initialClass} ${!activeParameterGroup.isValidField ? 'custom-group-groups-parameters-item--error' : ''} custom-group-groups-parameters-item--active`
        } else {
            return item.hasOwnProperty('isValidField') && item?.isValidField === false
                ? `${initialClass} custom-group-groups-parameters-item--error`
                : `${initialClass}`
        }
    }

    // const reorder = (list: ICustomGroupParameter[], startIndex: number, endIndex: number) => {
    //     const result = [...list];
    //     const [removed] = result.splice(startIndex, 1);
    //     result.splice(endIndex, 0, removed)
    //     return result;
    // }

    const updateParametersGroupsData = (list: ICustomGroupParameter[]) => {
        if (parametersGroups && parametersGroups.length > 0 && Object.keys(activeParameterGroup).length > 0) {
            setParametersGroups(parametersGroups.map(item => item.id === activeParameterGroup.id ? {
                ...item,
                parameters: list
            } : item))
            setActiveParameterGroup({ ...activeParameterGroup, parameters: list })
            setParameters(list)
        } else {
            setParameters(list)
        }
    }

    const onDragEnd = (result: DropResult, parameters: ICustomGroupParameter[], setParameters: (items: ICustomGroupParameter[]) => void) => {
        const { destination, source, draggableId, type } = result
        if (!destination) {
            return
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            if (type !== 'form-parameter') {
                return
            }
        }
        if (type === 'parameter') {
            if (draggableId === '123') {

                // const list: ICustomGroupParameter[] = reorder(parameters, result.source.index, result.destination.index)
                // updateParametersGroupsData(list)
            } else {
                // const draggableParameter = JSON.parse(draggableId) as ICustomGroupParameter
                const draggableParameter = { ...parameter, id: uid, isValidField: !isErrorActiveElement }
                if (parameters.length !== 0) {
                    //1 - if it's first income parameter
                    //2 - if it isn't first param
                    let paramStatus:'new' | 'edited' = 'new'
                    const searchedParameter = parameters.find(el=>el.id===draggableId)
                    if(searchedParameter){
                        paramStatus = 'edited'
                    }

                    if (paramStatus=='edited') {
                        const changedParameters = parameters.map(item => item.id === draggableId ? draggableParameter : item)
                        updateParametersGroupsData(changedParameters)

                    } else {
                        const newList = [...parameters]
                        newList.splice(destination.index, 0, draggableParameter)
                        updateParametersGroupsData(newList)
                    }
                } else {
                    setParameters([draggableParameter])
                }
            }
        }
    }

    //parameter groups
    const addParameterGroupHandler = () => {
        const newParameter: ICustomParameterGroup = {
            id: uid,
            name: parameterGroupName,
            parameters,
            isValidField: true
        }
        setParametersGroups([...parametersGroups, newParameter])
        setParameterGroupName(defaultNameData)
        setParameters([])
        setActiveParameter(defaultElement)
    }

    //сбросить фокус с активного парамтера и дать возможность создать новый параметр
    const clearParameterGroupFocus = () => {
        setParameters([])
        setActiveParameter(defaultElement)
        setActiveParameterGroup({} as ICustomParameterGroup)
        setParameterGroupName(defaultNameData)
    }

    const setActiveParameterGroupHandler = (e, item: ICustomParameterGroup) => {
        e.stopPropagation()
        setParameters(item.parameters)
        setActiveParameter(item?.parameters[0])
        setParameterGroupName(item?.name)
        setActiveParameterGroup(item)
    }

    const showValidParameterName = (name: string) => {
        if (name?.length > 0) {
            return name
        } else {
            return t('not-filled-in').toString()
        }
    }

    const showParameterGroupList = () => {
        return parametersGroups.map((item, i) => (
            <div
                className={`font-12-normal ${getValidClassForParameterGroupTitle(item)}`}
                key={i} onClick={(e) => setActiveParameterGroupHandler(e, item)}>
                <div>{showValidParameterName(item?.name[i18n.language === 'ru' ? 'ru' : 'en'])}</div>
                <SettingCancelIcon className={'cursor-pointer'}
                    onClick={(e) => {
                        e.stopPropagation()
                        deleteGroupsParameter(item.id)
                    }}
                    width={26}
                    height={26}
                    viewBox={'9 7 21 21'}
                />
            </div>
        ))
    }

    const deleteGroupsParameter = (id) => {
        setParametersGroups(parametersGroups.filter(item => item.id !== id))
        if (Object.keys(activeParameterGroup).length > 0) {
            if (activeParameterGroup?.id === id) {
                setActiveParameterGroup({} as ICustomParameterGroup)
                setActiveParameter(defaultElement)
                setParameters([])
                setParameterGroupName(defaultNameData)
            }
        }
    }

    //возможность редактирования уже созданной группы параметров
    const editParameter = () => {
        if (activeParameterGroup && Object.keys(activeParameterGroup).length > 0) {
            const isValidActiveParameter = activeParameterGroup?.parameters?.filter(item => {
                return item?.isValidField === false
            }).length === 0 && !isErrorParameterGroupName
            setParametersGroups(parametersGroups?.map(item => item.id === activeParameterGroup.id
                ? { ...activeParameterGroup, name: parameterGroupName, isValidField: isValidActiveParameter } : item))
        }
    }
    useEffect(() => {
        editParameter()
    }, [activeParameterGroup, parameterGroupName])

    useEffect(() => {
        if (Object.keys(activeParameterGroup)?.length > 0) {
            setActiveParameterGroup({ ...activeParameterGroup, name: parameterGroupName })
        }
    }, [parameterGroupName])

    //удаляем параметр если элементов больше не осталось
    useEffect(() => {
        if (parametersGroups && parametersGroups?.length > 0) {
            if (Object.keys(activeParameterGroup)?.length > 0) {
                if (activeParameterGroup.parameters?.length === 0) {
                    setParametersGroups(parametersGroups.filter(item => item.id !== activeParameterGroup.id))
                    setActiveParameterGroup({} as ICustomParameterGroup)
                    setParameters([])
                    setParameterGroupName(defaultNameData)
                }
            }
        }
    }, [activeParameterGroup])


    useEffect(()=>{
        if(refreshTrigger!==undefined && refreshTrigger>0){
            setActiveParameter(defaultElement)
            setParameters([])
        }
    },[refreshTrigger])

    const isDisabledToAddParameter =
        parameters.length === 0
        || isErrorParameterGroupName
        || parameters?.find(item => !(item.isValidField))
        || Object.keys(activeParameterGroup)?.length > 0

    const addGroupHandler = async () => {
        try {
            setIsLoading(true)
            const groupResponse = await customGroupService.addGroup(currentProject?.id, coreId, {
                name_en: customGroupName.en,
                name_ru: customGroupName.ru
            })
            for (let i = 0; i < parametersGroups.length; i++) {
                const parameterGroupItem = parametersGroups[i]
                const measurementResponse = await customGroupService.addCustomMeasurement(currentProject?.id, coreId, groupResponse?.id, {
                    name_en: parameterGroupItem.name.en,
                    name_ru: parameterGroupItem.name.ru
                })
                const parameterGroupResponse = await customGroupService.addParameterGroup(currentProject?.id, coreId, groupResponse?.id, measurementResponse?.id, {})
                if (parameterGroupItem && parameterGroupItem?.parameters && parameterGroupItem?.parameters?.length > 0) {
                    let changedParameters = parameterGroupItem?.parameters && parameterGroupItem?.parameters.length > 0 ? parameterGroupItem?.parameters?.map((item, i) => ({
                        ...item,
                        order: i
                    })) : []
                    for (let j = 0; j < changedParameters.length; j++) {
                        let element = changedParameters[j]
                        await customGroupService.addParameter(currentProject?.id, coreId, groupResponse?.id, measurementResponse?.id, parameterGroupResponse?.id, {
                            type: element?.type,
                            data: JSON.stringify(element?.data)
                        })
                    }
                }
            }

            // console.log(groupResponse, '<--groupResponse')
            showNotice('custom-group-added-successfully', t)
            setParameters([])
            setParametersGroups([])
            setActiveParameter(defaultElement)
            setActiveParameterGroup({} as ICustomParameterGroup)
            setCustomGroupName({ en: '', ru: '' })
            setParameterGroupName({ en: '', ru: '' })
            closeModal()
            setIsLoading(false)
            refechGroups()
        } catch (e) {
            setIsLoading(false)
            errorsHandler(e, t)
        }
    }

    return (
        <div className='custom-group-wrapper'>
            <SpinInLog isLoading={isLoading} />
            <DragDropContext onDragEnd={(result) => onDragEnd(result, parameters, setParameters)}>
                <div>
                    <div className={'custom-group-top custom-group-paragraph'}>
                        <div
                            className={'font-14-normal mb-10'}>
                            {t('enter-name-of-group-in-two-languages').toString()}
                        </div>
                        <div className={'custom-group-top-input-block'}>
                            <SelectInputBlock className={'custom-group-select-input--normal'}
                                onChange={changeCustomGroupNameHandler}
                                type={SelectInputTypes.input}
                                activeLang={i18n.language === LanguageTypes.ru ? 'ru' : 'en'}
                                data={customGroupName}
                                setIsError={setIsErrorCustomGroupName}
                                id={'parameterGroupName'}

                            />
                            <SettingCancelIcon className={'cursor-pointer'} viewBox={'9 9 18 18'} width={25} height={25}
                                onClick={clearNameOfGroupField} />
                        </div>
                    </div>
                    <div className={'custom-group-columns'}>
                        <div className={'custom-group-parameters-column'}>
                            <div className={'custom-group-parameters-name'}>
                                <div className={'custom-group-parameters-name-top'}>
                                    <span
                                        className={'font-14-normal'}>{t('enter-measurement-name-in-two-languages').toString()}</span>
                                    <SettingCancelIcon viewBox={'9 9 18 18'} width={25} height={25}
                                        className={'cursor-pointer'}
                                        onClick={clearParameterGroupNameField} />
                                </div>
                                <SelectInputBlock onChange={changeParameterGroupNameHandler}
                                    type={SelectInputTypes.input}
                                    activeLang={i18n.language === LanguageTypes.ru ? 'ru' : 'en'}
                                    data={parameterGroupName}
                                    setIsError={setIsErrorParameterGroupName}
                                    className={'custom-group-select-input--normal'}
                                />
                            </div>
                            <div
                                className={'font-14-normal mt-10'}>{t('list-of-parameter-elements').toString()}</div>
                            <div className={'custom-group-parameters-list'}>
                                {showInitialParametersList()}
                            </div>
                        </div>
                        <AddParameterZone
                            type={'parameter'}
                            parameter={parameter}
                            parameters={parameters}
                            isErrorActiveElement={isErrorActiveElement}
                            droppableId={'addParameterZone'}
                            activeParameter={activeParameter}

                            addParameter={addParameter}
                            setParameter={setParameter}
                            setActiveParameter={setActiveParameter}
                            setIsErrorActiveElement={setIsErrorActiveElement}
                            changeParametersValidStatus={setIsValidParametersToAdd}

                        />
                        <div className={'custom-group-groups-parameters-list'}>
                            <div className={'font-14-normal row-flex-5'}>
                                <span>
                                    {t('parameters')}
                                </span>
                                {
                                    parametersGroups.length > 0 && Object.keys(activeParameterGroup)?.length > 0 &&
                                    //Снять с фокуса группу параметров и дать возможность создать новую группу
                                    <AddElementIcon onClick={clearParameterGroupFocus} />
                                }

                            </div>
                            <div>
                                {showParameterGroupList()}
                            </div>
                        </div>
                    </div>
                    {
                        !isValidParametersToAdd &&
                        <div className={'font-14-normal mt-10'}>
                            {t('only-set-single-element-or-pairs-message').toString()}
                        </div>
                    }
                    <div className={'custom-group-add-group-wrapper'}>
                        <MadeParametersList
                            activeParameterGroup={activeParameterGroup}
                            activeParameter={activeParameter}
                            parameters={parameters}
                            setActiveParameter={setActiveParameter}
                            deleteMadeParameter={deleteMadeParameter}
                            droppableId={'madeParametersList'}
                            type={'parameter'}
                        />

                        <AddGroupIcon
                            className={`custom-group-add-group-icon ${isDisabledToAddParameter ? 'custom-group-add-group-icon--disabled' : ''}`}
                            fill={isDisabledToAddParameter ? '#8096a6' : undefined}
                            onClick={isDisabledToAddParameter ? () => {
                            } : addParameterGroupHandler}
                        />
                        <div className={'add-custom-group-wrapper'}>
                            <Button
                                disabled={parametersGroups.length === 0 || isErrorCustomGroupName || parametersGroups.filter(item => item?.isValidField === false)?.length > 0}
                                className={'add-custom-group-button'}
                                onClick={addGroupHandler}>
                                {t('add-group').toString()}
                            </Button>
                        </div>
                    </div>
                </div>
            </DragDropContext>
        </div>
    )
        ;
};

export default CustomGroup;