import React, { FC } from 'react'
import './signButton.css'
import { Link } from 'react-router-dom'

interface IProps {
    link: string
    name: string
}

const SignButton: FC<IProps> = ({ link, name }) => {
    return (
        <Link to={link} className="signBtn">
            <span>{name}</span>
        </Link>
    )
}

export { SignButton }
