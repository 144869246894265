import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import SchedulerMenu from '../../features/scheduler-menu/SchedulerMenu'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { useGetMembers } from '../../hooks/use-get-members'
import { projectsService } from '../../services/projects-service.'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { projectsActions } from '../../store/projects'
import { setProjectsLoading } from '../../store/projects/actions'
import { selectProjects } from '../../store/projects/selectors'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import SchedulerRoutes from './components/scheduler-routes'
import './scheduler.css'
import { SchedulerPagesType } from './types'

export const Scheduler = () => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { setProjects } = projectsActions
    const projects = useSelector(selectProjects)
    //селекторы
    const [activePage, setActivePage] = useState<SchedulerPagesType>(
        SchedulerPagesType.tasks,
    )
    const projectId = Number(location.pathname.split('/')[2])

    const { refetch: fetchMembers } = useGetMembers()

    const fetchProjects = useCallback(async () => {
        try {
            if (location?.state?.projects) {
                dispatch(setProjects({ projects: location?.state?.projects }))
            } else if (!projects || projects?.length === 0) {
                dispatch(setProjectsLoading({ loading: true }))
                const res = await projectsService.getProjects()
                dispatch(setProjects({ projects: res }))
                dispatch(setProjectsLoading({ loading: false }))
            }
        } catch (e) {
            errorsHandler(e, t)
        } finally {
            dispatch(setProjectsLoading({ loading: false }))
        }
    }, [])

    useEffect(() => {
        if (activePage === SchedulerPagesType.tasks) {
            fetchProjects()
            const pathname = location.pathname
            const pathItemsList = pathname.split('/').filter((p) => p !== '')
            if (pathItemsList[0] === 'scheduler' && pathItemsList[1] === '*') {
                if (projectId!==undefined && !isNaN(projectId)) {
                    navigate(`${projectId}/tasks`)
                } else {
                    if (projects && projects?.length > 0) {
                        navigate(`${projects[0]?.id}/tasks`)
                    }
                }
            }else{
                //что делать если перезагрузить страницу, будучи в деталях 
            }
        }
    }, [activePage, projects, projectId])

    useEffect(() => {
        if (Number(projectId)) {
            fetchMembers(projectId)
        }
    }, [projectId])

    return (
        <div className="scheduler">
            <div className={'scheduler__inner'}>
                <SchedulerMenu setActivePage={setActivePage} />
                <SchedulerRoutes />
            </div>
        </div>
    )
}
