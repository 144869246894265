import { RootState } from '../store'
import {
    ICore,
    IPad,
    IResearchEntity,
    IWell,
    IWellBore,
} from '../../app/types/models/logging'
import { IProject } from '../../app/types/models/project'
import { IWellLogFile } from '../../app/types/models/well-log-file'
import { IMnemonic } from '../../app/types/models/IMnemonic'

export const selectCurrentProject = (state: RootState): IProject =>
    state.currentProject.project
export const selectCurrentPads = (state: RootState): IPad[] =>
    state.currentProject.pads
export const selectCurrentWells = (state: RootState): IWell[] =>
    state.currentProject.wells
export const selectCurrentWellBores = (state: RootState): IWellBore[] =>
    state.currentProject.wellBores
export const selectCurrentCores = (state: RootState): ICore[] =>
    state.currentProject.cores

export const selectWellLogFiles = (state: RootState): IWellLogFile[] | null =>
    state.currentProject.wellLogFiles

export const selectCurrentWellLogFile = (
    state: RootState,
): IWellLogFile | null => state.currentProject.currentWellLogFile

export const selectCurrentMnemonics = (state: RootState): IMnemonic[] | null =>
    state.currentProject.mnemonics

export const selectCurrentStrangeNans = (state: RootState): string[] | null =>
    state.currentProject.strange_nans

export const selectCurrentWell = (state: RootState): IWell | null =>
    state.currentProject.currentWell

export const selectCurrentPad = (state: RootState): IPad | null =>
    state.currentProject.currentPad

export const selectCurrentWellBore = (state: RootState): IWellBore | null =>
    state.currentProject.currentWellBore

export const selectCurrentCore = (state: RootState): ICore | null =>
    state.currentProject.currentCore

export const selectLoadingCurrentWellsStatus = (state: RootState): boolean =>
    state.currentProject.isLoadingCurrentWells
export const selectLoadingCurrentPadsStatus = (state: RootState): boolean =>
    state.currentProject.isLoadingCurrentPads
export const selectLoadingCurrentCoresStatus = (state: RootState): boolean =>
    state.currentProject.isLoadingCurrentCores
export const selectLoadingCurrentWellboresStatus = (
    state: RootState,
): boolean => state.currentProject.isLoadingCurrentWellbores
