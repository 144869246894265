import { ICalendarGroup, ICalendarItem } from '../../../../../app/types/i-roadmap-calendar'

export const getGroupsListByMonthDetalization = (
    calendarGroups: ICalendarGroup[],
    data: {
        priorityColor: string
        startDate: string | Date
        endDate: string | Date
    },
) => {
    
    let { startDate, endDate, priorityColor } = data
    startDate = new Date(startDate)
    endDate = new Date(endDate)

    const startYear = startDate.getFullYear()
    const startMonth = startDate.getMonth()
    const startDay = startDate.getDate()

    const endYear = endDate.getFullYear()
    const endMonth = endDate.getMonth()
    const endDay = endDate.getDate()

    let targetStartMonth = startMonth
    let targetEndMonth = endMonth

    const targetStartGroup = calendarGroups?.find(el=>el?.index===targetStartMonth)
    const targetEndGroup = calendarGroups?.find(el=>el?.index===targetEndMonth)
    const targetStartDay = targetStartGroup?.list
        ? targetStartGroup?.list.find((week, weekI) => {
              if (weekI === 0) {
                  let list = week?.value?.days || []
                  list = list.map((d) => (d >= 25 ? 0 : d))
                  return list.includes(startDay)
              } else return week?.value?.days.includes(startDay)
          })
        : undefined
    if (!targetStartDay) {
        targetStartMonth = targetStartMonth + 1
    }

    const targetEndDay = targetEndGroup?.list?.find((week, weekI) => {
        if (weekI === 0) {
            let list = week?.value?.days || []
            list = list.map((d) => (d >= 25 ? 0 : d))
            return list.includes(endDay)
        } else return week?.value?.days.includes(endDay)
    })

    if (!targetEndDay) {
        targetEndMonth = targetEndMonth + 1
    }

    return calendarGroups.map((group, groupI) => {

        const getGroup = (interval:[number,number])=>{
            return {
                ...group,
                list: group.list.map((week) => {
                    return {
                        ...week,
                        value: {
                            ...week?.value,
                            interval,
                            priorityColor,
                        },
                    }
                }),
            }
        }

        const getWeek = (week:ICalendarItem,interval:[number,number])=>{
            return {
                ...week,
                value: {
                    ...week?.value,
                    interval,
                    priorityColor,
                },
            }
        }

        if (group.year === startYear && group.year===endYear) {
            if (group?.index === targetStartMonth && group?.index === targetEndMonth) {
                return {
                    ...group,
                    list: group.list.map((week, weekI) => {
                        let days = week?.value?.days || []
                        const listLength = days.length
                        const startPosition = days.indexOf(startDay) + 1
                        const endPosition = days.indexOf(endDay) + 1
                        const firstPercent = (startPosition / listLength) * 100
                        const endPercent = (endPosition / listLength) * 100

                        if (weekI === 0) {
                            days = days.map((d) => (d >= 25 ? 0 : d))
                        }

                        if (days.includes(startDay) && !days.includes(endDay)) {
                            return {
                                ...week,
                                value: {
                                    ...week?.value,
                                    interval: [firstPercent, 100],
                                    priorityColor,
                                },
                            }
                        } else if (
                            days.includes(startDay) &&
                            days.includes(endDay) &&
                            days.indexOf(startDay) < days.indexOf(endDay)
                        ) {
                            return {
                                ...week,
                                value: {
                                    ...week?.value,
                                    interval: [firstPercent, endPercent],
                                    priorityColor,
                                },
                            }
                        } else if (
                            !days.includes(startDay) &&
                            days.includes(endDay)
                        ) {
                            return {
                                ...week,
                                value: {
                                    ...week?.value,
                                    interval: [0, endPercent],
                                    priorityColor,
                                },
                            }
                        } else return week
                    }),
                }
            } else if (
                group?.index === targetStartMonth &&
                group?.index !== targetEndMonth
            ) {
                return {
                    ...group,
                    list: group.list.map((week, weekI) => {
                        const days = week?.value?.days || []
                        const listLength = days.length
                        const startPosition = days.indexOf(startDay) + 1
                        const firstPercent = (startPosition / listLength) * 100

                        if (days.includes(startDay)) {
                            return getWeek(week,[firstPercent, 100])
                        } else {
                            if (days[0] < startDay) {
                                return getWeek(week,[0,0])
                            } else {
                                return getWeek(week,[0,100])
                            }
                        }
                    }),
                }
            } else if (
                group?.index !== targetStartMonth &&
                group?.index === targetEndMonth  
            ) {
                let weekIndex = null as null | number
                return {
                    ...group,
                    list: group.list.map((week, weekI) => {
                        let days = week?.value?.days || []
                        const listLength = days.length
                        const endPosition = days.indexOf(endDay) + 1
                        const endPercent = (endPosition / listLength) * 100

                        if (days.includes(endDay)) {
                            weekIndex = weekI
                            return getWeek(week,[0,endPercent])
                        } else {
                            if (weekIndex === null) {
                                return getWeek(week,[0,100])
                            } else {
                                return getWeek(week,[0,0])
                            }
                        }
                    }),
                }
            } else if (
                group?.index !== targetStartMonth &&
                group?.index !== targetEndMonth
            ) {
                if (group?.index < targetStartMonth) {
                    return group
                } else if (
                    group?.index > targetStartMonth &&
                    group?.index < targetEndMonth
                ) {
                    return getGroup([0,100])
                } else {
                    return group
                }
            }
            return group
        }else if(group.year >startYear && group.year < endYear){
            return getGroup([0,100])
        }else if(group.year===startYear && group.year!==endYear){
            if(group.index===targetStartMonth){
                return {
                    ...group,
                    list: group.list.map((week, weekI) => {
                        const days = week?.value?.days || []
                        const listLength = days.length
                        const startPosition = days.indexOf(startDay) + 1
                        const firstPercent = (startPosition / listLength) * 100

                        if (days.includes(startDay)) {
                            return getWeek(week,[firstPercent,100])
                        } else {
                            if (days[0] < startDay) {
                                return getWeek(week,[0,0])
                            } else {
                                if(days[0]<days[days.length-1]){
                                    return getWeek(week,[0,100])
                                }else return  getWeek(week,[0,0])
                                
                            }
                        }
                    }),
                }
            }else if(group.index<startMonth){
                return getGroup([0,0])
            }else{
                return getGroup([0,100])
            }

            
        } else if(group.year===endYear && group.year!==startYear){
            if(group.index<targetEndMonth && group.index!==targetEndMonth){
                return getGroup([0,100])
            }else if(group.index>targetEndMonth){
                return getGroup([0,0])
            }else{
                let weekIndex = null as null | number
                return {
                    ...group,
                    list: group.list.map((week, weekI) => {
                        let days = week?.value?.days || []
                        const listLength = days.length
                        const endPosition = days.indexOf(endDay) + 1
                        const endPercent = (endPosition / listLength) * 100
                        if(days[0]<days[days.length-1]){
                            if (days.includes(endDay)) { 
                                weekIndex = weekI
                                return getWeek(week,[0,endPercent])
                            } else {
                                if (weekIndex === null) {
                                    return getWeek(week,[0,100])
                                } else {
                                    return getWeek(week,[0,0])
                                }
                            }
                        }else{
                            return getWeek(week,[0,100])
                        }

                    }),
                }
            }
        }else if(group.year<startYear || group.year>endYear){
            return getGroup([0,0])
        }
        return group
    }) as ICalendarGroup[]
}
