import React, { FC, ReactElement } from 'react'
import './DetailsName.css'

interface IProps {
    icon: ReactElement
    name: string
}
export const DetailsName: FC<IProps> = ({ icon, name }) => {
    return (
        <div className="details__names-container">
            <div className="details__names-icon">{icon}</div>
            <div className="details__names-title">{name}</div>
        </div>
    )
}
