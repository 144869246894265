import {
    ContentState,
    convertFromHTML,
    convertToRaw,
    EditorState,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import React, { FC, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { tasksService } from '../../../../services/tasks-services'
import { setTasksLoadingStatus } from '../../../../store/tasks/actions'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { getValidText } from '../../../../utils/helpers/getValidText'
import { showNotify } from '../../../../utils/helpers/showNotice'
import './index.css'

interface IProps {
    initialText: string
    projectId: number
    taskSlug: string
}

export const Description: FC<IProps> = ({
    initialText,
    projectId,
    taskSlug,
}) => {
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(
                    initialText === '<p>null</p>' || initialText === null
                        ? '<p></p>'
                        : `${getValidText(initialText)}`,
                ),
            ),
        ),
    )
    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation()

    const onEditorStateChange = (editorStateValue) => {
        setEditorState(editorStateValue)
    }

    const onBlurHandler = () => {
        const descrCount = draftToHtml(
            convertToRaw(editorState.getCurrentContent()),
        )
        dispatch(setTasksLoadingStatus(true))
        tasksService
            .updateTask({ description: `${descrCount}` }, projectId, taskSlug)
            .then(() => {
                showNotify(t('description-added-successfully'))
            })
            .catch((e) => {
                errorsHandler(e, t)
                setEditorState(
                    EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                            convertFromHTML(
                                initialText === '<p>null</p>' ||
                                    initialText === null
                                    ? '<p></p>'
                                    : `${initialText}`,
                            ),
                        ),
                    ),
                )
            })
            .finally(() => {
                dispatch(setTasksLoadingStatus(false))
            })
    }

    return (
        <div className="scheduleDescription">
            <div className="scheduleDescription__inner">
                <Editor
                    editorState={editorState}
                    toolbarClassName="home-toolbar"
                    wrapperClassName="home-wrapper"
                    editorClassName="home-editor"
                    onEditorStateChange={onEditorStateChange}
                    placeholder={t('add-description')}
                    onBlur={onBlurHandler}
                    spellCheck
                    localization={{
                        locale: i18n.language,
                    }}
                    toolbar={{
                        options: [
                            'fontFamily',
                            'inline',
                            'fontSize',
                            'list',
                            'textAlign',
                            'colorPicker',
                            'emoji',
                            'remove',
                            'history',
                        ],
                        fontSize: {
                            options: [
                                8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48,
                                60, 72, 96,
                            ],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                        },
                        fontFamily: {
                            options: [
                                'Arial',
                                'Georgia',
                                'Impact',
                                'Tahoma',
                                'Times New Roman',
                                'Verdana',
                                'Gowun Dodum',
                                'Montserrat',
                            ],
                            className: 'home-fontFamily',
                            component: undefined,
                            dropdownClassName: undefined,
                        },
                    }}
                />
            </div>
        </div>
    )
}
