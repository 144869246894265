import { instance } from '../app/api'
import { AxiosResponse } from 'axios'
import { MeasurementGroupResponse } from '../app/types/response'
import { ACCESS_TOKEN } from '../utils/constants'
import { IPad } from '../app/types/models/logging'

export const chemicalStudyGroupService = {
    getChemicalGroup: async (projectId: number, coreId: number) => {
        return await instance
            .get<any, AxiosResponse<MeasurementGroupResponse>>(
                `projects/${projectId}/wells/core/${coreId}/measurements/chemical-group/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    createChemicalGroup: async (
        projectId: number,
        coreId: number,
        data: { core: number },
    ) => {
        return await instance.post<
            { projectId: number; coreId: number; data: { core: number } },
            AxiosResponse<MeasurementGroupResponse>
        >(
            `projects/${projectId}/wells/core/${coreId}/measurements/chemical-group/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },
    createPyrolysisFile: async (projectId: number, data: FormData) => {
        return await instance
            .post<{ projectId: number; data: FormData }, AxiosResponse<IPad[]>>(
                `projects/${projectId}/measurements/pyrolysis-file/`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    updatePyrolysisFile: async (projectId: number,fileId:number, data: FormData) => {
        return await instance
            .patch<{ projectId: number; data: FormData }, AxiosResponse<IPad[]>>(
                `projects/${projectId}/measurements/pyrolysis-file/${fileId}/`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    getPyrolysisFiles: async (projectId: number,params?:{
        well?:number,wellbore?:number
    }) => {
        return await instance
            .get<
                { projectId: number; coreId: number; groupId: number },
                AxiosResponse<any>
            >(`projects/${projectId}/measurements/pyrolysis-file/`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },params
            })
            .then((res) => res.data)
    },
    deletePyrolysisfile: async (projectId: number, fileId: number) => {
        return await instance.delete<
            { projectId: number; fileId: number },
            AxiosResponse<any>
        >(`projects/${projectId}/measurements/pyrolysis-file/${fileId}/`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        })
    },
}
