export const updateFieldError = (formInstance, t) => {
    const errorFields = formInstance
        .getFieldsError()
        .filter((field) => field.errors.length)
    console.log(errorFields)
    errorFields.forEach((field) => {
        formInstance.setFields([
            {
                name: field.name[0],
                errors: [t(`${field.name[0]}`)],
            },
        ])
        if (field.name[0] === 'password1' || field.name[0] === 'password2') {
            formInstance.setFields([
                {
                    name: field.name[0],
                    errors: [t(`password-must-has-more-than-value-symbols`,{value:8})],
                },
            ])
        }
        if (
            field.errors[0] === 'Два введенных вами пароля не совпадают!' ||
            field.errors[0] ===
                'The two passwords that you entered do not match!'
        ) {
            formInstance.setFields([
                {
                    name: 'password2',
                    errors: [t(`both-passwords-dont-match`)],
                },
            ])
        }
        if (
            field.errors[0] === 'Введите действительный адрес почты и пароль' ||
            field.errors[0] === 'Enter a valid email and password'
        ) {
            formInstance.setFields([
                {
                    name: 'email',
                    errors: [t(`enter-real-email-and-password`)],
                },
            ])
        }
    })
}
