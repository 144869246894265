import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userService } from '../../services/user-service'
import { toast } from 'react-toastify'
import './langPanel.css'
import { BallIcon } from '../../image_files/icons/BallIcon'
import React from 'react'
import { userActions, userSelectors } from '../../store/user'
import {LanguageTypes} from "../../app/types/enums";
import { useTranslation } from 'react-i18next'

const exceptionList = [
    'langPanel-btn',
    'langPanel__select-option',
    'langPanel',
    'langPanel__img',
]

const LangPanel: FC<ILangPanelProps> = ({
    containerClassName,
    topClassName,
    noPhone,
    signMode,
}) => {
    const [collapsed, setCollapsed] = useState(false)
    const { i18n } = useTranslation()
    const [currentValue, setCurrentValue] = useState(i18n.language)
    const user = useSelector(userSelectors.selectUser)
    const dispatch = useDispatch()
    const { setUser, setLanguage } = userActions

    const setCurrentValueHandler = (value) => {
        if (!signMode) {
            userService
                .updateUserSettingsData({ language: value })
                .then((res) => {
                    dispatch(setUser({ user: {...user,settings:{
                        ...user.settings,language:res.language
                    }} }))
                    setCurrentValue(value)
                    i18n.changeLanguage(value)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            setCurrentValue(value)
            i18n.changeLanguage(value)
            dispatch(setLanguage({ language: value }))
        }

        setCollapsed(false)
    }
    const clickHandler = (e) => {
        if (exceptionList.indexOf(e.target.className) === -1) {
            setCollapsed(false)
        }
    }
    useEffect(() => {
        addEventListener('mousedown', clickHandler, false)
        return () => {
            removeEventListener('mousedown', clickHandler, false)
        }
    }, [])

    useEffect(() => {
        if (!signMode) {
            i18n.changeLanguage(user?.settings?.language)
                .then((res) => res)
                .catch((err) => {
                    console.log(err)
                    toast.error(err.data.detail, { position: 'top-center' })
                })
        }
    }, [user])

    return (
        <div
            className={
                containerClassName
                    ? `langPanel ${containerClassName}`
                    : 'langPanel'
            }
        >
            <div
                className={
                    topClassName
                        ? `langPanel__top ${topClassName}`
                        : 'langPanel__top'
                }
                onClick={() => setCollapsed(true)}
                style={noPhone ? { background: 'transparent' } : {}}
            >
                <BallIcon />
                <button
                    className={'langPanel-btn'}
                    onClick={(e) => {
                        setCollapsed(true)
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                >
                    {i18n.language === LanguageTypes.ru ? 'РУ' : 'EN'}
                </button>
            </div>
            <div
                className={'langPanel__bottom'}
                style={collapsed ? {} : { visibility: 'hidden' }}
            >
                <div
                    className="langPanel__select"
                    style={
                        collapsed
                            ? { transform: 'translateY(0)' }
                            : {
                                  transform: 'translateY(-105%)',
                                  zIndex: -1,
                              }
                    }
                >
                    <div
                        className="langPanel__select-option"
                        onClick={() => setCurrentValueHandler('ru')}
                        key={'ru'}
                        style={
                            currentValue === 'ru'
                                ? { background: 'lightgray' }
                                : {}
                        }
                    >
                        Русский
                    </div>
                    <div
                        className="langPanel__select-option"
                        onClick={() => setCurrentValueHandler('en')}
                        key={'en'}
                        style={
                            currentValue === 'en'
                                ? { background: 'lightgray' }
                                : {}
                        }
                    >
                        English
                    </div>
                </div>
            </div>
        </div>
    )
}

export { LangPanel }
