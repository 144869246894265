export const formatResultSum = (
    sourceValue: string | number | undefined,
    scale?: number
) => {
    scale = scale !== undefined ? scale : 2;
    let baseNumber: string | undefined = sourceValue?.toString();

    if (baseNumber) {
        baseNumber = baseNumber.replace(/ /g, '');
        const splitResult = baseNumber.split('.');
        if (splitResult && Array.isArray(splitResult) && splitResult.length > 0) {
            if (isNaN(+splitResult[0])) {
                return '';
            } else {
                if (splitResult[0].length > 3) {
                    splitResult[0] = splitResult[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                }
                baseNumber = splitResult[0];
                if (splitResult.length > 1) {
                    baseNumber += addFloat(splitResult[1], scale);
                } else {
                    // baseNumber += `.${'0'.repeat(scale)}`;
                }
                return baseNumber;
            }
        } else {
            return '';
        }
    } else {
        return '';
    }
};

const addFloat = (float: string, scale?: number) => {
    let result = '';
    scale = scale !== undefined ? scale : 2;
    if (float.length === 0) {
        result = `.${'0'.repeat(scale)}`;
    } else {
        if(scale>float.length){
            result = '.' + float + '0'.repeat(scale - float.length);
        }else{
            result='.'+float.slice(0,scale)
        }
        
    }
    return result;
};