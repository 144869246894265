import React, {FC} from 'react'
import {IIconProps} from "../../app/types/i-props";
import {getValidText} from "../../utils/helpers/getValidText";

export const AddGroupIcon: FC<IIconProps> = ({onClick,className,fill='#2B506B'}) => {
    return (
        <svg width="15"
             height="41"
             viewBox="0 0 15 41"
             fill="none" xmlns="http://www.w3.org/2000/svg"
             className={`${getValidText(className)}`}
             onClick={onClick ? onClick : ()=>{}}
        >
            <path
                d="M14.6725 19.9725C14.8731 20.2956 14.8731 20.7044 14.6725 21.0275L2.59955 40.471C2.06876 41.3258 0.75 40.9497 0.75 39.9435L0.750002 1.05655C0.750002 0.050321 2.06876 -0.325804 2.59955 0.529036L14.6725 19.9725Z"
                fill={fill} fillOpacity="0.6"/>
        </svg>
    )
}
