export enum SettingTypes{
    email_checked='email_checked',
    inLog_checked='inLog_checked',
}

export interface  ISettingRow{
    id:number
    title:string
    value:string
    email_checked?:boolean
    inLog_checked?:boolean
}
