import React from 'react'

export const DescriptionArrowIcon = () => {
    return (
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.65 7C0.65 7.1933 0.8067 7.35 1 7.35L4.15 7.35C4.3433 7.35 4.5 7.1933 4.5 7C4.5 6.8067 4.3433 6.65 4.15 6.65H1.35V3.85C1.35 3.6567 1.1933 3.5 1 3.5C0.8067 3.5 0.65 3.6567 0.65 3.85L0.65 7ZM6.75251 0.752513L0.752512 6.75251L1.24749 7.24749L7.24749 1.24749L6.75251 0.752513Z" fill="#2B506B"/>
        </svg>

    )
}
