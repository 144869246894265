export const getAllElemIndexes = (item: string, array: string[] | number[]) => {
    const indices = array.reduce((acc, value, index) => {
        if (value === item) {
            acc.push(index)
        }
        return acc
    }, [])
    return indices
}

export const quarters = [
    {
        quarter: 0,
        monthes: [0, 1, 2],
    },
    {
        quarter: 1,
        monthes: [3, 4, 5],
    },
    {
        quarter: 2,
        monthes: [6, 7, 8],
    },
    {
        quarter: 3,
        monthes: [9, 10, 11],
    },
]

export const firstMonthInquarterIndexes = [0, 3, 6, 9]
export const secondMonthInquarterIndexes = [1, 4, 7, 10]
export const thirdMonthInquarterIndexes = [2, 5, 8, 11]

export function getQuarterDays(year: number, quarter: number) {
    const monthsInQuarter = {
        1: [0, 1, 2], // Январь, Февраль, Март
        2: [3, 4, 5], // Апрель, Май, Июнь
        3: [6, 7, 8], // Июль, Август, Сентябрь
        4: [9, 10, 11], // Октябрь, Ноябрь, Декабрь
    }

    // Проверка на правильность ввода номера квартала
    if (quarter < 1 || quarter > 4) {
        throw new Error('Номер квартала должен быть в пределах от 1 до 4.')
    }

    const daysInMonths = monthsInQuarter[quarter].map((month) => {
        return new Date(year, month + 1, 0).getDate()
    })

    return daysInMonths
}

export const getIsHasMonthInQuarter = (quarter: number, month: number) => {
    const targerQuarter = quarters.find((el) => el.monthes.includes(month))

    if (targerQuarter && targerQuarter?.quarter !== undefined) {
        return targerQuarter.quarter === quarter
    } else return false
}

type Statuses = 'empty' | 'full' | 'without-start' | 'without-end'

export const generateQuarterInterval = (data: {
    year: number | null
    quarter: number | null
    startMonth: number | null
    endMonth: number | null
    startDay: number | null
    endDay: number | null
    status?: Statuses
}) => {
    let { startDay, endDay, year, quarter, startMonth, endMonth, status } = data
    const monthDays = getQuarterDays(year, quarter + 1)
    let daysList: string[] = []
    let startDayIndex: string | number = 0,
        endDayIndex: string | number = 0
 
    let startPercent = 0,
        endPercent = 0

    if (status !== 'empty' && status !== 'full') {
        monthDays.forEach((month) => {
            const days = new Array(month).fill('').map((c, i) => `${i + 1}`)
            daysList = [...daysList, ...days]
        })
    }

    startMonth = getIsHasMonthInQuarter(quarter, data.startMonth)
        ? data.startMonth
        : null
    endMonth = getIsHasMonthInQuarter(quarter, data.endMonth)
        ? data.endMonth
        : null

    startDay = startMonth === null ? null : data.startDay
    endDay = endMonth === null ? null : data.endDay

    const startDaysIndexes =
        startDay === null
            ? []
            : getAllElemIndexes(startDay.toString(), daysList)
    const endDaysIndexes =
        endDay === null ? [] : getAllElemIndexes(endDay.toString(), daysList)

    if (status === 'empty') {
        ;(startPercent = 0), (endPercent = 0)
    } else if (status === 'full') {
        ;(startPercent = 0), (endPercent = 100)
    } else if (startMonth === null && endMonth !== null) {
        if (firstMonthInquarterIndexes.includes(endMonth)) {
            endDayIndex = endDaysIndexes[0]
        } else if (secondMonthInquarterIndexes.includes(endMonth)) {
            endDayIndex =
                endDaysIndexes.length === 3 || endDaysIndexes.length === 2
                    ? endDaysIndexes[1]
                    : endDaysIndexes[0]
        } else {
            endDayIndex = endDaysIndexes[endDaysIndexes.length - 1]
        }
        startPercent = 0
        endPercent = ((Number(endDayIndex) + 1) / daysList.length) * 100
    } else if (endMonth === null && startMonth !== null) {
        if (firstMonthInquarterIndexes.includes(startMonth)) {
            startDayIndex = startDaysIndexes[0]
        } else if (secondMonthInquarterIndexes.includes(startMonth)) {
            startDayIndex =
                startDaysIndexes.length === 3 || startDaysIndexes.length === 2
                    ? startDaysIndexes[1]
                    : startDaysIndexes[0]
        } else {
            startDayIndex = startDaysIndexes[startDaysIndexes.length - 1]
        }
        endPercent = 100
        startPercent = ((Number(startDayIndex) + 1) / daysList.length) * 100
    } else if (startMonth === null && endMonth === null) {
        const monthes = quarters.find((q) => q.quarter === quarter)
        if (monthes) {
            if (monthes.monthes[2] < data.startMonth) {
                ;(startPercent = 0), (endPercent = 0)
            } else if (
                monthes.monthes[0] > data.startMonth &&
                monthes.monthes[2] < data.endMonth
            ) {
                ;(startPercent = 0), (endPercent = 100)
            } else {
                ;(startPercent = 0), (endPercent = 0)
            }
        } else {
            ;(startPercent = 0), (endPercent = 0)
        }
    } else {
        if (startMonth === endMonth) {
            if (
                firstMonthInquarterIndexes.includes(startMonth) ||
                secondMonthInquarterIndexes.includes(startMonth)
            ) {
                startDayIndex = startDaysIndexes[0]
                endDayIndex = endDaysIndexes[0]
            } else {
                startDayIndex =
                    startDaysIndexes.length === 3 ||
                    startDaysIndexes.length === 2
                        ? startDaysIndexes[1]
                        : startDaysIndexes[0]
                endDayIndex =
                    endDaysIndexes.length === 3 || endDaysIndexes.length === 2
                        ? endDaysIndexes[1]
                        : endDaysIndexes[0]
            }
        } else {
            if (firstMonthInquarterIndexes.includes(startMonth)) {
                startDayIndex = startDaysIndexes[0]

                if (endMonth - startMonth === 2) {
                    endDayIndex = endDaysIndexes[endDaysIndexes.length - 1]
                } else {
                    endDayIndex =
                        endDaysIndexes.length === 3 ||
                        endDaysIndexes.length === 2
                            ? endDaysIndexes[1]
                            : endDaysIndexes[0]
                }
            } else {
                startDayIndex =
                    startDaysIndexes.length === 3 ||
                    startDaysIndexes.length === 2
                        ? startDaysIndexes[1]
                        : startDaysIndexes[0]

                endDayIndex = endDaysIndexes[endDaysIndexes.length - 1]
            }
        }

        startPercent = ((Number(startDayIndex) + 1) / daysList.length) * 100
        endPercent = ((Number(endDayIndex) + 1) / daysList.length) * 100
    }
    return { startPercent, endPercent }
}

