export const addColorForEachMember = (members: any[]) => {
    return members.map((m) => {
        return {
            ...m,
            color:
                '#' +
                (Math.random().toString(16) + '000000')
                    .substring(2, 8)
                    .toUpperCase(),
        }
    })
}
