import React from 'react'

export const SuperVisorIcon = () => {
    return (
        <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="29" height="29" rx="1" fill="white" />
            <path
                d="M7 6.5H22C22.8284 6.5 23.5 7.17157 23.5 8V23C23.5 23.8284 22.8284 24.5 22 24.5H7C6.17157 24.5 5.5 23.8284 5.5 23V8C5.5 7.17157 6.17157 6.5 7 6.5Z"
                fill="#B7B6FF"
                stroke="#B7B6FF"
            />
            <circle cx="14.5" cy="11.5" r="4" fill="white" stroke="white" />
            <path
                d="M8.11449 18.0358L7.6 18.7143C6.45906 20.5949 7.81303 23 10.0127 23H13.2538C15.3005 23 17.1062 21.6608 17.7003 19.7022L17.9467 18.8899C17.982 18.7735 18 18.6524 18 18.5307C18 17.7429 17.2736 17.1557 16.5032 17.3207L16.1578 17.3948C16.0529 17.4172 15.9459 17.4286 15.8386 17.4286H14.5L10.2 17C9.38106 17 8.60932 17.3833 8.11449 18.0358Z"
                fill="white"
                stroke="white"
            />
            <circle
                cx="21.1055"
                cy="16.1056"
                r="1.88314"
                transform="rotate(-19.4881 21.1055 16.1056)"
                fill="#B7B6FF"
                stroke="white"
                strokeWidth="1.1"
            />
            <line
                x1="20.5821"
                y1="18.1455"
                x2="19.5821"
                y2="22.1455"
                stroke="white"
                strokeWidth="1.2"
            />
        </svg>
    )
}
