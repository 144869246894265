import '../../pages/home/home.css'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import './settings.css';
import {IControlNavButton} from "./types";
import {useLocation, useNavigate} from "react-router-dom";
import {routes} from "../../utils/constants";
import SettingsRoutes from "./settings-routes";

export const Settings = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const showStylesByParam = (param: string) => location?.pathname?.split('/').at(-1) === param ? 'settings-nav-btn--active' : ''

    const controlNavButtons:IControlNavButton[] = [
        {
            id:1,
            title:t('user-profile'),
            onClick:()=>navigate(routes.SETTINGS_PROFILE),
            className:`settings-nav-btn font-14-normal cursor-pointer ${showStylesByParam(routes.SETTINGS_PROFILE)}`
        },
        {
            id:2,
            title:t('change-password'),
            onClick:()=>navigate(routes.SETTINGS_CHANGE_PASSWORD),
            className:`settings-nav-btn font-14-normal cursor-pointer ${showStylesByParam(routes.SETTINGS_CHANGE_PASSWORD)}`
        },
        {
            id:3,
            title:t('notifications'),
            onClick:()=>navigate(routes.SETTINGS_NOTIFICATIONS),
            className:`settings-nav-btn font-14-normal cursor-pointer ${showStylesByParam(routes.SETTINGS_NOTIFICATIONS)}`
        },
    ]

    console.log(location.pathname.split('/').at(-1))

    useEffect(()=>{
        if(['/settings','/settings/*'].includes(location?.pathname) ){
            navigate('/settings/profile')
        }
    },[location])


    return (
        <div className="settings">
            <div className="settings-btn-list">
                {controlNavButtons.map((item,i)=>(
                    <button key={i} className={item.className} onClick={item.onClick}>
                        {item.title}
                    </button>
                ))}
            </div>
            <SettingsRoutes/>
        </div>
    )
}

