import React from 'react'
import { FC } from 'react'

interface IProps {
    className?: string
    circle?: boolean
    fill?: string
    width?: number
    height?: number
    viewBox?: string
    deleteMode?: boolean
    circleFill?: string

    onClick?: () => void
}

export const PlusIcon: FC<IProps> = ({
    className,
    onClick,
    circle = true,
    fill = 'transparent',
    width = 22,
    height = 22,
    viewBox = '0 0  22 22',
    deleteMode = false,
}) => {
    return (
        <svg
            className={className ? className : ''}
            onClick={onClick ? onClick : () => {}}
            width={width}
            height={height}
            viewBox={viewBox}
            fill={deleteMode ? '#c45d5d' : 'none'}
            xmlns="http://www.w3.org/2000/svg"
        >
            {circle && (
                <circle
                    cx="11"
                    cy="11"
                    r="10.5"
                    fill={deleteMode ? '#c45d5d' : fill}
                    stroke="#2B506B"
                />
            )}
            {deleteMode ? (
                ''
            ) : (
                <line x1="11.5" y1="7" x2="11.5" y2="16" stroke="#2B506B" />
            )}
            <line x1="7" y1="11.5" x2="16" y2="11.5" stroke="#2B506B" />
        </svg>
    )
}
