export const getFileBlockList = (t: Function) => (
    [
        {
            label: t('file-name')
        },
        {
            label: t('size')
        },
        {
            label: t('date-added')
        },
        {
            label: t('type')
        },
        {
            label: t('quality')
        },
    ]
)