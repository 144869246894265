import React, { FC, useContext } from 'react'
import { IIconProps } from '../../app/types/i-props'

const ServiceIcon: FC<IIconProps> = ({
    fill,
    width,
    height,
    viewBox,
    onClick,
}) => {
    return (
        <svg
            width={width ? width : 20}
            height={height ? height : 20}
            viewBox={viewBox ? viewBox : '0 0 12 11'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path
                d="M5.19917 2.43439C2.87995 2.43439 1 4.31463 1 6.63415M6.80059 8.57878C9.1198 8.57878 11 6.69854 11 4.37927M2.93166 6.30049C2.93166 8.62 4.81161 10.5 7.13107 10.5M9.07566 4.69951C9.07566 2.38 7.1957 0.5 4.87649 0.5"
                stroke={fill ? fill : '#364f6b'}
                stroke-width="1"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default ServiceIcon
