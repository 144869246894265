import React, { FC } from 'react'

interface IProps {
    style?: object
    className?: string
}

export const LightIcon: FC<IProps> = ({ style, className }) => {
    return (
        <svg
            className={className ? className : ''}
            style={style ? style : {}}
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="29" height="29" rx="1" fill="white" />
            <path
                d="M12.25 26C12.25 25.5858 12.5858 25.25 13 25.25H17C17.4142 25.25 17.75 25.5858 17.75 26C17.75 26.4142 17.4142 26.75 17 26.75H13C12.5858 26.75 12.25 26.4142 12.25 26Z"
                fill="#B2AFAF"
                fillOpacity="0.3"
                stroke="#B2AFAF"
                strokeWidth="0.5"
            />
            <path
                d="M11.25 23C11.25 22.5858 11.5858 22.25 12 22.25H18C18.4142 22.25 18.75 22.5858 18.75 23C18.75 23.4142 18.4142 23.75 18 23.75H12C11.5858 23.75 11.25 23.4142 11.25 23Z"
                fill="#B2AFAF"
                fillOpacity="0.3"
                stroke="#B2AFAF"
                strokeWidth="0.5"
            />
            <path
                d="M17.7722 21.5L12.2234 21.5C11.3673 21.5 10.6733 20.806 10.6734 19.9499L10.6732 19.8516C10.6722 19.1968 10.552 18.5477 10.3185 17.936C10.1232 17.4246 9.85116 16.9459 9.51176 16.5164L9.0724 15.9603L8.88672 15.7744C8.4637 15.3507 8.10993 14.8631 7.83839 14.3296C7.65483 13.9689 7.51013 13.5897 7.40674 13.1984L7.33265 12.918C7.13796 12.1812 7.04407 11.4214 7.05355 10.6594L7.05486 10.5542C7.06478 9.72945 7.26432 8.91809 7.63803 8.18284L7.92479 7.61868L8.14651 7.28885C8.56379 6.66811 9.05056 6.09703 9.59735 5.58668L9.78333 5.41309C10.3583 4.87648 11.0083 4.42652 11.713 4.07739L11.9606 3.95475C12.3593 3.75723 12.7796 3.60682 13.2131 3.50656C13.6133 3.414 14.0222 3.36474 14.4329 3.35962L15.2975 3.34885C15.6986 3.34385 16.0995 3.37219 16.496 3.43358L16.6462 3.45685C17.7936 3.63453 18.8748 4.10869 19.7827 4.8324C20.2333 5.19158 20.6355 5.60764 20.9792 6.07018L21.2955 6.49586C21.5591 6.85072 21.7896 7.22906 21.984 7.62611C22.329 8.33091 22.5568 9.08729 22.6582 9.86544L22.665 9.91712C22.8101 11.0307 22.6958 12.1627 22.3308 13.2247L22.3056 13.2978C22.1163 13.8487 21.8559 14.3725 21.5309 14.8559L21.497 14.9064C21.28 15.2291 21.0325 15.5302 20.7579 15.8055L20.4577 16.1065C20.0925 16.4725 19.8008 16.9129 19.6039 17.391C19.411 17.8596 19.3097 18.3674 19.3104 18.8742L19.3121 19.9629C19.3105 20.8123 18.6215 21.5 17.7722 21.5Z"
                fill="#FFE176"
                stroke="#B2AFAF"
                strokeWidth="0.5"
            />
            <path
                d="M15 4.94902L16.2296 5.3589C16.4096 5.4189 16.5852 5.49161 16.7549 5.57648V5.57648C17.2462 5.82221 17.6819 6.16634 18.0346 6.58749L18.5747 7.2322C18.8576 7.56992 19.1116 7.9308 19.3341 8.31104V8.31104C19.775 9.06441 20.0874 9.88589 20.2586 10.7418L20.5 11.949"
                stroke="white"
                strokeWidth="2"
            />
        </svg>
    )
}
