import { createSlice } from '@reduxjs/toolkit'
import { IResearchEntity } from '../../app/types/models/logging'
import { addEntity, setEntities, updateEntity } from './actions'

export interface InitialState {
    equipments: [] | IResearchEntity[]
    methods: [] | IResearchEntity[]
}

const initialState: InitialState = {
    equipments: [],
    methods: [],
}

export const slice = createSlice({
    name: 'researchEntities',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setEntities, (state, action) => {
            state[action.payload.type] = action.payload.entities
        })
        builder.addCase(addEntity, (state, action) => {
            state[action.payload.type] = [
                action.payload.entity,
                ...state.equipments,
            ]
        })
        builder.addCase(updateEntity, (state, action) => {
            state[action.payload.type] = state.equipments.map((item) =>
                item.id === action.payload.id
                    ? { ...item, name: action.payload.name }
                    : item,
            )
        })
    },
})
