export const addSetting = (
    groupId: number,
    settingsData: any,
    setSettingsData: any,
    defaultSetting: any,
) => {
    if (groupId && settingsData && settingsData.length > 0) {
        if (!Array.isArray(settingsData)) {
            setSettingsData([
                settingsData,
                {
                    ...defaultSetting,
                    id: '',
                    group: groupId,
                },
            ])
        } else {
            setSettingsData([
                ...settingsData,
                {
                    ...defaultSetting,
                    id: `${settingsData.length}/default`,
                    group: groupId,
                },
            ])
        }
    } else {
        setSettingsData(defaultSetting)
    }
}
