import { useSelector } from 'react-redux'
import './checkEmail.css'
import { toast } from 'react-toastify'
import { authService } from '../../services/auth-service'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'
import { getLocalizedText } from '../../utils/helpers/getLocalizedText'
import { routes } from '../../utils/constants'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { authSelectors } from '../../store/auth'
import { userSelectors } from '../../store/user'
import SignHeader from "../../shared/signHeader/SignHeader";
import { showNotify } from '../../utils/helpers/showNotice'

function CheckEmail() {
    const { email } = useSelector(authSelectors.selectPasswordResetData)
    const { t, i18n } = useTranslation()
    const currentLanguage: string = useSelector(userSelectors.selectLanguage)

    const resendEmailAsync = async () => {
        try {
            const response = await authService.resendEmail({ email })
            if (response && response?.detail) {
                showNotify(t('mail-sent-successfully'))
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    useEffect(() => {
        i18n.changeLanguage(currentLanguage)
    }, [currentLanguage])

    return (
        <div className="signBlock">
            <div className="signBlock">
                <SignHeader
                    buttonRoute={{link:routes.LOGIN,name:t('entrance')}}
                />
                <div className="signWrapper checkEmail__wrapper">
                    <h2 className="signForm__header">
                        {getLocalizedText('check-email-please', t)}
                    </h2>
                    <p className="signForm__topContent checkEmail__content">
                        {getLocalizedText('we-send-you', t)}
                        <br />
                        {t('email-with-confirmation')}
                        {email}
                    </p>
                    <div className="checkEmail__btn" onClick={resendEmailAsync}>
                        {getLocalizedText('send-mail-repeatedly', t)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckEmail
