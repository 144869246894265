import React from 'react'
export const getTopFileList = (t: Function) => (
    [
        {
            label: (
                <div>
                    <span>{t('file-name')}</span>
                </div>
            )
        },
        {
            label: t('well-logs-notation')
        },
        {
            label: t('modification-date')
        },
        {
            label: t('who-added')
        },
        {
            label:''
        },
    ]
)