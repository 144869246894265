import React, { useMemo } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { ITask } from "../../../../app/types/models/tasks";
import { tasksService } from "../../../../services/tasks-services";

const TasksBoard = (
    {
        task,
        projectId,
    }:{
        task:ITask,
        projectId:number
    }
) => {
    const location = useLocation()
    const navigate = useNavigate()

    const parentTaskSlug = ():string => {
        return location.pathname.split('/').at(-1).split('_').slice(0,2).join('_')

    }

    const memoizedTaskName = useMemo(() => {
        if(task?.slug && task.slug?.length>0){
            return decodeURIComponent(task?.slug?.split('_').slice(0, 2).join('_'))
        }else return ''
        
    }, [task])


    const onClickChangeCurrentPath = async () => {
        try {
            const taskPath = parentTaskSlug()
            const response = await tasksService.getCurrentTask(projectId, taskPath)
            navigate(`/scheduler/${projectId}/tasks/${response.slug}`)
        } catch (e) {
            console.log(e)
        }

    }

    return (
        <>
            {task?.slug?.split('_')?.length < 3 ? (
                <div> {task?.slug}</div>
            ) : (
                <div>
                    <a onClick={onClickChangeCurrentPath}>
                        {memoizedTaskName}
                    </a>{' > '}
                    <span className="taskEditSpace__slug">
                                {decodeURIComponent(task?.slug)}
                            </span>
                </div>
            )}
        </>
    );
};

export default TasksBoard;