import React from 'react';
import {Route, Routes} from "react-router-dom";
import {routes} from "../../utils/constants";
import UserProfile from "../../features/userProfile/UserProfile";
import PasswordBlock from "../../features/passwordBlock/PasswordBlock";
import Notifications from "../../features/notifications/components/Notifications";

const SettingsRoutes = () => {
    return (
        <Routes>
            <Route index path={routes.SETTINGS_PROFILE} element={<UserProfile />} />
            <Route path={routes.SETTINGS_CHANGE_PASSWORD} element={<PasswordBlock />} />
            <Route path={routes.SETTINGS_NOTIFICATIONS} element={<Notifications/>}/>
        </Routes>
    );
};

export default SettingsRoutes;