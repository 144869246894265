import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
  type Crop
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import setCanvasPreview from '../camera-box/services/set-canvas-preview';
import { ICropImageProps } from './types';
import React from 'react';
import useDebounce from '../../hooks/useDebounce';

const ASPECT_RATIO = 1;

const CropImage: FC<ICropImageProps> = (props) => {
  const { url, disableCropImage, setUrl } = props;

  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop | undefined>({
    unit: '%',
    x: 0,
    y: 0,
    width: 300,
    height: 300
  });
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const debouncedCrop = useDebounce(crop, 500);

  const onImageLoad = (e: ChangeEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;

    const targetCrop = makeAspectCrop(
      {
        unit: '%',
        width: 100,
        height: 100
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(targetCrop, width, height);
    setCrop(centeredCrop);
  };

  useEffect(() => {
    if (
      imgRef &&
      previewCanvasRef &&
      previewCanvasRef?.current &&
      imgRef?.current &&
      crop
    ) {
      setCanvasPreview(
        imgRef.current, // HTMLImageElement
        previewCanvasRef.current, // HTMLCanvasElement
        convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
      );
      const dataUrl = previewCanvasRef.current.toDataURL();

      if (dataUrl) {
        setUrl(dataUrl);
      }
    }
  }, [debouncedCrop]);

  //if disableCropImage=true, we need to send the same image back
  useEffect(() => {
    url && setUrl(url);
  }, [url]);

  return (
    <>
      {!disableCropImage ? (
        <ReactCrop
          crop={crop}
          // minHeight={200}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          circularCrop
          // keepSelection
          ruleOfThirds
          // aspect={ASPECT_RATIO}
          // minWidth={MIN_DIMENSION}
        >
          <img
            ref={imgRef}
            src={url}
            alt="profile photo"
            className="webcam-camera-img"
            onLoad={onImageLoad}
          />
        </ReactCrop>
      ) : (
        <img className="webcam-camera-img" src={url} alt="profile photo" />
      )}

      {crop && (
        <canvas
          ref={previewCanvasRef}
          style={{
            display: 'none',
            border: '1px solid black',
            objectFit: 'contain',
            width: 150,
            height: 150
          }}
        />
      )}
    </>
  );
};

export default CropImage;
