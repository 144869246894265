import React from 'react'

const CoreStudyIcon4 = ({ className }) => {
    return (
        <svg
            className={className ? className : ''}
            width="45"
            height="45"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width="39"
                height="39"
                rx="4.5"
                fill="#FDFDFD"
            />
            <path
                d="M37.3782 19.3567C37.729 20.5619 36.8958 21.7886 35.6462 21.9067L31.3759 22.3105C30.7177 22.3727 30.0712 22.1056 29.6488 21.5969L26.7756 18.1358C26.3125 17.5779 26.1875 16.8132 26.449 16.137L27.9154 12.3448C28.2981 11.355 29.3871 10.8365 30.3967 11.1633L34.4632 12.4795C35.0938 12.6837 35.5823 13.187 35.7676 13.8234L37.3782 19.3567Z"
                fill="#FEC580"
                fillOpacity="0.6"
            />
            <path
                d="M37.1862 19.4126C37.5019 20.4973 36.752 21.6013 35.6273 21.7076L31.3571 22.1114C30.7647 22.1674 30.1828 21.927 29.8027 21.4691L26.9295 18.008C26.5127 17.506 26.4002 16.8177 26.6356 16.2091L28.1019 12.4169C28.4464 11.5261 29.4265 11.0595 30.3351 11.3536L34.4016 12.6698C34.9692 12.8535 35.4088 13.3065 35.5756 13.8793L37.1862 19.4126Z"
                stroke="#364F6B"
                strokeOpacity="0.6"
                strokeWidth="0.4"
            />
            <path
                d="M8.65945 13.2309C8.25963 13.3813 7.82215 13.3997 7.41109 13.2835L5.3833 12.7102C4.34916 12.4178 3.73096 11.3604 3.98341 10.3158L4.88884 6.56929C5.04668 5.91619 5.52191 5.38599 6.1539 5.15789L7.82113 4.55614C8.82669 4.19321 9.93988 4.68523 10.3484 5.67317L11.2856 7.93982C11.3857 8.18202 11.4373 8.44155 11.4373 8.70365L11.4377 10.8009C11.4378 11.6339 10.9216 12.3799 10.1419 12.6732L8.65945 13.2309Z"
                fill="#FEC580"
                fillOpacity="0.6"
            />
            <path
                d="M8.58903 13.0437C8.22919 13.1791 7.83546 13.1957 7.4655 13.0911L5.43772 12.5177C4.50699 12.2546 3.9506 11.3029 4.17781 10.3628L5.08325 6.61627C5.2253 6.02848 5.653 5.5513 6.2218 5.34601L7.88903 4.74426C8.79403 4.41763 9.7959 4.86045 10.1635 5.74959L11.1007 8.01624C11.1909 8.23422 11.2373 8.4678 11.2373 8.70368L11.2377 10.8009C11.2378 11.5507 10.7732 12.222 10.0715 12.486L8.58903 13.0437Z"
                stroke="#364F6B"
                strokeOpacity="0.6"
                strokeWidth="0.4"
            />
            <path
                d="M33.3959 32.6476L33.3957 32.6478L33.4027 32.6553C33.839 33.1168 33.5098 33.8762 32.8747 33.8734L9.0964 33.7669C8.51085 33.7643 8.22448 33.0517 8.64537 32.6446L8.65293 32.6373L8.65968 32.6292L18.4174 20.9725C18.5982 20.7565 18.6973 20.4837 18.6972 20.202L18.6953 10.5986C18.6952 10.3614 18.6249 10.1295 18.4931 9.93222L17.7004 8.74579C17.3452 8.21414 17.7264 7.50124 18.3658 7.50137L23.3109 7.50232C23.918 7.50244 24.3037 8.15218 24.0132 8.68522L23.3091 9.9768C23.213 10.1531 23.1627 10.3506 23.1627 10.5514L23.1647 20.1983C23.1647 20.4835 23.2663 20.7593 23.4513 20.9763L33.3959 32.6476Z"
                fill="#F1FCFF"
                stroke="#364F6B"
                strokeWidth="0.4"
            />
            <path
                d="M20.2249 20.951C20.625 20.4601 21.375 20.4601 21.7751 20.951L30.0623 31.1182C30.5947 31.7715 30.1299 32.75 29.2871 32.75H12.7129C11.8701 32.75 11.4053 31.7715 11.9377 31.1182L20.2249 20.951Z"
                fill="#364F6B"
                fillOpacity="0.6"
            />
            <circle cx="23" cy="4" r="2" fill="#364F6B" fillOpacity="0.6" />
            <circle cx="20" cy="9" r="1" fill="#364F6B" fillOpacity="0.6" />
            <circle cx="21" cy="13" r="1" fill="#364F6B" fillOpacity="0.6" />
        </svg>
    )
}

export default CoreStudyIcon4
