import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { routes } from '../../utils/constants'
import { userService } from '../../services/user-service'
import { SocialNames } from '../../app/types/enums'
import {useTranslation} from "react-i18next";

function VerifyMicrosoftAccount() {
    const [url] = useState(window.location.href)
    const urlCode = url.slice(url.indexOf('=') + 1, url.length)
    const [active, setActive] = useState(false)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const verifyAsync = useCallback(async () => {
        try {
            const response = await userService.socialLogin(
                {
                    code: urlCode,
                },
                SocialNames.microsoft,
            )
            if (response.user) {
                localStorage.setItem('token', response.access_token)
                setActive(true)
            }
        } catch (e) {
            errorsHandler(e,t)
        }
    }, [urlCode])

    useEffect(() => {
        verifyAsync().then()
    }, [urlCode])

    active && navigate(routes.HOME)
    return <div className="VerifyMicrosoftAccount"></div>
}

export default VerifyMicrosoftAccount
