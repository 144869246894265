import { Button, ButtonProps } from "antd";
import React from "react";
import { getValidText } from "../../../utils/helpers/getValidText";
import './UiButton.css';

type IProps =ButtonProps & {};

const UiButton = (props:IProps) =>{
    return (
        <Button {...props} className={`ui-button ${getValidText(props?.className)}`}  />
    )
}

export default UiButton;