import React, { FC } from 'react'
import { DroppableProvided } from 'react-beautiful-dnd'
import { Panel } from '../panel/Panel'

interface IProps {
    provided: DroppableProvided
    panels: any
    projectId: number

    setCurrentSlug: (value: string) => void
}

const PanelsList: FC<IProps> = (props) => {
    const { provided, panels, projectId, setCurrentSlug } = props
    return (
        <div
            className="statuses__list-inner"
            {...provided.droppableProps}
            ref={provided.innerRef}
        >
            {panels.panels &&
                panels?.panelsOrder?.map((order, i) => {
                    const panel = panels.panels[order]
                    const tasks = []
                    panel?.tasksOrder?.length > 0 &&
                        panel?.tasksOrder?.forEach((taskOrder) => {
                            const targetTask = panel?.tasks?.find(
                                (task) => task?.status_position === taskOrder,
                            )
                            tasks?.push({
                                ...targetTask,
                            })
                        })
                    return (
                        <Panel
                            key={panel?.position}
                            panel={panel}
                            tasks={tasks}
                            index={i}
                            setCurrentSlug={setCurrentSlug}
                            currentProjectId={projectId}
                            panels={panels?.panels}
                            panelsOrder={panels?.panelsOrder}
                        />
                    )
                })}
            {provided.placeholder}
        </div>
    )
}

export default PanelsList
