import React, { FC, ReactNode, useEffect, useState, useTransition } from 'react'
import { useNavigate } from 'react-router'
import {
    IExtendedProject,
    IProject,
} from '../../../../app/types/models/project'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { deleteCurrentCore, deleteCurrentPad, deleteCurrentWell, deleteCurrentWellBore, setProject } from '../../../../store/currentProject/actions'
import {
    ICore,
    IExtendedWell,
    IPad,
    IWellBore,
} from '../../../../app/types/models/logging'
import { DeleteIcon } from '../../../../image_files/icons/DeleteIcon'
import { createPortal } from 'react-dom'
import { DeleteModal } from '../../../../shared/DeleteModal/DeleteModal'
import { projectsService } from '../../../../services/projects-service.'
import { deletePad, deleteProject, deleteWell, deleteWellBore, deleteWellBoreCore, deleteWellCore, setProjects } from '../../../../store/projects/actions'
import { showNotice } from '../../../../utils/helpers/showNotice'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../../store/currentProject/selectors'
import { selectProjects } from '../../../../store/projects/selectors'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { getValidText } from '../../../../utils/helpers/getValidText'
import { wellsService } from '../../../../services/wells-service'
import { wellBoreService } from '../../../../services/wellBore-service'

interface IProps {
    name: string
    project?: IProject
    leftIcon?: ReactNode
    pad?: IPad
    well?: IExtendedWell
    wellbore?: IWellBore
    core?: ICore
    type:
        | 'project'
        | 'pad'
        | 'well'
        | 'wellbore'
        | 'wellCore'
        | 'wellboreCore'
        | 'core'
    className?:string;
    isLoading?:boolean
}

const initialModalData = {
    isOpen: false,
    title: '',
}

const ProjectItemContent: FC<IProps> = (props) => {
    const { project, pad, well, wellbore, core, leftIcon, type, name,className,isLoading:isLoadingFetchedData } = props

    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const projects = useSelector(selectProjects)
    const [isLoading, setIsLoading] = useState(false)
    const [modalData, setModalData] = useState(initialModalData)
    const currentProject = useSelector(selectCurrentProject)

    const handleSelectCurrentProject = async (): Promise<void> => {
        if (type === 'project') {
            navigate(`${project?.id}`)
            dispatch(setProject({ project }))
        }
    }

    const handleDeleteProject = async (): Promise<void> => {
        try {
            setIsLoading(true)
            await projectsService.removeProject(project.id)
            dispatch(deleteProject({ id: project.id }))
            showNotice(t('profile-removed-success'), t)
            setIsLoading(false)
            setModalData(initialModalData)

            if (currentProject?.id === project.id) {
                const filteredProjects = projects.filter(
                    (item) => item.id !== project.id,
                )
                if (filteredProjects.length > 0) {
                    dispatch(setProject({ project: filteredProjects[0] }))
                    navigate(`/logging/${filteredProjects[0]?.id}`)
                } else {
                    dispatch(setProjects({ projects: [] }))
                    dispatch(setProject({ project: {} as IExtendedProject }))
                    navigate(`/logging/new`)
                }
            }
        } catch (e) {
            setIsLoading(false)
            setModalData(initialModalData)
            errorsHandler(e, t)
        }
    }

    const handleDeletePad = async (): Promise<void> => {
        try {
            setIsLoading(true)
            await projectsService.deletePad(project?.id, pad.id)
            dispatch(deletePad({ id: project?.id, padId: pad.id }))
            dispatch(deleteCurrentPad({id:pad?.id}))
            setIsLoading(false)
            setModalData(initialModalData)
            
        } catch (e) {
            setIsLoading(false)
            setModalData(initialModalData)
            errorsHandler(e, t)
        }
    }

    const handleDeleteWell = async (): Promise<void> => {
        try {
            setIsLoading(true)
            await wellsService.deleteWell(project?.id, well.id)
            dispatch(
                deleteWell({
                    id: project?.id,
                    wellId: well?.id,
                    padId: pad?.id,
                }),
            )
            dispatch(deleteCurrentWell({id:well?.id}))
            setIsLoading(false)
            setModalData(initialModalData)

        } catch (e) {
            setIsLoading(false)
            setModalData(initialModalData)
            errorsHandler(e, t)
        }
    }

    const handleDeleteWellBore = async (): Promise<void> => {
        try {
            setIsLoading(true)
            await wellBoreService.deleteWellBore(project?.id, wellbore.id)
            dispatch(
                deleteWellBore({
                    id: project?.id,
                    wellId: well?.id,
                    padId: pad?.id,
                    wellBoreId: wellbore?.id,
                }),
            )
            dispatch(deleteCurrentWellBore({ id: wellbore.id }))
            setIsLoading(false)
            setModalData(initialModalData)
        } catch (e) {
            setIsLoading(false)
            setModalData(initialModalData)
            errorsHandler(e, t)
        }
    }

    const handleDeleteCore = async (): Promise<void> => {
        try {
            setIsLoading(true)
            await projectsService.deleteCore(project?.id, core?.id)
            if(core?.well!==null && core?.well!==undefined && (core?.wellbore===null || core?.wellbore===undefined)){
                dispatch(
                    deleteWellCore({
                        id: project?.id,
                        wellId: core.well,
                        padId: pad?.id,
                        coreId: core.id,
                    }),
                )
            }else{
                dispatch(
                    deleteWellBoreCore({
                        id: project?.id,
                        wellId: core.well,
                        padId: pad?.id,
                        coreId: core.id,
                        wellBoreId: core.wellbore,
                    }),
                )
            }
            dispatch(deleteCurrentCore({ id: core.id }))
    
            setIsLoading(false)
            setModalData(initialModalData)
        } catch (e) {
            setIsLoading(false)
            setModalData(initialModalData)
            errorsHandler(e, t)
        }
    }

    const handleDelete = () => {
        switch (type) {
            case 'project':
                handleDeleteProject()
                break
            case 'pad':
                handleDeletePad()
                break;
            case 'well':
                handleDeleteWell()
                break;
            case 'wellbore':
                handleDeleteWellBore()
                break;
            case 'core':
                handleDeleteCore()
                break;
            default:
                break
        }
    }

    const openModal = () => {
        let element = '',
            name = ''
        switch (type) {
            case 'project':
                element = t('project')
                name = getValidText(project?.name)
                break
            case 'pad':
                element = t('pad')
                name = getValidText(pad?.name)
                break
            case 'well':
                element = t('for-well')
                name = getValidText(well?.name)
                break
            case 'wellbore':
                element = t('wellbore')
                name = getValidText(wellbore?.name)
                break
            case 'core':
                element = t('core')
                name = getValidText(core?.name)
                break
            default:
                break
        }
        return {title:t('you-realy-want-to-delete-element-name',{element:element.toLocaleLowerCase(),name}), isOpen:true}
    }

    return (
        <div className={`projectsMenu__item-inner ${getValidText(className)}`}>
            <div>
                {leftIcon}
                <p
                    className={`${
                        type === 'project' ? 'cursor-pointer' : ''
                    } projectsMenu__item-text`}
                    onClick={() => handleSelectCurrentProject()}
                >
                    {name}
                </p>
            </div>
            <DeleteIcon
                onClick={() =>
                    setModalData(openModal())
                }
                className={'projectsMenu__deleteIcon'}
            />
            {createPortal(
                <DeleteModal
                    isLoading={isLoading}
                    open={modalData.isOpen}
                    title={modalData.title}
                    onDelete={handleDelete}
                    className={'delete-project-modal'}
                    onCancel={() => setModalData(initialModalData)}
                />,
                document.body,
            )}
        </div>
    )
}

export default ProjectItemContent
