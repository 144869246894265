import {instance} from "../app/api";
import {ACCESS_TOKEN} from "../utils/constants";
import {
    ICustomGroupMeasurement,
    ICustomGroupName,
    ICustomGroupParameter,
    ICustomGroupResponse,
    ICustomMeasurementResponse,
    ICustomParameterRequest,
    ICustomParameterResponse,
    ICustomParametersGroupResponse
} from "../app/types/models/i-custom-group";
import {AxiosResponse} from "axios";

export const customGroupService = {
    addGroup: async (projectId: number,coreId:number, data: ICustomGroupName) => {
        return await instance.post<undefined,AxiosResponse<ICustomGroupResponse>>(`projects/${projectId}/wells/core/${coreId}/measurements/custom-group/`,data, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        }).then((data)=>data.data)
    },
    getGroups: async (projectId: number,coreId:number) => {
        return await instance.get<undefined,AxiosResponse<ICustomGroupResponse[]>>(`projects/${projectId}/wells/core/${coreId}/measurements/custom-group/`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        }).then(data=>data.data)
    },
    deleteGroup: async (projectId: number,coreId:number,groupId:number) => {
        return await instance.delete(`projects/${projectId}/wells/core/${coreId}/measurements/custom-group/${groupId}/`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        })
    },
    addCustomMeasurement: async (projectId: number,coreId:number,groupId:number, data: {}) => {
        return await instance.post<any,AxiosResponse<any>>(`projects/${projectId}/wells/core/${coreId}/measurements/custom-group/${groupId}/custom-measurement/`, data,{
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        }).then(data=>data.data)
    },
    getCustomMeasurements: async (projectId: number,coreId:number,groupId:number) => {
        return await instance.get<any,AxiosResponse<ICustomMeasurementResponse[]>>(`projects/${projectId}/wells/core/${coreId}/measurements/custom-group/${groupId}/custom-measurement/`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        }).then(data=>data.data)
    },
    deleteCustomMeasurement: async (projectId: number,coreId:number,groupId:number,customMeasurementId:number) => {
        return await instance.delete(`projects/${projectId}/wells/core/${coreId}/measurements/custom-group/${groupId}/custom-measurement/${customMeasurementId}/`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        })
    },
    addParameterGroup: async (projectId:number,coreId:number,groupId:number,measurementId:number, data: object) => {
        return await instance.post(`projects/${projectId}/wells/core/${coreId}/measurements/custom-group/${groupId}/custom-measurement/${measurementId}/parameter-group/`, data,{
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        }).then(data=>data.data)
    },
    getParameterGroups: async (projectId:number,coreId:number,groupId:number,measurementId:number) => {
        return await instance.get<any,AxiosResponse<ICustomParametersGroupResponse[]>>(`projects/${projectId}/wells/core/${coreId}/measurements/custom-group/${groupId}/custom-measurement/${measurementId}/parameter-group/`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        }).then(data=>data.data)
    },
    deleteParameterGroup: async (projectId:number,coreId:number,groupId:number,measurementId:number,parameterGroupId:number) => {
        return await instance.delete(`projects/${projectId}/wells/core/${coreId}/measurements/custom-group/${groupId}/custom-measurement/${measurementId}/parameter-group/${parameterGroupId}/`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        })
    },
    addParameter: async (projectId:number,coreId:number,groupId:number,measurementId:number,parameterGroupId:number, data: ICustomParameterRequest) => {
        return await instance.post<any,AxiosResponse<ICustomParameterResponse>>(`projects/${projectId}/wells/core/${coreId}/measurements/custom-group/${groupId}/custom-measurement/${measurementId}/parameter-group/${parameterGroupId}/parameter/`, data,{
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        }).then(data=>data.data)
    },
    updateParameter: async (projectId:number,coreId:number,groupId:number,measurementId:number,parameterGroupId:number,parameterId:number, data: ICustomParameterRequest) => {
        return await instance.patch(`projects/${projectId}/wells/core/${coreId}/measurements/custom-group/${groupId}/custom-measurement/${measurementId}/parameter-group/${parameterGroupId}/parameter/${parameterId}/`, data,{
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        }).then(data=>data.data)
    },
    getParameters: async (projectId:number,coreId:number,groupId:number,measurementId:number,parameterGroupId:number) => {
        return await instance.get<undefined,AxiosResponse<ICustomParameterResponse[]>>(`projects/${projectId}/wells/core/${coreId}/measurements/custom-group/${groupId}/custom-measurement/${measurementId}/parameter-group/${parameterGroupId}/parameter/`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        }).then(data=>data.data)
    },
    deleteParameter: async (projectId:number,coreId:number,groupId:number,measurementId:number,parameterGroupId:number,parameterId:number) => {
        return await instance.delete(`projects/${projectId}/wells/core/${coreId}/measurements/custom-group/${groupId}/custom-measurement/${measurementId}/parameter-group/${parameterGroupId}/parameter/${parameterId}/`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        })
    },
}