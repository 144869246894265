import {instance} from "../app/api";
import {ACCESS_TOKEN} from "../utils/constants";
import {AxiosResponse} from "axios";
import {INotification} from "../app/types/models/i-notification";

export const notificationsService = {
    getNotifications: async () => {
        return await instance.get<undefined,AxiosResponse<INotification[]>>(`notifications/notification/`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            }
        }).then(data=>data?.data)
    },
    updateNotification: async (id:number,data:any) => {
        return await instance.patch<undefined,AxiosResponse<any>>(`notifications/notification/${id}/`,data, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            }
        }).then(data=>data?.data)
    },
    deleteNotification:async (id:number)=>{
        return await instance.delete(`/notifications/notification/${id}/`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            }
        }).then(data=>data?.data)
    },
    invitationResponse:async (id:number,data:any)=>{
        return await instance.post(`/projects/${id}/invitation/response/`,data,{
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            }
        }).then(data=>data?.data)
    },
}
