import axios from 'axios'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IWellLogFile } from '../../../../../../app/types/models/well-log-file'
import { useAppDispatch } from '../../../../../../hooks/redux-hooks'
import { wellsService } from '../../../../../../services/wells-service'
import { FileAttach } from '../../../../../../shared/FileAttach/FileAttach'
import DownloadIconButton from '../../../../../../shared/downloadIconButton/DownloadIconButton'
import TableInLog from '../../../../../../shared/table/Table'
import { currentProjectSelectors } from '../../../../../../store/currentProject'
import {
    addWellLogFile,
    deleteWellLogFile,
    setCurrentWellLogFile,
    setWellLogFiles,
} from '../../../../../../store/currentProject/actions'
import {
    selectCurrentWell,
    selectCurrentWellBore,
    selectCurrentWellLogFile,
    selectWellLogFiles,
} from '../../../../../../store/currentProject/selectors'
import {
    ACCESS_TOKEN,
    HTTP_BASE_URL_API,
    WSS_BASE_URL_API,
} from '../../../../../../utils/constants'
import { errorsHandler } from '../../../../../../utils/helpers/errors/errors-hendler'
import { getFileBlockList } from '../../data/getFIleBlockList'
import BlockItem from '../blockItem/BlockItem'
import './logsUpload.css'
import { ILogsUploadProps } from './types'
import SelectFileText from '../../../../../../shared/selectFileText/SelectFileText'

const LogsUpload: FC<ILogsUploadProps> = ({
    assessmentStatus,
    setAssessmentStatus,
}) => {
    const { t, i18n } = useTranslation()
    const currentProject = useSelector(
        currentProjectSelectors.selectCurrentProject,
    )
    const currentWell = useSelector(selectCurrentWell)
    const currentWellBore = useSelector(selectCurrentWellBore)
    const wellLogFiles = useSelector(selectWellLogFiles)
    const [progress, setProgress] = useState(0)
    const [progressAll, setProgressAll] = useState(0)
    const currentWellLogFile = useSelector(selectCurrentWellLogFile)
    const dispatch = useAppDispatch()
    const ws = useRef(null)
    const [loadingWellLogFile, setLoadingWellLogFile] = useState(false)
    const [errorText, setErrorText] = useState('')

    const customRequest = async (options) => {
        const { onSuccess, onError, file } = options
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
            onUploadProgress: (event) => {
                const percent = Math.floor((event.loaded / event.total) * 100)
                setProgress(percent)
            },
        }
        if (currentWell || currentWellBore) {
            const fmData = new FormData()
            fmData.append('file', file)
            currentWell
                ? fmData.append('well', currentWell?.id.toString())
                : fmData.append('wellbore', currentWellBore?.id.toString())
            try {
                setLoadingWellLogFile(true)
                const response = await axios.post(
                    `${HTTP_BASE_URL_API}projects/${currentProject?.id}/wells/well-log-file/`,
                    fmData,
                    config,
                )
                dispatch(addWellLogFile(response.data))
                dispatch(setCurrentWellLogFile(response.data))
                onSuccess('Ok')
                setLoadingWellLogFile(false)
                setErrorText('')
            } catch (err) {
                setErrorText(t('logs-upload-error'))
                onError({ err })
                setLoadingWellLogFile(false)
            }
        }
    }

    const deleteFile = async (id: number) => {
        try {
            await wellsService.deleteWellLogFile(currentProject?.id, id)
            dispatch(deleteWellLogFile({ id }))
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const setCurrentFile = (file: IWellLogFile) => {
        dispatch(setCurrentWellLogFile(file))
    }

    const gettingData = () => {
        if (!ws.current) return
        ws.current.onmessage = (e) => {
            const message = JSON.parse(e.data)
            if (message && Array.isArray(message) && message?.length > 1) {
                setAssessmentStatus(message)
            }
        }
    }

    const fileBlockTopDatalist = useCallback(() => {
        return getFileBlockList(t)
    }, [i18n.language])

    const getRowFileList = useCallback(() => {
        if (wellLogFiles && wellLogFiles?.length > 0) {
            {
                return wellLogFiles.map((item) => (
                    <BlockItem
                        key={item.id}
                        item={item}
                        currentFileId={currentWellLogFile?.id}
                        deleteWellLogFile={deleteFile}
                        setCurrentFile={setCurrentFile}
                    />
                ))
            }
        } else {
            return []
        }
    }, [wellLogFiles, assessmentStatus, currentWellLogFile])

    const title = (
        <p className="font-14-normal">
            <SelectFileText />
        </p>
    )

    const getWellLogFiles = async () => {
        const params = currentWell
            ? {
                  well: currentWell?.id?.toString(),
              }
            : {
                  wellbore: currentWellBore?.id?.toString(),
              }
        const urlParams = new URLSearchParams(params)

        try {
            const response = await fetch(
                `https://api.data-factory.ru/api/projects/${currentProject?.id}/wells/well-log-file/?${urlParams}`,
                {
                    headers: {
                        Authorization: `Bearer ${
                            localStorage.getItem(ACCESS_TOKEN)
                                ? JSON.parse(localStorage.getItem(ACCESS_TOKEN))
                                : null
                        }`,
                    },
                },
            )
            const reader = response.body.getReader()
            // Шаг 2: получаем длину содержимого ответа
            const contentLength = +response.headers.get('Content-Length')

            // Шаг 3: считываем данные:
            let receivedLength = 0 // количество байт, полученных на данный момент
            let chunks = [] // массив полученных двоичных фрагментов (составляющих тело ответа)
            while (true) {
                const { done, value } = await reader.read()

                if (done) {
                    break
                }
                chunks.push(value)
                receivedLength += value.length
                setLoadingWellLogFile(receivedLength !== contentLength)
                const percent = Math.floor(
                    (receivedLength / contentLength) * 100,
                )
                setProgressAll(percent)
            }
            // Шаг 4: соединим фрагменты в общий типизированный массив Uint8Array
            let chunksAll = new Uint8Array(receivedLength) // (4.1)
            let position = 0
            for (let chunk of chunks) {
                chunksAll.set(chunk, position) // (4.2)
                position += chunk.length
            }
            // Шаг 5: декодируем Uint8Array обратно в строку
            let data = JSON.parse(new TextDecoder('utf-8').decode(chunksAll))
            dispatch(setWellLogFiles(data))
            let lastDownloadFile = data[0]
            for (let i = 0; i < data?.length; i++) {
                if (
                    new Date(lastDownloadFile?.created_at) <
                    new Date(data[i]?.created_at)
                ) {
                    lastDownloadFile = data[i]
                }
            }
            ws.current = new WebSocket(
                `wss://${WSS_BASE_URL_API}projects/${
                    currentProject?.id
                }/well-log-file/?token=${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            )
            gettingData()
            dispatch(setCurrentWellLogFile(lastDownloadFile))
        } catch (e) {
            errorsHandler(e, t)
            dispatch(setWellLogFiles([]))
            dispatch(setCurrentWellLogFile(null))
        }
    }

    useEffect(() => {
        getWellLogFiles()
    }, [currentWell, currentWellBore, assessmentStatus])

    return (
        <div className={'logs-upload'}>
            {progress !== 0 && progress !== 100 && loadingWellLogFile ? (
                <div className={'logs__loading'}>
                    <div
                        className={'logs__loading_inner'}
                        style={{ width: `${progress}%` }}
                    >
                        {t('loading').toString()} {progress}%
                    </div>
                </div>
            ) : (
                <FileAttach
                    files={null}
                    title={title}
                    className={'logs__upload'}
                    accept={'.xlsx, .las'}
                    listType={'text'}
                    customRequest={customRequest}
                    onSelect={() => {}}
                />
            )}
            {progressAll !== 0 && progressAll !== 100 && (
                <div className={'logs-upload-download-message mt-15'}>
                    <div
                        className={'logs-upload-download-message-inner'}
                        style={{ width: progressAll + '%' }}
                    >
                        {progressAll > 30 && (
                            <span>
                                {t('files-uploaded-to-value', {
                                    value: progressAll,
                                }).toString()}
                                %
                            </span>
                        )}
                    </div>
                </div>
            )}
            {errorText?.length > 0 && (
                <div className={'mt-10 font-14-normal font-error'}>
                    {errorText}
                </div>
            )}
            <DownloadIconButton
                href={'/files/well_logging_example.xlsx'}
                description={t('download-template').toString()}
            />
            {getRowFileList()?.length > 0 && (
                <div className="mt-30">
                    <TableInLog
                        topDataList={fileBlockTopDatalist()}
                        rowFileList={getRowFileList()}
                    />
                </div>
            )}
        </div>
    )
}

export default LogsUpload
