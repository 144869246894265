import {
    CalendarGroupEntityTypes,
    CalendarItemEntityTypes,
    CalendarPriorityColors,
    ICalendarGroup,
    ICalendarItem,
} from '../../app/types/i-roadmap-calendar'
import { getFullMonths } from './services/get-full-months'
import { getQuarterData } from './services/get-quarters-by-date'

export const monthList = [
    'jan',
    'feb',
    'march',
    'apr',
    'may',
    'june',
    'jule',
    'aug',
    'sept',
    'oct',
    'nov',
    'dec',
]

export const quartersList = ['I', 'II', 'III', 'IV']

function getWeekdaysArray(startDate, endDate) {
    let days = []
    let currentDate = new Date(startDate)

    // Проходимся по всем дням между startDate и endDate
    while (currentDate <= endDate) {
        // Получаем день недели текущей даты (0 - воскресенье, 1 - понедельник, и т.д.)
        let dayOfWeek = currentDate.getDay()

        // Если текущий день недели не выходной (суббота и воскресенье), добавляем его в массив
        // if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        days.push(new Date(currentDate).getDate())
        // }

        // Переходим к следующему дню
        currentDate.setDate(currentDate.getDate() + 1)
    }

    return days
}

export function getDaysInMonth(year: number, month: number) {
    // Определяем количество дней в месяце
    const daysInMonth = new Date(year, month + 1, 0).getDate()

    // Создаем массив от 1 до последнего дня месяца
    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1)

    return daysArray
}

const chunkArray = (array, size) =>
    Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
        array.slice(index * size, index * size + size),
    )

function getWeeksInYear(year) {
    // Начальная дата 1 января указанного года
    let startDate = new Date(year, 0, 1)

    // Конечная дата 31 декабря указанного года
    let endDate = new Date(year, 11, 31)

    // Объект для хранения недель по месяцам
    let weeksByMonth = {}

    // Переменная для хранения текущей даты
    let currentDate = new Date(startDate)

    // Переменная для отслеживания начала недели
    let weekStart = startDate

    // Перебираем все дни от начальной до конечной даты
    while (currentDate <= endDate) {
        // Если текущий день - воскресенье или последний день года, это конец недели
        if (
            currentDate.getDay() === 0 ||
            currentDate.getTime() === endDate.getTime()
        ) {
            // Определяем месяц текущей недели
            let monthKey = monthList[currentDate.getMonth()]

            // Если в объекте нет массива для текущего месяца, создаем его
            if (!weeksByMonth[monthKey]) {
                weeksByMonth[monthKey] = []
            }

            // Добавляем в список текущую неделю для текущего месяца
            weeksByMonth[monthKey].push({
                start: new Date(weekStart).getDate(),
                end: new Date(currentDate).getDate(),
                days: getWeekdaysArray(
                    new Date(weekStart),
                    new Date(currentDate),
                ),
            })

            // Устанавливаем начало следующей недели на следующий день
            weekStart = new Date(currentDate)
            weekStart.setDate(weekStart.getDate() + 1)
        }

        // Переходим к следующему дню
        currentDate.setDate(currentDate.getDate() + 1)
    }

    return weeksByMonth as {
        [key: string]: Array<{ start: number; end: number; days: number[] }>
    }
}

const getWeeksList = (year: number, month: number) => {
    const list = getWeeksInYear(year)
    return list[monthList[month]]
}

const currentYear = new Date().getFullYear()

const getValidMonthIndex = (quater: number, quraterMonthIndex: number) => {
    const list = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [9, 10, 11],
    ]
    return list[quater][quraterMonthIndex]
}

export const getQuarterDetalization = (data?: {
    startPeriod: Date | string
    endPeriod: Date | string
    priorityColor?: CalendarPriorityColors
}): ICalendarGroup[] => {
    const monthChunks = chunkArray(monthList, 3)

    let { startPeriod, endPeriod, priorityColor } = data

    startPeriod = new Date(startPeriod)
    endPeriod = new Date(endPeriod)

    const quarters = getQuarterData(startPeriod, endPeriod)

    return quarters.map((quarter) => {
        const quarterIndex = quarter.index - 1
        return {
            ...quarter,
            list: monthChunks[quarterIndex].map((month, monthI) => {
                const validMonthIndex = getValidMonthIndex(quarterIndex, monthI)
                return {
                    label: month,
                    type: CalendarItemEntityTypes.MONTH,
                    index: validMonthIndex,
                    days: getDaysInMonth(quarter.year, validMonthIndex),
                    value: {
                        interval: [0, 0],
                        priorityColor:
                            priorityColor || CalendarPriorityColors.IMPORTANT,
                    },
                } as ICalendarItem
            }),
        }
    })
}

export const getMonthDetalization = (data?: {
    startPeriod: Date | string
    endPeriod: Date | string
    priorityColor?: CalendarPriorityColors
}): ICalendarGroup[] => {
    const { startPeriod, endPeriod, priorityColor } = data

    const list = getFullMonths(
        new Date(startPeriod || new Date()),
        new Date(endPeriod || new Date()),
    )

    return list.map(
        (month: { month: number; year: number; label: string }) => ({
            type: CalendarGroupEntityTypes.MONTH,
            label: month.label,
            // year: year ? year : new Date().getFullYear(),
            index: month.month,
            year: month.year,
            list: getWeeksList(month.year, month.month)?.map(
                (week) =>
                    ({
                        label: `${week?.start}-${week?.end}`,
                        value: {
                            days: week?.days,
                            interval: [0, 0],
                            month: {
                                label: month.label,
                                index: month.month,
                            },
                            priorityColor:
                                priorityColor ||
                                CalendarPriorityColors.IMPORTANT,
                        },
                        type: CalendarItemEntityTypes.WEEK,
                    } as ICalendarItem),
            ),
        }),
    )
}

export const getYearDetalization = (data?: {
    startPeriod: Date | string
    endPeriod: Date | string
    priorityColor?: CalendarPriorityColors
}): ICalendarGroup[] => {
    const { startPeriod, endPeriod, priorityColor } = data

    const getYears = () => {
        const startYear = new Date(startPeriod).getFullYear()
        const endYear = new Date(endPeriod).getFullYear()
        const res: number[] = []

        if (startYear === endYear) {
            res.push(startYear)
        } else {
            let start = startYear
            while (start <= endYear) {
                res.push(start)
                start += 1
            }
        }

        return res;
    }

    return getYears().map(
        (year) => ({
            type: CalendarGroupEntityTypes.YEAR,
            label: year.toString(),
            year,
            list: quartersList.map(
                (quarter, quarterI) =>
                    ({
                        label: quarter,
                        type: CalendarItemEntityTypes.QUARTER,
                        value: {
                            orderIndex: quarterI,
                            interval: [0, 0],
                            priorityColor:
                                priorityColor ||
                                CalendarPriorityColors.IMPORTANT,
                        },
                    } as ICalendarItem),
            ),
        }),
    )
}
