import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {Input, Select} from "antd";
//@ts-ignore
import s from './addonsInput.module.scss';
import {SelectIcon} from "../../image_files/icons/SelectIcon";
import {SelectType} from "../../app/types/common";

const { Option } = Select;


interface IProps{
    options?:SelectType[]
    value?:string | null
    type?:'select' | 'string'
    data?:string
    defaultOption?:string | undefined
    onChange?:(data:{type:any,value:string})=>void
}

const AddonsInput:FC<IProps> = (props) => {
    const {options,value,type='select',data,defaultOption,onChange} = props
    const [valueData,setValueData] = useState<string>(value)
    const [typeData,setTypeData] = useState<string | undefined>('')

    const onChangeSetValue = (e:ChangeEvent<HTMLInputElement>)=>{
        if(typeData){
            setValueData(e.currentTarget.value)
            onChange({type:typeData,value:e.currentTarget.value})
        }
    }

    const onChangeSelectValue = (selectValue:string)=>{
        setTypeData(selectValue)
        if(valueData){
            onChange({type:selectValue,value:valueData})
        }
    }

    useEffect(()=>{
        if(value){
            setValueData(value)
        }
    },[value])

    useEffect(()=>{
        defaultOption &&  setTypeData(defaultOption)
    },[defaultOption])

    return (
        <div className={s.addonsInput}>
            {
                type==='select'
                ?             <Select onChange={onChangeSelectValue}
                                      className={s.customSelect}
                                      popupClassName={s.popupSelect}
                                      bordered={false}
                                      defaultValue={defaultOption} suffixIcon={<SelectIcon/>}
                    >
                        {
                            options && options.map((option,key)=>
                                <Option key={key} value={option.value} className={s.customOption}><span style={{color:'var(--main-text-color)'}} className={s.optionText}>{option.name}</span></Option>
                            )
                        }
                    </Select>
                    : <div className={s.inputText}>{data ? data : ''}</div>
            }

            <Input className={s.input}
                   type={'number'}
                   onChange={onChangeSetValue}
                   value={valueData}
            />
        </div>
    );
};

export default AddonsInput;