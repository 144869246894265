const monthList = [
    'jan',
    'feb',
    'march',
    'apr',
    'may',
    'june',
    'jule',
    'aug',
    'sept',
    'oct',
    'nov',
    'dec',
]

export function getFullMonths(startDate: Date, endDate: Date) {
    const start = new Date(startDate)
    const end = new Date(endDate)

    // Проверяем, что начальная дата меньше или равна конечной
    if (start > end) {
        return []
    }

    const result = []
    let currentYear = start.getFullYear()
    let currentMonth = start.getMonth()

    // Если день конца меньше дня начала, не засчитываем последний неполный месяц
    if (end.getDate() < start.getDate()) {
        end.setMonth(end.getMonth() - 1)
    }

    while (
        currentYear < end.getFullYear() ||
        (currentYear === end.getFullYear() && currentMonth <= end.getMonth())
    ) {

        const monthName = monthList[currentMonth]

        result.push({
            label: monthName,
            year: currentYear,
            month: currentMonth,
        })

        // Переходим к следующему месяцу
        currentMonth++
        if (currentMonth > 11) {
            // Если вышли за декабрь
            currentMonth = 0
            currentYear++
        }
    }

    return result
}
