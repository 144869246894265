import { Button, Modal, Spin } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import { XIcon } from '../../../../../../image_files/icons/XIcon'
import { FileAttach } from '../../../../../../shared/FileAttach/FileAttach'
import { ICoreBoxData } from '../../types'
import AddCoreBoxPhotoItem from '../addCoreBoxPhotoItem/AddCoreBoxPhotoItem'
import CoreBoxRadioContent from '../coreBoxRadioContent/CoreBoxRadioContent'
import './AddCoreBoxPhotoModal.css'
import { IAddCoreBoxPhotoModalProps } from './types'
import SelectFileText from '../../../../../../shared/selectFileText/SelectFileText'
import { showNotice } from '../../../../../../utils/helpers/showNotice'

const initalData: ICoreBoxData = {
    intervalId: 1,
    id: 1,
    coreLength: '',
    direction: 'left_to_right',
    lighting: 'visible',
    loading: 'laid_down',
    laying: 'yes',
    comment: '',
    file: {},
}

const AddCoreBoxPhotoModal: FC<IAddCoreBoxPhotoModalProps> = (props) => {
    const {
        loading,
        isOpenModal,
        resetFileTrigger,
        currentInterval,
        addData,
        closeModal,
        setResetFileTrigger,
    } = props
    const { t } = useTranslation()
    const uid = uuidv4()

    const [data, setData] = useState<ICoreBoxData>(initalData)
    const [fieldError, setFieldError] = useState('')

    const removeUploadFileHandler = async (id: number) => {}

    const onChange = (name: string, value: '') => {
        setData({
            ...data,
            [name]: value,
        })
    }

    const saveData = () => {
        const keysLength = Object.keys(data?.file)?.length
        if (
            data['coreLength'] &&
            keysLength > 0 &&
            Number(data['coreLength']) > 0
        ) {
            addData({ ...data, id: uid })
        } else {
            if (keysLength === 0) {
                showNotice('you-didnt-select-file',t,{type:'error'})
            }
            if (Number(data['coreLength']) === 0) {
                showNotice('you-didnt-add-column-core-length',t,{type:'error'})
            }
        }
    }

    useEffect(() => {
        currentInterval &&
            Object.keys(currentInterval).length > 0 &&
            setData({ ...initalData, intervalId: currentInterval.id })
    }, [currentInterval])

    return (
        <Modal
            className="core-box-photo-modal"
            title={null}
            width={620}
            open={isOpenModal}
            onCancel={closeModal}
            footer={null}
            closeIcon={
                <XIcon
                    width={13}
                    height={13}
                    className={'notification-top-cancel-icon'}
                />
            }
        >
            {loading ? (
                <div className="add-core-box-photo-inner--loading">
                    <Spin />
                </div>
            ) : (
                <div className="add-core-box-photo-inner">
                    <h6 className="font-25-normal">
                        {t('select-image-of-core-box')}
                    </h6>
                    <FileAttach
                        files={[]}
                        accept=".png,.jpg,.tiff"
                        resetFileTrigger={resetFileTrigger}
                        className="add-core-box-photo-file-attach"
                        title={
                            <SelectFileText value='(.png, .jpg, .tiff)' />
                        }
                        customRequest={() => undefined}
                        removeFile={removeUploadFileHandler}
                        onSelect={(fileData) => {
                            setData({ ...data, file: fileData[0] })
                        }}
                    />
                    <div className="add-core-box-photo-content">
                        <AddCoreBoxPhotoItem
                            name="coreLength"
                            type="input"
                            value={data['coreLength']}
                            isShowError={true}
                            rules={[
                                {
                                    isRequired: true,
                                    pattern: /^\d(?:\.\d{1,2})?$/,
                                    message: t(
                                        'can-to-input-max-symbols-count-by-exemples',
                                        { value: 3, example: 1.23 },
                                    ),
                                },
                            ]}
                            title={t('core-column-length')}
                            onChange={onChange}
                            setFieldError={setFieldError}
                        />
                        <AddCoreBoxPhotoItem
                            type="radio"
                            title={t(
                                'direction-of-core-selection',
                            )}
                            value={data['direction']}
                            name={'direction'}
                            radioItems={[
                                {
                                    value: 'left_to_right',
                                    content: CoreBoxRadioContent({
                                        text: t(
                                            'from-left-to-right',
                                        ),
                                        arrow: 'right',
                                    }),
                                },
                                {
                                    value: 'right_to_left',
                                    content: CoreBoxRadioContent({
                                        text: t(
                                            'from-right-to-left',
                                        ),
                                        arrow: 'left',
                                    }),
                                },
                            ]}
                            onChange={onChange}
                        />
                        <AddCoreBoxPhotoItem
                            type="radio"
                            value={data['lighting']}
                            name={'lighting'}
                            title={t('lighting')}
                            radioItems={[
                                {
                                    value: 'visible',
                                    content: CoreBoxRadioContent({
                                        text: t(
                                            'visible-light',
                                        ),
                                    }),
                                },
                                {
                                    value: 'ultraviolet',
                                    content: CoreBoxRadioContent({
                                        text: t(
                                            'ultraviolet-light',
                                        ),
                                    }),
                                },
                            ]}
                            onChange={onChange}
                        />
                        <AddCoreBoxPhotoItem
                            type="radio"
                            value={data['loading']}
                            title={t(
                                'loading-process-a-core-into-box',
                            )}
                            name={'loading'}
                            radioItems={[
                                {
                                    value: 'laid_down',
                                    content: CoreBoxRadioContent({
                                        text: t(
                                            'laid-down',
                                        ),
                                    }),
                                },
                                {
                                    value: 'emptied',
                                    content: CoreBoxRadioContent({
                                        text: t(
                                            'poured-out',
                                        ),
                                    }),
                                },
                            ]}
                            onChange={onChange}
                        />
                        <AddCoreBoxPhotoItem
                            type="radio"
                            value={data['laying']}
                            title={t(
                                'lithologist-checked-the-installation',
                            )}
                            name={'laying'}
                            radioItems={[
                                {
                                    value: 'yes',
                                    content: CoreBoxRadioContent({
                                        text: t('yes'),
                                    }),
                                },
                                {
                                    value: 'no',
                                    content: CoreBoxRadioContent({
                                        text: t('no'),
                                    }),
                                },
                            ]}
                            onChange={onChange}
                        />
                        <AddCoreBoxPhotoItem
                            name="comment"
                            value={data['comment']}
                            type="textarea"
                            title={t('comment')}
                            onChange={onChange}
                        />
                        <div className="add-core-box-photo-btn-wrapper">
                            <Button
                                type="primary"
                                onClick={saveData}
                                disabled={fieldError?.length > 0}
                            >
                                {t(
                                    'upload-image',
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    )
}

export default AddCoreBoxPhotoModal
