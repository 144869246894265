import { DatePicker, Modal } from 'antd'
import en_EN from 'antd/es/date-picker/locale/en_US'
import ru_RU from 'antd/es/date-picker/locale/ru_RU'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ISelectOption } from '../../../../app/types/common'
import { PriorityTypes } from '../../../../app/types/enums'
import { IComment, ITag, ITask } from '../../../../app/types/models/tasks'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import useDebounce from '../../../../hooks/useDebounce'
import { CopyDataIcon } from '../../../../image_files/icons/CopyDataIcon'
import { tasksService } from '../../../../services/tasks-services'
import { MembersItem } from '../../../../shared/MembersItem/MembersItem'
import { PrioritySelect } from '../../../../shared/PrioritySelect/PrioritySelect'
import InputInLog from '../../../../shared/input/Input'
import SelectInLog from '../../../../shared/select/Select'
import SpinInLog from '../../../../shared/spin/spin'
import { stopEvent } from '../../../../shared/stopEvent'
import { selectCurrentProject } from '../../../../store/currentProject/selectors'
import { membersSelectors } from '../../../../store/members'
import { updateTask } from '../../../../store/tasks/actions'
import { selectIsLoadingTasksStatus, selectStatuses } from '../../../../store/tasks/selectors'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { getValidText } from '../../../../utils/helpers/getValidText'
import { showNotice, showNotify } from '../../../../utils/helpers/showNotice'
import './RoadMapUpdateTaskBlock.css'
import TabsInlog from '../../../../shared/tabs/Tabs'
import { Description } from '../../../taskEditSpace/components/Description/Description'
import { SubTask } from '../../../taskEditSpace/components/SubTask/SubTask'
import TasksFiles from '../../../taskEditSpace/components/TaskFiles/TasksFiles'
import { Comment } from '../../../taskEditSpace/components/Comment/Comment'

interface IProps {
    task?: ITask
    isOpen: boolean
    className?: string
    tagsOptions: ISelectOption[]

    setIsOpen: (status: boolean) => void
    refetchTasks?: () => void
}

const RoadMapUpdateTaskBlock: FC<IProps> = (props) => {
    const { task, isOpen, tagsOptions, setIsOpen } = props
    const { t, i18n } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useAppDispatch()
    const statuses = useSelector(selectStatuses)
    const currentProject = useSelector(selectCurrentProject)
    const [statusOptions, setStatusesOptions] = useState<ISelectOption[]>([])
    const [membersOptions, setMembersOptions] = useState<ISelectOption[]>([])
    const members = useSelector(membersSelectors.selectMembers)
    const [fieldsData, setFieldsData] = useState({
        name: '',
        slug: '',
        priority: 'low',
        status: undefined as any,
        due_date_start: '',
        due_date_end: '',
        tags: [] as number[],
        supervisor: 0 as string | number,
        doers: [] as number[],
    })
    const taskLoadingStatus = useSelector(selectIsLoadingTasksStatus)
    const debouncedName = useDebounce(fieldsData.name, 500)
    const [comments, setComments] = useState<IComment[]>([])

    const updateTaskFields = async (data: {
        name?: string
        tags?: number[]
        doers?: number[]
        priority?: string
        status?: number | null
        due_date_start?: string
        due_date_end?: string
    }) => {
        try {
            setIsLoading(true)
            const res = await tasksService.updateTask(
                data,
                currentProject?.id,
                task?.slug,
            )
            dispatch(updateTask(res))
            showNotice(t('task-updated-successfully'), t)
            setIsLoading(false)
        } catch (error) {
            errorsHandler(error, t)
            setIsLoading(false)
        }
    }

    const handleChangeRate = (value: any[]) => {
        if (Array.isArray(value)) {
            const due_date_start = moment(new Date(value[0])).format(
                'YYYY-MM-DD',
            )
            const due_date_end = moment(new Date(value[1])).format('YYYY-MM-DD')

            updateTaskFields({ due_date_start, due_date_end })
        }
    }

    const getMembersOptions = () => {
        if (members && members?.length > 0) {
            setMembersOptions(
                members.map((m, i) => ({
                    label: (
                        <MembersItem
                            member={m}
                            containerClassName="roadmap-filter-member-select-option"
                        />
                    ),
                    value: m?.user?.id,
                })),
            )
        }
    }

    const getValidTagsOptions = (tags: ITag[] | undefined): number[] => {
        if (tags && tags.length > 0) {
            return tags.map((el) => {
                if (typeof el !== 'number') {
                    return el?.id
                } else return el
            })
        } else return []
    }

    const getValidStatus = (task: ITask) => {
        if (task?.status !== undefined && task?.status !== null) {
            if (typeof task?.status === 'number') {
                return task.status
            } else {
                if (task.status?.id !== undefined) {
                    return Number(task.status?.id)
                } else return t('without-status')
            }
        } else return t('without-status')
    }

    const addSupervisor = async (userId: string) => {
        const res = await tasksService.addTaskSuperVisor(
            currentProject?.id,
            task?.slug,
            {
                user: userId,
                project: currentProject?.id,
            },
        )

        const targetMember = members?.find((el) => el?.user?.id === res?.user)
        if (targetMember) {
            dispatch(
                updateTask({
                    ...task,
                    supervisor: { ...targetMember, id: res?.id },
                }),
            )
            setFieldsData((prev) => ({
                ...prev,
                supervisor: userId,
            }))
        }
    }

    const changeSupervisor = async (userId?: string) => {
        try {
            setIsLoading(true)
            if (fieldsData.supervisor !== undefined) {
                //уже есть наблюдатель
                await tasksService.deleteTaskSuperVisor(
                    currentProject?.id,
                    task?.slug,
                    task?.supervisor?.id,
                )
            }
            if (userId !== undefined) {
                await addSupervisor(userId)
            } else {
                updateTask({
                    ...task,
                    supervisor: undefined,
                })
                setFieldsData((prev) => ({
                    ...prev,
                    supervisor: undefined,
                }))
                dispatch(
                    updateTask({
                        ...task,
                        supervisor: undefined,
                    }),
                )
            }
            setIsLoading(false)
            showNotice(t('task-updated-successfully'), t)
        } catch (error) {
            setIsLoading(false)
            errorsHandler(error, t)
        }
    }

    const changeDoers = async (ids?: number[]) => {
        try {
            setIsLoading(true)
            const localIds = fieldsData.doers || []
            if (ids?.length > 0 && localIds?.length < ids?.length) {
                //добавляю участника
                const lastEl = ids[ids.length - 1]
                const res = await tasksService.addTaskDoer(
                    currentProject?.id,
                    task?.slug,
                    {
                        user: lastEl,
                        project: currentProject?.id,
                    },
                )

                const targetDoer = members?.find(
                    (val) => val?.user?.id === lastEl,
                )
                if (targetDoer) {
                    dispatch(
                        updateTask({
                            ...task,
                            doers: [
                                ...task?.doers,
                                { ...targetDoer, id: res?.id },
                            ],
                        }),
                    )
                    setFieldsData((prev) => {
                        const doers = prev?.doers || []
                        return {
                            ...prev,
                            doers: [...doers, targetDoer?.user?.id],
                        }
                    })
                } else {
                    console.log(
                        'member with id user id ===' + lastEl + ' didn-t find',
                    )
                }

                setIsLoading(false)
            } else {
                //удаляю
                if (ids?.length > 0) {
                    const targetDoer = task?.doers?.find(
                        (el) => !ids.includes(el?.user?.id),
                    )
                    await tasksService.deleteTaskAssignee(
                        currentProject?.id,
                        task?.slug,
                        targetDoer?.id,
                    )

                    dispatch(
                        updateTask({
                            ...task,
                            doers: task?.doers?.filter(
                                (el) => el?.id !== targetDoer?.id,
                            ),
                        }),
                    )
                    setFieldsData((prev) => ({
                        ...prev,
                        doers: prev?.doers?.filter(
                            (val) => val !== targetDoer?.user?.id,
                        ),
                    }))
                } else {
                    const targetDoer = task?.doers[0]
                    if (targetDoer) {
                        await tasksService.deleteTaskAssignee(
                            currentProject?.id,
                            task?.slug,
                            targetDoer?.id,
                        )

                        dispatch(
                            updateTask({
                                ...task,
                                doers: [],
                            }),
                        )
                        setFieldsData((prev) => ({
                            ...prev,
                            doers: [],
                        }))
                    }
                }
            }
            showNotice(t('task-updated-successfully'), t)
            setIsLoading(false)
        } catch (error) {
            errorsHandler(error, t)
            setIsLoading(false)
        }
    }

    const handleCopyLink = () => {
        const changedPath = window.location.href.replace('roadmap', 'tasks')
        navigator.clipboard
            .writeText(`${changedPath}/${task?.slug}`)
            .then(() => {
                showNotify(t('link-copied'))
            })
    }

    const fetchComments = async () => {
        try {
            if (currentProject?.id && task?.slug) {
                setIsLoading(true)
                const res = await tasksService.getTaskComments(
                    currentProject?.id,
                    task?.slug,
                )
                setComments(res || [])
                setIsLoading(false)
            }
        } catch (error) {
            errorsHandler(error, t)
            setIsLoading(false)
        }
    }

    const saveComment = async (value: string) => {
        try {
            setIsLoading(true)
            const res = await tasksService.addTaskComment(
                currentProject?.id,
                task?.slug,
                { text: value },
            )
            setComments((prev) => [...prev, res])
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            errorsHandler(error, t)
        }
    }

    const tabsItems = [
        {
            label: t('description'),
            key:'0',
            children:(
                <>
                <Description
                    key={task?.id}
                    initialText={task?.description}
                    projectId={task?.project}
                    taskSlug={task?.slug}
                />
                <SubTask task={task} />
            </>
            )
        },
        {
            label: t('comments'),
            key:'1',
            children:<Comment onSave={saveComment} comments={comments} />
        },
        {
            label: t('files'),
            key:'2',
            children:<TasksFiles task={task} setTask={(data) => {
                dispatch(
                    updateTask(data),
                )
            }} />
        },
    ]

    useEffect(() => {
        if (task !== undefined && isOpen) {
            setFieldsData((prev) => ({
                ...prev,
                name: getValidText(task?.name),
                slug: getValidText(task?.slug),
                priority: getValidText(task?.priority),
                status: getValidStatus(task),
                due_date_start: getValidText(task?.due_date_start),
                due_date_end: getValidText(task?.due_date_end),
                tags: getValidTagsOptions(task?.tags),
                doers:
                    task?.doers && task?.doers.length > 0
                        ? task?.doers.map((el) => el?.user?.id)
                        : undefined,
                supervisor: task.supervisor?.user?.id,
            }))
        }
    }, [task, isOpen])

    useEffect(() => {
        if (statuses && statuses.length > 0) {
            setStatusesOptions(
                statuses?.map((el) => ({
                    label: i18n.language === 'en' ? el?.name_en : el?.name_ru,
                    value: el?.id,
                })),
            )
        }
    }, [statuses, i18n])

    useEffect(() => {
        getMembersOptions()
    }, [members])

    useEffect(() => {
        if (debouncedName.length > 0 && debouncedName !== task?.name) {
            updateTaskFields({ name: debouncedName })
        }
    }, [debouncedName])

    useEffect(() => {
        fetchComments()
    }, [])

    return (
        <div
            className="roadmap-edit-task-block"
            onClick={(e) => {
                stopEvent(e)
            }}
        >
            {createPortal(<SpinInLog isLoading={isLoading || taskLoadingStatus} />, document.body)}
            {createPortal(
                <Modal
                    footer={null}
                    open={isOpen}
                    className="roadmap-add-task-modal"
                    onCancel={() => {
                        setIsOpen(false)
                    }}
                >
                    <div className={'roadmap-add-task-modal-inner'}>
                        <div className="roadmap-add-task-modal-settings">
                            <div className="roadmap-add-task-modal-task-data">
                                <div className="roadmap-add-task-modal-name-wrapper">
                                    <CopyDataIcon
                                        className="cursor-pointer"
                                        onClick={handleCopyLink}
                                    />
                                    <InputInLog
                                        placeholder={t('enter-name')}
                                        value={fieldsData.name}
                                        onChange={(e) =>
                                            setFieldsData((prev) => ({
                                                ...prev,
                                                name: e,
                                            }))
                                        }
                                    />
                                </div>
                                <div className="roadmap-add-task-modal-items-wrapper">
                                    <div className="roadmap-filter-field-wrap">
                                        <div className="roadmap-filter-field-label">
                                            {t('priority')}
                                        </div>
                                        <PrioritySelect
                                            onChange={(e) =>
                                                updateTaskFields({
                                                    priority: e,
                                                })
                                            }
                                            currentPriority={
                                                task.priority as PriorityTypes
                                            }
                                        />
                                    </div>
                                    <div className="roadmap-filter-field-wrap">
                                        <div className="roadmap-filter-field-label">
                                            {t('status')}
                                        </div>
                                        <SelectInLog
                                            allowClear={true}
                                            options={statusOptions}
                                            value={task.status}
                                            isMovedDropdownArrow={true}
                                            className="roadmap-filter-field"
                                            onChange={(e) =>
                                                updateTaskFields({ status: e })
                                            }
                                        />
                                    </div>
                                    <div className="roadmap-filter-field-wrap">
                                        <div className="roadmap-filter-field-label">
                                            {t('deadlines')}
                                        </div>
                                        <DatePicker.RangePicker
                                            allowClear
                                            format={'YYYY-MM-DD'}
                                            value={[
                                                moment(task.due_date_start),
                                                moment(task.due_date_end),
                                            ]}
                                            className="datePicker-item"
                                            locale={
                                                i18n.language === 'ru'
                                                    ? ru_RU
                                                    : en_EN
                                            }
                                            onChange={handleChangeRate}
                                        />
                                    </div>
                                    <div className="roadmap-filter-field-wrap">
                                        <div className="roadmap-filter-field-label">
                                            {t('tags')}
                                        </div>
                                        <SelectInLog
                                            allowClear={true}
                                            options={tagsOptions}
                                            mode="tags"
                                            value={fieldsData.tags}
                                            isMovedDropdownArrow={true}
                                            className="roadmap-filter-field roadmap-filter-field-tags"
                                            onChange={(e) =>
                                                updateTaskFields({ tags: e })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="roadmap-add-task-modal-members">
                                <div className="roadmap-filter-field-wrap">
                                    <div className="roadmap-filter-field-label">
                                        {t('supervisor')}
                                    </div>
                                    <SelectInLog
                                        allowClear={true}
                                        options={membersOptions}
                                        value={fieldsData.supervisor}
                                        isMovedDropdownArrow={true}
                                        className="roadmap-filter-field"
                                        onChange={changeSupervisor}
                                    />
                                </div>
                                <div className="roadmap-filter-field-wrap">
                                    <div className="roadmap-filter-field-label">
                                        {t('assignee')}
                                    </div>
                                    <SelectInLog
                                        options={membersOptions}
                                        isMovedDropdownArrow={true}
                                        mode="multiple"
                                        value={fieldsData?.doers}
                                        className="roadmap-filter-field"
                                        onChange={changeDoers}
                                    />
                                </div>
                            </div>
                        </div>
                        <TabsInlog
                            className="roadMap-add-task-tabs"
                            items={tabsItems}
                        />
                    </div>
                </Modal>,
                document.body,
            )}
        </div>
    )
}

export default RoadMapUpdateTaskBlock
