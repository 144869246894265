import React, {useState} from 'react';
import {LogsDataBaseTabs} from "./components/logsDataBaseTabs/LogsDataBaseTabs";
import LogsUpload from "./components/logsUpload/LogsUpload";

const Logs = () => {
    const [assessmentStatus, setAssessmentStatus] = useState(null)
    return (
        <div className={'max-w-750'}>
            <div className={'logs__uploadWrap'}>
                <LogsUpload assessmentStatus={assessmentStatus} setAssessmentStatus={setAssessmentStatus} />
                <LogsDataBaseTabs assessmentStatus={assessmentStatus}/>
            </div>
        </div>
    );
};

export default Logs;

