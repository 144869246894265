export const porosityTypes = (func) => [
    { label: func('open'), value: 'open' },
    {
        label: func('she-effective'),
        value: 'effective',
    },
    {
        label: func('she-dynamic'),
        value: 'dynamic',
    },
    { label: func('she-full'), value: 'full' },
]

export const saturationTypes = (func) => [
    {
        label: func('kerosene'),
        value: 'kerosene',
    },
    { label: func('helium'), value: 'helium' },
    { label: func('water'), value: 'water' },
]

export const permeabilityTypes = (func) => [
    {
        label: func('she-absolute'),
        value: 'absolute',
    },
    {
        label: func('she-effective'),
        value: 'effective',
    },
    {
        label: func('she-relative'),
        value: 'relative',
    },
]

export const permeabilityFluidTypes = (func) => [
    { label: func('helium'), value: 'helium' },
    { label: func('water'), value: 'water' },
    {
        label: func('nitrogen'),
        value: 'nitrogen',
    },
]

export const permeabilityComponentTypes = (func) => [
    {
        label: func('she-parallel'),
        value: 'parallel',
    },
    {
        label: func('she-perpendicular'),
        value: 'perpendicular',
    },
]

export const directionOptions = (func) => [
    {
        name: func('she-parallel'),
        value: 'parallel',
    },
    {
        value: 'perpendicular',
    },
] as any

export const saturationPageTypes = (func) => [
    {
        label: func('it-residual'),
        value: 'residual',
    },
    {
        label: func('it-initial'),
        value: 'initial',
    },
]
export const saturationFluidTypes = (func) => [
    { label: func('oil'), value: 'oil' },
    { label: func('water'), value: 'water' },
]

export const densityFluidTypes = (func) => [
    {
        label: func('she-volumetric'),
        value: 'volumetric',
    },
    {
        label: func('she-mineral'),
        value: 'mineral',
    },
]

export const youngModulusTypes = (func) => [
    {
        label: func('static'),
        value: 'static',
    },
    {
        label: func('dynamic'),
        value: 'dynamic',
    },
]

export const acousticWaveTypes = (func) => [
    {
        label: func('she-compressional'),
        value: 'compressional',
    },
    {
        label: func('she-shear'),
        value: 'shear',
    },
]

export const compressibilityTypes = (func) => [
    {
        label: func('she-volumetric'),
        value: 'volumetric',
    },
    {
        label: func('she-mineral'),
        value: 'mineral',
    },
    {
        label: func('she-effective'),
        value: 'effective',
    },
    {
        label: func('porousMedia'),
        value: 'porous_media',
    },
]
