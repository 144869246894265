import { createSlice } from '@reduxjs/toolkit'
import { IProject } from '../../app/types/models/project'
import {
    addPad,
    addProject,
    addWell,
    addWellBore,
    addWellBoreCore,
    addWellCore,
    deletePad,
    deleteProject,
    deleteWell,
    deleteWellBore,
    deleteWellBoreCore,
    deleteWellCore,
    setIsCollpsedProjectEntityStatus,
    setIsOpenProjectsMenuStatus,
    setPads,
    setProjects,
    setProjectsLoading,
    setWellBoreCores,
    setWellBores,
    setWellCores,
    setWells,
    updatePad,
    updateProject,
    updateProjectName,
    updateWell,
    updateWellBore,
    updateWellBoreCore,
    updateWellCore,
} from './actions'

export interface InitialState {
    projects: IProject[]
    loading: boolean
    isOpenProjectsMenu: boolean
}

const initialState: InitialState = {
    projects: [],
    loading: false,
    isOpenProjectsMenu: true,
}

export const slice = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setProjectsLoading, (state, action) => {
            state.loading = action.payload.loading
        })
        builder.addCase(setProjects, (state, action) => {
            state.projects = action.payload.projects
        })
        builder.addCase(addProject, (state, action) => {
            state.projects = [action.payload.project, ...state.projects]
        })
        builder.addCase(updateProject, (state, action) => {
            state.projects = state.projects.map(el=>el.id===action.payload?.id ? action.payload : el)
        })
        builder.addCase(deleteProject, (state, action) => {
            state.projects = state.projects.filter(
                (item) => item?.id !== action.payload.id,
            )
        })
        builder.addCase(updateProjectName, (state, action) => {
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id
                    ? { ...item, name: action.payload.name }
                    : item,
            )
        })
        builder.addCase(setPads, (state, action) => {
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id
                    ? { ...item, pads: action.payload.pads }
                    : item,
            )
        })
        builder.addCase(addPad, (state, action) => {
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id
                    ? { ...item, pads: [action.payload.pad, ...item.pads] }
                    : item,
            )
        })
        builder.addCase(updatePad, (state, action) => {
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id
                    ? {
                          ...item,
                          pads: item.pads.map((pad) =>
                              pad.id === action.payload.padId
                                  ? { ...pad, name: action.payload.name }
                                  : pad,
                          ),
                      }
                    : item,
            )
        })
        builder.addCase(deletePad, (state, action) => {
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id
                    ? {
                          ...item,
                          pads: item.pads.filter(
                              (p) => p.id !== action.payload.padId,
                          ),
                      }
                    : item,
            )
        })
        builder.addCase(setWells, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            const pads = targetProject?.pads.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: action.payload.wells.filter(
                              (well) => well.well_pad === action.payload.padId,
                          ),
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        })
        builder.addCase(addWell, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            if(targetProject && targetProject?.pads && targetProject?.pads?.length>0){

            
            const pads = targetProject?.pads?.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: [action.payload.well, ...pad.wells],
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        }
        })
        builder.addCase(updateWell, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            const pads = targetProject.pads.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: pad.wells.map((well) =>
                              well.id === action.payload.wellId
                                  ? { ...well, name: action.payload.name }
                                  : well,
                          ),
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        })
        builder.addCase(deleteWell, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            const pads = targetProject?.pads.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: pad.wells.filter(
                              (well) => well.id !== action.payload.wellId,
                          ),
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        })
        builder.addCase(setWellCores, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            const pads = targetProject?.pads.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: pad.wells.map((well) =>
                              action.payload.wellId === well.id
                                  ? { ...well, cores: action.payload.cores }
                                  : well,
                          ),
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        })
        builder.addCase(addWellCore, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            const pads = targetProject?.pads.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: pad.wells.map((well) =>
                              action.payload.wellId === well.id
                                  ? {
                                        ...well,
                                        cores: [
                                            action.payload.core,
                                            ...well.cores,
                                        ],
                                    }
                                  : well,
                          ),
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        })
        builder.addCase(updateWellCore, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            const pads = targetProject?.pads.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: pad.wells.map((well) =>
                              action.payload.wellId === well.id
                                  ? {
                                        ...well,
                                        cores: well.cores.map((core) =>
                                            core.id === action.payload.coreId
                                                ? {
                                                      ...core,
                                                      name: action.payload.name,
                                                  }
                                                : core,
                                        ),
                                    }
                                  : well,
                          ),
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        })
        builder.addCase(deleteWellCore, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            const pads = targetProject?.pads.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: pad.wells.map((well) =>
                              action.payload.wellId === well.id
                                  ? {
                                        ...well,
                                        cores: well.cores.filter(
                                            (item) =>
                                                item?.id !==
                                                action.payload.coreId,
                                        ),
                                    }
                                  : well,
                          ),
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        })
        builder.addCase(setWellBores, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            const pads = targetProject?.pads.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: pad.wells.map((well) =>
                              action.payload.wellId === well.id
                                  ? {
                                        ...well,
                                        wellBores: action.payload.wellBores,
                                    }
                                  : well,
                          ),
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        })
        builder.addCase(addWellBore, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            const pads = targetProject?.pads.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: pad.wells.map((well) =>
                              action.payload.wellId === well.id
                                  ? {
                                        ...well,
                                        wellBores: [
                                            action.payload.wellBore,
                                            ...well.wellBores,
                                        ],
                                    }
                                  : well,
                          ),
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        })
        builder.addCase(updateWellBore, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            const pads = targetProject?.pads.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: pad.wells.map((well) =>
                              action.payload.wellId === well.id
                                  ? {
                                        ...well,
                                        wellBores: well.wellBores.map(
                                            (wellBore) =>
                                                wellBore.id ===
                                                action.payload.wellBoreId
                                                    ? {
                                                          ...wellBore,
                                                          name: action.payload
                                                              .name,
                                                      }
                                                    : wellBore,
                                        ),
                                    }
                                  : well,
                          ),
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        })
        builder.addCase(deleteWellBore, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            const pads = targetProject?.pads.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: pad.wells.map((well) =>
                              action.payload.wellId === well.id
                                  ? {
                                        ...well,
                                        wellBores: well.wellBores.filter(
                                            (item) =>
                                                item.id !==
                                                action.payload.wellBoreId,
                                        ),
                                    }
                                  : well,
                          ),
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        })
        builder.addCase(setWellBoreCores, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            const pads = targetProject?.pads.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: pad.wells.map((well) =>
                              action.payload.wellId === well.id
                                  ? {
                                        ...well,
                                        wellBores: well.wellBores.map(
                                            (wellBore) =>
                                                wellBore.id ===
                                                action.payload.wellBoreId
                                                    ? {
                                                          ...wellBore,
                                                          cores: action.payload
                                                              .cores,
                                                      }
                                                    : wellBore,
                                        ),
                                    }
                                  : well,
                          ),
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        })
        builder.addCase(addWellBoreCore, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            const pads = targetProject?.pads.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: pad.wells.map((well) =>
                              action.payload.wellId === well.id
                                  ? {
                                        ...well,
                                        wellBores: well.wellBores.map(
                                            (wellBore) =>
                                                wellBore.id ===
                                                action.payload.wellBoreId
                                                    ? {
                                                          ...wellBore,
                                                          cores: [
                                                              action.payload
                                                                  .core,
                                                              ...wellBore.cores,
                                                          ],
                                                      }
                                                    : wellBore,
                                        ),
                                    }
                                  : well,
                          ),
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        })
        builder.addCase(updateWellBoreCore, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            const pads = targetProject?.pads.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: pad.wells.map((well) =>
                              action.payload.wellId === well.id
                                  ? {
                                        ...well,
                                        wellBores: well.wellBores.map(
                                            (wellBore) =>
                                                wellBore.id ===
                                                action.payload.wellBoreId
                                                    ? {
                                                          ...wellBore,
                                                          cores: wellBore.cores.map(
                                                              (core) =>
                                                                  core.id ===
                                                                  action.payload
                                                                      .coreId
                                                                      ? {
                                                                            ...core,
                                                                            name: action
                                                                                .payload
                                                                                .name,
                                                                        }
                                                                      : core,
                                                          ),
                                                      }
                                                    : wellBore,
                                        ),
                                    }
                                  : well,
                          ),
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        })
        builder.addCase(deleteWellBoreCore, (state, action) => {
            const targetProject: IProject = state.projects.find(
                (project) => project.id === action.payload.id,
            )
            const pads = targetProject?.pads.map((pad) =>
                pad.id === action.payload.padId
                    ? {
                          ...pad,
                          wells: pad.wells.map((well) =>
                              action.payload.wellId === well.id
                                  ? {
                                        ...well,
                                        wellBores: well.wellBores.map(
                                            (wellBore) =>
                                                wellBore.id ===
                                                action.payload.wellBoreId
                                                    ? {
                                                          ...wellBore,
                                                          cores: wellBore.cores.filter(
                                                              (core) =>
                                                                  core.id !==
                                                                  action.payload
                                                                      .coreId,
                                                          ),
                                                      }
                                                    : wellBore,
                                        ),
                                    }
                                  : well,
                          ),
                      }
                    : pad,
            )
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id ? { ...item, pads } : item,
            )
        })
        builder.addCase(setIsOpenProjectsMenuStatus, (state, action) => {
            state.isOpenProjectsMenu = action.payload
        })
        builder.addCase(setIsCollpsedProjectEntityStatus, (state, action) => {
            switch (action.payload.type) {
                case 'project':
                    state.projects = state.projects.map((pr) =>
                        pr.id === action.payload.data.projectId
                            ? { ...pr, isCollapsed: action.payload.status }
                            : pr,
                    )
                    break;
                case 'pad':
                    state.projects = state.projects.map((pr) =>
                        pr.id === action.payload.data.projectId
                            ? { ...pr, isCollapsed: action.payload.status,pads:pr.pads.map(pad=>pad.id===action.payload.data?.padId ? {...pad,isCollapsed:action.payload.status} : pad) }
                            : pr,
                    )
                    break
                default:
                    break
            }
        })
    },
})
