import { Modal } from 'antd';
import imageCompression from 'browser-image-compression';
import React, { FC, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-image-crop/dist/ReactCrop.css';
import { ButtonItem } from '../../app/types/i-button';
import { useUserMedia } from '../../hooks/useUserMedia';
import PhotoIcon from '../../image_files/icons/PhotoIcon';
import TrashIcon from '../../image_files/icons/TrashIcon';
import UploadPhotoIcon from '../../image_files/icons/UploadPhotoIcon';
import { detectWebcam } from '../../services/detect-webcam';
import { getBase64FormatFromReader } from '../../services/get-base64-from-reader';
import { convertBase64 } from '../../utils/helpers/convertBase64';
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler';
import { getValidText } from '../../utils/helpers/getValidText';
import CameraBox from '../camera-box/CameraBox';
import CropImage from '../crop-image/CropImage';
import ModalInlog from '../modalInlog/ModalInlog';
import { IUploadFile } from '../uploadButton/types';
import './ChangePhotoMenu.css';
import { IChangePhotoMenuProps } from './types';

const initialSettings = {
  menu: false,
  deleteImage: false,
  info: false,
  camera: false,
  file: false
};

type InitialSettings = typeof initialSettings;

const ChangePhotoMenu: FC<IChangePhotoMenuProps> = (props) => {
  const {
    mode,
    accept,
    className,
    isDeleteMode,
    isCroopingMode,
    openModalTrigger,
    onSave,
    onRemove
  } = props;
  const { t } = useTranslation();
  const {isHasCamera:cameraStatus, refresh: refreshDeficesDataStatus } = useUserMedia([
    'user', 'environment'
  ]);
  const inputLocalRef = useRef<HTMLInputElement | null>();
  const [file, setFile] = useState<IUploadFile>({} as IUploadFile);
  const [changedFile, setChangedFile] = useState<IUploadFile>(
    {} as IUploadFile
  ); //one more state to avoid infinity loop. Use for File from file explorer
  const [resetCameraTrigger, setResetCameraTrigger] = useState(1);
  const [openModal, setOpenModal] = useState<InitialSettings>(initialSettings);
  const [isHasCamera, setIshasCamera] = useState<boolean>(false);
  const [isCameraBlocked, setICameraBlocked] = useState<boolean>(false);

  const openFileExplorer = () => {
    if (inputLocalRef && inputLocalRef?.current) {
      inputLocalRef?.current?.click();
    }
  };

  const toggleWindowMode = (field: keyof InitialSettings, value: boolean) => {
        setOpenModal({ ...initialSettings, [`${field}`]: value });
    if (field === 'camera') {
      setResetCameraTrigger((prev) => prev + 1);
    }
  };

  const deleteModalButtonsList: ButtonItem[] = [
    {
      id: 1,
      titleBtn: t('cancel'),
      handleBtn: () => toggleWindowMode('deleteImage', false),
      type: 'default'
    },
    {
      id: 2,
      titleBtn: t('delete'),
      handleBtn: () => {
        onRemove && onRemove();
        toggleWindowMode('deleteImage', false);
      },
      type: 'primary'
    }
  ];

  const filePreviewModalButtonsList: ButtonItem[] = [
    {
      id: 1,
      titleBtn: t('select-other-photo'),
      handleBtn: () => {
        setOpenModal(initialSettings);
        setFile({} as IUploadFile);
        setChangedFile({} as IUploadFile);
        openFileExplorer();
      },
      type: 'default'
    },
    {
      id: 2,
      titleBtn: t('save-photo'),
      handleBtn: () => {
        onSave && onSave(changedFile);
        setOpenModal(initialSettings);
      },
      type: 'primary'
    }
  ];

  const getAcceptExtensions = () => {
    if (accept && accept?.length > 0) {
      const changedAccept = accept?.map((el) => `.${el.trim()}`);
      return changedAccept?.join(',');
    } else return undefined;
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileData = e?.target?.files && e.target.files[0];
    if (fileData) {
      const data = await convertBase64(fileData);
      const prepapredData: IUploadFile = {
        file: fileData,
        view: data?.view,
        name: fileData?.name?.split('.')[0],
        extension: fileData?.name?.split('.')[1],
        base64ToServer: getBase64FormatFromReader(data?.view) // string without data:image/jpeg;base64, part
      };
      if (accept) {
        if (accept?.includes(fileData?.name?.split('.')[1])) {
          setFile(prepapredData);
          e.target.value = '';
        } else {
          errorsHandler(t('select-files-with-correct-format-value', { value: accept.join(', ') }), t)
        }
      } else {
        setFile(prepapredData);
        e.target.value = '';
      }
      setOpenModal(initialSettings);
    }
  };

  useEffect(() => {
    if (openModalTrigger > 0) {
      if (mode === 'file-explorer') {
        openFileExplorer();
      } else {
        if (mode === 'camera') {
          setOpenModal({ ...openModal, camera: true });
        } else {
          setOpenModal({ ...initialSettings, menu: true });
        }
      }
    } else {
      setOpenModal(initialSettings);
    }
  }, [openModalTrigger]);

  detectWebcam(val=>{
    setIshasCamera(val)
  })

  useEffect(() => {
    if (file?.view) {
      toggleWindowMode('file', true);
    }
  }, [file]);

  useEffect(()=>{
    setICameraBlocked(!cameraStatus)
  },[cameraStatus])

  useEffect(()=>{
    if(openModal.menu){
      refreshDeficesDataStatus()
    }
  },[openModal.menu])

  return (
    <div className={`change-photo-menu-wrapper ${getValidText(className)}`}>
      <input
        type="file"
        ref={inputLocalRef as RefObject<HTMLInputElement>}
        id="upload-button-file"
        accept={getAcceptExtensions()}
        className="photo-upload-input-file"
        onChange={handleFileChange}
      />

      {/* menu modal */}
      <ModalInlog
        title={t('photo-changing')}
        open={openModal.menu}
        onCancel={() => toggleWindowMode('menu', false)}
        footer={<></>}
      >
        <div className="change-photo-menu-content">
          {
            isHasCamera &&
            <div
              className="change-photo-menu-content-item"
              onClick={() => toggleWindowMode('camera', true)}
            >
              <PhotoIcon />
              <span className="font-16-normal">{t('make-photo')}</span>
            </div>
          }
          <div
            className="change-photo-menu-content-item"
            onClick={openFileExplorer}
          >
            <UploadPhotoIcon />
            <span className="font-16-normal">{t('upload-from-galery')}</span>
          </div>
          {isDeleteMode && (
            <div
              className="change-photo-menu-content-item"
              onClick={() => toggleWindowMode('deleteImage', true)}
            >
              <TrashIcon />
              <span className="font-16-normal">{t('delete')}</span>
            </div>
          )}
        </div>
      </ModalInlog>

      {/* delete modal */}
      <ModalInlog
        title={t('photo-deleting')}
        open={openModal.deleteImage}
        onCancel={() => toggleWindowMode('deleteImage', false)}
        listButton={deleteModalButtonsList}
      >
        <div className="change-photo-menu-content">
          <span className="font-16-normal">{t('you-sure-to-delete-photo')}</span>
        </div>
      </ModalInlog>

      {/* camera modal */}
      <Modal
        width={640}
        footer={null}
        open={openModal.camera}
        title={t('make-photo')}
        className={'change-photo-menu-camera-modal'}
        onCancel={() => toggleWindowMode('camera', false)}
      >
        <div className={'change-photo-menu-camera-modal-inner'}>
          <CameraBox
            isCroopingMode={isCroopingMode}
            resetTrigger={resetCameraTrigger}
            isHasCamera={!isCameraBlocked}
            onSave={(dataUrl: string) => {
              const nameArr = file?.name?.split('.');
              const name = nameArr && nameArr[0] ? nameArr[0] : 'photo';
              const extension = nameArr && nameArr[1] ? nameArr[1] : 'jpg';
              imageCompression
                .getFilefromDataUrl(dataUrl, getValidText(file?.name))
                .then((res) => {
                  onSave &&
                    onSave({
                      name,
                      view: dataUrl,
                      extension,
                      base64ToServer: getBase64FormatFromReader(dataUrl),
                      file: res
                    });
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
            closeModal={() => toggleWindowMode('camera', false)}
          />
        </div>
      </Modal>

      {/* Priview modal for file */}
      <ModalInlog
        title={t('make-photo')}
        className={'change-photo-menu-camera-modal'}
        open={openModal.file}
        onCancel={() => toggleWindowMode('file', false)}
        listButton={filePreviewModalButtonsList}
        width={640}
      >
        {file?.view && (
          <div className={'change-photo-menu-file-modal-inner'}>
            <CropImage
              disableCropImage={!isCroopingMode}
              url={file?.view}
              setUrl={(dataUrl) => {
                const nameArr = file?.name?.split('.');
                const name = nameArr && nameArr[0] ? nameArr[0] : 'photo';
                const extension = nameArr && nameArr[1] ? nameArr[1] : 'jpg';
                console.log(name, extension, '--name,extension');
                imageCompression
                  .getFilefromDataUrl(
                    getValidText(dataUrl),
                    getValidText(file?.name)
                  )
                  .then((res) => {
                    setChangedFile({
                      name,
                      view: getValidText(dataUrl),
                      extension,
                      base64ToServer: getBase64FormatFromReader(
                        getValidText(dataUrl)
                      ),
                      file: res
                    });
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }}
            />
          </div>
        )}
      </ModalInlog>
    </div>
  );
};

export default ChangePhotoMenu;
