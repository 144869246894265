import './studiesIconWrap.css'
import { FC, ReactElement } from 'react'
import React from 'react'

interface IProps {
    title?: string
    icon: ReactElement
    isActive: boolean
    handler: () => void
}

const StudiesIconWrap: FC<IProps> = ({ handler, isActive, icon, title }) => {
    return (
        <div
            title={title}
            onClick={(e)=> {
                e.preventDefault()
                e.stopPropagation()
                handler()
            }}
            className={`coreStudiesIconWrap ${
                isActive ? 'coreStudiesIconWrap__active' : ''
            }`}
        >
            {icon}
        </div>
    )
}

export { StudiesIconWrap }
