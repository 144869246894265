export const mocMnemonic = {
    id: 1,
    log_en: '',
    log_ru: '',
    inlog_mnemonic: "",
    inlog_unit_and_conversion_en: [
        {scale: '', value: '', id: 1},

    ],
    inlog_unit_and_conversion_ru: [
        {scale: '', value: '', id: 1},
    ],
    inlog_description_en: '',
    inlog_description_ru: '',
    possible_mnemonics: [""],
    constant_mnemonic: false,
}