import { ICalendarGroup } from '../../../../../app/types/i-roadmap-calendar'

export const getGroupsByQuarterDetalization = (
    calendarGroups: ICalendarGroup[],
    data: {
        priorityColor: string
        startDate: string | Date
        endDate: string | Date
    },
) => {
    let { startDate, endDate, priorityColor } = data
    startDate = new Date(startDate)
    endDate = new Date(endDate)

    const startYear = startDate.getFullYear()
    const startMonth = startDate.getMonth()
    const startDay = startDate.getDate()

    const endYear = endDate.getFullYear()
    const endMonth = endDate.getMonth()
    const endDay = endDate.getDate()

    return calendarGroups.map((quarter) => {
        const quarterYear = quarter.year

        const getQuarter = (data:{
            startMonth:number,
            startDay:number,
            endMonth:number,
            endDay:number,
        }) => {
            const {startMonth,startDay,endMonth,endDay} = data
           return {...quarter,
            list: quarter.list.map((month) => {
                const monthIndex = month?.index
                const days = month?.days || []
                const daysLength = days.length
                const firstPercent = (startDay / daysLength) * 100
                const endPercent = (endDay / daysLength) * 100

                const getMonth = (interval:[number,number]) =>{
                    return {
                        ...month, value: {
                            ...month?.value,
                            interval,priorityColor,
                        },
                    }
                }

                if (monthIndex === startMonth && monthIndex === endMonth) {
                    return getMonth([firstPercent, endPercent])
                } else if (
                    monthIndex === startMonth &&
                    monthIndex !== endMonth
                ) {
                    return getMonth([firstPercent, 100])
                } else if (
                    monthIndex !== startMonth &&
                    monthIndex === endMonth
                ) {
                    return getMonth([0, endPercent])
                } else if (
                    monthIndex !== startMonth &&
                    monthIndex !== endMonth
                ) {
                    if(monthIndex>startMonth && monthIndex<endMonth){
                        return getMonth([0, 100])
                    }else if(monthIndex<startMonth || monthIndex>endMonth){
                        return getMonth([0, 0])
                    }else return month
                } else return month
            })}
        }

        if(startYear===quarterYear && endYear===quarterYear ){
            return getQuarter({startMonth,endMonth,startDay,endDay})
        }else if(startYear===quarterYear && endYear!==quarterYear ){
            return getQuarter({startMonth,endMonth:11,startDay,endDay:31})
        }else if(startYear!==quarterYear && endYear===quarterYear){
            return getQuarter({startMonth:0,endMonth,startDay:0,endDay})
        } else if(quarterYear>startYear && quarterYear<endYear){
            return {...quarter,list:quarter.list.map((month)=>{
                return {
                    ...month, value: {
                        ...month?.value,
                        interval:[0,100],priorityColor,
                    },
                }
            })}
        }else {
            return {...quarter,list:quarter.list.map((month)=>{
                return {
                    ...month, value: {
                        ...month?.value,
                        interval:[0,0],priorityColor,
                    },
                }
            })}
        }
    }) as ICalendarGroup[]
}
