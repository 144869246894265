import React, {FC, useEffect, useState} from 'react';
import NoticeIcon from "../../image_files/icons/NoticeIcon";
import './noticeBlock.css';
import {Modal} from "antd";
import LatestUpdates from "./components/latestUpdates/LatestUpdates";
import {useTranslation} from "react-i18next";
import SimpleBar from "simplebar-react";
import {INotification} from "../../app/types/models/i-notification";
import {useNavigate} from "react-router-dom";
import {INoticeBlockProps} from "./types";
import {XIcon} from "../../image_files/icons/XIcon";
import {useSelector} from "react-redux";
import {selectNotifications} from "../../store/notifications/selectors";

const NoticeBlock: FC<INoticeBlockProps> = (props) => {
    const {wsNotifications,refetchNotifications} = props
    const [openModal, setOpenModal] = useState(false)
    const {t} = useTranslation()
    const [unReadNotices, setUnReadNotices] = useState<INotification[]>([])
    const navigate = useNavigate()
    const count = unReadNotices?.length
    const notifications = useSelector(selectNotifications)

    const navigateToPage = (path: string) => {
        navigate('/settings/notifications', {
            state: {
                tab: path,
            }
        })
        setOpenModal(false)
    }

    useEffect(() => {
        if(notifications && Array.isArray(notifications) && notifications.length>0){
            const list = notifications.filter(el=>!(el?.is_read))
            setUnReadNotices(list)
        }
    }, [notifications])

    useEffect(()=>{
        if(wsNotifications?.length>0){
            setOpenModal(true)
        }
    },[wsNotifications])

    useEffect(()=>{
        if(openModal){
            refetchNotifications && refetchNotifications()
        }
    },[openModal])

    return (
        <>
            <div className='notice-block flex-c-c cursor-pointer' onClick={() => setOpenModal(true)}>
                <NoticeIcon/>
                {count > 0 &&
                    <div className={count > 99 ? 'notice-block-count-m' : 'notice-block-count flex-c-c'}>
                        <span className={count > 99 ? 'font-10-normal' : 'font-12-normal'}>{count}</span>
                    </div>
                }
            </div>
            <Modal
                wrapClassName={'notice-block-wrap-modal'}
                open={openModal} onCancel={() => setOpenModal(false)}
                footer={null}
                closeIcon={<XIcon width={13} height={13} className={'notification-top-cancel-icon'}/>}
            >
                <div className={'notice-block-tabs'}>
                    <div className='notice-block-tabs-top'>
                        <div className={`font-20-normal cursor-pointer notice-block-tab-active`}>{t('latest-updates').toString()}</div>
                        <div className={`font-20-normal cursor-pointer`}
                             onClick={() => navigateToPage('all-notices')}>{t('all').toString()}</div>
                        <div className={`font-20-normal cursor-pointer`}
                             onClick={() => navigateToPage('settings')}>{t('settings').toString()}</div>
                    </div>
                    <SimpleBar className={'notice-block-tabs-sb'} autoHide={false}>
                        <div className={'notice-block-tabs-wrapper'}>
                            <LatestUpdates notifications={unReadNotices}
                                           setNotifications={setUnReadNotices}
                                           closeModal={()=>setOpenModal(false)}
                            />
                        </div>
                    </SimpleBar>
                </div>
            </Modal>
        </>
    )
};

export default NoticeBlock;