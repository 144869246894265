import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getValidText } from '../../../../../../utils/helpers/getValidText';
import AddCoreBoxPhotoItem from '../addCoreBoxPhotoItem/AddCoreBoxPhotoItem';
import CoreBoxRadioContent from '../coreBoxRadioContent/CoreBoxRadioContent';
import CoreBoxCanvas from './components/coreBoxCanvas.tsx/CanvasComponent';
import './coreBoxPreparing.css';
import { ICoreBoxPreparingProps } from './types';
import { ICoreBoxPhotoResponse } from '../../../../../../app/types/dto/core-box-data';

const CoreBoxPreparing: FC<ICoreBoxPreparingProps> = ({
    currentBox,
    onSave,
}) => {
    const { t } = useTranslation()
    const [fieldError,setFieldError] = useState('')
    const [data, setData] = useState<ICoreBoxPhotoResponse>({} as ICoreBoxPhotoResponse)
    
    const onChange = (name: string, value: string) => {
        setData({
            ...data,
            [name]: value,
        })
    }

    const handleSave = (url:string) =>{
        onSave({ ...data,photo : { ...data.photo,medium: url } })
    }

    useEffect(() => {
        setData(currentBox)
    }, [currentBox])

    return (
        <div className="core-box-preparing">
            <div className="core-box-prepaparing-photo-space">
                <CoreBoxCanvas url={getValidText(currentBox?.photo?.medium)} onSave={handleSave} />
            </div>
            <div className="core-box-prepaparing-content-space">
                <AddCoreBoxPhotoItem
                    name="column_length"
                    type="input"
                    title={t('core-column-length')}
                    // isShowError={true}
                    rules={[
                        {
                            isRequired:true,
                            pattern:/^\d(?:\.\d{1,2})?$/,
                            message:t('can-to-input-max-symbols-count-by-exemples',{value:3,example:1.23})
                        }
                    ]} 
                    value={getValidText(data['column_length'])}
                    
                    onChange={onChange}
                    setFieldError={setFieldError}
                />
                <AddCoreBoxPhotoItem
                    type="radio"
                    title={t('direction-of-core-selection')}
                    value={data['direction']}
                    name={'direction'}
                    radioItems={[
                        {
                            value: 'left_to_right',
                            content: CoreBoxRadioContent({
                                text: t('from-left-to-right'),
                                arrow: 'right',
                            }),
                        },
                        {
                            value: 'right_to_left',
                            content: CoreBoxRadioContent({
                                text: t('from-right-to-left'),
                                arrow: 'left',
                            }),
                        },
                    ]}
                    onChange={onChange}
                />
                <AddCoreBoxPhotoItem
                    type="radio"
                    value={getValidText(data['light'])}
                    name={'light'}
                    title={t('lighting')}
                    radioItems={[
                        {
                            value: 'visible',
                            content: CoreBoxRadioContent({
                                text: t('visible-light'),
                            }),
                        },
                        {
                            value: 'ultravioletLight',
                            content: CoreBoxRadioContent({
                                text: t(
                                    'ultraviolet-light',
                                ),
                            }),
                        },
                    ]}
                    onChange={onChange}
                />
                <AddCoreBoxPhotoItem
                    type="radio"
                    value={getValidText(data['loading_process'])}
                    title={t('loading-process-a-core-into-box')}
                    name={'loading_process'}
                    radioItems={[
                        {
                            value: 'laid_down',
                            content: CoreBoxRadioContent({
                                text: t('laid-down'),
                            }),
                        },
                        {
                            value: 'poured_out',
                            content: CoreBoxRadioContent({
                                text: t('poured-out'),
                            }),
                        },
                    ]}
                    onChange={onChange}
                />
                <AddCoreBoxPhotoItem
                    type="radio"
                    value={getValidText(data['lithologist_check'] ? 'yes' : 'no')}
                    title={t(
                        'lithologist-checked-the-installation',
                    )}
                    name={'lithologist_check'}
                    radioItems={[
                        {
                            value: 'yes',
                            content: CoreBoxRadioContent({
                                text: t('yes'),
                            }),
                        },
                        {
                            value: 'no',
                            content: CoreBoxRadioContent({
                                text: t('no'),
                            }),
                        },
                    ]}
                    onChange={onChange}
                />
                <AddCoreBoxPhotoItem
                    name="comment"
                    value={getValidText(data['comment'])}
                    type="textarea"
                    title={t('comment')}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}

export default CoreBoxPreparing
