import { useSelector } from 'react-redux'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { findCurrentPathFromString } from '../../utils/findCurrentPathFromString'
import { useTranslation } from 'react-i18next'
import HomeRoutes from './components/HomeRoutes'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { projectsActions, projectsSelectors } from '../../store/projects'
import { ACCESS_TOKEN, routes } from '../../utils/constants'
import { projectsService } from '../../services/projects-service.'
import { authService } from '../../services/auth-service'
import { userService } from '../../services/user-service'
import { userActions, userSelectors } from '../../store/user'
import './home.css';
import Header from "../../entities/header/Header";
import { LargeSpin } from "../../shared/LargeSpin";
import Navigation from "../../features/navigation/Navigation";
import { invitationService } from "../../services/invitation-service";
import { showNotice } from "../../utils/helpers/showNotice";
import { errorsHandler } from "../../utils/helpers/errors/errors-hendler";

function Home() {
    let location = useLocation()
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const changeEmail = useSelector((state: any) => state.change)
    const { selectProjects } = projectsSelectors
    const userData = useSelector(userSelectors.selectUser)
   
    const dispatch = useAppDispatch()
    const { setProjects } = projectsActions
    const { setUser } = userActions
    const [loading, setLoading] = useState(false)
    const projects = useSelector(selectProjects)
    const [isVerified, setIsVerified] = useState(false)

    const verifyToken = useCallback(async () => {
        try {
            await authService.verifyToken({
                token: JSON.parse(localStorage.getItem(ACCESS_TOKEN)),
            })
            setIsVerified(true)
        } catch (e) {
            console.log(e)
            setIsVerified(false)
            navigate(routes.LOGIN)
        }
    }, [localStorage.getItem(ACCESS_TOKEN)])

    const updateProjects = useCallback(async (): Promise<void> => {
        try {
            setLoading(true)
            const projects = await projectsService.getProjects()
            dispatch(setProjects({ projects }))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }, [projects])

    const getUserData = useCallback(async (): Promise<void> => {
        try {
            const user = await userService.fetchUser()
           
            dispatch(setUser({ user }))
            i18n.changeLanguage(user.settings.language)
        } catch (e) {
            console.log(e)
        }
    }, [userData,i18n.language])

    const verifyAndFetchData = async (): Promise<void> => {
        try {
            if (localStorage.getItem(ACCESS_TOKEN)) {
                await verifyToken()
                await updateProjects()
            } else {
                navigate(routes.LOGIN)
            }
        } catch (e) {
            navigate(routes.LOGIN)
            console.log(e)
        }
    }

    useEffect(() => { 
         getUserData()
         }, [])

    const fetchProjectInviteProcess = async () => {
        try {
            if (localStorage.getItem('copy-link')) {
                const copyLinkData = JSON.parse(localStorage.getItem('copy-link'))
                await invitationService.addUserInvitation(Number(copyLinkData?.project), { token: copyLinkData?.token })
                showNotice('you-added-to-project-successfully', t)
                localStorage.removeItem('copy-link')
            }
        } catch (e) {
            errorsHandler(e, t)
            localStorage.removeItem('copy-link')
        }

    }

    const changeLeftMenuItems = useMemo((): string => {
        if (findCurrentPathFromString(location.pathname, 'settings')) {
            return `${t('profile')}`
        }
        if (
            findCurrentPathFromString(location.pathname, 'logging') ||
            findCurrentPathFromString(location.pathname, 'firstProject')
        ) {
            return t('data-logging')
        }
        if (findCurrentPathFromString(location.pathname, 'scheduler')) {
            return t('scheduler')
        }
    }, [location.pathname, i18n.language])

    useEffect(() => {
        if (location.pathname === '/*' || location.pathname === '/') {
            navigate(routes.LOGGING)
        }
        verifyAndFetchData()
        fetchProjectInviteProcess()
    }, [])

    if (loading || !isVerified) {
        return <LargeSpin />
    }
    return (
        <>
            <div className="UserProfile">
                <Navigation />
                <div className="user_main_box">
                    <Header currentTitle={changeLeftMenuItems} />
                    <HomeRoutes />
                </div>
                {changeEmail ? (
                    <div className="alert_change_div">
                        <div className="alert_exit_box">
                            <button className="alert_exit">+</button>
                        </div>
                        <p className="alert_text">
                            {`${t('email-changed-success')}`}
                        </p>
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default Home
