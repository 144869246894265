import React, { useEffect, useState } from 'react';
import TableInLog from "../../../../shared/table/Table";
import { useTranslation } from "react-i18next";
import { getDefaultRowsList } from "./getDefaultRowsList";
import { Skeleton, Switch } from "antd";
import { userService } from "../../../../services/user-service";
import { errorsHandler } from "../../../../utils/helpers/errors/errors-hendler";
import { showNotice } from "../../../../utils/helpers/showNotice";
import { SettingTypes } from "./types";
import { IUserSettingsData } from "../../../../app/types/models/users";
import './allSettings.css'
import SelectInLog from '../../../../shared/select/Select';
import { mocTimesData } from '../../../../utils/moc/moc-times';
import { getValidText } from '../../../../utils/helpers/getValidText';
import { weekDaysMoc } from '../allNotifications/data';

const AllSettings = () => {
    const { t, i18n } = useTranslation()
    const [tableData, setTableData] = useState(getDefaultRowsList(t))
    const [loading, setLoading] = useState(false)
    const [dateTimeloading, setDateTimeLoading] = useState(false)
    const [allEmailToggleStatus, setAllEmailToggleStatus] = useState(false)
    const [allInlogToggleStatus, setAllInlogToggleStatus] = useState(false)
    const [settingsData, setSettingsData] = useState<IUserSettingsData>({} as IUserSettingsData)
    const [weekDays, setWeekDays] = useState(weekDaysMoc['ru'])
    const [currentDate, setCurrentDate] = useState({
        day: [7],
        interval: ['14:00', '17:00']
    })

    const getCorrectTimeData = (time:string)=>{
        return getValidText(time.split(':').slice(0,2).join(':'))
    }

    const fetchSettingsData = async () => {
        try {
            setLoading(true)
            const settingsDataResponse = await userService.fetchUserSettingsData()
            setSettingsData(settingsDataResponse)
            setCurrentDate({
                ...currentDate,
                day: settingsDataResponse.notifiable_days_of_week,
                interval: [getCorrectTimeData(settingsDataResponse.notify_from_time), getCorrectTimeData(settingsDataResponse.notify_to_time)]
            })
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const tableTopList = [
        {
            label: t('notification-type').toString()
        },
        {
            label: (
                <div className='row-flex-8'>
                    <span>{t('email').toString()}</span>
                    <Switch checked={allEmailToggleStatus} onChange={(e) => toggleAllRowsStatus(e, SettingTypes.email_checked)} />
                </div>
            )
        },
        {
            label: (
                <div className='row-flex-8'>
                    <span>inLog</span>
                    <Switch checked={allInlogToggleStatus} onChange={(e) => toggleAllRowsStatus(e, SettingTypes.inLog_checked)} />
                </div>
            )
        },
    ]

    const changeFieldStatus = async (field: string, type: SettingTypes, status: boolean) => {
        let userForRequest = { ...settingsData, sound_notification: field === 'sound_notification' ? status : settingsData.sound_notification }
        let disabled_email_notifications = userForRequest?.disabled_email_notifications ? userForRequest?.disabled_email_notifications : []
        let disabled_inlog_notifications = userForRequest?.disabled_email_notifications ? userForRequest?.disabled_inlog_notifications : []
        if (status) {
            if (type === SettingTypes.email_checked) {
                if (disabled_email_notifications?.includes(field)) {
                    disabled_email_notifications = disabled_email_notifications.filter(item => item !== field)
                }
            }
            if (type === SettingTypes.inLog_checked) {
                if (disabled_inlog_notifications?.includes(field)) {
                    disabled_inlog_notifications = disabled_inlog_notifications.filter(item => item !== field)
                }
            }
        } else {
            if (type === SettingTypes.email_checked) {
                if (!(disabled_email_notifications?.includes(field))) {
                    disabled_email_notifications = [...disabled_email_notifications, field]
                }
            }
            if (type === SettingTypes.inLog_checked) {
                if (!(disabled_inlog_notifications?.includes(field))) {
                    disabled_inlog_notifications = field !== 'sound_notification' ? [...disabled_inlog_notifications, field] : disabled_inlog_notifications
                }
            }
        }
        try {
            const sound_notification = field === 'sound_notification' ? status : settingsData.sound_notification
            await userService.updateUserSettingsData({
                disabled_email_notifications,
                disabled_inlog_notifications,
                sound_notification
            })
            setSettingsData({ ...settingsData, disabled_email_notifications, disabled_inlog_notifications, sound_notification })
            showNotice('changes-added-successfully', t)
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const toggleSwitch = async (status: boolean, field: string, type: SettingTypes) => {
        const changedData = tableData?.map(item => item?.value === field ? { ...item, [type]: status } : item)
        try {
            setTableData(changedData)
            await changeFieldStatus(field, type, status)
        } catch (e) {
            errorsHandler(e, t)
        }

    }

    const toggleAllRowsStatus = async (status: boolean, type: SettingTypes) => {
        let userForRequest = { ...settingsData }
        let disabled_email_notifications = userForRequest?.disabled_email_notifications ? userForRequest?.disabled_email_notifications : []
        let disabled_inlog_notifications = userForRequest?.disabled_email_notifications ? userForRequest?.disabled_inlog_notifications : []
        if (status) {
            if (type === SettingTypes.email_checked) {
                disabled_email_notifications = []
            }
            if (type === SettingTypes.inLog_checked) {
                disabled_inlog_notifications = []
            }
        } else {
            if (type === SettingTypes.email_checked) {
                disabled_email_notifications = getDefaultRowsList(t)?.filter(el => el?.email_checked !== undefined).map(item => item?.value)
            }
            if (type === SettingTypes.inLog_checked) {
                disabled_inlog_notifications = getDefaultRowsList(t)?.filter(el => el?.inLog_checked !== undefined && el.value !== 'sound_notification').map(item => item?.value)
            }
        }
        userForRequest.sound_notification = status
        try {
            type === SettingTypes.email_checked ? setAllEmailToggleStatus(status) : setAllInlogToggleStatus(status)
            // dispatch(setUser({user: {...userForRequest, disabled_email_notifications, disabled_inlog_notifications}}))
            setSettingsData({ ...settingsData, disabled_email_notifications, disabled_inlog_notifications })
            await userService.updateUserSettingsData({
                disabled_email_notifications,
                disabled_inlog_notifications,
                sound_notification: status
            })
            showNotice('changes-added-successfully', t)
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    useEffect(() => {
        setTableData(getDefaultRowsList(t))
    }, [i18n.language])

    useEffect(() => {
        if (settingsData?.disabled_email_notifications?.length === 20) {
            setAllEmailToggleStatus(false)
        } else if (settingsData?.disabled_email_notifications?.length === 0) {
            setAllEmailToggleStatus(true)
        }
        if (settingsData?.disabled_inlog_notifications?.length === 20) {
            setAllInlogToggleStatus(false)
        } else if (settingsData?.disabled_inlog_notifications?.length === 0) {
            setAllInlogToggleStatus(true)
        }
    }, [settingsData])

    //актуализация полей по обьекту user, который приходит в случае успешного запроса на получение данных пользователя
    useEffect(() => {
        console.log(settingsData,'--settingsData')
        let disabled_email_notifications = settingsData?.disabled_email_notifications ? settingsData?.disabled_email_notifications : []
        let disabled_inlog_notifications = settingsData?.disabled_email_notifications ? settingsData?.disabled_inlog_notifications : []
        //функция которая проверяет есть такое поле в массиве обьектов и оно не равно undefined
        const showValidField = (item: any, targetField: SettingTypes) => {
            let rule = targetField === SettingTypes.email_checked ? !(disabled_email_notifications?.includes(item?.value)) : !(disabled_inlog_notifications?.includes(item?.value))
            if (item[targetField] !== undefined) {
                return {
                    [targetField]: rule
                }
            } else {
                return {}
            }
        }
        const changedData = getDefaultRowsList(t)?.map(item => ({
            ...item,
            ...showValidField(item, SettingTypes.email_checked),
            ...showValidField(item, SettingTypes.inLog_checked)
        }))
        setTableData(changedData)
    }, [settingsData])

    useEffect(() => {
        fetchSettingsData()
    }, [])

    const showRowsList = () => {
        return tableData.map(item => {
            return <li className={'notifications-all-block-row-item'} key={item.id}>
                <div className={'font-14-normal'}>{item.title}</div>
                <div>
                    {
                        item?.email_checked !== undefined &&
                        <Switch checked={item.email_checked}
                            onChange={(e) => toggleSwitch(e, item.value, SettingTypes.email_checked)} />
                    }
                </div>
                <div>
                    {
                        item?.inLog_checked !== undefined &&
                        <Switch checked={item.value === 'sound_notification' ? !!(settingsData?.sound_notification) : item.inLog_checked}
                            onChange={(e) => toggleSwitch(e, item.value, SettingTypes.inLog_checked)} />
                    }
                </div>
            </li>
        })
    }

    const toggleActiveWeekDay = async (dayId: number) => {
        try {
            setDateTimeLoading(true)
            let chandedDaysList = []
            if (currentDate.day.includes(dayId)) {
                chandedDaysList = currentDate.day.filter(el => el !== dayId)
                setCurrentDate({ ...currentDate, day: chandedDaysList })
            } else {
                chandedDaysList = [...currentDate.day, dayId]
                setCurrentDate({ ...currentDate, day: chandedDaysList })

            }
            await userService.updateUserSettingsData({
                notifiable_days_of_week: chandedDaysList,
            })
            setDateTimeLoading(false)
        } catch (e) {
            setDateTimeLoading(false)
            errorsHandler(e, t)
        }

    }

    const showWeekDays = () => {
        return weekDays.map((el, i) => (
            <div
                className={`notifications-all-block-week-day ${currentDate.day.includes(el.id) ? 'active-week-day' : ''}`}
                onClick={() => toggleActiveWeekDay(el.id)}
            ><span>{el?.name}</span></div>
        ))
    }

    const onChangeSelectHandler = async (value: string, type: 'from' | 'to') => {
        try {
            setDateTimeLoading(true)
            const changedInterval = currentDate.interval.map((interval, index) => {
                if (type === 'from') {
                    return index === 0 ? value : interval
                } else {
                    return index === 1 ? value : interval
                }
            })
            await userService.updateUserSettingsData({
                notify_from_time: changedInterval[0],
                notify_to_time: changedInterval[1],
            })
            setCurrentDate({ ...currentDate, interval: changedInterval })
            setDateTimeLoading(false)
        } catch (e) {
            setDateTimeLoading(false)
            errorsHandler(e, t)
        }

    }

    useEffect(() => {
        if (i18n.language === 'ru') {
            setWeekDays(weekDaysMoc['ru'])
        } else {
            setWeekDays(weekDaysMoc['en'])
        }

    }, [i18n.language])

    return (
        <Skeleton loading={loading} className={'mt-20'}>
            <div className={'notifications-all-block mt-20'}>
                {
                    dateTimeloading
                        ? <div className='notifications-all-block-top-skeleton'>
                            <Skeleton.Input />
                        </div>
                        : (
                            <div className='notifications-all-block-time-container'>
                                <div className='notifications-all-block-times-interval'>
                                    <div className='notifications-all-block-time-select-block'>
                                        <SelectInLog value={currentDate.interval[0]} suffixIcon={null} options={mocTimesData.map(el => ({ label: el, value: el }))} onChange={(e) => onChangeSelectHandler(e, 'from')} />
                                        <SelectInLog value={currentDate.interval[1]} suffixIcon={null} options={mocTimesData.map(el => ({ label: el, value: el }))} onChange={(e) => onChangeSelectHandler(e, 'to')} />
                                    </div>
                                </div>
                                <div className='notifications-all-block-weeks'>
                                    {showWeekDays()}
                                </div>
                            </div>
                        )
                }
                <TableInLog topDataList={tableTopList} rowFileList={[showRowsList()]} />
            </div>
        </Skeleton>
    );
};

export default AllSettings;
