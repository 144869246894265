import React, {FC, useEffect, useState} from 'react';
import {getValidText} from "../../../../utils/helpers/getValidText";
import SelectInputBlock from "../../../../shared/selectInputBlock/SelectInputBlock";
import {SelectInputTypes} from "../../../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/types";
import {LanguageTypes} from "../../../../app/types/enums";
import {ICustomGroupProps, IValueRangeItem} from "./types";
import {useTranslation} from "react-i18next";
import ValueRangeBlock from "./valueRangeBlock/ValueRangeBlock";
import {ICustomGroupParameter} from "../../types";
import {defaultNameData} from "../../data";

const NumericValueBlock: FC<ICustomGroupProps> = (props) => {
    const {className, setIsError, activeParameter, addParameter} = props
    const [parameter,setParameter] = useState<ICustomGroupParameter>({} as ICustomGroupParameter)
    const [isErrorBlockTitle] = useState(false)
    const [isErrorUnitTitle, setIsErrorUnitTitle] = useState(false)
    const [isErrorIntervalFields, setIsErrorIntervalFields] = useState(false)
    const {t,i18n} = useTranslation()

    const changeUnitTitleBlock = (e) => {
        const preparedItem = {
            ...parameter,
            data: {...parameter.data, element_name: e}
        }
        setParameter(preparedItem)
        addParameter(preparedItem)
    }

    const changeRangeBlock = (e: IValueRangeItem) => {
        console.log(e)
        const preparedItem = {
            ...parameter,
            data: {...parameter.data, range: e}
        }
        setParameter(preparedItem)
        addParameter(preparedItem)
    }

    useEffect(() => {
        if (setIsError) {
            if (isErrorBlockTitle || isErrorUnitTitle || isErrorIntervalFields) {
                setIsError(true)
            } else {
                setIsError(false)
            }
        }
    }, [isErrorBlockTitle, isErrorUnitTitle, isErrorIntervalFields, activeParameter])

    useEffect(() => {
        const element_name = activeParameter.data.hasOwnProperty('element_name') ? activeParameter.data?.element_name : defaultNameData
        const range = activeParameter.data.hasOwnProperty('range') ? activeParameter.data?.range : {
            type: 'any',
            value: 'any'
        }
        setParameter({
            ...activeParameter,
            data: {
                element_name,
                range
            }
        })
    }, [activeParameter])

    return (
        <div className={`add-parameter-zone-space ${getValidText((className))}`}>
            <div className={'numeric-value-block cursor-pointer'}>
                <div className={'numeric-value-block-top'}>
                    <span
                        className={'font-10-normal'}>{t('enter-unit-title').toString()}</span>
                    <SelectInputBlock className={'custom-group-select-input--small'}
                                      onChange={changeUnitTitleBlock}
                                      setIsError={setIsErrorUnitTitle}
                                      type={SelectInputTypes.input}
                                      activeLang={i18n.language as LanguageTypes}
                                      data={parameter?.data?.element_name}
                    />
                </div>
                <div className={'numeric-value-block-value-range'}>
                    <span
                        className={'font-10-normal'}>{t('value-range').toString()}</span>
                    <ValueRangeBlock defaultData={parameter?.data?.range}
                                     onChange={changeRangeBlock}
                                     setIsErrorIntervalFields={setIsErrorIntervalFields}
                    />
                </div>
            </div>
        </div>
    );
};

export default NumericValueBlock;