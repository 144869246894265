import React, {
    FC,
    KeyboardEvent,
    useCallback,
    useEffect,
    useState,
} from 'react'
import InputInLog from '../input/Input'
import './exelViewer.css'
import { IExelViewerProps } from './types'
import { t } from 'i18next'
import { Button } from 'antd'

const ExelViewer: FC<IExelViewerProps> = (props) => {
    const {
        tableRows,
        isOpenModal,
        isOpenQuestionModal,
        isChangedData,
        updateTrigger,
        onSave,
        setIsChangedData,
        closeModal,
    } = props
    const [exelData, setExcelData] = useState(null)

    const handleChangeTableRowValues = useCallback(
        (data: {
            value: string
            entry: any
            entryIndex: number
            rowIndex: number
        }) => {
            const { value, entry, rowIndex, entryIndex } = data
            if (value !== undefined) {
                entry[entryIndex][1] = value
                const copyData = [...exelData]
                const newEntry = Object.fromEntries(entry)
                const oldValue = Object.entries(tableRows?.data[rowIndex])[entryIndex][1]
                if (value!=oldValue) {
                    copyData[rowIndex] = newEntry
                    setExcelData(copyData)
                    setIsChangedData(true)
                }else{
                    setIsChangedData(false)
                }
            }
        },
        [exelData, setExcelData],
    )

    const saveData = () => {
        onSave({
            ...tableRows,
            data: exelData,
        })
        setIsChangedData(false)
        if (isOpenQuestionModal) {
            closeModal()
        }
    }

    const cancelChanges = () => {
        if (tableRows && tableRows?.data) {
            setExcelData(tableRows?.data)
            setIsChangedData(false)
        }
    }

    const onKeyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.currentTarget.blur()
        }
    }

    const getCorrectValue = (val: string | number) => {
        return val !== undefined ? val?.toString() : ''
    }

    useEffect(() => {
        isOpenModal && tableRows?.data && setExcelData(tableRows?.data)
    }, [isOpenModal, tableRows?.data, updateTrigger])

    const renderTable = useCallback(() => {
        const res = exelData ? (
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            {Object.entries(exelData[0]).map((entry, i) => {
                                return (
                                    <th key={i}>
                                        <span className="cursor-not-allowed">
                                            {getCorrectValue(entry[0])}
                                        </span>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>

                    <tbody>
                        {exelData.map((individualExcelData, index) => {
                            return (
                                <tr key={index}>
                                    {renderEditableRows({
                                        individualExcelData,
                                        index,
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        ) : (
            <div>{t('mesages.logging.noFileUploaded')}</div>
        )
        return res
    }, [exelData])

    const renderEditableRows = useCallback(
        (data: { individualExcelData: any; index: number }) => {
            const { index, individualExcelData } = data
            return Object.entries(individualExcelData).map(
                (entry: [string, string], i, arr) => {
                    return (
                        <td key={i}>
                            {index === 0 ? (
                                <span className="cursor-not-allowed">
                                    {getCorrectValue(entry[1])}
                                </span>
                            ) : (
                                <InputInLog
                                    className={'exel-viewer-input'}
                                    defaultValue={getCorrectValue(entry[1])}
                                    debouncedMode
                                    onChange={(value) => {
                                        if (index !== 0 && value!==undefined) {
                                            handleChangeTableRowValues({
                                                value,
                                                entry: arr,
                                                entryIndex: i,
                                                rowIndex: index,
                                            })
                                        }
                                    }}
                                    onKeyDownhandler={onKeyDownHandler}
                                />
                            )}
                        </td>
                    )
                },
            )
        },
        [exelData],
    )

    if (isOpenQuestionModal) {
        return (
            <div className="exel-viewer-wrapper">
                <div className="exel-viewer-question-block">
                    <div>{t('you-want-to-save-changes')}</div>
                    <div className="exel-viewer-question-buttons">
                        <Button type="primary" onClick={saveData}>
                            {t('save')}
                        </Button>
                        <Button type="primary" onClick={closeModal}>
                            {t('not-save')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    } else
        return (
            <div className="exel-viewer-wrapper">
                <div className="exel-viewer-wrapper-top">
                    <Button
                        type="primary"
                        onClick={saveData}
                        disabled={!isChangedData}
                    >
                        {t('save')}
                    </Button>
                    <Button
                        type="primary"
                        onClick={cancelChanges}
                        disabled={!isChangedData}
                    >
                        {t('cancel-changes')}
                    </Button>
                </div>

                <div className="exel-viewer">{renderTable()}</div>
            </div>
        )
}

export default ExelViewer
