import React, { SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PriorityTypes } from '../../../../app/types/enums'
import { ITask } from '../../../../app/types/models/tasks'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { PlusIcon } from '../../../../image_files/icons/PlusIcon'
import { UpIcon } from '../../../../image_files/icons/UpIcon'
import { tasksService } from '../../../../services/tasks-services'
import { CreatePanel } from '../../../../shared/CreatePanel/CreatePanel'
import { selectCurrentProject } from '../../../../store/currentProject/selectors'
import { addTask } from '../../../../store/tasks/actions'
import { getCorrectDateFormat } from '../../../../utils/getCorrectDateFormat'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { showNotify } from '../../../../utils/helpers/showNotice'
import './RoadMapCalendarTop.css'

type SortTaskTypes = 'due_date_end' | 'slug' | 'name' | 'status'

const RoadMapCalendarTop = ({setAddTaskModalData}:{
    setAddTaskModalData:(data:SetStateAction<{isOpen?:boolean,task?:ITask}>)=>void
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const currentProject = useSelector(selectCurrentProject)
    const [sortParams, setSortParams] = useState<SortTaskTypes[]>([])

    const sortByType = (type: SortTaskTypes) => {
        if (sortParams.includes(type)) {
            setSortParams((prev) => prev.filter((el) => el !== type))
        } else {
            setSortParams([...sortParams, type])
        }
    }

    const getValidIcon = (type: SortTaskTypes) => {
        let className = ''
        if (sortParams.includes(type))
            className = 'scheduler__tasks-sort-age--down'

        return <UpIcon className={className} />
    }

    const createTask = async (name: string, priority: PriorityTypes) => {
        try {
            const dateNow = new Date()

            const res = await tasksService.createTask(
                {
                    name: name.trim(),
                    priority,
                    due_date_start: getCorrectDateFormat({ date: new Date() })
                        .split('-')
                        .reverse()
                        .join('-'),
                    due_date_end: getCorrectDateFormat({
                        date: new Date(dateNow.setDate(dateNow.getDate() + 7)),
                    })
                        .split('-')
                        .reverse()
                        .join('-'),
                },
                currentProject?.id,
            )

            const task = {
                ...res,
            }
            dispatch(
                addTask({
                    task,
                }),
            )
            showNotify(t('task-added-successfully'))
            setAddTaskModalData((prev) => ({ ...prev, isOpen: true, task }))
        } catch (error) {
            errorsHandler(error, t)
        }
    }

    return (
        <div className="roadMap-calendar-top">
            <div className="roadMap-items-top">
                <div className="roadMap-items-top-columns">
                    <CreatePanel
                        placeholder={t('input-task-name')}
                        prioritySelect={true}
                        panelIcon={(
                            <div className='roadMap-calendar-icon-wrap'>
                                <PlusIcon circle={false}/>
                            </div>
                        )}
                        addTask={createTask}
                    />
                    <div
                        className="cursor-pointer roadMap-sort-item"
                        onClick={() => sortByType('due_date_end')}
                    >
                        <span>{t('last-deadline')}</span>
                        {getValidIcon('due_date_end')}
                    </div>
                    <div
                        className="cursor-pointer roadMap-sort-item"
                        onClick={() => sortByType('slug')}
                    >
                        <span>{t('task-code')}</span>
                        {getValidIcon('slug')}
                    </div>
                    <div
                        className="cursor-pointer roadMap-sort-item"
                        onClick={() => sortByType('name')}
                    >
                        <span>{t('task-name')}</span>
                        {getValidIcon('name')}
                    </div>
                    <div>
                        <span>{`${t('supervisor')}/ ${t('assignees')}`}</span>
                    </div>
                    <div
                        className="cursor-pointer roadMap-sort-item"
                        onClick={() => sortByType('status')}
                    >
                        <span>{t('status')}</span>
                        {getValidIcon('status')}
                    </div>
                    <div>
                        <span>{t('additional-info')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoadMapCalendarTop
