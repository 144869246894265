import {ISelectFieldData} from "../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/types";

export  interface ICustomGroupProps{
    coreId:number
    openModal:boolean
    refreshTrigger:number
    closeModal:()=>void
    refechGroups:()=>void
}

export enum CustomGroupParameters{
    CUSTOM_DROPDOWN='DROPDOWN',
    NUMERIC_VALUE='VALUE',
    SET_OF_PAIRS='SET_OF_PAIRS'
}

export interface ICustomGroupParameter {
    type:CustomGroupParameters
    id:string
    data:any
    isValidField:boolean
    order?:number
}

export interface ICustomParameterGroup {
    id:number
    name:ISelectFieldData
    parameters:ICustomGroupParameter[]
    isValidField:boolean
}