// Use throughout your app instead of plain `useDispatch` and `useSelector`
import { AppDispatch, RootState } from '../store/store'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { ActionCreatorsMapObject, bindActionCreators } from 'redux'
import { useMemo } from 'react'

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function useActions<T extends ActionCreatorsMapObject<any>>(actions: T) {
    const dispatch = useAppDispatch()

    const boundActions = useMemo(() => {
        return bindActionCreators(actions, dispatch)
    }, [])

    return boundActions
}
