import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {INotification} from "../../../../app/types/models/i-notification";
import Notification from "../../../notification/Notification";
import './latestUpdates.scss';
import {ILatestUpdatesProps} from "./types";
import {RequestAction} from "../../../../app/types/enums";
import {notificationsService} from "../../../../services/notifications-service";
import {errorsHandler} from "../../../../utils/helpers/errors/errors-hendler";
import {showNotice} from "../../../../utils/helpers/showNotice";
import {userService} from "../../../../services/user-service";

const LatestUpdates:FC<ILatestUpdatesProps> = ({notifications,setNotifications,closeModal}) => {
    const {t} = useTranslation()

    const deleteNoticeById = async (id:number)=>{
        try {
            await notificationsService.deleteNotification(id)
            const filteredNotices = notifications?.filter(item=>item?.id!==id)
            setNotifications(filteredNotices)
            if(filteredNotices.length===0){
                closeModal()
            }
            showNotice('notification-deleted-successfully',t)
        }catch (e) {
            console.log(e)
            errorsHandler(e,t)
        }
    }

    const sendNoticeResponse = async (item:INotification,action:RequestAction)=>{
        try {
            const getPreparedData = (field:string) =>(
                {
                    [field]:item?.data[field]?.id,
                    action:action
                }
            ) as any
            if(item?.type ==='role_request'){
                await userService.changeRoleAdminResponse(item?.data?.project?.id, getPreparedData(item?.type))
            }
            if(item?.type ==='project_user_invitation'){
                await notificationsService.invitationResponse(item?.data?.project?.id,getPreparedData(item?.type))
            }
            const filteredNotices = notifications?.filter(el=>el?.id!==item?.id)
            setNotifications(filteredNotices)
            if(filteredNotices.length===0){
                closeModal()
            }
        }catch (e) {
            console.log(e)
            errorsHandler(e,t)
        }
    }

    return (
        <div className='latest-updates'>
            {notifications?.filter(el=>!(el?.is_deleted) && !(el?.deleted)).map((item: INotification, index: number) => (
                <Notification key={index} item={item} isModalItem={true} deleteBlock={deleteNoticeById} sendNoticeResponse={sendNoticeResponse}/>
            ))}
        </div>
    );
};

export default LatestUpdates;