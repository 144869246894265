export enum CalendarGroupEntityTypes {
    MONTH = 'month',
    QUARTER = 'quarter',
    YEAR = 'year',
}
export enum CalendarItemEntityTypes {
    WEEK = 'week',
    MONTH = 'month',
    QUARTER = 'quarter',
}

export interface ICalendarGroup {
    value?: string
    label: string
    year?: number
    index?: number
    type: CalendarGroupEntityTypes
    list: ICalendarItem[]
}

export interface ICalendarItem {
    value?: {
        interval?: [number, number]
        month?: { label?: string; index?: number }
        priorityColor?: string
        days?: number[]
        orderIndex?: number
    }
    label: string
    index?: number
    days?: number[]
    type: CalendarItemEntityTypes
}

export enum CalendarPriorityColors {
    LOW = 'var(--low-priority)',
    MEDIUM = 'var(--medium-priority)',
    IMPORTANT = 'var(--important-priority)',
    CRITICAL = 'var(--critical-priority)',
}
