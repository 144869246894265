import React, {FC, useEffect, useState} from 'react';
import {ICustomGroupProps, PairsNumberFieldsTypes} from "./types";
import {useTranslation} from "react-i18next";
import {getValidText} from "../../../../utils/helpers/getValidText";
import SelectInputBlock from "../../../../shared/selectInputBlock/SelectInputBlock";
import {SelectInputTypes} from "../../../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/types";
import {LanguageTypes} from "../../../../app/types/enums";
import ValueRangeBlock from "./valueRangeBlock/ValueRangeBlock";
import InputInLog from "../../../../shared/input/Input";
import {ICustomGroupParameter} from "../../types";


const PairsOfPointBlock: FC<ICustomGroupProps> = (props) => {
    const {className, setIsError, activeParameter, addParameter} = props
    const {t,i18n} = useTranslation()
    const [parameter, setParameter] = useState<ICustomGroupParameter>({} as ICustomGroupParameter)
    const [isErrorXTitle, setIsErrorXTitle] = useState(false)
    const [isErrorYTitle, setIsErrorYTitle] = useState(false)
    const [isErrorXRange, setIsErrorXRange] = useState(false)
    const [isErrorYRange, setIsErrorYRange] = useState(false)
    const [isErrorPointsNumberValue, setIsErrorPointsNumberValue] = useState(false)

    const changeFieldByType = (e: any, type: PairsNumberFieldsTypes) => {
        let preparedItem;
        if(type===PairsNumberFieldsTypes.POINTS_NUMBER){
            //only integers
            if((/^[0-9]*[.,]?[0-9]+$/g).test(e) || e===''){
                preparedItem = {
                    ...parameter,
                    data: {...parameter.data, [type]: e}
                }
            }else {
                return
            }
        }else{
            preparedItem = {
                ...parameter,
                data: {...parameter.data, [type]: e}
            }
        }
        setParameter(preparedItem)
        addParameter(preparedItem)
    }

    useEffect(() => {
        if (setIsError) {
            if (
                isErrorXTitle
                || isErrorYTitle
                || isErrorXRange
                || isErrorYRange
                || isErrorPointsNumberValue
            ) {
                setIsError(true)
            } else {
                setIsError(false)
            }
        }
    }, [
        isErrorXTitle,
        isErrorYTitle,
        isErrorXRange,
        isErrorYRange,
        isErrorPointsNumberValue,
        activeParameter
    ])

    useEffect(() => {
        const x_title = activeParameter.data.hasOwnProperty(PairsNumberFieldsTypes.X_TITLE)
            ? activeParameter.data?.x_title : {en: '', ru: ''}
        const y_title = activeParameter.data.hasOwnProperty(PairsNumberFieldsTypes.Y_TITLE)
            ? activeParameter.data?.y_title : {en: '', ru: ''}
        const x_range = activeParameter.data.hasOwnProperty(PairsNumberFieldsTypes.X_RANGE)
            ? activeParameter.data?.x_range : {type: 'any', value: 'any'}
        const y_range = activeParameter.data.hasOwnProperty(PairsNumberFieldsTypes.Y_RANGE)
            ? activeParameter.data?.y_range : {type: 'any', value: 'any'}
        const points_number = activeParameter.data.hasOwnProperty(PairsNumberFieldsTypes.POINTS_NUMBER)
            ? activeParameter.data?.points_number : ''
        setParameter({
            ...activeParameter,
            data: {
                x_title,
                y_title,
                x_range,
                y_range,
                points_number
            }
        })
    }, [activeParameter])

    console.log(activeParameter,'set of pairs, active parameter')
    console.log(parameter,'set of pairs, parameter')

    return (
        <div className={`add-parameter-zone-space ${getValidText((className))}`}>
            <div className={'set-pairs-block cursor-pointer'}>
                <div className="set-pairs-block-top">
                <span className={'font-10-normal'}>
                    {t('enter-points-title').toString()}
                </span>
                    <div className={'row-flex-5'}>
                        <SelectInputBlock className={'custom-group-select-input--small'}
                                          onChange={(e) => changeFieldByType(e,
                                              PairsNumberFieldsTypes.X_TITLE)}
                                          type={SelectInputTypes.input}
                                          activeLang={i18n.language as LanguageTypes}
                                          data={parameter?.data?.x_title}
                                          setIsError={(e)=>{
                                              console.log(e)
                                              setIsErrorXTitle(e)
                                          }}
                        />
                        <SelectInputBlock className={'custom-group-select-input--small'}
                                          onChange={(e) => changeFieldByType(e,
                                              PairsNumberFieldsTypes.Y_TITLE)}
                                          type={SelectInputTypes.input}
                                          activeLang={i18n.language as LanguageTypes}
                                          data={parameter?.data?.y_title}
                                          setIsError={setIsErrorYTitle}
                        />
                    </div>
                    <div className={'set-pairs-value-range-block'}>
                        <span className={'font-10-normal'}>
                           {t('value-range').toString()}
                        </span>
                        <div className={'set-pairs-value-range-columns mt-10'}>
                            <ValueRangeBlock defaultData={parameter?.data?.x_range}
                                             onChange={(e) => changeFieldByType(e,
                                                 PairsNumberFieldsTypes.X_RANGE)}
                                             setIsErrorIntervalFields={setIsErrorXRange}

                            />
                            <ValueRangeBlock defaultData={parameter?.data?.y_range}
                                             onChange={(e) => changeFieldByType(e,
                                                 PairsNumberFieldsTypes.Y_RANGE)}
                                             setIsErrorIntervalFields={setIsErrorYRange}
                            />
                        </div>
                    </div>
                    <div className='set-pairs-value-number-block'>
                        <span className={'font-10-normal '}>
                           {t('max-number-pairs-of-points').toString()}
                        </span>
                        <InputInLog rules={[{isRequired: true}]}
                                    className={`set-pairs-value-max-number-field ${!isErrorPointsNumberValue ? 'set-pairs-value-max-number-field--filled' : ''}`}
                                    value={parameter?.data?.points_number}
                                    onChange={(e) => changeFieldByType(e,
                                        PairsNumberFieldsTypes.POINTS_NUMBER)}
                                    // setIsError={(e)=>{
                                    //     console.log(e)
                                    //     setIsErrorPointsNumberValue(e)
                                    // }}
                        />
                    </div>
                </div>
                <div>
                </div>
            </div>
        </div>
    );
};

export default PairsOfPointBlock;