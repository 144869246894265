import React, { FC, useEffect, useMemo, useState } from 'react'
import * as XLSX from 'xlsx'
import { Button, Upload, UploadFile } from 'antd'
import './fileAttach.css'
import { IFileAttachProps } from './types'
import { uploadProps } from './data'
import { UploadItem } from './UploadItem'
import { getValidText } from '../../utils/helpers/getValidText'
import { convertBase64 } from '../../utils/helpers/convertBase64'

const FileAttach: FC<IFileAttachProps> = (props) => {
    const {
        className,
        title,
        buttonTitle,
        files,
        action,
        accept,
        listType,
        multiple,
        name,
        resetFileTrigger,
        onSelect,
        customRequest,
        removeFile,
        onChange
    } = props
    const [fileList, setFileList] = useState(files ? files : [])

    const onChangeDownloadFileHandler = (file: any) => {
        const changeFileFormat = async (file: any) => {
            let convertedData = await convertBase64(file.originFileObj)
            return {
                ...file,
                uuid: file?.id,
                name:
                    file?.filename && file?.filename?.split('/').at(-1)
                        ? file?.filename?.split('/').at(-1)
                        : file?.name,
                url: convertedData.view,
            }
        }
        const changedFileList = file.fileList.map((m) => {
            return changeFileFormat(m)
        })
        if (multiple) {
            setFileList(changedFileList)
            onSelect && onSelect(fileList)
        } else {
            changeFileFormat(file.file).then((res) => {
                setFileList([res])
                onSelect && onSelect([res])
            })
        }
    }

    const removeFileHandler = (file) => {
        removeFile(file.id)
        setFileList(fileList.filter((item) => item.id !== file.id))
    }

    const preparedFileList = useMemo(() => {
        return fileList
            ? fileList.map((m) => {
                  return {
                      ...m,
                      uuid: m?.id,
                      name: m?.filename?.split('/').at(-1)
                          ? m?.filename?.split('/').at(-1)
                          : m?.name,
                      url: m?.file,
                  }
              })
            : null
    }, [fileList])

    useEffect(()=>{
        if(resetFileTrigger!==undefined && resetFileTrigger>0){
            setFileList([])
        }
    },[resetFileTrigger])

    return (
        <div
            className={`file-attach ${getValidText(className)}`}
        >
            <Upload.Dragger
                name={name ? name : 'file'}
                accept={accept}
                action={action ? action : undefined}
                headers={{
                    authorization: `Bearer ${JSON.parse(
                        localStorage.getItem('token'),
                    )}`,
                }}
                customRequest={customRequest}
                // multiple
                listType={listType ? listType : 'picture'}
                multiple={multiple}
                fileList={preparedFileList as any}
                onChange={onChange ? onChange : onChangeDownloadFileHandler}
                // customRequest={customRequestHandler}
                onRemove={removeFileHandler}
                {...uploadProps}
                itemRender={(_data, f: UploadFile & { file: string }) => {
                    if (listType === 'text') {
                        return ''
                    } else {
                        const targetFile = fileList.find(
                            (el: any) => el.uid === f.uid,
                        ) as any
                        if (targetFile) {
                            return (
                                <UploadItem
                                    image={targetFile.url}
                                    name={targetFile.name}
                                    id={targetFile.uid}
                                    file={targetFile}
                                    onRemove={() => removeFileHandler(f)}
                                />
                            )
                        } else {
                            return ''
                        }
                    }
                }}
            >
                <div>{title ? title : ''}</div>
                {buttonTitle && <Button>{buttonTitle}</Button>}
            </Upload.Dragger>
        </div>
    )
}

export { FileAttach }
