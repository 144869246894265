import React, { FC } from "react"
import { Trans } from "react-i18next"
import { getValidText } from "../../utils/helpers/getValidText"

interface IProps {
    value?:string
}

const SelectFileText:FC<IProps> = ({value}) =>{
    return (
        <Trans
                i18nKey="select-file-or-drag-here" // optional -> fallbacks to defaults if not provided
                defaults="<span>Выберите файл {{value}}</span> или перетащите сюда" // optional defaultValue
                values={{ value: getValidText(value) }}
                components={{
                    span: <span className={'drag-zone-highlight-text'} />,
                }}
            />
    )
}

export default SelectFileText;