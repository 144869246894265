import { createAction } from '@reduxjs/toolkit'
import { RequestStatusType } from '../../app/types/request'

const setAppStatus = createAction<{ status: RequestStatusType }>(
    'appActions/setAppStatus',
)
const setAppError = createAction<{ error: string | null }>(
    'appActions/setAppError',
)

const setAppIsInitialized = createAction<{ isInitialized: boolean }>(
    'appActions/setAppIsInitialized',
)

export { setAppStatus, setAppError, setAppIsInitialized }
