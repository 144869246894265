import React from 'react'
interface IProps {
    title?:string
    viewBox?:string
    stroke?:string
    width?:number
    height?:number
}
export const QuestionIcon = (props:IProps) => {
    return (
        <div title={props.title} style={{cursor:'pointer',height:24}} >
            <svg width={props.width} height={props.height} viewBox={props.viewBox ? props.viewBox : "-3 0 21 13"} fill="none" stroke={props.stroke ? props.stroke : ''}
                 xmlns="http://www.w3.org/2000/svg">
                <circle cx="7" cy="7" r="7" fill="white"/>
                <circle cx="7" cy="7" r="6.5" stroke={props.stroke ? props.stroke : 'black'}
                        strokeOpacity="0.45"
                />
                <path
                    d="M4.59067 5.3361V5.3361C4.58204 4.43848 5.08199 3.61324 5.88159 3.20527L6.16679 3.05976C6.91614 2.67742 7.81843 2.75921 8.48681 3.27005L8.5256 3.2997C9.03616 3.68992 9.33828 4.29376 9.34446 4.93634L9.34587 5.08269C9.35328 5.85287 8.88789 6.54888 8.17332 6.83631V6.83631C7.45875 7.12374 6.99336 7.81975 7.00077 8.58993L7.01667 10.2429"
                    stroke={props.stroke ? props.stroke : 'black'} strokeOpacity="0.45"/>
                <path d="M7 11V12" stroke={props.stroke ? props.stroke : 'black'} strokeOpacity="0.45"/>
            </svg>
        </div>


    )
}
