interface IQueryVariable {
    value:string
    name:string
}

export const getQueryVariables = ():IQueryVariable[]=>{
    const query =window.location.search.substring(1)
    const vars = query.split('&')
    let result = []
    if(vars && vars?.length>0){
        result = vars?.map(item=>({name:item?.split('=')[0],value:item?.split('=')[1]}))
    }
    return result
}