import { Popover } from 'antd'
import React, { ReactNode, useEffect, useState } from 'react'
import { IMember } from '../../app/types/models/users'
import { SearchIcon } from '../../image_files/icons/SearchIcon'
import TagsArrowIcon from '../../image_files/icons/TagsArrowIcon'
import { MembersItem } from '../MembersItem/MembersItem'
import './membersSelect.css'
import { useTranslation } from 'react-i18next'

interface IProps {
    icon?: ReactNode
    className?: string
    members?: IMember[]
    initialMembers?: IMember[]
    addMember?: (value: IMember) => void
    deleteMember?: (id: number) => void
}

export const MembersSelect = (props) => {
    const {
        title,
        className,
        members,
        addMember,
        deleteMember,
        initialMembers: initialMember,
        membersType,
        icon,
    } = props
    const { t } = useTranslation()
    const [collapsed, setCollapsed] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [filteredMembersList, setFilteredMembersList] = useState(members)
    const [currentMember, setCurrentMember] = useState(initialMember)

    const searchInputHandler = (e) => {
        setSearchValue(e.target.value)
        const searchedMembers = members?.filter((f) => {
            if (e.target.value.length > 0) {
                return f.user['full_name']
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase().trim())
            }
        })
        setFilteredMembersList(
            e.target.value.length > 0 ? searchedMembers : members,
        )
    }

    const changeCurrentMember = (member) => {
        if (!currentMember && membersType === 'supervisor') {
            setCurrentMember(member)
            addMember(member)
        }
        if (membersType === 'assignees') {
            addMember(member)
            setCurrentMember(initialMember)
        }
    }
    const deleteHandler = (memberId) => {
        if (membersType === 'supervisor') {
            setCurrentMember(null)
            deleteMember(initialMember?.id)
        }
        if (membersType === 'assignees') {
            const filteredMembers = currentMember.filter(
                (member) => member.id !== memberId,
            )
            setCurrentMember(filteredMembers)
            deleteMember(memberId)
        }
    }
    useEffect(() => {
        setCurrentMember(initialMember)
    }, [initialMember])

    useEffect(() => {
        if (members && members?.length > 0) {
            setFilteredMembersList(
                members.filter((f) => {
                    if (!Array.isArray(currentMember)) {
                        return (
                            f.user.full_name !== currentMember?.user?.full_name
                        )
                    }
                    if (currentMember && Array.isArray(currentMember)) {
                        const namesList =
                            currentMember.length > 0
                                ? currentMember.map(
                                      (member) => member?.user?.full_name,
                                  )
                                : []
                        const targetMemberList = namesList.find(
                            (name) => name === f?.user?.full_name,
                        )
                        return !targetMemberList
                    }
                }),
            )
        }
    }, [members])

    return (
        <div
            className={
                className ? `membersSelect ${className}` : 'membersSelect'
            }
        >
            <Popover
                placement="bottomRight"
                onOpenChange={() => setCollapsed(!collapsed)}
                overlayClassName={'membersSelect-popover'}
                content={
                    <div className="membersSelect__list">
                        <div className="membersSelect__list-inner">
                            {filteredMembersList?.length > 3 && (
                                <div className="membersSelect__list-search">
                                    <SearchIcon className="membersSelect__list-searchIcon" />
                                    <input
                                        type="text"
                                        onChange={searchInputHandler}
                                        className="membersSelect__list-searchInput"
                                        value={searchValue}
                                    />
                                </div>
                            )}

                            {filteredMembersList?.length > 0 ? (
                                <div className="membersSelect__bottom-list">
                                    {filteredMembersList.map((m, i) => {
                                        return (
                                            <MembersItem
                                                key={i}
                                                member={m}
                                                containerClassName={''}
                                                textClassName={''}
                                                setMembers={() =>
                                                    changeCurrentMember(m)
                                                }
                                            />
                                        )
                                    })}
                                </div>
                            ) : (
                                <div className="font-14-normal">
                                    {t('list-is-empty')}
                                </div>
                            )}
                        </div>
                    </div>
                }
            >
                <button className="membersSelect__panel-content">
                    <div className={'membersSelect__panel-content-wrap'}>
                        {icon && icon}
                    </div>
                    <div className={'membersSelect__panel-content-wrap'}>
                        <span className={'membersSelect__top-name'}>
                            {title}
                        </span>
                    </div>
                    <TagsArrowIcon
                        className="membersSelect__top-arrow"
                        style={collapsed ? { transform: 'rotate(180deg)' } : {}}
                    />
                </button>
            </Popover>
            <div className={'membersSelect__checkedList'}>
                {!Array.isArray(currentMember) && currentMember !== null ? (
                    <MembersItem
                        member={currentMember}
                        containerClassName={'membersSelect__Container-item'}
                        className={'membersSelect__checkedList-item'}
                        removeMembers={() => deleteHandler(currentMember.id)}
                    />
                ) : Array.isArray(currentMember) &&
                  currentMember &&
                  currentMember.length > 0 ? (
                    currentMember.map((member) => {
                        return (
                            <MembersItem
                                key={member.id}
                                member={member}
                                containerClassName={
                                    'membersSelect__Container-item'
                                }
                                className={'membersSelect__checkedList-item'}
                                removeMembers={() => deleteHandler(member.id)}
                            />
                        )
                    })
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}
