import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
    ICoreBoxIntervalRequest,
    ICoreBoxPhotoResponse,
} from '../../../../app/types/dto/core-box-data'
import { coreBoxService } from '../../../../services/core-box-service'
import { currentProjectSelectors } from '../../../../store/currentProject'
import { selectCurrentProject } from '../../../../store/currentProject/selectors'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import CoreBoxesPhotos from './components/coreBoxesPhotos/CoreBoxesPhotos'
import SelectionIntervals from './components/selectionIntervals/SelectionIntervals'
import './coreBoxes.css'
import {
    convertIntervalForRequest,
    convertIntervalFromResponse,
} from './services/convert-interval-object-data'
import { ISelectInterval } from './types'
import { showNotify } from '../../../../utils/helpers/showNotice'

export const initialCorePhotoInterval: ISelectInterval = {
    id: '1',
    interval: {
        vertical: ['', ''],
        actual: ['', ''],
    },
    type: 'vertical',
    photoBoxes: [],
    selected: true,
}

const CoreBoxes = () => {
    const { t } = useTranslation()

    const [intervals, setIntervals] = useState<ISelectInterval[]>([
        initialCorePhotoInterval,
    ])
    const [currentBox, setCurrentBox] = useState<ICoreBoxPhotoResponse>(
        {} as ICoreBoxPhotoResponse,
    )
    const [loading, setLoading] = useState(false)
    const currentWell = useSelector(currentProjectSelectors.selectCurrentWell)
    const currentWellBore = useSelector(
        currentProjectSelectors.selectCurrentWellBore,
    )

    const currentProject = useSelector(selectCurrentProject)

    const fetchIntervals = async () => {
        try {
            setLoading(true)
            let params = {}
            if (currentWell) params = { well: currentWell?.id }
            if (currentWellBore) params = { wellbore: currentWellBore?.id }
            const response = await coreBoxService.getInterval(
                currentProject?.id,
                params,
            )
            if (response && response.length > 0) {
                const preparedIntervals: ISelectInterval[] = response.map(
                    (interval) => convertIntervalFromResponse(interval),
                )
                if (preparedIntervals && preparedIntervals.length > 0) {
                    setIntervals(
                        preparedIntervals.map((el, i) =>
                            i === 0 ? { ...el, selected: true } : el,
                        ),
                    )
                }
            } else setIntervals([])
            setLoading(false)
        } catch (e) {
            setLoading(false)
            errorsHandler(e, t)
        }
    }

    const selectInterval = (id: number) => {
        setIntervals(
            intervals.map((el) => {
                return {
                    ...el,
                    selected: el.id === id,
                }
            }),
        )
    }

    const changeInterval = useCallback(
        async (data: {
            intervalDataArg: ISelectInterval
            type: 'add' | 'update'
        }) => {
            const { intervalDataArg, type } = data
            try {
                const preparedData: ICoreBoxIntervalRequest =
                    convertIntervalForRequest(intervalDataArg)

                if (currentWell) {
                    preparedData.well = currentWell?.id
                }

                if (currentWellBore) {
                    preparedData.wellbore = currentWellBore?.id
                }

                let response

                if (type === 'add') {
                    response = await coreBoxService.addInterval(
                        currentProject?.id,
                        preparedData,
                    )
                } else {
                    response = await coreBoxService.updateInterval(
                        currentProject?.id,
                        Number(intervalDataArg?.id),
                        preparedData,
                    )
                }

                if (response && response?.id) {
                    const searchedIntervalForReplace = intervals.find(
                        (intervalEl) => intervalEl.id === intervalDataArg.id,
                    )
                    if (searchedIntervalForReplace) {
                        if (type === 'add') {
                            setIntervals((prev) =>
                                prev.map((intervalEl) =>
                                    intervalEl.id ===
                                    searchedIntervalForReplace.id
                                        ? {
                                              ...convertIntervalFromResponse(
                                                  response,
                                              ),
                                              type: intervalEl.type,
                                              isNew: false,
                                          }
                                        : intervalEl,
                                ),
                            )
                            showNotify(t('interval-added-successfully'))
                        } else {
                            showNotify(t('interval-updated-successfully'))
                        }
                    }
                }
            } catch (e) {
                errorsHandler(e, t)
                setIntervals(intervals)
            }
        },
        [currentWell, currentWellBore, intervals, setIntervals],
    )

    const deleteInterval = useCallback(
        async (id: number | string) => {
            try {
                if (typeof id !== 'string') {
                    await coreBoxService.deleteInterval(currentProject?.id, id)
                    showNotify(t('interval-deleted-successfully'), {})
                }
                if (intervals.length > 1) {
                    let filteredIntervals = intervals.filter(
                        (el) => el.id !== id,
                    )
                    const targetInterval = intervals.find((el) => el.id === id)
                    if (targetInterval.selected) {
                        filteredIntervals[
                            filteredIntervals.length - 1
                        ].selected = true
                    }
                    setIntervals(filteredIntervals)
                } else {
                    setIntervals([])
                }
            } catch (e) {
                errorsHandler(e, t)
            }
        },
        [intervals, setIntervals],
    )

    useEffect(() => {
        fetchIntervals()
    }, [currentWell, currentWellBore])

    useEffect(() => {
        if (
            intervals.length === 1 &&
            typeof intervals[0].id === 'number' &&
            !intervals[0]?.selected
        ) {
            setIntervals([{ ...intervals[0], selected: true }])
        }
    }, [intervals])

    return (
        <div className={'core-boxes'}>
            <SelectionIntervals
                loading={loading}
                intervals={intervals}
                setIntervals={setIntervals}
                changeInterval={changeInterval}
                deleteInterval={deleteInterval}
                selectInterval={selectInterval}
                setCurrentBox={setCurrentBox}
            />

            <CoreBoxesPhotos
                intervals={intervals}
                currentBox={currentBox}
                isLoadingIntervals={loading}
                setCurrentBox={setCurrentBox}
                setIntervals={setIntervals}
            />
        </div>
    )
}

export default CoreBoxes
