import { Modal, ModalProps } from 'antd'
import React, { FC, ReactNode } from 'react'
import { XIcon } from '../../image_files/icons/XIcon'
import './modalInlog.css';
import { ButtonItem } from '../../app/types/i-button';
import { showButtonsList } from '../../services/show-buttonst-list';
import { getValidText } from '../../utils/helpers/getValidText';

export type ModalTypeInlog = ModalProps & {
  listButton?: ButtonItem[];
  iconTitle?:ReactNode
}

const ModalInlog:FC<ModalTypeInlog> = (props) => {


  const showTitle = ()=>{
    return (
      <div className='modal-title'>
        {props?.iconTitle && props.iconTitle}
        <div className='font-14-normal modal-title-text'>{props?.title}</div>
      </div>
    )
  }


  return (
    <Modal
      {...props}
      title={showTitle()}
      footer={props?.listButton ? showButtonsList(props?.listButton, 'modal-button-list') : null}
      closeIcon={<XIcon width={13} height={13} className={'modal-top-cancel-icon'} />}
    />
  )
}

export default ModalInlog