import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Select } from 'antd'
import { SelectIcon } from '../../image_files/icons/SelectIcon'
import { UnitWindow } from './components/unitWindow/UnitWindow'
import useDebounce from '../../hooks/useDebounce'
import './selectInputBlock.scss'
import { getValidText } from '../../utils/helpers/getValidText'
import {
    ISelectFieldData,
    SelectInputTypes,
} from '../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/types'
import { DEBOUNCE_DELAY } from '../../utils/constants'
import { errorStyles, langOptions, selectDropdownStyles } from './data'
import { ISelectInputBlockProps } from './types'
import SelectInLog from '../select/Select'
import { IUnitItem } from '../../app/types/models/IMnemonic'

const SelectInputBlock: FC<ISelectInputBlockProps> = (props) => {
    const {
        id,
        type,
        data,
        disabled,
        className,
        activeLang,
        defaultData,

        onChange,
        setIsError,
    } = props
    const [currentLang, setCurrentLang] = useState<'ru' | 'en'>('ru')
    const [currentUnitSelect, setCurrentUnitSelect] = useState('')
    //временное решение. Компонент должен изнутри знать когда показывать ошибку, для этого создаю доп стейт
    const [fieldsData, setFieldsData] = useState<ISelectFieldData>(
        {} as ISelectFieldData,
    )
    const debouncedFieldData = useDebounce(fieldsData, DEBOUNCE_DELAY)
    const onChangeSetInputValue = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value
        // setDefaultInputData(value)
        setFieldsData({
            ...fieldsData,
            [currentLang]: value,
        })
    }

    const onChangeUnitField = (data: IUnitItem[]) => {
        setFieldsData({
            ...fieldsData,
            [currentLang]: data,
        })
        setCurrentUnitSelect(data[0].scale)
    }

    const changeCurrentLang = (e: 'ru' | 'en') => {
        setCurrentLang(e)
    }

    const setBlockColorByType = () => {
        let isError
        if (type === SelectInputTypes.unit) {
            if (currentLang === 'ru') {
                if (
                    fieldsData?.ru?.find(
                        (item) =>
                            item.value?.toString()?.trim() === '' ||
                            item.scale.toString().trim() === '',
                    ) ||
                    fieldsData?.en?.find(
                        (item) =>
                            item?.value?.toString().trim() === '' ||
                            item?.scale?.toString()?.trim() === '',
                    )
                ) {
                    setIsError && setIsError(true)
                    isError = true
                } else {
                    setIsError && setIsError(false)
                    isError = false
                }
            } else {
                if (
                    fieldsData?.en?.find(
                        (item) =>
                            item.value.toString().trim() === '' ||
                            item.scale.toString().trim() === '',
                    ) ||
                    fieldsData?.ru?.find(
                        (item) =>
                            item.value.toString().trim() === '' ||
                            item.scale.toString().trim() === '',
                    )
                ) {
                    setIsError && setIsError(true)
                    isError = true
                } else {
                    setIsError && setIsError(false)
                    isError = false
                }
            }
        } else if (type === SelectInputTypes.tags) {
            if (
                !fieldsData?.ru ||
                !fieldsData?.en ||
                fieldsData?.en?.length === 0 ||
                fieldsData?.ru?.length === 0
            ) {
                setIsError && setIsError(true)
                isError = true
            } else {
                setIsError && setIsError(false)
                isError = false
            }
        } else {
            if (type === SelectInputTypes.input) {
                setIsError && setIsError(false)
                isError = false
                if (
                    fieldsData &&
                    fieldsData?.hasOwnProperty('en') &&
                    fieldsData?.hasOwnProperty('ru') &&
                    typeof fieldsData?.en === 'string' &&
                    typeof fieldsData?.ru === 'string' &&
                    fieldsData?.en?.trim() !== '' &&
                    fieldsData?.ru?.trim() !== ''
                ) {
                    setIsError && setIsError(false)
                    isError = false
                } else {
                    setIsError && setIsError(true)
                    isError = true
                }
            } else {
                setIsError && setIsError(false)
                isError = false
            }
        }
        return isError ? errorStyles : {}
    }

    const changeTagsOptions = (value: string[]) => {
        const changedArr = value.map((item) => ({ label: item, value: item }))
        setFieldsData({
            ...fieldsData,
            [currentLang]: changedArr,
        })
    }

    useEffect(() => {
        if (defaultData) {
            setFieldsData(defaultData)
            if (type === SelectInputTypes.unit) {
                setCurrentUnitSelect(defaultData[currentLang][0]?.scale)
            }
        }
    }, [currentLang])

    useEffect(() => {
        if (data) {
            setFieldsData(data)
            if (type === SelectInputTypes.unit) {
                setCurrentUnitSelect(data[currentLang][0]?.scale)
            }
        }
    }, [currentLang, data])

    useEffect(() => {
        setCurrentLang(activeLang)
    }, [activeLang])

    useEffect(() => {
        onChange(debouncedFieldData, currentLang)
    }, [debouncedFieldData])

    const showBlockByType = () => {
        switch (type) {
            case SelectInputTypes.input:
                return (
                    <input
                        type="text"
                        className={'select-input-block--input'}
                        value={
                            fieldsData[currentLang]
                                ? fieldsData[currentLang]
                                : ''
                        }
                        disabled={disabled}
                        onChange={onChangeSetInputValue}
                    />
                )
            case SelectInputTypes.unit:
                return (
                    <Select
                        bordered={false}
                        disabled={disabled}
                        value={currentUnitSelect}
                        suffixIcon={<SelectIcon />}
                        dropdownStyle={selectDropdownStyles}
                        className={'select-input-block--dropdownSelect'}
                        dropdownRender={() => {
                            return (
                                <UnitWindow
                                    data={fieldsData}
                                    currentLanguage={currentLang}
                                    onChange={(data, _) =>
                                        onChangeUnitField(data)
                                    }
                                    activeLang={activeLang}
                                />
                            )
                        }}
                    />
                )
            case SelectInputTypes.tags:
                return (
                    <SelectInLog
                        disabled={disabled}
                        value={fieldsData[currentLang]}
                        options={[]}
                        mode={'tags'}
                        onChange={changeTagsOptions}
                        className={'select-input-block--select'}
                    />
                )
            default:
                return ''
        }
    }

    return (
        <div
            id={id}
            className={`select-input-block ${getValidText(className)}`}
            style={setBlockColorByType()}
        >
            {
                <Select
                    bordered={false}
                    value={currentLang}
                    disabled={disabled}
                    suffixIcon={<SelectIcon />}
                    className={'select-input-block--select'}
                    
                    onChange={changeCurrentLang}
                    dropdownStyle={selectDropdownStyles}
                >
                    {langOptions.map(
                        (item: { value: string; label: string }) => (
                            <Select.Option
                                style={{ color: 'var(--main-text-color)' }}
                                value={item.value}
                                key={item.value}
                            >
                                <div
                                    className={'select-input-block--optionText'}
                                >
                                    {item.label}
                                </div>
                            </Select.Option>
                        ),
                    )}
                </Select>
            }
            {showBlockByType()}
        </div>
    )
}

export default SelectInputBlock
