import { Button } from 'antd'
import { t } from 'i18next'
import React, { useEffect, useRef, useState } from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import './canvasComponent.css'
import CropImageModal from '../../../../../../../../shared/cropImageModal/CropImageModal'
import { EditIcon } from '../../../../../../../../image_files/icons/EditIcon'

enum LineWidthTypes {
    small = 18,
    medium = 28,
    large = 35,
}

function CoreBoxCanvas({ url, onSave }) {
    const ctxRef = useRef(null)

    const canvasRef = useRef(null)
    const [scale, setScale] = useState(1)
    const [saveUrl, setSaveUrl] = useState(null)

    const [image, setImage] = useState(null)
    const [isOpenModal, setIsOpenModal] = useState(null)

    const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 })
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
    const [canvasPosition, setCanvasPosition] = useState({ x: 0, y: 0 })
    const [isMoving, setIsMoving] = useState(false)
    const [isDrawing, setIsDrawing] = useState(false)
    const [isDrawingMode, setIsDrawingMode] = useState(false)
    const [drawings, setDrawings] = useState([])
    const [localUrl,setLocalUrl] =useState('')
    const [lineWidth, setLineWidth] = useState<LineWidthTypes>(
        LineWidthTypes.small,
    )

    useEffect(()=>{
        if(url) setLocalUrl(url)
    },[url])


    useEffect(() => {
        setDrawings([])
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        ctxRef.current = ctx
        const image = new Image()

        image.crossOrigin = 'Anonymous'

        image.onload = () => {
            setImage(image)
            ctx.clearRect(
                0,
                0,
                canvasRef?.current?.width,
                canvasRef?.current?.height,
            )
            const scaledWidth = image?.width * scale
            const scaledHeight = image?.height * scale
            ctx.drawImage(
                image,
                canvasPosition.x,
                canvasPosition.y,
                scaledWidth,
                scaledHeight,
            )
            setCanvasPosition({
                x: (canvasRef.current?.width - image?.width) / 2,
                y: (canvasRef.current?.height - image?.height) / 2,
            })
        }
        image.src = localUrl
    }, [localUrl])

    useEffect(() => {
        if (ctxRef.current && image) {
            console.log('rrendered canvas')
            const ctx = ctxRef.current
            ctx.clearRect(
                0,
                0,
                canvasRef.current.width,
                canvasRef.current.height,
            )
            const scaledWidth = image?.width * scale
            const scaledHeight = image?.height * scale

            drawings.forEach((drawing) => {
                const startX = drawing.startX * scale
                const startY = drawing.startY * scale 
                const endX = drawing.endX * scale 
                const endY = drawing.endY * scale
                ctx.beginPath()

                ctx.moveTo(startX, startY)
                ctx.lineTo(endX, endY)

                ctx.globalCompositeOperation = 'multiply'
                ctx.lineCap = 'round'
                // ctx.fillStyle = "rgba(255, 255, 0, 0.5)";
                ctx.strokeStyle = 'rgba(255, 255, 0, 0.5)' // Желтый цвет с прозрачностью 50%
                ctx.lineWidth = drawing.lineWidth * scale // Ширина линии
                ctx.stroke()
            })

            ctx.drawImage(
                image,
                canvasPosition.x * scale,
                canvasPosition.y * scale,
                scaledWidth,
                scaledHeight,
            )
        }
    }, [scale, drawings, canvasPosition])

    const handleWheel = (event) => {
        const newScale = scale + (event.deltaY > 0 ? -0.1 : 0.1)
        if (newScale >= 0.1 && newScale <= 3) {
            setScale(newScale)
        }
        event.preventDefault()
    }

    const handleMouseDown = (event) => {
        const rect = canvasRef.current.getBoundingClientRect()
        const x = (event.clientX - rect.left) / scale
        const y = (event.clientY - rect.top) / scale
        setLastPosition({ x, y })
        if (event?.button === 0 && !isDrawingMode) {
            setIsMoving(true)
            setMousePosition({ x, y })
        }
        if (event?.button === 0 && isDrawingMode) {
            setIsDrawing(true)
            setMousePosition({ x, y })
        }
    }

    const handleMouseMove = (event) => {
        const rect = canvasRef.current.getBoundingClientRect()
        const x = (event.clientX - rect.left) / scale
        const y = (event.clientY - rect.top) / scale

        if (isMoving) {
            const dx = x - mousePosition.x
            const dy = y - mousePosition.y
            setCanvasPosition({
                x: canvasPosition.x + dx,
                y: canvasPosition.y + dy,
            })
            setMousePosition({ x, y })

            setDrawings(prev=>{
                
                return prev.map(el=>{
                    return {
                        ...el,
                        startX: el.startX + dx,
                        startY: el.startY + dy,
                        endX: el.endX+dx,
                        endY: el.endY+dy,
                    }
                })
            })
        }
        if (isDrawing) {
            setDrawings([
                ...drawings,
                {
                    startX: lastPosition.x,
                    startY: lastPosition.y,
                    endX: x,
                    endY: y,
                    lineWidth,
                },
            ])
            setLastPosition({ x, y })
        }
    }

    const handleMouseUp = () => {
        setIsMoving(false)
        setIsDrawing(false)
    }

    const handleMouseOut = () => {
        setIsMoving(false)
        setIsDrawing(false)
    }

    const handleReset = () => {
        setCanvasPosition({
            x: (canvasRef.current?.width - image?.width) / 2,
            y: (canvasRef.current?.height - image?.height) / 2,
        })
        setScale(1)
        setDrawings([])
        setLocalUrl(url)
    }

    const handleOpenSaveModal = () => {
        const canvas = canvasRef.current as HTMLCanvasElement
        let newUrl = canvas.toDataURL()
        setSaveUrl(newUrl)
        setIsOpenModal(true)
    }

    const handleSaveChanges = (data: string) => {
        onSave(data)
        setIsOpenModal(false)
    }

    const handleSelectLineSize = (size:LineWidthTypes)=>{
      setLineWidth(size)
      setIsDrawingMode(true)
      setIsMoving(false)
    }

    const getActiveDrawingBtnClass = (lineWidthParam:LineWidthTypes) =>{
        return lineWidth === lineWidthParam && isDrawingMode
        ? 'core-box-prepaparing-size-btn-active'
        : ''
    }

    const handleActiveDrawingMode = () =>{
        setIsDrawingMode(!isDrawingMode)
        if(!isDrawingMode===true){
            handleSelectLineSize(LineWidthTypes.large)
        }
    }

    return (
        <div className="core-box-prepaparing-photo-space-content">
            <canvas
                id="testCanvasRef"
                ref={canvasRef}
                width={500}
                height={500}
                onWheel={handleWheel}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseOut={handleMouseOut}
                onMouseOver={() =>
                    (document.querySelector('body').style.overflow = 'hidden')
                }
                onMouseLeave={() =>
                    (document.querySelector('body').style.overflow = 'visible')
                }
            />

            <div className="core-box-prepaparing-photo-space-btn-wrapper">
                <div className="core-box-prepaparing-size-buttons">
                    <div
                        className={`core-box-prepaparing-size-btn-lg ${getActiveDrawingBtnClass(LineWidthTypes.large)}`}
                        onClick={() => handleSelectLineSize(LineWidthTypes.large)}
                    ></div>
                    <div
                        className={`core-box-prepaparing-size-btn-medium ${getActiveDrawingBtnClass(LineWidthTypes.medium)}`}
                        onClick={() => handleSelectLineSize(LineWidthTypes.medium)}
                    ></div>
                    <div
                        className={`core-box-prepaparing-size-btn-sm ${getActiveDrawingBtnClass(LineWidthTypes.small)}`}
                        onClick={() => handleSelectLineSize(LineWidthTypes.small)}
                    ></div>
                    <div></div>
                    <div></div>
                </div>
                <Button
                    className="core-box-preparing-draw-btn"
                    type={isDrawingMode ? 'primary' : 'default'}
                    onClick={handleActiveDrawingMode}
                >
                    <EditIcon
                        width={20}
                        height={20}
                        fill={isDrawingMode ? '#fff' : undefined}
                    />
                </Button>
                <Button onClick={handleReset}>
                    {t('reset-changes')}
                </Button>
                <Button onClick={handleOpenSaveModal}>
                    {t('save-changes')}
                </Button>
            </div>
            <CropImageModal
                url={saveUrl}
                isOpenModal={isOpenModal}
                onSave={handleSaveChanges}
                setIsOpenModal={setIsOpenModal}
            />
        </div>
    )
}

export default CoreBoxCanvas
