import axios, { AxiosResponse } from 'axios'
import {
    LoginResponse,
    PasswordResetConfirmResponse,
    RegistrationResponse,
} from '../app/types/response'
import {
    ConfirmEmailChangeRequest,
    LoginRequest,
    PasswordResetConfirmRequest,
    RegistrationRequest,
} from '../app/types/request'
import { HTTP_BASE_URL_API } from '../utils/constants'
import {instance} from "../app/api";

export const authService = {
    registration: async (data: RegistrationRequest) => {
        return await axios
            .post<RegistrationRequest, AxiosResponse<RegistrationResponse>>(
                `${HTTP_BASE_URL_API}auth/registration/`,
                data,
                {
                    withCredentials: true,
                },
            )
            .then((res) => res.data)
    },
    login: async (data: LoginRequest) => {
        return await axios
            .post<LoginRequest, AxiosResponse<LoginResponse>>(
                `${HTTP_BASE_URL_API}auth/login/`,
                data,
                {
                    withCredentials: true,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    },
                },
            )
            .then((res) => res.data)
    },
    logOut: async () => {
        return await instance
            .post<LoginRequest, AxiosResponse<LoginResponse>>(
                `/auth/logout/`,
            )
            .then((res) => res.data)
    },
    verifyToken: async (data: { token: string }) => {
        return await axios
            .post<{ token: string }, AxiosResponse<{ token: string }>>(
                `${HTTP_BASE_URL_API}auth/token/verify/`,
                data,
                {withCredentials: true },
            )
            .then((res) => res.data)
    },
    passwordReset: async (data: { email: string }) => {
        return await axios
            .post<
                { email: string },
                AxiosResponse<{ email: string; detail?: string }>
            >(`${HTTP_BASE_URL_API}auth/password/reset/`, data)
            .then((res) => res.data)
    },
    passwordResetConfirm: async (data: PasswordResetConfirmRequest) => {
        return await axios
            .post<
                PasswordResetConfirmRequest,
                AxiosResponse<PasswordResetConfirmResponse>
            >(`${HTTP_BASE_URL_API}auth/password/reset/confirm/`, data, {})
            .then((res) => res.data)
    },
    emailVerify: async (data: { key: string; uid?: string }) => {
        return await axios
            .post<
                { key: string; uid?: string },
                AxiosResponse<{ key: string; uid?: string; detail?: any }>
            >(`${HTTP_BASE_URL_API}auth/registration/verify-email/`, data)
            .then((res) => res.data)
    },
    //todo - нет такого метода в свагере
    confirmEmailChange: async (data: ConfirmEmailChangeRequest) => {
        return await instance
            .post<ConfirmEmailChangeRequest, AxiosResponse<{ detail: any }>>(
                `/users/me/confirm-email-change/`,
                data,
            )
            .then((res) => res.data)
    },
    resendEmail: async (data: { email: string }) => {
        return await instance
            .post<
                { email: string },
                AxiosResponse<{ email: string; detail?: string }>
            >(`/auth/registration/resend-email/`, data)
            .then((res) => res.data)
    },
}
