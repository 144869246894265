import { ITask } from '../../app/types/models/tasks'

export const getMinPeriodFromTasksList = (tasks: ITask[]):{min:Date,max:Date} => {
    let min = '' as string | Date,
        max = '' as string | Date

    if (tasks.length > 0) {
        tasks.forEach((task) => {
            const startDate = task.due_date_start
                ? task.due_date_start
                : new Date()
            const endDate = task.due_date_end
                ? task.due_date_end
                : new Date()

            if (min === '') {
                min = startDate
            } else {
                if (new Date(min) > new Date(startDate)) {
                    min = startDate
                }
            }

            if (max === '') {
                max = endDate
            } else {
                if (new Date(max) < new Date(endDate)) {
                    max = endDate
                }
            }
        })
    }else{
        min = new Date()
        max = new Date()
    }
    return {min:new Date(min),max:new Date(max)}
}
