import * as d3 from 'd3'
import {curveBoxLocal} from "./curve-box-local";

export const curveBoxTemplateLocal = (string_of_either__help_example_definitions_mandatories) => {
    let request_string = string_of_either__help_example_definitions_mandatories;
    if (request_string == "help") {
        return "The curveBoxTemplateExamples function returns example templates based on an input argument. Possible argument values are 'example' 'defintions' or 'mandatories'";
    }
    if (request_string == "example") {
        return [{
            "curve_box": {
                "show_well_name": "yes", /// not built yet
                "show_depth_type": "yes", /// not built yet
                "show_curve_units": "yes", /// not built yet
                "curve_box_depth_min": -999, /// not built yet
                "curve_box_depth_max": -999, /// not built yet
                "take_out_null_or_visualize": "yes", /// not built yet
                "show_title": "yes",
                "width": 260,
                "height": 100,
                "height_multiplier_components": 1,
                "margin": {"top": 0, "right": 0, "bottom": 0, "left": 20},
                "title": {"text": "", "title_font_size": "10px"}, /// not built yet
                "div_id": "well_holder_3", /// Should be skip-able // default=random str? What happens if div doesn't exist?
                "order_of_component": ["curves", "rectanges", "lines"], /// not built yet
                "lines_connected_across_curveboxes": "yes", /// not built yet
                "header_sep_svg_or_not": "yes",
                "svg_header_height": "0em",
                "gridlines": "yes",
                "gridlines_color": "transparent",
                "gridlines_stroke_width": 0.20,
                "grouped_or_independent_x_scales": "independent",
                //// variables for how to draw mouseover of hover box
                "mouseover_yes_or_no": "no", //// "yes" or "no"
                "mouseover_depth_or_depth_and_curve": "depth_and_curve", /// options= "depth_and_curve", "depth", or "curve"
                "mouseover_curvename": "default", //// default is first curve
                "mouseover_color_or_default_which_is_curve_color": "default" /// default is default, which then uses curve color or black
            },
            "components": [{
                "curves": [
                    {
                        "data_type": "curve",
                        "curve_names": ["RHOB"],
                        "curve_colors": ["black"],
                        "curve_stroke_dasharray": ["solid"],
                        "stroke_linecap": ["butt"],
                        "stroke_width": [1],
                        "fill": [
                            {
                                "curve_name": "RHOB",
                                "fill": "yes",
                                "fill_direction": "left",
                                "cutoffs": [0.21, 2.23, 2.24],
                                "fill_colors": ["gray", "beige", "white"],
                                "curve2": ""
                            }
                        ],
                        "curve_units": ["g/cm3"],
                        "depth_limits": [{"min": "autocalculate", "max": "autocalculate"}],
                        "curve_limits": [{"curve_name": "", "min": -10000000, "max": 3}],
                        "data": [{"depth": 1598.3, "RHOB": 2.2322}, {
                            "depth": 1598.4,
                            "RHOB": 2.0513
                        }, {"depth": 1598.5, "RHOB": 2.2548}, {
                            "depth": 1598.6,
                            "RHOB": 2.9445
                        }, {"depth": 1598.7, "RHOB": 2.2223}, {
                            "depth": 1598.8,
                            "RHOB": 2.447
                        }, {"depth": 1598.9, "RHOB": 2.598}, {"depth": 1599, "RHOB": 2.8088}, {
                            "depth": 1599.1,
                            "RHOB": 2.2248
                        }, {"depth": 1599.2, "RHOB": 2.2399}, {
                            "depth": 1599.3,
                            "RHOB": 2.251
                        }, {"depth": 1599.4, "RHOB": 2.255}, {
                            "depth": 1599.5,
                            "RHOB": 2.2526
                        }, {"depth": 1599.6, "RHOB": 2.2322}, {
                            "depth": 1599.7,
                            "RHOB": 2.2513
                        }, {"depth": 1599.8, "RHOB": 2.2548}, {"depth": 1599.9, "RHOB": 2.2445}, {
                            "depth": 1600,
                            "RHOB": 2.2223
                        }, {"depth": 1600.1, "RHOB": 2.2047}, {"depth": 1600.2, "RHOB": 2.198}], /// not built yet
                        "depth_curve_name": "DEPT",/// not built yet
                        //////
                        "data_id": ["example_1",], /// not built yet
                        "well_names": ["D"], /// not built yet
                        "scale_linear_log_or_yours": ["linear"],
                        "line_color": ["red"], /// not built yet
                        "max_depth": "autocalculate", /// not built yet
                        "min_depth": "autocalculate", /// not built yet
                        "depth_type_string": [""],
                        "depth_units_string": [""],
                        "null_value": [""], /// not built yet
                    }
                ],
                "lines": [
                    {
                        "data_type": "line",  /// not built yet
                        "label": "example",  /// not built yet
                        "depth": -999, /// not built yet
                        "color": "transparent", /// not built yet
                        "stroke_width": "3px", /// not built yet
                        "stroke_style": "solid", /// not built yet
                        "transparency": 1.0, /// not built yet
                        "stroke_linecap": "butt"
                    }
                ],
                "rectangles": [
                    {
                        "data_type": "rectangle",
                        "depth_top": 0,
                        "x_starting_upper_left_corner": 0,
                        "width": 100,
                        "height": 100,
                        "stroke_width": "2px",
                        "stroke_linecap": "butt",
                        "fill": "transparent",
                        "opacity": 0, //0.5
                        "label": "", // not built into plotting template yet
                        "label_orientation": "horizontal", // not built into plotting template yet
                        "lable_position": "right" // not built into plotting template yet
                    }
                ]
            }]
        }];
    }
}

export const minimumDataIntoTemplateFuncLocal = (example_template, data, well_names, curve_names, curve_colors, curve_units, fill, div_id, width, height, depth_curve_name) => {
    //// remember that all curve components should be an array, even if one item!
    let example_template_n = JSON.parse(JSON.stringify(example_template));
    example_template_n[0]["components"][0]["curves"][0]["data"] = data;
    example_template_n[0]["components"][0]["curves"][0]["well_names"] = well_names;
    example_template_n[0]["components"][0]["curves"][0]["curve_names"] = curve_names;
    example_template_n[0]["components"][0]["curves"][0]["curve_colors"] = curve_colors;
    example_template_n[0]["components"][0]["curves"][0]["curve_units"] = curve_units;
    example_template_n[0]["components"][0]["curves"][0]["fill"] = fill;
    example_template_n[0]["components"][0]["curves"][0]["depth_curve_name"] = depth_curve_name;
    example_template_n[0]["curve_box"]["div_id"] = div_id;
    example_template_n[0]["curve_box"]["width"] = width;
    example_template_n[0]["curve_box"]["height"] = height;
    return example_template_n;
}

export const multipleLogPlotLocal = (div_id, templates, show_all = true,remove_preexisting=true) => {
    let noDIV = d3.select("#" + div_id).selectAll("div").remove();
    let noSVG = d3.select("#" + div_id).selectAll("svg").remove();
    let new_templates = [];
    for (let i = 0; i < templates.length; i++) {
        let curvebox_holder = d3.select("#" + div_id).append("div");
        curvebox_holder.style("vertical-align", "middle")
            .attr("id", div_id + "curvebox_holder" + i);
        //// to control view of plots on site, user can show-hide by triggering action here. However, if show_all = false then none will show, so developer will need to change CSS with another function one by one!
        if (show_all) {
            curvebox_holder.style("display", "inline-block");
        } else {
            curvebox_holder.style("display", "none");
        }
        templates[i][0]["curve_box"]["div_id"] = div_id + "curvebox_holder" + i;
        new_templates.push(templates[i]);
        let template = templates[i];
        let check = curveBoxLocal(template);
    }
    return new_templates;
}