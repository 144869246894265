import React, {FC} from 'react';
import {IIconProps} from "../../app/types/i-props";

const NoticeIcon:FC<IIconProps> = (props) => {
    return (
        <svg width="40" height="40" viewBox="2 4 56 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.0286 35.0004L28.997 34.9995L28.9655 35.0006L18.6868 35.355C16.2682 35.4384 15.584 32.0776 17.8428 31.2088C19.4437 30.5931 20.5 29.055 20.5 27.3398V19.2703C20.5 18.8887 20.5728 18.5105 20.7146 18.1562L21.2083 16.9217L20.2799 16.5504L21.2083 16.9217C21.3178 16.6481 21.467 16.392 21.6512 16.1618L22.8318 14.686C23.0546 14.4076 23.3252 14.1711 23.6309 13.9876L25.138 13.0834C25.3257 12.9708 25.5251 12.879 25.7328 12.8098L27.4035 12.2529C28.3128 11.9498 28.9845 11.1753 29.156 10.2323L29.1768 10.1179C29.3002 9.43898 30.2808 9.46074 30.3741 10.1444C30.5083 11.1285 31.1901 11.9508 32.1323 12.2649L33.7672 12.8098C33.9749 12.8791 34.1743 12.9708 34.362 13.0834L35.8691 13.9876C36.1748 14.1711 36.4454 14.4076 36.6682 14.686L37.8488 16.1618C38.033 16.392 38.1822 16.6481 38.2917 16.9217L38.7854 18.1562L39.7139 17.7848L38.7854 18.1562C38.9272 18.5105 39 18.8887 39 19.2703V27.8053C39 29.5503 40.1517 31.0859 41.8269 31.5745C43.9963 32.2072 43.4914 35.4136 41.2325 35.3491L29.0286 35.0004Z"
                fill="#E9EFF3" stroke="#364F6B" strokeWidth="2"/>
            <path d="M26.9526 35.4962V35.4962C27.337 39.314 32.8711 39.2998 33.4717 35.51V35.51" stroke="#364F6B"
                  strokeWidth="2"/>
        </svg>

    );
};

export default NoticeIcon;