import { Popover } from 'antd'
import { TooltipPlacement } from 'antd/es/tooltip'
import React, { FC } from 'react'
import Clipboard from 'react-clipboard.js'
import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ITask } from '../../app/types/models/tasks'
import { CopyDataIcon } from '../../image_files/icons/CopyDataIcon'
import { DeleteIcon } from '../../image_files/icons/DeleteIcon'
import { DottesMenuIcon } from '../../image_files/icons/DotesMenuIcon'
import { EditIcon } from '../../image_files/icons/EditIcon'
import { OpenInNewTabIcon } from '../../image_files/icons/OpenInNewTabIcon'
import { DotesMenuItem } from '../DotesMenuItem/DotesMenuItem'
import './index.css'

interface IProps {
    task?: ITask
    popoverClassName?: string
    className?: string
    statusItemContent?: any
    targetUrl?: string
    copyUrl?: string
    popoverPosition?: TooltipPlacement

    deleteTask?: (value: ITask) => void
}

export const DotesMenu: FC<IProps> = ({
    task,
    popoverClassName,
    className,
    statusItemContent,
    targetUrl,
    copyUrl,
    popoverPosition,

    deleteTask,
}) => {
    const currentUrlPath = window.location.href.split('/')[2]
    const { t } = useTranslation()

    return (
        <div
            className={className ? `dotesMenu ${className}` : 'dotesMenu'}
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
        >
            <div className="dotesMenu__list">
                <Popover
                    placement={popoverPosition ? popoverPosition : 'bottomLeft'}
                    overlayClassName={popoverClassName ? popoverClassName : ''}
                    content={
                        statusItemContent ? (
                            statusItemContent
                        ) : (
                            <div className="dotesMenu__list-inner">
                                <NavLink
                                    to={targetUrl ? targetUrl : `${task?.slug}`}
                                    target={'_blank'}
                                >
                                    <DotesMenuItem
                                        title={t('open-in-new-tab')}
                                        icon={<OpenInNewTabIcon />}
                                    />
                                </NavLink>
                                <Clipboard
                                    data-clipboard-text={
                                        copyUrl
                                            ? copyUrl
                                            : `http://${currentUrlPath}${targetUrl}`
                                    }
                                    className="dotesMenu__clipboard"
                                    onSuccess={() => {
                                        toast.success(`${t('copy-link')}`, {
                                            position: 'top-center',
                                        })
                                    }}
                                >
                                    <DotesMenuItem
                                        title={t('copy-link')}
                                        icon={<CopyDataIcon />}
                                    />
                                </Clipboard>
                                <Link
                                    to={targetUrl ? targetUrl : `${task.slug}`}
                                >
                                    <DotesMenuItem
                                        title={t('edit')}
                                        icon={<EditIcon />}
                                        handler={() => {}}
                                    />
                                </Link>
                                <DotesMenuItem
                                    title={t('delete')}
                                    icon={<DeleteIcon />}
                                    handler={
                                        deleteTask
                                            ? () => deleteTask(task)
                                            : () => {}
                                    }
                                />
                            </div>
                        )
                    }
                >
                    <button className="dotesMenu__btn">
                        <DottesMenuIcon />
                    </button>
                </Popover>
            </div>
        </div>
    )
}
