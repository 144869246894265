import React, { FC } from 'react'
import { IIconProps } from '../../app/types/i-props'

export const CopyDataIcon: FC<IIconProps> = ({
    width,
    height,
    stroke,
    viewBox,
    className,

    onClick,
}) => {
    return (
        <svg
            fill="none"
            className={className}
            width={width ? width : 24}
            height={height ? height : 24}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox ? viewBox : '0 0 24 24'}
            onClick={onClick}
        >
            <path
                d="M10.9665 19.8808C10.183 20.6156 9.14437 21.0167 8.07037 20.9995C6.99636 20.9822 5.97119 20.5479 5.21165 19.7883C4.45212 19.0288 4.0178 18.0036 4.00053 16.9296C3.98327 15.8556 4.38443 14.817 5.11916 14.0335L8.77415 10.3769C9.51216 9.63985 10.5014 9.20874 11.5437 9.16996C12.586 9.13117 13.6046 9.48757 14.3953 10.1678M13.8918 5.26089C14.2733 4.86626 14.7296 4.55154 15.234 4.33509C15.7385 4.11863 16.281 4.00477 16.8299 4.00015C17.3788 3.99552 17.9231 4.10022 18.4311 4.30815C18.9391 4.51607 19.4007 4.82306 19.7888 5.2112C20.1769 5.59934 20.4839 6.06088 20.6919 6.5689C20.8998 7.07691 21.0045 7.62124 20.9999 8.17014C20.9952 8.71904 20.8814 9.26152 20.6649 9.76596C20.4485 10.2704 20.1337 10.7267 19.7391 11.1083L16.0841 14.7648C15.3461 15.5019 14.3569 15.933 13.3146 15.9718C12.2722 16.0106 11.2537 15.6542 10.463 14.974"
                stroke={stroke ? stroke : '#2B506B'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
