import React, {FC} from 'react';
import {getValidText} from "../../utils/helpers/getValidText";
import {getFirstMemberCharacter} from "../../utils/helpers/getFirstMemberCharacter";
import {IPersonProps} from "./types";
import './person.scss';

const Person:FC<IPersonProps> = ({avatar,email,firstName,lastName}) => {
    const showName = ()=>{
        let str = `${getValidText(firstName)} ${getValidText(lastName)}`
        return str?.length>1 ? str : getValidText(email)
    }
    return (
        <div className={'row-flex-10'}>
            {avatar ? (
                <img
                    className={'person__img'}
                    src={getValidText(avatar?.original)}
                    alt="user image"
                />
            ) : (
                <div
                    className={'person-name-phone '}
                >
                    <span >{getFirstMemberCharacter(email)}</span>
                </div>
            )}
            <div className={'font-15-normal person-name'}>{showName()}</div>
        </div>
    );
};

export default Person;