import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ISubTask, ITask } from '../../../../app/types/models/tasks'
import { useAppDispatch } from "../../../../hooks/redux-hooks"
import { tasksService } from '../../../../services/tasks-services'
import { AddTextButton } from '../../../../shared/AddTextButton/AddTextButton'
import { DotesMenu } from '../../../../shared/DotesMenu/DotesMenu'
import { EditableSpan } from '../../../../shared/EditableSpan/EditableSpan'
import { TaskDeleteModal } from '../../../../shared/taskDeleteModal/TaskDeleteModal'
import { tasksActions } from '../../../../store/tasks'
import { errorsHandler } from "../../../../utils/helpers/errors/errors-hendler"
import './subTask.css'

export const SubTask = ({ className,task}:{className?:string,task:ITask}) => {
    const [subtaskList, setSubtaskList] = useState([])
    const [editMode, setEditMode] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [currentSubTask, setCurrentSubTask] = useState<ISubTask | null>(null)
    const dispatch = useAppDispatch()
    //селекторы
    const copyUrl = window.location.href
    const { t } = useTranslation()
    const { addTask, addSubtask, setTaskTitle,deleteSubtask,deleteTask } = tasksActions

    useEffect(() => {
        setSubtaskList(task?.subtasks || [])
    }, [task])

    const addText = async (text:string):Promise<void> => {
        try {
            const newSubTask = { name: text.trim(), parent: task?.id }
            const {data} = await tasksService.createSubTask(newSubTask, task?.project)
            dispatch(addSubtask({ subtask: data }))
            dispatch(addTask({ task: data }))
            setSubtaskList([data, ...subtaskList])
        }catch (e) {
            console.log(e)
            errorsHandler(e,t)
        }
    }

    const editSubtaskTitleHandler = (value, taskSlug) => {
        const newSubtaskList = subtaskList.map((m) =>
            m === value ? value : m,
        )
        const updatedSubTask = { name: value, parent: task?.id }
        tasksService
            .updateSubTask(updatedSubTask, task?.project, taskSlug)
            .then(() => {
                dispatch(setTaskTitle({ title: value.trim(), slug: taskSlug }))
            })
        setSubtaskList(newSubtaskList)
    }

    const changeCurrentTaskHandler = (value: string) => {
        console.log(value)
    }


    const deleteSubTaskHandler = (subTask) => {
        setCurrentSubTask(subTask)
        setOpenModal(true)
    }

    // хэндлеры для модалки
    const hideModal = () => {
        setOpenModal(false)
    }

    const showModal = async () => {
        try {
            if(currentSubTask){
                await tasksService.deleteTask(task?.project, currentSubTask?.slug)
                dispatch(deleteSubtask({slug:currentSubTask.slug}))
                dispatch(deleteTask({slug:task.slug}))
                setOpenModal(false)
            }
        }catch (e) {
            console.log(e)
            errorsHandler(e,t)
        }
    }

    if(task?.slug?.split('_').length < 3){
        return (
            <div className={className ? `subTask ${className}` : 'subTask'}>
                <AddTextButton
                    addText={addText}
                    placeholder={t('add-subtask-name')}
                    title={t('subtask')}
                />
                <div className="subtask__taskList">
                    {subtaskList.map((task) => {
                        return (
                            <div
                                key={task?.id}
                                className="subtask__taskList-item"
                                onClick={() => changeCurrentTaskHandler(task)}
                            >
                                <EditableSpan
                                    title={task?.name}
                                    editTask={editMode}
                                    taskSlug={task?.slug}
                                    currentSubTask={currentSubTask?.name}
                                    setEditStatus={setEditMode}
                                    handler={editSubtaskTitleHandler}
                                />
                                <DotesMenu
                                    deleteTask={deleteSubTaskHandler}
                                    className={'subtask__taskList-itemMenu'}
                                    copyUrl={copyUrl + '/' + task.slug}
                                    task={task}
                                    popoverClassName={'subtask-popover'}
                                />
                            </div>
                        )
                    })}
                </div>
                <TaskDeleteModal
                    openModal={openModal}
                    showModal={showModal}
                    hideModal={hideModal}
                    title={t(
                        'delition-subtask',
                    )}
                    confirmText={t(
                        'ready-to-delete-value-questuion',{value:t('a-subtask')}
                    )}
                />
            </div>
        )
    }else{
        return <div></div>
    }


}
