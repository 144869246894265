import React from 'react';
import { FC, useContext }from 'react';
import { IIconProps } from '../../app/types/i-props';

const FolderIcon: FC<IIconProps> = ({
  fill,
  width,
  height,
  viewBox,
  onClick
}) => {
  return (
    <svg
      width={width ? width : 24}
      height={height ? height : 24}
      viewBox={viewBox ? viewBox : '0 0 24 24'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.559 3.88218C11.3323 3.76882 11.0567 3.75021 10 3.75021H7C6.03599 3.75021 5.38843 3.75181 4.90539 3.81675C4.44393 3.87879 4.24644 3.98601 4.11612 4.11633C3.9858 4.24665 3.87858 4.44415 3.81654 4.90561C3.77009 5.25111 3.75605 5.68078 3.75182 6.25022H13.5L12.6 5.05022C11.966 4.20484 11.7857 3.99554 11.559 3.88218ZM15.375 6.25022L13.8 4.15021C13.7704 4.1108 13.7413 4.07182 13.7125 4.0333C13.2113 3.36347 12.8141 2.83267 12.2298 2.54054C11.6456 2.24841 10.9826 2.24916 10.1461 2.25011C10.098 2.25016 10.0493 2.25021 10 2.25021L6.948 2.25021C6.04952 2.25018 5.3003 2.25016 4.70552 2.33013C4.07773 2.41453 3.51093 2.6002 3.05546 3.05567C2.59999 3.51114 2.41432 4.07794 2.32991 4.70574C2.24995 5.30052 2.24997 6.04974 2.25 6.94822V17.0522C2.24997 17.9507 2.24995 18.6999 2.32991 19.2947C2.41432 19.9225 2.59999 20.4893 3.05546 20.9448C3.51093 21.4002 4.07773 21.5859 4.70552 21.6703C5.3003 21.7503 6.04953 21.7502 6.94801 21.7502H17.052C17.9505 21.7502 18.6997 21.7503 19.2945 21.6703C19.9223 21.5859 20.4891 21.4002 20.9445 20.9448C21.4 20.4893 21.5857 19.9225 21.6701 19.2947C21.7501 18.6999 21.75 17.9507 21.75 17.0522V10.9482C21.75 10.0497 21.7501 9.30052 21.6701 8.70574C21.5857 8.07794 21.4 7.51114 20.9445 7.05567C20.4891 6.6002 19.9223 6.41453 19.2945 6.33013C18.6997 6.25016 17.9505 6.25018 17.052 6.25021L15.375 6.25022ZM3.75 7.75022V17.0002C3.75 17.9642 3.7516 18.6118 3.81654 19.0948C3.87858 19.5563 3.9858 19.7538 4.11612 19.8841C4.24644 20.0144 4.44393 20.1216 4.90539 20.1837C5.38843 20.2486 6.03599 20.2502 7 20.2502H17C17.964 20.2502 18.6116 20.2486 19.0946 20.1837C19.5561 20.1216 19.7536 20.0144 19.8839 19.8841C20.0142 19.7538 20.1214 19.5563 20.1835 19.0948C20.2484 18.6118 20.25 17.9642 20.25 17.0002V11.0002C20.25 10.0362 20.2484 9.38864 20.1835 8.90561C20.1214 8.44415 20.0142 8.24665 19.8839 8.11633C19.7536 7.98602 19.5561 7.87879 19.0946 7.81675C18.6116 7.75181 17.964 7.75022 17 7.75022H3.75Z"
        fill={fill ? fill : '#364f6b'}
      />
    </svg>
  );
};

export default FolderIcon;
