import React, {ChangeEvent, FC, KeyboardEvent, ReactNode} from 'react';
import {Form, Input, Select, Skeleton, Switch} from "antd";
import {SelectIcon} from "../../image_files/icons/SelectIcon";
import {NumericFormat} from "react-number-format";
//@ts-ignore
import s from './formItem.module.scss';
import InputInLog from "../input/Input";

type InputFieldTypes = 'input' | 'select' | 'numeric' | 'switch' | 'textArea' | 'custom'

interface IProps {
    label: string | ReactNode
    placeholder?: string
    name?: string
    type?: InputFieldTypes
    options?: { name: string, value: string }[]
    value?: string
    checked?: boolean
    colProps?: any
    inputStyles?: object
    children?: React.ReactElement
    formItemClassName?: string
    loading?: boolean

    onChange?: (value: any, name?: string) => void
    onKeyDown?:(e:KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLTextAreaElement>)=>void
    onSelectChange?: (value: string, name?: string) => void
    onChangeSwitch?: () => void
}

const inputStyle = {
    minWidth: '100%',
    border: '1px solid #d9d9d9',
    borderRadius: '5px',
    fontsize: '16px',
    color: '#364f6b',
    padding: '4px 11px',
    outline: 'none'
}

const FormItem: FC<IProps> = (props) => {
    const {label, name, colProps, loading} = props
    return (
        <div {...colProps} className={colProps?.className ? `${s.block} ${colProps?.className}` : s.block}>
            <Form.Item
                label={label}
                name={name}
                className={props.formItemClassName ? `${s.formItem} ${props.formItemClassName}` : s.formItem}
            >
                {
                    typeof loading !== 'undefined' && loading ?
                        <Skeleton.Input active={true} className={'skeleton-input'}/>
                        : <SelectedTag {...props}  />
                }
            </Form.Item>
        </div>
    );
};

export default FormItem;


const SelectedTag: FC<IProps> = (props) => {
    const {
        placeholder,
        type = 'input',
        options,
        value,
        name,
        checked,
        inputStyles,
        children,
        onChange,
        onKeyDown,
        onSelectChange,
        onChangeSwitch,
    } = props
    if (type === 'input') {
        return (
            <InputInLog
                className={s.input}
                placeholder={placeholder}
                onChange={onChange}
                onKeyDownhandler={onKeyDown}
                style={inputStyles ? inputStyles : inputStyle}
                value={value}
            />
        )
    } else if (type === 'select') {
        return <Select
            className={s.select}
            placeholder={placeholder}
            onChange={(e: string) => {
                onSelectChange(e, name)
            }}
            suffixIcon={<SelectIcon/>}
            defaultValue={value}
            bordered={false}
            style={{
                width: '100%',
                height: '100%',
                border: '1px solid #d9d9d9',
                borderRadius: '5px',
                fontSize: '16px'
            }}
        >
            {options && options.map(
                (status: { name: string, value: string }) => (
                    <Select.Option
                        value={
                            status.value
                        }
                        key={
                            status.value
                        }
                    >
                        <div
                            className={s.option}
                        >
                            {
                                status.name
                            }
                        </div>
                    </Select.Option>
                ),
            )}
        </Select>
    } else if (type === 'numeric') {
        return <NumericFormat
            className={s.input}
            maxLength={11}
            value={value}
            onChange={(e) => onChange(e, name,)}
            decimalScale={2}
            style={inputStyles ? inputStyles : inputStyle}
        />
    } else if (type === 'switch') {
        return <Switch
            checked={checked}
            onChange={onChangeSwitch}
        />
    } else if (type === 'textArea') {
        return <Input.TextArea
            placeholder={placeholder}
            onChange={(e) => onChange(e, name,)}
            style={{
                minWidth: '100%',
                borderRadius: '5px',
                resize: 'none',
            }}
            onKeyDown={onKeyDown}
            className={s.input}
            value={value}
        />
    } else if (type === 'custom') {
        return children
    }
}

