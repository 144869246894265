import { createAction } from '@reduxjs/toolkit'
import { IUser } from '../../app/types/models/users'
import { LanguageTypes } from '../../app/types/enums'

const setUser = createAction<{ user: IUser }>('userActions/setUser')

const setLanguage = createAction<{ language: LanguageTypes }>(
    'userActions/setLanguage',
)

export { setUser, setLanguage }
