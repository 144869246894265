import React from 'react'

export const ClockIcon = () => {
    return (
        <svg
            style={{ transform: 'translateY(3px)' }}
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="29" height="29" rx="1" fill="white" />
            <circle cx="14.5" cy="14.5" r="9" fill="white" stroke="#FF6969" />
            <path
                d="M10.0001 17.0001L14.5001 14.5001L14.5001 7.50001"
                stroke="#B2AFAF"
            />
        </svg>
    )
}
