export const countriesRu = {
    AF: "Афганистан",
    AL: "Албания",
    DZ: "Алжир",
    AS: "Американское Самоа",
    AD: "Андорра",
    AO: "Ангола",
    AI: "Ангилья",
    AG: "Антигуа и Барбуда",
    AR: "Аргентина",
    AM: "Армения",
    AW: "Аруба",
    AU: "Австралия",
    AT: "Австрия",
    AZ: "Азербайджан",
    BS: "Багамские острова",
    BH: "Бахрейн",
    BD: "Бангладеш",
    BB: "Барбадос",
    BY: "Беларусь",
    BE: "Бельгия",
    BZ: "Белиз",
    BJ: "Бенин",
    BM: "Бермудские острова",
    BT: "Бутан",
    BO: "Боливия, многонациональное государство",
    BA: "Босния и Герцеговина",
    BW: "Ботсвана",
    BR: "Бразилия",
    IO: "Британская территория в Индийском океане",
    BG: "Болгария",
    BF: "Буркина-Фасо",
    BI: "Бурунди",
    KH: "Камбоджа",
    CM: "Камерун",
    CA: "Канада",
    CV: "Кабо-Верде",
    KY: "Каймановы острова",
    CF: "Центральноафриканская Республика",
    TD: "Чад",
    CL: "Чили",
    CN: "Китай",
    CO: "Колумбия",
    KM: "Коморские острова",
    CG: "Конго",
    CD: "Демократическая Республика Конго",
    CK: "Острова Кука",
    CR: "Коста-Рика",
    CI: "Кот-д'Ивуар",
    HR: "Хорватия",
    CU: "Куба",
    CW: "Кюрасао",
    CY: "Кипр",
    CZ: "Чешская Республика",
    DK: "Дания",
    DJ: "Джибути",
    DM: "Доминика",
    DO: "Доминиканская Республика",
    EC: "Эквадор",
    EG: "Египет",
    SV: "Сальвадор",
    GQ: "Экваториальная Гвинея",
    ER: "Эритрея",
    EE: "Эстония",
    ET: "Эфиопия",
    FK: "Фолклендские (Мальвинские) острова",
    FO: "Фарерские острова",
    FJ: "Фиджи",
    FI: "Финляндия",
    FR: "Франция",
    PF: "Французская Полинезия",
    GA: "Габон",
    GM: "Гамбия",
    GE: "Джорджия",
    DE: "Германия",
    GH: "Гана",
    GI: "Гибралтар",
    GR: "Греция",
    GL: "Гренландия",
    GD: "Гренада",
    GU: "Гуам",
    GT: "Гватемала",
    GG: "Гернси",
    GN: "Гвинея",
    GW: "Гвинея-Бисау",
    HT: "Гаити",
    HN: "Гондурас",
    HK: "Гонконг",
    HU: "Венгрия",
    IS: "Исландия",
    IN: "Индия",
    ID: "Индонезия",
    IR: "Иран, Исламская Республика",
    IQ: "Ирак",
    IE: "Ирландия",
    IM: "Остров Мэн",
    IL: "Израиль",
    IT: "Италия",
    JM: "Ямайка",
    JP: "Япония",
    JE: "Джерси",
    JO: "Иордания",
    KZ: "Казахстан",
    KE: "Кения",
    KI: "Кирибати",
    KP: "Северная Корея",
    KR: "Южная Корея",
    KW: "Кувейт",
    KG: "Кыргызстан",
    LA: "Лаосская Народно-Демократическая Республика",
    LV: "Латвия",
    LB: "Ливан",
    LS: "Лесото",
    LR: "Либерия",
    LY: "Ливия",
    LI: "Лихтенштейн",
    LT: "Литва",
    LU: "Люксембург",
    MO: "Макао",
    MK: "Республика Македония",
    MG: "Мадагаскар",
    MW: "Малави",
    MY: "Малайзия",
    MV: "Мальдивы",
    ML: "Мали",
    MT: "Мальта",
    MH: "Маршалловы острова",
    MQ: "Мартиника",
    MR: "Мавритания",
    MU: "Маврикий",
    MX: "Мексика",
    FM: "Микронезия, Федеративные Штаты",
    MD: "Республика Молдова",
    MC: "Монако",
    MN: "Монголия",
    ME: "Черногория",
    MS: "Монтсеррат",
    MA: "Марокко",
    MZ: "Мозамбик",
    MM: "Мьянма",
    NA: "Намибия",
    NR: "Науру",
    NP: "Непал",
    NL: "Нидерланды",
    NZ: "Новая Зеландия",
    NI: "Никарагуа",
    NE: "Нигер",
    NG: "Нигерия",
    NU: "Ниуэ",
    NF: "Остров Норфолк",
    MP: "Северные Марианские острова",
    NO: "Норвегия",
    OM: "Оман",
    PK: "Пакистан",
    PW: "Палау",
    PS: "Палестинская территория",
    PA: "Панама",
    PG: "Папуа - Новая Гвинея",
    PY: "Парагвай",
    PE: "Перу",
    PH: "Филиппины",
    PN: "Питкэрн",
    PL: "Польша",
    PT: "Португалия",
    PR: "Пуэрто-Рико",
    QA: "Катар",
    RO: "Румыния",
    RU: "Россия",
    RW: "Руанда",
    KN: "Сент-Китс и Невис",
    LC: "Сент-Люсия",
    WS: "Самоа",
    SM: "Сан-Марино",
    ST: "Сан-Томе и Принсипи",
    SA: "Саудовская Аравия",
    SN: "Сенегал",
    RS: "Сербия",
    SC: "Сейшельские острова",
    SL: "Сьерра-Леоне",
    SG: "Сингапур",
    SX: "Синт-Мартен",
    SK: "Словакия",
    SI: "Словения",
    SB: "Соломоновы острова",
    SO: "Сомали",
    ZA: "Южная Африка",
    SS: "Южный Судан",
    ES: "Испания",
    LK: "Шри-Ланка",
    SD: "Судан",
    SR: "Суринам",
    SZ: "Свазиленд",
    SE: "Швеция",
    CH: "Швейцария",
    SY: "Сирия",
    TW: "Тайвань",
    TJ: "Таджикистан",
    TZ: "Танзания",
    TH: "Таиланд",
    TG: "Того",
    TK: "Токелау",
    TO: "Тонга",
    TT: "Тринидад и Тобаго",
    TN: "Тунис",
    TR: "Турция",
    TM: "Туркменистан",
    TC: "Острова Теркс и Кайкос",
    TV: "Тувалу",
    UG: "Уганда",
    UA: "Украина",
    AE: "Объединенные Арабские Эмираты",
    GB: "Соединенное Королевство",
    US: "Соединенные Штаты",
    UY: "Уругвай",
    UZ: "Узбекистан",
    VU: "Вануату",
    VE: "Венесуэла, Боливарианская Республика",
    VN: "Вьетнам",
    VI: "Виргинские острова",
    YE: "Йемен",
    ZM: "Замбия",
    ZW: "Зимбабве",
}

export const countriesEn = {
    AF: "Afghanistan",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia, Plurinational State of",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Democratic Republic of the Congo",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Côte d'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    PF: "French Polynesia",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    HT: "Haiti",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic of",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "North Korea",
    KR: "South Korea",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Republic of Macedonia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    MX: "Mexico",
    FM: "Micronesia, Federated States of",
    MD: "Republic of Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RO: "Romania",
    RU: "Russia",
    RW: "Rwanda",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syria",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela, Bolivarian Republic of",
    VN: "Viet Nam",
    VI: "Virgin Islands",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
}
