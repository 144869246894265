import React, { FC, useCallback, useEffect, useState } from 'react';
import './gisTable.css';
import { useTranslation } from "react-i18next";
import {
    selectCurrentProject,
    selectCurrentWell,
    selectCurrentWellBore,
    selectCurrentWellLogFile,
    selectWellLogFiles,
} from "../../../../../../store/currentProject/selectors";
import { v4 as uuidv4 } from 'uuid'
import SettingCancelIcon from "../../../../../../image_files/icons/SettingCancelIcon";
import { wellsService } from "../../../../../../services/wells-service";
import { IFileData, IGisTableProps, ITableRow } from "./types";
import { showNotice } from "../../../../../../utils/helpers/showNotice";
import { IMnemonicGroup } from "../../../../../../app/types/models/IMnemonic";
import { getCorrectDateFormat } from "../../../../../../utils/getCorrectDateFormat";
import { useSelector } from "react-redux";
import { mnemonicGroupsService } from "../../../../../../services/mnemonic-groups";
import { getTopFileList } from "./data/getTopFileList";
import TableInLog from "../../../../../../shared/table/Table";
import { getTableTopData } from "./data/getTableTopData";
import GisTableRow from "./components/gisTableRow/GisTableRow";
import { errorsHandler } from "../../../../../../utils/helpers/errors/errors-hendler";

export const GisTable: FC<IGisTableProps> = ({ assesmentStatus }) => {
    const currentWellLogFile = useSelector(selectCurrentWellLogFile)
    const { t, i18n } = useTranslation()
    const [isCheckedTopRadioButton, setIsCheckedTopRadioButton] = useState(false)
    const currentProject = useSelector(selectCurrentProject)
    const [fetchedMnemonicGroups, setFetchedMnemonicGroups] = useState(null)
    const [fileData, setFileData] = useState<IFileData[]>([])
    const [tableLoading, setTableLoading] = useState(false)
    const wellLogFiles = useSelector(selectWellLogFiles)
    const currentWell = useSelector(selectCurrentWell)
    const currentWellBore = useSelector(selectCurrentWellBore)

    //local services
    const showValidMnemonicParam = () => {
        return currentWell ? { well_log_file__well: currentWell?.id } : { well_log_file__wellbore: currentWellBore?.id }
    }

    const getPreparedMnemonicData = () => {
        const filteredData = fileData && fileData?.length > 0 ? fileData?.filter(item => item.isChecked) : []
        return filteredData?.length > 0 ? filteredData?.map(val => ({
            description: val.item[0],
            coefficient: val.item[6],
            name: val.item[2],
            depths: val.item[7],
            values: val.item[8],
            unit: val.item[5],
            well_log_file: currentWellLogFile?.id,
        })) : []
    }

    //fetch handlers
    const fetchCurrentWellLogFile = useCallback(async () => {
        try {
            if (currentWellLogFile) {
                setTableLoading(true)
                const response = await wellsService.getWellLogFileById(currentProject?.id, currentWellLogFile?.id)
                if (response.data_matching_detail && response.data_matching_detail.length > 0) {
                    setFileData(response?.data_matching_detail?.map(item => {
                        let isReady = !(item.slice(0, 3).includes(null)) && !(item.slice(5, 7).includes(null));
                        return { item, isChecked: false, id: uuidv4(), disabled: !isReady }
                    }))
                    setTableLoading(false)
                } else {
                    setTimeout(async () => {
                        await fetchCurrentWellLogFile()
                    }, 1500)
                    setTableLoading(true)
                }
            }
        } catch (e) {
            setTableLoading(false)
        }
    }, [currentWellLogFile, currentProject, assesmentStatus])

    const fetchMnemonicGroup = async () => {
        try {
            const response = await mnemonicGroupsService.getMnemonicGroups(currentProject?.id, showValidMnemonicParam())
            setFetchedMnemonicGroups(response)
        } catch (e) {
            console.log(e)
            // errorsHandler(e, t)
        }
    }

    const addMnemonicGroup = async () => {
        try {
            await mnemonicGroupsService.addMnemonicGroup(currentProject?.id, {
                'analytic_priority': !(fetchedMnemonicGroups && fetchedMnemonicGroups.length > 0),
                saved_mnemonics_data: getPreparedMnemonicData(),
                well_log_file: currentWellLogFile?.id,
            })
            showNotice('group-added-successfully', t)
            fetchMnemonicGroup()
        } catch (e) {
            console.log(e)
            // errorsHandler(e,t)
        }
    }

    const updateMnemonicGroup = async (priority?: boolean, mnemonicGroupId?: number) => {
        try {
            await mnemonicGroupsService.updateMnemonicGroup(currentProject?.id, {
                analytic_priority: priority,
                saved_mnemonics_data: getPreparedMnemonicData(),
                well_log_file: currentWellLogFile?.id,
            }, mnemonicGroupId ? mnemonicGroupId : fetchedMnemonicGroups[0]?.id)
            showNotice('group-updated-successfully', t)
            fetchMnemonicGroup()
        } catch (e) {
            console.log(e)
        }
    }

    const deleteGroup = async (id: number, groupId: number) => {
        try {
            await mnemonicGroupsService.deleteMnemonicGroup(currentProject?.id, groupId)
            setFetchedMnemonicGroups(fetchedMnemonicGroups?.filter(item => item.id !== groupId))
            showNotice('group-deleted-successfully', t)
        } catch (e) {
            errorsHandler(e, t)
        }
    }
    //gis table
    const getGisRowsList = () => {
        if (fileData && fileData?.length > 0) {
            return (
                <>
                    <GisTableRow className={''}
                        title={t('description-of-file')}
                        middleData={[t('in-file'), t('in-inlog')]}
                        endData={[t('in-file'), t('ml'), 'Inlog', t('coef')]}
                        onClick={onClickSelectReadyFields}description-of-file
                        isCheckedRadioButton={isCheckedTopRadioButton}
                    />
                    <div>
                        {
                            fileData.map((item: ITableRow, i) =>
                                <GisTableRow
                                    title={item.item[0]}
                                    middleData={[item.item[1], item.item[2]]}
                                    endData={[item.item[3], item.item[4], item.item[5], item.item[6]]}
                                    isCheckedRadioButton={item.isChecked}
                                    disabled={item.disabled}
                                    onClick={onClickSelectRow}
                                    id={item?.id}
                                    key={i}
                                />
                            )
                        }
                    </div>
                </>
            )
        } else return []
    }

    const onClickSelectReadyFields = (_title: string, isChecked: boolean) => {
        const changedData = fileData.map(item => {
            if (!isChecked) {
                return { ...item, isChecked: false }
            } else {
                return { ...item, isChecked: !item.disabled }
            }
        })
        setFileData(changedData)
        setIsCheckedTopRadioButton(!isCheckedTopRadioButton)
    }

    const onClickSelectRow = (id: string, status: boolean) => {
        const changedData = fileData?.map((item: ITableRow) => item?.id === id ? {
            ...item,
            isChecked: status
        } : item)
        setFileData(changedData)
    }

    //mnemonic table
    const getTopMnemonicRow = useCallback(() => {
        return getTopFileList(t)
    }, [i18n.language])

    const findTargetFileName = (id: number) => {
        const targetFile = wellLogFiles?.find(item => item?.id === id)
        return targetFile ? targetFile?.filename.split('/').at(-1) : ''
    }

    const changeTableRowRadio = async (id: number) => {
        const targetGroup = fetchedMnemonicGroups?.find(item => item.id === id)
        try {
            await mnemonicGroupsService.updateMnemonicGroup(currentProject?.id, {
                analytic_priority: true,
                saved_mnemonics_data: targetGroup?.saved_mnemonics,
                well_log_file: currentWellLogFile?.id,
            }, id ? id : fetchedMnemonicGroups[0]?.id)
            const changedData = fetchedMnemonicGroups.map(item => ({ ...item, analytic_priority: item.id === id }))
            setFetchedMnemonicGroups(changedData)
            showNotice('group-updated-successfully', t)
        } catch (e) {
            console.log(e)
        }
    }

    const showValidFio = (item: IMnemonicGroup) => {
        const userData = item.user
        if (userData?.first_name || userData?.last_name) {
            return `${userData?.last_name} ${userData?.first_name}`
        } else if (!userData?.first_name && !userData?.last_name && userData?.email) {
            return userData?.email
        } else return ''
    }

    const getMnemonicGroupRowList = useCallback(() => {
        return fetchedMnemonicGroups ? fetchedMnemonicGroups?.map((item: IMnemonicGroup) => {
            const notationList = item?.saved_mnemonics?.map(val => val?.name)?.join(',')
            return (
                <div className={'gis-table-mnemonic-group--row-list'} key={item?.id}>
                    <div title={t('analytic-priority')}
                        className={'gis-table-mnemonic-group-priority-btn-wrapper'}>
                        <input type="radio"
                            className={'font-14-normal cursor-pointer gis-table-mnemonic-group-priority-btn'}
                            checked={item?.analytic_priority}
                            onClick={() => changeTableRowRadio(item.id)} onChange={() => {
                            }} />
                    </div>
                    <div className={'font-14-normal break-word '}>{findTargetFileName(item?.well_log_file)}</div>
                    <div className={'font-14-normal break-word '}>{notationList}</div>
                    <div className={'font-14-normal break-word '}>{getCorrectDateFormat({date:item?.changed_at, isShowTime:true})}</div>
                    <div className={'font-14-normal break-word'}>{showValidFio(item)}</div>
                    <button onClick={() => deleteGroup(item?.well_log_file, item?.id)}>
                        <SettingCancelIcon />
                    </button>
                    <div></div>
                </div>
            )
        }) : []
    }, [fetchedMnemonicGroups])

    const onClickUpdateRow = async () => {
        if (fetchedMnemonicGroups && fetchedMnemonicGroups?.length > 0) {
            const targetGroup = fetchedMnemonicGroups.find(item => item?.well_log_file === currentWellLogFile?.id)
            if (targetGroup) {
                await updateMnemonicGroup(targetGroup?.analytic_priority)
            } else {
                await addMnemonicGroup()
            }
        } else {
            await addMnemonicGroup()
        }
    }

    useEffect(() => {
        if (currentWellLogFile) {
            fetchMnemonicGroup()
        }
    }, [currentWellLogFile, assesmentStatus])


    useEffect(() => {
        currentWellLogFile && fetchCurrentWellLogFile()
    }, [currentWellLogFile, assesmentStatus, wellLogFiles])

    if (tableLoading) {
        return <div className={'font-error'}>{`...${t('processing')}`}</div>
    }

    if (fileData && fileData?.length > 0 && wellLogFiles.length > 0) {
        return (
            <div className={'gis-table'}>
                <div className={'border-gray mt-35 br-5 gis-table-block-gis-table'}>
                    <TableInLog topDataList={getTableTopData(t)} rowFileList={getGisRowsList()} />
                </div>
                <div className=' text-right mt-20'>
                    <button className={'gis-table-btn font-14-normal br-5'}
                        disabled={!fileData || fileData?.filter(item => item.isChecked)?.length === 0}
                        onClick={onClickUpdateRow}>{t('add-to-inlog-database').toString()}</button>
                </div>
                <div className={'gis-table-mnemonic-group mt-30'}>
                    {
                        getMnemonicGroupRowList() && getMnemonicGroupRowList()?.length > 0 &&
                        <TableInLog
                            topDataList={getTopMnemonicRow()}
                            rowFileList={getMnemonicGroupRowList()}
                            hintData={t('analytic-priority').toString()}
                        />
                    }
                </div>
            </div>
        );
    } else return <div className={'font-14-normal font-error'}>{t('gis-table-message').toString()}</div>

};

