import { createAction } from '@reduxjs/toolkit'

const setIsLoggedIn = createAction<{
    isLoggedIn: boolean
}>('authActions/setIsLoggedIn')

const setPasswordResetData = createAction<{
    passwordResetData: any
}>('authActions/setPasswordResetData')

export { setIsLoggedIn, setPasswordResetData }
