import { createSlice } from '@reduxjs/toolkit'
import { addColorForEachMember } from '../../utils/helpers/addColorForEachMember'
import { IMember } from '../../app/types/models/users'
import { setMembers, setStorageMembers } from './actions'

export interface InitialState {
    members: null | IMember[]
}

const initialState: InitialState = {
    members: null,
}

export const slice = createSlice({
    name: 'members',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setMembers, (state, action) => {
            const changedMembers = addColorForEachMember(action.payload.members)
            sessionStorage.setItem('members', JSON.stringify(changedMembers))
            state.members = changedMembers
        })
        builder.addCase(setStorageMembers, (state, action) => {
            state.members = action.payload.members
        })
    },
})
