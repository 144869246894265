import { TFunction } from "i18next";
import { SystemTagItem, SystemTagsTypes } from "../types";

export const getInitialSystemTags = (t:TFunction<"translation", undefined, "translation">): SystemTagItem[] => [
  {
      id: 1,
      type: 'folder',
      name:t('pad'),
      systemType: SystemTagsTypes.PAD,
  },
  {
      id: 2,
      type: 'folder',
      name:t('well'),
      systemType: SystemTagsTypes.WELL,
  },
  {
      id: 3,
      type: 'folder',
      name:t('wellbore'),
      systemType: SystemTagsTypes.WELLBORE,
  },
  {
      id: 4,
      type: 'folder',
      name:t('core'),
      systemType: SystemTagsTypes.CORE,
  },
  {
      id: 5,
      type: 'folder',
      name:t('method'),
      systemType: SystemTagsTypes.METHOD,
  },
  {
      id: 6,
      type: 'folder',
      name:t('equipment'),
      systemType: SystemTagsTypes.EQUIPMENT,
  },
  {
      id: 7,
      type: 'folder',
      name:t('studies'),
      systemType: SystemTagsTypes.STUDIES,
  },
]