import React, { FC, useCallback, useMemo, useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import SimpleBar from 'simplebar-react'
import { ITask } from '../../../../app/types/models/tasks'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { statusesServices } from '../../../../services/statuses-services'
import { tasksService } from '../../../../services/tasks-services'
import { CreatePanel } from '../../../../shared/CreatePanel/CreatePanel'
import { DotesMenu } from '../../../../shared/DotesMenu/DotesMenu'
import { DropdownMenuItem } from '../../../../shared/DropdownMenuItem/DropdownMenuItem'
import { EditableSpan } from '../../../../shared/EditableSpan/EditableSpan'
import { TaskDeleteModal } from '../../../../shared/taskDeleteModal/TaskDeleteModal'
import { panelsActions } from '../../../../store/panels'
import { editPanelName } from '../../../../store/panels/actions'
import { showNotice, showNotify } from '../../../../utils/helpers/showNotice'
import { PanelTask } from '../panelTask/PanelTask'
import './panel.css'
import { createPortal } from 'react-dom'
import ModalInlog from '../../../../shared/modalInlog/ModalInlog'
import { ButtonItem } from '../../../../app/types/i-button'

interface IPanel {
    id: number
    name: string
    position: string | number
    tasks: ITask[]
    tasksOrder: string[]
}

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'rgba(219, 218, 255, 0.5)' : 'transparent',
})

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? 'rgba(219, 218, 255, 0.5)' : 'transparent',
    ...draggableStyle,
})

const Panel = ({
    panel,
    tasks,
    index,
    setCurrentSlug,
    currentProjectId,
    panels,
    panelsOrder,
}) => {
    const dispatch = useAppDispatch()
    const [openModal, setOpenModal] = useState(false)
    const [statusPositionSymbol, setStatusPositionSymbol] = useState(false)
    const { t } = useTranslation()
    const {
        addTasksToPanel,
        deletePanel,
        updateTasksOrder,
        moveAllTasks,
        deletePanelTask,
    } = panelsActions

    const addTask = (value, priorityStatus) => {
        const newTask =
            panel?.name !== t('without-status')
                ? { name: value.trim(), priority: priorityStatus, status: panel.id }
                : { name: value.trim(), priority: priorityStatus }
        tasksService
            .createTask(newTask, currentProjectId)
            .then((res) => {
                const responseTask = {
                    ...res,
                    status_position: `${panel.tasksOrder.length + 1}-${res.id}`,
                }
                const tasksList = [...tasks, responseTask]
                const tasksOrder = [
                    ...panel.tasksOrder,
                    responseTask.status_position,
                ]
                dispatch(
                    addTasksToPanel({
                        position: panel.position,
                        tasks: tasksList,
                        tasksOrder,
                    }),
                )
                showNotify(t('task-added-successfully'))
            })
            .catch((err) =>
                showNotify(err?.message,{type:'error'})
            )
    }

    const editStatusName = (inputValue: string) => {
        const statusData = {
            name_en: inputValue,
            name_ru: inputValue,
            project: currentProjectId,
        }
        statusesServices
            .updateStatus(currentProjectId, panel.id, statusData)
            .then(() => {
                dispatch(
                    editPanelName({
                        position: panel.position,
                        name: inputValue,
                    }),
                )
                showNotify(t('name-changed-successfully'))
            })
            .catch((err) =>
                showNotify(err?.message,{type:'error'}),
            )
    }

    const deletePanelInRedux = () => {
        // @ts-ignore
        if (panelsOrder !== ['withoutStatus']) {
            const newPanelsOrder = panelsOrder.filter(
                (order) => order !== panel.position,
            )
            dispatch(
                deletePanel({
                    position: panel?.position,
                    panelsOrder: newPanelsOrder,
                }),
            )
        }
    }

    const deletePanelAsync = () => {
        statusesServices
            .deleteStatus(currentProjectId, panel.id)
            .then(() => {
                deletePanelInRedux()
                setOpenModal(false)
                showNotify(t('status-deleted-successfully'))
            })
            .catch((err) =>
                showNotify(err?.message,{type:'error'}),
            )
    }

    const closeModal = () => {
        setOpenModal(false)
    }

    const showModalToDeletePanel = () => {
        setOpenModal(true)
    }

    const getTasksByParamsHandler = useCallback(
        (params) => {
            tasksService
                .getTasks(currentProjectId, {
                    status: panel.id,
                    ordering: params,
                })
                .then((res) => {
                    const tasksOrder = res.results.map(
                        (task) => `${task.status_position}-${task.id}`,
                    )
                    const tasks = res.results.map((task) => ({
                        ...task,
                        status_position: `${task.status_position}-${task.id}`,
                    }))
                    dispatch(
                        updateTasksOrder({
                            position: panel.position,
                            tasksOrder,
                            tasks,
                        }),
                    )
                })
        },
        [currentProjectId],
    )

    const sortTasksHandler = (value: IPanel) => {
        if (value?.name === t('by-cretion-date')) {
            setStatusPositionSymbol(!statusPositionSymbol)
            getTasksByParamsHandler(
                `${statusPositionSymbol ? '' : '-'}status_position`,
            )
        }
        if (value?.name === t('by-low-to-critical-priority')) {
            getTasksByParamsHandler('priority')
        }
        if (value?.name === t('by-critical-to-low-priority')) {
            getTasksByParamsHandler('-priority')
        }
        if (value?.name === t('by-name')) {
            getTasksByParamsHandler('name')
        }
    }

    const tasksMultipleUpdateHandler = (value: IPanel) => {
        const task_ids = tasks.map((task) => task.id)
        tasksService
            .tasksMultipleUpdate(currentProjectId, {
                task_ids,
                status: value.id,
            })
            .then((res) => {
                const tasksOrder = res.data.map(
                    (task) => `${task.status_position}-${task.id}`,
                )
                const tasks = res.data.map((task) => ({
                    ...task,
                    status_position: `${task.status_position}-${task.id}`,
                }))
                dispatch(
                    moveAllTasks({
                        currentPosition: panel.position,
                        targetPosition: value.position,
                        tasksOrder,
                        tasks,
                    }),
                )
            })
            .catch((err) =>
                showNotify(err?.message,{type:'error'}),
            )
    }

    const preparePanelData = (panel: IPanel) => {
        return (
            <PanelItemContent
                panels={
                    panels &&
                    Object.values(panels)?.filter(
                        (item: any) =>
                            item?.name !== panel?.name &&
                            item?.name !== t('without-status'),
                    )
                }
                showDeleteModal={panel ? showModalToDeletePanel : () => {}}
                sortTasks={sortTasksHandler}
                tasksMultipleUpdate={tasksMultipleUpdateHandler}
            />
        )
    }

    const prepareNamesList = useMemo(() => {
        return panels
            ? [
                  ...Object.values(panels)?.map((panel: any) =>
                      panel?.name?.toLowerCase(),
                  ),
                  'without status',
                  'без статуса',
              ]
            : [t('without-status')]
    }, [panels])

    const modalButtonList: ButtonItem[] = [
        {
            id: 1,
            titleBtn: t('cancel'),
            handleBtn: closeModal,
        },
        {
            id: 2,
            type: 'primary',
            className: 'modal-button-primary-list',
            titleBtn: t('delete'),
            handleBtn: deletePanelAsync,
        },
    ]

    return (
        <div key={`${panel?.position}`}>
            <Draggable
                key={`${panel?.position}`}
                draggableId={`${panel?.position}`}
                index={index}
                isDragDisabled={panel?.position === 'withoutStatus'}
            >
                {(provided, snapshot) => (
                    <div
                        className="statuses__item"
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        style={
                            panel?.position !== 'withoutStatus'
                                ? getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                  )
                                : {}
                        }
                    >
                        <div
                            className="statuses__item-top"
                            {...provided.dragHandleProps}
                        >
                            {['Without status', 'Без статуса'].indexOf(
                                panel?.name,
                            ) === -1 ? (
                                <EditableSpan
                                    title={panel?.name}
                                    className="statuses__item-title title"
                                    handler={editStatusName}
                                    inputClassName="status__item-editInput"
                                    lengthLimit={15}
                                    namesList={prepareNamesList}
                                />
                            ) : (
                                <div className="statuses__item-title title withoutStatusTitle">
                                    {panel?.name}
                                </div>
                            )}
                            <div className="statuses__item-buttons">
                                <CreatePanel
                                    popoverPlacement={'bottomRight'}
                                    className="addTaskPanel"
                                    popoverClassName="addTaskPanel__popover"
                                    prioritySelect
                                    addTask={addTask}
                                    btnClassName={`status-${panel?.id}`}
                                    statusId={panel?.id}
                                    placeholder={t('input-task-name')}
                                />
                                {panel?.name !== t('without-status') && (
                                    <DotesMenu
                                        popoverClassName={
                                            'status__item-popover'
                                        }
                                        popoverPosition={'bottomRight'}
                                        statusItemContent={preparePanelData(
                                            panel,
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                        <Droppable
                            key={`${panel?.position}`}
                            type={'task'}
                            droppableId={`${panel?.position}`}
                        >
                            {(provided, snapshot) => (
                                <div
                                    className="statuses__taskList"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(
                                        snapshot.isDraggingOver,
                                    )}
                                >
                                    <div className="statuses__taskList-container">
                                        {tasks?.map((task, index) => (
                                            <PanelTask
                                                task={task}
                                                key={task?.status_position}
                                                taskIndex={index}
                                                setCurrentSlug={setCurrentSlug}
                                                panelPosition={panel?.position}
                                                currentProjectId={
                                                    currentProjectId
                                                }
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </div>
                )}
            </Draggable>
            {createPortal(
                <ModalInlog
                    width={400}
                    footer={null}
                    title={t('status-delition')}
                    open={openModal}
                    listButton={modalButtonList}
                    onCancel={closeModal}
                >
                    <div className="font-14-normal">
                        {t('ready-to-delete-status-questuion')}
                    </div>
                </ModalInlog>,
                document.body,
            )}
        </div>
    )
}

interface IPanelsItemContentProps {
    panels: any

    showDeleteModal: () => void
    sortTasks: (value: any) => void
    tasksMultipleUpdate: (value: any) => void
}

const PanelItemContent: FC<IPanelsItemContentProps> = ({
    panels,
    showDeleteModal,
    sortTasks,
    tasksMultipleUpdate,
}) => {
    const { t } = useTranslation()

    const sortVariants = [
        { name: t('by-cretion-date') },
        { name: t('by-low-to-critical-priority') },
        { name: t('by-critical-to-low-priority') },
        { name: t('by-name') },
    ]

    return (
        <div className="statuses__item-dropdownMenu">
            <DropdownMenuItem
                title={t('move-tasks')}
                dropdownItems={panels}
                simpleBarStyle={{
                    height: '60px',
                    width: '120px',
                    transform: 'translateX(40px)',
                }}
                dropdownItemClassName={'status__item-dropdownMenuItem'}
                onClick={tasksMultipleUpdate}
                containerClassName={'status__item-containerDropdownMenuItem'}
            />
            <DropdownMenuItem
                title={t('sort-by')}
                dropdownItems={sortVariants}
                simpleBarStyle={{
                    width: '155px',
                    transform: 'translateX(75px)',
                }}
                dropdownItemClassName={'status__item-dropdownMenuItem'}
                onClick={sortTasks}
                containerClassName={'status__item-containerDropdownMenuItem'}
            />
            <div
                className="statuses__item-deleteStatusItem"
                onClick={showDeleteModal}
            >
                {t('delete-status')}
            </div>
        </div>
    )
}

export { Panel }
