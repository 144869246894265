import React, {FC} from 'react'
import {IIconProps} from "../../app/types/i-props";

export const TaskCancelIcon:FC<IIconProps> = ({ className,onClick }) => {
    return (
        <svg
            className={className ? className : ''}
            width="10"
            height="9"
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <line
                x1="0.332182"
                y1="0.626295"
                x2="9.33218"
                y2="8.6263"
                stroke="#616161"
            />
            <line
                x1="0.332182"
                y1="0.626295"
                x2="9.33218"
                y2="8.6263"
                stroke="#616161"
            />
            <line
                x1="0.646447"
                y1="8.64645"
                x2="8.64645"
                y2="0.646446"
                stroke="#616161"
            />
            <line
                x1="0.646447"
                y1="8.64645"
                x2="8.64645"
                y2="0.646446"
                stroke="#616161"
            />
        </svg>
    )
}
