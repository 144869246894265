import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import { IPad } from '../../../../../../app/types/models/logging'
import { useAppDispatch } from '../../../../../../hooks/redux-hooks'
import { projectsService } from '../../../../../../services/projects-service.'
import { wellsService } from "../../../../../../services/wells-service"
import { EditableSpan } from '../../../../../../shared/EditableSpan/EditableSpan'
import { currentProjectActions, currentProjectSelectors, } from '../../../../../../store/currentProject'
import { setCurrentCore, setCurrentPads } from '../../../../../../store/currentProject/actions'
import { selectCurrentProject, selectLoadingCurrentPadsStatus } from '../../../../../../store/currentProject/selectors'
import { projectsActions } from '../../../../../../store/projects'
import { errorsHandler } from '../../../../../../utils/helpers/errors/errors-hendler'
import { showNotice } from '../../../../../../utils/helpers/showNotice'
import LoggingProductSceleton from '../logging-product-sceleton/LoggingProductSceleton'
import './pads.css'
interface IProps {
    openValue: boolean

    toOpenInput: (value: boolean) => void
    setOpenValue: (value: boolean) => void
}

const Pads: FC<IProps> = ({
    openValue,

    toOpenInput,
    setOpenValue,
}) => {
    const [padValue, setPadValue] = useState('')
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const currentProject = useSelector(selectCurrentProject)
    const pads = useSelector(currentProjectSelectors.selectCurrentPads)
    const currentPad = useSelector(currentProjectSelectors.selectCurrentPad)
    const { addWell, updatePad } = projectsActions
    const {selectCurrentPad} = currentProjectActions
    const { addCurrentWell, updateCurrentPad } =
        currentProjectActions

    const padsLoadingStatus = useSelector(selectLoadingCurrentPadsStatus)
        
    const onChangeAddElement = (e: ChangeEvent<HTMLInputElement>) => {
        setPadValue(e.target.value)
    }

    const handleAddEntities = async (e) => {
        try {
            if (e.keyCode === 13) {
                const padResponse = await projectsService.addPad(
                    currentProject.id,
                    {
                        name: padValue,
                    },
                ) as IPad

                const wellResponse = await wellsService.addWell(
                    currentProject.id,
                    {
                        name: 'well 1',
                        well_pad: padResponse.id,
                    },
                )
                dispatch(addCurrentWell({ well: wellResponse }))
                dispatch(
                    addWell({
                        id: currentProject.id,
                        padId: padResponse.id,
                        well: wellResponse,
                    }),
                )
                dispatch(setCurrentPads({pads:[{...padResponse,wells:[wellResponse]},...pads]}))
                setPadValue('')
                setOpenValue(false)
                showNotice(t('data-saved-successfully'),t)
            }
        } catch (e) {
            setPadValue('')
            errorsHandler(e, t)
        }
    }

    const handleUpdatePad = async (value: string) => {
        try {
            const data = await projectsService.updatePad(
                currentProject?.id,
                currentPad?.id,
                { name: value },
            )
            dispatch(
                updateCurrentPad({ id: currentProject?.id, name: data.name }),
            )
            dispatch(
                updatePad({
                    id: currentProject?.id,
                    padId: data.id,
                    name: data.name,
                }),
            )
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const onBlurHideInput = () => {
        toOpenInput(false)
    }
    const changeCurrentPad = (item:IPad) => {
        dispatch(selectCurrentPad(item))
        dispatch(setCurrentCore(null))
    }

    const getListClass = () =>{
        if(padsLoadingStatus){
            return 'projects__elements-list projects__elements-Padslist projects__elements-Padslist--loading'
        }
        return 'projects__elements-list projects__elements-Padslist'
    }

    useEffect(() => {
        if (pads && pads.length > 0) {
            dispatch(selectCurrentPad(pads[0]))
        }
    }, [pads])

    const showItems = () =>{
        if(padsLoadingStatus){
            return <LoggingProductSceleton /> 
        }else{
            return (
                pads &&
                pads.map((el) =>
                    currentProject &&
                    el?.project === currentProject?.id ? (
                        <li
                            key={el?.id}
                            className={
                                currentPad?.id &&
                                el.id === currentPad?.id
                                    ? 'projects__elements-Item activeElement'
                                    : 'projects__elements-Item'
                            }
                            onClick={() =>
                                changeCurrentPad(el)
                            }
                        >
                            <EditableSpan
                                title={el?.name==='pad 1' ? t('pad-1') : el?.name}
                                spanClassName={
                                    'projects__elements-editSpan'
                                }
                                inputClassName={
                                    'projects__elements-editInput'
                                }
                                handler={handleUpdatePad}
                            />
                        </li>
                    ) : null,
                )
            )
        }
    }

    return (
        <div
            className={'projects__elements-pads'}
            style={
                (pads && pads.length > 0) || openValue
                    ? { display: 'block' }
                    : { display: 'none' }
            }
        >
            <div className="projects__elements-simpleBarInner">
                <ul className={getListClass()}>
                    <SimpleBar className={'projects__elements-pads-sb'}>
                        <div className="projects__elements-listInner">
                            {openValue && (
                                <div
                                    className={'projects__elements__inputWrap'}
                                >
                                    <input
                                        type="text"
                                        autoFocus
                                        className="projects__elements-addInput padsAddInput"
                                        onChange={onChangeAddElement}
                                        onKeyDown={handleAddEntities}
                                        onBlur={onBlurHideInput}
                                    />
                                </div>
                            )}
                            {showItems()}
                        </div>
                    </SimpleBar>
                    <div>
                    </div>
                </ul>
            </div>
        </div>
    )
}


export { Pads }

