import { Spin } from 'antd'
import React from 'react'

export const LargeSpin = ({ style }: { style?: object }) => {
    return (
        <div style={style ? style : {}} className="projects__workSpace-spin">
            <Spin size={'large'} />
        </div>
    )
}
