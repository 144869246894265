import {CustomGroupParameters, ICustomGroupParameter} from "./types";

export const getInitialCustomGroupParameters =(t:Function)=> ([
    {
        id:'1',
        title:t('dropdown-list').toString(),
        className:'custom-group-parameters-list-item__orange',
        parameter:CustomGroupParameters.CUSTOM_DROPDOWN,

    },
    {
        id:'2',
        title:t('numeric-value').toString(),
        className:'custom-group-parameters-list-item__gray',
        parameter:CustomGroupParameters.NUMERIC_VALUE
    },
    {
        id:'3',
        title:t('pairs-of-points').toString(),
        className:'custom-group-parameters-list-item__green',
        parameter: CustomGroupParameters.SET_OF_PAIRS
    },
])