import React, { FC } from 'react'
import './RoadmapPriority.css'
import { PriorityTypes } from '../../../../app/types/enums'

interface IProps {
    priority: string
}

const RoadmapPriority: FC<IProps> = ({ priority }) => {
    return (
        <div
            className={`roadmap-item-priority roadmap-item-priority-${priority}`}
        />
    )
}

export default RoadmapPriority
