import { Button } from 'antd'
import { t } from 'i18next'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import * as XLSX from 'xlsx'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { chemicalStudyGroupService } from '../../services/chemical-study-group-service'
import { projectsService } from '../../services/projects-service.'
import PopoverInLog from '../../shared/popoverInlog/PopoverInLog'
import SpinInLog from '../../shared/spin/spin'
import {
    setCurrentCores,
    setLoadingCurrentItemStatus,
} from '../../store/currentProject/actions'
import {
    selectCurrentWell,
    selectCurrentWellBore,
} from '../../store/currentProject/selectors'
import { getCorrectDateFormat } from '../../utils/getCorrectDateFormat'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { getValidText } from '../../utils/helpers/getValidText'
import { showNotice } from '../../utils/helpers/showNotice'
import PyrolysisFileItem from './PyrolysisFileItem'
import './pyrolysis.css'
import { IPyrolysisFileItem, IPyrolysisProps } from './types'
const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

const Pyrolysis: FC<IPyrolysisProps> = (props) => {
    const { projectId, coreId, groupId, setGroup } = props
    const [responseDataList, setResponseDataList] = useState<
        IPyrolysisFileItem[]
    >([])
    const inputRef = useRef<HTMLInputElement>()
    const [loading, setLoading] = useState(false)
    const currentWell = useSelector(selectCurrentWell)
    const currentWellbore = useSelector(selectCurrentWellBore)
    const dispatch = useAppDispatch()

    // onchange event
    const handleFile = useCallback(
        async (e) => {
            let fileTypes = [
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                fileType,
                'text/csv',
            ]
            let selectedFile = e.target.files[0]

            if (selectedFile) {
                if (selectedFile && fileTypes.includes(selectedFile.type)) {
                    try {
                        const formData = new FormData()
                        formData.append('file', selectedFile)
                        if (currentWell) {
                            formData.append(
                                'well',
                                getValidText(`${currentWell?.id}`),
                            )
                        }
                        if (currentWellbore) {
                            formData.append(
                                'wellbore',
                                getValidText(`${currentWellbore?.id}`),
                            )
                        }
                        setLoading(true)
                        if (!groupId) {
                            await chemicalStudyGroupService.createChemicalGroup(
                                projectId,
                                coreId,
                                { core: coreId },
                            )
                        }
                        const response =
                            (await chemicalStudyGroupService.createPyrolysisFile(
                                projectId,
                                formData,
                            )) as any

                        let reader = new FileReader()
                        reader.readAsArrayBuffer(selectedFile)

                        reader.onload = (e) => {
                            const excelFile = e.target.result
                            if (excelFile !== null) {
                                const workbook = XLSX.read(excelFile, {
                                    type: 'binary',
                                })
                                const worksheetName = workbook.SheetNames[0]
                                const worksheet = workbook.Sheets[worksheetName]
                                const data = XLSX.utils.sheet_to_json(
                                    worksheet,
                                    {
                                        raw: false,
                                        defval: '',
                                    },
                                )
                                if (
                                    responseDataList &&
                                    responseDataList.length > 0
                                ) {
                                    setResponseDataList([
                                        ...responseDataList,
                                        {
                                            id: response?.id,
                                            name: response?.filename,
                                            data: data,
                                            uploaded_at: getCorrectDateFormat({
                                                date: response?.uploaded_at,
                                                isShowTime: {
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                },
                                            }),
                                        },
                                    ])
                                } else {
                                    setResponseDataList([
                                        {
                                            id: response?.id,
                                            name: response?.filename,
                                            data: data,
                                            uploaded_at: getCorrectDateFormat({
                                                date: response?.uploaded_at,
                                                isShowTime: {
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                },
                                            }),
                                        },
                                    ])
                                }
                                resetFile()
                            }
                        }
                        resetFile()
                        setLoading(false)
                        dispatch(
                            setLoadingCurrentItemStatus({
                                status: true,
                                type: 'currentCores',
                            }),
                        )
                        const cores = await projectsService.getCores(projectId)
                        dispatch(setCurrentCores({ cores }))
                        dispatch(
                            setLoadingCurrentItemStatus({
                                status: false,
                                type: 'currentCores',
                            }),
                        )
                    } catch (e) {
                        resetFile()
                        setLoading(false)
                        dispatch(
                            setLoadingCurrentItemStatus({
                                status: false,
                                type: 'currentCores',
                            }),
                        )
                        errorsHandler(e, t)
                    }
                } else {
                    toast.error(
                        t('select-files-with-correct-format-value', {
                            value: 'xlsx',
                        }),
                        { position: 'top-center' },
                    )
                    resetFile()
                }
            } else {
                console.log('Please select your file')
            }
        },
        [groupId, responseDataList, setResponseDataList],
    )

    const btnHandler = () => {
        if (inputRef && inputRef?.current) {
            inputRef?.current?.click()
        }
    }

    const resetFile = () => {
        const emptyFile = document.createElement('input')
        emptyFile.type = 'file'
        if (inputRef) {
            inputRef.current.files = emptyFile?.files
        }
    }

    const deleteFile = useCallback(
        async (id: number) => {
            try {
                await chemicalStudyGroupService.deletePyrolysisfile(
                    projectId,
                    id,
                )
                setResponseDataList((prev) => prev.filter((el) => el.id !== id))
                showNotice('file-deleted-successfully', t)
            } catch (e) {
                errorsHandler(e, t)
            }
        },
        [setResponseDataList],
    )

    const fetchGroup = async () => {
        try {
            const response = await chemicalStudyGroupService.getChemicalGroup(
                projectId,
                coreId,
            )
            setGroup(Number(response.id))
            fetchPyrolysisData()
        } catch (e) {
            setGroup(null)
            setResponseDataList([])
        }
    }

    const fetchPyrolysisData = async () => {
        try {
            setLoading(true)
            let params
            if (currentWell) {
                params = {
                    well: currentWell?.id,
                }
            }
            if (currentWellbore) {
                params = {
                    wellbore: currentWellbore?.id,
                }
            }
            const data = await chemicalStudyGroupService.getPyrolysisFiles(
                projectId,
                params,
            )
            if (data && Array.isArray(data)) {
                setResponseDataList(
                    data.map((el) => ({
                        id: el?.id,
                        data: el.file,
                        name: el.filename,
                        uploaded_at: getCorrectDateFormat({
                            date: el?.uploaded_at,
                            isShowTime: {
                                hour: 'numeric',
                                minute: 'numeric',
                            },
                        }),
                    })),
                )
            } else {
                setResponseDataList([])
            }
            setLoading(false)
        } catch (e) {
            setResponseDataList([])
            setLoading(false)
        }
    }

    const updateFile = useCallback(async (data: IPyrolysisFileItem) => {
        setLoading(true)
        const worksheet = XLSX.utils.json_to_sheet(data.data as any)
        const wb = XLSX.utils.book_new() as any
        XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
        const excelBuffer = XLSX.write(wb, { bookType: 'xls', type: 'array' })
        const dataBlob = new Blob([excelBuffer], { type: fileType })

        // FileSaver.saveAs(dataBlob, 'test.xls');
        const file = new File([dataBlob], data.name, {
            type: 'application/vnd.ms-exel',
        })
        const formData = new FormData()
        formData.append('file', file)

        chemicalStudyGroupService
            .updatePyrolysisFile(projectId, data.id, formData)
            .then(() => {
                toast.success(t('data-saved-successfully'), {
                    position: 'top-center',
                })
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                errorsHandler(e, t)
            })
    }, [])

    const downloadTemplate = () => {
        const link = document.createElement('a')
        link.href = '/files/Пиролиз шаблон.xlsx'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    useEffect(() => {
        fetchGroup()
    }, [projectId, coreId])

    return (
        <div className="coreStudySetting-pyrolysisSetting">
            {createPortal(<SpinInLog isLoading={loading} />, document.body)}
            <div className={'coreStudySetting-pyrolysisSetting-top'}>
                <input
                    type="file"
                    className="upload-exel-input"
                    onChange={async (e) => {
                        await handleFile(e)
                    }}
                    ref={inputRef}
                    accept={'.xlsx, .xls'}
                />
                <PopoverInLog
                    content={
                        <div
                            className={
                                'font-12-normal font-white table-popover'
                            }
                        >
                            {t('required-file-format-xlsx')}
                        </div>
                    }
                >
                    <Button
                        type={'primary'}
                        onClick={btnHandler}
                        className="upload-exel-btn"
                    >
                        {t('upload')}
                    </Button>
                </PopoverInLog>
                <Button
                    type={'primary'}
                    onClick={downloadTemplate}
                    className="upload-exel-btn"
                >
                    {t('download-template')}
                </Button>
            </div>
            <div className={'coreStudySetting-pyrolysisSetting-files'}>
                {responseDataList &&
                    responseDataList.length > 0 &&
                    responseDataList.map((item, i) => (
                        <PyrolysisFileItem
                            key={item.id}
                            item={item}
                            deleteFile={deleteFile}
                            isLoading={loading}
                            updateFile={updateFile}
                        />
                    ))}
            </div>
        </div>
    )
}

export default Pyrolysis
