import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import ModalInlog from '../../../../shared/modalInlog/ModalInlog'
import { useTranslation } from 'react-i18next'
import { ButtonItem } from '../../../../app/types/i-button'
import UiInput from '../../../../shared/ui/input/UiInput'
import { useLocation } from 'react-router'
import { statusesServices } from '../../../../services/statuses-services'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { addPanel } from '../../../../store/panels/actions'
import { showNotify } from '../../../../utils/helpers/showNotice'
import { useSelector } from 'react-redux'
import { panelsSelectors } from '../../../../store/panels'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'

const StatusAdditing = ({
    trigger,
    setIsLoading,
    setStrigger,
}: {
    trigger: number
    setIsLoading: (value: boolean) => void
    setStrigger: (value: number) => void
}) => {
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const [value, setValue] = useState('')
    const [isOpenModal, setIsOpenModal] = useState(false)
    const projectId = Number(location.pathname.split('/')[2])
    const panels = useSelector(panelsSelectors.selectPanelsData)

    const createPanel = async () => {
        try {
            const statusData = {
                projectId: projectId,
                name_en: value?.trim(),
                name_ru: value?.trim(),
            }
            setIsLoading(true)
            const res = await statusesServices.createStatus(
                projectId,
                statusData,
            )
            if (res) {
                dispatch(
                    addPanel({
                        panel: {
                            ...res,
                            tasks: [],
                            tasksOrder: [],
                            name: res.name_en,
                        },
                        position: res.position,
                        panelsOrder: [...panels.panelsOrder, res.position],
                    }),
                )
                showNotify(t('status-added-successfully'))
                setIsOpenModal(false)
                setStrigger(0)
            }
        } catch (error) {
            errorsHandler(error, t)
            showNotify(error.message, { type: 'error' })
        } finally {
            setIsLoading(false)
        }
    }

    const modalButtonList: ButtonItem[] = [
        {
            id: 1,
            titleBtn: t('cancel'),
            handleBtn: () => setIsOpenModal(false),
        },
        {
            id: 2,
            type: 'primary',
            className: 'modal-button-primary-list',
            titleBtn: t('create'),
            isDisabled: value.length === 0,
            handleBtn: createPanel,
        },
    ]

    useEffect(() => {
        if (trigger! > 0) {
            setIsOpenModal(true)
        }
    }, [trigger])

    return (
        <>
            {createPortal(
                <ModalInlog
                    width={400}
                    footer={null}
                    title={t('add-new-status')}
                    open={isOpenModal}
                    listButton={modalButtonList}
                    onCancel={() => setIsOpenModal(false)}
                >
                    <div className="statuses-modal-inner">
                        <UiInput
                            label={t('name')}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </div>
                </ModalInlog>,
                document.body,
            )}
        </>
    )
}

export default StatusAdditing
