import React from 'react'
import './activity.css'
const Activity = () => {
    return (
        <div className="activity">
            <div className="activity__container">
                <div className="activity__entitiesSpace"></div>
                <div className="activity__workSpace"></div>
            </div>
        </div>
    )
}

export { Activity }
