import React, { FC, SetStateAction, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { IPopoverMenuItem } from '../../../../app/types/common'
import { ButtonItem } from '../../../../app/types/i-button'
import { ITask } from '../../../../app/types/models/tasks'
import { CopyDataIcon } from '../../../../image_files/icons/CopyDataIcon'
import { DeleteIcon } from '../../../../image_files/icons/DeleteIcon'
import { EditIcon } from '../../../../image_files/icons/EditIcon'
import { OpenInNewTabIcon } from '../../../../image_files/icons/OpenInNewTabIcon'
import { tasksService } from '../../../../services/tasks-services'
import ModalInlog from '../../../../shared/modalInlog/ModalInlog'
import PopoverMenu from '../../../../shared/popoverMenu/PopoverMenu'
import SpinInLog from '../../../../shared/spin/spin'
import { deletePanelTask } from '../../../../store/panels/actions'
import { findColorByParam } from '../../../../utils/findColorByParam'
import { goToNewTab } from '../../../../utils/go-to-new-tab'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { showNotify } from '../../../../utils/helpers/showNotice'
import './panelTask.css'

const getTaskStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? 'lightgreen' : '#fff',
    ...draggableStyle,
})

interface IProps {
    task: ITask
    taskIndex: number
    currentProjectId: number
    panelPosition: number

    setCurrentSlug: (data: SetStateAction<string>) => void
}

const PanelTask: FC<IProps> = ({
    task,
    taskIndex,
    panelPosition,
    setCurrentSlug,
    currentProjectId,
}) => {
    const targetUrl = `../${currentProjectId}/tasks/${task?.slug}`
    const [openModal, setOpenModal] = useState(false)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const copyUrl = window.location.href
    const [closeMenuTrigger, setCloseMenuTrigger] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const handleDeleteTask = () => {
        setCloseMenuTrigger((prev) => prev + 1)
        setOpenModal(true)
    }

    const deleteTask = async () => {
        try {
            setIsLoading(true)
            await tasksService.deleteTask(currentProjectId, task?.slug)
            dispatch(
                deletePanelTask({
                    position: panelPosition,
                    taskId: task?.id,
                    status_position: task?.status_position,
                }),
            )

            showNotify(t('task-deleted-successfully'))
        } catch (error) {
            errorsHandler(error, t)
        } finally {
            setOpenModal(false)
            setIsLoading(false)
        }
    }

    const menuItems: IPopoverMenuItem[] = [
        {
            id: 1,
            title: t('open-in-new-tab'),
            icon: <OpenInNewTabIcon />,
            onClick: () => {
                goToNewTab(targetUrl)
            },
        },
        {
            id: 2,
            title: t('copy-link'),
            icon: <CopyDataIcon />,
            onClick: () => {
                navigator.clipboard
                    .writeText(`${copyUrl}/${task.slug}`)
                    .then(() => {
                        showNotify(t('link-copied'))
                        setCloseMenuTrigger((prev) => prev + 1)
                    })
            },
        },
        {
            id: 3,
            title: t('edit'),
            icon: <EditIcon />,
            onClick: () => {},
        },
        {
            id: 4,
            title: t('delete'),
            icon: <DeleteIcon />,
            onClick: handleDeleteTask,
        },
    ]

    const modalBtnList: ButtonItem[] = [
        {
            id: 1,
            titleBtn: t('cancel'),
            className: 'font-14-normal-imp',
            handleBtn: () => setOpenModal(false),
        },
        {
            id: 2,
            type: 'primary',
            titleBtn: t('delete'),
            handleBtn: deleteTask,
        },
    ]

    return (
        <div>
            {createPortal(<SpinInLog isLoading={isLoading} />, document.body)}
            <Draggable draggableId={task?.status_position.toString()} index={taskIndex}>
                {(provided, snapshot) => (
                    <div
                        className="statuses__task"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onMouseDown={() => setCurrentSlug(task?.slug)}
                        ref={provided.innerRef}
                        style={getTaskStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                        )}
                    >
                        <div className="statuses__task-top">
                            <div className="statuses__task-column">
                                <div
                                    className="priorityStatus"
                                    style={findColorByParam(task?.priority)}
                                ></div>
                                <div className="statuses__task-slag title">
                                    {task?.slug}
                                </div>
                            </div>
                            <PopoverMenu
                                items={menuItems}
                                closeTrigger={closeMenuTrigger}
                            />
                        </div>
                        <div className="statuses__task-bottom">
                            {task?.name}
                        </div>
                    </div>
                )}
            </Draggable>
            {createPortal(
                <ModalInlog
                    width={400}
                    open={openModal}
                    title={t('task-deleting')}
                    iconTitle={<DeleteIcon width={18} height={18} />}
                    listButton={modalBtnList}
                    onCancel={() => setOpenModal(false)}
                >
                    <div className="font-14-normal roadmap-item-modal-inner">
                        {t('you-realy-want-do-delete-this-task')}
                    </div>
                </ModalInlog>,
                document.body,
            )}
        </div>
    )
}

export { PanelTask }
