import {CustomGroupParameters, ICustomGroupParameter} from "./types";
import {ISelectFieldData} from "../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/types";

export const defaultElement:ICustomGroupParameter = {
    id: '1',
    type: CustomGroupParameters.CUSTOM_DROPDOWN,
    data: {},
    isValidField: false
}

export const defaultNameData:ISelectFieldData = {
    en:'',
    ru:''
}

export const defaultParameters: any = [{
    "id": "33cf3dac-e9da-4221-b76e-5885c46fba97",
    "name": {"en": "Группа параметров 1", "ru": "Parameter group 1"},
    "parameters": [{
        "type": "DROPDOWN",
        "id": "74c618aa-2109-4394-bf81-a496c08e8fe3",
        "data": {
            "title": {"en": "123", "ru": "123"},
            "tags": {"en": [{"label": "Field 1", "value": "Field 1"}], "ru": [{"label": "Поле 1", "value": "Поле 1"}]}
        },
        "isValidField": true
    }],
    "isValidField": true
}]