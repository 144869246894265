import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { INotification } from '../../app/types/models/i-notification'
import NoticeBlock from '../../entities/noticeBlock/NoticeBlock'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { EditLogoIcon } from '../../image_files/icons/EditLogoIcon'
import { LogoIcon } from '../../image_files/icons/LogoIcon'
import { SettingsIcon } from '../../image_files/icons/SettingsIcon'
import { TasksIcon } from '../../image_files/icons/TasksIcon'
import { notificationsService } from '../../services/notifications-service'
import { setNotifications } from '../../store/notifications/actions'
import { selectProjects } from '../../store/projects/selectors'
import { ACCESS_TOKEN, WSS_BASE_URL_API, routes } from '../../utils/constants'
import { findCurrentPathFromString } from '../../utils/findCurrentPathFromString'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import './navigation.css'

const Navigation = () => {
    const projects = useSelector(selectProjects)
    const [wsNotifications, setWsNotifications] = useState<INotification[]>([])
    const [fetchedNotifications, setFetchedNotifications] = useState<
        INotification[]
    >([])
    const ws = useRef(null)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const fetchNotificationsWs = async () => {
        try {
            ws.current = await new WebSocket(
                `wss://${WSS_BASE_URL_API}notifications/?token=${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            )
            gettingData()
        } catch (e) {
            console.log(e)
            errorsHandler(e, t)
        }
    }

    const gettingData = () => {
        if (!ws.current) return
        ws.current.onmessage = (e) => {
            const message = JSON.parse(e.data) as INotification
            if (message && !message.is_deleted && !message.deleted) {
                setWsNotifications([...wsNotifications, message])
            }
        }
    }

    const fetchNotifications = async () => {
        try {
            const response = await notificationsService.getNotifications()
            setFetchedNotifications(response.filter((el) => !el.is_deleted))
        } catch (e) {
            console.log(e)
            errorsHandler(e, t)
        }
    }

    useEffect(() => {
        fetchNotificationsWs()
    }, [projects])

    useEffect(() => {
        fetchNotifications()
    }, [projects])

    useEffect(() => {
        let list = [...fetchedNotifications]
        if (wsNotifications.length > 0) {
            wsNotifications.forEach((val) => {
                if (list.find((el) => el?.id === val?.id)) {
                } else {
                    list.push(val)
                }
            })
        }
        dispatch(setNotifications({ notifications: list }))
    }, [fetchedNotifications, wsNotifications])

    return (
        <div className="navigation">
            <div className="navigation-top">
                <div className="icon1_box">
                    <LogoIcon
                        className={'cursor-pointer'}
                        onClick={() => navigate(routes.GRAPHICS)}
                    />
                </div>
                <NavLink
                    to={projects ? routes.LOGGING : routes.FIRST_PROJECT}
                    className="icon2_box"
                >
                    <EditLogoIcon
                        isCurrentPage={
                            findCurrentPathFromString(
                                location.pathname,
                                'logging',
                            ) ||
                            findCurrentPathFromString(
                                location.pathname,
                                'first-project',
                            )
                        }
                    />
                </NavLink>
                {projects.length > 0 ? (
                    <NavLink to={routes.SCHEDULER} className="icon3_box">
                        <TasksIcon
                            isCurrentPage={findCurrentPathFromString(
                                location.pathname,
                                'scheduler',
                            )}
                        />
                    </NavLink>
                ) : (
                    <div className="icon3_box" onClick={()=>{
                        toast.info(t('you-sure-to-delete-photo'),{position:'top-center'})
                    }}>
                        <TasksIcon
                            isCurrentPage={findCurrentPathFromString(
                                location.pathname,
                                'scheduler',
                            )}
                        />
                    </div>
                )}

                <NavLink to={routes.SETTINGS} className="icon4_box">
                    <SettingsIcon
                        isCurrentPage={findCurrentPathFromString(
                            location.pathname,
                            'settings',
                        )}
                    />
                </NavLink>
            </div>
            <NoticeBlock
                wsNotifications={wsNotifications}
                refetchNotifications={fetchNotifications}
            />
        </div>
    )
}

export default Navigation
