import { toast } from 'react-toastify'

type ToastPositionTypes = "top-center" | "top-right" | "top-left" | "bottom-right" | "bottom-center" | "bottom-left"

export const showNotice = (message: string, translateFunc: Function,params?:object,type:'success'|'info'='success',translateParams?:object) => {
    const parameters = params ? params : {}
    const translateParameters = translateParams ? translateParams : {}
    toast[type](`${translateFunc(message,translateParameters)}`, {...parameters,position:'top-center'})
}

export const showNotify = (message:string,data?:{position?:ToastPositionTypes,type?:string})=>{
    const type = data?.type || 'success'
    toast[type](`${message}`, {position:data?.position || 'top-center'})
}
