import React from 'react'

export const EditLogoIcon = ({ isCurrentPage }) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            style={{ cursor: 'pointer' }}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="40"
                height="40"
                rx="10"
                style={{ fill: isCurrentPage ? '#E9EFF3' : '#364F6B' }}
            />
            <path
                d="M10 11H13H17.5H25M10 26H13M10 16H21M10 21H17"
                style={{ stroke: isCurrentPage ? '#364F6B' : '#94D7DB' }}
                strokeWidth="2"
            />
            <path
                d="M33 24V29.9289C33 31.255 32.4732 32.5268 31.5355 33.4645L31.4645 33.5355C30.5268 34.4732 29.255 35 27.9289 35L11.0711 35C9.74499 35 8.47322 34.4732 7.53553 33.5355L7.46447 33.4645C6.52679 32.5268 6 31.255 6 29.9289L6 10.0711C6 8.74498 6.52678 7.47322 7.46447 6.53553L7.53553 6.46447C8.47321 5.52679 9.74499 5 11.0711 5H14.5L27.9289 5C29.255 5 30.5268 5.52678 31.4645 6.46447L31.5858 6.58579C32.4913 7.49129 33 8.71942 33 10V10"
                style={{ stroke: isCurrentPage ? '#364F6B' : '#94D7DB' }}
                strokeWidth="2"
            />
            <path
                d="M21.4112 28.8434L30.0366 17.0001"
                style={{ stroke: isCurrentPage ? '#364F6B' : '#94D7DB' }}
                strokeWidth="6"
            />
            <path
                d="M18.3555 28L23 31.3822L20.5 32.1322L18 32.8822L18.3555 28Z"
                style={{ stroke: isCurrentPage ? '#364F6B' : '#94D7DB' }}
            />
            <path
                d="M29.6755 12.4597C29.9982 12.0115 30.6231 11.9098 31.0713 12.2325L34.2088 14.4913C34.657 14.814 34.7588 15.4389 34.4361 15.8871L33.5119 17.1708C33.1892 17.619 32.5643 17.7207 32.1161 17.3981L28.9786 15.1392C28.5304 14.8165 28.4286 14.1916 28.7513 13.7434L29.6755 12.4597Z"
                style={{ stroke: isCurrentPage ? '#364F6B' : '#94D7DB' }}
            />
        </svg>
    )
}
