import React, {FC} from 'react'
import {IIconProps} from "../../app/types/i-props";

const UploadIcon: FC<IIconProps> = ({onClick, className}) => {
    return (
        <svg onClick={onClick ? onClick : () => undefined} className={`cursor-pointer ${className}`} width="25"
             height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="25" height="25" fill="#E9EFF3"/>
            <rect width="25" height="25" fill="#E9EFF3"/>
            <path d="M5.42857 15V15C5.19804 17.6895 7.31896 20 10.0183 20H15C17.7614 20 20 17.7614 20 15V15"
                  stroke="#364F6B" strokeWidth="1.2" strokeLinecap="round"/>
            <path
                d="M11.9 16.5C11.9 16.8314 12.1686 17.1 12.5 17.1C12.8314 17.1 13.1 16.8314 13.1 16.5H11.9ZM12.9243 5.07574C12.6899 4.84142 12.3101 4.84142 12.0757 5.07574L8.25736 8.89411C8.02304 9.12843 8.02304 9.50833 8.25736 9.74264C8.49167 9.97696 8.87157 9.97696 9.10589 9.74264L12.5 6.34853L15.8941 9.74264C16.1284 9.97696 16.5083 9.97696 16.7426 9.74264C16.977 9.50833 16.977 9.12843 16.7426 8.89411L12.9243 5.07574ZM13.1 16.5L13.1 5.5H11.9L11.9 16.5H13.1Z"
                fill="#364F6B"/>
        </svg>
    )
}

export {UploadIcon}
