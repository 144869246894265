import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { authService } from '../../services/auth-service'
import { routes } from '../../utils/constants'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'

function EmailConfirmation() {
    const [url] = useState(window.location.href)
    const navigate = useNavigate()
    const uid = url.slice(url.indexOf('=') + 1, url.indexOf('&'))
    const key = url.slice(url.lastIndexOf('=') + 1, url.length)
    const {t} = useTranslation()

    const verifyEmailAsync = useCallback(async () => {
        try {
            const response = await authService.emailVerify({ key, uid })
            if (response.detail) {
                toast.success(response.detail, { position: 'top-center' })
                navigate(routes.LOGIN)
            }
        } catch (e) {
            errorsHandler(e,t)
        }
    }, [uid, key])

    useEffect(() => {
        verifyEmailAsync()
    }, [])

    return <div className="EmailConfirmation"></div>
}

export default EmailConfirmation
