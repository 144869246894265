export const getKindData = (t:Function)=>{
    return [
        {
            name: t('stratigraphic'),
            value: 'stratigraphic',
        },
        {
            name: t('parametric'),
            value: 'parametric',
        },
        {
            name: t('exploration'),
            value: 'exploration',
        },
        {
            name: t('mining'),
            value: 'production',
        },
        {
            name: t('injection'),
            value: 'injection',
        },
        {
            name: t('observation'),
            value: 'observation',
        },
    ]
}