import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ICoreBoxPhotoResponse } from '../../../../../../app/types/dto/core-box-data'
import { PlusIcon } from '../../../../../../image_files/icons/PlusIcon'
import SettingCancelIcon from '../../../../../../image_files/icons/SettingCancelIcon'
import { coreBoxService } from '../../../../../../services/core-box-service'
import { selectCurrentProject } from '../../../../../../store/currentProject/selectors'
import { errorsHandler } from '../../../../../../utils/helpers/errors/errors-hendler'
import { getValidText } from '../../../../../../utils/helpers/getValidText'
import { initialCorePhotoInterval } from '../../CoreBoxes'
import {
    ICoreBoxData,
    ICoreBoxesPhotoProps,
    ISelectInterval,
} from '../../types'
import AddCoreBoxPhotoModal from '../addCoreBoxPhotoModal/AddCoreBoxPhotoModal'
import CoreBoxEditSpace from '../coreBoxEditSpace/CoreBoxEditSpace'
import './CoreBoxesPhotos.css'
import { srcToFile } from '../../../../../../utils/helpers/srcToFile'
import { createPortal } from 'react-dom'
import SpinInLog from '../../../../../../shared/spin/spin'
import { Skeleton } from 'antd'
import { showNotice } from '../../../../../../utils/helpers/showNotice'

const CoreBoxesPhotos: FC<ICoreBoxesPhotoProps> = ({
    intervals,
    currentBox,
    isLoadingIntervals,

    setIntervals,
    setCurrentBox,
}) => {
    const { t } = useTranslation()
    const [isOpenModal, setIsOpenModal] = useState(false)
    const currentProject = useSelector(selectCurrentProject)
    const [resetFileTrigger, setResetFileTrigger] = useState(0)
    const [currentInterval, setCurrentInterval] = useState<ISelectInterval>(
        initialCorePhotoInterval,
    )
    const [loading, setLoading] = useState({
        addBox: false,
        changeBox: false,
        getBox: false,
    })

    const openModal = () => {
        const photoBoxes = currentInterval?.photoBoxes || []
        if (intervals?.length > 0) {
            if (photoBoxes.length <= 5) {
                setIsOpenModal(true)
            } else {
                toast.info(t('you-can-add-till-photo-count-core-boxes',{value:5}), {
                    position: 'top-center',
                })
            }
        } else {
            toast.info(t('first-add-interval-message'), {
                position: 'top-center',
            })
        }
    }
    const closeModal = () => {
        setResetFileTrigger(resetFileTrigger + 1)
        setIsOpenModal(false)
    }

    const showActiveBoxItemClass = (el: any) => {
        if (currentBox?.id && currentBox?.id === el?.id)
            return 'core-boxes-photos-active-item'
        return ''
    }

    const updateIntervalsByResponse = (
        res: ICoreBoxPhotoResponse,
        type: 'add' | 'change' | 'delete',
    ) => {
        const updateBox = (boxes: ICoreBoxPhotoResponse[] | undefined) => {
            boxes = boxes && boxes.length > 0 ? boxes : []
            switch (type) {
                case 'change':
                    return boxes.map((box) => {
                        if (box.id === res?.id) {
                            return res
                        } else return box
                    })
                case 'delete':
                    return boxes.filter((box) => box?.id !== res.id)
                case 'add':
                    return [...boxes, res]
                default:
                    return boxes
            }
        }

        setIntervals(
            intervals.map((el) => {
                if (el.id === res.box_interval) {
                    const targetEl = {
                        ...el,
                        photoBoxes: updateBox(el?.photoBoxes),
                    }
                    return targetEl
                } else return el
            }),
        )
    }

    const getPhotoBox = async (photoId: number) => {
        try {
            setLoading({ ...loading, getBox: true })
            const res = await coreBoxService.getPhotoById(
                currentProject?.id,
                photoId,
            )
            updateIntervalsByResponse(res, 'change')
            setCurrentBox(res)
            setLoading({ ...loading, getBox: false })
        } catch (e) {
            setLoading({ ...loading, getBox: false })
            errorsHandler(e, t)
        }
    }

    const addBox = async (data: ICoreBoxData) => {
        try {
            setLoading({ ...loading, addBox: true })
            let targetInterval = {} as any
            const formData = new FormData()
            formData.append('box_interval', `${currentInterval.id}`)
            formData.append('light', data.lighting)
            formData.append('column_length', data.coreLength)
            formData.append('direction', data.direction)
            formData.append('loading_process', data.loading)
            formData.append('photo', data.file?.originFileObj)
            formData.append('comment', data.comment)

            const response = await coreBoxService.addPhoto(
                currentProject?.id,
                formData,
            )
            if (response) {
                updateIntervalsByResponse(response, 'add')
                if (
                    targetInterval?.photoBoxes &&
                    targetInterval?.photoBoxes?.length === 1
                ) {
                    setCurrentBox(targetInterval?.photoBoxes[0])
                }
                setResetFileTrigger(resetFileTrigger + 1)
                showNotice('core-photo-added-sucessfully',t)
            }
            setLoading({ ...loading, addBox: false })
            closeModal()
        } catch (e) {
            setLoading({ ...loading, addBox: false })
            errorsHandler(e, t)
        }
    }

    const deleteBox = (intervalId: string | number, boxId: string | number) => {
        try {
            if (typeof boxId === 'number') {
                coreBoxService.deletePhoto(currentProject?.id, Number(boxId))
                updateIntervalsByResponse({ id: boxId }, 'delete')
                if (
                    currentBox.id === boxId ||
                    intervalId === currentBox?.box_interval
                ) {
                    setCurrentBox({} as ICoreBoxPhotoResponse)
                }
            }
        } catch (e) {
            console.log(e)
            errorsHandler(e, t)
        }
    }

    const changeBox = async (data: ICoreBoxPhotoResponse) => {
        try {
            setLoading({ ...loading, changeBox: true })
            const formData = new FormData()
            formData.append('box_interval', `${data?.box_interval}`)
            formData.append('light', data.light)
            formData.append('column_length', data.column_length)
            formData.append('direction', data.direction)
            formData.append('loading_process', data.loading_process)
            formData.append('comment', data.comment)
            srcToFile(
                getValidText(data?.photo?.medium),
                'test.png',
                'text/plain',
            )
                .then(async (res) => {
                    formData.append('photo', res)
                    const response = await coreBoxService.updatePhoto(
                        currentProject?.id,
                        Number(data.id),
                        formData,
                    )
                    if (response) {
                        updateIntervalsByResponse(response, 'change')
                        setResetFileTrigger(resetFileTrigger + 1)
                        showNotice('core-photo-added-sucessfully',t)
                    }
                    setLoading({ ...loading, changeBox: false })
                })
                .catch((e) => {
                    console.log(e)
                    setLoading({ ...loading, changeBox: false })
                })
        } catch (e) {
            setLoading({ ...loading, changeBox: false })
            errorsHandler(e, t)
        }
    }

    useEffect(() => {
        if (intervals && intervals.length > 0) {
            const targetInterval = intervals.find((el) => el.selected)
            if (targetInterval) {
                setCurrentInterval(targetInterval)
            } else setCurrentInterval(initialCorePhotoInterval)
        } else setCurrentInterval(initialCorePhotoInterval)
    }, [intervals])

    const renderPhotoBoxes = useCallback(() => {
            return (
                <ul className="core-boxes-photos-list">
                    {isLoadingIntervals ? (
                        <Skeleton.Input className="core-boxes-skeleton-item" />
                    ) : (currentInterval?.photoBoxes && Array.isArray(currentInterval?.photoBoxes) && currentInterval?.photoBoxes.length > 0 ? (
                        currentInterval?.photoBoxes?.map((el, i) => (
                            <li
                                key={i}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    getPhotoBox(el?.id)
                                }}
                                className={showActiveBoxItemClass(el)}
                            >
                                <img
                                    src={el?.photo.medium}
                                    alt="core box photo image"
                                />
                                <SettingCancelIcon
                                    width={20}
                                    height={20}
                                    viewBox="0 0 35 35"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        deleteBox(currentInterval?.id, el?.id)
                                    }}
                                />
                            </li>
                        ))
                    ) : (
                        <div className="core-boxes-photos-abcent-data">
                            {t('no-fotos-added')}
                        </div>
                    ))}
                </ul>
            )
    },[isLoadingIntervals,currentInterval?.photoBoxes])

    return (
        <>
            <div className="core-boxes-photos mt-15">
                {createPortal(
                    <SpinInLog
                        isLoading={loading.getBox || loading.changeBox}
                    />,
                    document.body,
                )}
                <div className="core-boxes-paragraph-top">
                    <PlusIcon
                        onClick={openModal}
                        className={'cursor-pointer'}
                    />
                    <span className={'font-14-normal'}>
                        {t('add-photo-box-with-core')}
                    </span>
                </div>
                <div className="core-boxes-photos-list">
                    {renderPhotoBoxes()}
                </div>
                <AddCoreBoxPhotoModal
                    loading={loading.addBox}
                    isOpenModal={isOpenModal}
                    currentInterval={currentInterval}
                    resetFileTrigger={resetFileTrigger}
                    addData={addBox}
                    closeModal={closeModal}
                    setResetFileTrigger={setResetFileTrigger}
                />
            </div>
            {Object.keys(currentBox).length > 0 &&
                Number(currentInterval?.id) ===
                    Number(currentBox?.box_interval) &&
                !isLoadingIntervals && (
                    <CoreBoxEditSpace
                        currentBox={currentBox}
                        changeCurrentBox={changeBox}
                    />
                )}
        </>
    )
}

export default memo(CoreBoxesPhotos)
