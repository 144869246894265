import React,{ FC, useContext } from 'react';

const TrashIcon =({ fill = 'var(--main-text-color)' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.89955 8.75677C5.31003 8.70125 5.6878 8.98899 5.74332 9.39947L6.83034 17.4356C6.9446 18.2804 7.02223 18.8438 7.13363 19.2616C7.23957 19.659 7.35327 19.8298 7.48081 19.9413C7.60836 20.0527 7.7929 20.1425 8.20087 20.1942C8.62986 20.2486 9.19858 20.25 10.051 20.25H13.9492C14.8016 20.25 15.3703 20.2486 15.7993 20.1942C16.2073 20.1425 16.3918 20.0527 16.5194 19.9413C16.6469 19.8298 16.7606 19.659 16.8665 19.2616C16.9779 18.8438 17.0556 18.2804 17.1698 17.4356L18.2569 9.39947C18.3124 8.98899 18.6901 8.70125 19.1006 8.75677C19.5111 8.81229 19.7988 9.19006 19.7433 9.60053L18.6499 17.684C18.5438 18.469 18.4547 19.1275 18.3159 19.648C18.1689 20.1995 17.9441 20.6882 17.5064 21.0708C17.0686 21.4533 16.5541 21.6105 15.988 21.6823C15.4535 21.75 14.789 21.75 13.9969 21.75H10.0033C9.21117 21.75 8.54666 21.75 8.01222 21.6823C7.44606 21.6105 6.93155 21.4533 6.4938 21.0708C6.05604 20.6882 5.83128 20.1995 5.68426 19.648C5.54548 19.1275 5.45642 18.469 5.35028 17.684L4.25686 9.60053C4.20133 9.19006 4.48908 8.81229 4.89955 8.75677Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9748 3.25H12.0253C12.4698 3.24999 12.8408 3.24999 13.1455 3.27077C13.4626 3.29241 13.7621 3.33905 14.0525 3.45933C14.7263 3.73844 15.2616 4.27379 15.5408 4.94762C15.661 5.23801 15.7077 5.53754 15.7293 5.85464C15.7501 6.15925 15.7501 6.53028 15.7501 6.97474V7C15.7501 7.41421 15.4143 7.75 15.0001 7.75C14.5859 7.75 14.2501 7.41421 14.2501 7C14.2501 6.5238 14.2497 6.20421 14.2328 5.95674C14.2164 5.71602 14.1869 5.5988 14.1549 5.52165C14.0281 5.21536 13.7847 4.97202 13.4784 4.84515C13.4013 4.81319 13.2841 4.78372 13.0433 4.76729C12.7959 4.75041 12.4763 4.75 12.0001 4.75C11.5239 4.75 11.2043 4.75041 10.9568 4.76729C10.7161 4.78372 10.5989 4.81319 10.5217 4.84515C10.2154 4.97202 9.97211 5.21536 9.84524 5.52165C9.81328 5.5988 9.7838 5.71602 9.76738 5.95674C9.75049 6.20421 9.75009 6.5238 9.75009 7H8.25009V6.97475C8.25008 6.53029 8.25007 6.15925 8.27086 5.85464C8.29249 5.53754 8.33914 5.23801 8.45942 4.94762C8.73853 4.27379 9.27388 3.73844 9.94771 3.45933C10.2381 3.33905 10.5376 3.29241 10.8547 3.27077C11.1593 3.24999 11.5304 3.24999 11.9748 3.25Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25009 7C4.25009 6.58579 4.58588 6.25 5.00009 6.25H19.0001C19.4143 6.25 19.7501 6.58579 19.7501 7C19.7501 7.41421 19.4143 7.75 19.0001 7.75H5.00009C4.58588 7.75 4.25009 7.41421 4.25009 7Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4502 9.25166C9.8635 9.22411 10.2209 9.53681 10.2484 9.95011L10.7484 17.4501C10.776 17.8634 10.4633 18.2208 10.05 18.2483C9.63668 18.2759 9.2793 17.9632 9.25175 17.5499L8.75175 10.0499C8.7242 9.63659 9.0369 9.27921 9.4502 9.25166Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.55 9.25166C14.9633 9.27921 15.276 9.63659 15.2484 10.0499L14.7484 17.5499C14.7209 17.9632 14.3635 18.2759 13.9502 18.2483C13.5369 18.2208 13.2242 17.8634 13.2517 17.4501L13.7517 9.95011C13.7793 9.53681 14.1367 9.22411 14.55 9.25166Z"
        fill={fill}
      />
    </svg>
  );
};

export default TrashIcon;
