import {ACCESS_TOKEN, HTTP_BASE_URL_API} from "../utils/constants";
import axios from "axios";

export const instance = axios.create({
    withCredentials: true, /// чтобы при каждом запросе кука цеплялась автоматически
    baseURL: HTTP_BASE_URL_API,
    headers: {
        Authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN)
                ? JSON.parse(localStorage.getItem(ACCESS_TOKEN))
                : null
        }`,
    },
})
