import {AxiosResponse} from "axios";
import {IWellBore, IWellBoreInfo} from "../app/types/models/logging";
import {ACCESS_TOKEN} from "../utils/constants";
import {instance} from "../app/api";

export const wellBoreService ={
    getWellBores: async (projectId: number) => {
        return await instance
            .get<undefined, AxiosResponse<IWellBore[]>>(
                `projects/${projectId}/wells/wellbore/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    addWellBore: async (id: number, data: { name: string; well: number }) => {
        return await instance
            .post<
                { id: number; data: { name: string; well: number } },
                AxiosResponse<IWellBore>
                >(`projects/${id}/wells/wellbore/`, data, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
    updateWellBore: async (
        projectId: number,
        wellBoreId: number,
        data: { name: string; well: number,wellbore?:number },
    ) => {
        return await instance
            .put<
                undefined,
                AxiosResponse<IWellBoreInfo>
                >(`projects/${projectId}/wells/wellbore/${wellBoreId}/`, data, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
    deleteWellBore: async (projectId: number, wellBoreId: number) => {
        return await instance.delete(
            `projects/${projectId}/wells/wellbore/${wellBoreId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },
    getWellBoreInfo: async (projectId: number,wellBoreId:number) => {
        return await instance
            .get<undefined, AxiosResponse<IWellBoreInfo>>(
                `projects/${projectId}/wells/wellbore/${wellBoreId}/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
}