import React, {FC} from 'react';
import {getValidText} from "../../utils/helpers/getValidText";
import {NoticeTypes} from "../noticeBlock/types";
import {INotificationDescriptionProps} from "./types";
import {useTranslation} from "react-i18next";

const NotificationDescription:FC<INotificationDescriptionProps> = ({item}) => {
    const {t} = useTranslation()
    const userName = `${getValidText(item?.receiver.first_name)} ${getValidText(item?.receiver.last_name)}`
    console.log(userName.trim().length)
    let content:React.ReactNode;
    if (item?.type === NoticeTypes.RoleRequest) {
        content = (
            <div>

                <div>{t('sender-requests-role-in-project', {
                    sender: `${getValidText(item?.sender.first_name)} ${getValidText(item?.sender.last_name)}`,
                    senderEmail: getValidText(item?.sender.email),
                    role: t(`${item?.data?.role_request?.role}`).toString(),
                }).toString()}</div>
            </div>
        )
    } else if (item?.type === NoticeTypes.ProjectUserInvitationResponse) {
        content = (
            <div>
                <div>
                    {
                        t('user-do-action-with-invitation-to-project', {
                            user: `${getValidText(item?.sender.first_name)} ${getValidText(item?.sender.last_name)}`,
                            senderEmail: getValidText(item?.sender.email),
                            action: `${item?.data?.accepted ? t('accepted') : t('rejected')}`,
                        }).toString()
                    }
                </div>
            </div>
        )
    } else if (item?.type === NoticeTypes.RoleRequestResponse) {
        content = (
            <div>
                <div>{t('user-do-action-with-role-in-project', {
                    user: `${getValidText(item?.sender.first_name)} ${getValidText(item?.sender.last_name)}`,
                    senderEmail: getValidText(item?.sender.email),
                    action: `${item?.data?.accepted ? t('accepted') : t('rejected')}`,
                    role: t(`${item?.data?.role_request?.role}`).toString(),
                }).toString()}</div>
            </div>
        )
    } else if (item?.type === NoticeTypes.ProjectUserInvitation) {
        content = (
            <div>
                <div>
                    {
                        t('user-invite-you-to-project-with-certain', {
                            user: `${getValidText(item?.sender?.first_name)} ${getValidText(item?.sender?.last_name)}`,
                            senderEmail: getValidText(item?.sender?.email),
                            action: `${item?.data?.accepted ? t('accepted') : t('rejected')}`,
                            role: t(`${item?.data?.project_user_invitation?.role}`).toString(),
                        }).toString()
                    }
                </div>
            </div>
        )
    } else if (item?.type === NoticeTypes.ProjectLeaving){
        content = (
            <div>
                <div>
                    {
                        t('existing-the-project', {
                            user: `${getValidText(item?.sender?.first_name)} ${getValidText(item?.sender?.last_name)}`,
                            senderEmail: getValidText(item?.sender?.email),
                            role: t(`${item?.data[item?.type]?.role}`).toString(),
                        }).toString()
                    }
                </div>
            </div>
        )
    }
    else if (item?.type === NoticeTypes.AdminRightsTransfer){
        content = (
            <div>
                <div>
                    {
                        t('admin-transfered-rights-to-you', {
                            user: `${getValidText(item?.sender?.first_name)} ${getValidText(item?.sender?.last_name)}`,
                            senderEmail: getValidText(item?.sender?.email),
                        }).toString()
                    }
                </div>
            </div>
        )
    }else if (item?.type === NoticeTypes.ProjectUserRemoval){
        content = (
            <div>
                <div>
                    {
                        t('user-removed-you-from-project', {
                            user: `${getValidText(item?.sender?.first_name)} ${getValidText(item?.sender?.last_name)}`,
                            senderEmail: getValidText(item?.sender?.email),
                        }).toString()
                    }
                </div>
            </div>
        )
    } else if (item?.type === NoticeTypes.RoleChangeByAdmin){
        content = (
            <div>
                <div>
                    {
                        t('admin-changed-your-role-to-certain', {
                            user: `${getValidText(item?.sender?.first_name)} ${getValidText(item?.sender?.last_name)}`,
                            senderEmail: getValidText(item?.sender?.email),
                            role:getValidText(item?.data?.role).toLowerCase(),
                        }).toString()
                    }
                </div>
            </div>
        )
    }
    else {
        content = ''
    }
    return (
        <>
            {content}
        </>
    )
}

export default NotificationDescription;