import { Button } from 'antd'
import {
    FC,
    default as React,
    default as react,
    useCallback,
    useEffect,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ITag } from '../../app/types/models/tasks'
import ArrowShortLeft from '../../image_files/icons/ArrowShortLeft'
import FolderIcon from '../../image_files/icons/FolderIcon'
import ServiceIcon from '../../image_files/icons/ServiceIcon'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { TagsTypes } from '../TagsDropdown/types'
import './TagsList.css'
import { getCurrentSystemTags } from './services/get-current-systemTags'
import { getInitialSystemTags } from './services/getInitialSystemTags'
import { ITagsListProps, SystemTagItem, SystemTagListItem } from './types'

export const TagsList: FC<ITagsListProps> = react.memo((props) => {
    const {
        currentTab,
        selectedTags,
        fetchedTags,

        addTags,
        deleteTags,
    } = props
    const { t, i18n } = useTranslation()
    const currentProject = useSelector(selectCurrentProject)

    const [cashList, setCashList] = useState<SystemTagListItem[]>([])

    const [currentSystemTags, setCurrentSystemTags] = useState<SystemTagItem[]>(
        [],
    )

    const tagsList =
        selectedTags && Array.isArray(selectedTags) && selectedTags?.length > 0
            ? selectedTags.map((el) => el.name)
            : []

    const toggleTagStatus = (tag: ITag) => {
        const targetTag = selectedTags.find((el) => el.name === tag.name)
        if (targetTag && targetTag?.id !== undefined) {
            deleteTags && deleteTags([targetTag.id])
        } else {
            addTags && addTags([tag?.name])
        }
    }

    const showIsSelectedTagStatus = useCallback(
        (name: string) => {
            const status = tagsList.includes(name)
            return status ? 'recent-tag recent-tag--selected' : 'recent-tag'
        },
        [tagsList],
    )

    const showRecentTags = useCallback(() => {
        return (
            <>
                <ul className="recent-tags-list">
                    {fetchedTags && Array.isArray(fetchedTags)
                        ? fetchedTags.map((p, i) => {
                              return (
                                  <li
                                      key={i}
                                      className={showIsSelectedTagStatus(
                                          p.name,
                                      )}
                                      onClick={() => toggleTagStatus(p)}
                                  >
                                      <ServiceIcon />
                                      <span>{p?.name}</span>
                                  </li>
                              )
                          })
                        : ''}
                </ul>
                {showSelectBtn(fetchedTags)}
            </>
        )
    }, [fetchedTags, showIsSelectedTagStatus, toggleTagStatus])

    const handleClickOnBreadCrumb = (index: number) => {
        if (index !== cashList.length - 1) {
            const targetBreadCrumb = cashList[index]
            if (targetBreadCrumb) {
                const newList = cashList.slice(0, index + 1)
                setCashList(newList)
                setCurrentSystemTags(targetBreadCrumb.list)
            }
        }
    }

    const handleSelectSystemItem = async (tagEl: SystemTagItem) => {
        if (tagEl.type === 'folder') {
            const currentSystemTagsData = await getCurrentSystemTags(
                tagEl,
                currentProject?.id,
                t,
            )
            if (currentSystemTagsData?.cashListItem) {
                setCashList((prev) => [
                    ...prev,
                    currentSystemTagsData?.cashListItem,
                ])
            }
            if (currentSystemTagsData?.tags) {
                setCurrentSystemTags(currentSystemTagsData?.tags)
            }
        } else {
            toggleTagStatus(tagEl)
        }
    }

    const showBreadCrumbsList = useCallback(() => {
        return (
            <div className="system-tags-breadcrumbs">
                <div></div>
                {cashList.map((el, index) => (
                    <div
                        key={el.name + index}
                        className={`font-14-normal system-tags-breadcrumb`}
                        onClick={() => handleClickOnBreadCrumb(index)}
                    >
                        {el.name}
                        {index < cashList.length - 1 && (
                            <ArrowShortLeft height={20} />
                        )}
                    </div>
                ))}
            </div>
        )
    }, [cashList, handleClickOnBreadCrumb])

    const getTagClass = useCallback(
        (tag: SystemTagItem) => {
            if (selectedTags && selectedTags.length > 0) {
                if (
                    tag.type === 'service' &&
                    selectedTags.find((el) => el.name === tag.name)
                ) {
                    return 'tag-element tag-element--selected'
                } else return 'tag-element'
            } else return 'tag-element'
        },
        [selectedTags],
    )

    const handleSelectAll = (tagsListArg: SystemTagItem[]) => {
        addTags && addTags(tagsListArg.map((el) => el?.name))
    }

    const handleDeleteAll = (tagsListArg: SystemTagItem[]) => {
        const targetList = selectedTags?.filter(
            (tag) =>
                !!tagsListArg?.find(
                    (nestedTag) => nestedTag?.name === tag?.name,
                ),
        )
        deleteTags &&
            targetList?.length > 0 &&
            deleteTags(targetList.map((el) => el?.id))
    }

    useEffect(() => {
        if (cashList.length === 0 || currentTab === TagsTypes.RESENT) {
            setCashList([
                {
                    list: getInitialSystemTags(t),
                    name: t('system'),
                },
            ])
            currentTab === TagsTypes.RESENT &&
                setCurrentSystemTags(getInitialSystemTags(t))
        }
    }, [i18n.language, currentTab])

    useEffect(() => {
        if (currentSystemTags.length === 0) {
            setCurrentSystemTags(getInitialSystemTags(t))
        }
    }, [i18n.language, currentTab])

    const showSelectBtn = useCallback(
        (tagsList: SystemTagItem[]) => {
            const filteredList = tagsList?.filter(
                (el) =>
                    el?.type !== 'folder' &&
                    el?.name &&
                    !selectedTags.find((val) => val.name === el?.name),
            )
            if (
                currentTab === TagsTypes.RESENT ||
                (cashList.length > 1 &&
                    currentSystemTags.find((el) => el.type === 'service'))
            ) {
                return (
                    <div className="system-tags-list-buttons-list">
                        <Button
                            className="tag-btn--select"
                            disabled={filteredList.length === 0}
                            onClick={() => handleSelectAll(filteredList)}
                        >
                            {t('select-All')}
                        </Button>
                        <Button
                            className="tag-btn--delete"
                            disabled={filteredList.length === tagsList.length}
                            onClick={() => handleDeleteAll(tagsList)}
                        >
                            {t('delete-All')}
                        </Button>
                    </div>
                )
            } else {
                return <></>
            }
        },
        [tagsList, selectedTags, currentTab, cashList],
    )

    const showSystemTags = useCallback(() => {
        return (
            <div className="system-tags-inner">
                {showBreadCrumbsList()}
                <ul className="system-tags-list">
                    {currentSystemTags && currentSystemTags.length > 0 ? (
                        currentSystemTags.map((tag, i) => (
                            <li
                                key={i}
                                className={`font-14-normal ${getTagClass(tag)}`}
                                onClick={() => handleSelectSystemItem(tag)}
                            >
                                {tag.type === 'folder' ? (
                                    <FolderIcon />
                                ) : (
                                    <ServiceIcon />
                                )}
                                <span>{tag.name}</span>
                            </li>
                        ))
                    ) : (
                        <div className="font-14-normal">{t('no-data')}</div>
                    )}
                </ul>
                {showSelectBtn(currentSystemTags)}
            </div>
        )
    }, [
        showBreadCrumbsList,
        currentSystemTags,
        showSelectBtn,
        getTagClass,
        handleSelectSystemItem,
    ])

    return (
        <>
            {currentTab === TagsTypes.RESENT
                ? showRecentTags()
                : showSystemTags()}
        </>
    )
})
