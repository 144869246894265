import React from 'react'
import { FC } from 'react'

interface IProps {
    className?: string
    style?: object
    onClick?: () => void
}

const DotIcon: FC<IProps> = ({ className, onClick, style }) => {
    return (
        <svg
            className={className ? className : ''}
            onClick={onClick ? onClick : () => {}}
            style={style ? style : {}}
            width="5"
            height="5"
            viewBox="0 0 5 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="2.5" cy="2.5" r="2.5" fill="#999999" />
        </svg>
    )
}

export { DotIcon }
