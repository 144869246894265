import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { invitationService } from '../../services/invitation-service'
import { routes } from '../../utils/constants'
import { getQueryVariables } from '../../utils/helpers/getQueryVariable'
import { showNotice } from '../../utils/helpers/showNotice'
import { useTranslation } from 'react-i18next'

const CopyLink = () => {
    const {t} = useTranslation()
    const urlParams = getQueryVariables()
    const navigate = useNavigate()
    const targetToken = urlParams?.find((item) => item?.name === 'token')
    const targetProject = urlParams?.find((item) => item?.name === 'project')

    const fetchProcess = useCallback(async () => {
        try {
            await invitationService.addUserInvitation(
                Number(targetProject?.value),
                { token: targetToken?.value },
            )
            showNotice('you-added-to-project-successfully', t)
            navigate(routes.HOME)
        } catch (e) {
            const preparedProj = {
                token: targetToken?.value,
                project: Number(targetProject?.value),
            }
            localStorage.setItem('copy-link', JSON.stringify(preparedProj))
            navigate(routes.LOGIN)
        }
    }, [])

    useEffect(() => {
        fetchProcess()
    }, [])
    return <div />
}

export default CopyLink
