import React, {FC} from 'react';
import {Link} from "react-router-dom";
import {DownloadIcon} from "../../image_files/icons/downloadIcon";
import {IDownloadIconButtonProps} from "./types";
import {getValidText} from "../../utils/helpers/getValidText";

const DownloadIconButton:FC<IDownloadIconButtonProps> = (props) => {
    const {href,description,className,onClick} = props
    return (
        <div className={`mt-15 cursor-pointer ${onClick ? 'flex-c-c' : ''} ${className}`} onClick={onClick ? onClick : ()=>undefined}>
            {
                !onClick &&
                <Link to={getValidText(href)} target="_blank" download
                      className={'cursor-pointer row-flex-5 font-primary'}>
                    {
                        description &&
                        <span className={'font-primary'}>{description}</span>
                    }
                    <DownloadIcon/>
                </Link>
            }
            {
                onClick &&
                <DownloadIcon/>
            }

        </div>
    );
};

export default DownloadIconButton;