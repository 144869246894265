import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectCurrentStrangeNans, selectWellLogFiles} from "../../../../../../store/currentProject/selectors";
import useDebounce from "../../../../../../hooks/useDebounce";
import {QuestionIcon} from "../../../../../../image_files/icons/QuestionIcon";
//@ts-ignore
import s from './strangeNanList.module.scss';
import PopoverInLog from "../../../../../../shared/popoverInlog/PopoverInLog";

interface IStrangeNanListProps {
    onChange:(value:string)=>void
}

export const StrangeNanList:FC<IStrangeNanListProps> = ({onChange}) =>{
    const {t} = useTranslation()
    const strange_nans = useSelector(selectCurrentStrangeNans)
    const [value,setValue] = useState(strange_nans ? strange_nans.join(',') : '')
    const debouncedValue = useDebounce(value, 500)
    const wellLogFiles = useSelector(selectWellLogFiles)

    const onChangeHandler = (e:ChangeEvent<HTMLTextAreaElement>)=>{
        setValue(e.currentTarget.value)
    }

    useEffect(() => {
        if(strange_nans.join(',')!==debouncedValue){
            onChange(debouncedValue)
        }
    }, [debouncedValue])
    return (
        <>
            {
                wellLogFiles && Array.isArray(wellLogFiles) && wellLogFiles?.length>0
                    ? <div className={s.strangeNanList}>
                        <div className={s.strangeNanList_label}>
                            <span>{t('strange-nan-list').toString()}</span>
                            <PopoverInLog content={<div className={'font-12-normal font-white mnemMatching-popover'}>{`${t('for-example').toString()} 9999, -9999.25`}</div>}>
                                <QuestionIcon viewBox={'0 0 18 13'}/>
                            </PopoverInLog>

                        </div>
                        <textarea value={value} onChange={onChangeHandler}/>
                    </div>
                    : <div className={s.missingDataMessage}>{t('strange-nan-message').toString()}</div>

            }
        </>

    )
}
