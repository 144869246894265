import { FC, useEffect } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ICurrentCustomMeasurementsListProps } from "./types";

const CurrentCustomMeasurementsList:FC<ICurrentCustomMeasurementsListProps> = (props) => {
  const {currentCustomGroup,currentCustomMeasurement,setCurrentCustomMeasurement} = props
  const {i18n} = useTranslation();

  useEffect(()=>{
    if(Object.keys(currentCustomMeasurement).length===0){
      setCurrentCustomMeasurement(currentCustomGroup?.measurements[0])
    }
  },[currentCustomGroup])

  return (
      <ul className="coreStudies__menuItems">
          {currentCustomGroup?.measurements &&
          currentCustomGroup.measurements?.length > 0
              ? currentCustomGroup.measurements?.map((item, index) => (
                    <li
                        key={index}
                        className={`coreStudies__menuText ${
                            item.id === currentCustomMeasurement?.id
                                ? 'active-measurement'
                                : ''
                        }`}
                        onClick={() => {
                            setCurrentCustomMeasurement(item)
                        }}
                    >
                        {i18n.language === 'ru'
                            ? item.name_ru
                            : item.name_en}
                    </li>
                ))
              : []}
      </ul>
  )
}

export default CurrentCustomMeasurementsList;