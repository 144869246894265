import {
    ACOUSTIC_VELOCITY,
    BIO,
    BULK_MODULUS,
    CAPACITY,
    CARBONATES,
    COMPRESSIBILITY,
    COMPRESSIVE_STRENGTH,
    CONDUCTIVITY,
    DENSITY,
    DIFFUSIVITY,
    ELASTIC_LIMIT,
    OIL_DISPLACEMENT,
    PERMEABILITY,
    POISSON_RATIO,
    POROSITY,
    SATURATION,
    SHEAR_MODULUS,
    ULTIMATE_COMPRESSIVE_STRENGTH,
    YIELD_STRENGTH,
    YOUNG_MODULE,
} from '../../../../../../../utils/constants'
import { thermalService } from '../../../../../../../services/thermal-service'
import { mechanicalService } from '../../../../../../../services/mechanical-service'
import { standardStudyGroupService } from '../../../../../../../services/standard-study-group-service'

interface IParams {
    groupType: 'standard' | 'mechanical' | 'thermal'
    settingType: string
    projectId: number
    coreId: number

    setData: (value: any) => void
    setLoading: (value: boolean) => void
    setGroup: (value: number) => void
}

export const fetchGroupAsync = async (
    values: IParams,
): Promise<{ groupId: number; setting: any }> => {
    const {
        settingType,
        groupType,
        setLoading,
        setData,
        coreId,
        projectId,
        setGroup,
    } = values
    try {
        let groupResponse
        let settingResponse
        if (groupType === 'mechanical') {
            groupResponse = await mechanicalService.getMechanicalGroup(
                projectId,
                coreId,
            )
        } else if (groupType === 'thermal') {
            groupResponse = await thermalService.getThermalGroup(
                projectId,
                coreId,
            )
        } else {
            groupResponse = await standardStudyGroupService.getStandardGroup(
                projectId,
                coreId,
            )
        }

        if (groupResponse && groupResponse.id) {
            if (groupType === 'standard') {
                if (settingType === POROSITY) {
                    settingResponse = await standardStudyGroupService.getPorosity(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                } else if (settingType === PERMEABILITY) {
                    settingResponse = await standardStudyGroupService.getPermeability(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                } else if (settingType === SATURATION) {
                    settingResponse = await standardStudyGroupService.getSaturation(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                } else if (settingType === OIL_DISPLACEMENT) {
                    settingResponse = await standardStudyGroupService.getOilDisplacement(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                } else if (settingType === CARBONATES) {
                    settingResponse = await standardStudyGroupService.getCarbonateContent(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                } else if (settingType === DENSITY) {
                    settingResponse = await standardStudyGroupService.getDensity(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                }
            } else if (groupType === 'mechanical') {
                if (settingType === YOUNG_MODULE) {
                    settingResponse = await mechanicalService.getYoungModule(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                } else if (settingType === POISSON_RATIO) {
                    settingResponse = await mechanicalService.getPoissonRatio(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                } else if (settingType === ACOUSTIC_VELOCITY) {
                    settingResponse =
                        await mechanicalService.getAcousticVelocity(
                            projectId,
                            coreId,
                            groupResponse.id,
                        )
                } else if (settingType === COMPRESSIBILITY) {
                    settingResponse =
                        await mechanicalService.getCompressibility(
                            projectId,
                            coreId,
                            groupResponse.id,
                        )
                } else if (settingType === SHEAR_MODULUS) {
                    settingResponse = await mechanicalService.getShearModulus(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                } else if (settingType === YIELD_STRENGTH) {
                    settingResponse = await mechanicalService.getYieldStrength(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                } else if (settingType === BIO) {
                    settingResponse = await mechanicalService.getBio(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                } else if (settingType === BULK_MODULUS) {
                    settingResponse = await mechanicalService.getBulkModulus(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                } else if (settingType === COMPRESSIVE_STRENGTH) {
                    settingResponse =
                        await mechanicalService.getUniaxialCompressiveStrength(
                            projectId,
                            coreId,
                            groupResponse.id,
                        )
                } else if (settingType === ULTIMATE_COMPRESSIVE_STRENGTH) {
                    settingResponse =
                        await mechanicalService.getUltimateCompressiveStrength(
                            projectId,
                            coreId,
                            groupResponse.id,
                        )
                } else if (settingType === ELASTIC_LIMIT) {
                    settingResponse = await mechanicalService.getElasticLimit(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                }
            } else {
                if (settingType === CONDUCTIVITY) {
                    settingResponse = await thermalService.getConductivity(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                } else if (settingType === DIFFUSIVITY) {
                    settingResponse = await thermalService.getDiffusivity(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                } else if (settingType === CAPACITY) {
                    settingResponse = await thermalService.getCapacity(
                        projectId,
                        coreId,
                        groupResponse.id,
                    )
                }
            }
        }
        setLoading(false)
        setGroup(groupResponse.id)
        if (settingResponse.length > 0) {
            setData(
                settingResponse.map((item) => ({
                    ...item,
                    //переводим value_normalized, потому что отправлять нужно будет именно поле value
                    value: item?.value_normalized,
                    method: item.method.id,
                    equipment: item.equipment.id,
                })),
            )
        } else {
            setData(null)
        }
        return Promise.resolve({
            groupId: groupResponse.id,
            setting: Array.isArray(settingResponse)
                ? settingResponse.map((item) => ({
                      ...item,
                      //переводим value_normalized, потому что отправлять нужно будет именно поле value
                      value: item?.value_normalized,
                      method: item.method.id,
                      equipment: item.equipment.id,
                  }))
                : {
                      ...settingResponse,
                      value: settingResponse.value_normalized,
                      method: settingResponse.method.id,
                      equipment: settingResponse.equipment.id,
                  },
        })
    } catch (e) {
        console.log(e)
        setGroup(null)
        setLoading(false)
        setData(null)
    }
}
