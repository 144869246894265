import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import './notifications.css';
import AllSettings from "./allSettings/allSettings";
import {notificationsService} from "../../../services/notifications-service";
import {errorsHandler} from "../../../utils/helpers/errors/errors-hendler";
import AllNotifications from "./allNotifications/AllNotifications";
import {useLocation} from "react-router-dom";
import {useAppDispatch} from "../../../hooks/redux-hooks";
import {setNotifications} from "../../../store/notifications/actions";
import {useSelector} from "react-redux";
import {selectNotifications} from "../../../store/notifications/selectors";

const Notifications = () => {
    const [currentTab, setCurrentTab] = useState('all-notices')
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation()
    const dispatch = useAppDispatch()
    const notifications = useSelector(selectNotifications) || []

    const showCurrentBlock = () => {
        switch (currentTab) {
            case 'settings':
                return <AllSettings/>
            case 'all-notices':
                return <AllNotifications isLoading={isLoading}/>
            default:
                return ''
        }
    }
    const showActiveTab = (value: string) => {
        return currentTab === value ? 'notifications-active-tab' : ''
    }

    const fetchNotifications = async () => {
        try {
            setIsLoading(true)
            const response = await notificationsService.getNotifications()
            dispatch(setNotifications({notifications:response}))
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            errorsHandler(e, t)
        }
    }

    useEffect(() => {
        if(currentTab==='all-notices'){
            fetchNotifications()
        }
    }, [currentTab])

    useEffect(() => {
        if (location?.state?.tab) {
            setCurrentTab(location?.state?.tab)
        }
    }, [location])

    return (
        <div className={'notifications max-w-700 br-5'}>
            <div className={'row-flex-10'}>
                <div className={`font-20-normal cursor-pointer ${showActiveTab('all-notices')}`}
                     onClick={() => setCurrentTab('all-notices')}>
                    {t('all-notifications').toString() + `(${notifications?.length})`}
                </div>
                <div className={`font-20-normal cursor-pointer ${showActiveTab('settings')}`}
                     onClick={() => setCurrentTab('settings')}>
                    {t('settings').toString()}
                </div>
            </div>
            {showCurrentBlock()}
        </div>
    );
};

export default Notifications;