import { useState, useEffect } from 'react';

type CameraPositionType = 'environment' | 'user';

interface ICameraData {
  stream: MediaStream | null;
  type: CameraPositionType;
  active: boolean;
}

const getMediaOptionsByType = (type: CameraPositionType) => {
  return {
    audio: false,
    video: { facingMode: type }
  };
};

export function useUserMedia(cameraPositions: CameraPositionType[]) {
  const [mediaDataList, setMediaList] = useState<ICameraData[]>([]);
  const [isHasCamera, setIshasCamera] = useState(false);

  const getDevicesData = () => {
    async function enableStream() {
      try {
        const preparedDataArr: ICameraData[] = [];
        for (let i = 0; i < cameraPositions.length; i++) {
          try {
            const stream = await navigator.mediaDevices.getUserMedia(
              getMediaOptionsByType(cameraPositions[i])
            );
            preparedDataArr.push({
              stream,
              type: cameraPositions[i],
              active: stream.active
            });
          } catch (e) {
            console.log(e);
          }
        }

        setMediaList(preparedDataArr);
        if (preparedDataArr.length > 0) {
          const targetEl = preparedDataArr.find((el) => el.stream?.active);
          setIshasCamera(!!targetEl);
        } else {
          setIshasCamera(false);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (mediaDataList.length === 0) {
      enableStream();
    } else {
      return function cleanup() {
        mediaDataList.forEach((data) => {
          if (data && data?.stream) {
            data?.stream.getTracks().forEach((track: any) => {
              track.stop();
            });
          }
        });
      };
    }
  };

  const refreshData = () => {
    getDevicesData();
  };

  return { mediaDataList, isHasCamera, refresh: refreshData };
}
