import { Button, Modal, Radio, Space } from 'antd'
import React, { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../../../../../../../hooks/redux-hooks'
import { DeleteIcon } from '../../../../../../../../../../image_files/icons/DeleteIcon'
import { PlusIcon } from '../../../../../../../../../../image_files/icons/PlusIcon'
import { PreviewIcon } from '../../../../../../../../../../image_files/icons/PreviewIcon'
import { projectsService } from '../../../../../../../../../../services/projects-service.'
import { DeleteModal } from '../../../../../../../../../../shared/DeleteModal/DeleteModal'
import { FileAttach } from '../../../../../../../../../../shared/FileAttach/FileAttach'
import { LargeSpin } from '../../../../../../../../../../shared/LargeSpin'
import SelectFileText from '../../../../../../../../../../shared/selectFileText/SelectFileText'
import { currentProjectActions } from '../../../../../../../../../../store/currentProject'
import { errorsHandler } from '../../../../../../../../../../utils/helpers/errors/errors-hendler'
import {
    showNotice,
    showNotify,
} from '../../../../../../../../../../utils/helpers/showNotice'
import './corePhoto.css'
import { ICorePhotoProps } from './types'
import { setCurrentCore } from '../../../../../../../../../../store/currentProject/actions'
import { IPhotoEntity } from '../../../../../../../../../../app/types/common'

const CorePhoto: FC<ICorePhotoProps> = (props) => {
    const { currentCore, currentProjectId, menuOpen, setMenuOpen } = props
    const [previewOpen, setPreviewOpen] = useState(false)
    const [deletePhotoModalOpen, setDeletePhotoModalOpen] = useState(false)
    const [resetFileTrigger, setResetFileTrigger] = useState(0)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [file, setFile] = useState([])
    const [sampleTypeValue, setSampleTypeValue] = useState('full_size_sample')
    const [lightTypeValue, setLightTypeValue] = useState('visible')
    const filePicker = useRef(null)
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const { addCurrentCorePhoto, deleteCurrentCorePhoto } =
        currentProjectActions

    const uploadPhotoHandler = async (): Promise<void> => {
        try {
            setLoading(true)
            const formData = new FormData()
            formData.append('photo', file[0]?.originFileObj)
            formData.append('core', String(currentCore?.id))
            formData.append('core_type', sampleTypeValue)
            formData.append('light', lightTypeValue)
            const data = await projectsService.addCorePhoto(
                currentProjectId,
                formData,
            )
            dispatch(addCurrentCorePhoto({ id: currentCore?.id, photo: data }))
            dispatch(
                setCurrentCore({
                    ...currentCore,
                    photos:
                        currentCore?.photos && currentCore.photos?.length > 0
                            ? [...currentCore?.photos, data] as IPhotoEntity[]
                            : [data] as any,
                }),
            )
            setMenuOpen(false)
            showNotify(t('image-added-successfully'))
            setFile(null)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            setMenuOpen(false)
        } finally {
            setLoading(false)
        }
    }

    //удаляем уже загруженные фото
    const deletePhotoHandler = async (photoId: number) => {
        try {
            await projectsService.deleteCorePhoto(currentProjectId, photoId)
            dispatch(
                deleteCurrentCorePhoto({
                    id: currentCore.id,
                    photoId,
                }),
            )
            dispatch(
                setCurrentCore({
                    ...currentCore,
                    photos:
                        currentCore?.photos?.filter(el=>el?.id!==photoId) || [],
                }),
            )
            setDeletePhotoModalOpen(false)
            showNotify(t('image-deleted-successfully'))
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const handleCancelPreview = () => setPreviewOpen(false)
    const handleCancelMenu = () => setMenuOpen(false)

    const addPhotoHandler = (e) => {
        setFile(e.target.files[0])
    }

    const deleteModalCancel = (): void => setDeletePhotoModalOpen(false)

    const showPreviewHandler = (photo: string, name?: string) => {
        setPreviewImage(photo)
        if (name) {
            setPreviewTitle(name?.split('/').at(-1))
            setPreviewOpen(true)
        }
    }

    const onChangeCoreSampleType = (e) => {
        setSampleTypeValue(e.target.value)
    }

    const onChangeLightType = (e) => {
        setLightTypeValue(e.target.value)
    }

    const removeUploadFileHandler = async (id: number) => undefined

    return (
        <div className={'corePhotoUpload-inner'}>
            <div className={'corePhotoUpload'}>
                <ul className={'corePhotoUpload__list'}>
                    {currentCore &&
                        Array.isArray(currentCore.photos) &&
                        currentCore?.photos?.map((photo) => (
                            <div
                                className={'corePhotoUpload__photoContainer'}
                                key={photo?.id}
                            >
                                <img
                                    className={'corePhotoUpload__photo'}
                                    src={photo?.photo.medium}
                                    alt="core photo image"
                                />
                                <PreviewIcon
                                    className={'corePhotoUpload__previewIcon'}
                                    onClick={() =>
                                        showPreviewHandler(
                                            photo.photo.large,
                                            photo.filename,
                                        )
                                    }
                                />
                                <DeleteIcon
                                    className={'corePhotoUpload__deleteIcon'}
                                    onClick={() =>
                                        deletePhotoHandler(photo?.id)
                                    }
                                />
                                <div
                                    className={'corePhotoUpload__photoPhone'}
                                ></div>
                            </div>
                        ))}

                    <div>
                        <div
                            className={'corePhotoUpload__btn'}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setMenuOpen(true)
                            }}
                        >
                            <PlusIcon />
                        </div>
                        <input
                            type={'file'}
                            ref={filePicker}
                            accept={'.png,.jpg,.gif,.web,'}
                            className={'hidden'}
                            id={'loggingProduction-input'}
                            onChange={addPhotoHandler}
                        />
                    </div>
                </ul>
            </div>
            <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancelPreview}
            >
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
            <Modal footer={null} open={menuOpen} onCancel={handleCancelMenu}>
                {loading ? (
                    <LargeSpin style={{ width: '100%', height: '40vh' }} />
                ) : (
                    <div className={'corePhotoMenu br-5'}>
                        <h3 className="corePhotoMenu__top ">
                            {`${t('select-image')}`}
                        </h3>
                        <div className="corePhotoMenu__dragBlock">
                            <FileAttach
                                files={[]}
                                accept=".png,.jpg,.tiff"
                                resetFileTrigger={resetFileTrigger}
                                title={
                                    <SelectFileText value="(.png, .jpg, .tiff)" />
                                }
                                customRequest={() => undefined}
                                removeFile={removeUploadFileHandler}
                                onSelect={(fileData) => {
                                    setFile([fileData[0]])
                                }}
                            />
                        </div>
                        <div className="corePhotoMenu__bottom">
                            <div className="corePhotoMenu__bottom-column">
                                <h5 className="corePhotoMenu__bottom-title">{`${t(
                                    'core-type',
                                )}`}</h5>
                                <Radio.Group
                                    onChange={onChangeCoreSampleType}
                                    defaultValue="plug"
                                    className={
                                        'corePhotoMenu__bottom-radioGroup'
                                    }
                                >
                                    <Space direction="vertical">
                                        <Radio value={'full_size_sample'}>
                                            {`${t('full-size-core')}`}
                                        </Radio>
                                        <Radio value={'plug'}>
                                            {`${t('lab-sample')}`}
                                        </Radio>
                                        <Radio value={'cuttings'}>
                                            {`${t('core-cuttings')}`}
                                        </Radio>
                                        <Radio value={'other'}>
                                            {`${t('other')}`}
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </div>
                            <div className="corePhotoMenu__bottom-column">
                                <h5 className="corePhotoMenu__bottom-title">
                                    {`${t('lighting')}`}
                                </h5>
                                <Radio.Group
                                    onChange={onChangeLightType}
                                    defaultValue="visible"
                                    className={
                                        'corePhotoMenu__bottom-radioGroup'
                                    }
                                >
                                    <Space direction="vertical">
                                        <Radio value={'visible'}>
                                            {`${t('visible-light')}`}
                                        </Radio>
                                        <Radio value={'ultraviolet'}>
                                            {`${t('ultraviolet-light')}`}
                                        </Radio>
                                        <Radio value={'other'}>
                                            {`${t('other')}`}
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className={'corePhotoMenu__btnWrapper'}>
                            <Button
                                type={'primary'}
                                onClick={uploadPhotoHandler}
                            >
                                {`${t('upload-image')}`}
                            </Button>
                        </div>
                    </div>
                )}
            </Modal>
            <DeleteModal
                open={deletePhotoModalOpen}
                onCancel={deleteModalCancel}
                onDelete={() => {}}
            />
        </div>
    )
}

export { CorePhoto }
