import {
    ACOUSTIC_VELOCITY,
    BIO,
    BULK_MODULUS,
    CAPACITY,
    CARBONATES,
    COMPRESSIBILITY,
    COMPRESSIVE_STRENGTH,
    CONDUCTIVITY,
    DENSITY,
    DIFFUSIVITY,
    ELASTIC_LIMIT,
    OIL_DISPLACEMENT,
    PERMEABILITY,
    POISSON_RATIO,
    POROSITY,
    SATURATION,
    SHEAR_MODULUS,
    ULTIMATE_COMPRESSIVE_STRENGTH,
    YIELD_STRENGTH,
    YOUNG_MODULE,
} from '../../../../../../../utils/constants'
import { mechanicalService } from '../../../../../../../services/mechanical-service'
import { thermalService } from '../../../../../../../services/thermal-service'
import { errorsHandler } from '../../../../../../../utils/helpers/errors/errors-hendler'
import { showNotice } from '../../../../../../../utils/helpers/showNotice'
import { standardStudyGroupService } from '../../../../../../../services/standard-study-group-service'

type DataType = any

interface IParams {
    settingType: string
    groupType: 'standard' | 'mechanical' | 'thermal'
    projectId: number
    coreId: number
    data: DataType
    groupId?: number
    setData: (value: any) => void
    t?: Function
}

export const createSetting = async (values: IParams) => {
    const {
        settingType,
        groupType,
        projectId,
        coreId,
        data,
        setData,
        groupId,
        t,
    } = values
    const dataToSend = { ...data, value_normalized: data.value }
    try {
        let settingResponse = null
        if (groupType === 'standard') {
            if (settingType === POROSITY) {
                settingResponse = await standardStudyGroupService.createPorosity(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            } else if (settingType === PERMEABILITY) {
                settingResponse = await standardStudyGroupService.createPermeability(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            } else if (settingType === SATURATION) {
                settingResponse = await standardStudyGroupService.createSaturation(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            } else if (settingType === OIL_DISPLACEMENT) {
                settingResponse = await standardStudyGroupService.createOilDisplacement(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            } else if (settingType === CARBONATES) {
                settingResponse = await standardStudyGroupService.createCarbonateContent(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            } else if (settingType === DENSITY) {
                settingResponse = await standardStudyGroupService.createDensity(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            }
        } else if (groupType === 'mechanical') {
            if (settingType === YOUNG_MODULE) {
                settingResponse = await mechanicalService.createYoungModule(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            } else if (settingType === POISSON_RATIO) {
                settingResponse = await mechanicalService.createPoissonRatio(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            } else if (settingType === ACOUSTIC_VELOCITY) {
                settingResponse =
                    await mechanicalService.createAcousticVelocity(
                        projectId,
                        coreId,
                        groupId,
                        dataToSend,
                    )
            } else if (settingType === COMPRESSIBILITY) {
                settingResponse = await mechanicalService.createCompressibility(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            } else if (settingType === SHEAR_MODULUS) {
                settingResponse = await mechanicalService.createShearModulus(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            } else if (settingType === YIELD_STRENGTH) {
                settingResponse = await mechanicalService.createYieldStrength(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            } else if (settingType === BIO) {
                settingResponse = await mechanicalService.createBio(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            } else if (settingType === BULK_MODULUS) {
                settingResponse = await mechanicalService.createBulkModulus(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            } else if (settingType === COMPRESSIVE_STRENGTH) {
                settingResponse =
                    await mechanicalService.createUniaxialCompressiveStrength(
                        projectId,
                        coreId,
                        groupId,
                        dataToSend,
                    )
            } else if (settingType === ULTIMATE_COMPRESSIVE_STRENGTH) {
                settingResponse =
                    await mechanicalService.createUltimateCompressiveStrength(
                        projectId,
                        coreId,
                        groupId,
                        dataToSend,
                    )
            } else if (settingType === ELASTIC_LIMIT) {
                settingResponse = await mechanicalService.createElasticLimit(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            }
        } else {
            if (settingType === CONDUCTIVITY) {
                settingResponse = await thermalService.createConductivity(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend as any,
                )
            } else if (settingType === DIFFUSIVITY) {
                settingResponse = await thermalService.createDiffusivity(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            } else if (settingType === CAPACITY) {
                settingResponse = await thermalService.createCapacity(
                    projectId,
                    coreId,
                    groupId,
                    dataToSend,
                )
            }
        }

        //при создании переписывам ключ value_normalized на value. Значения method, equipment приходят ввиде обьектов, что вызывает ошибку при последующем обновлении настроек, поэтому ставлю null
        setData([
            {
                ...settingResponse,
                method: null,
                equipment: null,
                value: settingResponse?.value_normalized,
            },
        ])
        showNotice('setting-created-successfully', t)
    } catch (e) {
        errorsHandler(e, t)
    }
}
