import React from 'react'

const SelectIcon = () => {
    return (
        <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.75673 5.66292C4.87648 5.82884 5.12352 5.82884 5.24327 5.66292L8.98692 0.475562C9.13011 0.277153 8.98834 0 8.74366 0H1.25635C1.01166 0 0.86989 0.277153 1.01308 0.475562L4.75673 5.66292Z"
                fill="#2B506B"
                fillOpacity="0.5"
            />
        </svg>
    )
}

export { SelectIcon }
