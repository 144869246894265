import React from 'react'
import { FC } from 'react'
import {getValidText} from "../../utils/helpers/getValidText";
import {IIconProps} from "../../app/types/i-props";


export const AddElementIcon: FC<IIconProps> = ({ className, onClick, title }) => {
    return (
        <div title={title ? title : ''}>
            <svg
                className={`cursor-pointer ${getValidText(className)}`}
                onClick={onClick ? onClick : () => {}}
                width="12"
                height="12"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    x1="4"
                    y1="0.166748"
                    x2="4"
                    y2="7.66675"
                    stroke="#2B506B"
                />
                <line
                    x1="7.6665"
                    y1="4"
                    x2="0.166504"
                    y2="4"
                    stroke="#2B506B"
                />
            </svg>
        </div>
    )
}
