import React, {FC, useEffect, useState} from 'react'
import './teamItem.css'
import {useTranslation} from 'react-i18next'
import {RoleTypes} from "../../../../app/types/enums";
import {userService} from "../../../../services/user-service";
import {useSelector} from "react-redux";
import {selectCurrentProject} from "../../../../store/currentProject/selectors";
import {errorsHandler} from "../../../../utils/helpers/errors/errors-hendler";
import {selectUser} from "../../../../store/user/selectors";
import {selectMembers} from "../../../../store/members/selectors";
import {getValidText} from "../../../../utils/helpers/getValidText";
import {getValidRoleOptions} from "../../data/getValidRoleOptions";
import {ITeamItemProps} from "./types";
import {IUser} from "../../../../app/types/models/users";
import SelectInLog from "../../../../shared/select/Select";
import {toast} from "react-toastify";
import {showNotice} from "../../../../utils/helpers/showNotice";
import {membersService} from "../../../../services/memembers-service";
import {useAppDispatch} from "../../../../hooks/redux-hooks";
import {setMembers} from "../../../../store/members/actions";
import {useNavigate} from "react-router-dom";
import {selectProjects} from "../../../../store/projects/selectors";
import {projectsService} from "../../../../services/projects-service.";
import {setProjects} from "../../../../store/projects/actions";

const TeamItem: FC<ITeamItemProps> = ({member, isTopRow}) => {
    const {t} = useTranslation()
    const currentProject = useSelector(selectCurrentProject)
    const [userSelectValue, setUserSelectValue] = useState<RoleTypes>('' as RoleTypes)
    const me = useSelector(selectUser)
    const members = useSelector(selectMembers)
    const [currentOption, setCurrentOption] = useState('')
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const projects = useSelector(selectProjects)

    const currentProjectIndex = projects?.findIndex(el=>el?.id===currentProject?.id)

    const changedUserObj: IUser = me ? {
        ...me,
        role: getValidText(members?.find(item => item?.user?.id === me?.id)?.role) as RoleTypes
    } : {} as IUser
    const roleOptions = getValidRoleOptions(changedUserObj, member, t)

    const fetchMembers = async () => {
        try {
            const response = await membersService.getMembers(currentProject?.id)
            dispatch(setMembers({members: response}))

        } catch (e) {
            console.log(e)
        }
    }

    const fetchProjects = async ()=>{
        try {
            const projects = await projectsService.getProjects()
            dispatch(setProjects({ projects }))
        }catch (e) {
            console.log(e)
            errorsHandler(e,t)
        }
    }

    const changeRole = async (value: any) => {
        try {
            if (changedUserObj?.role === RoleTypes.admin) {
                if (member?.role === RoleTypes.admin) {
                    if (value === 'leave_project') {
                        toast.error(t('first-transfer-your-admin-rights'), {position: 'top-center'})
                    }
                } else {
                    if (value === 'transfer_rights') {
                        await userService.changeRole(currentProject?.id, member?.id, {role: RoleTypes.admin})
                        await fetchMembers()
                        setCurrentOption(RoleTypes.admin)
                        showNotice('admin-rights-transfered-successfully', t)

                    } else if (value === 'close_access') {
                        await userService.closeRoleAccess(currentProject?.id, member?.id)
                        await fetchMembers()
                        setCurrentOption(value)
                        showNotice('member-deleted-successfully-from-project', t)
                    } else {
                        await userService.changeRole(currentProject?.id, member?.id, {role: value})
                        setCurrentOption(value)
                        showNotice('member-role-changed-successfully', t)
                    }
                }
            } else {
                if (value === 'leave_project') {
                    await userService.closeRoleAccess(currentProject?.id, member?.id)
                    const filteredProjects = projects.filter(item=>item.id!==currentProject.id)
                    if(filteredProjects.length===0){
                        dispatch(setProjects({projects:[]}))
                        navigate(`/logging/new`)

                    }else{
                        const targetIndex = currentProjectIndex !==projects?.length-1 ? currentProjectIndex +1 : 0
                        const targetProject = projects[targetIndex]
                        fetchProjects().then(()=>{
                            dispatch(setProjects({ projects:projects?.filter(item=>item?.id!==currentProject?.id) }))
                            navigate(`/scheduler/${targetProject?.id}/tasks`,{
                                state:{
                                    projects:projects?.filter(item=>item?.id!==currentProject?.id)
                                }
                            })
                        })
                    }
                } else {
                    if(value===RoleTypes.editor || value===RoleTypes.member){
                        await userService.changeRoleByUser(currentProject?.id,{
                            role:value,
                            project:currentProject?.id
                        })
                    }else{
                        await userService.changeRole(currentProject?.id, member?.id, {role: value})
                    }

                    setCurrentOption(value)
                    showNotice('member-role-changed-successfully', t)
                }
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    useEffect(() => {
        setUserSelectValue(member?.role)
    }, [member])

    useEffect(() => {
        setCurrentOption(member?.role)
    }, [member])

    const showValidRoleField = () => {
        if (isTopRow) {
            return t('role').toString()
        } else {
            if (roleOptions?.length === 0) {
            return t('creator').toString()
            }else{
                if(roleOptions?.length >0 && members?.length>1){
                    return <SelectInLog
                        className={'team-item-roles-select'}
                        options={roleOptions}
                        value={currentOption}
                        onChange={changeRole}

                    />
                }else{
                    return <span>{t('creator').toString()}</span>
                }

            }
        }
    }

    return (
        <div
            className={
                top ? `team__table-item team__table-top` : 'team__table-item'
            }
        >
            <div
                className="team__table-column">{getValidText(member ? member?.user?.full_name : t('member').toString())}</div>
            <div
                className="team__table-column">{getValidText(member ? member?.user?.email : t('email'))}</div>
            <div
                className="team__table-column">{getValidText(member ? member?.user?.company_name : t('company'))}</div>
            <div
                className="team__table-column">{getValidText(member ? member?.user?.position : t('position'))}</div>
            <div className="team__table-column">
                {showValidRoleField()}
            </div>
        </div>
    )
}

export {TeamItem}
