import React, {FC, useEffect, useState} from 'react';
import SelectInputBlock from "../../../../shared/selectInputBlock/SelectInputBlock";
import {LanguageTypes} from "../../../../app/types/enums";
import {SelectInputTypes} from "../../../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/types";
import {useTranslation} from "react-i18next";
import {ICustomGroupProps} from "./types";
import {getValidText} from "../../../../utils/helpers/getValidText";
import {ICustomGroupParameter} from "../../types";

const CustomDropdownBlock: FC<ICustomGroupProps> = (props) => {
    const {activeParameter,addParameter, className,setIsError} = props
    const {t,i18n} = useTranslation()
    const [parameter,setParameter] = useState<ICustomGroupParameter>({} as ICustomGroupParameter)
    const [isErrorBlockTitle,setIsErrorBlockTitle] = useState(false)
    const [isErrorInputTitle,setIsErrorInputTitle] = useState(false)

    const changeTagsBlock = (e) => {
        const preparedItem = {...parameter,
            data:{...parameter.data,tags:e}}
        setParameter(preparedItem)
        addParameter(preparedItem)
    }

    const changeAddTitleBlock = (e) => {
        const preparedItem = {...parameter,
            data:{...parameter.data,title:e}}
        setParameter(preparedItem)
        addParameter(preparedItem)
    }

    useEffect(()=>{
        const title = activeParameter.data.hasOwnProperty('title') ? activeParameter.data?.title : {en: '', ru: ''}
        const tags = activeParameter.data.hasOwnProperty('tags') ? activeParameter.data?.tags : {en: undefined, ru: undefined}
        setParameter({
            ...activeParameter,
            data:{
                title,
                tags
            }
        })
    },[activeParameter])

    useEffect(()=>{
        if(setIsError){
            if(isErrorBlockTitle || isErrorInputTitle){
                setIsError(true)
            }else{
                 setIsError(false)
            }
        }
    },[isErrorBlockTitle,isErrorInputTitle,activeParameter])

    return (
        <div className={`add-parameter-zone-space ${getValidText(className)}`}>
            <div className={'custom-dropdown-block cursor-pointer'}>
                <SelectInputBlock
                    className={'custom-dropdown-tags-select'}
                    onChange={changeTagsBlock}
                    setIsError={setIsErrorBlockTitle}
                    type={SelectInputTypes.tags}
                    activeLang={i18n.language ===LanguageTypes.ru ? 'ru' : 'en'}
                    data={ parameter?.data?.tags}
                />
                <div className={'custom-dropdown-block-add-title'}>
                <span className={'font-10-normal'}>
                    {t('add-dynamic-value',{value:''}).toString()}
                    <SelectInputBlock
                        className={'custom-group-select-input--small'}
                        onChange={changeAddTitleBlock}
                        setIsError={setIsErrorInputTitle}
                        type={SelectInputTypes.input}
                        activeLang={i18n.language ===LanguageTypes.ru ? 'ru' : 'en'}
                        data={parameter?.data?.title}
                    />
                </span>
                </div>
            </div>
        </div>
    );
};

export default CustomDropdownBlock;