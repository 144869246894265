import {notification} from 'antd';
import React, {FC, useEffect, useRef, useState} from 'react';
import './UploadButton.css';
import {IUploadFile, IUPlodButtonProps} from './types';
import {convertBase64} from "../../utils/helpers/convertBase64";
import { useTranslation } from 'react-i18next';

const UploadButton: FC<IUPlodButtonProps> = (props) => {
    const {children, title, onChange, resetTrigger, accept, externalRef, className} =
        props;
    const {t} = useTranslation()
    const [file, setFile] = useState<IUploadFile>({
        data: '',
        name: '',
        extension: '',
        view: '',
        file:null
    } as IUploadFile);
    const ref = useRef<HTMLInputElement>(null);

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileData = e?.target?.files && e.target.files[0];
        if (fileData) {
            const data = await convertBase64(fileData);
            const prepapredData = {
                data: data?.data,
                name: fileData?.name?.split('.')[0],
                extension: fileData?.name?.split('.')[1],
                view: data?.view,
                file:fileData
            };
            if (accept) {
                if (accept?.includes(fileData?.name?.split('.')[1])) {
                    setFile(prepapredData);
                    onChange && onChange(prepapredData);
                } else {
                    notification.open({
                        message: t('attached-file-has-invalid-extension'),
                        placement: 'topRight',
                        type: 'error'
                    });
                }
            } else {
                setFile(prepapredData);
                onChange && onChange(prepapredData);
            }
        }
    };

    const btnHandler = () => {
        if (ref && ref?.current) {
            ref?.current?.click();
        }
    };

    const resetFile = () => {
        const emptyFile = document.createElement('input');
        emptyFile.type = 'file';
        if(ref){
            ref.current.files = emptyFile?.files;
        }
    };

    useEffect(() => {
        resetFile();
        console.log('reset')
    }, [resetTrigger]);

    const getAcceptExtensions = () => {
        if (accept && accept?.length > 0) {
            const changedAccept = accept?.map((el) => `.${el.trim()}`);
            return changedAccept?.join(',');
        } else return undefined;
    };
    return (
        <div className={`upload-button-wrapper ${className}`}>
            <input
                ref={externalRef ? externalRef : ref}
                id="upload-button-file"
                type="file"
                accept={getAcceptExtensions()}
                className="file"
                onChange={handleFileChange}
            />
            <button
                className="upload-button-btn"
                onClick={btnHandler}
            >
                {title ? title : children ? children : ''}
            </button>
            <img src={file?.view} alt=""/>
        </div>
    );
};

export default UploadButton;