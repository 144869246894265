import {ICustomGroupParameter, ICustomParameterGroup} from "../../types";

export interface IAddGroupZoneProps{
    type:string
    droppableId:string
    isErrorActiveElement:boolean
    parameter:ICustomGroupParameter
    parameters:ICustomGroupParameter[]
    activeParameter:ICustomGroupParameter
    setIsErrorActiveElement:(status:boolean)=>void
    setParameter:(data:ICustomGroupParameter)=>void
    addParameter:(data:ICustomGroupParameter)=>void
    changeParametersValidStatus:(status:boolean)=>void
    setActiveParameter:(data:ICustomGroupParameter)=>void
}

export interface ICustomGroupProps {
    activeParameter:ICustomGroupParameter
    className?:string
    addParameter:(data:ICustomGroupParameter)=>void
    setIsError?:(status:boolean)=>void
}

export interface IValueRangeItem {
    type:string
    value:any
}

export enum PairsNumberFieldsTypes {
    // LEFT_PAIR_TITLE='leftPairTitle',
    // RIGHT_PAIR_TITLE='rightPairTitle',
    X_TITLE='x_title',
    Y_TITLE='y_title',
    X_RANGE='x_range',
    Y_RANGE='y_range',
    POINTS_NUMBER='points_number'
}

export interface IMadeParametersListProps{
    activeParameterGroup:ICustomParameterGroup
    activeParameter:ICustomGroupParameter
    parameters:ICustomGroupParameter[]
    setActiveParameter:(value:ICustomGroupParameter)=>void
    deleteMadeParameter:(id:string)=>void
    droppableId:string
    type:string
}
