import { CalendarGroupEntityTypes } from "../../../app/types/i-roadmap-calendar";

export function getQuarterData(startDate: Date, endDate: Date): Array<{
  quarter: string,
  year: number,
  type: CalendarGroupEntityTypes,
  index: number,
  label: string
}> {
  // Проверка корректности входных данных
  if (!(startDate instanceof Date) || !(endDate instanceof Date) || startDate > endDate) {
    throw new Error('Invalid dates');
  }

  const quarters = [];
  let current = new Date(startDate);

  // Функция для получения начала квартала
  const getQuarterStartDate = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    let quarterStartMonth: number;

    if (month < 3) quarterStartMonth = 0; // Q1
    else if (month < 6) quarterStartMonth = 3; // Q2
    else if (month < 9) quarterStartMonth = 6; // Q3
    else quarterStartMonth = 9; // Q4

    return new Date(year, quarterStartMonth, 1);
  };

  // Основной цикл для создания объектов кварталов
  while (current <= endDate) {
    const quarterStartDate = getQuarterStartDate(current);
    const quarterEndDate = new Date(quarterStartDate);
    quarterEndDate.setMonth(quarterEndDate.getMonth() + 3);
    quarterEndDate.setDate(quarterEndDate.getDate() - 1);

    // Проверка, если конец квартала превышает конечную дату
    if (quarterStartDate > endDate) break;

    quarters.push({
      quarter: `Q${Math.ceil((quarterStartDate.getMonth() + 1) / 3)}`,
      label: 'quarter',
      type: 'quarter',
      index: Math.ceil((quarterStartDate.getMonth() + 1) / 3),
      year: quarterStartDate.getFullYear(),
    });

    // Перемещение к следующему кварталу
    current = new Date(quarterEndDate);
    current.setDate(current.getDate() + 1);  // Сдвиг на следующий день, чтобы не пропустить квартал
  }

  // Проверка и добавление последнего квартала, если необходимо
  if (quarters.length > 0) {
    const lastQuarter = quarters[quarters.length - 1];
    const lastQuarterEndDate = new Date(lastQuarter.year, lastQuarter.index * 3, 0);

    if (lastQuarterEndDate < endDate) {
      const nextQuarterStartDate = new Date(lastQuarterEndDate);
      nextQuarterStartDate.setDate(nextQuarterStartDate.getDate() + 1);

      const newQuarter = {
        quarter: `Q${Math.ceil((nextQuarterStartDate.getMonth() + 1) / 3)}`,
        label: 'quarter',
        type: 'quarter',
        index: Math.ceil((nextQuarterStartDate.getMonth() + 1) / 3),
        year: nextQuarterStartDate.getFullYear(),
      };

      quarters.push(newQuarter);
    }
  }

  return quarters;
}
  