import React, { CSSProperties, FC, useState } from 'react'
import SimpleBar from 'simplebar-react'
import './dopdownMenuItem.css'
import TagsArrowIcon from '../../image_files/icons/TagsArrowIcon'
import { ITag } from '../../app/types/models/tasks'

interface IProps {
    title: string
    index?: number
    autoHide?: boolean
    simpleBarStyle?: object
    dropdownItemStyle?: (value: any, name: string) => CSSProperties
    dropdownItemClassName?: string
    containerClassName?: string
    dropdownItems: any
    tags?: ITag[]

    onClick: (name: string) => void
}

const DropdownMenuItem: FC<IProps> = ({
    title,
    index,
    autoHide,
    simpleBarStyle,
    dropdownItems,
    dropdownItemStyle, onClick,
    dropdownItemClassName,
    containerClassName,
    tags,
}) => {
    const [moveArrow, setMoveArrow] = useState(false)

    const renderElements = () => {
        if (
            dropdownItems &&
            Array.isArray(dropdownItems) &&
            !dropdownItems.every((item) => item?.items)
        ) {
            const filteredItems = dropdownItems.filter(
                (f) => f?.name !== 'without status',
            )
            return filteredItems.map((n) => {
                return (
                    <div
                        key={n?.id}
                        className={
                            dropdownItemClassName
                                ? `dropdownItem ${dropdownItemClassName}`
                                : 'dropdownItem'
                        }
                        style={
                            dropdownItemStyle
                                ? dropdownItemStyle(tags, n)
                                : {}
                        }
                        onClick={() => onClick(n)}
                    >
                        {/*{ type === 'tasks' ? n : n.name }*/}
                        {n?.name}
                    </div>
                )
            })
        } else if (
            dropdownItems &&
            dropdownItems.every((item) => item?.items)
        ) {
            const filteredItems = dropdownItems.filter(
                (f) => f?.name !== 'without status',
            )
            return filteredItems.map((n) => {
                return (
                    <div
                        key={n?.id}
                        className={
                            dropdownItemClassName
                                ? `dropdownItem ${dropdownItemClassName}`
                                : 'dropdownItem'
                        }
                        style={
                            dropdownItemStyle
                                ? dropdownItemStyle(tags, n?.name)
                                : {}
                        }
                        // onClick={ () => dropDownItemOnClick(n?.name) }
                    >
                        {/*{ type === 'tasks' ? n : n.name }*/}
                        {n?.name}
                    </div>
                )
            })
        } else {
            return <div>...данных нет</div>
        }
    }
    return (
        <div>
            <div
                key={index}
                className={
                    containerClassName
                        ? `dropdownMenuItem ${containerClassName}`
                        : 'dropdownMenuItem'
                }
                onMouseOver={() => setMoveArrow(true)}
                onMouseLeave={() => setMoveArrow(false)}
            >
                {title}
                <button>
                    <TagsArrowIcon
                        style={
                            moveArrow
                                ? { transform: 'rotate(180deg)' }
                                : { transform: 'rotate(0deg)' }
                        }
                        className={'dropdownMenuItem-icon'}
                    />
                </button>
                <SimpleBar
                    className="dropdownMenuItem-list"
                    autoHide={autoHide ? autoHide : false}
                    style={simpleBarStyle}
                >
                    {renderElements()}
                </SimpleBar>
            </div>
        </div>
    )
}

export { DropdownMenuItem }
