import {IMnemonic} from "../../../../../../app/types/models/IMnemonic";

export interface ITableItemProps {
    data: IMnemonic
    mnemonics:IMnemonic[]
    activeLang:'ru' | 'en'
    orderIndex:number

    onSave:(id:number,data:IMnemonic)=>void
    onDelete: (id: number,isValid:boolean) => void
    setMnemonicStatus:(data:{id:number,status:'not completed' | 'ready'})=>void
}

export enum MnemColumnTypes{
    log='log',
    description='inlog_description',
    unit='inlog_unit_and_conversion',
    mnemonic='inlog_mnemonic',
    mnemonics='inlog_mnemonics',

}