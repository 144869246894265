import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { UserAvatar } from '../userAvatar/UserAvatar'
import React from 'react'
import { UserMenuSettingIcon } from '../../image_files/icons/UserMenuSettingIcon'
import { getLocalizedText } from '../../utils/helpers/getLocalizedText'
import { LogoutIcon } from '../../image_files/icons/LogoutIcon'
import { userSelectors } from '../../store/user'
import { ACCESS_TOKEN, routes } from '../../utils/constants'
import './profileMenu.css'
import { IUser } from '../../app/types/models/users'
import { useTranslation } from 'react-i18next'

function ProfileMenu() {
    const user: IUser = useSelector(userSelectors.selectUser)
    const { t } = useTranslation()

    const logOutUser = () => {
        document.cookie = 'csrftoken=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        localStorage.removeItem(ACCESS_TOKEN)
    }

    return (
        <div className="userPersonalWindow">
            <Link to={routes.LOGGING} className="userPersonalWindow__name">
                <UserAvatar />
                <p className={'userPersonalWindow__text'}>
                    <div>{`${user?.first_name ? user?.first_name : ''} ${
                        user?.last_name ? user?.last_name : ''
                    }`}</div>
                    <div>{user?.email}</div>
                </p>
            </Link>
            <Link to={routes.SETTINGS} className="userPersonalWindow__settings">
                <div>
                    <div>
                        <UserMenuSettingIcon />
                    </div>
                </div>
                <p>
                    {t('settings')}
                </p>
            </Link>
            <Link
                onClick={logOutUser}
                to={routes.LOGIN}
                className="userPersonalWindow__logout"
            >
                <LogoutIcon />
                <div onClick={logOutUser}>
                    {getLocalizedText('log-out', t)}
                </div>
            </Link>
        </div>
    )
}

export default ProfileMenu
