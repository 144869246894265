import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { PlusIcon } from '../../../../image_files/icons/PlusIcon'
import SettingCancelIcon from '../../../../image_files/icons/SettingCancelIcon'
import './UnitWindow.scss'
import { useTranslation } from 'react-i18next'
import {
    IUnitConversion,
    IUnitItem,
} from '../../../../app/types/models/IMnemonic'
import useDebounce from '../../../../hooks/useDebounce'
import { FieldType, IUnitWindowProps } from './types'

export const UnitWindow: FC<IUnitWindowProps> = (props) => {
    const { data, currentLanguage, activeLang, onChange } = props
    const { t, i18n } = useTranslation()
    const [lang, setLang] = useState(currentLanguage)
    const [localeLang, setLocaleLang] = useState(i18n.language)
    const [fetchedData, setFetchedData] = useState<IUnitItem[]>(
        lang === 'ru' ? data?.ru : data?.en,
    )
    const debouncedData = useDebounce(fetchedData, 500)

    const onClickAddItem = () => {
        if (fetchedData && fetchedData?.length > 0) {
            setFetchedData([
                ...fetchedData,
                {
                    scale: '',
                    value: '',
                    id: fetchedData[fetchedData.length - 1].id + 1,
                },
            ])
        } else {
            setFetchedData([{ scale: '', value: '', id: 1 }])
        }
    }
    const onClickDeleteItem = (id: number) => {
        setFetchedData(fetchedData.filter((item) => item.id !== id))
    }

    const changeFieldValue = (
        e: ChangeEvent<HTMLInputElement>,
        id: number,
        field: FieldType,
    ) => {
        setFetchedData(
            fetchedData &&
                fetchedData.map((item) =>
                    item.id === id
                        ? {
                              ...item,
                              [`${field}`]: e.currentTarget.value,
                          }
                        : item,
                ),
        )
    }

    const setInputStyle = (value: string) => {
        if (value.length === 0) {
            return {
                border: '1px solid var(--main-text-color)',
                borderRadius: '5px',
            }
        }
    }

    useEffect(() => {
        setLang(currentLanguage)
    }, [currentLanguage])

    useEffect(() => {
        setLang(activeLang)
    }, [activeLang])

    useEffect(() => {
        setLocaleLang(activeLang)
    }, [activeLang])

    useEffect(() => {
        setLocaleLang(i18n.language)
    }, [i18n.language])

    useEffect(() => {
        if (debouncedData) {
            if (JSON.stringify(data) !== JSON.stringify(debouncedData)) {
                onChange(debouncedData, lang)
            }
        }
    }, [debouncedData])

    //1 помещаю данные в отдельный стейт
    useEffect(() => {
        setFetchedData(lang === 'ru' ? data?.ru : data?.en)
    }, [data, lang])

    //2 prepare date for render
    const getPreparedToRenderData = () => {
        if (fetchedData && Array.isArray(fetchedData)) {
            return fetchedData.map((item: IUnitConversion) => ({
                from: item?.scale,
                to: item?.scale,
                coefficient: item?.value,
                id: item?.id,
            }))
        } else return []
    }

    return (
        <div className={'unitWindow'}>
            <div className={'unitWindow__top'}>
                <div>
                    {t('from', {
                        lng: localeLang,
                    })}
                </div>
                <div>
                    {t('to', {
                        lng: localeLang,
                    })}
                </div>
                <div>
                    {t('coef', {
                        lng: localeLang,
                    })}
                </div>
                <button onClick={onClickAddItem}>
                    <PlusIcon />
                </button>
            </div>
            <div className={'unitWindow__content'}>
                {getPreparedToRenderData().map((item, index, arr) => (
                    <div key={index} className={'unitWindow__item'}>
                        <input
                            type="text"
                            value={item?.from}
                            className={'unitWindow__input'}
                            style={setInputStyle(item?.from)}
                            onChange={(e) =>
                                changeFieldValue(e, item?.id, 'scale')
                            }
                        />
                        <input
                            type="text"
                            value={arr[0].to}
                            className={'unitWindow__input'}
                            onChange={(e) =>
                                changeFieldValue(e, arr[0]?.id, 'scale')
                            }
                        />
                        <input
                            type="number"
                            value={item?.coefficient}
                            className={'unitWindow__input'}
                            style={setInputStyle(item?.coefficient.toString())}
                            onChange={(e) =>
                                changeFieldValue(e, item?.id, 'value')
                            }
                        />
                        {item?.id !== arr[0]?.id && (
                            <button onClick={() => onClickDeleteItem(item?.id)}>
                                <SettingCancelIcon
                                    width={18}
                                    height={18}
                                />
                            </button>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}
