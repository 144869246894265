import { Space } from 'antd'
import 'antd/dist/antd.compact.min.css'
import en_EN from 'antd/es/date-picker/locale/en_US'
import ru_RU from 'antd/es/date-picker/locale/ru_RU'
import moment from 'moment'
import 'moment/locale/en-au'
import 'moment/locale/ru'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getLocalizedText } from '../../utils/helpers/getLocalizedText'
import DatePickerInlog from '../datePicker/DatePicker'
import './../../index.css'
import './index.css'

interface IProps {
    initialDate: { due_date_start: string; due_date_end: string }
    startDateHandler: ({ due_date_start }) => void
    endDateHandler: ({ due_date_end }) => void
}

export const CustomDatePicker: FC<IProps> = ({
    startDateHandler,
    endDateHandler,
    initialDate,
}) => {
    const [currentDate, setCurrentDate] = useState(initialDate)
    const { i18n, t } = useTranslation()

    useEffect(() => {
        setCurrentDate(initialDate)
    }, [initialDate])
    const setStartDate = (value) => {
        const newDate = moment(new Date(value)).format(
            'YYYY-MM-DDTHH:mm:ss.sssZ',
        )
        startDateHandler({ due_date_start: newDate })
    }
    const setEndDate = (value) => {
        const newDate = moment(new Date(value)).format(
            'YYYY-MM-DDTHH:mm:ss.sssZ',
        )
        endDateHandler({ due_date_end: newDate })
    }

    //подставляю дату следующего дня чтобы сделать интервал в 1 сутки. Это дефолтный интервал при создании таски
    const getNextDayDate = () => {
        const today = moment().toLocaleString()
        let D = new Date(today)
        D.setDate(D.getDate() + 1).toLocaleString()
        return D
    }

    const getDefaultDateEnd = () => {
        const startDate = moment(new Date(currentDate.due_date_start)).format(
            'DD-MM-YYYY HH:mm',
        )
        const endDate = moment(new Date(currentDate.due_date_end)).format(
            'DD-MM-YYYY HH:mm',
        )
        if (currentDate.due_date_end !== null && startDate !== endDate) {
            return moment(currentDate.due_date_end).locale(i18n.language)
        } else {
            return moment(getNextDayDate())
        }
    }

    return (
            <div>
                <div className="datePicker-from">
                    <span>
                        {getLocalizedText(
                            'from',
                            t,
                        )}
                    </span>
                    <Space direction="vertical" size={12}>
                        <DatePickerInlog
                            locale={i18n.language === 'ru' ? ru_RU : en_EN}
                            defaultValue={
                                currentDate.due_date_start !== null
                                    ? moment(currentDate.due_date_start).locale(
                                          i18n.language,
                                      )
                                    : moment()
                            }
                            format={'DD-MM-YYYY HH:mm'}
                            showTime
                            onChange={setStartDate}
                        />
                    </Space>
                </div>
                <div className="datePicker-after">
                    <span>
                        {getLocalizedText(
                            'till',
                            t,
                        )}
                    </span>
                    <Space direction="vertical" size={12}>
                        <DatePickerInlog
                            format={'DD-MM-YYYY HH:mm'}
                            className="datePicker-item"
                            showTime
                            onChange={setEndDate}
                            locale={i18n.language === 'ru' ? ru_RU : en_EN}
                            defaultValue={getDefaultDateEnd()}
                        />
                    </Space>
                </div>
            </div>
    )
}
