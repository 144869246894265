import { entitiesServices } from '../../../../../../../services/entities-services'
import { TargetParameters } from '../../../../../../../app/types/enums'
import { IResearchEntity } from '../../../../../../../app/types/models/logging'
import { toast } from 'react-toastify'

interface IIncomeData {
    id: number
    parameter: TargetParameters
    type: 'method' | 'equipment'
    setData: (value: IResearchEntity[]) => void
}

export const getEntity = async (values: IIncomeData) => {
    try {
        if (values.type === 'method') {
            const data = await entitiesServices.getMethod(values.id, {
                target_parameter: values.parameter,
            })
            values.setData(data)
        } else {
            const data = await entitiesServices.getEquipment(values.id, {
                target_parameter: values.parameter,
            })
            values.setData(data)
        }
    } catch (e) {
        console.log(e)
        toast.error(e.message, { position: 'top-center' })
    }
}
