import React, {FC, useEffect} from 'react';
import {INotificationProps} from "./types";
import './notification.css';
import {getCorrectDateFormat} from "../../utils/getCorrectDateFormat";
import {Button} from "antd";
import {useTranslation} from "react-i18next";
import {XIcon} from "../../image_files/icons/XIcon";
import {RequestAction} from "../../app/types/enums";
import Person from "../../shared/person/Person";
import {NoticeTypes} from "../noticeBlock/types";
import NotificationDescription from "./NotificationDescription";

const typesForButtons = [NoticeTypes.ProjectUserInvitation, NoticeTypes.RoleRequest]
const typesToShowBlocks = [...typesForButtons, NoticeTypes.ProjectUserInvitationResponse, NoticeTypes.RoleRequestResponse, NoticeTypes.ProjectLeaving, NoticeTypes.AdminRightsTransfer, NoticeTypes.ProjectUserRemoval, NoticeTypes.RoleChangeByAdmin]

const Notification: FC<INotificationProps> = (props) => {
    const {item, isModalItem, deleteBlock, sendNoticeResponse,readNotification} = props
    const {t} = useTranslation()

    const sendResponse = async (action: RequestAction) => {
        sendNoticeResponse && sendNoticeResponse(item, action)
    }

    const deleteNotice = () => {
        deleteBlock && deleteBlock(item?.id)
    }

    const getValidTitle = () =>{
        switch(item?.type){
            case 'project_user_invitation':
                return t('invitation-to-project')
            case 'project_user_invitation_response':
                return t('Ответ на приглашение в проект')
            case 'role_request':
                return t('role-request')
            case 'role_request_response':
                return t('response-on-role-request')
            case 'project_leaving':
                return t('existing-the-project')
            case 'role_change':
                return t('role-changing')
            case 'project_user_removal':
                return t('project-user-removal')
            case 'admin_rights_transfer':
                return t('admin-rights-transfer')
            case 'general_task_update':
                return t('general-tasks-update')
                default:
                    return ''
        }
    }

    const noticeInfoBlock = (
        <div className={'notification-top row-flex-sb'}>
            <div className={'notification-top-project-name font-15-normal'}>
                <span
                    className={'dotted-text-250'}>{getValidTitle()}</span>
                <span className={'dotted-text-150'}>[{item?.data?.project?.name}]</span>
            </div>
        </div>
    )

    const taskChangesBlock = (
        <>
            {noticeInfoBlock}
            <div className={'notification-task-changes-block'}>
                <div dangerouslySetInnerHTML={{__html: item?.data?.html}}/>
            </div>
        </>
    )

    const readNotificationHandler = () =>{
        if(!item.is_read){
            readNotification && readNotification(item.id)
        }
    }

    useEffect(()=>{
        const targetNode = document.getElementsByClassName('top__projectCode')
        console.log(targetNode)
    },[item])



    return (
        <div className={`notification cursor-pointer br-5 p-10 c ${!item?.is_read ? 'notification-unread' : ''}`} onMouseOver={readNotificationHandler}>
            <div className={'font-15-normal notification-date'}>
                {getCorrectDateFormat({date:item?.created_at, isShowTime:!(isModalItem)})}</div>
            <XIcon width={13} height={13} onClick={deleteNotice} className={'notification-top-cancel-icon'}/>
            {
                typesToShowBlocks.includes(item?.type)
                    ? noticeInfoBlock
                    : taskChangesBlock
            }
            {
                typesToShowBlocks.includes(item?.type) &&
                <div className={'mt-10 row-flex-10'}>
                    <Person firstName={item?.sender?.first_name} lastName={item?.sender?.last_name}
                            avatar={item?.sender?.avatar} email={item?.sender?.email}/>
                </div>
            }
            <div className={'font-15-normal mt-10'}>
                {typesToShowBlocks.includes(item?.type) && <NotificationDescription item={item}/>}
            </div>
            {
                typesForButtons.includes(item?.type) &&
                <div className={'mt-10 row-flex-10 notification-buttons'}>
                    <Button
                        onClick={() => sendResponse(RequestAction.accept)}>{t('accept').toString()}</Button>
                    <Button
                        onClick={() => sendResponse(RequestAction.reject)}>{t('reject').toString()}</Button>
                </div>
            }
        </div>
    )
};

export default Notification;