import React, { FC, useEffect, useState } from 'react'
import './select.scss'
import { Select, SelectProps } from 'antd'
import { ReactComponent as SelectIcon } from '../../image_files/icons/selectIcon.svg'
import { getValidText } from '../../utils/helpers/getValidText'
import TagsArrowIcon from '../../image_files/icons/TagsArrowIcon'

interface IProps extends SelectProps {
    isMovedDropdownArrow?:boolean
    onBlur?:()=>void
    onClick?:()=>void
}

const SelectInLog: FC<IProps> = (props) => {
    const {
        mode,
        open,
        value,
        options,
        className,
        direction='ltr',
        suffixIcon,
        allowClear,
        placeholder,
        maxTagCount,
        defaultValue,
        isMovedDropdownArrow=true,
        disabled,
        onBlur,
        onClick,
        onChange,
    } = props

    const [isOpenStatus,setIsOpenStatus] = useState(false)

    const handleClick = () =>{
        setIsOpenStatus(prev=>!prev)
        onClick && onClick()
        
    }
    const handleBlur = () =>{
        setIsOpenStatus(false)
        onBlur && onBlur()
    }

    const getSuffixIcon = () =>{
        if(isMovedDropdownArrow) return <></>
        if(suffixIcon) return suffixIcon
        return undefined
    }

    useEffect(()=>{
        if(open!==undefined){
            setIsOpenStatus(open)
        }
    },[open])

    return (
        <div className={'select-wrapper'}>
            {isMovedDropdownArrow && (
                <TagsArrowIcon
                    className={`select-wrapper-moved-icon select-wrapper-moved-icon-${isOpenStatus ? 'opened' : 'closed'}`}
                />
            )}

            <Select
                mode={mode}
                value={value}
                bordered={false}
                options={options}
                open={isOpenStatus}
                disabled={disabled}
                direction={direction}
                allowClear={allowClear}
                maxTagCount={maxTagCount}
                placeholder={placeholder}
                defaultValue={defaultValue}
                suffixIcon={getSuffixIcon()}
                className={`select ${getValidText(className)}`}
                optionLabelProp={''}

                onChange={onChange}
                onBlur={handleBlur}
                onClick={handleClick}
            />
        </div>
    )
}

export default SelectInLog
