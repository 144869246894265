import React, {FC, useState} from 'react';
import {LogsSettingIcon} from "../../../../../../image_files/icons/LogsSettingIcon";
import {MatchSettings} from "../matchSettings/MatchSettings";
import {useTranslation} from "react-i18next";
import {GisTable} from "../gisTable/GisTable";
import {ILogsDataBaseTabsProps} from "./types";

export const LogsDataBaseTabs:FC<ILogsDataBaseTabsProps> = ({assessmentStatus}) => {
    const [activeTabId, setActiveTabId] = useState<1 | 2>(1)
    const {t} = useTranslation()

    const renderBlockById = () => {
        if (activeTabId === 1) {
            return <MatchSettings/>
        } else {
            return <GisTable assesmentStatus={assessmentStatus}/>
        }
    }

    const setActiveClassName = (id:number)=> activeTabId === id
        ? {className:'row-flex-5 cursor-pointer text-underline'} : {className:'row-flex-5 cursor-pointer'}

    return (
        <div className='mt-30'>
            <div className={'row-flex-32'}>
                <div {...setActiveClassName(1)} onClick={() => setActiveTabId(1)}>
                    <span className={'font-14-normal'}>{t('db-settings')}</span>
                    <LogsSettingIcon />
                </div>
                <div {...setActiveClassName(2)} onClick={() => setActiveTabId(2)}>
                    <span className={'font-14-normal'}>{t('logs-to-inlog')}</span>
                </div>
            </div>
            <div className={'mt-15'}>
                {renderBlockById()}
            </div>
        </div>
    );
};

