import { Skeleton } from "antd";
import React from "react";

const LoggingProductSceleton = () => {
    return (
        <div>
            <div className={'core-item--skeleton'}>
                <Skeleton.Input active />
            </div>
            <div className={'core-item--skeleton'}>
                <Skeleton.Input active />
            </div>
            <div className={'core-item--skeleton'}>
                <Skeleton.Input active />
            </div>
        </div>
    )
}

export default LoggingProductSceleton;