import {AxiosResponse} from "axios";
import {ACCESS_TOKEN} from "../utils/constants";
import {instance} from "../app/api";
import {InvitationTokenResponse, IUserInvitation} from "../app/types/dto/user-invitation";

export const invitationService = {
    inviteByEmail:async (projectId:number,data:IUserInvitation)=>{
        return await instance
            .post<undefined, AxiosResponse<IUserInvitation>>(
                `projects/${projectId}/invitation/`,data,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    addInvitationToken:async (projectId:number,data:{role:string})=>{
        return await instance
            .post<undefined, AxiosResponse<InvitationTokenResponse>>(
                `projects/${projectId}/invitation-token/`,data,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    addUserInvitation:async (projectId:number,data:{token:string})=>{
        return await instance
            .post<undefined, AxiosResponse<{token:string}>>(
                `projects/${projectId}/invitation-token/process/`,data,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
}