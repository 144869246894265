import { Button, Modal } from 'antd'
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactCrop, { Crop, centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop'
import useDebounce from '../../hooks/useDebounce'
import { XIcon } from '../../image_files/icons/XIcon'
import setCanvasPreview from './services/set-canvas-preview'
import { ICoreBoxSaveModalProps } from './types'

const ASPECT_RATIO = 1;

const CropImageModal: FC<ICoreBoxSaveModalProps> = (props) => {
  const {url,title, isOpenModal,reopenTrigger, onSave, setIsOpenModal } = props

  const {t} = useTranslation()
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const [localUrl, setLocalUrl] = useState(null);
  const [crop, setCrop] = useState<Crop | undefined>({
    unit: '%',
    x: 0,
    y: 0,
    width: 100,
    height: 100
  });

  const debouncedCrop = useDebounce(crop, 500);

  const onImageLoad = (e: ChangeEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;

    const targetCrop = makeAspectCrop(
      {
        unit: '%',
        x: 0,
        y: 0,
        width: 100,
        height: 100
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(targetCrop, width, height);
    setCrop(centeredCrop);
  };

  const handleSave = () =>{
    if(localUrl){
      onSave(localUrl)
    }
  }

  useEffect(() => {
    if (
      imgRef &&
      previewCanvasRef &&
      previewCanvasRef?.current &&
      imgRef?.current &&
      crop
    ) {
      setCanvasPreview(
        imgRef.current, // HTMLImageElement
        previewCanvasRef.current, // HTMLCanvasElement
        convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
      );
      const dataUrl = previewCanvasRef.current.toDataURL();

      if (dataUrl) {
        setLocalUrl(dataUrl);
      }
    }
  }, [debouncedCrop,reopenTrigger,url]);

  return (
    <>
      <Modal
        title={title ? title : t('saving-image')}
        wrapClassName={'canvas-component-modal'}
        open={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        footer={(
          <div className='canvas-component-modal-footer-content'>
            <Button onClick={() => setIsOpenModal(false)}>{t('cancel')}</Button>
            <Button type='primary' onClick={handleSave} className='canvas-component-modal-save-btn'>{t('save')}</Button>
          </div>
        )}
        closeIcon={
          <XIcon
            width={13}
            height={13}
            className={'notification-top-cancel-icon'}
          />
        }
      >
        <ReactCrop
          crop={crop}
          keepSelection
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          // circularCrop
          ruleOfThirds
        >
          <img
            ref={imgRef}
            src={url}
            alt="profile photo"
            className="webcam-camera-img"
            onLoad={onImageLoad}
          />
        </ReactCrop>
      </Modal>
      {crop && (
        <canvas
          ref={previewCanvasRef}
          style={{
            display: 'none',
            border: '1px solid black',
            objectFit: 'contain',
            width: 150,
            height: 150
          }}
        />
      )}
    </>
  )
}

export default CropImageModal