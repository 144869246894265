import { slice } from './members-slice'
import * as membersSelectors from './selectors'
import * as actions from './actions'

const membersActions = {
    ...slice.actions,
    ...actions,
}

const membersReducer = slice.reducer

export { membersActions, membersReducer, membersSelectors }
