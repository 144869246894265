import {instance} from "../app/api";
import {AxiosResponse} from "axios";
import {ACCESS_TOKEN} from "../utils/constants";
import {IPad, IWell} from "../app/types/models/logging";

export const createFromFileService ={
    createWellFile:async (projectId:number, data:FormData)=>{
        return await instance.post<{projectId: number,data: FormData },
            AxiosResponse<IWell[]>>(`projects/${projectId}/wells/well/create-from-file/`, data, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
                'Content-type': 'application/json; charset=utf-8'
            },
        })
            .then((res) => res.data)
    },
    createFromNamesFile:async (projectId:number, data:FormData)=>{
        return await instance.post<{projectId: number,data: FormData },
            AxiosResponse<IWell[]>>(`projects/${projectId}/wells/well/create-from-names-file/`, data, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
                'Content-type': 'application/json; charset=utf-8'
            },
        })
            .then((res) => res.data)
    },
    createPadFile:async (projectId:number, data:FormData)=>{
        return await instance.post<{projectId: number,data: FormData },
            AxiosResponse<IPad[]>>(`projects/${projectId}/wells/pad/create-from-file/`, data, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        })
            .then((res) => res.data)
    },
    createCoreFile:async (projectId:number, data:FormData,path:string)=>{
        return await instance.post<{projectId: number,data: FormData },
            AxiosResponse<IPad[]>>(`projects/${projectId}/wells/core/${path}/`, data, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        })
            .then((res) => res.data)
    },

}
