import { Button, Col, Form } from 'antd'
import moment from 'moment'
import React, {
    ChangeEvent,
    KeyboardEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SimpleBar from 'simplebar-react'
import { IValidateStatementFileResponse } from '../../../../../../../../app/types/dto/validate-file-dto'
import { ICore } from '../../../../../../../../app/types/models/logging'
import { useAppDispatch } from '../../../../../../../../hooks/redux-hooks'
import { createFromFileService } from '../../../../../../../../services/create-from-file-service'
import { projectsService } from '../../../../../../../../services/projects-service.'
import { wellsService } from '../../../../../../../../services/wells-service'
import DatePickerInlog from '../../../../../../../../shared/datePicker/DatePicker'
import DownloadIconButton from '../../../../../../../../shared/downloadIconButton/DownloadIconButton'
import FormItem from '../../../../../../../../shared/formItem/FormItem'
import InputInLog from '../../../../../../../../shared/input/Input'
import ModalInlog from '../../../../../../../../shared/modalInlog/ModalInlog'
import PopoverInLog from '../../../../../../../../shared/popoverInlog/PopoverInLog'
import SpinInLog from '../../../../../../../../shared/spin/spin'
import {
    currentProjectActions,
    currentProjectSelectors,
} from '../../../../../../../../store/currentProject'
import {
    setCurrentCores,
    setCurrentWells,
    setLoadingCurrentItemStatus,
} from '../../../../../../../../store/currentProject/actions'
import {
    selectCurrentCores,
    selectCurrentProject,
    selectCurrentWells,
} from '../../../../../../../../store/currentProject/selectors'
import { deleteWellCore } from '../../../../../../../../store/projects/actions'
import { errorsHandler } from '../../../../../../../../utils/helpers/errors/errors-hendler'
import { showNotice, showNotify } from '../../../../../../../../utils/helpers/showNotice'
import { directionOptions } from '../../helpers/defaultCoresData'
import { CorePhoto } from './components/corePhoto/CorePhoto'
import './coreForm.css'
import { colProps } from './data'
import { ICoreForm } from './types'

const initialDoubleFieldsData = {
    agreement: '',
    agreement_date: null,
    top: '',
    bottom: '',
}

const CoreForm = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const inputRef = useRef<HTMLInputElement>()
    const [loading, setLoading] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [fileData, setFileData] = useState<any>(null)
    const { updateCurrentCoreInfo } = currentProjectActions
    const [isChangedField, setIsChangedField] = useState(false)
    const [form] = Form.useForm()
    const [doubleFieldsData, setDoubleFieldsData] = useState(
        initialDoubleFieldsData,
    )
    const [isLoadingValidateFile, setIsLoadingValidateFile] = useState(false)
    const currentProject = useSelector(selectCurrentProject)
    const cores = useSelector(selectCurrentCores)
    const wells = useSelector(selectCurrentWells)
    const currentPad = useSelector(currentProjectSelectors.selectCurrentPad)
    const currentWell = useSelector(currentProjectSelectors.selectCurrentWell)
    const currentWellBore = useSelector(
        currentProjectSelectors.selectCurrentWellBore,
    )
    const currentCore = useSelector(currentProjectSelectors.selectCurrentCore)
    const [modalData, setModalData] = useState<string[]>([])
    const [overLapCores, setOverLapCores] = useState<ICore[]>([])

    const onFormFinish = useCallback(
        async (values: ICoreForm) => {
            const data = { ...values, ...doubleFieldsData }
            try {
                //отправляем либо керн от скважины с id скважины либо керн от ствола с соотв Id
                if (currentWell?.id && !currentWellBore?.id) {
                    updateCore('well', currentWell?.id, data)
                } else if (!currentWell?.id && currentWellBore?.id) {
                    updateCore('wellbore', currentWellBore?.id, data)
                } else {
                    console.log('error')
                }
            } catch (e) {
                errorsHandler(e, t)
            }
        },
        [
            dispatch,
            form,
            currentWell,
            currentCore,
            currentWellBore,
            isChangedField,
            doubleFieldsData,
        ],
    )

    const onChangeSelectValue = (e: string, name?: string) => {
        setIsChangedField(true)
        if (name) {
            form.setFieldValue(name, e)
        }
    }

    const onChangeInputValue = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        name?: string,
    ) => {
        setIsChangedField(true)
        if (name) {
            form.setFieldValue(name, `${e.target.value}`)
        }
    }

    const updateCore = async (
        fieldName: string,
        id: number,
        values: any,
    ): Promise<void> => {
        try {
            setLoading(true)
            const requestBody = {
                ...values,
                [fieldName]: id,
            }
            const { data } = await projectsService.updateCore(
                currentProject?.id,
                currentCore?.id,
                requestBody,
            )
            dispatch(updateCurrentCoreInfo({ id: currentCore?.id, core: data }))
            showNotify(t('data-saved'))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            errorsHandler(e, t)
        }
    }

    const handleFile = async (e) => {
        let selectedFile = e.target.files[0]
        if (selectedFile) {
            setFileData(selectedFile)
            try {
                setIsLoadingValidateFile(true)
                const formData = new FormData()
                formData.append('file', selectedFile)
                const responseValidatedData =
                    await wellsService.validateWellLogFile(
                        currentProject?.id,
                        formData,
                    )
                if (responseValidatedData) {
                    checkCores(responseValidatedData, selectedFile)
                    resetFile()
                    showNotify(t('file-validated-successfully'))
                }
                resetFile()
                setIsLoadingValidateFile(false)
            } catch (error) {
                setIsLoadingValidateFile(false)
                resetFile()
                errorsHandler(error, t)
            }
        }
    }

    const deleteOverlapsCores = async () => {
        try {
            if (overLapCores && overLapCores.length > 0) {
                for (let i = 0; i < overLapCores.length; i++) {
                    const targetCore = overLapCores[i]
                    await projectsService.deleteCore(
                        currentProject?.id,
                        targetCore.id,
                    )
                    dispatch(
                        deleteWellCore({
                            id: currentProject?.id,
                            wellId: targetCore.well,
                            padId: targetCore.well_pad,
                            coreId: targetCore.id,
                        }),
                    )
                }
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const updateCoresFromFile = async (file?: File) => {
        try {
            setIsLoadingValidateFile(true)
            await deleteOverlapsCores()
            const formData = new FormData()
            if (file) {
                formData.append('file', file)
            } else if (fileData) {
                formData.append('file', fileData)
            }

            if (currentPad) {
                formData.append('well_pad', currentPad?.id?.toString())
            }
            if (currentWell) {
                formData.append('well', currentWell?.id?.toString())
            }
            if (currentWellBore) {
                formData.append('wellbore', currentWellBore?.id?.toString())
            }
            await createFromFileService.createCoreFile(
                currentProject?.id,
                formData,
                'create-from-statement-file',
            )
            dispatch(
                setLoadingCurrentItemStatus({
                    status: true,
                    type: 'currentCores',
                }),
            )
            dispatch(
                setLoadingCurrentItemStatus({
                    status: true,
                    type: 'currentWells',
                }),
            )
            const coresResponse = await projectsService.getCores(
                currentProject?.id,
            )
            const wellsResponse = await wellsService.getWells(
                currentProject?.id,
            )
            setOpenModal(false)
            dispatch(setCurrentCores({ cores: coresResponse }))
            dispatch(setCurrentWells({ wells: wellsResponse }))
            setLoading(false)
            showNotify(t('data-saved-successfully'))
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentCores',
                }),
            )
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentWells',
                }),
            )
            setIsLoadingValidateFile(false)
        } catch (error) {
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentCores',
                }),
            )
            dispatch(
                setLoadingCurrentItemStatus({
                    status: false,
                    type: 'currentWells',
                }),
            )
            errorsHandler(error, t)
            setIsLoadingValidateFile(false)
        }
    }

    const checkCores = async (
        data: IValidateStatementFileResponse[],
        file: File,
    ) => {
        if (data && Array.isArray(data)) {
            const overlaps = []
            const filteredWells = wells?.filter(
                (well) => well.well_pad === currentPad?.id,
            )
            let filteredCores = []
            if (currentWell) {
                filteredCores = cores.filter(
                    (el) => el.well_pad === currentPad?.id,
                )
            }
            if (
                filteredCores.length > 0 &&
                filteredWells.length > 0 &&
                data.length > 0
            ) {
                let targetOverlapedCores = []
                filteredCores.forEach((core) => {
                    const targetWell = filteredWells.find(
                        (well) => well.id === core?.well,
                    )
                    if (targetWell) {
                        data.forEach((coreFile) => {
                            if (coreFile.well) {
                                if (targetWell?.name === coreFile?.well?.name) {
                                    //overlap for well name
                                    if (coreFile.name === core.name) {
                                        const str = t(
                                            'core-name-in-well-name',
                                            {
                                                core: core?.name,
                                                well: targetWell?.name,
                                            },
                                        )
                                        overlaps.push(str)
                                        targetOverlapedCores.push(core)
                                    }
                                }
                            }
                        })
                    }
                })
                setOverLapCores(targetOverlapedCores)
            }
            if (overlaps.length > 0) {
                setModalData(overlaps)
                setOpenModal(true)
            } else {
                await updateCoresFromFile(file)
            }
        }
    }

    const btnHandler = () => {
        if (inputRef && inputRef?.current) {
            inputRef?.current?.click()
        }
    }

    const resetFile = () => {
        const emptyFile = document.createElement('input')
        emptyFile.type = 'file'
        if (inputRef) {
            inputRef.current.files = emptyFile?.files
        }
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            form.submit()
        }
    }

    //сброс заполненных полей при переходе между кернами
    useEffect(() => {
        form.resetFields()
        setDoubleFieldsData(initialDoubleFieldsData)
        if (currentCore) {
            form.setFieldsValue({
                ...currentCore,
            })
            setDoubleFieldsData({
                agreement: currentCore?.agreement,
                agreement_date: currentCore?.agreement_date,
                top: currentCore?.top,
                bottom: currentCore?.bottom,
            })
        }
    }, [currentCore, currentWell, currentWellBore])

    return (
        <>
            {createPortal(
                <SpinInLog isLoading={isLoadingValidateFile} />,
                document.body,
            )}
            <ModalInlog
                open={openModal}
                onCancel={() => setOpenModal(false)}
                footer={null}
            >
                <div className="core-form-question-modal-text">
                    <div>
                        {t(
                            'lab-samples-with-numbers',
                        )}
                        :
                    </div>
                    <div>
                        {modalData?.map((el, i) => (
                            <div key={i}>{el}</div>
                        ))}
                    </div>
                    <div>
                        {t(
                            'already-added-project-base',
                        )}
                    </div>
                </div>
                <div className="core-form-question-modal-bottom">
                    <Button type="primary" onClick={() => setOpenModal(false)}>
                        {t('cancel-loading')}
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            updateCoresFromFile()
                        }}
                    >
                        {t(
                            'continue-loading-with-updating-info-exepting-core-photo',
                        )}
                    </Button>
                </div>
            </ModalInlog>
            <SimpleBar className={'core-info-scrollbar'}>
                <div className={'coreInfo-scrollbar-inner'}>
                    <div className="core-info-top">
                        <input
                            type="file"
                            className="upload-exel-input"
                            onChange={async (e) => {
                                await handleFile(e)
                            }}
                            ref={inputRef}
                            accept={'.xlsx, .xls'}
                        />
                        {currentWell && (
                            <>
                                <PopoverInLog
                                    content={
                                        <div
                                            className={
                                                'font-12-normal font-white table-popover'
                                            }
                                        >
                                            {t(
                                                'required-file-format-xlsx',
                                            )}
                                        </div>
                                    }
                                >
                                    <Button
                                        type={'primary'}
                                        onClick={btnHandler}
                                        className="upload-exel-btn"
                                    >
                                        {t('upload')}
                                    </Button>
                                </PopoverInLog>
                                <div className="core-info-template">
                                    <DownloadIconButton
                                        className="core-info-download-btn"
                                        href={
                                            '/files/образцы_шаблон_ведомость.xlsx'
                                        }
                                        description={t(
                                            'download-template',
                                        ).toString()}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <Form
                        form={form}
                        layout="horizontal"
                        onFinish={onFormFinish}
                        onFieldsChange={() => {
                            setIsChangedField(true)
                        }}
                        className={'core-info-scrollbar-form'}
                    >
                        <div className={'core-info-items'}>
                            <FormItem
                                loading={loading}
                                name={'statement'}
                                value={form.getFieldValue('statement')}
                                colProps={{ className: 'col-info-form-item' }}
                                label={t(
                                    'statement',
                                ).toString()}
                                onChange={onChangeInputValue}
                            />
                            <FormItem
                                type={'custom'}
                                loading={loading}
                                colProps={{ className: 'col-info-form-item' }}
                                label={`${t('agreement-with-date')}`}
                            >
                                <div className="core-info-double-field-wrapper">
                                    <InputInLog
                                        value={doubleFieldsData['agreement']}
                                        onChange={(e) => {
                                            setDoubleFieldsData({
                                                ...doubleFieldsData,
                                                agreement: e,
                                            })
                                            setIsChangedField(true)
                                        }}
                                        onKeyDownhandler={handleKeyDown}
                                    />
                                    <DatePickerInlog
                                        value={
                                            doubleFieldsData['agreement_date']
                                                ? moment(
                                                      doubleFieldsData[
                                                          'agreement_date'
                                                      ],
                                                  )
                                                : null
                                        }
                                        format={'DD.MM.YYYY'}
                                        placeholder=" "
                                        onChange={(e: any) => {
                                            setDoubleFieldsData({
                                                ...doubleFieldsData,
                                                agreement_date: moment(
                                                    new Date(e),
                                                ).format('YYYY-MM-DD'),
                                            })
                                            setIsChangedField(true)
                                        }}
                                        className="core-info-datePicker"
                                    />
                                </div>
                            </FormItem>
                            <FormItem
                                label={
                                    <span className="core-info-long-label">
                                        {t(
                                            'geological-sample',
                                        )}
                                    </span>
                                }
                                name={'geological_sample'}
                                loading={loading}
                                value={form.getFieldValue('geological_sample')}
                                colProps={{ className: 'col-info-form-item' }}
                                onKeyDown={handleKeyDown}
                                onChange={onChangeInputValue}
                            />
                            <FormItem
                                label={
                                    <span className="core-info-long-label">
                                        {t('lab-sample-number')}
                                    </span>
                                }
                                name={'name'}
                                loading={loading}
                                value={form.getFieldValue('name')}
                                onChange={onChangeSelectValue}
                                colProps={{ className: 'col-info-form-item' }}
                            />
                            <FormItem
                                label={t('box-number')}
                                name={'box'}
                                loading={loading}
                                value={form.getFieldValue('box')}
                                type={'numeric'}
                                colProps={{ className: 'col-info-form-item' }}
                                onKeyDown={handleKeyDown}
                                onChange={onChangeInputValue}
                            />
                            <FormItem
                                label={
                                    <span className="core-info-long-label">
                                        {t('direction-orientation')}
                                    </span>
                                }
                                name={'direction'}
                                loading={loading}
                                value={form.getFieldValue('direction')}
                                type={'select'}
                                options={directionOptions(t)}
                                colProps={{ className: 'col-info-form-item' }}
                                onSelectChange={onChangeSelectValue}
                            />
                            <FormItem
                                label={t('top-bottom-of-selection')}
                                type={'custom'}
                                loading={loading}
                                colProps={{ className: 'col-info-form-item' }}
                            >
                                <div className="core-info-double-field-wrapper">
                                    <InputInLog
                                        value={doubleFieldsData['top']}
                                        onChange={(e) => {
                                            setDoubleFieldsData({
                                                ...doubleFieldsData,
                                                top: e,
                                            })
                                            setIsChangedField(true)
                                        }}
                                        onKeyDownhandler={handleKeyDown}
                                    />
                                    <InputInLog
                                        value={doubleFieldsData['bottom']}
                                        className="core-info-bottom-field"
                                        onKeyDownhandler={handleKeyDown}
                                        onChange={(e) => {
                                            setDoubleFieldsData({
                                                ...doubleFieldsData,
                                                bottom: e,
                                            })
                                            setIsChangedField(true)
                                        }}
                                    />
                                </div>
                            </FormItem>
                            <FormItem
                                label={t('core-recovery')}
                                name={'recovery'}
                                loading={loading}
                                value={form.getFieldValue('recovery')}
                                type={'numeric'}
                                colProps={{ className: 'col-info-form-item' }}
                                onKeyDown={handleKeyDown}
                                onChange={onChangeInputValue}
                            />
                            <FormItem
                                label={t('selection-point')}
                                name={'sampling_point'}
                                value={form.getFieldValue('sampling_point')}
                                type={'numeric'}
                                loading={loading}
                                colProps={colProps}
                                onKeyDown={handleKeyDown}
                                onChange={onChangeInputValue}
                            />
                            <FormItem
                                label={
                                    <span>
                                        {t('formation')}
                                    </span>
                                }
                                name={'formation'}
                                loading={loading}
                                value={form.getFieldValue('formation')}
                                colProps={{ className: 'col-info-form-item' }}
                                onKeyDown={handleKeyDown}
                                onChange={onChangeInputValue}
                            />
                            <FormItem
                                label={
                                    <span className="core-info-long-label">
                                        {t(
                                            'lithological-description',
                                        )}
                                    </span>
                                }
                                name={'lithology_description'}
                                type={'textArea'}
                                loading={loading}
                                colProps={{
                                    ...colProps,
                                    className: `col-info-input-wrap col-info-textarea-comment`,
                                }}
                                inputStyles={{
                                    minWidth: '100%',
                                    borderRadius: '14px',
                                    resize: 'none',
                                }}
                                value={form.getFieldValue(
                                    'lithology_description',
                                )}
                                onKeyDown={handleKeyDown}
                                onChange={onChangeInputValue}
                            />
                            <FormItem
                                label={t('comment')}
                                name={'comment'}
                                loading={loading}
                                value={form.getFieldValue('comment')}
                                type={'textArea'}
                                colProps={{
                                    ...colProps,
                                    className: `col-info-input-wrap col-info-textarea-comment`,
                                }}
                                inputStyles={{
                                    minWidth: '100%',
                                    borderRadius: '14px',
                                    resize: 'none',
                                }}
                                onKeyDown={handleKeyDown}
                                onChange={onChangeInputValue}
                            />
                            <div className={'col-info-photos'}>
                                <h5 className={'col-info-photos-title'}>
                                    <span>
                                        {t('photos')}
                                    </span>
                                </h5>
                                <CorePhoto
                                    currentCore={currentCore}
                                    currentProjectId={currentProject?.id}
                                    menuOpen={menuOpen}
                                    setMenuOpen={setMenuOpen}
                                />
                            </div>
                        </div>
                        <Col className={'col-info-btn-wrap'} span={24}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                className={'col-info-save-btn'}
                                disabled={!isChangedField}
                            >
                                {t('save')}
                            </Button>
                        </Col>
                    </Form>
                </div>
            </SimpleBar>
        </>
    )
}

export default CoreForm
