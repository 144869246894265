import { createAction } from '@reduxjs/toolkit'
import { IResearchEntity } from '../../app/types/models/logging'

const setEntities = createAction<{
    entities: IResearchEntity[]
    type: 'methods' | 'equipments'
}>('researchEntitiesActions/setEntities')

const addEntity = createAction<{
    entity: IResearchEntity
    type: 'methods' | 'equipments'
}>('researchEntitiesActions/addEntity')

const updateEntity = createAction<{
    id: number
    name: string
    type: 'methods' | 'equipments'
}>('researchEntitiesActions/updateEntity')

export { setEntities, addEntity, updateEntity }
