import React from "react";
import { ButtonItem } from "../app/types/i-button";
import { getValidText } from "../utils/helpers/getValidText";
import { Button } from "antd";
import UiButton from "../shared/ui/button/UiButton";

export const showButtonsList = (list: ButtonItem[], className?: string) => {
  return list && list.length > 0
    ? list.map((item: ButtonItem, index: number) => {
        return item?.isHiden ? (
          <></>
        ) : (
          <div
            key={(item?.id || 0) + index}
            className={`${getValidText(className)}`}
            style={{ width: item.widthBtn }}
          >
            <UiButton
              onClick={item.handleBtn}
              disabled={!!item?.isDisabled}
              className={`modal-button`}
              type={item.type}
            >
              {item.titleBtn}
            </UiButton>
          </div>
        );
      })
    : null;
};
