export interface IFieldsIntervalProps{
    activeRange:IValueRangeItem
    onChange:(value:string[])=>void
    setIsErrorIntervalFields:(status:boolean)=>void
    defaultData:IValueRangeItem
}

export interface IValueRangeItem {
    type:string
    value:'any' | string[]
}

export enum ValueTypes{
    ANY='any',
    POSITIVE='positive-number',
    INTERVAL='interval'
}
