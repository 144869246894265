import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { Popover } from 'antd'
import MembersBlock from '../../shared/membersBlock/MembersBlock'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { findCurrentPathFromString } from '../../utils/helpers/findCurrentPathFromString'
import { ISchedulerMenuProps } from './types'
import { useSelector } from 'react-redux'
import { projectsSelectors } from '../../store/projects'
import { projectsService } from '../../services/projects-service.'
import { toast } from 'react-toastify'
import { useAppDispatch } from '../../hooks/redux-hooks'
import {
    setCurrentProjectLoading,
    setProject,
} from '../../store/currentProject/actions'
import { currentProjectSelectors } from '../../store/currentProject'
import { membersSelectors } from '../../store/members'
import { useTranslation } from 'react-i18next'
import { SchedulerPagesType } from '../../widgets/scheduler/types'
import ProjectList from '../../entities/projectList/ProjectList'

const SchedulerMenu: FC<ISchedulerMenuProps> = ({
    setActivePage,
}) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const location = useLocation()
    const [collapsed, setCollapsed] = useState(false)
    const projects = useSelector(projectsSelectors.selectProjects)
    const currentProject = useSelector(
        currentProjectSelectors.selectCurrentProject,
    )
    const members = useSelector(membersSelectors.selectMembers)

    const handleSelectProject = useCallback(
        async (id: number) => {
            try {
                dispatch(setCurrentProjectLoading({ loading: true }))
                const res = await projectsService.getCurrentProject(id)
                dispatch(setProject({ project: res }))
                dispatch(setCurrentProjectLoading({ loading: false }))
            } catch (err) {
                dispatch(setCurrentProjectLoading({ loading: false }))
                toast.error(err.message, { position: 'top-center' })
            }
        },
        [currentProject],
    )

    const features = [
        { title: t('tasks'), path: 'tasks' },
        { title: t('statuses'), path: 'statuses' },
        // { title: t('scheduler.navbar.equipment'), path: 'equipment' },
        // { title: t('scheduler.navbar.activity'), path: 'activity' },
        { title: t('roadmap'), path: 'roadmap' },
        { title: t('team'), path: 'team' },
    ]

    const getToCorrectPageByLocation = useCallback(() => {
        if (projects?.length > 0) {
            const pathname = location?.pathname
            //если перейти по ссылке на конкретную задачу
            const urlPartsList = pathname.split('/').filter(el=>el!=='')
            if(urlPartsList[0]==='scheduler' && urlPartsList.length>3){
                const targetProject = projects.find(p=>p?.id===Number(urlPartsList[1]))
                if(targetProject  ){
                    handleSelectProject(targetProject?.id)
                    if(urlPartsList.includes('tasks')){
                        navigate(`${targetProject?.id}/tasks/${urlPartsList[3]}`) 
                    }else if(urlPartsList.includes('roadmap')){
                        navigate(`${targetProject?.id}/roadmap`) 
                    }
                }
            }else{
                handleSelectProject(projects[0]?.id)
                if(urlPartsList.includes('tasks')){
                    navigate(`${projects[0]?.id}/tasks`)
                }else if(urlPartsList.includes('roadmap')){
                    navigate(`${projects[0]?.id}/roadmap`)
                }
            }
        }
    }, [projects])

    useEffect(() => {
        getToCorrectPageByLocation()
    }, [projects])

    //закрывает меню проектов по клику
    const handleToggleMenu = (e) => {
        if (e.target.className !== 'scheduler__menu-span') {
            setCollapsed(false)
        }
    }

    useEffect(() => {
        addEventListener('click', handleToggleMenu, false)
        return () => {
            removeEventListener('click', handleToggleMenu, false)
        }
    }, [])

    return (
        <div className="scheduler__menu">
            <Popover
                placement="bottomLeft"
                content={
                    <div className="scheduler__projectList-wrapper">
                        <ProjectList
                        />
                    </div>
                }
            >
                <div className="scheduler__menu-top">
                    <div
                        className="scheduler__menu-logo"
                        onClick={() => setCollapsed(!collapsed)}
                    >
                        <span className="scheduler__menu-span">
                            {Object.keys(currentProject).length > 0 &&
                                currentProject?.name[0]?.toUpperCase()}
                        </span>
                    </div>
                    <div className="scheduler__menu-name">
                        {Object.keys(currentProject).length > 0 &&
                            currentProject?.name}
                    </div>
                </div>
            </Popover>
            <MembersBlock members={members} />
            <ul className="scheduler__menu-features">
                {features.map(
                    (f: { path: SchedulerPagesType; title: string }) => {
                        return (
                            <NavLink
                                key={f?.path}
                                style={({ isActive }) => {
                                    isActive && setActivePage(f.path)
                                    return {}
                                }}
                                to={`${
                                    currentProject?.id
                                }/${f.path.toLowerCase()}`}
                            >
                                <li
                                    style={
                                        findCurrentPathFromString(
                                            location.pathname,
                                            f.path.toLowerCase(),
                                        )
                                            ? {
                                                  backgroundColor: '#DEE5FB',
                                              }
                                            : {}
                                    }
                                >
                                    <span>{f?.title}</span>
                                </li>
                            </NavLink>
                        )
                    },
                )}
            </ul>
        </div>
    )
}

export default SchedulerMenu
