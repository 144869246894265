import { Skeleton } from 'antd'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { PlusIcon } from '../../../../../../image_files/icons/PlusIcon'
import { initialCorePhotoInterval } from '../../CoreBoxes'
import { ISelectInterval } from '../../types'
import SelectionInterval from './SelectionInterval'
import './SelectionIntervals.css'
import { ISelectionIntervalsProps } from './types'
import { ICoreBoxPhotoResponse } from '../../../../../../app/types/dto/core-box-data'

const SelectionIntervals: FC<ISelectionIntervalsProps> = ({
    loading,
    intervals,

    setCurrentBox,
    selectInterval,
    setIntervals,
    changeInterval,
    deleteInterval,
}) => {
    const { t } = useTranslation()
    const uid = uuidv4()

    const handleAddInterval = () => {
        setIntervals([...intervals, { ...initialCorePhotoInterval, id: uid, selected: false,isNew:true }])
    }

    const handleOnSelect = (id: ISelectInterval['id']) => {
        if (typeof id === 'number') {
            const targetInterval = intervals.find(interval => interval.id === id)
            if (targetInterval) {
                selectInterval(id)

                if (targetInterval?.photoBoxes?.length === 0) {
                    setCurrentBox({} as ICoreBoxPhotoResponse)
                } else {
                    setCurrentBox(targetInterval?.photoBoxes[0] as ICoreBoxPhotoResponse)
                }
            }
        }
    }

    const renderIntervals = () => {
        if (loading) {
            return (
                <>
                    <Skeleton.Input className='selection-intervals-skeleton-item' />
                    <Skeleton.Input className='selection-intervals-skeleton-item' />
                </>
            )
        } else {
            return (
                <>
                    {intervals.length>0 ? intervals.map((el, i, arr) => {
                        return (
                            <SelectionInterval
                                key={el.id}
                                item={el}
                                changeInterval={changeInterval}
                                deleteInterval={deleteInterval}
                                onSelect={() => handleOnSelect(el.id)}
                            />
                        )
                    }) : <div className='font-14-normal'>{t('no-intervals added')}</div>}

                </>
            )
        }
    }

    useEffect(()=>{
        if(intervals?.length===0){
            setCurrentBox({} as ICoreBoxPhotoResponse)
            
        }
    },[intervals])

    return (
        <div className="selection-intervals">
            <div className="core-boxes-paragraph-top">
                <PlusIcon
                    onClick={handleAddInterval}
                    className={'cursor-pointer'}
                />
                <span className={'font-14-normal'}>
                    {t('selection-intervals')}
                </span>
            </div>
            <div className="selection-intervals-list">
                {renderIntervals()}
            </div>
        </div>
    )
}

export default SelectionIntervals
