import React, {FC, useEffect, useState} from 'react';
import './valueRangeBlock.scss';
import {Radio} from "antd";
import FieldsInterval from "../fieldsInterval/FieldsInterval";
import {useTranslation} from "react-i18next";
import {IValueRangeItem, IValueRangeProps, ValueTypes} from "./types";

const ValueRangeBlock: FC<IValueRangeProps> = (props) => {
    const {defaultData, onChange, setIsErrorIntervalFields} = props
    const {t} = useTranslation()
    const [value, setValue] = useState<IValueRangeItem>({
        type: ValueTypes.ANY,
        value: 'any'
    })
    const [fieldsIntervalData, setFieldsIntervalData] = useState<string[]>([])

    const onChangeRadio = (e) => {
        const value = e.target.value
        if (value !== ValueTypes.INTERVAL) {
            setValue(value)
            onChange({
                type: value,
                value: value
            })
        } else {
            setValue(value)
            onChange({
                type: value,
                value: fieldsIntervalData
            })
        }
    }

    useEffect(() => {
        defaultData && setValue(defaultData)
    }, [defaultData])

    useEffect(() => {
        if (value?.type !== ValueTypes.INTERVAL) {
            setIsErrorIntervalFields && setIsErrorIntervalFields(false)
        }
    }, [value])

    const saveFieldsIntervalData = (data: string[]) => {
        switch (value?.type) {
            case ValueTypes.INTERVAL:
                onChange && onChange({
                    type: value?.type,
                    value: data
                })
                break;
            case ValueTypes.POSITIVE:
                onChange && onChange({
                    type: value?.type,
                    value: value?.type
                })
                break;
            case ValueTypes.ANY:
                onChange && onChange({
                    type: value?.type,
                    value: value?.type
                })
                break;
            default:
                setIsErrorIntervalFields && setFieldsIntervalData(data)
                break;

        }
    }

    return (
        <Radio.Group onChange={onChangeRadio} value={value?.type}>
            <div className={'value-range-block'}>
                <Radio value={ValueTypes.ANY}>
                    <div
                        className={'font-10-normal value-range-block--text'}>{t('any-value').toString()}</div>
                </Radio>
                <Radio value={ValueTypes.POSITIVE}>
                    <div className={'font-10-normal value-range-block--text'}>{`> 0`}</div>
                </Radio>
                <Radio value={ValueTypes.INTERVAL}>
                    <FieldsInterval onChange={saveFieldsIntervalData}
                                    activeRange={value}
                                    defaultData={defaultData}
                                    setIsErrorIntervalFields={setIsErrorIntervalFields}
                    />
                </Radio>
            </div>
        </Radio.Group>
    );
};

export default ValueRangeBlock;