import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import './AddTextButton.css'

interface IProps {
    placeholder?: string
    title: string

    addText: (value: string) => void
}

const AddTextButton: FC<IProps> = ({ addText, placeholder, title }) => {
    const [inputMode, setInputMode] = useState(false)
    const [inputValue, setInputValue] = useState('')

    const onKeyPressHandler = (e) => {
        if (e.charCode === 13 && inputValue.length > 0) {
            addText(inputValue)
            setInputValue('')
            setInputMode(false)
        }
    }

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.currentTarget.value)
    }

    const clickHandler = (e) => {
        if (
            e.target.className !== 'addTextButton__btn' &&
            e.target.className !== 'addTextButton__inputForm-input' &&
            e.target.className !== 'addTextButton__span'
        ) {
            setInputMode(false)
        }
    }

    useEffect(() => {
        addEventListener('click', clickHandler, false)
        return () => {
            removeEventListener('click', clickHandler, false)
        }
    }, [])

    return (
        <div className={'addTextButton'}>
            <button
                className="addTextButton__btn"
                style={
                    inputMode ? { background: 'rgba(217, 217, 217, 0.2)' } : {}
                }
                onClick={() => setInputMode(!inputMode)}
            >
                <span className="addTextButton__span">+ {title}</span>
            </button>
            <div
                className="addTextButton__inputForm"
                style={!inputMode ? { maxHeight: 0 } : { maxHeight: '100px',marginTop:'12px' }}
            >
                <input
                    className="addTextButton__inputForm-input"
                    type="text"
                    placeholder={placeholder}
                    value={inputValue}
                    onKeyPress={onKeyPressHandler}
                    onChange={onChangeHandler}
                />
            </div>
        </div>
    )
}

export { AddTextButton }
