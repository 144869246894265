import React from 'react'

const LockIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            viewBox="0 0 256 256"
            xmlSpace="preserve"
            width={46}
            height={46}
            stroke={'#C3C3C3'}
        >
            <defs></defs>
            <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                <path
                    fill={'#C3C3C3'}
                    d="M 68.483 90 H 21.517 c -5.468 0 -9.917 -4.448 -9.917 -9.916 V 42.043 c 0 -5.468 4.449 -9.916 9.917 -9.916 h 46.967 c 5.468 0 9.916 4.448 9.916 9.916 v 38.041 C 78.399 85.552 73.951 90 68.483 90 z M 21.517 40.127 c -1.057 0 -1.917 0.859 -1.917 1.916 v 38.041 c 0 1.057 0.86 1.916 1.917 1.916 h 46.967 c 1.057 0 1.916 -0.859 1.916 -1.916 V 42.043 c 0 -1.057 -0.859 -1.916 -1.916 -1.916 H 21.517 z"
                    transform=" matrix(1 0 0 1 0 0) "
                    strokeLinecap="round"
                />
                <path
                    fill={'#C3C3C3'}
                    d="M 65.009 40.127 c -2.209 0 -4 -1.791 -4 -4 V 22.363 C 61.009 14.443 54.565 8 46.646 8 h -3.291 c -7.92 0 -14.363 6.443 -14.363 14.363 v 13.764 c 0 2.209 -1.791 4 -4 4 s -4 -1.791 -4 -4 V 22.363 C 20.991 10.032 31.023 0 43.354 0 h 3.291 c 12.331 0 22.363 10.032 22.363 22.363 v 13.764 C 69.009 38.336 67.218 40.127 65.009 40.127 z"
                    transform=" matrix(1 0 0 1 0 0) "
                    strokeLinecap="round"
                />
                <path
                    fill={'#C3C3C3'}
                    d="M 45 62.704 L 45 62.704 c -3.521 0 -6.402 -2.881 -6.402 -6.402 v -3.225 c 0 -3.521 2.881 -6.402 6.402 -6.402 h 0 c 3.521 0 6.402 2.881 6.402 6.402 v 3.225 C 51.402 59.823 48.521 62.704 45 62.704 z"
                    transform=" matrix(1 0 0 1 0 0) "
                    strokeLinecap="round"
                />
                <path
                    fill={'#C3C3C3'}
                    d="M 55.36 74.118 v -4.208 c 0 -4.514 -3.693 -8.207 -8.207 -8.207 h -4.306 c -4.514 0 -8.207 3.693 -8.207 8.207 v 4.208 c 0 0.46 0.373 0.833 0.833 0.833 h 19.054 C 54.987 74.951 55.36 74.578 55.36 74.118 z"
                    transform=" matrix(1 0 0 1 0 0) "
                    strokeLinecap="round"
                />
            </g>
        </svg>
    )
}

export default LockIcon
