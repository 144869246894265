import './firstProject.css'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { projectsService } from '../../../../services/projects-service.'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { projectsActions } from '../../../../store/projects'
import { appActions } from '../../../../store/app'
import {wellsService} from "../../../../services/wells-service";

function FirstProject() {
    const [projectName, setProjectName] = useState('')
    const [reservoirName, setReservoirName] = useState('')
    const [companyCustomer, setCompanyCustomer] = useState('')
    const [contractor, setContractor] = useState('')
    const [objectCountry, setObjectCountry] = useState('')
    const [errorSubmit, setErrorSubmit] = useState('')
    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()
    const { addProject, addPad, addWell } = projectsActions
    const { setAppStatus, setAppError } = appActions

    const fetchProjectWithEntities = async () => {
        try {
            const preparedData = {
                name: projectName,
                reservoir: reservoirName,
                company_customer: companyCustomer,
                contractor,
                country: objectCountry,
            }
            dispatch(setAppStatus({ status: 'loading' }))
            const projectResponse = await projectsService.addNewProject(
                preparedData,
            )
            dispatch(addProject({ project: projectResponse }))
            const padResponse = await projectsService.addPad(
                projectResponse.id,
                { name: 'first pad', project: projectResponse.id },
            )
            dispatch(addPad({ pad: padResponse, id: projectResponse.id }))
            const wellResponse = await wellsService.addWell(
                projectResponse.id,
                {
                    name: 'first well',
                    project: projectResponse.id,
                    well_pad: padResponse.id,
                },
            )
            dispatch(
                addWell({
                    well: wellResponse,
                    padId: padResponse.id,
                    id: projectResponse.id,
                }),
            )
            dispatch(setAppStatus({ status: 'succeeded' }))
        } catch (e) {
            dispatch(setAppStatus({ status: 'failed' }))
            dispatch(setAppError({ error: e }))
        }
    }

    const asd = (e) => {
        errorSubmit ? e.preventDefault() : null
    }
    const qwe = (e) => {
        e.target.focus()
    }

    useEffect(() => {
        setErrorSubmit(errorSubmit)
    }, [i18n.language])
    return (
        <div className="FirstProject">
            <form
                className="firstproject_form"
                onSubmit={fetchProjectWithEntities}
            >
                <div className="firstproject_datainput">
                    <label
                        className="firstproject_label"
                        style={{
                            color: errorSubmit
                                ? 'rgba(255, 109, 109, 0.7)'
                                : 'rgba(0, 0, 0, 0.7)',
                        }}
                    >
                        <>
                            {t('userProfile.projectInfo.projectName')}
                            <input
                                type="text"
                                onMouseDown={asd}
                                onDoubleClick={qwe}
                                style={{
                                    border: errorSubmit
                                        ? '1px solid rgba(255, 109, 109, 0.7)'
                                        : 'none',
                                }}
                                value={projectName}
                                className="firstproject_input"
                                onChange={(e) => setProjectName(e.target.value)}
                            />
                        </>
                    </label>
                </div>
                <div className="firstproject_datainput">
                    <label
                        className="firstproject_label"
                        style={{
                            color: errorSubmit
                                ? 'rgba(255, 109, 109, 0.7)'
                                : 'rgba(0, 0, 0, 0.7)',
                        }}
                    >
                        <>
                            {t('userProfile.projectInfo.reservoirName')}
                            <input
                                type="text"
                                value={reservoirName}
                                onMouseDown={asd}
                                onDoubleClick={qwe}
                                style={{
                                    border: errorSubmit
                                        ? '1px solid rgba(255, 109, 109, 0.7)'
                                        : 'none',
                                }}
                                className="firstproject_input"
                                onChange={(e) =>
                                    setReservoirName(e.target.value)
                                }
                            />
                        </>
                    </label>
                </div>
                <div className="firstproject_datainput">
                    <label className="firstproject_label">
                        <>
                            {t('userProfile.projectInfo.companyCustomer')}
                            <input
                                type="text"
                                value={companyCustomer}
                                className="firstproject_input"
                                onChange={(e) =>
                                    setCompanyCustomer(e.target.value)
                                }
                            />
                        </>
                    </label>
                </div>
                <div className="firstproject_datainput">
                    <label className="firstproject_label">
                        <>
                            {t('userProfile.projectInfo.contractor')}
                            <input
                                type="text"
                                value={contractor}
                                className="firstproject_input"
                                onChange={(e) => setContractor(e.target.value)}
                            />
                        </>
                    </label>
                </div>

                <div className="firstproject_datainput">
                    <label className="firstproject_label">
                        <>
                            {t('userProfile.projectInfo.objectCountry')}
                            <input
                                type="text"
                                value={objectCountry}
                                className="firstproject_input"
                                onChange={(e) =>
                                    setObjectCountry(e.target.value)
                                }
                            />
                        </>
                    </label>
                </div>
                <div className="firstproject_continue_box">
                    <p className="error_required_text">{errorSubmit}</p>
                    <input
                        type="submit"
                        value={`${t('userProfile.projectInfo.continue')}`}
                        className="firstproject_submit"
                    />
                </div>
            </form>
        </div>
    )
}

export default FirstProject
