import React from "react";

const MessageIcon = () => {
    return (
        <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.60417 10.2917H4.33334C2.16667 10.2917 1.08334 9.75 1.08334 7.04167V4.33334C1.08334 2.16667 2.16667 1.08334 4.33334 1.08334H8.66667C10.8333 1.08334 11.9167 2.16667 11.9167 4.33334V7.04167C11.9167 9.20834 10.8333 10.2917 8.66667 10.2917H8.39584C8.22792 10.2917 8.06542 10.3729 7.9625 10.5083L7.15 11.5917C6.7925 12.0683 6.2075 12.0683 5.85 11.5917L5.0375 10.5083C4.95084 10.3892 4.75042 10.2917 4.60417 10.2917Z"
                stroke="#828282"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.79166 4.33334H9.20833"
                stroke="#828282"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.79166 7.04166H7.04166"
                stroke="#828282"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default MessageIcon;
