import {
    ACOUSTIC_VELOCITY,
    BIO,
    BULK_MODULUS,
    CAPACITY,
    CAPILLARY_CURVE,
    CARBONATES,
    COMPRESSIBILITY,
    COMPRESSIVE_STRENGTH,
    CONDUCTIVITY,
    DENSITY,
    DIFFUSIVITY,
    ELASTIC_LIMIT,
    OIL_DISPLACEMENT,
    PERMEABILITY,
    POISSON_RATIO,
    POROSITY,
    SATURATION,
    SHEAR_MODULUS,
    ULTIMATE_COMPRESSIVE_STRENGTH,
    YIELD_STRENGTH,
    YOUNG_MODULE,
} from '../../../../../../../utils/constants'
import { mechanicalService } from '../../../../../../../services/mechanical-service'
import { thermalService } from '../../../../../../../services/thermal-service'
import { errorsHandler } from '../../../../../../../utils/helpers/errors/errors-hendler'
import { showNotice } from '../../../../../../../utils/helpers/showNotice'
import { standardStudyGroupService } from '../../../../../../../services/standard-study-group-service'

interface IParams {
    settingType: string
    groupType: 'standard' | 'mechanical' | 'thermal'
    projectId: number
    coreId: number
    settingId: number
    data: any
    groupId: number
    t: Function
}

export const updateSetting = async (values: IParams) => {
    const {
        settingType,
        groupType,
        settingId,
        data,
        coreId,
        groupId,
        projectId,
        t,
    } = values

    try {
        if (groupType === 'standard') {
            if (settingType === POROSITY) {
                await standardStudyGroupService.updatePorosity(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === PERMEABILITY) {
                await standardStudyGroupService.updatePermeability(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === SATURATION) {
                await standardStudyGroupService.updateSaturation(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === DENSITY) {
                await standardStudyGroupService.updateDensity(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === OIL_DISPLACEMENT) {
                await standardStudyGroupService.updateOilDisplacement(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === CARBONATES) {
                await standardStudyGroupService.updateCarbonateContent(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === CAPILLARY_CURVE) {
                await standardStudyGroupService.updateCapillaryCurvePoint(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            }
        } else if (groupType === 'mechanical') {
            if (settingType === YOUNG_MODULE) {
                await mechanicalService.updateYoungModule(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === POISSON_RATIO) {
                await mechanicalService.updatePoissonRatio(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === ACOUSTIC_VELOCITY) {
                await mechanicalService.updateAcousticVelocity(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === COMPRESSIBILITY) {
                await mechanicalService.updateCompressibility(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === SHEAR_MODULUS) {
                await mechanicalService.updateShearModulus(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === YIELD_STRENGTH) {
                await mechanicalService.updateYieldStrength(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === BIO) {
                await mechanicalService.updateBio(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === BULK_MODULUS) {
                await mechanicalService.updateBulkModulus(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === COMPRESSIVE_STRENGTH) {
                await mechanicalService.updateUniaxialCompressiveStrength(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === ULTIMATE_COMPRESSIVE_STRENGTH) {
                await mechanicalService.updateUltimateCompressiveStrength(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === ELASTIC_LIMIT) {
                await mechanicalService.updateElasticLimit(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            }
        } else {
            if (settingType === CONDUCTIVITY) {
                await thermalService.updateConductivity(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === DIFFUSIVITY) {
                await thermalService.updateDiffusivity(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            } else if (settingType === CAPACITY) {
                await thermalService.updateCapacity(
                    projectId,
                    coreId,
                    groupId,
                    settingId,
                    data,
                )
            }
        }

        showNotice('setting-updated-successfully', t)
    } catch (e) {
        errorsHandler(e,t)
    }
}
