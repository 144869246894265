import React from 'react';
import './Equipment.css';

const Equipment = () => {
    return (
        <div className="equipment">
            <div className="equipment__container">
                <div className="equipment__entitiesSpace">
                    {/*<AddTextButton*/}
                    {/*addText={addText}*/}
                    {/*title={'roadMap'}*/}
                    {/*placeholder={'Enter a subtask title'}*/}
                    {/*/>*/}
                </div>
                <div className="equipment__workSpace"></div>
            </div>
        </div>
    )
}

export { Equipment }
