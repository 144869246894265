import { ACCESS_TOKEN } from '../utils/constants'
import {instance} from "../app/api";
import { IResearchEntity } from '../app/types/models/logging';
import { AxiosResponse } from 'axios';

export const entitiesServices = {
    getEquipment: async (projectId: number, data?: any) => {
        return await instance.get<undefined,AxiosResponse<IResearchEntity[]>>(`projects/${projectId}/research-equipment/`, {
            params: data,
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        }).then((res) => res.data)
    },

    addEquipment: async (projectId, data) => {
        return await instance.post<undefined,AxiosResponse<IResearchEntity>>(`projects/${projectId}/research-equipment/`,data, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
        }).then((res) => res.data)
    },
    getMethod: async (projectId: number, data?: any) => {
        return await instance.get<undefined,AxiosResponse<IResearchEntity[]>>(`projects/${projectId}/research-methods/`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
            params: data,
        }).then((res) => res.data)
    },
    addMethod: async (projectId, data) => {
        return await instance.post(
            `projects/${projectId}/research-methods/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },
}
