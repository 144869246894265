import React from 'react'
import { FC } from 'react'

interface IProps {
    className?: string
    onClick?: () => void
    style?: object
    isMenuOpen?: boolean
}

const ToggleMenuIcon: FC<IProps> = ({
    className,
    style,
    isMenuOpen,

    onClick,
}) => {
    return (
        <svg
            className={className ? className : ''}
            onClick={onClick ? onClick : () => {}}
            style={style ? style : {}}
            width="18"
            height="21"
            viewBox="0 0 18 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.1306 1.30246C16.4639 1.11001 16.8806 1.35057 16.8806 1.73547L16.8806 19.056C16.8806 19.4409 16.4639 19.6814 16.1306 19.489L1.13059 10.8287C0.797254 10.6363 0.797253 10.1552 1.13059 9.96271L16.1306 1.30246Z"
                fill={isMenuOpen ? '#364f6b' : 'var(--grey-phone-color)'}
                stroke="#616161"
            />
        </svg>
    )
}

export { ToggleMenuIcon }
