import React, {FC} from "react";
import {UploadITemProps} from "./types";
import {imgExtensions} from "./data";
import FileIcon from "../../image_files/icons/FileIcon";
import {DeleteIcon} from "../../image_files/icons/DeleteIcon";
import {DownloadIcon} from "../../image_files/icons/downloadIcon";

const downloadHandler = (linkData:string,name:string) =>{
    fetch(linkData, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/pdf',
        },
    })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                name,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
}

export const UploadItem: FC<UploadITemProps> = ({image, name, id, onRemove}) => {
    const extension = name && name?.length>0 ? name?.split('.').at(-1) : ''

    const showFileView = ()=>{
        if(imgExtensions.includes(extension)){
            return <img className={'uploadItem__img'} src={image} alt="upload image"/>
        }else return <div className={'uploadItem__file'}><FileIcon/></div>
    }
    return (
        <div className={'uploadItem'}>
            <div className={'uploadItem-btn-wrapper'}>
                <button className={'uploadItem__btn'} onClick={() => onRemove(id)}><DeleteIcon/></button>
                <button className={'uploadItem__btn'} onClick={()=>downloadHandler(image,name)}><DownloadIcon/></button>
            </div>
            {showFileView()}
            <p className={'uploadItem__name'}>{name}</p>
        </div>
    )
}