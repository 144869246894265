import React, { FC } from 'react';
import { IIconProps } from '../../app/types/i-props';

const InfoIcon20: FC<IIconProps> = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0239 5.90064C11.0239 5.3342 10.5647 4.875 9.9983 4.875C9.43185 4.875 8.97266 5.3342 8.97266 5.90064C8.97266 6.46709 9.43185 6.92628 9.9983 6.92628C10.5647 6.92628 11.0239 6.46709 11.0239 5.90064Z"
        fill={fill ? fill : '#A2B5FF'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9983 14.875C10.4231 14.875 10.7675 14.5306 10.7675 14.1058V8.97756C10.7675 8.55273 10.4231 8.20833 9.9983 8.20833C9.57346 8.20833 9.22907 8.55273 9.22907 8.97756V14.1058C9.22907 14.5306 9.57346 14.875 9.9983 14.875Z"
        fill={fill ? fill : '#A2B5FF'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.72589 10C1.72589 5.43033 5.43033 1.72589 10 1.72589C14.5697 1.72589 18.2741 5.43033 18.2741 10C18.2741 14.5697 14.5697 18.2741 10 18.2741C5.43033 18.2741 1.72589 14.5697 1.72589 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0Z"
        fill={fill ? fill : '#A2B5FF'}
      />
    </svg>
  );
};

export default InfoIcon20;
