import React, {FC, useEffect, useState} from 'react';
import './customGroupParameter.css';
import {CustomParameterErrorTypes, ICustomGroupParameterProps, ICustomParameterRules} from "./types";
import {CustomGroupParameters} from "../../entities/customGroup/types";
import {useTranslation} from "react-i18next";
import {LanguageTypes} from "../../app/types/enums";
import {getValidText} from "../../utils/helpers/getValidText";
import InputInLog from "../input/Input";
import {ValueTypes} from "../../entities/customGroup/components/add-parameter-zone/fieldsInterval/types";
import SelectInLog from "../select/Select";

const CustomGroupParameter: FC<ICustomGroupParameterProps> = (props) => {
    const {type, label, onChange, rules, options, value} = props
    const {i18n, t} = useTranslation()
    const [inputValue, setInputValue] = useState('')
    const [error, setError] = useState<string>('')
    const [doubleInputValue, setDoubleInputValue] = useState<string[]>(['', ''])

    const showFieldByType = () => {
        switch (type) {
            case CustomGroupParameters.NUMERIC_VALUE:
                return (
                    <InputInLog
                        className={`custom-group-parameter--input ${error !== '' ? 'custom-group-parameter--error' : ''}`}
                        onChange={(e) => onChangeHandler(e)}
                        defaultValue={inputValue}
                        placeholder={showPlaceholder()}
                        debouncedMode={true}

                    />
                )
            case CustomGroupParameters.CUSTOM_DROPDOWN:
                return (
                    <SelectInLog options={options}
                                 className={`custom-group-parameter--select ${error !== '' ? 'custom-group-parameter--error' : ''}`}
                                 onChange={(e) => onChangeHandler(e)}
                                 defaultValue={value}
                    />

                )
            case CustomGroupParameters.SET_OF_PAIRS:
                return (
                    <div className={'custom-group-parameter--double-input-wrapper'}>
                        <InputInLog
                            className={`custom-group-parameter--input custom-group-parameter--input-first ${error !== '' ? 'custom-group-parameter--error' : ''}`}
                            onChange={(e) => onChangeHandler(e, rules[0], 0)}
                            value={doubleInputValue[0]}
                            placeholder={showPlaceholder(rules[0])}

                        />
                        <InputInLog
                            className={`custom-group-parameter--input custom-group-parameter--input-second ${error !== '' ? 'custom-group-parameter--error' : ''}`}
                            onChange={(e) => onChangeHandler(e, rules[1], 1)}
                            value={doubleInputValue[1]}
                            placeholder={showPlaceholder(rules[1])}
                        />
                    </div>

                )
            default:
                return <div></div>
        }
    }

    const showPlaceholder = (targetRules?: ICustomParameterRules) => {
        const validRules = targetRules ? targetRules : rules
        if (validRules && Object.keys(validRules).length > 0 && !Array.isArray(validRules)) {
            switch (validRules?.type) {
                case ValueTypes.ANY:
                    return t('any-number').toString()
                case ValueTypes.POSITIVE:
                    return t('only-positive-number').toString()
                case ValueTypes.INTERVAL:
                    return t('only-positive-number', {value: `[ ${validRules?.value[0]}, ${validRules?.value[1]} ]`}).toString()
                default:
                    return ''
            }
        }
    }

    const showName = () => {
        if (Array.isArray(label)) {
            return (
                <div className={'custom-group-parameter--double-title'}>
                    {label.map((item, index) => (
                        <span key={index}>{i18n.language === LanguageTypes.ru ? item?.ru : item?.en}</span>
                    ))}
                </div>
            )
        } else {
            const value = i18n.language === LanguageTypes.ru ? label?.ru : label?.en
            return getValidText(value)
        }

    }
    //.replace(/[^0-9\.]/g,"")
    const onChangeHandler = (e: any, targetRules?: ICustomParameterRules, doubleInputOrder?: number) => {
        let validRules = targetRules ? targetRules : rules
        let value = e;
        if (validRules && Object.keys(validRules).length > 0 && !Array.isArray(validRules)) {
            switch (validRules?.type) {
                case ValueTypes.ANY:
                    value = value.replace(/[^-.\d]/g, '')
                    break;
                case ValueTypes.POSITIVE:
                    value = value.replace(/([^\d]*)(\d*(\.\d{0,8})?)(.*)/, '$2');
                    break;
                case ValueTypes.INTERVAL:
                    value = getValueByIntervalRules(value, validRules)
                    break;
                default:
                    break;
            }
        }
        if (type === CustomGroupParameters.NUMERIC_VALUE) {
            if (value === '') {
                setError(CustomParameterErrorTypes.REQUIRED)
            } else {
                setError('')
            }
            setInputValue(value)
        } else if (type === CustomGroupParameters.SET_OF_PAIRS) {
        } else if (type === CustomGroupParameters.CUSTOM_DROPDOWN) {
            setError('')
        }
        if (doubleInputOrder !== undefined) {
            onChange(getIntervalValue(value, doubleInputOrder))
        } else {
            onChange({value: value, isError: !(value.length > 0)})
        }
    }

    const getValueByIntervalRules = (value: any, validRules: ICustomParameterRules) => {
        if (validRules && validRules?.value.length > 0) {
            const fromValue = validRules?.value[0]
            const toValue = validRules?.value[1]
            if (Number(value) >= Number(fromValue) && Number(value) <= Number(toValue)) {
                return value
            } else {
                setError('error')
                return value.slice(0, value.length - 1)
            }
        } else {
            return value
        }
    }

    const getIntervalValue = (value: any, order: number) => {
        let returnedError;
        if (order !== undefined) {
            let fromValue = doubleInputValue[0]
            let toValue = doubleInputValue[1]
            if (order === 0) {
                fromValue = value
                if (value === '') {
                    returnedError = 'error'
                } else {
                    if (toValue === '') {
                        returnedError = 'error'
                    } else {
                        if (Number(toValue) >= Number(value)) {
                            returnedError = ''
                        } else if (value === '') {
                            returnedError = 'error'
                        } else {
                            returnedError = t('min-value-must-be-less',{value:toValue})
                        }
                    }
                }
            } else if (order === 1) {
                toValue = value
                if (fromValue === '') {
                    returnedError = 'error'
                } else {
                    if (Number(fromValue) < Number(value)) {
                        returnedError = ``
                    } else if (value === '') {
                        returnedError = `error`
                    } else if (value === '-') {
                        returnedError = t('max-value-must-be-less',{value:fromValue})
                    } else {
                        returnedError = ``
                    }
                }
            }
            let isReadyToSend = false;
            setDoubleInputValue((prev) => {
                if (prev && JSON.stringify(prev) !== JSON.stringify([fromValue, toValue])) {
                    isReadyToSend = true
                    return [fromValue, toValue]
                } else {
                    isReadyToSend = false
                    return prev
                }
            })
            setError(returnedError)
            if (isReadyToSend) {
                return {value: [fromValue, toValue], isError: returnedError.length !== 0} as any
            }
        }
        return {value: ['', ''], isError: true} as any
    }

    useEffect(() => {
        if (value!==undefined) {
            switch (type) {
                case CustomGroupParameters.NUMERIC_VALUE:
                    setInputValue(value)
                    setError('')
                    break;
                case CustomGroupParameters.CUSTOM_DROPDOWN:
                    setError('')
                    break;
                case CustomGroupParameters.SET_OF_PAIRS:
                    if (value && Array.isArray(value) && value?.length > 0) {

                        if (value.filter(el => el && el !== '').length === value.length) {
                            setDoubleInputValue(value)
                        }
                    } else {
                        setDoubleInputValue(['', ''])
                        setError('error')
                    }
            }
        }
    }, [value])

    return (
        <div className={'custom-group-parameter'}>
            {showFieldByType()}
            <div className={'custom-group-parameter-title'}>{showName()}</div>
        </div>
    );
};

export default CustomGroupParameter;