import React, { FC } from 'react'
import { getValidText } from '../../utils/helpers/getValidText'
import { IIconProps } from '../../app/types/i-props'

const SettingCancelIcon: FC<IIconProps> = (props) => {
    const { width, height, viewBox, className, isRound = true, onClick } = props
    return (
        <svg
            className={`cursor-pointer ${getValidText(className)}`}
            width={width ? width : '20'}
            height={height ? height : '20'}
            onClick={onClick ? onClick : () => {}}
            viewBox={viewBox ? viewBox : '0 0 33 33'}
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M32.1019 16.3043C32.1019 25.033 25.0275 32.1087 16.3009 32.1087C7.57442 32.1087 0.5 25.033 0.5 16.3043C0.5 7.57575 7.57442 0.5 16.3009 0.5C25.0275 0.5 32.1019 7.57575 32.1019 16.3043Z"
                stroke="#F52B2B"
            />
            <path
                d="M11.4 24L10 22.6L15.6 17L10 11.4L11.4 10L17 15.6L22.6 10L24 11.4L18.4 17L24 22.6L22.6 24L17 18.4L11.4 24Z"
                fill="#F52B2B"
            />
        </svg>
    )
}

export default SettingCancelIcon
