import { Spin } from "antd"
import './spin.css';
import { FC }  from "react";
import React  from "react";
import { SpinPropTypes } from "./types";
import {getValidText} from "../../utils/helpers/getValidText";

const SpinInLog: FC<SpinPropTypes> = (
    {
        size = 'large',
        isLoading,
        className
    }
) => {
    return (
        <>
            {
                isLoading ?
                    <div className={`spin-wrapper ${getValidText(className)}`}>
                        <Spin size={size} />
                    </div> : ''
            }
        </>


    )
}

export default SpinInLog;