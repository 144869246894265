import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {routes} from '../utils/constants'
import Home from '../pages/home/Home'
import RecoveryData from '../pages/passwordReset/PasswordReset'
import RecoveryMessage from '../pages/recoveryMessage/RecoveryMessage'
import PasswordResetConfirm from '../pages/passwordResetConfirm/PasswordResetConfirm'
import RecoveryChange from '../pages/recoveryChange/RecoveryChange'
import CheckEmail from '../pages/checkEmail/CheckEmail'
import VerifyGoogle from '../pages/verifyGoogle/VerifyGoogle'
import VerifySlackAccount from '../pages/verifySlack/VerifySlack'
import VerifyYandexAccount from '../pages/verifyYandex/VerifyYandex'
import VerifyMicrosoftAccount from '../pages/verifyMicrosoft/VerifyMicrosoft'
import EmailConfirmation from '../pages/emailConfirmation/EmailConfirmation'
import ChangeEmailPage from "../pages/change-email";
import LoginPage from "../pages/login";
import RegistrationPage from "../pages/registration-page";
import CopyLinkPage from "../pages/copyLink";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path={routes.HOME} element={<Home/>}/>

            <Route path={routes.LOGIN} element={<LoginPage/>}/>

            <Route path={routes.REGISTRATION} element={<RegistrationPage/>}/>
            <Route path={routes.PASSWORD_RECOVERY} element={<RecoveryData/>}/>
            <Route
                path={routes.RECOVERY_MESSAGE}
                element={<RecoveryMessage/>}
            />
            <Route
                path={routes.RESET_CONFIRM}
                element={<PasswordResetConfirm/>}
            />
            <Route path={routes.RECOVERY_CHANGE} element={<RecoveryChange/>}/>
            <Route path={routes.CHECK_EMAIL} element={<CheckEmail/>}/>
            <Route path={routes.VERIFY_GOOGLE} element={<VerifyGoogle/>}/>
            <Route
                path={routes.VERIFY_SLACK}
                element={<VerifySlackAccount/>}
            />
            <Route
                path={routes.VERIFY_YANDEX}
                element={<VerifyYandexAccount/>}
            />
            <Route
                path={routes.VERIFY_MICROSOFT}
                element={<VerifyMicrosoftAccount/>}
            />
            <Route path={routes.EXTENDED_PROFILE} element={<Home/>}/>
            <Route path={routes.CHANGE_EMAIL} element={<ChangeEmailPage/>}/>
            <Route path={routes.COPY_LINK} element={<CopyLinkPage/>}/>
            <Route
                path={routes.EMAIL_CONFIRMATION}
                element={<EmailConfirmation/>}
            />
        </Routes>
    )
}

export default AppRoutes
