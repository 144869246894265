import React, {FC, useEffect, useState} from 'react'
import './editableSpan.css'
import {useTranslation} from 'react-i18next'
import {EditIcon} from '../../image_files/icons/EditIcon'
import {classesList} from "./data";
import { IEditableSpanProps } from './types-ALS-101';


export const EditableSpan: FC<IEditableSpanProps> = (props) => {
    const {
        title,
        className,
        spanClassName,
        children,
        handler,
        editTask,
        currentSubTask,
        setEditStatus,
        taskSlug,
        inputClassName,
        lengthLimit,
        namesList,
        error,
        setError
    } = props
    const [editMode, setEditMode] = useState(false)
    const [errorValue, setErrorValue] = useState('')
    const [inputValue, setInputValue] = useState(title ? title : '')
    const {t} = useTranslation()

    const addConditionByNamesList = (eventName?: string) => {
        if (namesList && namesList.length > 0) {
            if (namesList.indexOf(inputValue.toLowerCase()) === -1) {
                setEditMode(false)
                handler(inputValue, taskSlug)
                setErrorValue('')
            } else {
                setErrorValue(t('name-already-exists'))
                if (eventName && eventName === 'onBlur') {
                    setInputValue(title)
                }
            }
        }
        if (!namesList || namesList.length === 0) {
            setEditMode(false)
            handler(inputValue, taskSlug)
        }
    }

    const keyPressHandler = (e) => {
        if (e.charCode === 13 && inputValue.length > 0) {
            addConditionByNamesList()
        }
    }
    const onBlurHandler = () => {
        if (inputValue.length > 0) {
            addConditionByNamesList('onBlur')
            setEditMode(false)
        }
        if (inputValue.length === 0) {
            setInputValue(title)
            setEditMode(false)
            setEditStatus(false)
        }
    }

    const clickHandler = (e) => {
        if (!classesList.includes(e.target.className)) {
            setEditMode(false)
        }
    }

    const onInputChange = (e) => {
        setErrorValue('')
        setError && setError('')
        setInputValue(e.currentTarget.value)
    }

    useEffect(() => {
        addEventListener('click', clickHandler, false)
        return () => {
            removeEventListener('click', clickHandler, false)
        }
    }, [])

    useEffect(() => {
        setInputValue(title)
        setErrorValue('')
    }, [title])

    useEffect(() => {
        setErrorValue('')
    }, [editMode])

    useEffect(() => {
        if (error && error.length > 0) {
            setErrorValue(error)
            setInputValue(title)
        }
    }, [error])

    return (
        <div
            className={className ? `${className} editableSpan` : 'editableSpan'}
        >
            {editMode || (editTask && currentSubTask === title) ? (
                <div className={'editableSpan-inputContainer'}>
                    <input
                        maxLength={lengthLimit ? lengthLimit : 200}
                        type="text"
                        className={
                            inputClassName
                                ? `editableSpan-input ${inputClassName}`
                                : 'editableSpan-input'
                        }
                        value={inputValue}
                        onChange={onInputChange}
                        onBlur={onBlurHandler}
                        onKeyPress={keyPressHandler}
                    />
                    {errorValue.length > 0 && (
                        <p className={'editableSpan-error'}>{errorValue}</p>
                    )}
                </div>
            ) : (
                <div
                    className={
                        spanClassName
                            ? `${spanClassName} editSpanContainer`
                            : 'editSpanContainer'
                    }
                    onDoubleClick={() => setEditMode(true)}
                >
                    <span
                        className="editSpan"
                        onDoubleClick={() => setEditMode(true)}
                    >
                        {inputValue}
                    </span>
                    {children ? children : ''}
                    <EditIcon className={'editSpanIcon'}/>
                </div>
            )}
        </div>
    )
}
