import imageCompression from 'browser-image-compression'
import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useState
} from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CameraPlacementTypes } from '../../app/types/enums'
import { IUploadFile } from '../../app/types/i-upload-file'
import { useAppDispatch } from '../../hooks/redux-hooks'
import DefaultProfileAvatar from '../../image_files/icons/DefaultProfileAvatar'
import { EditIcon } from '../../image_files/icons/EditIcon'
import { userService } from '../../services/user-service'
import ChangePhotoMenu from '../../shared/changePhotoMenu/ChangePhotoMenu'
import SpinInLog from '../../shared/spin/spin'
import { userActions, userSelectors } from '../../store/user'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { showNotice } from '../../utils/helpers/showNotice'
import './userProfile.css'
import { initialUserProfileFieldsData } from './data'


const UserProfile = () => {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.selectUser)
    const [formFields, setFormFields] = useState(initialUserProfileFieldsData);
    const { setUser } = userActions
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    const [fetchedPhoto, setFetchedPhoto] = useState('')
    const [openModalTrigger, setOpenModalTrigger] = useState(0);
    const [photoData, setPhotoData] = useState<IUploadFile>({} as IUploadFile)

    const updateUserDataHandler = useCallback(
        async (data: FormData, emailData?: { email: string }) => {
            try {
                setLoading(true)
                const response = await userService.updateUser(data)
                showNotice('data-saved-successfully', t)
                dispatch(setUser({ user: response }))
                sessionStorage.removeItem('members')
                setLoading(false)
                if (emailData && emailData.email !== user.email) {
                    showNotice(
                        'confirmation-mail-has-been-sent',
                        t,
                        { className: 'changeEmailNotify' },
                        'info',
                        { email: emailData.email },
                    )
                }
            } catch (e) {
                errorsHandler(e, t)
                setLoading(false)
                if (fetchedPhoto !== photoData?.view) {
                    if (fetchedPhoto?.length > 0) {
                        setPhotoData({ ...photoData, view: fetchedPhoto })
                    } else {
                        setPhotoData({} as IUploadFile)
                    }
                }

            } finally {
                setLoading(false)
            }
        },
        [user, fetchedPhoto, photoData],
    )

    const fetchUserData = useCallback(async () => {
        try {
            setLoading(true)
            const res = await userService.fetchUser()
            const { first_name, middle_name, last_name, company_name, position, about_myself, email, avatar } = res
            if (res) {
                setLoading(false)
                setFormFields({
                    ...formFields,
                    first_name, middle_name, last_name, company_name, position, about_myself, email
                })
                setFetchedPhoto(res?.avatar.medium)
                setPhotoData({ view: res?.avatar.medium })
                setLoading(false)
            }
        } catch (e) {
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }, [user])

    const handleChangeFormField = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: keyof typeof formFields) => {
        setFormFields({ ...formFields, [name]: e.target.value })
    }

    const compareEmails = () =>
        user.email === formFields.email ? 'email' : 'new_email'

    const saveData = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        sessionStorage.removeItem('members')
        const { first_name, middle_name, last_name, company_name, position, about_myself, email, receive_notifications } = formFields
        formData.append('first_name', first_name)
        formData.append('middle_name', middle_name)
        formData.append('last_name', last_name)
        formData.append('company_name', company_name)
        formData.append('position', position)
        formData.append('about_myself', about_myself)
        formData.append(`${compareEmails()}`, email)
        formData.append('receive_notifications', String(receive_notifications))
        await updateUserDataHandler(formData, { email })
    }

    const savePhoto = (data:IUploadFile) =>{
        setPhotoData(data)
        if (data?.view) {
            const formData = new FormData()
            imageCompression
            .getFilefromDataUrl(
                data?.view,
                'photo.jpg',
                new Date().getTime(),
            )
            .then(async (res) => {
                formData.append('avatar', new File([res], 'photo.jpg'))
                await updateUserDataHandler(formData, { email:formFields.email })
            })
            .catch((e) => {
                console.log(e)
                errorsHandler(e,t)
            })
        }
    }
    const removePhoto = async()=>{
        try{
            const formData = new FormData()
            formData.append('avatar', '')
            await updateUserDataHandler(formData, { email:formFields.email })
            setPhotoData({} as IUploadFile)
        }catch(e){
            errorsHandler(e,t)
            console.log(e)
        }
    }

    useEffect(() => {
        fetchUserData()
    }, [])

    console.log(formFields, '--formFields')

    return (
        <div className="user-profile ">
            {createPortal((
                <SpinInLog isLoading={loading} />
            ), document.body)}
            <form className="user-profile-form br-5" onSubmit={saveData}>
                <div>
                    <div>
                        <h2 className="font-18-normal text-center">
                            {t('user-profile')}
                        </h2>
                    </div>
                    <div className="settings-profile-image-box">
                        <div className='settings-profile-image-wrapper' onClick={() => setOpenModalTrigger(openModalTrigger + 1)}>
                            <div className='settings-profile-image-content'>
                                {
                                    photoData?.view
                                        ? <img src={photoData.view} alt="profile photo" />
                                        : <DefaultProfileAvatar />
                                }
                            </div>
                            <div className='settings-profile-image-phone'>
                                <EditIcon
                                    width={30} height={30}
                                    className='settings-profile-edit-icon'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="user_bottom_data_bottom_box">
                    <div className="user_data_box">
                        <label className="user_profile_label">
                            <p>{t('firstName')}</p>
                            <input
                                type="text"
                                defaultValue={formFields.first_name}
                                className={'font-14-normal'}
                                onChange={(e) => handleChangeFormField(e, 'first_name')}
                            />
                        </label>
                        <label className="user_profile_label">
                            <p>{t('email-address')}</p>
                            <input
                                type="email"
                                defaultValue={formFields.email}
                                className={'font-14-normal'}
                                onChange={(e) => handleChangeFormField(e, 'email')}
                            />
                        </label>
                    </div>
                    <div className="user_data_box">
                        <label className="user_profile_label">
                            <p>{t('lastName')}</p>
                            <input
                                type="text"
                                defaultValue={formFields.last_name}
                                className={'font-14-normal'}
                                onChange={(e) => handleChangeFormField(e, 'last_name')}
                            />
                        </label>
                        <label className="user_profile_label">
                            <p>{t('company')}</p>
                            <input
                                type="text"
                                defaultValue={formFields.company_name}
                                className={'font-14-normal'}
                                onChange={(e) => handleChangeFormField(e, 'company_name')}
                            />
                        </label>
                    </div>
                    <div className="user_data_box">
                        <label className="user_profile_label">
                            <p>{t('middleName')}</p>
                            <input
                                type="text"
                                defaultValue={formFields.middle_name}
                                className={'font-14-normal'}
                                onChange={(e) => handleChangeFormField(e, 'middle_name')}
                            />
                        </label>
                        <label className="user_profile_label">
                            <p>{t('position')}</p>
                            <input
                                type="text"
                                defaultValue={formFields.position}
                                className={'font-14-normal'}
                                onChange={(e) => handleChangeFormField(e, 'position')}
                            />
                        </label>
                    </div>
                    <div className="user_bottom_comment_box">
                        <label className="user_profile_label">
                            <p>{t('bio')}</p>
                            <textarea
                                defaultValue={formFields.about_myself}
                                className={'font-14-normal'}
                                onChange={(e) => handleChangeFormField(e, 'about_myself')}
                                placeholder={t('tell-about-working-experience')}
                            ></textarea>
                        </label>
                    </div>
                    <div className="user_bottom_save_box">
                        <input
                            type="submit"
                            value={`${t('save')}`}
                            className="btn_save font-16-normal"
                        />
                    </div>
                </div>
            </form>
            <ChangePhotoMenu
                isDeleteMode={!!(photoData?.view)}
                isCroopingMode
                openModalTrigger={openModalTrigger}
                cameraPlacement={CameraPlacementTypes.ENVIRONMENT}
                accept={['jpeg', 'png', 'jpg']}
                onRemove={removePhoto}
                onSave={savePhoto}
            />
        </div>
    )
}

export default UserProfile
