import { Button, Modal, Skeleton } from "antd";
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import { IUserInvitation } from "../../app/types/dto/user-invitation";
import { RoleTypes } from "../../app/types/enums";
import { IUser } from "../../app/types/models/users";
import { useAppDispatch } from "../../hooks/redux-hooks";
import { invitationService } from "../../services/invitation-service";
import { membersService } from "../../services/memembers-service";
import { userService } from "../../services/user-service";
import SelectInLog from "../../shared/select/Select";
import { selectCurrentProject } from "../../store/currentProject/selectors";
import { setMembers } from "../../store/members/actions";
import { selectMembers } from "../../store/members/selectors";
import { selectUser } from "../../store/user/selectors";
import { errorsHandler } from "../../utils/helpers/errors/errors-hendler";
import { getValidText } from "../../utils/helpers/getValidText";
import { showNotice } from "../../utils/helpers/showNotice";
import Participant from "../team/components/participant/Participant";
import { getRoleOptions } from "../team/data/getRoleOptions";
import CopyLink from "./CopyLink";
import ShareProjectItem from "./ShareProjectItem";
import './shareProject.scss';
import { IShareProjectProps } from "./types";

const ShareProject: FC<IShareProjectProps> = ({isOpenModal, setIsOpenModal}) => {
    const {t} = useTranslation()
    const currentProject = useSelector(selectCurrentProject);
    const members = useSelector(selectMembers)
    const [memberInvitationStatus, setMemberInvitationStatus] = useState<RoleTypes | ''>(RoleTypes.member)
    const [inviteLoading, setInviteLoading] = useState(false)
    const [generateLinkLoading, setGenerateLinkLoading] = useState(false)
    const [userSelectValue, setUserSelectValue] = useState([])
    const [userSelectOptions, setUserSelectOptions] = useState([])
    const me = useSelector(selectUser)
    const dispatch = useAppDispatch()

    const changedUserObj: IUser = me ? {
        ...me,
        role: getValidText(members?.find(item => item?.user?.id === me?.id)?.role) as RoleTypes
    } : {} as IUser

    const roleOptions = getRoleOptions(t)

    const fetchResentParticipants = async () => {
        try {
            const response = await userService.fetchResentParticipants()
            setUserSelectOptions(response.map(el => ({
                label: <Participant item={el}/>,
                value: el.email
            })))
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchResentParticipants()
    }, [])

    const sendData = async () => {
        const preparedData = {
            role: memberInvitationStatus,
            emails: userSelectValue.map(item => item?.value)
        } as IUserInvitation
        try {
            setInviteLoading(true)
            await invitationService.inviteByEmail(currentProject?.id, preparedData)
            showNotice('invite-sent', t)
            setInviteLoading(false)
            setUserSelectValue([])
        } catch (e) {
            setInviteLoading(false)
            errorsHandler(e, t)
        } finally {
            setInviteLoading(false)
        }

    }

    const changeUserEmailOptions = (value: string[]) => {
        const changedArr = value.map(item => ({label: item, value: item}))
        setUserSelectValue(changedArr)
    }

    const changeMemberInvitationStatus = (value: RoleTypes) => {
        setMemberInvitationStatus(value)
    }


    const fetchMembers = async () => {
        try {
            const response = await membersService.getMembers(currentProject?.id)
            dispatch(setMembers({members: response}))

        } catch (e) {
            console.log(e)
        }
    }

    const showMembersList = () => members && members.map(item => (
        <ShareProjectItem
            key={item?.id}
            member={item}
            me={changedUserObj}
            fetchMembers={fetchMembers}
        />
    ))

    const copyLink = async (role: RoleTypes) => {
        try {
            setGenerateLinkLoading(true)
            const {token} = await invitationService.addInvitationToken(currentProject?.id, {role})
           await navigator?.clipboard?.writeText(window.location.href.split('/')[2] + `/copy-link/?project=${currentProject?.id}&token=${token}`)
            showNotice('Link copied', t)
            setGenerateLinkLoading(false)
        } catch (e) {
            setGenerateLinkLoading(false)
            errorsHandler(e, t)
        }
    }

    return (
        <Modal
            open={isOpenModal}
            onCancel={() => setIsOpenModal(false)}
            footer={null}
        >
            <div className={'share-project'}>
                <div className='share-project-top font-18-normal'>
                    {`${t('access')}: ${currentProject?.name}`}
                </div>
                <div className={'share-project-input-wrapper'}>
                    <SelectInLog value={userSelectValue} options={userSelectOptions} mode={'tags'} maxTagCount={10}
                                 onChange={changeUserEmailOptions}
                                 placeholder={t('add-users').toString()}
                                 className={'font-20-normal share-project-tags-select'}
                    />
                    <div className={'member-status-select'}>
                        <SelectInLog defaultValue={'member'} placeholder={t('select-role')}
                                     options={roleOptions.slice(0, 2)}
                                     onChange={changeMemberInvitationStatus}/>
                    </div>
                    {
                        inviteLoading &&
                        <Skeleton className={'member-status-skeleton'} round={true} active={true} paragraph={{
                            rows: 1,
                        }} title={false}/>
                    }
                    <div className={'share-project-invite-block'}>
                        <Button onClick={sendData} className={'share-project-invite-button'}
                                disabled={userSelectValue.length === 0 || memberInvitationStatus === ''}
                                type={'primary'}>{t('invite')}</Button>
                    </div>
                </div>
                <div className={'share-project-select-users mt-54'}>
                    <div className={'font-18-normal'}>
                        {t('users-with-access')}
                    </div>
                    <SimpleBar className={'share-project-scrollbar'}>
                        <ul className={'share-project-users-list'}>
                            {showMembersList()}
                        </ul>
                    </SimpleBar>
                      <CopyLink roleOptions={roleOptions} copyLink={copyLink} me={changedUserObj} isLoading={generateLinkLoading}/>
                </div>
            </div>
        </Modal>
    );
};

export default ShareProject;


