import React, { FC } from 'react'
import { IIconProps } from '../../app/types/i-props'

export const OpenInNewTabIcon: FC<IIconProps> = ({ 
    width,
    height,
    stroke,
    viewBox,
    className,

    onClick,
 }) => {
    return (
        <svg
        fill="none"
        className={className}
        width={width ? width : 24}
        height={height ? height : 24}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox ? viewBox : '0 0 24 24'}
        onClick={onClick}
        >
            <path
                d="M7 9.667C7 8.95967 7.28099 8.28131 7.78115 7.78115C8.28131 7.28099 8.95967 7 9.667 7H18.333C18.6832 7 19.03 7.06898 19.3536 7.20301C19.6772 7.33704 19.9712 7.53349 20.2189 7.78115C20.4665 8.0288 20.663 8.32281 20.797 8.64638C20.931 8.96996 21 9.31676 21 9.667V18.333C21 18.6832 20.931 19.03 20.797 19.3536C20.663 19.6772 20.4665 19.9712 20.2189 20.2189C19.9712 20.4665 19.6772 20.663 19.3536 20.797C19.03 20.931 18.6832 21 18.333 21H9.667C9.31676 21 8.96996 20.931 8.64638 20.797C8.32281 20.663 8.0288 20.4665 7.78115 20.2189C7.53349 19.9712 7.33704 19.6772 7.20301 19.3536C7.06898 19.03 7 18.6832 7 18.333V9.667Z"
                stroke={stroke ? stroke : '#2B506B'}
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M4.012 16.737C3.705 16.5626 3.44965 16.31 3.2719 16.0049C3.09415 15.6998 3.00034 15.3531 3 15V5C3 3.9 3.9 3 5 3H15C15.75 3 16.158 3.385 16.5 4"
                stroke={stroke ? stroke : '#2B506B'}
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
