import { Modal } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import SimpleBar from "simplebar-react"
import { ICustomGroupMeasurement } from "../../../../../../../../app/types/models/i-custom-group"
import CustomGroup from "../../../../../../../../entities/customGroup/CustomGroup"
import CustomGroupsView from "../../../../../../../../entities/customGroupsView/CustomGroupsView"
import CoreStudyIcon from '../../../../../../../../image_files/icons/CoreStudyIcon'
import CoreStudyIcon2 from '../../../../../../../../image_files/icons/CoreStudyIcon2'
import CoreStudyIcon3 from '../../../../../../../../image_files/icons/CoreStudyIcon3'
import CoreStudyIcon4 from '../../../../../../../../image_files/icons/CoreStudyIcon4'
import { PlusIcon } from "../../../../../../../../image_files/icons/PlusIcon"
import { XIcon } from "../../../../../../../../image_files/icons/XIcon"
import { customGroupService } from "../../../../../../../../services/custom-group-service"
import PopoverInLog from "../../../../../../../../shared/popoverInlog/PopoverInLog"
import { currentProjectSelectors } from '../../../../../../../../store/currentProject'
import { selectCurrentProject } from "../../../../../../../../store/currentProject/selectors"
import { CONDUCTIVITY, PYROLYSIS, YOUNG_MODULE } from '../../../../../../../../utils/constants'
import { errorsHandler } from "../../../../../../../../utils/helpers/errors/errors-hendler"
import { RenderedGroupSettingByParam } from '../../helpers/RenderedGroupSettingByParam'
import { changeSettingsListByParams } from '../../helpers/changeSettingsListByParams'
import { StudiesIconWrap } from '../coreForm/components/studiesIconWrap/StudiesIconWrap'
import CurrentCustomMeasurementsList from "./components/currentCustomMeasurementList/CurrentCustomMeasurementList"
import RenderCustomGroupMeasurement from "./components/renderCustomGroupMeasurement/RenderCustomGroupMeasurement"
import './studies.css'
import { ICustomGroupListItem } from "./types"
export const CUSTOM_GROUP_UNIQUE_INDEX = 'custom'

const Studies = () => {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [activeGroupId, setActiveGroupId] = useState<string | number>(1) //order number of group
    const [activeGroupItem, setActiveGroupItem] = useState('')
    const [standardGroupId, setStandardGroupId] = useState(null)
    const [mechanicalGroupId, setMechanicalGroupId] = useState(null)
    const [thermalGroupId, setThermalGroupId] = useState(null)
    const [chemicalGroupId, setChemicalGroupId] = useState(null)
    const currentProject = useSelector(selectCurrentProject)
    const [isOpenCustomGroupModal, setIsOpenCustomGroupModal] = useState(false)
    const [customModalRefreshTrigger, setCustomModalRefreshTrigger] = useState(0)
    const [customGroupsDataList, setCustomGroupsDataList] = useState<ICustomGroupListItem[]>([])
    const [currentCustomGroup, setCurrentCustomGroup] = useState({} as ICustomGroupListItem)
    const [currentCustomMeasurement, setCurrentCustomMeasurement] = useState<ICustomGroupMeasurement>({} as ICustomGroupMeasurement)
    const {id:currentCoreId} = useSelector(currentProjectSelectors.selectCurrentCore)

    const toggleItemHandler = (itemId) => {
        if (activeGroupItem === itemId) {
            return {textDecoration: 'underline'}
        } else return {}
    }

    const showActiveIconHandler = (id, name) => {
        setActiveGroupId(id)
        setActiveGroupItem(name)
        setCurrentCustomMeasurement({} as ICustomGroupMeasurement)
    }

    useEffect(() => {
        if (activeGroupId === 1) {
            setActiveGroupItem('porosity')
        } else if (activeGroupId === 2) {
            setActiveGroupItem(YOUNG_MODULE)
        } else if (activeGroupId === 3) {
            setActiveGroupItem(CONDUCTIVITY)
        } else if (activeGroupId === 4) {
            setActiveGroupItem(PYROLYSIS)
        } else {
            setActiveGroupItem('')
        }
    }, [activeGroupId])

    //custom groups
    const getAllCustomGroupData = useCallback(async () => {
        try {
            setIsLoading(true)
            const groupResponse = await customGroupService.getGroups(currentProject?.id, currentCoreId)
            const list = [...groupResponse] as any
            if (Array.isArray(groupResponse) && groupResponse.length > 0) {
                // setCustomGroupList(groupResponse)
                for (let i = 0; i < groupResponse.length; i++) {
                    const groupItem = groupResponse[i]
                    const measurementResponse = await customGroupService.getCustomMeasurements(currentProject?.id, currentCoreId, groupItem?.id)
                    list[i].measurements = measurementResponse
                    if (Array.isArray(measurementResponse) && measurementResponse.length > 0) {
                        for (let j = 0; j < measurementResponse.length; j++) {
                            const measurementItem = measurementResponse[j]
                            const parametersGroupResponse = await customGroupService.getParameterGroups(currentProject?.id, currentCoreId, groupItem?.id, measurementItem?.id)
                            list[i].measurements[j].groupsParameters = parametersGroupResponse
                            if (Array.isArray(parametersGroupResponse) && parametersGroupResponse.length > 0) {
                                for (let k = 0; k < parametersGroupResponse.length; k++) {
                                    const parameterGroupItem = parametersGroupResponse[k];
                                    const parameterResponse = await customGroupService.getParameters(currentProject?.id, currentCoreId, groupItem?.id, measurementItem?.id, parameterGroupItem?.id)
                                    list[i].measurements[j].groupsParameters[k].parameters = parameterResponse
                                    if (parameterResponse && Array.isArray(parameterResponse)) {
                                    } else {
                                    }
                                }
                            } else {
                            }
                        }
                    } else {
                    }
                }
                setCustomGroupsDataList(list)
            } else {
                setCustomGroupsDataList([])
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            errorsHandler(e, t)
            console.log(e)
        }
    }, [currentProject, currentCoreId])

    const selectActiveCustomGroup = (item: ICustomGroupListItem) => {
        //need to unique id of customGroup => 'custom' + id
        setCurrentCustomGroup(item)
        setActiveGroupId(CUSTOM_GROUP_UNIQUE_INDEX+item.id)
        setCurrentCustomMeasurement({} as ICustomGroupMeasurement)
    }

    const deleteCustomGroup = async (id: number) => {
        try {
            setIsLoading(true)
            await customGroupService.deleteGroup(currentProject?.id, currentCoreId, id)
            setCustomGroupsDataList(customGroupsDataList.filter(el => el.id !== id))
            showActiveIconHandler(1, 'porosity')
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            errorsHandler(e, t)
        }

    }

    useEffect(() => {
        getAllCustomGroupData()
    }, [currentProject, currentCoreId])

    useEffect(()=>{
        if(currentCoreId){
            setActiveGroupId(1)
        }
    },[currentCoreId])

    return (
        <>
            <SimpleBar className={'core-info-scrollbar'}>
                <div>
                    <div className={'coreStudies'}>
                        <div className="coreStudies__menu">
                            <div className="coreStudies__menuIcons">
                                <StudiesIconWrap
                                    isActive={activeGroupId === 1}
                                    handler={() => showActiveIconHandler(1, 'porosity')}
                                    icon={(
                                        <PopoverInLog content={<div
                                            className={'font-12-normal font-white'}>{t('standard-studies').toString()}</div>}>
                                            <CoreStudyIcon className={'cursorPointer'}/>
                                        </PopoverInLog>
                                    )}
                                />
                                <StudiesIconWrap
                                    isActive={activeGroupId === 2}
                                    handler={() => showActiveIconHandler(2, 'mechanical')}
                                    icon={(
                                        <PopoverInLog content={<div
                                            className={'font-12-normal font-white'}>{t('mechanical-studies').toString()}</div>}>
                                            <CoreStudyIcon2 className={'cursorPointer'}/>
                                        </PopoverInLog>
                                    )}
                                />
                                <StudiesIconWrap
                                    isActive={activeGroupId === 3}
                                    handler={() => showActiveIconHandler(3, 'default')}
                                    icon={(
                                        <PopoverInLog content={<div
                                            className={'font-12-normal font-white'}>{t('thermal-studies').toString()}</div>}>
                                            <CoreStudyIcon3 className={'cursorPointer'}/>
                                        </PopoverInLog>
                                    )}
                                />
                                <StudiesIconWrap
                                    isActive={activeGroupId === 4}
                                    handler={() => showActiveIconHandler(4, 'default')}
                                    icon={(
                                        <PopoverInLog content={<div
                                            className={'font-12-normal font-white'}>{t('chemical-studies').toString()}</div>}>
                                            <CoreStudyIcon4 className={'cursorPointer'}/>
                                        </PopoverInLog>
                                    )}
                                />
                                <CustomGroupsView
                                    currentGroupId={activeGroupId}
                                    selectActiveGroup={selectActiveCustomGroup}
                                    list={customGroupsDataList}
                                    isLoading={isLoading}
                                    deleteGroup={deleteCustomGroup}
                                />

                                <StudiesIconWrap
                                    isActive={activeGroupId === 5}
                                    handler={() => setIsOpenCustomGroupModal(true)}
                                    icon={(
                                        <PopoverInLog content={<div
                                            className={'font-12-normal font-white'}>{t('create-group-of-studies').toString()}</div>}>
                                            <PlusIcon className={'cursor-pointer'}/>
                                        </PopoverInLog>
                                    )}
                                />
                            </div>
                            <div className="coreStudies__menuItem">
                                {Number(activeGroupId) < 5 && changeSettingsListByParams(
                                    activeGroupId,
                                    toggleItemHandler,
                                    setActiveGroupItem,
                                    t,
                                )}
                                {typeof  activeGroupId==='string'
                                    && <CurrentCustomMeasurementsList
                                        currentCustomGroup={currentCustomGroup}
                                        currentCustomMeasurement={currentCustomMeasurement}
                                        setCurrentCustomMeasurement={setCurrentCustomMeasurement}
                                    />
                                }

                            </div>
                        </div>
                        <div className="coreStudies__workSpace">
                            {RenderedGroupSettingByParam(
                                activeGroupItem,
                                currentProject?.id,
                                currentCoreId,
                                standardGroupId,
                                setActiveGroupItem,
                                setStandardGroupId,
                                mechanicalGroupId,
                                setMechanicalGroupId,
                                thermalGroupId,
                                setThermalGroupId,
                                chemicalGroupId,
                                setChemicalGroupId,
                            )}

                            {Object.keys(currentCustomMeasurement).length > 0 ?
                                <RenderCustomGroupMeasurement measurement={currentCustomMeasurement}
                                                              coreId={currentCoreId}
                                                              groupId={currentCustomGroup.id}
                                />
                                : ''
                            }
                        </div>
                    </div>
                </div>
                <Modal
                    width={650}
                    wrapClassName={'custom-group-modal'}
                    open={isOpenCustomGroupModal} onCancel={() => {
                        setCustomModalRefreshTrigger(customModalRefreshTrigger+1)
                        setIsOpenCustomGroupModal(false)
                    }}
                    footer={null}
                    closeIcon={<XIcon width={13} height={13} className={'notification-top-cancel-icon'}/>}
                >
                    <CustomGroup coreId={currentCoreId}
                                 closeModal={() => setIsOpenCustomGroupModal(false)}
                                 refreshTrigger={customModalRefreshTrigger}
                                 openModal={isOpenCustomGroupModal}
                                 refechGroups={getAllCustomGroupData}
                    />
                </Modal>
            </SimpleBar>
        </>
    )
}

export { Studies }

