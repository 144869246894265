import { AxiosResponse } from "axios"
import { instance } from "../app/api"
import { ICoreBoxIntervalRequest, ICoreBoxIntervalResponse, ICoreBoxPhotoRequest, ICoreBoxPhotoResponse } from "../app/types/dto/core-box-data"
import { ACCESS_TOKEN } from "../utils/constants"

export const coreBoxService = {
  getInterval: async (projectId: number,params?:{well?:number | string | boolean,wellbore?:number | string | boolean}) => {
    return await instance.get<undefined, AxiosResponse<ICoreBoxIntervalResponse[]>>(`projects/${projectId}/wells/core-box-interval/`, {
      params,
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(ACCESS_TOKEN),
        )}`,
      }
    }).then(data => data.data)
  },
  getIntervalById: async (projectId: number, intervalId: number) => {
    return await instance.get<undefined, AxiosResponse<ICoreBoxIntervalResponse>>(`projects/${projectId}/wells/core-box-interval/${intervalId}/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(ACCESS_TOKEN),
        )}`,
      },
    }).then(data => data.data)
  },
  updateInterval: async (projectId: number, intervalId: number, data: any) => {
    return await instance.patch<undefined, AxiosResponse<ICoreBoxIntervalResponse>>(`projects/${projectId}/wells/core-box-interval/${intervalId}/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(ACCESS_TOKEN),
        )}`,
      },
    }).then(data => data.data)
  },
  addInterval: async (projectId: number, data: ICoreBoxIntervalRequest) => {
    return await instance.post<undefined, AxiosResponse<ICoreBoxIntervalResponse>>(`projects/${projectId}/wells/core-box-interval/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(ACCESS_TOKEN),
        )}`,
      },
    }).then(data => data.data)
  },
  deleteInterval: async (projectId: number, intervalId: number) => {
    return await instance.delete<undefined, undefined>(`projects/${projectId}/wells/core-box-interval/${intervalId}/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(ACCESS_TOKEN),
        )}`,
      },
    })
  },
  getPhotoById: async (projectId: number,photoId:number) => {
    return await instance.get<undefined, AxiosResponse<ICoreBoxPhotoResponse>>(`projects/${projectId}/wells/core-box-photo/${photoId}/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(ACCESS_TOKEN),
        )}`,
        'Access-Control-Allow-Origin':'http://localhost:3000'
      },
    }).then(data => data.data)
  },
  addPhoto: async (projectId: number, data: any) => {
    return await instance.post<undefined, AxiosResponse<ICoreBoxPhotoResponse>>(`projects/${projectId}/wells/core-box-photo/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(ACCESS_TOKEN),
        )}`,
      },
    }).then(data => data.data)
  },
  updatePhoto: async (projectId: number, photoId: number, data: FormData) => {
    return await instance.patch<undefined, AxiosResponse<ICoreBoxPhotoResponse>>(`projects/${projectId}/wells/core-box-photo/${photoId}/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(ACCESS_TOKEN),
        )}`,
      },
    }).then(data => data.data)
  },
  deletePhoto: async (projectId: number, photoId: number) => {
    return await instance.delete<undefined, undefined>(`projects/${projectId}/wells/core-box-photo/${photoId}/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(ACCESS_TOKEN),
        )}`,
      },
    })
  },
}