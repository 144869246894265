import React, { FC, useCallback, useEffect, useState } from 'react'
import { PlusIcon } from '../../image_files/icons/PlusIcon'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { getEntity } from '../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/helpers/getEntity'
import { TargetParameters } from '../../app/types/enums'
import StudyInput from '../../shared/studyInput/StudyInput'
import './capillaryCurve.css'
import { standardStudyGroupService } from '../../services/standard-study-group-service'
import SettingCancelIcon from '../../image_files/icons/SettingCancelIcon'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { showNotice } from '../../utils/helpers/showNotice'

interface IProps {
    projectId: number | null
    coreId: number | null
    groupId: number | null

    setGroup: (value: number | null) => void
}

const CapillaryCurve: FC<IProps> = ({
    projectId,
    coreId,
    groupId,

    setGroup,
}) => {
    const [loading, setLoading] = useState(false)
    const [pointsData, setPointsData] = useState(null)
    const [settingsData, setSettingsData] = useState(null)
    const [currentEquipmentOptions, setCurrentEquipmentOptions] = useState(null)
    const [currentMethodOptions, setCurrentMethodOptions] = useState(null)
    const { t } = useTranslation()

    const checkIsReadyTheFields = (groupId, targetSetting) => {
        return (
            groupId &&
            targetSetting.krw !== '' &&
            targetSetting.p !== '' &&
            Number(targetSetting.krw) > 0
        )
    }

    const createCapillaryPoint = async (data) => {
        try {
            const capillaryResponse =
                await standardStudyGroupService.createCapillaryCurvePoint(
                    projectId,
                    coreId,
                    data.groupId,
                    data.body,
                )
            setPointsData([capillaryResponse])
            showNotice('setting-created-successfully', t)
        } catch (e) {
            toast.error(`${t('server-error')}`, {
                position: 'top-center',
            })
        }
    }

    const createCapillaryCurve = async (data) => {
        try {
            const capillaryResponse =
                await standardStudyGroupService.createCapillaryCurve(
                    projectId,
                    coreId,
                    data.groupId,
                    data.body,
                )
            setSettingsData(capillaryResponse)
            await createCapillaryPoint({
                groupId: data.groupId,
                body: data.pointBody,
            })
        } catch (e) {
            console.log(e)
        }
    }

    const updateCapillaryCurve = useCallback(async (data) => {
        try {
            await standardStudyGroupService.updateCapillaryCurve(
                projectId,
                coreId,
                groupId,
                data,
            )
            showNotice(t('setting-updated-successfully'), t)
        } catch (e) {
            errorsHandler(e, t)
        }
    }, [])

    const updatePoint = (pointId, data) => {
        standardStudyGroupService
            .updateCapillaryCurvePoint(
                projectId,
                coreId,
                groupId,
                pointId,
                data,
            )
            .then(() => {
                showNotice(t('setting-updated-successfully'), t)
            })
            .catch((e) => {
                errorsHandler(e, t)
            })
    }

    const changeMethodEquipmentHandler = async (value, name) => {
        if (name === 'equipment') {
            setSettingsData({ ...settingsData, equipment: value })
            await updateCapillaryCurve({
                group: groupId,
                method: settingsData.method,
                equipment: value,
            })
        } else {
            setSettingsData({ ...settingsData, method: value })
            await updateCapillaryCurve({
                group: groupId,
                method: value,
                equipment: settingsData.equipment,
            })
        }
    }

    const addPointAsync = () => {
        if (groupId && pointsData) {
            if (!Array.isArray(pointsData)) {
                setPointsData([
                    pointsData,
                    { krw: '', p: '', id: '', group: groupId },
                ])
            } else {
                setPointsData([
                    ...pointsData,
                    {
                        krw: '',
                        p: '',
                        id: `${pointsData.length}/default`,
                        group: groupId,
                    },
                ])
            }
        } else {
            setPointsData({ krw: '', p: '' })
        }
    }

    const changeSetting = (value: string, name: string, id: number) => {
        let changedSettings = { ...pointsData, [name]: value }
        if (pointsData && !Array.isArray(pointsData)) {
            setPointsData(changedSettings)
            if (!groupId) {
                standardStudyGroupService
                    .createStandardGroup(projectId, coreId, { core: coreId })
                    .then((groupResponse) => {
                        setGroup(groupResponse.data.id)
                        createCapillaryCurve({
                            groupId,
                            body: {
                                ...changedSettings,
                                group: groupId,
                            },
                            pointBody: {
                                ...changedSettings,
                                group: groupId,
                                equipment: currentEquipmentOptions
                                    ? currentEquipmentOptions[0]?.id
                                    : null,
                                method: currentMethodOptions
                                    ? currentMethodOptions[0]?.id
                                    : null,
                            },
                        }).then()
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            } else {
                if (checkIsReadyTheFields(groupId, changedSettings)) {
                    createCapillaryCurve({
                        groupId,
                        body: {
                            ...changedSettings,
                            group: groupId,
                        },
                        pointBody: {
                            ...changedSettings,
                            group: groupId,
                            equipment: currentEquipmentOptions
                                ? currentEquipmentOptions[0]?.id
                                : null,
                            method: currentMethodOptions
                                ? currentMethodOptions[0]?.id
                                : null,
                        },
                    }).then()
                }
            }
        }
        if (Array.isArray(pointsData)) {
            let targetPoint = pointsData.find((item) => item.id === id)
            targetPoint = {
                ...targetPoint,
                [name]: value,
                equipment: currentEquipmentOptions
                    ? currentEquipmentOptions[0]?.id
                    : null,
                method: currentMethodOptions
                    ? currentMethodOptions[0]?.id
                    : null,
            }
            const changedPointsData = pointsData.map((item) =>
                item.id === id ? { ...targetPoint } : item,
            )
            setPointsData(changedPointsData)
            //если данные еще не были сохранены и использ дефолтный id
            if (
                (typeof id === 'string' &&
                    String(id).split('/')[1] === 'default') ||
                String(id) === ''
            ) {
                if (checkIsReadyTheFields(groupId, targetPoint)) {
                    //кривая уже будет создана к тому времени как создасться массив - поэтому создаем сразу поинт
                    if (settingsData && groupId) {
                        standardStudyGroupService
                            .createCapillaryCurvePoint(
                                projectId,
                                coreId,
                                groupId,
                                {
                                    ...targetPoint,
                                    capillary_curve: settingsData.id,
                                },
                            )
                            .then((res) => {
                                const createdSetting = { ...res }
                                const changedSettings = pointsData.map((item) =>
                                    item.id === id ? createdSetting : item,
                                )
                                setPointsData(changedSettings)
                                showNotice(t('setting-created-successfully'), t)
                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    }
                }
            } else {
                updatePoint(id, {
                    ...targetPoint,
                    equipment: currentEquipmentOptions
                        ? currentEquipmentOptions[0]?.id
                        : null,
                    method: currentMethodOptions
                        ? currentMethodOptions[0]?.id
                        : null,
                })
            }
        }
    }

    const deletePointAsync = (item) => {
        setLoading(true)
        if (checkIsReadyTheFields(groupId, item)) {
            standardStudyGroupService
                .deleteCapillaryCurvePoint(projectId, coreId, groupId, item.id)
                .then(() => {
                    const changedItems = pointsData.filter(
                        (el) => el.id !== item.id,
                    )
                    setPointsData(changedItems)
                    setLoading(false)
                    showNotice(t('setting-deleted-successfully'), t)
                })
                .catch((e) => {
                    console.log(e)
                    setLoading(false)
                })
        } else {
            if (pointsData && Array.isArray(pointsData)) {
                setPointsData(pointsData.filter((el) => el.id !== item.id))
                setLoading(false)
            } else {
                setPointsData(null)
                setLoading(false)
            }
        }
    }

    const fetchCurvePointAsync = async () => {
        try {
            const res = await standardStudyGroupService.getCapillaryCurvePoint(
                projectId,
                coreId,
                groupId,
            )
            if (res.data && Array.isArray(res.data)) {
                setPointsData(
                    res.data.map((item) => ({
                        ...item,
                        equipment: null,
                        method: null,
                    })),
                )
            } else {
                setPointsData(null)
            }
        } catch (e) {
            console.log(e)
            // errorsHandler(e, t)
        }
    }

    const fetchCapillaryCurveAsync = async (id) => {
        try {
            const { data } = await standardStudyGroupService.getCapillaryCurve(
                projectId,
                coreId,
                id,
            )
            setSettingsData({
                ...data,
                equipment: data?.equipment?.id,
                method: data?.method?.id,
            })
            await fetchCurvePointAsync()
        } catch (e) {
            setSettingsData(null)
            setPointsData(null)
            console.log(e)
            // errorsHandler(e, t)
        }
    }

    const fetchGroupAsync = async () => {
        try {
            setLoading(true)
            const data = await standardStudyGroupService.getStandardGroup(
                projectId,
                coreId,
            )
            setLoading(false)
            setGroup(data.id)
            if (data && data.id) {
                await fetchCapillaryCurveAsync(data.id)
            }
        } catch (e) {
            console.log(e)
            setSettingsData(null)
            setGroup(null)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchGroupAsync()
        getEntity({
            id: projectId,
            type: 'equipment',
            parameter: TargetParameters.capillary_curve,
            setData: setCurrentEquipmentOptions,
        }).then()
        getEntity({
            id: projectId,
            type: 'method',
            parameter: TargetParameters.capillary_curve,
            setData: setCurrentMethodOptions,
        }).then()
    }, [coreId])

    const renderCommonBlocks = (id, p, krw) => {
        return (
            <>
                <StudyInput
                    type={'input'}
                    initialValue={krw}
                    id={id}
                    name={'krw'}
                    onChange={changeSetting}
                />
                <StudyInput
                    type={'positiveNumber'}
                    initialValue={p}
                    id={id}
                    name={'p'}
                    onChange={changeSetting}
                />
            </>
        )
    }

    if (loading) {
        return <div>...loading</div>
    }

    console.log(settingsData)
    return (
        <div className={'coreStudySetting'}>
            <div className="coreStudySetting__container">
                <div className={'coreStudySetting__top'}>
                    <StudyInput
                        dataList={currentMethodOptions}
                        label={t('method')}
                        type={'addValueSelect'}
                        projectId={projectId}
                        initialValue={
                            settingsData ? settingsData?.method : null
                        }
                        name={'method'}
                        target={TargetParameters.capillary_curve}
                        onChange={changeMethodEquipmentHandler}
                    />
                    <StudyInput
                        dataList={currentEquipmentOptions}
                        label={t('equipment')}
                        projectId={projectId}
                        type={'addValueSelect'}
                        target={TargetParameters.capillary_curve}
                        initialValue={
                            settingsData ? settingsData?.equipment : null
                        }
                        name={'equipment'}
                        onChange={changeMethodEquipmentHandler}
                    />
                </div>
                <button className={'addSettingBtn'} onClick={addPointAsync}>
                    <PlusIcon />
                </button>
                {pointsData && !Array.isArray(pointsData) ? (
                    <div className={'coreStudySetting__measurementUnits'}>
                        <div>{`${t('krw')}`}</div>
                        <div>{t('pressure')}</div>
                    </div>
                ) : pointsData &&
                  Array.isArray(pointsData) &&
                  pointsData.length > 0 ? (
                    <div className={'coreStudySetting__measurementUnits'}>
                        <div>
                            {`${t('krw')}`}
                        </div>
                        <div>{t('pressure')}</div>
                    </div>
                ) : (
                    ''
                )}
                {Array.isArray(pointsData) ? (
                    pointsData.map((item) => (
                        <div
                            className="coreStudySetting__item capillaryCurve__item"
                            key={item.id}
                        >
                            {renderCommonBlocks(item.id, item.p, item.krw)}
                            {
                                <button
                                    className={'deleteSettingBtn'}
                                    onClick={() => deletePointAsync(item)}
                                >
                                    <SettingCancelIcon />
                                </button>
                            }
                        </div>
                    ))
                ) : pointsData ? (
                    <div className="coreStudySetting__item capillaryCurve__item">
                        {renderCommonBlocks(
                            pointsData.id,
                            pointsData.p,
                            pointsData.krw,
                        )}
                        {
                            <button
                                className={'deleteSettingBtn'}
                                onClick={() => deletePointAsync(pointsData)}
                            >
                                <SettingCancelIcon />
                            </button>
                        }
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

export { CapillaryCurve }
