import {Link} from 'react-router-dom'
import './recoveryChange.css'
import React from 'react'
import {getLocalizedText} from '../../utils/helpers/getLocalizedText'
import {routes} from '../../utils/constants'
import {useTranslation} from 'react-i18next'
import SignHeader from "../../shared/signHeader/SignHeader";

function RecoveryChange() {
    const { t } = useTranslation()
    return (
        <div className="signBlock recoveryChange">
            <SignHeader
                buttonRoute={{link: routes.REGISTRATION, name: t('registration')}}
            />
            <div className="signWrapper recoveryChange__content">
                <h2 className="signForm__header">
                    {getLocalizedText('registration-finished-successfully', t)}
                </h2>
                <p className="signForm__topContent">
                    {getLocalizedText(
                        'password-was-changed',
                        t,
                    )}
                </p>
                <Link to={routes.LOGIN} className="signForm__textLink">
                    {t('sign-in')}
                </Link>
            </div>
        </div>
    )
}

export default RecoveryChange
