import React, { SetStateAction, useCallback } from 'react'
import { ISelectOption } from '../../../../app/types/common'
import { RoadMapDetalizationTypes } from '../../../../app/types/enums'
import { ITask } from '../../../../app/types/models/tasks'
import RoadMapItem from '../roadMapItem/RoadMapItem'
import './RoadMapItems.css'

const RoadMapItems = ({
    items,
    detalizationData,
    tagsOptions,
    saveHeightList,
}: {
    items: ITask[]
    detalizationData: {
        detalization: RoadMapDetalizationTypes
        startPeriod: Date | string
        endPeriod: Date | string
    }
    tagsOptions: ISelectOption[],
    saveHeightList:(data:SetStateAction<{id:number,height:number}[]>)=>void
}) => {

    const showTasks = () => {
        return items.map((el, i) => (
            <RoadMapItem
                key={el?.id}
                item={el}
                tagsOptions={tagsOptions}
                detalizationData={detalizationData}
                saveBlockHeight={(height:number)=>{
                    saveHeightList(prev=>(prev.map(val=>val.id===el?.id ? {...val,height} : val)))
                }}
            />
        ))
    }

    return <ul className="roadmap-items">{showTasks()}</ul>
}

export default RoadMapItems
