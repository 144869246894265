import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/constants'
import { userService } from '../../services/user-service'
import { SocialNames } from '../../app/types/enums'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import {useTranslation} from "react-i18next";

function VerifyGoogle() {
    const [url] = useState(window.location.href)
    const urlCode = url.slice(url.indexOf('=') + 1, url.indexOf('&'))
    let deCode = decodeURIComponent(urlCode)
    const [active, setActive] = useState(false)
    const navigate = useNavigate()
    const {t} = useTranslation()

    const signAsync = useCallback(async () => {
        try {
            const response = await userService.socialLogin(
                { code: deCode },
                SocialNames.google,
            )
            if (response.user) {
                localStorage.setItem('token', response.access_token)
                setActive(true)
            }
        } catch (e) {
            errorsHandler(e,t)
        }
    }, [deCode])

    useEffect(() => {
        signAsync().then()
    }, [deCode])

    active && navigate(routes.HOME)
    return <div className="VerifyGoogleAccount"></div>
}

export default VerifyGoogle
