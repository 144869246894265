import {CustomGroupParameters} from "../../entities/customGroup/types";
import {ValueTypes} from "../../entities/customGroup/components/add-parameter-zone/fieldsInterval/types";
import {ISelectOption} from "../../app/types/common";
import {IRenderParameterGroup} from "../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/components/coreStudies/components/renderCustomGroupMeasurement/types";

export interface ICustomParameterRules{
    type:ValueTypes,
    value:ValueTypes.ANY | ValueTypes.POSITIVE | string[]
}

export enum CustomParameterErrorTypes{
    REQUIRED='required'
}

export interface ICustomGroupParameterProps{
    value?:any
    parameterGroup?:IRenderParameterGroup
    type:CustomGroupParameters
    label:{en:string,ru:string}[] | {en:string,ru:string}
    onChange:(data:{value:any,isError:boolean})=>void
    rules?:ICustomParameterRules | ICustomParameterRules[]
    options?:ISelectOption[]
}