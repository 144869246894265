import { createAction } from '@reduxjs/toolkit'
import {INotification} from "../../app/types/models/i-notification";

const setNotifications = createAction<{
    notifications: INotification[]
}>('notificationActions/setNotifications')

const deleteNotification = createAction<{ id: number }>('notificationActions/deleteNotification')

const updateNotification = createAction<{ id: number; notification: INotification }>(
    'notificationActions/updateNotification',
)

export {
    setNotifications,
    deleteNotification,
    updateNotification,
}
