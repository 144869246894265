export const generateSelectOptions = (data: { en: any, ru: any },lang:string) => {
    if (data.en && data.ru && Array.isArray(data?.en) && Array.isArray(data?.ru)) {
        if (lang === 'ru') {
            return data?.ru.map(el=>({...el,title:''}))
        } else {
            return data?.en.map(el=>({...el,title:''}))
        }
    } else {
        if (lang === 'ru') {
            return data.en && data?.en.length > 0 ? data.en.split(',').map(item => ({
                label: item,
                value: item,
                title:''
            })) : []
        } else {
            return data.ru && data?.ru.length > 0 ? data.ru.split(',').map(item => ({
                label: item,
                value: item,
                title:''
            })) : []
        }
    }
}