import { FC, LegacyRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';

import { Button } from 'antd';
import React from 'react';
import { CameraPlacementTypes } from '../../app/types/enums';
import CropImage from '../crop-image/CropImage';
import './CameraBox.css';
import { ICameraProps } from './types';
import { useUserMedia } from '../../hooks/useUserMedia';


const CameraBox: FC<ICameraProps> = (props) => {
  const {
    placementType,
    resetTrigger,
    isCroopingMode,
    isHasCamera,
    closeModal,
    onSave
  } = props;

  const { t } = useTranslation();
  const webcamRef = useRef<Webcam>();

  const [url, setUrl] = useState<string | null>(null);
  const [changedUrl, setChangedUrl] = useState<string | null>(null);
  const [hasPhoto, setHasPhoto] = useState(false);
  const userMediaData = useUserMedia(['environment', 'user']);

  const isHasCameraStatus =
    isHasCamera !== undefined ? isHasCamera : userMediaData.isHasCamera;
  const takePhoto = async () => {
    const imageSrc = webcamRef?.current?.getScreenshot() as string;
    setUrl(imageSrc);
    setHasPhoto(true);
  };

  const resetPhoto = () => {
    setUrl(null);
    setHasPhoto(false);
  };

  const videoConstraints = {
    facingMode:
      placementType && placementType === CameraPlacementTypes.ENVIRONMENT
        ? {
          exact: CameraPlacementTypes.ENVIRONMENT
        }
        : CameraPlacementTypes.FACE
  };

  const saveData = () => {
    if (changedUrl) {
      onSave(changedUrl);
      resetPhoto();
      closeModal();
    }
  };

  useEffect(() => {
    if (resetTrigger && resetTrigger > 0) {
      resetPhoto();
    } else {
      userMediaData.refresh();
    }
  }, [resetTrigger]);


  return (
    <div className="webcam-camera">
      {url && (
        <CropImage
          url={url}
          setUrl={setChangedUrl}
          disableCropImage={!isCroopingMode}
        />
      )}
      {isHasCameraStatus ? (
        <div
          className={`webcam-camera-video-space  ${hasPhoto ? 'webcam-camera-video-space-hidden' : ''
            }`}
        >
          <Webcam
            ref={webcamRef as LegacyRef<Webcam> | undefined}
            audio={false}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            imageSmoothing={true}
            mirrored={true}
          />
        </div>
      ) : (
        <>
          <div className="grey-stroke-bg font-16-normal webcam-camera-video-space--no-camera">Доступ к камере заблокирован. Для использования предоставьте доступ камеры к сайту в настройках браузера и перезагрузите страницу
          </div>
        </>
      )}

      {/* <Webcam imageSmoothing={true} /> */}
      <div className="webcam-camera-buttons">
        <Button
          onClick={resetPhoto}
          type={'default'}
          disabled={!hasPhoto}
        >
          <span className='font-14-normal'>{t('reshoot')}</span>
        </Button>
        <Button
          type='default'
          onClick={() => {
            hasPhoto ? saveData() : takePhoto();
          }}
        >
          <span className='font-14-normal'>{hasPhoto ? t('save-photo') : t('make-photo')}</span>
        </Button>
      </div>
    </div>
  );
};

export default CameraBox;
