import React from 'react'

export const ErrorIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="7" fill="white" stroke="#FF8C68" strokeWidth="2"/>
            <path d="M7.76113 3.00023L7.84161 6.09476L7.93215 9.57629" stroke="#FF8C68" strokeWidth="2"/>
            <circle cx="8" cy="12" r="1" fill="#FF8C68"/>
        </svg>

    )
}
