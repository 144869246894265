import { slice } from './tasks-slice'
import * as tasksSelectors from './selectors'
import * as actions from './actions'

const tasksActions = {
    ...slice.actions,
    ...actions,
}

const tasksReducer = slice.reducer

export { tasksReducer, tasksSelectors, tasksActions }
