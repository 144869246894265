import { createAction } from '@reduxjs/toolkit'
import { IProject } from '../../app/types/models/project'
import {
    ICore, IPad,
    IResearchEntity,
    IWell,
    IWellBore,
} from '../../app/types/models/logging'
import {IWellLogFile} from "../../app/types/models/well-log-file";
import {IMnemonic} from "../../app/types/models/IMnemonic";

type CurrentProjectLoadingStatusTypes = 'currentPads' | 'currentWells' |'currentWellbores' | 'currentCores'

const setProject = createAction<{ project: IProject }>(
    'currentProjectActions/setProject',
)
const setCurrentProjectLoading = createAction<{ loading: boolean }>(
    'currentProjectActions/setProjectLoadingStatus',
)

const updateProject = createAction<any>('currentProjectActions/updateProject')

const setCurrentPads = createAction<{ pads: IResearchEntity[] }>(
    'currentProjectActions/setCurrentPads',
)

const addCurrentPad = createAction<{ pad: IResearchEntity }>(
    'currentProjectActions/addCurrentPad',
)

const updateCurrentPad = createAction<{ id: number; name: string }>(
    'currentProjectActions/updateCurrentPad',
)

const deleteCurrentPad = createAction<{ id: number }>(
    'currentProjectActions/deleteCurrentPad',
)

const selectCurrentPad = createAction<IPad>(
    'currentProjectActions/selectCurrentPad',
)

const setCurrentWells = createAction<{ wells: IWell[] }>(
    'currentProjectActions/setCurrentWells',
)

const addCurrentWell = createAction<{ well: IWell }>(
    'currentProjectActions/addCurrentWell',
)

const updateCurrentWell = createAction<{ id: number; data: IWell | null }>(
    'currentProjectActions/updateCurrentWell',
)

const deleteCurrentWell = createAction<{ id: number }>(
    'currentProjectActions/deleteCurrentWell',
)

const selectCurrentWell = createAction<IWell>(
    'currentProjectActions/selectCurrentWell',
)

const selectCurrentWellBore = createAction<IWellBore>(
    'currentProjectActions/selectCurrentWellBore',
)

const setCurrentWellBores = createAction<{ wellBores: IWellBore[] }>(
    'currentProjectActions/setCurrentWellBores',
)

const addCurrentWellBore = createAction<{ wellBore: IWellBore }>(
    'currentProjectActions/addCurrentWellBore',
)

const updateCurrentWellBore = createAction<{ id: number; data: IWellBore | null }>(
    'currentProjectActions/updateCurrentWellBore',
)

const deleteCurrentWellBore = createAction<{ id: number }>(
    'currentProjectActions/deleteCurrentWellBore',
)

const setCurrentCores = createAction<{ cores: ICore[] }>(
    'currentProjectActions/setCurrentCores',
)

const addCurrentCore = createAction<{ core: ICore }>(
    'currentProjectActions/addCurrentCore',
)

const updateCurrentCore = createAction<{ id: number; name: string }>(
    'currentProjectActions/updateCurrentCore',
)

const updateCurrentCoreInfo = createAction<{ id: number; core: ICore }>(
    'currentProjectActions/updateCurrentCoreInfo',
)

const deleteCurrentCore = createAction<{ id: number }>(
    'currentProjectActions/deleteCurrentCore',
)
//поменять тип ани
const addCurrentCorePhoto = createAction<{ id: number; photo: any }>(
    'currentProjectActions/addCurrentCorePhoto',
)

const deleteCurrentCorePhoto = createAction<{ id: number; photoId: number }>(
    'currentProjectActions/deleteCurrentCorePhoto',
)

const setWellLogFiles = createAction<IWellLogFile[]>('currentProjectActions/setWellLogFiles')
const setCurrentWellLogFile = createAction<IWellLogFile>('currentProjectActions/setCurrentWellLogFile')

const deleteWellLogFile = createAction<{id:number}>('currentProjectActions/deleteWellLogFile')
const addWellLogFile = createAction<IWellLogFile>('currentProjectActions/addWellLogFile')
const updateWellLogFile = createAction<IWellLogFile>('currentProjectActions/updateWellLogFile')

const setWellLogMnemonics = createAction<IMnemonic[]>('currentProjectActions/setWellLogMnemonics')
const setWellLogStrangeNans = createAction<string[]>('currentProjectActions/setWellLogStrangeNans')
const setLoadingCurrentItemStatus = createAction<{type:CurrentProjectLoadingStatusTypes,status:boolean}>('currentProjectActions/setLoadingCurrentItemStatus')

const setCurrentCore = createAction<ICore>('currentProjectActions/setCurrentCore')

export {
    setProject,
    updateProject,
    setCurrentPads,
    addCurrentPad,
    selectCurrentPad,
    updateCurrentPad,
    deleteCurrentPad,
    setCurrentWells,
    addCurrentWell,
    updateCurrentWell,
    deleteCurrentWell,
    setCurrentWellBores,
    addCurrentWellBore,
    updateCurrentWellBore,
    deleteCurrentWellBore,
    setCurrentCores,
    addCurrentCore,
    updateCurrentCore,
    updateCurrentCoreInfo,
    deleteCurrentCore,
    addCurrentCorePhoto,
    deleteCurrentCorePhoto,
    setWellLogFiles,
    setCurrentWellLogFile,
    deleteWellLogFile,
    addWellLogFile,
    updateWellLogFile,
    setWellLogMnemonics,
    setWellLogStrangeNans,
    selectCurrentWell,
    selectCurrentWellBore,
    setCurrentProjectLoading,
    setLoadingCurrentItemStatus,
    setCurrentCore
}
