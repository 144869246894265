import React, { FC, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { Button, Modal } from 'antd'
import { IPyrolysisFileItem, IPyrolysisFileItemProps } from './types'
import DownloadIconButton from '../../shared/downloadIconButton/DownloadIconButton'
import SettingCancelIcon from '../../image_files/icons/SettingCancelIcon'
import { getValidText } from '../../utils/helpers/getValidText'
import { XIcon } from '../../image_files/icons/XIcon'
import ExelViewer from '../../shared/exelViewer/ExelViewer'
import axios from 'axios'
import { createPortal } from 'react-dom'
import SpinInLog from '../../shared/spin/spin'

const PyrolysisFileItem: FC<IPyrolysisFileItemProps> = (props) => {
    const { item,isLoading, deleteFile,updateFile } = props
    
    const { t } = useTranslation()
    const [openModal, setOpenModal] = useState(false)
    const [localItem, setLocalItem] = useState<IPyrolysisFileItem>(
        {} as IPyrolysisFileItem,
    )

    const [updateModalTrigger, setUpdateModalTrigger] = useState(0)
    const [isChangedTableData, setIsChangedTableData] = useState(false)
    const [isOpenQuestionModal, setIsOpenQuestionModal] = useState(false)
    const [isLoadingFetchFile, setIsLoadingFetchFile] = useState(false)

    const getCorrectName = () => {
        const name = getValidText(
            localItem?.name ? localItem?.name : 'default-name.xls',
        )
        const arr = name.split('.')
        let namePart = arr[0];
        const namePartArr = namePart.split('_')
        if(namePartArr.length>1){
            namePart = namePartArr.slice(0,namePartArr.length-1).join(' ')
        }
        if(namePart.length>=31){
            namePart = namePart.slice(0,30)
        }
        return {
            name:namePart,
            extension:arr[1]
        }
    }

    const {name,extension} = getCorrectName()

    const exportFile = () => {
        if (localItem.data) {
            const worksheet = XLSX.utils.json_to_sheet(localItem.data as any)
            const workbook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workbook, worksheet, name)
            const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            })
            const blob = new Blob([excelBuffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
            })
            saveAs(blob, `${name}.${extension}`)
        }
    }

    const downloadFile = () => {
        if (localItem.data) {
            if (typeof localItem.data === 'string') {
                const link = document.createElement('a')
                link.href = localItem.data as string
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } else {
                exportFile()
            }
        }
    }

    const fetchFile = async () => {
        try {
            setIsLoadingFetchFile(true)
            const data = await axios.get(item.data as any, {
                responseType: 'arraybuffer',
                headers:{},
            })
            const workbook = XLSX.read(data.data, {
                type: 'binary',
                cellDates: true ,dateNF: "DD-MMM-YYYY",
            })
            const worksheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[worksheetName]
            const sheetData = XLSX.utils.sheet_to_json(worksheet, {
                defval: '',
            })
            if (worksheet) {
                setLocalItem((prev) => {
                    return { ...prev, data: sheetData }
                })
                setOpenModal(true)
                setUpdateModalTrigger((prev) => prev + 1)
            }
            setIsLoadingFetchFile(false)
        } catch (e) {
            setIsLoadingFetchFile(false)
            console.log(e)
        }
    }

    const closeModal = () => {
        if (isChangedTableData) {
            setIsOpenQuestionModal(true)
        } else {
            setOpenModal(false)
            setIsChangedTableData(false)
        }
    }

    const openEditModalView = () => {
        if (localItem.data) {
            if (typeof localItem.data === 'string') {
                fetchFile()
            } else {
                setOpenModal(true)
                setUpdateModalTrigger((prev) => prev + 1)
            }
        }
    }

    const saveData = (data:any) =>{
        setLocalItem(data)
        updateFile(data)
    }

    useEffect(() => {
        if (item) {
            setLocalItem(item)
        }
    }, [item])

    return (
        <>
            {createPortal(<SpinInLog isLoading={isLoadingFetchFile || isLoading} />, document.body)}
            <div className={'coreStudySetting-pyrolysisSetting-file'}>
                <div
                    className={
                        'font-14-normal coreStudySetting-pyrolysisSetting-file-content'
                    }
                >
                    {`${name}.${extension}`}
                </div>
                <div
                    className={'coreStudySetting-pyrolysisSetting-file-buttons'}
                >
                    <div className='font-14-normal'>{getValidText(item.uploaded_at)}</div>
                    <Button type={'primary'} onClick={openEditModalView}>
                        {t('edit')}
                    </Button>
                    <DownloadIconButton
                        className={
                            'coreStudySetting-pyrolysisSetting-file-download'
                        }
                        onClick={downloadFile}
                    />
                    <SettingCancelIcon onClick={()=>deleteFile(item.id)} />
                </div>
            </div>
            <Modal
                title={name}
                width={1400}
                wrapClassName={'coreStudySetting-pyrolysisSetting-modal'}
                open={openModal}
                onCancel={closeModal}
                afterClose={() => {
                    setUpdateModalTrigger((prev) => prev + 1)
                }}
                footer={null}
                closeIcon={
                    <XIcon
                        width={13}
                        height={13}
                        className={'notification-top-cancel-icon'}
                    />
                }
            >
                <ExelViewer
                    tableRows={localItem}
                    isOpenModal={openModal}
                    isOpenQuestionModal={isOpenQuestionModal}
                    updateTrigger={updateModalTrigger}
                    closeModal={() => {
                        setOpenModal(false)
                        setIsOpenQuestionModal(false)
                        setIsChangedTableData(false)
                    }}
                    isChangedData={isChangedTableData}
                    setIsChangedData={setIsChangedTableData}
                    onSave={saveData}
                />
            </Modal>
        </>
    )
}

export default memo(PyrolysisFileItem)
