import { Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IStatus } from '../../../../app/types/models/tasks'
import TagsArrowIcon from '../../../../image_files/icons/TagsArrowIcon'
import './detailsSelect.css'

interface IProps {
    initialStatus?: string | number
    statusesList: IStatus[]

    selectStatus?: (value: number) => void
}

export const DetailsSelect: FC<IProps> = ({
    initialStatus,
    selectStatus,
    statusesList,
}) => {
    const [open, setOpen] = useState(false)
    const { t, i18n } = useTranslation()
    const [dropDownValue, setDropDownValue] = useState<any>(
        initialStatus ? initialStatus : t('without-status'),
    )

    const onChangeDropdownSelect = (value) => {
        selectStatus(value)
        setDropDownValue(value)
    }

    const showOptions = (): DefaultOptionType[] => {
        if (statusesList && statusesList.length > 0) {
            return statusesList.map((el) => ({
                title: '',
                value: el?.id,
                label: i18n.language === 'en' ? el?.name_en : el?.name_ru,
            }))
        } else return []
    }

    useEffect(() => {
        if (initialStatus) {
            setDropDownValue(initialStatus)
        } else {
            setDropDownValue(t('without-status'))
        }
    }, [i18n.language, initialStatus])

    return (
        <div className="detailsSelect">
            <TagsArrowIcon
                className="detailsSelect-icon"
                style={
                    open
                        ? { transform: 'rotate(180deg)' }
                        : { transform: 'rotate(0deg)' }
                }
            />
            <Select
                value={dropDownValue}
                onClick={() => setOpen(!open)}
                onBlur={() => setOpen(false)}
                className={'detailsSelect__statusSelect'}
                bordered={false}
                open={open}
                onChange={onChangeDropdownSelect}
                suffixIcon={false}
                style={{
                    width: '100%',
                }}
                options={showOptions()}
            />
        </div>
    )
}
