import React from 'react'

const CoreStudyIcon2 = ({ className }) => {
    return (
        <svg
            className={className ? className : ''}
            width="45"
            height="45"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width="39"
                height="39"
                rx="4.5"
                fill="#FDFDFD"
            />
            <path
                d="M7 5C7 4.44772 7.44772 4 8 4H26C26.5523 4 27 4.44772 27 5V12.9785C27 13.2988 26.8465 13.5997 26.5873 13.7878L8.58727 26.8483C7.92618 27.328 7 26.8557 7 26.0389V5Z"
                fill="#FEC580"
                fillOpacity="0.6"
            />
            <path
                d="M7.2 5C7.2 4.55817 7.55817 4.2 8 4.2H26C26.4418 4.2 26.8 4.55817 26.8 5V12.9785C26.8 13.2347 26.6772 13.4755 26.4698 13.626L8.46982 26.6864C7.94095 27.0702 7.2 26.6923 7.2 26.0389V5Z"
                stroke="#364F6B"
                strokeOpacity="0.6"
                strokeWidth="0.4"
            />
            <path
                d="M32.1212 34.744C32.1187 35.2963 31.669 35.742 31.1167 35.7395L13.0357 35.6586C12.4852 35.6561 12.0402 35.2091 12.0402 34.6586L12.0402 27.1757C12.0402 26.8546 12.1944 26.553 12.4547 26.365L30.6284 13.2413C31.2914 12.7625 32.2175 13.2387 32.2138 14.0565L32.1212 34.744Z"
                fill="#FEC580"
                fillOpacity="0.6"
            />
            <path
                d="M31.9212 34.7431C31.9192 35.185 31.5594 35.5415 31.1176 35.5395L13.0366 35.4586C12.5962 35.4566 12.2402 35.099 12.2402 34.6586L12.2402 27.1757C12.2402 26.9188 12.3636 26.6775 12.5718 26.5271L30.7455 13.4034C31.2759 13.0204 32.0168 13.4014 32.0138 14.0556L31.9212 34.7431Z"
                stroke="#364F6B"
                strokeOpacity="0.6"
                strokeWidth="0.4"
            />
        </svg>
    )
}

export default CoreStudyIcon2
