import React, {FC} from 'react';
import {ICustomGroupsViewProps} from "./types";
import {useTranslation} from "react-i18next";
import './customGroupView.css';
import PopoverInLog from "../../shared/popoverInlog/PopoverInLog";
import {ICustomGroupListItem} from "../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/components/coreStudies/types";
import SpinInLog from "../../shared/spin/spin";
import {
    CUSTOM_GROUP_UNIQUE_INDEX
} from "../../pages/dataLogging/components/loggingEditSpace/components/coreInfo/components/coreStudies/Studies";

const CustomGroupsView: FC<ICustomGroupsViewProps> = (props) => {
    const {currentGroupId, selectActiveGroup, list, isLoading,deleteGroup} = props
    const {i18n} = useTranslation()

    const showCorrectName = (item: ICustomGroupListItem) => {
        let name = i18n.language === 'ru' ? item.name_ru : item.name_en
        return name && name.length > 0 ? name[0].toUpperCase() : ''
    }

    if (isLoading) {
        return (
            <SpinInLog size={'default'} isLoading={true} className={'custom-group-view-loader'}/>
        )
    }

    const showCustomGroups = () => {
        return list.map((item) => (
            <PopoverInLog key={item.id} content={<div
                className={'font-12-normal font-white'}>{i18n.language === 'ru' ? item.name_ru : item.name_en}</div>}>
                <div className={`custom-group-item ${currentGroupId === CUSTOM_GROUP_UNIQUE_INDEX+item.id ? 'custom-group-item--active' : ''}`}
                     onClick={(e) => {
                         e.preventDefault()
                         e.stopPropagation()
                         selectActiveGroup(item)
                     }}>
                    <div className={'font-20-normal'}>{showCorrectName(item)}</div>
                    <button
                        className={'custom-group-item--delete-group-btn'}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            deleteGroup(item.id)
                        }}
                    >
                        <span className={'font-14-normal font-error'}>x</span>
                    </button>
                </div>
            </PopoverInLog>
        ))
    }


    return (
        <div className={'custom-groups-view'}>

            {showCustomGroups()}
        </div>
    );
};

export default CustomGroupsView;