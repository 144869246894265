export const uploadProps = {
    progress: {
        strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
        },
        strokeWidth: 3,
        format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
}

export const imgExtensions = [
    'jpg',
    'png',
    'jpeg',
    'svg',
    'jfif',
    'gif',
    'webp',
    'avif',
    'apng'
]