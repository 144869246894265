import {BaseOptionType} from "antd/lib/select";
import {DefaultOptionType} from "antd/lib/cascader";

export const selectDropdownStyles={
    borderRadius: '14px',
    minWidth: '185px',
    color: 'var(--main-text-color)'
}

export const errorStyles = {
    background: 'var(--bg-error)',
    border: '0.5px solid var(--error-text-color)'
}

export const langOptions: BaseOptionType[] | DefaultOptionType[] = [
    {label: 'En', value: 'en'},
    {label: 'Ру', value: 'ru'},
]